import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core";
import ConfirmModal from "../../../common/ConfirmModal";
import {API} from "aws-amplify";
import AddEditProviderRotation from "./AddEditProviderRotation";
import _ from "lodash";


const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const columns = [
    { id: 'name', label: 'Rotation Name', minWidth: 170 },
    { id: 'providerRotationMembers', label: 'Members', minWidth: 300 }

];

function ProviderRotationsIndex( props ){
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [importSchema, setImportSchema] = React.useState([]);
    const [providerRotation, setProviderRotation] = React.useState([]);

    const loadProviderRotation = () => {
        API.get("Core", "/api/v1/provider-rotation")
            .then(response => {
                    setProviderRotation(response);
                },
                error => {
                    toast("Could not get provider rotation: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
    }

    React.useEffect(() => {
        loadProviderRotation();
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteProviderRotation = (providerRotationId) => {
        API.del('Core', '/api/v1/provider-rotation/' + providerRotationId)
            .then(response => {
                    toast("Deleted Provider Rotation!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                    loadProviderRotation();
                },
                error => {
                    toast("Could not Delete Personnel" + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <AddEditProviderRotation
                    button={(<Button variant="contained" color="primary" className={classes.button}>
                        Add Provider Rotation
                    </Button>)}
                    onSuccess={() => {loadProviderRotation();}}
                />
            </Grid>
            <Grid xs={12}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {providerRotation.map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns.map(column => {
                                        let value = row[column.id];
                                        if(column.id == 'providerRotationMembers'){
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    <ul>
                                                        {
                                                            _.map(value, function(prm, k){
                                                                return (
                                                                    <li>{prm.providerUser.firstName + " " + prm.providerUser.lastName}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </TableCell>
                                            )
                                        }
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value.length > 30 ? value.substring(0, 30) + '...' : value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key={'edit'}>
                                        <AddEditProviderRotation button={(
                                            <Button variant="contained" color="primary" className={classes.button}>
                                                Edit
                                            </Button>
                                        )} providerRotationId={row.id} onSuccess={() => {loadProviderRotation();}}/>
                                        <ConfirmModal
                                            title={"Delete Provider Rotation"}
                                            text={(<span>Are you sure you want to delete <b>{row.name}</b>?</span>)}
                                            onConfirm={deleteProviderRotation.bind(this, row.id)}
                                            confirmTitle={"Delete"}
                                        >
                                            <Button variant="contained" color="primary" className={classes.button}>
                                                Delete
                                            </Button>
                                        </ConfirmModal>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default ProviderRotationsIndex;