import { API } from 'aws-amplify';
import {updateConversationFailure} from "../conversation/actions";

export const GET_CSV_SCHEMA = 'GET_CSV_SCHEMA';
export const requestCsvSchema = () => dispatch => {
    dispatch({
        type : GET_CSV_SCHEMA
    });
}

export const GET_CSV_SCHEMA_SUCCESS = 'GET_CSV_SCHEMA_SUCCESS';
export const requestCsvSchemaSuccess = (json) => dispatch => {
    dispatch({
        type : GET_CSV_SCHEMA_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const SEARCH_CLIENTS = 'SEARCH_CLIENTS';
export const searchClients = () => dispatch => {
    dispatch({
        type : SEARCH_CLIENTS
    });
}

export const SEARCH_CLIENTS_SUCCESS = 'SEARCH_CLIENTS_SUCCESS';
export const searchClientSuccess = (json) => dispatch => {
    dispatch({
        type : SEARCH_CLIENTS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const SEARCH_CLIENTS_FAILURE = 'SEARCH_CLIENTS_FAILURE';
export const searchClientFailure = (message) => dispatch => {
    dispatch({
        type : SEARCH_CLIENTS_FAILURE,
        payload: message,
        receivedAt: Date.now()
    });
}

export const GET_SINGLE_CLIENT = 'GET_SINGLE_CLIENT';
export const getSingleClient = () => dispatch => {
    dispatch({
        type : GET_SINGLE_CLIENT
    });
}

export const GET_SINGLE_CLIENT_SUCCESS = 'GET_SINGLE_CLIENT_SUCCESS';
export const getSingleClientSuccess = (json) => dispatch => {
    dispatch({
        type : GET_SINGLE_CLIENT_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function fetchClientCsvSchema() {

    return function(dispatch) {

        dispatch(requestCsvSchema())

        return API.get('Core', '/api/v1/client/csv/schema')
            .then(response => {
                    dispatch(requestCsvSchemaSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchClientSearch(page, size, search) {
    return function(dispatch){
        dispatch(searchClients())

        return API.get('Core', '/api/v1/client', {
            queryStringParameters: {
                page: page,
                size: size,
                search: search
            }
        })
            .then(response => {
                dispatch(searchClientSuccess(response))
            },
            error => {
                dispatch(searchClientFailure(error.response.data.message));
            })
    }
}

export function fetchSingleClient(id) {
    return function(dispatch){
        dispatch(getSingleClient())

        return API.get('Core', '/api/v1/client/' + id)
            .then(response => {
                    dispatch(getSingleClientSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                })
    }
}