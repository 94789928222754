import {API} from 'aws-amplify';
import {throwGenericError} from "../transaction_state/actions";
import {TRANSACTION_KEY} from "../providers/actions";
import _ from "lodash";

export const GET_SURVEYS = 'GET_SURVEYS';
export const requestSurveys = () => dispatch => {
    dispatch({
        type: GET_SURVEYS
    });
}

export const GET_FOLLOW_UPS = 'GET_FOLLOW_UPS';
export const requestFollowUps = () => dispatch => {
    dispatch({
        type: GET_FOLLOW_UPS
    });
}

export const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export const requestSurveySuccess = (json) => dispatch => {
    dispatch({
        type: GET_SURVEY_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}


export const GET_SURVEY_DATA_SUCCESS = 'GET_SURVEY_DATA_SUCCESS';
export const requestSurveyDataSuccess = (json) => dispatch => {
    dispatch({
        type: GET_SURVEY_DATA_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_FOLLOW_UPS_SUCCESS = 'GET_FOLLOW_UPS_SUCCESS';
export const requestFollowUpsSuccess = (json) => dispatch => {
    dispatch({
        type: GET_FOLLOW_UPS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_SURVEY_STATS = 'GET_SURVEY_STATS';
export const requestSurveyStats = () => dispatch => {
    dispatch({
        type: GET_SURVEY_STATS
    });
}

export const GET_SURVEY_STATS_SUCCESS = 'GET_SURVEY_STATS_SUCCESS';
export const requestSurveyStatsSuccess = (json) => dispatch => {
    dispatch({
        type: GET_SURVEY_STATS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const CHANGE_SELECTED_SURVEY = 'CHANGE_SELECTED_SURVEY';
export const changeSelectedSurvey = (surveyId) => dispatch => {
    dispatch({
        type: CHANGE_SELECTED_SURVEY,
        payload: surveyId,
    });
}

export const GET_SINGLE_SURVEY = 'GET_SINGLE_SURVEY';
export const requestSingleSurvey = (surveyId) => dispatch => {
    dispatch({
        type: GET_SINGLE_SURVEY,
        payload: surveyId
    });
}

export const GET_SINGLE_SURVEY_SUCCESS = 'GET_SINGLE_SURVEY_SUCCESS';
export const requestSingleSurveySuccess = (json) => dispatch => {
    dispatch({
        type: GET_SINGLE_SURVEY_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_SURVEY_VERSIONS = 'GET_SURVEY_VERSIONS';
export const requestSurveyVersions = () => dispatch => {
    dispatch({
        type: GET_SURVEY_VERSIONS
    });
}

export const GET_SURVEY_VERSIONS_SUCCESS = 'GET_SURVEY_VERSIONS_SUCCESS';
export const requestSurveyVersionsSuccess = (json) => dispatch => {
    dispatch({
        type: GET_SURVEY_VERSIONS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const DELETE_SURVEY = 'DELETE_SURVEY';
export const deleteSurvey = () => dispatch => {
    dispatch({
        type: DELETE_SURVEY
    });
}

export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS';
export const deleteSurveySuccess = (json) => dispatch => {
    dispatch({
        type: DELETE_SURVEY_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_SURVEY_SEND_IMPORT_SCHEMA = 'GET_SURVEY_SEND_IMPORT_SCHEMA';
export const requestSurveySendImportSchema = () => dispatch => {
    dispatch({
        type: GET_SURVEY_SEND_IMPORT_SCHEMA
    });
}

export const GET_SURVEY_SEND_IMPORT_SCHEMA_SUCCESS = 'GET_SURVEY_SEND_IMPORT_SCHEMA_SUCCESS';
export const requestSurveySendImportSchemaSuccess = (json) => dispatch => {
    dispatch({
        type: GET_SURVEY_SEND_IMPORT_SCHEMA_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_SURVEY_IMPORTS = 'GET_SURVEY_IMPORTS';
export const requestSurveyImports = () => dispatch => {
    dispatch({
        type: GET_SURVEY_IMPORTS
    });
}

export const GET_SURVEY_IMPORTS_SUCCESS = 'GET_SURVEY_IMPORTS_SUCCESS';
export const requestSurveyImportsSuccess = (surveyId, json) => dispatch => {
    dispatch({
        type: GET_SURVEY_IMPORTS_SUCCESS,
        payload: json,
        surveyId: surveyId,
        receivedAt: Date.now()
    });
}

export const GET_SURVEY_IMPORT_ROWS = 'GET_SURVEY_IMPORT_ROWS';
export const requestSurveyImportRows = () => dispatch => {
    dispatch({
        type: GET_SURVEY_IMPORT_ROWS
    });
}

export const GET_SURVEY_IMPORT_ROWS_SUCCESS = 'GET_SURVEY_IMPORT_ROWS_SUCCESS';
export const requestSurveyImportRowsSuccess = (importId, json) => dispatch => {
    dispatch({
        type: GET_SURVEY_IMPORT_ROWS_SUCCESS,
        importId: importId,
        payload: json,
        receivedAt: Date.now()
    });
}

export const UPDATE_ACTIVE_SURVEY_IN_SURVEY_DEFINITION = "UPDATE_ACTIVE_SURVEY_IN_SURVEY_DEFINITION";
export const updateActiveSurveyInSurveyDefinition = (newActiveSurveyData) => dispatch => {
    dispatch({
        type: UPDATE_ACTIVE_SURVEY_IN_SURVEY_DEFINITION,
        payload: newActiveSurveyData
    })
}

export const UPDATE_OR_SAVE_SURVEY_VERSION = "UPDATE_OR_SAVE_SURVEY_VERSION";
export const updateOrSaveSurveyVersion = (updatedNewSurveyVersion) => dispatch => {
    dispatch({
        type: UPDATE_OR_SAVE_SURVEY_VERSION,
        payload: updatedNewSurveyVersion
    })
}

export const ADD_SURVEY_VERSION_TO_ACTIVE_SURVEY = "ADD_SURVEY_VERSION_TO_ACTIVE_SURVEY";
export const addSurveyVersionToActiveSurvey = (newSurveyVersion) => dispatch => {
    dispatch({
        type: ADD_SURVEY_VERSION_TO_ACTIVE_SURVEY,
        payload: newSurveyVersion
    })
}

export const GET_SURVEY_SAVED_VERSIONS = "GET_SURVEY_SAVED_VERSIONS";
export const getSurveySavedVersions = (surveyVersion) => dispatch => {
    dispatch({
        type: GET_SURVEY_SAVED_VERSIONS,
        payload: surveyVersion
    })
}

export const REMOVE_SURVEY_VERSION_TO_ACTIVE_SURVEY = "REMOVE_SURVEY_VERSION_TO_ACTIVE_SURVEY";
export const removeSurveyVersionToActiveSurvey = (surveyIdDeleted) => dispatch => {
    dispatch({
        type: REMOVE_SURVEY_VERSION_TO_ACTIVE_SURVEY,
        payload: surveyIdDeleted
    })
}


export function fetchSurveySendImportSchema() {

    return function (dispatch) {

        dispatch(requestSurveySendImportSchema())

        return API.get('Core', '/api/v1/survey/import/csv/schema')
            .then(response => {
                    dispatch(requestSurveySendImportSchemaSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchSurveyImports(surveyId) {

    return function (dispatch) {

        dispatch(requestSurveyImports())

        return API.get('Core', '/api/v1/survey/' + surveyId + '/imports')
            .then(response => {
                    dispatch(requestSurveyImportsSuccess(surveyId, response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchSurveyImportRows(surveyId, importId) {

    return function (dispatch) {

        dispatch(requestSurveyImportRows())

        return API.get('Core', '/api/v1/survey/' + surveyId + '/imports/' + importId + '/rows')
            .then(response => {
                    dispatch(requestSurveyImportRowsSuccess(importId, response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchAllSurveys() {

    return function (dispatch) {

        // dispatch(requestSurveys())

        return API.get('Core', '/api/v1/survey')
            .then(response => {
                    dispatch(requestSurveySuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchAllFollowUpQuestions() {

    return function (dispatch) {

        dispatch(requestFollowUps())

        return API.get('Core', '/api/v1/survey/followups')
            .then(response => {
                    dispatch(requestFollowUpsSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchSurveyStats() {

    return function (dispatch) {

        dispatch(requestSurveyStats())

        return API.get('Core', '/api/v1/survey/sending-stats')
            .then(response => {
                    dispatch(requestSurveyStatsSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchSurveyData(surveyId,versionId) {
    return function (dispatch) {

        return Promise.all([
            API.get('Core', `/api/v1/survey/${surveyId}`),
            API.get('Core', `/api/v1/survey/${surveyId}/version`)
        ]).then(
            ([singleSurveyResponse, surveyVersionsResponse]) => {
                let selectVersion = null
                if (versionId) {
                    selectVersion = versionId;
                }
                else if (singleSurveyResponse != null && singleSurveyResponse.activeSurveyVersion != null) {
                    selectVersion = singleSurveyResponse.activeSurveyVersion.id;
                } else if (surveyVersionsResponse != null) {
                    let versionsSorted = _.sortBy(surveyVersionsResponse, function (v) {
                        return v.version;
                    });
                    selectVersion = versionsSorted[versionsSorted.length - 1].id;
                }

                dispatch(requestSurveyDataSuccess({
                    survey: singleSurveyResponse,
                    versions: surveyVersionsResponse,
                    selectVersion: selectVersion
                }));
            },
            error => {
                console.log('An error occurred.', error);
                // Optionally, dispatch a failure action here
            }
        );
    }
}

export function deleteSingleSurvey(id) {

    return function (dispatch) {

        dispatch(deleteSurvey())

        return API.del('Core', '/api/v1/survey/' + id)
            .then(response => {
                    dispatch(fetchAllSurveys())
                },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}

export const GET_SYSTEM_LANGUAGES = 'GET_SYSTEM_LANGUAGES';
export const requestSystemLanguages = () => dispatch => {
    dispatch({
        type: GET_SYSTEM_LANGUAGES
    });
}

export const GET_SYSTEM_LANGUAGES_SUCCESS = 'GET_SYSTEM_LANGUAGES_SUCCESS';
export const requestSystemLanguagesSuccess = (json) => dispatch => {
    dispatch({
        type: GET_SYSTEM_LANGUAGES_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function fetchAllSystemLanguages() {
    return function (dispatch) {
        dispatch(requestSystemLanguages())
        return API.get('Core', '/api/v1/survey/system-languages')
            .then(response => {
                    dispatch(requestSystemLanguagesSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}