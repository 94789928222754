import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import SvgIcon from '@material-ui/core/SvgIcon';

const sizes = {
  "small" : {
    width: "320px"
  },
  "medium" : {
    width: "480px"
  },
  "large" : {
    width: "640px",
    maxWidth: "72%"
  },
  "xl" : {
    width: "800px",
    maxWidth: "80%"
  },
  "xxl" : {
    width: "90%",
    maxWidth: "90%"
  },
  "auto" : {
    width: "auto",
    maxWidth: "auto"
  }
}

function CloseIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
    </SvgIcon>
  );
}

function ModalDialogTitleBar(props){
  const { visibility, title } = props
  switch(visibility) {
    case 'hidden':
      return null
    default:
      return (
        <div className="titleBar">
          <div className="title">{title}</div>
          <div className="separator"></div>
        </div>
        )
  }
}

export default function CommonModal(props) {
  var {button, content, size, title, titleBar, handleClose, handleOpen, open, dialogClassName, containerDivClass, containerDivButtonClass} = props;
  let styleSize = sizes[size];
  let finalDialogClassName = dialogClassName ? dialogClassName : "modalDialog";

  return (
    <div className={containerDivClass}>
      <div className={containerDivButtonClass} onClick={handleOpen}>
        {button}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={"modalOverlay"}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={finalDialogClassName} style={styleSize}>
            <ModalDialogTitleBar visibility={titleBar} title={title} />
            {content}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}