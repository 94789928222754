import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import _ from "lodash";

import { SUCCESS, ERROR} from '@/shared/lifecycleStates';
import ManageEmailSettings from "@/components/ManageEmailSettings";
import ManageSMSSettings from "@/components/ManageSMSSettings";

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
})

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
  completed: {
    color: theme.palette.success[600] + "!important",
  }
}));

function getSteps() {
  return [
    {label: 'Contact by Text', stepStateKey: 'TREAT_TEXT'},
    {label: 'Contact by Email', stepStateKey: 'TREAT_EMAIL'},
  ];
}

function getActiveStep(currentStep){
  if(!currentStep){
    return -1;
  }

  let stepIdx = _.findIndex(getSteps(), function(o){return o.stepStateKey === currentStep});
  if(stepIdx >= 0){
    return stepIdx;
  }
  return -1;
}

function SetupConnect(props) {
  const {stepStates, onStepChange, currentStep } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(getActiveStep(currentStep));
  const [open, setOpen] = React.useState(false);
  const [loadActionsCalled, setLoadActionsCalled] = React.useState(false);
  const steps = getSteps();

  if(!loadActionsCalled){
    setLoadActionsCalled(true);
  }

  useEffect(() => {
    setActiveStep(getActiveStep(currentStep));
  }, [currentStep]);

  if(open && props.user.inviteUserLifecycleState === ERROR){
    toast(props.user.inviteUserError, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
  }
  else if(open && props.user.inviteUserLifecycleState === SUCCESS){
    props.inviteUserClear();
    toast.success("Invitation sent!", {position: toast.POSITION.TOP_CENTER});
    setOpen(false);
  }

  const getStepContent = (step) => {
    // eslint-disable-next-line default-case
    switch (step) {
      case 0:
        return (
            <div>
              <Typography color={"textSecondary"}>Allow clients and patients to text in questions, pictures and other concerns.</Typography>
              <ManageSMSSettings/>
            </div>
        );
      case 1:
        return (
            <div>
              <Typography color={"textSecondary"}>Setup an email inbox to receive correspondence from your clients and patients.</Typography>
              <ManageEmailSettings/>
            </div>
        );
    }
  };

  const selectStep = (stepIdx) => {
    setActiveStep(stepIdx);
    onStepChange(getSteps()[stepIdx].stepStateKey);
  }

  return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} nonLinear orientation="vertical">
          {steps.map((obj, index) => {
            const stepProps = {};
            const stepLabelProps = {};

            if(stepStates[obj.stepStateKey] && stepStates[obj.stepStateKey].completed){
              stepProps.completed = true;
              stepLabelProps.StepIconProps={
                classes: { root: classes.completed }
              };
            }

            return (<Step key={obj.label} {...stepProps}>
              <StepButton onClick={selectStep.bind(this, index)}><StepLabel {...stepLabelProps}>{obj.label}</StepLabel></StepButton>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
              </StepContent>
            </Step>);
          })}
        </Stepper>
      </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupConnect);