import { useEffect, useRef, useState } from 'react';
import Select from "react-select";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import _ from "lodash";

import InputCell from "@/common/form/InputCell";

export default function EditDataAccessRule({ dataAccessRule, consortiumTenants, onEdit, onCancel }) {
    const [dataSources, setDataSources] = useState();
    const [sourceTenant, setSourceTenant] = useState(dataAccessRule.sourceTenant);
    const [sourceDataSource, setSourceDataSource] = useState(dataAccessRule.dataSource);
    const [grantedTenant, setGrantedTenant] = useState(dataAccessRule.grantedTenant);
    const [surveyTargetRole, setSurveyTargetRole] = useState(dataAccessRule.surveyTargetRole);
    const agencyFilterRef = useRef()
    const jurisdictionFilterRef = useRef()

    const surveyRoleTypes = [
        { value: null, label: "-" },
        { value: 'RESPONDER', label: 'Responder' },
        { value: 'CALLTAKER', label: 'Call Taker' }
    ]

    const updateSurveyRoleType = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setSurveyTargetRole(obj.value);
                break;
        }
    }

    const getCurrentRoleValue = () => {
        if (surveyTargetRole != null) {
            var currentOpt = _.find(surveyRoleTypes, function (f) {
                return f.value === surveyTargetRole;
            })
            return currentOpt;
        }
        return null;
    }

    const validForm = (ruleConfig) => {
        if(!ruleConfig.sourceTenant) {
            toast.error('Source Tenant is required');
            return false;
        }
        if(!ruleConfig.dataSource) {
            toast.error('Source Tenant Datasource is required');
            return false;
        }
        if(!ruleConfig.grantedTenant) {
            toast.error('Destination Target is required');
            return false;
        }
        if(ruleConfig.agencyFilter === "" && ruleConfig.jurisdictionFilter === "" ) {
            toast.error('At least one criteria must be included in the filter');
            return false;
        }
        return true;
    }

    const onSubmit = () => {
        const ruleConfig = {
            id: dataAccessRule.id,
            sourceTenant: sourceTenant,
            grantedTenant: grantedTenant,
            dataSource: sourceDataSource,
            agencyFilter: agencyFilterRef.current.value,
            jurisdictionFilter: jurisdictionFilterRef.current.value,
            surveyTargetRole: surveyTargetRole,
        };

        if (!validForm(ruleConfig)) {
            return;
        }

        onEdit(ruleConfig);
    }

    useEffect(() => {
        setDataSources([]);
        if (sourceTenant) {
            API.get('Core', '/api/v1/datasource/tenant/' + sourceTenant.id)
                .then(response => {
                    setDataSources(response);
                },
                    error => {
                        toast("Could not load source tenant data sources: " + error.response.data.message, {
                            position: toast.POSITION.TOP_CENTER,
                            type: toast.TYPE.ERROR
                        });
                    })
        }

    }, [sourceTenant])

    const onSourceTenantSelect = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setSourceTenant(obj);
                break;
            case "clear":
                setSourceTenant(null);
                break;
        }
    }

    const onDestinationTenantSelect = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setGrantedTenant(obj);
                break;
            case "clear":
                setGrantedTenant(null);
                break;
        }
    }

    const onDataSourceSelect = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setSourceDataSource(obj);
                break;
            case "clear":
                setSourceDataSource(null);
                break;
        }
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Source Tenant</div>
                        </div>
                        <Select className="selectCell" classNamePrefix="selectCell"
                            onChange={onSourceTenantSelect}
                            value={sourceTenant ? sourceTenant : "--"}
                            options={consortiumTenants}
                            isClearable={true}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id} />
                    </div>
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Source Tenant Data Source</div>
                        </div>
                        <Select className="selectCell" classNamePrefix="selectCell"
                            isDisabled={!sourceTenant}
                            onChange={onDataSourceSelect}
                            value={sourceDataSource ? sourceDataSource : "--"}
                            options={dataSources}
                            isClearable={true}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id} />
                    </div>
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Destination Tenant</div>
                        </div>
                        <Select className="selectCell" classNamePrefix="selectCell"
                            onChange={onDestinationTenantSelect}
                            value={grantedTenant ? grantedTenant : "--"}
                            options={consortiumTenants}
                            isClearable={true}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id} />
                    </div>
                    <div className="tableSection">
                        <InputCell
                            placeholder={"Agency Filter"}
                            required
                            id="agencyFilter"
                            label="Agency Filter"
                            inputRef={agencyFilterRef}
                            defaultValue={dataAccessRule.agencyFilter}
                        />
                    </div>
                    <div className="tableSection">
                        <InputCell
                            placeholder={"Jurisdiction Filter"}
                            required
                            id="jurisdictionFilter"
                            label="Jurisdiction Filter"
                            defaultValue={dataAccessRule.jurisdictionFilter}
                            inputRef={jurisdictionFilterRef}
                        />
                    </div>
                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Survey Role Type</div>
                        </div>
                        <Select className="tableCell selectCell"
                            value={getCurrentRoleValue()}
                            classNamePrefix="selectCell"
                            isDisabled={false}
                            onChange={updateSurveyRoleType}
                            options={surveyRoleTypes}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={onSubmit} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>
                <div className="separator"></div>
            </div>
        </div>
    );
}