import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import Participant from './Participant';
import LocalParticipant from "./LocalParticipant";
import Grid from "@material-ui/core/Grid";
import ConversationNotes from "../conversations/ConversationNotes";
import {isMobile} from 'react-device-detect';
import moment from '../../common/momentConfig';

import Countdown from "react-countdown";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import AddParticipant from "../conversations/AddParticipant";

const Room = ({ roomName, token, handleLogout, me, roomInfo, videoRecommendedLengthMinutes, conversation }) => {
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [muted, setMuted] = useState(false);
    const [videoDisabled, setVideoDisabled] = useState(false);
    
    useEffect(() => {
        const participantConnected = participant => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };

        const participantDisconnected = participant => {
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };

        Video.connect(token, {
            name: roomName,
            audio:true,
            video:false
        }).then(room => {
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
        });

    }, [roomName, token]);

    const onLogout = () => {
        if(room){
            room.disconnect();
            setRoom(null);
        }
        handleLogout();
    }

    const addParticipant = (newParticipant) => {
        newParticipant.activeRoom = room.name;
        return API.post('Core', '/api/v1/conversation/' + conversation.id + '/participants', {body: newParticipant})
            .then(response => {
                    toast("Interpreter added!  They will dial in shortly.", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                },
                error => {
                    toast("Interpreter could not be added.", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    const remoteParticipants = participants.map(participant => (
        <Grid item xs>
            <Participant key={participant.sid} participant={participant} />
        </Grid>
    ));

    const toggleMute = event => {
        setMuted(!muted);
    }

    const reconnect = (track) => {
        return Video.connect(token, {
            name: roomName,
            track: track
        })
    }

    const toggleVideo = event => {
        setVideoDisabled(!videoDisabled);
    }
    let joinAtTime = moment.utc(roomInfo.providerJoinAt).toDate();
    let localJoinAtTime = moment(joinAtTime).local();
    let totalTime = videoRecommendedLengthMinutes ? videoRecommendedLengthMinutes * 1000 * 60 : 0;
    return (
        <div className="videoCallView">
            <div className="videoCallWindow">

                <div className={`callDetails ${isMobile ? 'mobile' : 'desktop'}`}>
                    {
                        me && me.userType === "PROVIDER" && totalTime && !isNaN(totalTime) ? (
                            <Countdown
                                zeroPadTime={2}
                                renderer={({minutes,seconds, total, completed}) => {

                                    let secondsVal = (seconds.toString().length === 1 ? "0" + seconds : seconds);
                                    let minutesVal = (minutes.toString().length === 1 ? "0" + minutes : minutes);
                                    let content = (<span>{minutesVal}:{secondsVal}</span>);
                                    if(completed){
                                        content = (<span style={{fontSize: '18px'}}>Please finish the call</span>);
                                    }
                                    return (<div className={`timer ${(completed || (total / totalTime < .2)) ? "warn" : ""}`}>
                                                <div className="time">
                                                    {content}
                                                </div>
                                            </div>
                                    )
                                }}
                                date={localJoinAtTime.valueOf() + (totalTime)} />
                        ) : null
                    }
                    <div className="localParticipant">
                            {(room && room.localParticipant) ? (
                                <LocalParticipant
                                    key={room.localParticipant.sid}
                                    participant={room.localParticipant}
                                    muted={muted}
                                    videoDisabled={videoDisabled}
                                    reconnect={reconnect}
                                />

                            ) : (
                                null
                            )}
                    </div>

                    <div className="clientDetails">
                        <div className="client">{me.readableName}</div>
                    </div>
                    {
                        (!isMobile && me.userType === "PROVIDER") ? (
                        <div className="participantsList">
                            <AddParticipant title={"Add Participant"} onAdd={addParticipant} submitTitle={"Add Participant"} currentConversation={conversation}>
                                <div className="button small action addParticipant">
                                    <div className="title">Add Participant</div>
                                </div>
                            </AddParticipant>
                        </div>):null
                    }

                    {
                        (false && !isMobile && me.userType === "PROVIDER") ? (
                            <div style={{marginTop: "5px"}} className={"videoCallNotes chatSideBar"}>
                                <div className={"sideBarSection"}>
                                    <div className={"sectionTitle"}>Notes</div>
                                    <div style={{padding: "0px 8px 0px 8px"}}>
                                        <ConversationNotes activeConversation={conversation}/>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </div>

                <div className="callActions">
                    <div onClick={toggleVideo} className={`callAction privacyAction camera ${videoDisabled ? "off" : "on"}`}>
                        <div className="icon"></div>
                    </div>
                    <div onClick={onLogout} className="callAction actionEnd">
                        <div className="icon"></div>
                    </div>
                    <div onClick={toggleMute} className={`callAction privacyAction microphone ${muted ? "off" : "on"}`}>
                        <div className="icon"></div>
                    </div>
                </div>

                <div className="remoteParticipantStream">
                    <Grid container spacing={3}>
                        {remoteParticipants}
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default Room;