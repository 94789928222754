import _ from 'lodash';

import {
    ADD_UPDATE_TRIGGER_EVENT,
    GET_ENCOUNTER_SCHEMA_SUCCESS, GET_QUEUED_RULE_TRIGGERS_SUCCESS, GET_RULE_TRIGGER_COUNTS_SUCCESS,
    GET_RULE_VERSIONS_SUCCESS,
    GET_RULES_SUCCESS, GET_SENT_RULE_TRIGGERS_SUCCESS, GET_SINGLE_RULE,
    GET_SINGLE_RULE_SUCCESS
} from "./actions";


const initialState = {
    rules: [],
    activeRule: null,
    activeRuleVersions: null,
    encounterSchema:[],
    ruleTriggerCounts:[],
    queuedTriggers: [],
    queuedTriggersPageInfo: {},
    sentTriggers: [],
    sentTriggersPageInfo: {}

}

export default (state = initialState, action) => {

    function addOrUpdateQueuedTriggers(trigger){
        let triggerExists = _.findIndex(state.queuedTriggers, function(t){return t.id == trigger.id});
        if(triggerExists >= 0){
            state.queuedTriggers[triggerExists] = trigger;
        }
        else{
            state.queuedTriggers.push(trigger);
        }
    }

    function addOrUpdateSentTriggers(trigger){
        let triggerExists = _.findIndex(state.sentTriggers, function(t){return t.id == trigger.id});
        if(triggerExists >= 0){
            state.sentTriggers[triggerExists] = trigger;
        }
        else{
            state.sentTriggers.push(trigger);
        }
    }

    switch (action.type) {
        case ADD_UPDATE_TRIGGER_EVENT:
            let trigger = action.data.payload;
            if(trigger.status == 'QUEUED' || trigger.status == 'IN_REVIEW'){
                addOrUpdateQueuedTriggers(trigger)
            }
            else {
                addOrUpdateSentTriggers(trigger);
            }
            return Object.assign({}, state);
        case GET_SINGLE_RULE:
            // Payload is the requested survey ID. If that ID is changing then clear out the active items
            if(state.activeRule && state.activeRule.id != action.payload){
                return Object.assign({}, state, {
                    activeRule: null,
                    activeRuleVersions: null
                })
            }
            return state;
        case GET_RULES_SUCCESS:
            return Object.assign({}, state, {
                rules: action.payload
            });
        case GET_SINGLE_RULE_SUCCESS:
            return Object.assign({}, state, {
                activeRule: action.payload
            });
        case GET_RULE_VERSIONS_SUCCESS:
            return Object.assign({}, state, {
                activeRuleVersions: action.payload
            });
        case GET_ENCOUNTER_SCHEMA_SUCCESS:
            return Object.assign({}, state, {
                encounterSchema: action.payload
            });
        case GET_RULE_TRIGGER_COUNTS_SUCCESS:
            return Object.assign({}, state, {
                ruleTriggerCounts: action.payload
            })
        case GET_SENT_RULE_TRIGGERS_SUCCESS:
            _.forEach(action.payload.content, function(t){
                addOrUpdateSentTriggers(t);
            })
            return Object.assign({}, state, {
                sentTriggersPageInfo: action.payload
            });
        case GET_QUEUED_RULE_TRIGGERS_SUCCESS:
            state.queuedTriggers = [];
            _.forEach(action.payload.content, function(t){
                addOrUpdateQueuedTriggers(t);
            })
            return Object.assign({}, state, {
                queuedTriggersPageInfo: action.payload
            });
            return;
        default:
            return state;
    }
}