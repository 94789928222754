import React from 'react';
import { connect, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core";
import {API} from "aws-amplify";

import ConfirmModal from "@/common/ConfirmModal";
import AddEditTaskType from "@/routes/tasks/AddEditTaskType";
import {fetchAllTaskTypes, postTaskType, putTaskType} from "@/shared/tasks/actions";
import {fetchRoles} from "@/shared/user/actions";
import {fetchAllWorkflows} from "@/shared/workflow/actions";
import {clearFeedbackMessages} from "../../../shared/tasks/actions";
import _ from "lodash";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getTaskTypes : () => dispatch(fetchAllTaskTypes()),
    addNewTaskType : (newTaskType) => dispatch(postTaskType(newTaskType)),
    updateTaskType : (updatedTaskType) => dispatch(putTaskType(updatedTaskType)),
    getRoles : () => dispatch(fetchRoles()),
    getWorkflows : () => dispatch(fetchAllWorkflows()),
    clearFeedbackMessages: () => dispatch(clearFeedbackMessages())
})

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const columns = [
    { id: 'name', label: 'Task Type Name', minWidth: 170 }
];

function TaskTypesIndex( props ){
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedTaskType, setSelectedTaskType] = React.useState(null)

    const actionMessage = useSelector(state => state.tasks.successTaskTypeActionMessage)

    React.useEffect(() => {
        if (actionMessage !== null) {
            toast(actionMessage, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
            props.clearFeedbackMessages()
        }
    }, [actionMessage])


    React.useEffect(() => {
        props.getTaskTypes();
    }, [])

    const handleOpen = (taskType) => {
        if (taskType){
            setSelectedTaskType(taskType)
        }
        if(open) {
            setOpen(false);
            return;
        }
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedTaskType(null);
        setOpen(false);
    };

    const handleSubmit = (taskTypeObj, action) => {
        if (action === "create"){
            props.addNewTaskType(taskTypeObj);
        }else if (action === "update"){
            props.updateTaskType(taskTypeObj);
        }
    }

    const deleteTaskType = (taskTypeId) => {
        API.del('Core', '/api/v1/task/types/' + taskTypeId)
            .then(response => {
                    props.getTaskTypes();
                    toast("Deleted Task Type!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                },
                error => {
                    toast("Could not delete task type" + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div className="navActions">
                    <Button variant="contained" color="primary" className={classes.button} onClick={() => handleOpen(null)}>
                        Add Task Type
                    </Button>
                </div>
            </Grid>
            <Grid xs={12}>
                {selectedTaskType || open ? <AddEditTaskType props={props} open={open} close={handleClose} onSubmit={handleSubmit} existingTaskType={selectedTaskType}/> : null}
                </Grid>
            <Grid xs={12}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.tasks.taskTypes ? _.cloneDeep(props.tasks.taskTypes).map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns.map(column => {
                                        let value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key={'edit'}>
                                        <Button variant="contained" color="primary" className={classes.button} onClick={() => handleOpen(row)}>
                                            Edit
                                        </Button>
                                        <ConfirmModal
                                            title={"Delete Task Type"}
                                            text={(<span>Are you sure you want to delete <b>{row.name}</b>?</span>)}
                                            onConfirm={deleteTaskType.bind(this, row.id)}
                                            confirmTitle={"Delete Task Type"}
                                        >
                                            <Button variant="contained" color="primary" className={classes.button}>
                                                Delete
                                            </Button>
                                        </ConfirmModal>
                                    </TableCell>
                                </TableRow>
                            );
                        }) : null}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskTypesIndex);
