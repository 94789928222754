import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import Select from "react-select";

import InputCell from "@/common/form/InputCell";
import { isCandidateCommsEnabled } from "@/shared/access";
import { filterGroupWorkflowType, workflowTypes } from "@/utils/utils";

export default function AddResponseGroup(props) {
    var { onCancel, onSuccess } = props;

    const titleRef = React.createRef();
    const areaCodeRef = React.createRef();
    const defaultGreetingRef = React.createRef();
    const autoReplyDelayTimeRef = React.createRef();
    const [useMasterNumber, setUseMasterNumber] = React.useState(false);
    const [sharedNumberHandle, setSharedNumberHandle] = React.useState("");
    const [botEnabled, setBotEnabled] = React.useState(false);
    const [workflowType, setWorkflowType] = useState(workflowTypes.DEFAULT);
    const [workflowTypeOptions, setWorkflowTypeOptions] = useState([]);

    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

    useEffect(() => {
        API.get('Core', '/api/v1/workflow/types')
            .then((response) => {

                if (!isCandidateCommsEnabled() && response && response.length) {
                    response = response.filter(wf => wf.name !== workflowTypes.APPLICANT)
                }

                setWorkflowTypeOptions(response
                    .filter(filterGroupWorkflowType)
                    .map(type => ({
                    value: type.name,
                    label: type.displayName
                })))
            })
    }, []);


    const saveWorkflow = () => {
        API.post('Core', '/api/v1/workflow', {
            body: {
                name: titleRef.current.value,
                areaCode: (useMasterNumber ? "" :
                    areaCodeRef.current.value),
                sharedNumberHandle: (useMasterNumber ? sharedNumberHandle : ""),
                botEnabled: botEnabled,
                type: workflowType,
                defaultGreeting: defaultGreetingRef.current.value,
                autoReplyDelayTime: autoReplyDelayTimeRef.current.value
            }
        })
            .then(response => {
                onSuccess(response);
            },
                error => {
                    toast("Could not create response group: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const toggleUseMasterNumber = () => {
        setUseMasterNumber(!useMasterNumber);
        if (areaCodeRef && areaCodeRef.current) areaCodeRef.current.value = ""
        setSharedNumberHandle("")
        validateSaveButton()
    }

    const toggleBotEnabled = () => {
        setBotEnabled(!botEnabled);
    }

    const onSharedNumberHandleChange = (e) => {
        setSharedNumberHandle(e.target.value);
        validateSaveButton(e.target.value)
    }

    const onSelectWorkflowType = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setWorkflowType(obj.value);
                break;
        }
    }

    const validateSaveButton = (value) => {
        setSaveButtonEnabled(false)

        if (titleRef.current.value
            && (
                (useMasterNumber && value)
                ||
                (!useMasterNumber && areaCodeRef.current && areaCodeRef.current.value)
            )
        )
            setSaveButtonEnabled(true)
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"Communication Group Name"}
                            defaultValue={""}
                            required
                            id="ruleName"
                            label="Communication Group Name"
                            inputRef={titleRef}
                            onChange={validateSaveButton}
                        />
                    </div>
                    <div className="tableSection sectionName">
                        <div className="tableCell titledSelectCell">
                            <div className="title">Type</div>
                            <Select styles={{ width: "100px" }}
                                className="selectCell workflowTextBackSetting" classNamePrefix="selectCell"
                                onChange={onSelectWorkflowType}
                                value={workflowTypeOptions.find(opt => opt.value === workflowType)}
                                options={workflowTypeOptions}
                            />
                        </div>
                    </div>
                    <div className={"tableSection"}>
                        <div onClick={toggleUseMasterNumber} className={`tableCell switchCell ${useMasterNumber ? 'switchOn' : 'switchOff'}`}>
                            <div className="title">Use System Short Code</div>
                            <div className={`accessory accessorySwitch ${useMasterNumber ? 'on' : 'off'}`}>
                                <div className="switchThumb" />
                            </div>
                        </div>
                        <div onClick={toggleBotEnabled} className={`tableCell switchCell ${botEnabled ? 'switchOn' : 'switchOff'}`}>
                            <div className="title">Allow Bot Response</div>
                            <div className={`accessory accessorySwitch ${botEnabled ? 'on' : 'off'}`}>
                                <div className="switchThumb" />
                            </div>
                        </div>
                    </div>

                    {useMasterNumber &&
                        <div className="tableSection">
                            <InputCell
                                placeholder={"@ABCD"}
                                defaultValue={""}
                                required
                                id="shortCodeHandle"
                                label="Short Code Handle"
                                onChange={onSharedNumberHandleChange}
                            />
                        </div>
                    }

                    {!useMasterNumber &&
                        <div className="tableSection tableRow">
                            <InputCell
                                placeholder={"314"}
                                defaultValue={""}
                                required
                                id="areaCode"
                                label="Area Code"
                                maxLength={3}
                                inputRef={areaCodeRef}
                                onChange={validateSaveButton}
                            />
                        </div>
                    }
                    <div className="tableSection">
                        <div className="sectionTitle">Default Greeting</div>
                        <textarea rows="8" id="textArt"
                            placeholder={"Default Greeting"}
                            defaultValue={""}
                            ref={defaultGreetingRef}
                        />
                    </div>
                    <div className="tableSection">
                        <div className="sectionTitle">Auto Reply Delay Time (Minutes)</div>
                        <InputCell
                            placeholder={""}
                            defaultValue={0}
                            type={"number"}
                            minValue="0"
                            id="autoReplyDelayTime"
                            inputRef={autoReplyDelayTimeRef}
                        />
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveButtonEnabled ? saveWorkflow : null} className={`button confirm medium ${saveButtonEnabled ? "" : "disabled"}`}>
                        <div className="title">Done</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>

    )
}