import React from 'react';
import {connect} from "react-redux";
import _ from "lodash";

import Modal from "@/common/Modal";
import {
    createServiceOffering,
    deleteServiceOffering,
    fetchAllServiceOfferings, updateServiceOffering,
} from "@/shared/services/actions";
import {
    fetchPaymentSettings
} from "@/shared/payment/actions";

import AddEditServiceOffering from "./AddEditServiceOffering";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    fetchAllServiceOfferings: () => dispatch(fetchAllServiceOfferings()),
    createServiceOffering: (body) => dispatch(createServiceOffering(body)),
    deleteServiceOffering: (id) => dispatch(deleteServiceOffering(id)),
    updateServiceOffering: (id, body) => dispatch(updateServiceOffering(id,body)),
    getPaymentSettings: () => dispatch(fetchPaymentSettings())
})

function Services(props) {
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [editService, setEditService] = React.useState(null);

    React.useEffect(() => {
        props.fetchAllServiceOfferings();
        props.getPaymentSettings();
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditService = (service) => {
        setEditService(service);
        setEditOpen(true);
    }

    const handleEditClose = () => {
        setEditService(null);
        setEditOpen(false);
    }

    const onDelete = (serviceId) => {
        if(serviceId){
            props.deleteServiceOffering(serviceId);
            handleEditClose();
        }
    }

    const onSuccess = (serviceOffering) => {
        if(serviceOffering.id){
            props.updateServiceOffering(serviceOffering.id, serviceOffering);
            handleEditClose();
        }
        else{
            props.createServiceOffering(serviceOffering);
            handleClose();
        }
    }

    return (
        <div className="rootView servicesView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="title">Services</div>
                </div>
                <div className="actions">
                    <div className="">
                        <Modal
                            button={(<div className="add action">
                                <div className="icon"/>
                                <div className="title">Add Service</div>
                            </div>)}
                            content={(<AddEditServiceOffering
                                onSuccess={onSuccess}
                                onCancel={handleClose}
                            />)}
                            title="Add Service"
                            size="small"
                            handleClose={handleClose}
                            handleOpen={handleOpen}
                            open={open}
                        />

                    </div>
                </div>
                <div className="separator"></div>
            </div>
            <div className="servicesList tableView">
                <div className="tableSection">
                    <div className="sectionTitle">Your Services</div>
                    {
                        _.map(props.services.services, function(service, k){
                            return (
                                <div className="tableCell service categoryChat" key={k}>
                                    <div className="icon">
                                        <div className="glyph"></div>
                                    </div>
                                    <div className="cellBody">
                                        <div className="title serviceName">{service.name}</div>
                                        <div className="subtitle description">{service.details}</div>
                                    </div>
                                    <div className="cellActions">
                                        <div onClick={handleEditService.bind(this, service)} className="cellAction actionEdit button small action">
                                            <div className="title">Edit</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Modal
                button={null}
                content={(<AddEditServiceOffering
                    existingService={editService}
                    onDelete={onDelete}
                    onSuccess={onSuccess}
                    onCancel={handleEditClose}
                />)}
                title="Edit Service"
                size="small"
                handleClose={handleEditClose}
                open={editOpen}
            />
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);