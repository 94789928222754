import { API } from 'aws-amplify';
import {throwGenericError} from "../transaction_state/actions";

export const GET_DIGEST_SETTINGS = 'GET_DIGEST_SETTINGS';
export const getDigestSettings = () => dispatch => {
    dispatch({
        type : GET_DIGEST_SETTINGS
    });
}

export const GET_DIGEST_SETTINGS_SUCCESS = 'GET_DIGEST_SETTINGS_SUCCESS';
export const getDigestSettingsSuccess = (json) => dispatch => {
    dispatch({
        type : GET_DIGEST_SETTINGS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function fetchDigestSettings() {

    return function(dispatch) {
        dispatch(getDigestSettings());


        return API.get('Core', '/api/v1/digest/settings')
            .then(response => {
                    dispatch(getDigestSettingsSuccess(response))
                },
                error => {

                }
            );
    }
}