import React from "react";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import InputCell from "../common/form/InputCell";
import _ from "lodash";


export default function AddEditRole({systemPrivileges, existingRole, onSuccess, onCancel}){
    const [privileges, setPrivileges] = React.useState((existingRole && existingRole.privileges) ? existingRole.privileges : []);
    const nameRef = React.createRef();

    const togglePrivilege = (privilegeId) => {
        let privelegeGiven = _.findIndex(privileges, function(p){return p.privilegeId == privilegeId;})
        if(privelegeGiven == -1){
            privileges.push({privilegeId: privilegeId});
        }
        else {
            privileges.splice(privelegeGiven, 1);
        }
        setPrivileges(Object.assign([], privileges));
    }

    const saveRole = () => {
        let updateRole = {
            name : nameRef.current.value,
            privileges : privileges
        };

        if(existingRole){
            updateRole = Object.assign(existingRole, updateRole);
        }
        if(existingRole){
            API.put('Core', '/api/v1/role/' + existingRole.id, {body: updateRole})
                .then(response => {
                        onSuccess(response);
                    },
                    error => {
                        toast("Could not update role: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    }
                );
        }
        else{
            API.post('Core', '/api/v1/role', {body: updateRole})
                .then(response => {
                        onSuccess(response);
                    },
                    error => {
                        toast("Could not create role: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    }
                );

        }
    }

    return (
        <div className="addEditClientForm">
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"e.g. Administrator"}
                            defaultValue={(existingRole ? existingRole.name : "")}
                            required
                            id="roleName"
                            label="Role Name"
                            inputRef={nameRef}
                        />
                    </div>

                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Privileges</div>
                        </div>
                        {
                            _.map(systemPrivileges, function(group, i){
                                return (
                                    <div className="tableSection">
                                        <div className="sectionTitle">
                                            <div className="title">{group.groupName}</div>
                                        </div>
                                        {
                                            _.map(_.sortBy(group.groupPrivileges, function(p){
                                                return p.name;
                                            }), function(priv, j){
                                                let privelegeGiven = _.find(privileges, function(p){return p.privilegeId == priv.sysPrivilegeId;})
                                                return (
                                                    <div onClick={togglePrivilege.bind(this, priv.sysPrivilegeId)} className={`tableCell switchCell ${privelegeGiven ? 'switchOn' : 'switchOff'}`}>
                                                        <div className="title">{priv.name}</div>
                                                        <div className={`accessory accessorySwitch ${privelegeGiven ? 'on' : 'off'}`}>
                                                            <div className="switchThumb"/>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveRole} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>
    );
}