const CALLTAKER = "CALLTAKER";
const RESPONDER = "RESPONDER";

export const tabs = [
    CALLTAKER,
    RESPONDER
]

export const frequencies = [
    { value: null, label: "-" },
    { value: 'DAILY', label: 'Every Day' },
    { value: 'WEEKLY', label: 'Every Week' },
    { value: 'MONTHLY', label: 'Every Month' }
]

export const dowValues = [
    { value: null, label: "-" },
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
    { value: 7, label: 'Sunday' }
]