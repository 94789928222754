import { Divider, FormControl, makeStyles, TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { API, Auth } from "aws-amplify";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import { useDropzone } from "react-dropzone";
import Img from "react-image";


const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left',
        marginLeft: theme.spacing(2)
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    },
    formControl: {
        width: '50%',
        marginBottom: "15px",
        marginLeft: "10px"
    }
}));

function uploadLogo(file) {
    return new Promise(async (resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open("POST", window.__RUNTIME_CONFIG__.API_HOST + "/api/v1/files/upload");
        req.setRequestHeader("Authorization", `Bearer ${(await Auth.currentSession()).accessToken.jwtToken}`)

        req.upload.addEventListener("progress", event => {
            if (event.lengthComputable) {
                let copy = {};
                copy[file.name] = {
                    state: "pending",
                    percentage: (event.loaded / event.total) * 100
                };
            }
        });

        req.onreadystatechange = function () {
            if (req.readyState === 4) {
                resolve(req.response);
            }
        }

        req.upload.addEventListener("error", event => {
            reject(req.response);
        });


        const formData = new FormData();
        formData.append("file", file, file.name);
        req.send(formData);
    })
}

function AgencyInfoIndex(props) {
    const classes = useStyles();
    const pathRef = React.createRef();
    const agencyNameRef = React.createRef();
    const address1Ref = React.createRef();
    const address2Ref = React.createRef();
    const cityRef = React.createRef();
    const stateRef = React.createRef();
    const zipRef = React.createRef();
    const phoneRef = React.createRef();
    const emailRef = React.createRef();
    const websiteRef = React.createRef();
    const [settings, setSettings] = React.useState({});
    const [logoFile, setLogoFile] = React.useState({});
    const [loaded, setLoaded] = React.useState(false);

    let uploadOpts = {
        noClick: true,
        noKeyboard: true,
        multiple: false,
        accept: ['image/*'],
        onDropRejected: function (files, event) {
            toast("Could not upload file", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
        },
        onDropAccepted: function (files, event) {
            if (files[0]) {
                uploadLogo(files[0]).then((res) => {
                    let uploadRes = JSON.parse(res);
                    uploadRes.file.url = uploadRes.url;
                    setLogoFile(uploadRes.file);
                }).catch(err => {
                    toast("Could not upload file", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                })
            }
        }
    };

    const { getRootProps, getInputProps, open } = useDropzone(uploadOpts)

    const loadSettings = () => {
        API.get('Core', "/api/v1/case-portal")
            .then(res => {
                setSettings(res);
                if (res.storedFile && res.storedFile.id) {
                    setLogoFile(res.storedFile);
                }
                setLoaded(true);
            },
                err => {
                    toast("Could not load settings", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR })
                })
    }

    const saveSettings = () => {
        settings.path = pathRef.current.value;
        settings.formalAgencyName = agencyNameRef.current.value;
        settings.address1 = address1Ref.current.value;
        settings.address2 = address2Ref.current.value;
        settings.city = cityRef.current.value;
        settings.state = stateRef.current.value;
        settings.zip = zipRef.current.value;
        settings.phone = phoneRef.current.value;
        settings.email = emailRef.current.value;
        settings.agencyWebsite = websiteRef.current.value;
        if (logoFile) {
            settings.storedFile = logoFile;
        }
        let init = { body: settings };
        API.put('Core', '/api/v1/case-portal', init)
            .then(res => {
                loadSettings();
                toast("Saved Agency Info Settings!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
            },
                err => {
                    toast("Could not save settings", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                });
    }

    React.useEffect(() => {
        loadSettings();
    }, []);

    if (!loaded) {
        return (<span>Loading...</span>);
    }

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Typography variant={"h5"}>
                    Configure Agency Information
                </Typography>
                <Typography variant={"body1"}>
                    This information will power the Victim NotificationCase Lookup Portal
                </Typography>
            </Grid>

            <Grid xs={12} style={{ marginLeft: "15px" }}>
                <Typography variant={"overline"}><b>Victim Notification Portal Path</b></Typography>
                <Divider />
                <FormControl className={classes.formControl} style={{ marginTop: "10px" }}>
                    <TextField defaultValue={settings.path} helperText={"This will be used to form a unique URL to your agency for the public to access and lookup cases"} inputRef={pathRef} required id="standard-required" label="Path" />
                </FormControl>
            </Grid>

            <Grid {...getRootProps()} xs={12} style={{ marginLeft: "15px" }}>
                <Typography variant={"overline"}><b>Agency Logo</b></Typography>
                <Divider />
                <input {...getInputProps()} />
                <Button onClick={open} variant={"contained"} color={"primary"}>
                    Set Agency Logo
                </Button>
                {
                    logoFile ? (
                        <Grid xs={12}>
                            <img src={logoFile.url} style={{ maxWidth: "200px" }} />
                        </Grid>
                    ) : null
                }
            </Grid>

            <Grid xs={12} style={{ marginLeft: "15px" }}>
                <Typography variant={"overline"}><b>Agency Information</b></Typography>
                <Divider />
                <FormControl className={classes.formControl} style={{ marginTop: "10px" }}>
                    <TextField defaultValue={settings.formalAgencyName} helperText={"This is the agency name displayed on the victim notification portal"} inputRef={agencyNameRef} required id="standard-required" label="Agency Name" />
                </FormControl>
                <FormControl className={classes.formControl} style={{ marginTop: "10px" }}>
                    <TextField defaultValue={settings.address1} inputRef={address1Ref} required id="standard-required" label="Address 1" />
                </FormControl>
                <FormControl className={classes.formControl} style={{ marginTop: "10px" }}>
                    <TextField defaultValue={settings.address2} inputRef={address2Ref} required id="standard-required" label="Address 2" />
                </FormControl>
                <FormControl className={classes.formControl} style={{ marginTop: "10px" }}>
                    <TextField defaultValue={settings.city} inputRef={cityRef} required id="standard-required" label="City" />
                </FormControl>
                <FormControl className={classes.formControl} style={{ marginTop: "10px" }}>
                    <TextField defaultValue={settings.state} inputRef={stateRef} required id="standard-required" label="State" />
                </FormControl>
                <FormControl className={classes.formControl} style={{ marginTop: "10px" }}>
                    <TextField defaultValue={settings.zip} inputRef={zipRef} required id="standard-required" label="Zip" />
                </FormControl>
                <FormControl className={classes.formControl} style={{ marginTop: "10px" }}>
                    <TextField defaultValue={settings.phone} inputRef={phoneRef} required id="standard-required" label="Phone" />
                </FormControl>
                <FormControl className={classes.formControl} style={{ marginTop: "10px" }}>
                    <TextField defaultValue={settings.email} inputRef={emailRef} required id="standard-required" label="Email" />
                </FormControl>
                <FormControl className={classes.formControl} style={{ marginTop: "10px" }}>
                    <TextField defaultValue={settings.agencyWebsite} inputRef={websiteRef} required id="standard-required" label="Website" />
                </FormControl>
            </Grid>
            <Grid xs={12}>
                <Button onClick={saveSettings} variant="contained" color="primary" className={classes.button}>
                    Save
                </Button>
            </Grid>
        </Grid>
    )
}

export default AgencyInfoIndex;