import React from 'react';
import _ from 'lodash';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import {Select} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import {throwGenericError} from "@/shared/transaction_state/actions";
import {requestWorkflowConversationsSuccess, TRANSACTION_KEY} from "@/shared/conversation/actions";
import InputCell from "@/common/form/InputCell";

export default function AddEditClient(props){
    var { onCancel, onSuccess, existingClient} = props;

    const [phoneVal, setPhoneVal] = React.useState((existingClient && existingClient.phone) ? existingClient.phone : "");
    const firstNameRef = React.createRef()
    const lastNameRef = React.createRef()
    const emailRef = React.createRef()
    const [surveyOptOut, setSurveyOptOut] = React.useState(existingClient ? existingClient.surveyOptOut : false);
    const [allTextOptOut, setAllTextOptOut] = React.useState(existingClient ? existingClient.allTextOptOut : false);

    const saveClient = () => {
        let updateClient = {
            firstName : firstNameRef.current.value,
            lastName : lastNameRef.current.value,
            email: emailRef.current.value,
            phone: phoneVal,
            surveyOptOut: surveyOptOut,
            allTextOptOut: allTextOptOut
        };

        if(existingClient){
            updateClient = Object.assign(existingClient, updateClient);
        }
        if(existingClient){
            API.put('Core', '/api/v1/client/' + existingClient.id, {body: updateClient})
                .then(response => {
                        onSuccess(response);
                    },
                    error => {
                        toast("Could not update user: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    }
                );
        }
        else{
            API.post('Core', '/api/v1/client', {body: updateClient})
                .then(response => {
                        onSuccess(response);
                    },
                    error => {
                        toast("Could not create user: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    }
                );

        }
    }

    const onPhoneChange = (phone) => {
        setPhoneVal(phone);
    }

    const toggleSurveyOptOutEnabled = () => {
        setSurveyOptOut(!surveyOptOut);
    }

    const toggleAllTextOptOutEnabled = () => {
        setAllTextOptOut(!allTextOptOut);
    }

    return (
        <div className="addEditClientForm" data-testid="addEditContactContainer">
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            inputProps={{"data-testid": "firstName"}}
                            placeholder={"First Name"}
                            defaultValue={(existingClient ? existingClient.firstName : "")}
                            required
                            id="firstName"
                            label="First Name"
                            inputRef={firstNameRef}
                        />

                        <InputCell
                            inputProps={{"data-testid": "lastName"}}
                            placeholder={"Last Name"}
                            required
                            defaultValue={(existingClient ? existingClient.lastName : "")}
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            inputRef={lastNameRef}
                        />
                    </div>

                    <div className="tableSection sectionContact">
                        <InputCell
                            inputProps={{"data-testid": "email"}}
                            placeholder={"Email Address"}
                            required
                            defaultValue={(existingClient ? existingClient.email : "")}
                            id="email"
                            label="Email Address"
                            inputRef={emailRef}
                        />

                        <div className="cell inputCell phone">
                            <div className="title">Phone Number</div>
                            <PhoneInput
                                inputProps={{"data-testid": "phoneNumber"}}
                                inputClass="tel-input"
                                country={'us'}
                                onlyCountries={['us']}
                                value={phoneVal}
                                onChange={onPhoneChange}
                            />
                        </div>
                    </div>
                    <div className="tableSection sectionContact">
                        <div onClick={toggleSurveyOptOutEnabled} className={`tableCell switchCell ${surveyOptOut ? 'switchOn' : 'switchOff'}`}>
                            <div className="title">Opt Out of Surveys</div>
                            <div className={`accessory accessorySwitch ${surveyOptOut ? 'on' : 'off'}`}>
                                <div className="switchThumb"/>
                            </div>
                        </div>
                        <div onClick={toggleAllTextOptOutEnabled} className={`tableCell switchCell ${allTextOptOut ? 'switchOn' : 'switchOff'}`}>
                            <div className="title">Opt Out of All Texts</div>
                            <div className={`accessory accessorySwitch ${allTextOptOut ? 'on' : 'off'}`}>
                                <div className="switchThumb"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div data-testid="doneButton" onClick={saveClient} className={`button confirm medium`}>
                        <div className="title">Done</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>
    );
}