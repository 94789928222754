import { connect } from "react-redux";
import React from "react";
import * as FS from '@fullstory/browser';

function mapStateToProps(state, props) {
    return {
        aboutMe: state.user.aboutMe
    }

}

const mapDispatchToProps = dispatch => ({

})

function FullStory(props) {
    const { aboutMe } = props;
    const [fsLoaded, setFsLoaded] = React.useState(false);
    const [fsInitiated, setFsInitiated] = React.useState(false);

    React.useEffect(() => {
        const fullStoryDisabled = window.__RUNTIME_CONFIG__.FULLSTORY_DISABLED === undefined || window.__RUNTIME_CONFIG__.FULLSTORY_DISABLED === 'true';
        if (!fullStoryDisabled) {
            FS.init({ orgId: '14ERZE', debug: false }, ({ sessionUrl }) => {
                setFsLoaded(true);
            });
        }
    }, []);


    const initiateFS = () => {
        if (!fsLoaded || !aboutMe.user || !aboutMe.currentTenant || aboutMe.user.userType !== 'PROVIDER') {
            return;
        }

        FS.identify(aboutMe.user.id, {
            displayName: aboutMe.user.readableName,
            tenant: aboutMe.currentTenant.name,
            tenantId: aboutMe.currentTenant.id
        })
    }

    if (fsLoaded && !fsInitiated && aboutMe.user && aboutMe.currentTenant) {
        initiateFS("boot");
        setFsInitiated(true);
    }

    return (<div></div>);
}

export default connect(mapStateToProps, mapDispatchToProps)(FullStory);
