import React from 'react';

import InputCell from "@/common/form/InputCell";
import ConfirmModal from "@/common/ConfirmModal";

export default function AddEditServiceOffering(props) {
    var { onCancel, onDelete, onSuccess, existingService } = props;

    const titleRef = React.createRef();
    const detailsRef = React.createRef();
    const [priceVal, setPriceVal] = React.useState((existingService && existingService.price) ? existingService.price : "0.00");

    const saveService = () => {
        if (existingService) {
            onSuccess({
                id: existingService.id,
                name: titleRef.current.value,
                details: detailsRef.current.value,
                price: priceVal
            })
        }
        else {
            onSuccess({
                name: titleRef.current.value,
                details: detailsRef.current.value,
                price: priceVal
            })
        }
    }

    const onPriceChange = (value) => {
        setPriceVal(value)
    }

    const deleteService = () => {
        onDelete(existingService.id);
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"Service Name"}
                            defaultValue={(existingService ? existingService.name : "")}
                            required
                            id="serviceName"
                            label="Title Name"
                            inputRef={titleRef}
                        />

                        <InputCell
                            placeholder={"Description"}
                            required
                            defaultValue={(existingService ? existingService.details : "")}
                            id="serviceDetails"
                            label="Description"
                            name="description"
                            inputRef={detailsRef}
                        />
                        <InputCell
                            placeholder={"Price"}
                            required
                            defaultValue={(existingService ? existingService.price : 0)}
                            id="servicePrice"
                            label="Price"
                            name="price"
                            onChange={onPriceChange}
                            isCurrency
                        />
                    </div>
                </div>
            </div>
            <div className="actionBar">
                {existingService ?
                    <div className="actions left">
                        <ConfirmModal
                            title={"Delete"}
                            text={"Are you sure you want to delete this service?"}
                            onConfirm={deleteService.bind(this)}
                            confirmTitle={"Delete Service"}
                        >
                            <div className="button destructive medium">
                                <div className="title">Delete</div>
                            </div>
                        </ConfirmModal>
                    </div> : null
                }
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveService} className={`button confirm medium`}>
                        <div className="title">Done</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>

    )
}