import React from 'react';
import _ from 'lodash';

export default function SegmentedSelector(props) {
    const { segments, active, onChange } = props;

    const onSegmentSelect = (key) => {
        onChange(key);
    }
    let widthCalculated = (100 / segments.length);
    let widthPerc = widthCalculated + '%';
    let activeIdx = _.findIndex(segments, function (s) { return s.key == active; });
    let selectionOffset = widthCalculated * activeIdx;
    return (
        <div className="segmentedBar index0">
            {
                _.map(segments, function (seg, segIdx) {
                    return (
                        <div style={{ width: widthPerc }} onClick={onSegmentSelect.bind(this, seg.key)} className={`segment ${active == seg.key ? "selected" : ""}`} key={segIdx}>
                            <div className="title">{seg.label}</div>
                        </div>

                    )
                })
            }
            <div style={{ left: selectionOffset + '%' }} className="selection"></div>
        </div>
    )

}