import React from 'react';
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import ReactDOM from 'react-dom';
import {ContentState, Editor, EditorState} from 'draft-js';

import InputCell from "@/common/form/InputCell";

export default function AddEditArticles(props){

    var {onDelete, onCancel, onSuccess, existingArticle, articleGroupId, articleId} = props;

    const [articleSwitch, setArticleSwitch] = React.useState(false);

    const onArticleSwitch = () => {
       setArticleSwitch(!articleSwitch)
    }

    const nameRef = React.createRef();
    const contentRef = React.createRef();

    const deleteArticle = () => {
        if(!existingArticle){
            return;
        }

        API.del('Core', '/api/v1/articles/' + articleGroupId + '/article/' + articleId)
            .then(response => {
                onDelete();
            },
                error =>{
                    toast("Could not delete article: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                });
    }

    const saveArticle = () => {
        let article = {name: nameRef.current.value, contents: contentRef.current.value};
        if(existingArticle){
            API.put('Core', '/api/v1/articles/' + articleGroupId + '/article/' + articleId, {body: article})
                .then(response => {
                        onSuccess(response);
                    },
                    error => {
                        toast("Could not update article: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    }
                );
        }
        else{

            API.post('Core', '/api/v1/articles/' + articleGroupId + '/article', {body: article})
                .then(response => {
                        onSuccess(response);
                    },
                    error => {
                        toast("Could not create Article: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    }
                );
        }
    }




    return (
        <div className="scrollView">
                <div className="content">
                    <div className="tableView">
                        <div className="tableSection sectionName">
                            <InputCell
                                placeholder={"name..."}
                                defaultValue={(existingArticle ? existingArticle.name : "")}
                                required
                                label="Name "
                                inputRef={nameRef}
                                disabled={existingArticle && existingArticle.libraryGroup.type !== "USER_CREATED"}
                            />
                        </div>
                        <div className="tableSection sectionName">
                                <textarea rows = "32" id = "textArt"
                                      placeholder={"Article contents"}
                                      defaultValue={(existingArticle ? existingArticle.contents : "")}
                                      required
                                      ref={contentRef}
                                />



                        </div>
                    </div>
                </div>
                <div className="actionBar">
                    {
                        existingArticle ? (
                            <div className="actions left">
                                <div onClick={deleteArticle} className="button destructive medium">
                                    <div className="title">Delete</div>
                                </div>
                            </div>
                        ) : null
                    }
                    <div className="actions">
                        {/*add functionality*/}
                        <div onClick={onCancel} className="button dismiss medium">
                            <div className="title">Cancel</div>
                        </div>

                        <div onClick={saveArticle} className={`button confirm medium`}>
                            <div className="title">Done</div>
                        </div>

                    </div>

                    <div className="separator"></div>
            </div>

    </div>

    )
}