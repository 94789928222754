import _ from 'lodash';

import {
    START_FILE_UPLOAD,
    UPLOAD_PROGRESS,
    UPLOAD_FAILURE,
    UPLOAD_SUCCESS,
    CLEAR_UPLOADS,
    CLEAR_UPLOAD
} from "./actions";

const initialState = {
    inProgressUploads: {},
    uploadedFiles:{},
    failedFiles:{}

}

export default (state = initialState, action) => {
    function addObjToStateKeyExists(stateKey, key, obj){
        if(!state[stateKey][key]){
            state[stateKey][key] = [];
        }
        state[stateKey][key].push(obj);
    }

    function removeInProgressFile(stateKey, filename){
        if(state.inProgressUploads[stateKey]){
            _.remove(state.inProgressUploads[stateKey], function(f){
                return f.name == filename;
            })
        }
    }

    function removeUploadedFile(stateKey, filename){
        if(state.uploadedFiles[stateKey]){
            _.remove(state.uploadedFiles[stateKey], function(f){
                return f.name == filename;
            })
        }
    }
    switch (action.type) {
        case START_FILE_UPLOAD:
            addObjToStateKeyExists("inProgressUploads", action.key, action.file);
            return Object.assign({}, state);
            break;
        case UPLOAD_PROGRESS:
            // addObjToStateKeyExists("fileProgress", action.key, action.file);
            // return Object.assign({}, state);
            break;
        case UPLOAD_SUCCESS:
            addObjToStateKeyExists("uploadedFiles", action.key, action.storedFile);
            removeInProgressFile(action.key, action.file.name);
            return Object.assign({}, state);
            break;
        case UPLOAD_FAILURE:
            addObjToStateKeyExists("failedFiles", action.key, action.file);
            return Object.assign({}, state);
            break;
        case CLEAR_UPLOADS:
            let key = action.key;
            state.failedFiles[key] = [];
            state.uploadedFiles[key] = [];
            state.inProgressUploads[key] = [];
            return Object.assign({}, state);
        case CLEAR_UPLOAD:
            removeUploadedFile(action.key, action.file.name);
            return Object.assign({}, state);
        default:
            return state
    }
}
