import React from 'react'
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import { BarChart, ResponsiveContainer,  Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import _ from "lodash";

import { AssetGeneralDisclosureIndicatorExtraLarge } from "@/icons/Assets"
import { NavBackButton } from "@/components/Buttons"
import moment from '@/common/momentConfig';
import SurveyResults from "@/routes/survey/SurveyResults";

import SatisfactionPersonnelFeedbackCell from "./SatisfactionPersonnelFeedbackCell"
import SatisfactionPersonnelFeedbackStatCell from "./SatisfactionPersonnelFeedbackStatCell"
import SatisfactionPersonnelSurveyCell from "./SatisfactionPersonnelSurveyCell"

function SatisfactionPersonnelView(props) {

    const { personnelId } = props.match.params;
    const query = new URLSearchParams(props.location.search);
    const DATE_FORMAT = "YYYY-MM-DD";
    const START_DATE_QUERY_PARAM = "startDate";
    const END_DATE_QUERY_PARAM = "endDate";
    let startDateMoment = query.has(START_DATE_QUERY_PARAM) ? moment(query.get(START_DATE_QUERY_PARAM), DATE_FORMAT) : moment().utc().subtract(30, 'day');
    let endDateMoment = query.has(END_DATE_QUERY_PARAM) ? moment(query.get(END_DATE_QUERY_PARAM), DATE_FORMAT) : moment().utc();

    const [surveyDetails, setSurveyDetails] = React.useState(null);
    const [feedbackOpen, setFeedbackOpen] = React.useState(true);
    const [startDate, setStartDate] = React.useState(startDateMoment);
    const [endDate, setEndDate] = React.useState(endDateMoment);

    React.useEffect(() => {
        loadDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onModalClose = () => {
        loadDetails();
    }

    const loadDetails = () => {
        API.get("Core", "/api/v1/survey-reports/cpss/personnel/" + personnelId, {
            queryStringParameters: {
                startDate: startDate.format(DATE_FORMAT),
                endDate: endDate.format(DATE_FORMAT)
            }
        }).then(response => {
            setSurveyDetails(response);
        }, err => {
            toast("Could not load survey details: " + err.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
        })
    }

    if (!surveyDetails) {
        return (<span>Loading...</span>);
    }

    const fetchSurveyBasedSatisfactionRatings = (survey) => {
        if (!survey || !survey.answers) {
            return null;
        }
        const scores = survey.answers
            .filter(a =>
                a.normalizedAnswer &&
                !isNaN(parseInt(a.normalizedAnswer)) && isFinite(a.normalizedAnswer) &&
                a.question && !isNaN(parseInt(a.question.high)) &&
                !a.ignored
            )
            .map(a => ({
                percentage: (parseInt(a.normalizedAnswer) / parseInt(a.question.high)).toFixed(2),
                formattedScore: `${a.normalizedAnswer}/${a.question.high}`,
                highestScore: parseInt(a.question.high)
            }))
            .sort((a, b) => a.percentage - b.percentage || b.highestScore - a.highestScore);

        if (scores.length === 0) {
            return null;
        }
        const uniquePercentages = [...new Set(scores.map(item => item.percentage))];

        if (uniquePercentages.length === 1) {
            return { type: 'same', value: scores[0].formattedScore };
        } else {
            const lowest = scores[0].formattedScore;
            const highestScoreGroup = scores.filter(s => s.percentage === scores[scores.length - 1].percentage);
            const highest = highestScoreGroup.sort((a, b) => b.highestScore - a.highestScore)[0].formattedScore;

            return { type: 'range', lowest: lowest, highest: highest };
        }
    };

    return (
        <div className="detailView satisfactionPersonnelView">
            <div className="navBar">
                <NavBackButton
                    parentTitle="Satisfaction"
                    parentLink="/satisfaction"
                />

                <div className="navTitle">
                    <div className="viewName">{surveyDetails.currentPeriodCPSS.name}</div>
                    <div className="viewSubtitle satisfactionPeriod">
                        <div className="dateValue">{startDate.format("MMMM Do YYYY")} - {endDate.format("MMMM Do YYYY")}</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>

            <div className="scrollView">
                <div className="tableView">
                    <div className="tableSection sectionGraph">
                        <div className="cpssGraph">
                            <div className="grapScore">{_.round((surveyDetails.currentPeriodCPSS.cpss * 100), 1) + '%'} CPSS</div>
                            <div className="graphPeriod">{moment(_.minBy(surveyDetails.ranges, function (r) { console.log(r.startDate); return r.startDate; }).startDate).format("MMMM Do YYYY")} - {moment(_.maxBy(surveyDetails.ranges, function (r) { return r.endDate; }).endDate).format("MMMM Do YYYY")}</div>
                            <ResponsiveContainer height={400}>
                                <BarChart data={_.map(_.sortBy(surveyDetails.ranges, function (r) {
                                              return r.startDate;
                                          }), function (r) {
                                              return {name: r.key, cpss: _.round((r.cpss * 100), 1)}
                                          })} margin={{ bottom: 30, top: 0, left: 30, right: 0 }} responsive>
                                    {/*<CartesianGrid strokeDasharray="3 3"/>*/}
                                    <XAxis dataKey="name" tick={{ fill: 'white' }} axisLine={{ stroke: 'transparent' }}/>
                                    <YAxis tickFormatter={(d) => {return d + "%"}} axisLine={{ stroke: 'transparent' }} tick={{ fill: 'white' }}
                                    />
                                    {/*<Tooltip formatter={(value, name) => [value + "%", name]}/>*/}
                                    <Bar dataKey="cpss" fill="#72ba4d" barSize={100} />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                    </div>

                    <div style={{ cursor: 'pointer' }} className={`tableSection sectionFeedback ${feedbackOpen ? 'expanded' : ''}`}>
                        <div className="sectionHeader">
                            <div className="sectionTitle">Feedback</div>
                            <div onClick={() => setFeedbackOpen(!feedbackOpen)}>
                                <AssetGeneralDisclosureIndicatorExtraLarge />
                            </div>
                        </div>
                        {
                            feedbackOpen ? (
                                <div>
                                    <div className="feedbackOverview">
                                        <SatisfactionPersonnelFeedbackStatCell
                                            value={_.sumBy(surveyDetails.surveys, function (s) {
                                                return _.filter(s.answers, function (a) { return a.sentiment; }).length;
                                            })}
                                            title="Total"
                                        />

                                        <SatisfactionPersonnelFeedbackStatCell
                                            value={_.sumBy(surveyDetails.surveys, function (s) {
                                                return _.filter(s.answers, function (a) { return a.sentiment === "POSITIVE"; }).length;
                                            })}
                                            title="Positive"
                                        />

                                        <SatisfactionPersonnelFeedbackStatCell
                                            value={_.sumBy(surveyDetails.surveys, function (s) {
                                                return _.filter(s.answers, function (a) { return a.sentiment === "NEUTRAL" || a.sentiment === "MIXED"; }).length;
                                            })}
                                            title="Neutral"
                                        />

                                        <SatisfactionPersonnelFeedbackStatCell
                                            value={_.sumBy(surveyDetails.surveys, function (s) {
                                                return _.filter(s.answers, function (a) { return a.sentiment === "NEGATIVE"; }).length;
                                            })}
                                            title="Negative"
                                        />
                                    </div>
                                    {
                                        _.sortBy(_.flatMap(surveyDetails.surveys, function (s) { return _.filter(s.answers, function (a) { return a.sentiment; }) }), function (a) { return a.sentiment; }).reverse().map(function (sentimentAnswer) {
                                            let currentSurvey = _.find(surveyDetails.surveys, function (s) {
                                                let exists = _.find(s.answers, function (a) { return a.id === sentimentAnswer.id; });
                                                return exists;
                                            });
                                            return (
                                                <SurveyResults surveyInstanceId={currentSurvey.id}>
                                                    <SatisfactionPersonnelFeedbackCell
                                                        feedbackSentiment={sentimentAnswer.sentiment}
                                                        feedbackLink=""
                                                        feedbackText={sentimentAnswer.commentAnswer}
                                                    />
                                                </SurveyResults>
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }
                    </div>

                    <div className="tableSection sectionSurveys">
                        <div className="sectionHeader">
                            <div className="sectionTitle">{surveyDetails.surveys.length} Surveys</div>
                        </div>

                        {
                            _.map(surveyDetails.surveys, function (survey) {
                                let time = moment(moment.utc(survey.createdAt)).local().calendar();
                                let surveySatisfactionRatings = fetchSurveyBasedSatisfactionRatings(survey);
                                return (
                                    <SatisfactionPersonnelSurveyCell
                                        link=""
                                        caller={survey.contact.readableName}
                                        timestamp={time}
                                        satisfaction={surveySatisfactionRatings ? surveySatisfactionRatings : ''}
                                        surveyId={survey.id}
                                        onModalClose={onModalClose}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SatisfactionPersonnelView);