export const GENERIC_ERROR = 'GENERIC_ERROR';
export const throwGenericError = (key, msg) => dispatch => {
    dispatch({
        type : GENERIC_ERROR,
        key: key,
        msg: msg
    });
}

export const CONFIRM_SUCCESS = 'CONFIRM_SUCCESS';
export const confirmSuccess = (key) => dispatch => {
    dispatch({
        type : CONFIRM_SUCCESS,
        key: key
    });
}

export const CLEAR_ERROR = 'CLEAR_ERROR';
export const clearTransaction = (key) => dispatch => {
    dispatch({
        type : CLEAR_ERROR,
        key: key
    });
}