import React from 'react';
import { Img } from 'react-image';
import VisibilitySensor from "react-visibility-sensor"
import ImageViewer from 'react-simple-image-viewer';

export default function MessageAttachment(props){
    const {file} = props;
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    let parts = file.contentType.split("/");
    let mimePrefix = parts[0];

    const openImageViewer = () => {
        setIsViewerOpen(true);
    };

    const closeImageViewer = () => {
        setIsViewerOpen(false);
    };

    if(mimePrefix === "image"){

        return (
            <VisibilitySensor>
                <div>
                    <div onClick={openImageViewer} style={{cursor:'pointer'}}>
                    <Img src={file.url} width={"100%"}/>
                </div>
                    {isViewerOpen ? (
                        <ImageViewer
                            src={[file.url]}
                            onClose={closeImageViewer}
                            backgroundStyle={{
                                backgroundColor: "rgba(0,0,0,0.9)",
                                zIndex:10
                            }}
                        />
                    ): null}
                </div>
            </VisibilitySensor>
        )
    }
    else{
        return (
            <div className="attachment compact file">
                <div className="icon"></div>
                <div className="attachmentDetails">
                    <div className="attachmentTitle">{file.name}</div>
                </div>
                <a className="attachmentAction" href={file.url}><div className="title">Open</div></a>
            </div>
        )
    }
}