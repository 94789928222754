import React from 'react';
import _ from "lodash";
import { useTranslation } from 'react-i18next';

function SurveyQuestionChoice({ question, answer, onAnswer, language }) {
    const [languageSetup, setLanguageSetup] = React.useState(false);

    React.useEffect(() => {
        console.log('setup')
        setupTranslations(question);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setupTranslations = (_question) => {
        let languagesTranslations = null;

        try {
            languagesTranslations = JSON.parse(question.languagesTranslations)[language];
        } catch (e) {
            console.warn('Problem to parse choice question languages translations');
        }

        _question.prompt = language && language !== 'en' && languagesTranslations && languagesTranslations.prompt ? languagesTranslations.prompt : _question.prompt;

        _question.options.forEach((option) => {
            try {
                let languagesTranslations = null;
                languagesTranslations = JSON.parse(option.languagesTranslations)[language];
                option.option = language && language !== 'en' && languagesTranslations && languagesTranslations.option ? languagesTranslations.option : option.option;;
            } catch (e) {
                console.warn('Problem to parse choice question option languages translations');
            }
            return option
        })

        setLanguageSetup(true)
    }

    const onSelect = (option) => {
        onAnswer({ selectedOption: option })
    }

    if (languageSetup) {
        return (
            <div className="questionCell answerChoice">
                <div className="surveyQuestion">{question.prompt}</div>

                <div className="surveyAnswers">
                    {
                        _.map(_.sortBy(question.options, function (oo) {
                            return oo.optionOrder;
                        }), function (o) {
                            return (
                                <div onClick={onSelect.bind(this, o)} className={`surveyAnswer ${(answer && answer.selectedOption && answer.selectedOption.id === o.id) ? 'selected' : ''}`}>
                                    <div className="answerTitle">{o.option}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
    return null;
}

function SurveyQuestionScale({ question, answer, onAnswer, language }) {
    const [languageSetup, setLanguageSetup] = React.useState(false);

    React.useEffect(() => {
        setupTranslations(question);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setupTranslations = (_question) => {
        let languagesTranslations = null;

        try {
            languagesTranslations = JSON.parse(_question.languagesTranslations)[language];
        } catch (e) {
            console.warn('Problem to parse scale question languages translations');
        }

        _question.prompt = language && language !== 'en' && languagesTranslations && languagesTranslations.prompt ? languagesTranslations.prompt : _question.prompt;
        _question.lowDescription = language && language !== 'en' && languagesTranslations && languagesTranslations.lowDescription ? languagesTranslations.lowDescription : _question.lowDescription;
        _question.highDescription = language && language !== 'en' && languagesTranslations && languagesTranslations.highDescription ? languagesTranslations.highDescription : _question.highDescription;

        setLanguageSetup(true);
    }

    const onClick = (number) => {
        onAnswer({ rangeSelection: number });
    }

    if (languageSetup) {
        return (
            <div className="questionCell answerScale">
                <div className="surveyQuestion">{question.prompt}</div>

                <div className="surveyAnswers">
                    {
                        _.map(_.range(question.low, question.high + 1), function (i) {
                            return (
                                <div onClick={onClick.bind(this, i)} className={`surveyAnswer ${answer && answer.rangeSelection === i ? 'selected' : ''}`}>
                                    <div className="answerTitle">{i}</div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="answersLabels">
                    <div className="label min">{question.lowDescription ? question.lowDescription : ''}</div>
                    <div className="label max">{question.highDescription ? question.highDescription : ''}</div>
                </div>
            </div>
        );
    }
    return null;
}

function SurveyQuestionComment({ question, answer, onAnswer, language }) {
    const { t } = useTranslation();
    const responseRef = React.createRef();
    const [length, setLength] = React.useState(0);
    const [languageSetup, setLanguageSetup] = React.useState(false);

    React.useEffect(() => {
        setupTranslations(question);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setupTranslations = (_question) => {
        let languagesTranslations = null;

        try {
            languagesTranslations = JSON.parse(_question.languagesTranslations)[language];
        } catch (e) {
            console.warn('Problem to parse scale question languages translations');
        }

        _question.prompt = language && language !== 'en' && languagesTranslations && languagesTranslations.prompt ? languagesTranslations.prompt : _question.prompt;
        _question.lowDescription = language && language !== 'en' && languagesTranslations && languagesTranslations.lowDescription ? languagesTranslations.lowDescription : _question.lowDescription;
        _question.highDescription = language && language !== 'en' && languagesTranslations && languagesTranslations.highDescription ? languagesTranslations.highDescription : _question.highDescription;

        setLanguageSetup(true);
    }

    const onChange = () => {
        onAnswer({ comment: responseRef.current.value });
        if (responseRef && responseRef.current && responseRef.current.value) {
            setLength(responseRef.current.value.length);
        }
    }

    const MAX_CHARACTERS = 255;

    if (languageSetup) {
        return (
            <div className="questionCell answerComment">
                <div className="surveyQuestion">{question.prompt}</div>

                <div className="surveyAnswers">
                    <div className="surveyAnswer tableCell inputCell">
                        <div className="input">
                            <textarea maxLength={MAX_CHARACTERS} style={{
                                background: 'transparent',
                                resize: 'none',
                                border: '0 none',
                                width: '100%',
                                outline: 'none',
                                height: '100%'
                            }} ref={responseRef} onChange={onChange}
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className="answersLabels">
                    <div className="label max">{(length) ? (255 - length) + ` ${t('Characters Remaining')}` : `255 ${t('Character Limit')}`}</div>
                </div>
            </div>
        );
    }
    return null;
}

export { SurveyQuestionChoice, SurveyQuestionScale, SurveyQuestionComment }; 