import React from 'react';

function ConversationEncounterAttributeCell(props) {
  return (
    <div className="conversationEncounterAattributeCell">
      <div className="attributeTitle">{props.attributeTitle}</div>
      <div className="attributeDetail">{props.attributeDetail}</div>
    </div>
  );
}

export default (ConversationEncounterAttributeCell);
