import { API } from 'aws-amplify';

// -------------------------------- Article Groups --------------------------------
export const GET_ARTICLE_GROUPS = 'GET_ARTICLE_GROUPS';
export const requestArticleGroups = () => dispatch => {
    dispatch({
        type : GET_ARTICLE_GROUPS
    });
}

export const GET_ARTICLE_GROUPS_SUCCESS = 'GET_ARTICLE_GROUPS_SUCCESS';
export const requestArticleGroupsSuccess = (json) => dispatch => {
    dispatch({
        type : GET_ARTICLE_GROUPS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_ARTICLE_GROUP_NAME = 'GET_ARTICLE_GROUP_NAME';
export const requestArticleGroupName = (json) => dispatch => {
    dispatch({
        type : GET_ARTICLE_GROUP_NAME,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_SINGLE_ARTICLE_GROUP = 'GET_SINGLE_ARTICLE_GROUP';
export const requestSingleArticleGroup = () => dispatch => {
    dispatch({
        type : GET_SINGLE_ARTICLE_GROUP
    });
}

export const GET_SINGLE_ARTICLE_GROUP_SUCCESS = 'GET_SINGLE_ARTICLE_GROUP_SUCCESS';
export const requestSingleArticleGroupSuccess = (json) => dispatch => {
    dispatch({
        type : GET_SINGLE_ARTICLE_GROUP_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function fetchAllArticleGroups() {

    return function(dispatch) {

        dispatch(requestArticleGroups())

        return API.get('Core', '/api/v1/articles')
            .then(response => {
                    dispatch(requestArticleGroupsSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchSingleArticleGroup(ArticleGroupId) {

    return function(dispatch) {

        dispatch(requestSingleArticleGroup())

        return API.get('Core', '/api/v1/articles/' + ArticleGroupId)
            .then(response => {
                    dispatch(requestSingleArticleGroupSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

// -------------------------------- Articles --------------------------------
export const GET_ARTICLES = 'GET_ARTICLES';
export const requestArticles = () => dispatch => {
    dispatch({
        type : GET_ARTICLES
    });
}

export const GET_ARTICLES_SUCCESS = 'GET_ARTICLE_SUCCESS';
export const requestArticlesSuccess = (json) => dispatch => {
    dispatch({
        type : GET_ARTICLES_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_ALL_ARTICLES = 'GET_ALL_ARTICLES';
export const requestAllArticles = () => dispatch => {
    dispatch({
        type : GET_ALL_ARTICLES
    });
}

export const GET_ALL_ARTICLES_SUCCESS = 'GET_ALL_ARTICLES_SUCCESS';
export const requestAllArticlesSuccess = (json) => dispatch => {
    dispatch({
        type : GET_ALL_ARTICLES_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_SINGLE_ARTICLE = 'GET_SINGLE_ARTICLE';
export const requestSingleArticle = () => dispatch => {
    dispatch({
        type : GET_SINGLE_ARTICLE
    });
}

export const GET_SINGLE_ARTICLE_SUCCESS = 'GET_SINGLE_ARTICLE_SUCCESS';
export const requestSingleArticleSuccess = (json) => dispatch => {
    dispatch({
        type : GET_SINGLE_ARTICLE_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}


export function fetchAllArticles(articleGroupId) {

    return function(dispatch) {

        dispatch(requestArticles())
        if(articleGroupId !== undefined){
            return API.get('Core', '/api/v1/articles/' + articleGroupId + "/article")
                .then(response => {
                        dispatch(requestArticlesSuccess(response))
                    },
                    error => {
                        console.log('An error occurred.', error)
                    }
                );
        }
    }
}

export function fetchFullArticleList() {

    return function(dispatch) {
        dispatch(requestAllArticles())
        return API.get('Core', '/api/v1/articles/all')
            .then(response => {
                    dispatch(requestAllArticlesSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchSingleArticle(ArticleId) {

    return function(dispatch) {

        dispatch(requestSingleArticle())

        //need to change
        return API.get('Core', '/api/v1/articles/' + ArticleId)
            .then(response => {
                    dispatch(requestSingleArticleSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}


