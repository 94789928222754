import React from "react"

import {
    AssetConversationParticipantPatient, AssetConversationParticipantDoctor,
    AssetConversationParticipantMedic, AssetConversationParticipantInterpreter,
    AssetConversationParticipantOther, AssetProviderRotationCellActivity
} from "@/icons/Assets"

function ProviderRotationCell(props){
  const { providerStatus } = props
  switch(providerStatus) {
    case 'clockedIn':
      return (
          <div className="tableCell providerRotationCell clockedIn">
            <ProviderRotationCellProfilePicture providerType={props.providerType} />
            <div className="cellBody">
              <div className="cellTitle">{props.providerName}</div>
              <div className="cellSubtitle">started work {props.providerStarted}</div>
            </div>

            <div className="activityStatus">
              <AssetProviderRotationCellActivity className="statusIcon" />
              <div className="statusTitle">{props.providerActivity}</div>
            </div>
          </div>
        )
    case 'clockedOut':
      return (
          <div className="tableCell providerRotationCell clockedOut">
            <ProviderRotationCellProfilePicture providerType={props.providerType} />
            <div className="cellBody">
              <div className="cellTitle">{props.providerName}</div>
                {props.providerFinished != null ? <div className="cellSubtitle">finished work {props.providerFinished}</div> : null}
            </div>

            {/*<div className="activityStatus">*/}
            {/*  <AssetProviderRotationCellActivity className="statusIcon" />*/}
            {/*  <div className="statusTitle">{props.providerActivity}</div>*/}
            {/*</div>*/}
          </div>
        )
    case 'active':
      return (
          <div className="tableCell providerRotationCell active">
            <ProviderRotationCellProfilePicture providerType={props.providerType} />
            <div className="cellBody">
              <div className="cellTitle">{props.providerName}</div>
              <div className="cellSubtitle">started work {props.providerStarted}</div>
            </div>

            <div className="activityStatus">
              <AssetProviderRotationCellActivity className="statusIcon" />
              <div className="statusTitle">Active Now</div>
            </div>
          </div>
        )
    default:
      return (
          <div className="tableCell providerRotationCell clockedOut">
            <ProviderRotationCellProfilePicture providerType={props.providerType} />
            <div className="cellBody">
              <div className="cellTitle">{props.providerName}</div>
              <div className="cellSubtitle">finished work {props.providerFinished}</div>
            </div>

            <div className="activityStatus">
              <AssetProviderRotationCellActivity className="statusIcon" />
              <div className="statusTitle">{props.providerActivity}</div>
            </div>
          </div>
        )
  }
}

function ProviderRotationCellProfilePicture(props){
  const { providerType } = props
  switch(providerType) {
    case 'doctor':
      return (
          <AssetConversationParticipantDoctor className="providerProfilePicutre" />
        )
    case 'medic':
      return (
          <AssetConversationParticipantMedic className="providerProfilePicutre" />
        )
    case 'interpreter':
      return (
          <AssetConversationParticipantInterpreter className="providerProfilePicutre" />
        )
    default:
      return (
          <AssetConversationParticipantOther className="providerProfilePicutre" />
        )
  }
}

export default ProviderRotationCell