import React, { createRef, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getLocalDateStringFromUTC } from '@/common/LocalTimeStamp';

export default function Addendums(props) {

    const [creationFormActive, setCreationFormActive] = useState(false);
    const [addendums, setAddendums] = useState([]);
    const [currentAddendum, setCurrentAddendum] = useState('');

    let saveAddendum = () => {

        if(currentAddendum?.length > 0 && props.updateAddendum)
        {
            props.updateAddendum(currentAddendum);
            setCurrentAddendum('');
            setCreationFormActive(false);
        }
    } 

    let getAddendums = () => {
        API.get('Core', `/api/v1/conversation/${props.conversationId}/addendum`)
        .then(addendumsQueryResponse => {
            setAddendums(addendumsQueryResponse);
        });
    }

    useEffect(getAddendums, []);

    useEffect(() => {
        if(!props.addendumsToBeSaved?.length )
        {
            getAddendums();
        }
    }, [props.addendumsToBeSaved])

    return (
        <div className="tableSection sectionMatches">


            {!creationFormActive && <section className='tableSection newAddendumButtonContainer'>
                <div className={`button confirm medium newAddendumButton`} onClick={() => setCreationFormActive(true)}>
                    <div className="title">
                        Create addendum
                    </div>
                </div>
            </section>}

            {creationFormActive && <section className='tableSection newAddendumButtonSection'>
                <div className='addendumTextInputContainer'>
                    <textarea className='addendumTextInput' onChange={(event) => setCurrentAddendum(event.target.value)} placeholder='Addendum text' />
                </div>

                <div className='createAddendumButtonsContainer'>
                <div className={`button confirm medium newAddendumButton ${currentAddendum?.length > 0 ? "confirm" : "disabled"}`} onClick={saveAddendum}>
                    <div className="title">
                        Sign and Save Addendum 
                    </div>
                </div>
                </div>
            </section>}

            <section className='tableSection addendumsDisplay'>
                {(props.addendumsToBeSaved?.length > 0 ? addendums.concat(props.addendumsToBeSaved): addendums)
                 .map((a, i) => {
                    return (<Accordion key={`addendumDisplay${i}`} className='tableCell addendumAccordion' style={{height: 'fit-content', width: '100%'}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={'addendumAccordionSummary'}>
                            <Typography>{a.signerFullName} - {getLocalDateStringFromUTC(a.signedAt, "MM/DD/YYYY, h:mm:ss a")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{width: '100%'}}>
                            <Typography className='adddendumDetailText' style={{overflowWrap: 'break-word', width: '100%'}}>{a.addendumText}</Typography>
                        </AccordionDetails>
                    </Accordion>);
                })}
            </section>

        </div>
    )
}