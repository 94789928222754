import React, { useEffect, useRef, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { Card, CardActions, CardContent, Link, Typography } from "@material-ui/core";
import { API } from "aws-amplify";
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import axios from "axios";

import { GetDz } from '@/common/Dropzone';
import { canCadEncounterFeature, canRMSCaseEncounterFeature } from '@/shared/access';

const encounterTypesOptions = [
    { value: "CAD", label: "CAD" },
    { value: "RMS_CASE", label: "RMS CASE" }
];

const dateTimeFormatOptions = [
    { value: "", label: "Auto" },
    { value: "M/d/yyyy HH:mm:ss", label: "M/d/yyyy HH:mm:ss" },
    { value: "MM/dd/yyyy HH:mm:ss", label: "MM/dd/yyyy HH:mm:ss" },
    { value: "yyyy-MM-dd HH:mm:ss", label: "yyyy-MM-dd HH:mm:ss" },
    { value: "dd/MM/yyyy HH:mm:ss", label: "dd/MM/yyyy HH:mm:ss" },
    { value: "M/d/yyyy", label: "M/d/yyyy" },
    { value: "MM/dd/yyyy", label: "MM/dd/yyyy" },
    { value: "yyyy-MM-dd", label: "yyyy-MM-dd" },
    { value: "dd/MM/yyyy", label: "dd/MM/yyyy" },
];

const LANDING_PAGE_SCREEN = Symbol("Landing Screen");
const UPLOADER_SCREEN = Symbol("Uploader Screen");
const SELECTION_SCREEN = Symbol("Selection Screen");

const DZ_KEY = "DZ_ENCOUNTER_CONFIGURATION_INPUT";

const uploaderAcceptFiles = [".csv", ".xls", ".xlsx"];

export default function FieldMappingIndex() {

    const [currentScreen, setCurrentScreen] = useState(LANDING_PAGE_SCREEN);
    const [fieldMappings, setFieldMappings] = useState([]);
    const [selectedEncounterTypeOption, setSelectedEncounterTypeOption] = useState(null);
    const [targetFields, setTargetFields] = useState([]);
    const [sourceFields, setSourceFields] = useState([]);
    const [dateTimeFormat, setDateTimeFormat] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [missingRequiredTargetFields, setMissingRequiredTargetFields] = useState([]);
    const [showRequiredFieldsMessage, setShowRequiredFieldsMessage] = useState(false);
    const [errorUploader, setErrorUploader] = useState(false);

    const encounterTypes = useRef([]);
    const connector = useRef(null);

    useEffect(() => {
        API.get('Core', `/api/v1/encounter-batch/connector`)
            .then(response => {
                connector.current = response
                encounterTypes.current = response.encounterTypes ? JSON.parse(response.encounterTypes) : [];
                setLoading(false);
            },
                error => {
                    toast(`Could not fetch encounter fields mapping data: ${error}`, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                });
    }, []);

    const isEncounterTypeConfigured = (encounterType) => {
        if (!encounterType) return false;

        const existingEncounterType = encounterTypes.current.find(encountertType => encountertType["EncounterType"] === encounterType)

        return existingEncounterType && existingEncounterType["Mapping"];
    }


    const fieldsMatch = (source, target) => {
        source = source.toLowerCase().replace("-", "").replace("_", "");
        const nestedTarget = target.split(".").map(s => s.toLowerCase());
        const nestedTargetWords = target.split(".").flatMap(s => s.split(/(?=[A-Z])/)).map(s => s.toLowerCase());


        for (const target of nestedTarget) {
            if (target === source) {
                return true;
            }
        }

        for (const target of nestedTargetWords) {
            const last = nestedTarget.indexOf(target) === nestedTarget.length - 1;
            if (last) {
                if (target.includes(source)) {
                    return true;
                }
            } else {
                if (target === source) {
                    return true;
                }
            }

        }

        return false;
    }

    const getPossibleMatches = (headers) => {

        const matched = new Set();

        var fieldMappings = headers.map(header => ({
            source: [header],
            target: ""
        }));

        // check fully equals
        for (const fieldMapping of fieldMappings) {
            for (const targetField of targetFields) {
                if (matched.has(targetField.name)) {
                    continue;
                }

                const source = fieldMapping.source[0].toLowerCase().replace("-", "").replace("_", "")
                if (source === targetField.name.toLowerCase()) {
                    matched.add(targetField.name);
                    fieldMapping.target = targetField.name;
                    fieldMapping.required = targetField.required;
                    fieldMapping.type = targetField.type;
                    break;
                }
            }
        }

        for (const fieldMapping of fieldMappings) {
            if (fieldMapping.target) continue;

            for (const targetField of targetFields) {
                if (matched.has(targetField.name)) {
                    continue;
                }

                if (fieldsMatch(fieldMapping.source[0], targetField.name)) {
                    matched.add(targetField.name);
                    fieldMapping.target = targetField.name;
                    fieldMapping.required = targetField.required;
                    fieldMapping.type = targetField.type;
                    break;
                }
            }
        }

        fieldMappings = fieldMappings.filter(fieldMapping => fieldMapping.target);

        for (const targetField of targetFields) {
            if (targetField.required && !fieldMappings.some(fm => fm.target === targetField.name)) {
                fieldMappings.push({
                    source: [],
                    target: targetField.name,
                    required: targetField.required,
                    type: targetField.type
                })
            }
        }

        return fieldMappings;
    }

    const onChangeSource = (selectedOptions, idx) => {
        setFieldMappings(state => ([
            ...state.slice(0, idx),
            {
                ...state[idx],
                source: selectedOptions ? selectedOptions.map(o => o.value) : [],
            },
            ...state.slice(idx + 1)
        ]));
    }

    const onChangeDateTimeFormat = (selectedOption) => {
        setDateTimeFormat(selectedOption.value);
    }

    useEffect(() => {
        const requiredTargetFields = targetFields.filter(field => field.required).map(field => field.name);
        const missingRequiredTargetFields = requiredTargetFields.filter(field => !fieldMappings.find(mapping => mapping.target === field && mapping.source.length > 0));
        setMissingRequiredTargetFields(missingRequiredTargetFields);
    }, [targetFields, fieldMappings]);

    const saveEncounterTypes = () => {
        if (missingRequiredTargetFields.length > 0) {
            setShowRequiredFieldsMessage(true);
            return;
        } else {
            setShowRequiredFieldsMessage(false);
        }

        const fieldMappingsToSave = fieldMappings.filter(mapping => mapping.source.length > 0);

        const existingEncounterType = encounterTypes.current.find(encountertType => encountertType["EncounterType"] === selectedEncounterTypeOption.value)

        if (existingEncounterType) {
            existingEncounterType["Mapping"] = fieldMappingsToSave;
        } else {
            encounterTypes.current.push({
                "EncounterType": selectedEncounterTypeOption.value,
                "Mapping": fieldMappingsToSave
            });
        }

        existingEncounterType["SourceFields"] = sourceFields
        existingEncounterType["DateTimeFormat"] = dateTimeFormat

        API.post('Core', `/api/v1/encounter-batch/encounter-types`, { body: encounterTypes.current })
            .then(response => {
                toast(`Field Mapping for ${selectedEncounterTypeOption.label} saved successfully`, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                setCurrentScreen(LANDING_PAGE_SCREEN);
                setSelectedFile(null);
                setTargetFields([]);
            },
                error => {
                    toast(`Could not save mapping for ${selectedEncounterTypeOption.label}`, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                });
    };

    const LandingPageContent = () => {
        const [filteredEncounterTypesOptions, setFilteredEncounterTypesOptions] = useState([])

        useEffect(() => {
            let filteredEncounterTypesOptions = encounterTypesOptions;

            if (!canCadEncounterFeature()) {
                filteredEncounterTypesOptions = filteredEncounterTypesOptions.filter(option => option.value !== "CAD");
            }

            if (!canRMSCaseEncounterFeature()) {
                filteredEncounterTypesOptions = filteredEncounterTypesOptions.filter(option => option.value !== "RMS_CASE");
            }

            setFilteredEncounterTypesOptions(filteredEncounterTypesOptions)
        }, [])

        return (
            <Grid container spacing={4}>
                {
                    filteredEncounterTypesOptions.map(option => (
                        <EncounterCard encounterTypeOption={option} key={option.value} />
                    ))
                }
            </Grid>
        )
    }

    const EncounterCard = ({ encounterTypeOption }) => {
        const encounterTypeConfigured = isEncounterTypeConfigured(encounterTypeOption.value);

        const onSelectEncounterType = () => {
            setSelectedEncounterTypeOption(encounterTypeOption)
            setSelectedFile(null);

            API.get('Core', `/api/v1/encounter-batch/${encounterTypeOption.value}/target-fields`)
                .then(response => {
                    const existingEncounterType = encounterTypes.current.find(encountertType => encountertType["EncounterType"] === encounterTypeOption.value)

                    if (existingEncounterType && existingEncounterType["Mapping"]) {
                        setFieldMappings(existingEncounterType["Mapping"])
                    } else {
                        setFieldMappings([])
                    }

                    if (existingEncounterType && existingEncounterType["SourceFields"]) {
                        setSourceFields(existingEncounterType["SourceFields"])
                    } else {
                        setSourceFields([])
                    }

                    if (existingEncounterType && existingEncounterType["DateTimeFormat"]) {
                        setDateTimeFormat(existingEncounterType["DateTimeFormat"])
                    } else {
                        setDateTimeFormat("")
                    }

                    setTargetFields(response);
                    setCurrentScreen(UPLOADER_SCREEN);
                },
                    error => {
                        toast(`Could not fetch target fields data: ${error}`, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                    });


        }

        return (
            <Grid item xs={3} >
                <Card className={`encounterCard ${encounterTypeConfigured && "configured"}`}>
                    <CardContent>
                        <Typography variant="h6" component="div">
                            {encounterTypeOption.label}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button disabled={loading} startIcon={loading ? null : encounterTypeConfigured ? <EditIcon /> : <SettingsIcon />}
                            onClick={onSelectEncounterType}>
                            {loading ? "Loading..." : encounterTypeConfigured ? "Edit Configuration" : "Set Up Configuration"}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        )
    }

    const UploaderContent = ({ selectedFile, error, setError }) => {
        const uploadFile = (key, file) => {
            (async function () {
                try {
                    setSelectedFile(file);
                    setError(false);

                    const s3UploadInfo = await API.post("Core", `/rdb/batch?action=getPreSignedUrl`, {
                        body: { fileName: file.name },
                        headers: {
                            'Authorization': `Bearer ${connector.current.token}`
                        }
                    });

                    await axios.put(s3UploadInfo.presignedUrl, file, {
                        headers: {
                            'Content-Type': 'application/octet-stream'
                        }
                    });

                    const body = {
                        fileName: s3UploadInfo.presignedUrl.substring(0, s3UploadInfo.presignedUrl.indexOf("?"))
                    };

                    const responseParseColumns = await API.post('Core', '/rdb/batch?action=parseColumns', {
                        body: body,
                        headers: {
                            'Authorization': `Bearer ${connector.current.token}`
                        }
                    })
                    setSourceFields(responseParseColumns.columns);
                    setFieldMappings(getPossibleMatches(responseParseColumns.columns));
                } catch (error) {
                    console.error(error)
                    setSelectedFile(null);
                    setError(true);
                    toast.error("Could not parse columns: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER });
                }

            })();
        }

        const onDropRejected = () => {
            setSelectedFile(null);
            setError(true);
        }

        const { getRootProps, getInputProps, open } = GetDz(DZ_KEY, uploadFile, {
            accept: uploaderAcceptFiles,
            multiple: false,
            onDropRejected
        });

        const onClickBrowseFile = (e) => {
            e.preventDefault();
            open();
        }

        return (
            <>
                <div className={`uploaderContainer ${error ? "error" : selectedFile || isEncounterTypeConfigured(selectedEncounterTypeOption?.value) ? "selectedFile" : ""}`} {...getRootProps()} >
                    <input {...getInputProps()} />
                    {
                        selectedFile || isEncounterTypeConfigured(selectedEncounterTypeOption?.value)
                            ? <>
                                <InsertDriveFileIcon fontSize="large" />
                                {
                                    selectedFile &&
                                    <Typography variant="body2">
                                        {selectedFile.name}
                                    </Typography>
                                }

                                <Typography variant="body2">
                                    Drag and Drop or{' '}
                                    <Link className="browseButton" href="#" onClick={onClickBrowseFile} disabled={!targetFields.length}>
                                        Browse
                                    </Link>
                                    {' '}to Replace
                                </Typography>
                            </>
                            : <>
                                <CloudUploadIcon fontSize="large" />
                                {
                                    error &&
                                    <Typography className="errorMessage" variant="body2">
                                        Error Uploading File
                                    </Typography>
                                }

                                <Typography variant="body2">
                                    Drag and Drop your file here or{' '}
                                    <Link className="browseButton" href="#" onClick={onClickBrowseFile} disabled={!targetFields.length}>
                                        Browse
                                    </Link>
                                </Typography>
                            </>


                    }

                    <Typography variant="body2">
                        File Types: CSV, XLS, XLSX
                    </Typography>
                </div>
            </>
        )
    }

    const onClickUnmappedTargetField = (targetField) => {
        setFieldMappings(state => [
            ...state,
            {
                source: [],
                target: targetField.name,
                required: targetField.required,
                type: targetField.type
            }
        ])
    }

    const onClickRemoveMapping = (idx) => {
        setFieldMappings(state => ([
            ...state.slice(0, idx),
            ...state.slice(idx + 1)
        ]));
    }

    const SelectionContent = () => {
        return (
            <>
                {showRequiredFieldsMessage &&
                    <Grid item xs={12} style={{ marginBottom: "10px" }}>
                        <Alert severity="error" style={{ marginBottom: "10px" }}>
                            Please address all errors to save your configuration mapping.
                        </Alert>

                        {missingRequiredTargetFields.length > 0 &&
                            <Alert severity="error" style={{ marginBottom: "10px" }}>
                                The following required target fields map are missing: <b>{missingRequiredTargetFields.join(", ")}</b>
                            </Alert>}
                    </Grid>
                }

                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <div >Date and Time Format</div>

                    <div style={{ width: "300px" }}>
                        <CreatableSelect
                            onChange={selectedOption => onChangeDateTimeFormat(selectedOption)}
                            value={dateTimeFormatOptions.find(option => option.value === dateTimeFormat) || { value: dateTimeFormat, label: dateTimeFormat }}
                            options={dateTimeFormatOptions}
                            placeholder="Select Date and Time Format"
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="unmappedTargetFieldsTitle">Unmapped Target Fields</div>

                    <div className="unmappedTargetFieldsContainer">
                        {targetFields
                            .filter(tf => !tf.required && !fieldMappings.some(fm => fm.target === tf.name))
                            .map((unmmapedTargetField, idx) => (
                                <div className="unmappedTargetField" key={idx} onClick={() => onClickUnmappedTargetField(unmmapedTargetField)}>
                                    {unmmapedTargetField.name}
                                </div>
                            ))}
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Table className="selectionTable">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: "40%" }} >
                                    Target
                                </TableCell>
                                <TableCell style={{ width: "60%" }} >
                                    Source
                                </TableCell>
                                <TableCell style={{ width: "10%" }} >
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fieldMappings.map((mapping, idx) => {
                                const sourceOptions = [
                                    ...sourceFields.map(sourceField => ({ value: sourceField, label: sourceField }))
                                ];

                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                                        <TableCell>
                                            {mapping.target}
                                        </TableCell>
                                        <TableCell>
                                            <Select classNamePrefix="sourceDropdown"
                                                onChange={selectedOptions => onChangeSource(selectedOptions, idx)}
                                                value={mapping.source.map(s => sourceOptions.find(option => option.value === s))}
                                                options={sourceOptions}
                                                isMulti
                                                placeholder="Select Source"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {
                                                !mapping.required ?
                                                    <div className={"removeButton"} onClick={() => onClickRemoveMapping(idx)}>
                                                        <div className="icon" />
                                                    </div> : null
                                            }

                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            </>
        )
    }

    const WizardButtons = () => {
        const onClickBack = () => {
            switch (currentScreen) {
                case UPLOADER_SCREEN:
                    setSelectedFile(null);
                    setErrorUploader(false);
                    setTargetFields([]);
                    setCurrentScreen(LANDING_PAGE_SCREEN);
                    break;
                case SELECTION_SCREEN:
                    setCurrentScreen(UPLOADER_SCREEN);
                    break;
                default:
                    break;
            }
        }

        const onClickContinue = () => {
            switch (currentScreen) {
                case UPLOADER_SCREEN:
                    setCurrentScreen(SELECTION_SCREEN);
                    break;
                default:
                    break;
            }
        }

        const isContinueEnabled = () => {
            switch (currentScreen) {
                case UPLOADER_SCREEN:
                    return !errorUploader && (!!selectedFile || isEncounterTypeConfigured(selectedEncounterTypeOption.value));
                default:
                    return true;
            }
        }

        const BackButton = () => (
            <Button variant="outlined" color="primary" onClick={onClickBack}>
                Back
            </Button>
        )

        const ContinueButton = () => (
            <Button variant="contained" color="primary" onClick={onClickContinue} disabled={!isContinueEnabled()}>
                Continue
            </Button>
        )

        const SaveButton = () => (
            <Button variant="contained" color="primary" disabled={!selectedEncounterTypeOption || !fieldMappings.length}
                onClick={saveEncounterTypes}>
                Save
            </Button>
        )

        if (currentScreen === LANDING_PAGE_SCREEN) {
            return null;
        }

        return (
            <div className="wizardButtons">
                <BackButton />
                {
                    currentScreen === UPLOADER_SCREEN ? <ContinueButton /> :
                        currentScreen === SELECTION_SCREEN ? <SaveButton /> : null
                }
            </div>
        )
    }

    return (
        <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs={9}>
                <Typography variant="h6" style={{ color: "#8a8a8a" }}>
                    Encounter Configuration Settings
                </Typography>
            </Grid>

            <Grid item xs={3}>
                <WizardButtons />
            </Grid>

            <div style={{ padding: "10px", width: "100%" }}>
                {
                    currentScreen === LANDING_PAGE_SCREEN ? <LandingPageContent />
                        : currentScreen === UPLOADER_SCREEN ? <UploaderContent selectedFile={selectedFile}
                            error={errorUploader} setError={setErrorUploader} />
                            : currentScreen === SELECTION_SCREEN ? <SelectionContent />
                                : null
                }
            </div>
        </Grid>
    )
}