import React from 'react';
import MapGL, { Marker } from "react-map-gl";
import _ from "lodash";
import {Auth} from "aws-amplify";

import ProfilePicture from "@/common/ProfilePicture";
import moment from '@/common/momentConfig';
import MessageAttachment from "./MessageAttachment";
import Pin from "@/routes/location/Pin";
import { deliveryStatusSort, deliveryStatusSortOrder } from "@/utils/utils";
import {getCurrentUser, isNewVideoCallEnabled} from "@/shared/access";

function ConversationMessages(props) {
    const { messages, loading, height, mapboxApiAccessToken } = props;
    const [activeVideoChats, setActiveVideoChats] = React.useState({});

    const openVideo = async (videoUrl, e) => {
        if(isNewVideoCallEnabled()) {
            e.preventDefault();
            let sessionToken = (await Auth.currentSession()).accessToken.jwtToken;
            videoUrl = `${videoUrl}/access-token/${sessionToken}`
            let w = window.open(videoUrl, '_blank', 'width=800,height=800');
            w.addEventListener("beforeunload", function (v) {
                activeVideoChats[v] = false;
                setActiveVideoChats(Object.assign({}, activeVideoChats));
            }.bind(this, videoUrl))
            activeVideoChats[videoUrl] = w;
            setActiveVideoChats(Object.assign({}, activeVideoChats));
        } else {
            e.preventDefault();
            let w = window.open(videoUrl, videoUrl, 'width=800,height=800');
            w.addEventListener("beforeunload", function (v) {
                activeVideoChats[v] = false;
                setActiveVideoChats(Object.assign({}, activeVideoChats));
            }.bind(this, videoUrl))
            activeVideoChats[videoUrl] = w;
            setActiveVideoChats(Object.assign({}, activeVideoChats));
        }
    }

    const isVideoChatOpen = (videoUrl) => {
        return activeVideoChats[videoUrl] && !activeVideoChats[videoUrl].closed;
    }

    const focusVideo = (videoUrl) => {
        if (isVideoChatOpen(videoUrl)) {
            activeVideoChats[videoUrl].focus();
        }
    }

    let scrollEnd = React.createRef();

    React.useEffect(() => {
        if (scrollEnd.current) {
            scrollEnd.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    if (loading) {
        return (<div className="messages loading"></div>);
    }

    let currentUser = getCurrentUser();

    return (
        <div className="messagesList fsMask" style={{ height: height }}>
            {
                _.map(_.sortBy(messages, function (m) { return m.createdAt }), function (message, messageIdx) {
                    let hasAttachmentsClass = "";
                    message.deliveryStatuses.map(item => ({ ...item, sortStatus: deliveryStatusSortOrder.includes(item.status) ? item.status : 'unknown' }))
                    let recentStatus = deliveryStatusSort({ data: message.deliveryStatuses, sortField: 'status' })[0];
                    let time = moment(moment.utc(message.createdAt)).local().calendar();
                    let attachments = null;
                    if (message.attachments) {
                        attachments = (
                            <div className="messageAttachments fsExclude" key={messageIdx}>
                                {
                                    _.map(message.attachments, function (att, idx) {
                                        hasAttachmentsClass = "hasAttachments";
                                        return (
                                            <MessageAttachment file={att} key={idx} />
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    let extensions = null;
                    if (message.extensions) {
                        extensions = (
                            <ul>
                                {
                                    _.map(message.extensions, function (ext, jdx) {
                                        // eslint-disable-next-line default-case
                                        switch (ext.type) {
                                            case "VIDEO_CALL":

                                                if(isNewVideoCallEnabled()) {
                                                    let videoUrl = `/video-app-player/${ext.uniqueName}`;
                                                    if (isVideoChatOpen(videoUrl)) {
                                                        return (
                                                            <div onClick={focusVideo.bind(this, videoUrl)} className="attachment compact videoCall active" key={jdx}>
                                                                <div className="icon"></div>
                                                                <div className="attachmentDetails">
                                                                    <div className="attachmentTitle">Video Call</div>
                                                                    <div className="attachmentSubtitle">Call is active</div>
                                                                </div>

                                                                <div className="attachmentAction">
                                                                    <div className="title">Return</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <a target="_blank" onClick={openVideo.bind(this, videoUrl)} href={videoUrl} className="attachment compact videoCall" key={jdx}>
                                                            <div className="icon"></div>
                                                            <div className="attachmentDetails">
                                                                <div className="attachmentTitle">Video Call</div>
                                                                <div className="attachmentSubtitle">Be the first one to join!</div>
                                                            </div>

                                                            <div className="attachmentAction">
                                                                <div className="title">Join</div>
                                                            </div>
                                                        </a>
                                                    );
                                                } else {
                                                    let videoUrl = `/video-chat/${ext.uniqueName}`;
                                                    if (isVideoChatOpen(videoUrl)) {
                                                        return (
                                                            <div onClick={focusVideo.bind(this, videoUrl)} className="attachment compact videoCall active" key={jdx}>
                                                                <div className="icon"></div>
                                                                <div className="attachmentDetails">
                                                                    <div className="attachmentTitle">Video Call</div>
                                                                    <div className="attachmentSubtitle">Call is active</div>
                                                                </div>

                                                                <div className="attachmentAction">
                                                                    <div className="title">Return</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <a target="_blank" onClick={openVideo.bind(this, videoUrl)} href={videoUrl} className="attachment compact videoCall" key={jdx}>
                                                            <div className="icon"></div>
                                                            <div className="attachmentDetails">
                                                                <div className="attachmentTitle">Video Call</div>
                                                                <div className="attachmentSubtitle">Be the first one to join!</div>
                                                            </div>

                                                            <div className="attachmentAction">
                                                                <div className="title">Join</div>
                                                            </div>
                                                        </a>
                                                    );
                                                }
                                            case "LOCATION":
                                                if (ext.latitude !== null && message.user.userType === "CLIENT") {
                                                    const viewport = {
                                                        width: 750,
                                                        height: 750,
                                                        latitude: ext.latitude,
                                                        longitude: ext.longitude,
                                                        zoom: 14
                                                    };
                                                    const marker = {
                                                        latitude: ext.latitude,
                                                        longitude: ext.longitude
                                                    };
                                                    return (
                                                        <div className="attachment compact requestLocationFilled" key={jdx}>
                                                            <MapGL
                                                                {...viewport}
                                                                mapStyle="mapbox://styles/mapbox/outdoors-v11"
                                                                width="100%"
                                                                height="100%"
                                                                mapboxApiAccessToken={mapboxApiAccessToken}>
                                                                <Marker
                                                                    longitude={marker.longitude}
                                                                    latitude={marker.latitude}
                                                                    offsetTop={-20}
                                                                    offsetLeft={-10}
                                                                >
                                                                    <Pin size={20} />
                                                                </Marker>
                                                                {/*<div className="nav" style={navStyle}>*/}
                                                                {/*    <NavigationControl/>*/}
                                                                {/*</div>*/}
                                                            </MapGL>
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div className="attachment compact requestLocationUnfulfilled" key={jdx}>
                                                            <div className="icon">
                                                                <div className="glyph" />
                                                            </div>

                                                            <div className="attachmentDetails">
                                                                <div className="attachmentTitle">Location Requested</div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            case "SERVICE_OFFERING":
                                                return (
                                                    <div className="attachment compact serviceOffering" key={jdx}>
                                                        <div className="icon">
                                                            <div className="glyph" />
                                                        </div>

                                                        <div className="attachmentDetails">
                                                            <div className="attachmentTitle">{ext.name}</div>
                                                        </div>
                                                    </div>
                                                )
                                            case "REFERRAL":
                                                return (
                                                    <div className="attachment compact serviceOffering" key={jdx}>
                                                        <div className="icon">
                                                            <div className="glyph" />
                                                        </div>

                                                        <div className="attachmentDetails">
                                                            <div className="attachmentTitle">{ext.referralProvider.name}</div>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    })
                                }
                            </ul>
                        );
                    }
                    if (message.isBot) {
                        return (
                            <div className={`messageCell bot ${hasAttachmentsClass}`} key={messageIdx}>
                                <div className="profilePicture">
                                    <div className="icon" />
                                </div>
                                <div className="messageContainer">
                                    <div className="messageBubble">
                                        <div className="attributes">
                                            <div className="timestamp">{time}</div>
                                        </div>
                                        <div className="text">{message.message}</div>
                                    </div>
                                    {attachments}
                                    {extensions}
                                </div>
                            </div>
                        )
                    }
                    else if (message.user.userType === "PROVIDER" && message.user.id === currentUser.id) {
                        return (
                            <div className={`messageCell business ${hasAttachmentsClass}`} key={messageIdx}>
                                <ProfilePicture user={message.user} size={36} />
                                <div className="messageContainer">
                                    <div className="messageBubble">
                                        <div className="attributes">
                                            <div className="timestamp">{time}</div>
                                            <div className="source">{message.user.readableName}</div>
                                        </div>
                                        <div className="text">{message.message}</div>
                                    </div>
                                    {attachments}
                                    {extensions}
                                    {recentStatus ? <div className="deliveryStatus">{recentStatus.status}</div> : <div className="deliveryStatus"></div>}
                                </div>
                            </div>)
                    }
                    return (
                        <div className={`messageCell client ${hasAttachmentsClass}`} key={messageIdx}>
                            <ProfilePicture user={message.user} size={36} />
                            <div className="messageContainer">
                                <div className="messageBubble">
                                    <div className="attributes">
                                        <div className="timestamp">{time}</div>
                                        {/*<div className="source">via {message.type == "EMAIL" ? "Email" : "Text"}</div>*/}
                                        <div className="source">{message.user.readableName}</div>
                                    </div>
                                    <div className="text">{message.message}</div>
                                </div>
                                {attachments}
                                {extensions}
                            </div>
                        </div>)
                })
            }
            <div style={{ float: "left", clear: "both" }}
                ref={scrollEnd}>
            </div>
        </div>
    )
}

export default ConversationMessages;