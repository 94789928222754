import React from "react"
import _ from "lodash";

import SimpleEncounterField from "./SimpleEncounterField";

/**
 *
 * @param revision - Sample structure { commitId: "1234.0", commitDate: [date], encounterRevision: [encounterTypeDto], changes: [array of changes]}
 * @param schema
 * @param props
 * @constructor
 */
export default function GenericEncounterDiff({revision, ...props}) {

    return (
        <div className="contentView tableView encounterDetails">
            <div className="tableSection attributes">
                <div className={"sectionTitle"}>Encounter Details</div>
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Caller Number"}
                    fieldPath={"contact.phone"}/>

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Status"}
                    fieldPath={"status"}/>

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident Number"}
                    fieldPath={"incidentNumber"}/>

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Encounter Date"}
                    isDate={true}
                    fieldPath={"encounterDate"}/>

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Agency"}
                    fieldPath={"agency"}/>

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Agency Type"}
                    fieldPath={"agencyType"}/>

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident Type"}
                    fieldPath={"incidentType"}/>

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident Type 2"}
                    fieldPath={"incidentType2"}/>

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident SubType"}
                    fieldPath={"incidentSubtype"}/>

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Disposition"}
                    fieldPath={"disposition"}/>
            </div>
            <div className="tableSection attributes">
                <div className={"sectionTitle"}>Location Details</div>
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Address"}
                    fieldPath={"encounterLocation.summary"}/>
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Latitude"}
                    fieldPath={"encounterLocation.latitude"}/>
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Longitude"}
                    fieldPath={"encounterLocation.longitude"}/>
            </div>
        </div>
    )
}