import React from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";

import Modal from "@/common/Modal";
import AddEditRole from "@/components/AddEditRole";
import { fetchUserMe, fetchRoles, fetchSystemPrivileges } from "@/shared/user/actions";

const mapStateToProps = state => ({
    user: state.user,
})

const mapDispatchToProps = dispatch => ({
    getRoles: () => dispatch(fetchRoles()),
    getSystemPrivileges: () => dispatch(fetchSystemPrivileges()),
    reloadUser: () => dispatch(fetchUserMe())
})
const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const columns = [
    { id: 'name', label: 'Role Name', minWidth: 170 },
    { id: 'privileges', label: 'Privileges', minWidth: 300 }
];

function RoleIndex(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState({});

    React.useEffect(() => {
        props.getRoles();
        props.getSystemPrivileges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSuccess = () => {
        setOpen(false);
        props.getRoles();
    }

    const handleEditOpen = (roleId) => {
        editOpen[roleId] = true;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditClose = (roleId) => {
        editOpen[roleId] = false;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditSuccess = (roleId) => {
        editOpen[roleId] = false;
        setEditOpen(Object.assign({}, editOpen));
        props.getRoles();
        props.reloadUser();
    }


    let privsFlat = {};
    _.forEach(props.user.systemPrivileges, function (group, k) {
        _.forEach(group.groupPrivileges, function (priv, l) {
            privsFlat[priv.sysPrivilegeId] = priv;
        })
    })

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Modal
                    button={(<Button variant="contained" color="primary" className={classes.button}>
                        Add Role
                    </Button>)}
                    content={(<AddEditRole
                        systemPrivileges={props.user.systemPrivileges}
                        onCancel={handleClose}
                        onSuccess={handleSuccess}
                    />)}
                    title="Add Role"
                    size="small"
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    open={open}
                />
            </Grid>
            <Grid xs={12}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(_.sortBy(props.user.roles, function (r) { return r.name; }), row => {

                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns.map(column => {
                                        const value = row[column.id];
                                        if (column.id === 'privileges') {
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    <ul>
                                                        {
                                                            _.map(_.sortBy(value, function (v) { return v; }), function (priv, k) {
                                                                console.log(privsFlat)
                                                                let sysPriv = privsFlat[priv.privilegeId];
                                                                if (sysPriv == undefined )
                                                                    return ""
                                                                return (
                                                                    <li>{sysPriv.name}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </TableCell>
                                            )
                                        }
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key='edit' >
                                        <Modal
                                            button={(
                                                <Button variant="contained" color="primary" className={classes.button}>
                                                    Edit Role
                                                </Button>)}
                                            content={(<AddEditRole
                                                systemPrivileges={props.user.systemPrivileges}
                                                onCancel={handleEditClose.bind(this, row.id)}
                                                onSuccess={handleEditSuccess.bind(this, row.id)}
                                                existingRole={row}
                                            />)}
                                            title="Edit Role"
                                            size="small"
                                            handleClose={handleEditClose.bind(this, row.id)}
                                            handleOpen={handleEditOpen.bind(this, row.id)}
                                            open={editOpen[row.id]}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleIndex);
