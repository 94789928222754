import { API } from "aws-amplify";
import { toast } from "react-toastify";
import React from "react";
import Select from "react-select";

export default function ShiftSelect({ onSelect, value }) {
  const [shifts, setShifts] = React.useState([]);

  const loadShifts = () => {
    API.get("Core", "/api/v1/shift").then(
      (response) => {
        setShifts(response);
      },
      (error) => {
        toast("Could not get shifts: " + error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.ERROR,
        });
      }
    );
  };

  React.useEffect(() => {
    loadShifts();
  }, []);

  const onShiftSelect = (obj, action) => {
    switch (action.action) {
      case "select-option":
        onSelect(obj);
        break;
      case "clear":
        onSelect(null);
        break;
    }
  };

  if (!shifts || shifts.length == 0) {
    return <span>No Shifts</span>;
  }

  return (
    <Select
      className="selectCell"
      classNamePrefix="selectCell"
      isDisabled={false}
      onChange={onShiftSelect}
      value={value ? value : "--"}
      options={shifts}
      isClearable={true}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
    />
  );
}
