import React from "react";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import _ from "lodash";

import ConfirmModal from "@/common/ConfirmModal";
import {getDateString, getLocalDateStringFromUTC} from '@/common/LocalTimeStamp';
import PhoneNumberDisplay from "@/common/PhoneNumberDisplay";
import { AssetActivityConsortiumIndicator } from "@/icons/Assets";
import SurveyResults from "@/routes/survey/SurveyResults";
import { fetchSentTriggers } from "@/shared/rules/actions";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getSentTriggers: (page, search) => dispatch(fetchSentTriggers(page, search)),
})

function ActivityMatchedRules(props) {
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [searchFocused, setSearchFocused] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState("");
    const searchRef = React.createRef();

    React.useEffect(() => {
        props.getSentTriggers(page, search);
    }, []);

    const loadMore = () => {
        let nextPage = page + 1;
        setPage(nextPage);
        props.getSentTriggers(nextPage, search);
    }

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            let searchParam = searchRef.current.value;
            setSearch(searchParam);
            loadFirstPage(searchParam);
        }
    }

    const clearSearch = () => {
        setSearch("");
        setSearchInput("");
        loadFirstPage("");
    }

    const loadFirstPage = (searchParam) => {
        setPage(0);
        props.getSentTriggers(0, searchParam);
    }

    const onSearchFocused = () => {
        setSearchFocused(true);
    }

    const onSearchBlurred = () => {
        setSearchFocused(false);
    }

    const onSearchChange = (e) => {
        setSearchInput(searchRef.current.value);
    }

    const cancelScheduled = (queuedTrigger) => {
        API.post('Core', '/api/v1/rule/trigger/cancel/' + queuedTrigger.id)
            .then(response => {
                props.getSentTriggers(page);
            },
                error => {
                    toast("Could not cancel rule target: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    let triggerList = props.rules.sentTriggers ? props.rules.sentTriggers : null;
    if (search && props.rules.sentTriggersPageInfo) {
        triggerList = props.rules.sentTriggersPageInfo.content;
    }

    return (
        <div>
            <div className="secondaryNavBar">
                <div className="navTitle">
                    <div className="viewName">Matched Rules</div>
                    <div className="counterBadge">
                        <div className="counterValue">{props.rules.sentTriggersPageInfo ? props.rules.sentTriggersPageInfo.totalElements : ''}</div>
                    </div>
                </div>
                <div className={"navActions"}>
                    <div className={`searchInput ${searchFocused ? "isFocused" : ""} ${(searchInput.length > 0) ? "hasInput" : ""}`}>
                        <div className="icon" />
                        <input value={searchInput} onChange={onSearchChange} placeholder={"Search Matched Rules"} onFocus={onSearchFocused} onBlur={onSearchBlurred} className="input" onKeyDown={keyPress} type="text" ref={searchRef} />
                        <div className="clear" onClick={clearSearch}></div>
                    </div>
                </div>
            </div>

            <div className="activitySection sectionSentSurveys">
                <div className="detailedEncountersList">
                    <div className="detailedEncountersCells">

                        {
                            (props.rules.sentTriggersPageInfo && !props.rules.sentTriggersPageInfo.last) ?
                                (
                                    <div onClick={loadMore} className="encountersExpander">
                                        <div className="expanderButton">
                                            <div className="title">Show More</div>
                                            <div className="icon" />
                                        </div>
                                    </div>
                                ) : null
                        }

                        <TransitionGroup className="encounter-list">
                            {
                                (triggerList) ? _.map(_.reverse(_.sortBy(triggerList, function (t) {
                                    return t.createdAt
                                })), function (t) {
                                    let encounter = t.encounter;
                                    let unitNames = encounter.units ? _.map(encounter.units, function (u) {
                                        return u.unitId;
                                    }) : [];
                                    
                                    return (
                                        <CSSTransition
                                            key={t.id}
                                            timeout={5000}
                                            classNames="encounter"
                                        >
                                            <div className="detailedEncounterCell tableCell">
                                                <div className="encounterCellBody">
                                                    {
                                                        encounter.testEncounter ? (
                                                            <div className="bodyColumn columnTestStatus">
                                                                <div className="testIndicator">
                                                                    <div className="indicatorTitle">TEST</div>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }

                                                    {t.matchingRule.tenantId != props.user.aboutMe.currentTenant.id ? (
                                                        <div className="bodyColumn columnConsortium">
                                                            <div className="consortiumIndicator">
                                                                <AssetActivityConsortiumIndicator className="indicatorIcon" />
                                                                {/*<div className="indicatorTitle">{props.user.aboutMe.currentTenant.id}</div>*/}
                                                            </div>
                                                        </div>
                                                    ) : null}

                                                    <div className="bodyColumn columnEncounterType">
                                                        <div className="bodyTitle encounterName">{encounter.summary}</div>
                                                        <div className="bodySubtitle encounterAddress">{encounter.encounterLocation != null ? encounter.encounterLocation.summary : ""}</div>
                                                    </div>

                                                    <div className="bodyColumn columnEncounterContact">
                                                        <div className="bodyTitle encounterContactName">{encounter.priority != null ? "Priority " + encounter.priority : ""}</div>
                                                        <div className="bodySubtitle encounterContactPhone"><PhoneNumberDisplay phoneNumber={encounter.contact != null ? encounter.contact.phone : ""} /></div>
                                                    </div>

                                                    <div className="bodyColumn columnEncounterTime">
                                                        <div className="bodyTitle encounterTimeReported">Reported</div>
                                                        <div className="bodySubtitle encounterTimeResponded">
                                                            {
                                                                encounter.source && encounter.source.utc 
                                                                ? getLocalDateStringFromUTC(encounter.encounterDate, "MM/DD/YYYY, h:mm:ss a")
                                                                : getDateString(encounter.encounterDate, "MM/DD/YYYY, h:mm:ss a")
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="bodyColumn columnEncounterResponder">
                                                        <div className="bodyTitle encounterResponderType">Units</div>
                                                        <div className="bodySubtitle encounterResponderType">{unitNames.join(", ")}</div>
                                                    </div>
                                                </div>

                                                <div className="encounterCellDetail">
                                                    <div className="encounterTrigger">
                                                        <div className="triggerItem triggerRule">
                                                            <div className="title">{t.matchingRule.name}</div>
                                                        </div>

                                                        {
                                                            (t.surveyInstance) ? (
                                                                <div className="triggerItem arrow">
                                                                    <div className="title">→</div>
                                                                </div>
                                                            ) : null
                                                        }

                                                        {
                                                            (t.surveyInstance) ? (
                                                                <div className="triggerItem triggerTarget">
                                                                    <SurveyResults surveyInstanceId={t.surveyInstance.id} onChange={null}>
                                                                        <div className="title">{t.surveyInstance.surveyName}</div>
                                                                    </SurveyResults>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </div>

                                                    <div className="encounterTargetStatus">
                                                        <div className="targetStatus statusDelivery deliveryDelivered">
                                                            <div className="title">{t.status}</div>
                                                        </div>
                                                        {(t.surveyInstance) ? (
                                                            <div className="targetStatus statusCompletion completionAwaiting">
                                                                <div className="title">{t.surveyInstance.status}</div>
                                                            </div>
                                                        ) : null
                                                        }
                                                    </div>
                                                    {(t.status == "SCHEDULED") ?
                                                        (
                                                            <div className="encounterActions surveyActions">
                                                                <ConfirmModal
                                                                    title={"Are you sure you want to cancel this trigger?"}
                                                                    body={(
                                                                        <div>
                                                                            This system will mark this to be cancelled. There is no way to undo this.
                                                                        </div>
                                                                    )}
                                                                    confirmTitle={"Confirm"}
                                                                    onConfirm={cancelScheduled.bind(this, t)}
                                                                >
                                                                    <div className="encounterActions actionIgnore button small action">
                                                                        <div className="title">Cancel</div>
                                                                    </div>
                                                                </ConfirmModal>
                                                            </div>
                                                        ) : null}
                                                </div>
                                            </div>
                                        </CSSTransition>
                                    )
                                }) : null
                            }
                        </TransitionGroup>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityMatchedRules);