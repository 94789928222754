import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import _ from "lodash";

import VersionList from "@/components/VersionList";
import { fetchAllSurveys } from "@/shared/survey/actions";
import { fetchSMSSetting } from "@/shared/connect_config/actions";
import { fetchAllTaskTypes } from "@/shared/tasks/actions";
import { fetchAllWorkflows } from '@/shared/workflow/actions';
import { fetchConversationDispositions } from '@/shared/conversation/actions';
import {
    fetchRuleVersions,
    fetchSingleRule
} from "@/shared/rules/actions";

import AddEditRuleVersion from "./AddEditRuleVersion";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getRule: (ruleId) => dispatch(fetchSingleRule(ruleId)),
    getRuleVersions: (ruleId) => dispatch(fetchRuleVersions(ruleId)),
    getSurveys: () => dispatch(fetchAllSurveys()),
    getPhoneNumbers: () => dispatch(fetchSMSSetting()),
    getTaskTypes: () => dispatch(fetchAllTaskTypes()),
    getWorkflows: () => dispatch(fetchAllWorkflows()),
    getDispositions: () => dispatch(fetchConversationDispositions())
})

function RuleVersion(props) {
    const [ruleId, setRuleId] = React.useState(props.match.params.ruleId);
    const [selectedVersionId, setSelectedVersionId] = React.useState(props.match.params.versionId);

    const refreshRule = () => {
        if (ruleId) {
            props.getRule(ruleId);
            props.getRuleVersions(ruleId);
        }
    }

    React.useEffect(() => {
        refreshRule();
        props.getSurveys();
        props.getPhoneNumbers();
        props.getTaskTypes();
        props.getWorkflows();
        props.getDispositions();
    }, []);

    if (!props.rules.activeRule || props.rules.activeRule.id != ruleId || !props.rules.activeRuleVersions) {
        return (<span>Loading...</span>);
    }

    let currentVersionId = selectedVersionId;
    if (!currentVersionId && props.rules.activeRule.activeRuleVersion != null) {
        currentVersionId = props.rules.activeRule.activeRuleVersion.id;
        setSelectedVersionId(currentVersionId);
        props.history.push("/rules/" + ruleId + "/version/" + currentVersionId);
    } else if (!currentVersionId) {
        let versionsSorted = _.sortBy(props.rules.activeRuleVersions, function (v) { return v.version; });
        currentVersionId = versionsSorted[versionsSorted.length - 1].id;
        setSelectedVersionId(currentVersionId);
        props.history.push("/rules/" + ruleId + "/version/" + currentVersionId);
    }

    let currentVersion = _.find(props.rules.activeRuleVersions, function (v) { return v.id == currentVersionId; })
    if (!currentVersion) {
        return (<span>Loading...</span>);
    }

    const setSelectedVersion = (version) => {
        props.history.push("/rules/" + ruleId + "/version/" + version.id);
        setSelectedVersionId(version.id);
    }

    const onUpdate = (action, version) => {
        // eslint-disable-next-line default-case
        switch (action) {
            case "set-active":
                refreshRule();
                break;
            case "version-edit":
                refreshRule();
                break;
            case "version-delete":
                let versionsSorted = _.sortBy(props.rules.activeRuleVersions, function (v) { return v.version; });
                currentVersionId = versionsSorted[0].id;
                setSelectedVersionId(currentVersionId);
                refreshRule();
                break;
            case "new-version":
                refreshRule();
                setSelectedVersionId(version.id);
                break;
        }
    }

    return (
        <div className="rulesView versionsView">
            <div className="versionList tableView">
                <div className="navBar">
                    <div className="title">Edit Rule</div>
                    <div className="separator"></div>
                </div>
                <div className="versionSection tableSection groupedSection">
                    <div className={"cell inputCell"}>
                        <div className={"title"}>Name</div>
                        <div className="input">{props.rules.activeRule.name}</div>
                    </div>
                </div>
                <div className="versionSection tableSection groupedSection">
                    <VersionList selectedVersion={currentVersion} activeVersion={props.rules.activeRule.activeRuleVersion} versions={props.rules.activeRuleVersions} onSelect={setSelectedVersion} />
                </div>
            </div>
            <div className="versionEdit tableView">
                <AddEditRuleVersion
                    numbers={_.get(props, "connectConfig.sms")}
                    surveys={props.survey.surveys}
                    taskTypes={props.tasks.taskTypes}
                    onUpdate={onUpdate}
                    rule={props.rules.activeRule}
                    ruleVersion={currentVersion}
                    dispositions={props.conversation.conversationDispositions}
                    workflows={props.workflow.allWorkflows}
                />
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RuleVersion));