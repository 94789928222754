import {
    GET_CSV_SCHEMA,
    GET_CSV_SCHEMA_SUCCESS, GET_SINGLE_CLIENT_SUCCESS, SEARCH_CLIENTS_SUCCESS
} from './actions'

const initialState = {
    csvSchema: [],
    clientListPage: {},
    singleClients: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CSV_SCHEMA:
            return state;
            break;
        case GET_CSV_SCHEMA_SUCCESS:
            return Object.assign({}, state, {
                csvSchema: action.payload
            });
        case SEARCH_CLIENTS_SUCCESS:
            return Object.assign({}, state, {
                clientListPage: action.payload
            });
        case GET_SINGLE_CLIENT_SUCCESS:
            state.singleClients[action.payload.id] = action.payload;
            return Object.assign({}, state);
        default:
            return state
    }
}