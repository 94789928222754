import React from "react"
import _, { isBoolean } from "lodash"

import {getDateString, getLocalDateStringFromUTC} from "@/common/LocalTimeStamp";

export default function SimpleEncounterField({encounter, fieldName, fieldPath, changes, getFieldValue, isDate, arrayValue}){

    let change = _.find(changes, function(ch){ return ch.propertyName === fieldPath; });

    let val = "";
    
    if(fieldPath && !arrayValue){
        val = _.get(encounter, fieldPath)
    } else if(getFieldValue){
        val = getFieldValue(encounter);
    } else if(fieldPath && arrayValue){
        val = _.get(encounter, fieldPath)
        if(val) {
            val = val.map(function (elem) {
                return elem[arrayValue];
            }).join(", ");
        }
    }

    if(val && isDate){
        if(encounter && encounter.source && encounter.source.utc)
            val = getLocalDateStringFromUTC(val, "MM/DD/YYYY, h:mm:ss a")
        else
            val = getDateString(val, "MM/DD/YYYY, h:mm:ss a")            
    }

    if(change){
        if(isBoolean(val)){
            val = val ? '1' : '0';
        }

        if(isBoolean(change.left)){
            change.left = change.left ? '1' : '0';
        }
        
        return (
            <div className="encounterAttributeCell changed">
                <div className="currentValue">
                    <div className="attributeTitle">{fieldName}</div>
                    <div className="attributeDetail">{val}</div>
                </div>

                <div className="previousValue">
                    <div className="attributeTitle">Previous Value</div>
                    <div className="attributeDetail">{change.left}</div>
                </div>

                <div className="changeIndicator">
                    <div className="indicatorTitle">Changed</div>
                </div>
            </div>
        )
    }

    if(isBoolean(val)){
        val = val ? '1' : '0';
    }

     //console.log(fieldName + ':' + val);
   
    return (
        <div className="encounterAttributeCell">
            <div className="currentValue">
                <div className="attributeTitle">{fieldName}</div>
                <div className="attributeDetail">{val}</div>
            </div>
        </div>
    )
}