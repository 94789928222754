import CurrencyFormat from "react-currency-format";
import React from "react";

export default function PhoneNumberDisplay({ phoneNumber }) {
    if (!phoneNumber) {
        return null;
    }
    return (
        <CurrencyFormat displayType={"text"} value={phoneNumber} format="+# (###) ###-####" mask="_" />
    )
}