import React from 'react';
import {useDispatch} from 'react-redux';
import _ from "lodash";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import {ContentState, Editor, EditorState} from "draft-js";
import {ReactSortable} from "react-sortablejs";

import Modal from "@/common/Modal";
import {Button} from "@/components/Buttons";
import {isLanguageSupportEnable} from "@/shared/access";

import EditQuestion from "./EditQuestion"
import {
    addSurveyVersionToActiveSurvey,
    getSurveySavedVersions,
    removeSurveyVersionToActiveSurvey,
    updateActiveSurveyInSurveyDefinition,
    updateOrSaveSurveyVersion
} from "../../shared/survey/actions";


function AddEditSurveyVersion({
                                  activeSurvey,
                                  currentVersion,
                                  user,
                                  activeSurveyVersions,
                                  taskTypes,
                                  followUpQuestions,
                                  systemLanguages,
                                  savedSurveyVersions,
                                  saveLoadingSpinner,
                                  onCancel
                              }) {

    const [headerEditorState, setHeaderEditorState] = React.useState();
    const [footerEditorState, setFooterEditorState] = React.useState();
    const [questions, setQuestions] = React.useState();
    const [languagesModalOpen, setLanguagesModalOpen] = React.useState(false);
    const [selectedLanguages, setSelectedLanguages] = React.useState([]);
    const [disableSetAsActive, setDisableSetAsActive] = React.useState(false);

    const dispatch = useDispatch();

    let headerDomEditor;
    const setHeaderDomEditorRef = ref => headerDomEditor = ref;

    function headerContainerClick() {
        headerDomEditor.focus();
    }

    let footerDomEditor;
    const setFooterDomEditorRef = ref => footerDomEditor = ref;

    function footerContainerClick() {
        footerDomEditor.focus();
    }

    React.useEffect(() => {
        // If we have notes and the current content is empty - the set those notes
        if (currentVersion) {
            let headerContentState = ContentState.createFromText(currentVersion.header ? currentVersion.header : "");
            let footerContentState = ContentState.createFromText(currentVersion.footer ? currentVersion.footer : "");
            setHeaderEditorState(EditorState.createWithContent(headerContentState));
            setFooterEditorState(EditorState.createWithContent(footerContentState));
            setQuestions(currentVersion.questions ? _.cloneDeep(currentVersion.questions) : []);
            setSelectedLanguages(currentVersion.supportedLanguages ? currentVersion.supportedLanguages.split(",") : []);
            (!currentVersion.activated) ? setDisableSetAsActive(true) : setDisableSetAsActive(false);
        }

    }, [currentVersion]);

    const updateSetAsActiveState = (flag) => {
        dispatch(getSurveySavedVersions({
            surveyId: activeSurvey.id,
            surveyVersionId: currentVersion.id,
            activated:flag
        }));
    }
    const addQuestion = () => {
        let editQuestions = getOrderedQuestions();
        if (!editQuestions) {
            editQuestions = [];
        }
        let maxQuestion = _.maxBy(editQuestions, function (q) {
            return q.questionOrder;
        });
        setQuestions(editQuestions.concat({
            prompt: "",
            type: "SCALE",
            questionOrder: maxQuestion ? maxQuestion.questionOrder + 1 : 0
        }));
    }

    const cancelSurvey = () => {
        onCancel();
    }

    const deleteSurveyVersion = () => {
        API.del('Core', '/api/v1/survey/' + activeSurvey.id + '/version/' + currentVersion.id)
            .then(response => {
                    // TODO: issue to solve, because the selected version is the deleted one, so the selected one must be changed
                    dispatch(removeSurveyVersionToActiveSurvey(currentVersion.id))
                },
                error => {
                    toast("Could not delete version: " + error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.ERROR
                    });
                }
            );
    }

    const isValidCriteria = (question) => {
        const criteria = question.surveyQuestionReviewCriteria
        if (criteria) {
            for (let j = 0; j < criteria.length; j++) {
                const criterion = criteria[j];
                if (!criterion.comparisonField) {
                    return "Comparison field cannot be empty, please select one comparison field";
                }
            }
        }
    }

    const areQuestionsValid = () => {
        for (let i = 0; i < questions.length; i++) {
            const question = questions[i];
            const criteriaValidation = isValidCriteria(question);
            if (criteriaValidation) {
                return criteriaValidation;
            }
            switch (question.type) {
                case "SCALE":
                    if (!question.prompt || question.low === null || question.low === undefined || question.high === null || question.high === undefined) {
                        return "Fill all required questions' fields";
                    }
                    break;
                case "COMMENT":
                    if (!question.prompt) {
                        return "Fill all required questions' fields";
                    }
                    break;
                case "CHOICE":
                    if (!question.prompt) {
                        return "Fill all required questions' fields";
                    }

                    if (!question.options || question.options.length === 0 || question.options.some(o => !o.option)) {
                        return "Fill all required questions' fields";
                    }
                    break;
                default:
                    break;
            }
        }

        return;
    }

    const saveSurveyVersion = () => {
        saveLoadingSpinner(true);
        const validationResult = areQuestionsValid();
        if (validationResult) {
            saveLoadingSpinner(false);
            toast(validationResult, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            return validationResult;
        }
        API.put('Core', '/api/v1/survey/' + activeSurvey.id + '/version/' + currentVersion.id,
            {
                body: {
                    ...currentVersion,
                    header: headerEditorState.getCurrentContent().getPlainText(),
                    footer: footerEditorState.getCurrentContent().getPlainText(),
                    questions: questions,
                    supportedLanguages: selectedLanguages.length ? selectedLanguages.join(",") : null
                }
            })
            .then(response => {
                    toast("Survey Version Saved!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                    console.log(response);
                    dispatch(updateOrSaveSurveyVersion(response));
                    updateSetAsActiveState(false);
                    saveLoadingSpinner(false);
                },
                error => {
                    toast("Could not update version: " + error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.ERROR
                    });
                    saveLoadingSpinner(false);
                }
            );
    }

    const setActive = () => {
        const validationResult = areQuestionsValid();
        if (validationResult) {
            toast(validationResult, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            return;
        }
        API.put('Core', '/api/v1/survey/' + activeSurvey.id, {
            body: {
                ...activeSurvey,
                activeSurveyVersion: currentVersion
            }
        })
            .then(response => {
                    dispatch(updateActiveSurveyInSurveyDefinition(response));
                    updateSetAsActiveState(true);
                    toast("Successfully Set Survey Version To Active!", {
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.SUCCESS
                    });
                },
                error => {
                    toast("Could not set survey active: " + error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.ERROR
                    });
                }
            );
    }

    const enableSetAsActive = () => {
        return ((savedSurveyVersions.filter(savedSurvey => savedSurvey.surveyId === activeSurvey.id && savedSurvey.surveyVersionId === currentVersion.id).length > 0) ? true : !disableSetAsActive);
    }

    const editNewVersion = (surveyVersion) => {
        API.post('Core', '/api/v1/survey/' + activeSurvey.id + "/version/" + surveyVersion.id, {body: activeSurvey})
            .then(response => {
                    dispatch(addSurveyVersionToActiveSurvey(response))
                },
                error => {
                    toast("Could not set survey active: " + error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.ERROR
                    });
                }
            );
    }

    const updateHeaderEditorState = (newEditorState) => {
        setHeaderEditorState(newEditorState);
        if (newEditorState.getCurrentContent().getPlainText() !== headerEditorState.getCurrentContent().getPlainText()){
            updateSetAsActiveState(true);
        }
    }

    const updateFooterEditorState = (newEditorState) => {
        setFooterEditorState(newEditorState);
        if (newEditorState.getCurrentContent().getPlainText() !== footerEditorState.getCurrentContent().getPlainText()){
            updateSetAsActiveState(true);
        }
    }

    const onQuestionUpdate = (idx, q) => {
        questions[idx] = q;
        setQuestions([].concat(questions));
        updateSetAsActiveState(true);
    }

    const removeQuestion = (idx) => {
        let ordered = _.sortBy(questions, function (s) {
            return s.questionOrder;
        })
        ordered.splice(idx, 1);
        setQuestions([].concat(ordered));
        updateSetAsActiveState(true);
    }

    const enableDisableLanguage = (languageCode) => {
        if (selectedLanguages.includes(languageCode)) setSelectedLanguages(selectedLanguages.filter(language => language !== languageCode))
        else setSelectedLanguages([...selectedLanguages, ...[languageCode]])
        updateSetAsActiveState(true);
    }

    const getOrderedQuestions = () => {
        if (!questions) {
            return [];
        }
        return _.sortBy(questions, function (s) {
            return s.questionOrder;
        })
    }

    const setList = () => {

    }

    const onReorderEnd = (e) => {
        let questionsOrdered = getOrderedQuestions();
        let itemMoved = questionsOrdered[e.oldIndex];
        questionsOrdered.splice(e.oldIndex, 1);
        questionsOrdered.splice(e.newIndex, 0, itemMoved);
        _.forEach(questionsOrdered, function (qo, j) {
            qo.questionOrder = j;
        });
        setQuestions([].concat(questionsOrdered));
    }

    if (!footerEditorState || !headerEditorState) {
        return null;
    }

    let disabled = currentVersion.activated;
    return (
        <div className="scrollView">
            <div className="navBar">
                <div className="title">Versions</div>
                <div className="separator"/>
            </div>
            <div className={"editContent tableView addEditSurvey"}>
                <div className={"tableSection sectionSurveyHeader"} onClick={headerContainerClick}>
                    <div className="sectionTitle">Header</div>
                    <div className="tableCell editorCell">
                        <Editor
                            readOnly={disabled}
                            style={{height: "100%"}}
                            editorState={headerEditorState}
                            onChange={updateHeaderEditorState}
                            placeholder={"Click and type to add header notes..."}
                            spellCheck={true}
                            stripPastedStyles={true}
                            ref={setHeaderDomEditorRef}
                        />
                    </div>
                </div>
                {isLanguageSupportEnable() && systemLanguages &&
                    <div className="tableSection">
                        <div className="actions right">
                            <Modal
                                button={<Button
                                    size="small"
                                    title={`${selectedLanguages.length + 1} Supported Language(s)       `}
                                    onClick={() => setLanguagesModalOpen(true)}
                                />}
                                content={(<div>
                                    <div className={`tableCell switchCell switchOn`}>
                                        <div className="title">English</div>
                                        <div className={`accessory accessorySwitch on`}>
                                            <div className="switchThumb"/>
                                        </div>
                                    </div>
                                    {systemLanguages.map((systemLanguage, index) => {
                                        return (
                                            <div key={index}
                                                 onClick={() => enableDisableLanguage(systemLanguage.languageCode)}
                                                 className={`tableCell switchCell ${selectedLanguages.includes(systemLanguage.languageCode) ? 'switchOn' : 'switchOff'}`}>
                                                <div className="title">{systemLanguage.language}</div>
                                                <div
                                                    className={`accessory accessorySwitch ${selectedLanguages.includes(systemLanguage.languageCode) ? 'on' : 'off'}`}>
                                                    <div className="switchThumb"/>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>)}
                                title="Supported Languages"
                                size="small"
                                handleClose={() => setLanguagesModalOpen(false)}
                                open={languagesModalOpen}
                            />
                        </div>
                    </div>
                }
                <div className="tableSection sectionSurveyQuestions questionsList">
                    <div className={"sectionTitle"}>Questions</div>
                    {/* TODO: this is causing issues at Redux level, something is changing the state not properly.*/}
                    <ReactSortable
                        setList={setList}
                        list={getOrderedQuestions()}
                        onEnd={onReorderEnd}
                        disabled={disabled}
                        style={{width: '100%'}}
                    >
                        {
                            _.map(getOrderedQuestions(), function (q, i) {
                                return (
                                    <div key={'question-' + (q.id ? q.id : 'idx-' + i) + ":rank-" + q.questionOrder}
                                         className={"questionListItem"}>
                                        <EditQuestion disabled={disabled} question={q}
                                                      onUpdate={onQuestionUpdate.bind(this, q)} user={user}
                                                      taskTypes={taskTypes} followUpQuestions={followUpQuestions}
                                                      updateSetAsActiveState={updateSetAsActiveState}/>
                                        <div className={"removeQuestion"} onClick={removeQuestion.bind(this, i)}>
                                            <div className="icon"/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </ReactSortable>
                    {
                        disabled ? null : (
                            <div onClick={addQuestion} className="button tint medium">
                                <div className="title">Add Another Question</div>
                            </div>
                        )
                    }
                </div>

                <div className="tableSection sectionSurveyFooter">
                    <div className="sectionTitle">Footer</div>
                    <div className={"tableCell editorCell"} onClick={footerContainerClick}>
                        <Editor
                            readOnly={disabled}
                            style={{height: "100%"}}
                            editorState={footerEditorState}
                            onChange={updateFooterEditorState}
                            placeholder={"Click and type to add footer notes..."}
                            spellCheck={true}
                            stripPastedStyles={true}
                            ref={setFooterDomEditorRef}
                        />
                    </div>
                </div>
            </div>

            <div className="actionBar">
                <div className="actions versioning left">
                    {
                        (activeSurvey.activeSurveyVersion && activeSurvey.activeSurveyVersion.id === currentVersion.id) ?
                            (
                                // TODO: this must not be a button, this does not have any functionality
                                <div className={`button tint medium`}>
                                    <div className="title">Active</div>
                                </div>
                            ) :
                            (
                                <div onClick={ enableSetAsActive() ? setActive : null} className={`button medium ${ enableSetAsActive() ? 'confirm' : ' dismiss disabled'}`}>
                                    <div className="title">Set as Active</div>
                                </div>
                            )
                    }
                    {
                        (!currentVersion.activated) && activeSurveyVersions.length > 1 ?
                            (
                                <div onClick={deleteSurveyVersion} className="button destructive medium">
                                    <div className="title">Delete</div>
                                </div>
                            ) : null
                    }
                </div>
                {
                    !currentVersion.activated ? (
                        <div className="actions edit right">
                            <div onClick={cancelSurvey} className="button dismiss medium">
                                <div className="title">Cancel</div>
                            </div>
                            <div onClick={saveSurveyVersion} className={`button confirm medium`}>
                                <div className="title">Save</div>
                            </div>
                        </div>
                    ) : (
                        <div className="actions edit right">
                            <div onClick={editNewVersion.bind(this, currentVersion)} className={`button plain medium`}>
                                <div className="title">Edit as a New Version</div>
                            </div>
                        </div>
                    )
                }
                <div className="separator"></div>
            </div>
        </div>
    )
}

export default AddEditSurveyVersion;
