import React from 'react';

function MessageCellIncoming(props) {
  return (
    <div className="messageCell messageIncoming">
      <div className="profilePicture">
        <div className="icon"/>
      </div>

      <div className="messageBubble">
        <div className="attributes">
          <div className="timestamp">{props.messageTimestamp}</div>
        </div>

        <div className="text">{props.messageText}</div>
      </div>
    </div>
  );
}

function MessageCellBot(props) {
  return (
    <div className="messageCell messageBot">
      <div className="profilePicture">
        <div className="icon"/>
      </div>

      <div className="messageBubble">
        <div className="attributes">
          <div className="timestamp">{props.messageTimestamp}</div>
        </div>

        <div className="text">{props.messageText}</div>
      </div>
    </div>
  );
}

export {MessageCellBot, MessageCellIncoming};