import { API } from 'aws-amplify';
import {requestSystemSchema, requestSystemSchemaSuccess} from "../schema/actions";

export const GET_PAYMENT_SETTINGS = 'GET_PAYMENT_SETTINGS';
export const requestPaymentSettings = () => dispatch => {
    dispatch({
        type : GET_PAYMENT_SETTINGS
    });
}

export const GET_PAYMENT_SETTINGS_SUCCESS = 'GET_PAYMENT_SETTINGS_SUCCESS';
export const requestPaymentSettingsSuccess = (json) => dispatch => {
    dispatch({
        type : GET_PAYMENT_SETTINGS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const DEAUTH_PAYMENT_SETTINGS_SUCCESS = 'DEAUTH_PAYMENT_SETTINGS_SUCCESS';
export const deauthorizePaymentSettingsSuccess = (json) => dispatch => {
    dispatch({
        type : DEAUTH_PAYMENT_SETTINGS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function fetchPaymentSettings() {

    return function(dispatch) {

        dispatch(requestPaymentSettings())

        return API.get('Core', '/api/v1/payment-provider')
            .then(response => {
                    dispatch(requestPaymentSettingsSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function disconnectPaymentSettings() {

    return function(dispatch) {

        return API.get('Core', '/api/v1/payment-provider/stripe/deauthorize')
            .then(response => {
                    dispatch(deauthorizePaymentSettingsSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}