import React, { Component } from 'react';
import { Link, makeStyles } from '@material-ui/core';

const customStyles = makeStyles(theme => ({
    legalDisclaimerLinkContainer:{
      display: 'flex',
      justifyContent: 'center',
    },
    legalDisclaimerLink: {
      color: '#FF9900',
      cursor: 'pointer'
    },
  }));

export default function LegalDisclaimer(props)
{
    const CLASSES = customStyles();

    return (<div className={CLASSES.legalDisclaimerLinkContainer}>
    <Link className={CLASSES.legalDisclaimerLink} href="https://www.cuehit.com/disclaimer/">Disclaimer</Link>
  </div>);
}