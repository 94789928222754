import React from "react"
import {getTaskStatusIcon} from "../../common/TaskUtils";

function TaskCell({contactInfo, taskStatus, taskTitle, taskAssignee, taskReportedDate, taskDueDate,...props}){
    let clzz = "";
    let icon = getTaskStatusIcon(taskStatus);

    // eslint-disable-next-line default-case
    switch (taskStatus){
        case "OPEN":
            clzz = "upcomingTask";
            break;
        case "IN_PROGRESS":
            clzz = "inProgressTask";
            break;
        case "OVERDUE":
            clzz = "overdueTask";
            break;
        case "CLOSED":
            clzz = "closedTask";
            break;
    }

    return (
        <div className={`taskCell ${clzz}`}>
            <div className="taskCellIcon">{icon}</div>

            <div className="taskCellBody">
                <div className="taskCellTitle">{taskTitle}</div>

                <div className="taskCellSubtitle">
                    <div className="taskCellAssignee">{taskAssignee ? `Assigned to ${taskAssignee}` : "Unassigned"}</div>
                    {
                        contactInfo ? (
                            <div className="taskCellSubtitleSeparator"> • </div>
                        ) : null
                    }
                    {
                        contactInfo ? (
                            <div className="taskCellContact">{`Contact: ${contactInfo}`}</div>
                        ) : null
                    }
                    <div className="taskCellSubtitleSeparator"> • </div>
                    <div className="taskCellReportedDate">Reported on {taskReportedDate}</div>
                </div>
            </div>

            <div className="taskCellDetail">
                <div className="taskCellDueDate">
                    <div className="dueDateTitle">{taskDueDate}{taskStatus === "OVERDUE" ? " - Overdue" : ""}</div>
                </div>
            </div>
        </div>
    )
}

export default TaskCell
