import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { EidAuthContext } from './EIDAuthContext';
import getOidcServiceInstance from "./EIDOidcSingletonService";
import { CircularProgress } from "@material-ui/core";

const EIDPostSignInCallback = () => {
    const history = useHistory();
    const { setSignInEidUser } = useContext(EidAuthContext);

    useEffect(() => {
        const handlePostEidSignInCallback = async () => {
            const oidcInstance = await getOidcServiceInstance();
            oidcInstance?.processSignInCallback()
                .then((eidUser) => {
                    setSignInEidUser(eidUser);
                    history.push('/');
                })
                .catch(err => {
                    console.error(err);
                });
        };

        handlePostEidSignInCallback();
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress size="5rem" />
        </div>
    );
};

export default EIDPostSignInCallback;