import React from 'react';
import InputCell from "../common/form/InputCell";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import _ from "lodash";
import Select from "react-select";
import {roles} from "../utils/utils";

export default function AddEditDisposition(props){
    var {existingDisposition, allWorkflows, onCancel, onSuccess, allTaskTypes} = props;

    const [disposition, setDisposition] = React.useState(existingDisposition ? existingDisposition : {});
    const [states, setStates] = React.useState([]);
    const [workflowToState, setWorkFlowToState] = React.useState([]);
    const [autoCreateTaskType, setAutoCreateTaskType] = React.useState();

    const nameRef = React.createRef()

    React.useEffect(() => {
        if(existingDisposition!= null && existingDisposition.advanceStates != null) {
            _.forEach(existingDisposition.advanceStates, function (a) {
                let workflow = findWorkflowByStateId(a.id);
                if(workflow != null) {
                    workflowToState.push({workflow: workflow.name, state: a.name})
                }
            })
            setWorkFlowToState(workflowToState);
            if(existingDisposition.autoCreateTaskType != null) {
                setAutoCreateTaskType(existingDisposition.autoCreateTaskType);
            }
            setStates([]); //hack to refresh page. wtf

        }
    }, [existingDisposition])

    const findWorkflowByStateId = (stateId) => {
        let workflowIdx = _.findIndex(allWorkflows, function (aw) {
            let sIdx = _.findIndex(aw.states, function (s) {
                return s.id == stateId;
            })
            if(sIdx >= 0) {
                return true;
            }
        })
        if(workflowIdx >= 0) {
            return allWorkflows[workflowIdx];
        } else {
            return null;
        }
    }

    const saveDisposition = () => {
        let advances = [];
        _.forEach(workflowToState, function (wts) {
            let workflowIdx = _.findIndex(allWorkflows, function(w) {
                return w.name == wts["workflow"];
            })
            if(workflowIdx >= 0) {
                let stateIdx = _.findIndex(allWorkflows[workflowIdx].states, function (s) {
                    return s.name == wts["state"];
                })
                if(stateIdx >= 0) {
                    advances.push(allWorkflows[workflowIdx].states[stateIdx]);
                }
            }
        });
        let dispoObj = {
            name: nameRef.current.value,
            advanceStates: advances,
            autoCreateTaskType: autoCreateTaskType
        }

        if(existingDisposition){
            API.put('Core', '/api/v1/conversation/disposition/' + existingDisposition.id, {body:dispoObj})
                .then(response => {
                        toast.success("Disposition updated!", {position: toast.POSITION.TOP_CENTER});
                        onSuccess(response);
                    },
                    error => {
                        toast.error("Could not update disposition: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                    }
                );
        }
        else {
            API.post('Core', '/api/v1/conversation/disposition', {body:dispoObj})
                .then(response => {
                        toast.success("Disposition Created!", {position: toast.POSITION.TOP_CENTER});
                        onSuccess(response);
                    },
                    error => {
                        toast.error("Could not create disposition: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                    }
                );
        }
    }

    const addState = () => {
        setStates([]);
        let workflowIdx = _.findIndex(workflowToState, function (w) {
            return w["workflow"] == "";
        })
        if(workflowToState.length == 0 || workflowIdx == -1) {
            workflowToState.push({workflow: "", state: ""})
        }
    }

    const removeState = (idx) => {
        setStates([]);
        workflowToState.splice(idx,1);
        setWorkFlowToState(workflowToState);
    }

    const workflowOpts = () => {
       let opts = []
        _.forEach(allWorkflows, function (wf) {
            let existing = _.findIndex(workflowToState, function (w) {
                return w["workflow"] == wf.name;
            })
            if(existing < 0) {
                opts.push({value: wf.name, label: wf.name});
            }
        })
        return opts;
    }

    const workflowStateOpts = (idx) => {
        let workflowName = workflowToState[idx]["workflow"];
        if(workflowName != "") {
            let workflowIdx = _.findIndex(allWorkflows, function (w) {
                return w.name == workflowName
            })
            return _.map(allWorkflows[workflowIdx].states, function (s) {
                return {value: s.name, label: s.name};
            })
        }
    }

    const taskOpts = () => {
        let opts = _.map(allTaskTypes, function (t) {
            return {value: t.name, label: t.name};
        })
        return opts;
    }

    const selectedWorkflowVal = (idx) => {
        let retVal = workflowToState[idx] ? {value: workflowToState[idx]["workflow"], label: workflowToState[idx]["workflow"]} : null;
        return retVal;
    }

    const selectedWorkflowStateVal = (idx) => {
        let retVal = workflowToState[idx] ? {value: workflowToState[idx]["state"], label: workflowToState[idx]["state"]} : null;
        return retVal;
    }

    const setWorkflowValue = (idx, obj, action) => {
        setStates([]);

        switch (action.action) {
            case "select-option":
                workflowToState[idx]["workflow"] = obj.value;
                setWorkFlowToState(workflowToState);
                break;
        }
    }

    const setAutoCreateTaskTypeValue = (obj, action) => {
        let taskType = _.find(allTaskTypes, function (t) {
            return t.name == obj.value;
        })
        setAutoCreateTaskType(taskType);
    }

    const setWorkflowStateValue = (idx, obj, action) => {
        setStates([]);
        switch (action.action) {
            case "select-option":
                workflowToState[idx]["state"] = obj.value;
                setWorkFlowToState(workflowToState);
                break;
        }
    }

    return (
        <div className="addEditClientForm">
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"Name"}
                            defaultValue={(existingDisposition ? existingDisposition.name : "")}
                            required
                            id="name"
                            label="Disposition Name"
                            inputRef={nameRef}
                        />
                    </div>
                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Workflow States</div>
                        </div>
                        {
                            _.map(workflowToState, function(wts, i){
                                return (
                                    <div key={wts.workflow + "-" + wts.state} className="tableRow">
                                        <Select styles={{width: "300px"}} className="selectCell workflowTextBackSettingLarge" classNamePrefix="selectCell"
                                                onChange={setWorkflowValue.bind(this, i)}
                                                value={selectedWorkflowVal(i)}
                                                options={workflowOpts()}
                                        />
                                        <Select styles={{width: "300px"}} className="selectCell workflowTextBackSettingLarge" classNamePrefix="selectCell"
                                                onChange={setWorkflowStateValue.bind(this, i)}
                                                value={selectedWorkflowStateVal(i)}
                                                options={workflowStateOpts(i)}
                                        />
                                        <div className={"remove"} onClick={removeState.bind(this, i)}>
                                            <div className="icon"/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div onClick={addState} className="button tint medium">
                            <div className="title">Add State</div>
                        </div>
                    </div>
                    <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                            onChange={setAutoCreateTaskTypeValue.bind(this)}
                            placeholder="Auto-create Task Type"
                            value={autoCreateTaskType != null && autoCreateTaskType.name != "" ? {label: autoCreateTaskType.name, value: autoCreateTaskType.name} : ""}
                            options={taskOpts()}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPlacement={"bottom"}
                    />
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveDisposition} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>
    );
}