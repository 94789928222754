import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { API } from 'aws-amplify';
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { CircularProgress, Menu, MenuItem, Tooltip } from '@material-ui/core';
import _ from "lodash";

import InputCell from '@/common/form/InputCell';
import Modal from "@/common/Modal";
import moment from '@/common/momentConfig';
import { ehrFieldType, ehrHeaderFields, ehrSections, ehrSectionsToFieldsMap, fixedOptions } from '@/utils/utils';

import Addendums from './Addendums';

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
})



const EHRModal = (props) => {
    const { conversationId } = props.match.params;
    const [sections, setSections] = useState([]);
    const [activeTab, setActiveTab] = useState(window.targetEHRTab || null);
    const [dynamicOptions, setDynamicOptions] = useState({})
    const [recordsData, setRecordsData] = useState({})
    const [loadingFields, setLoadingFields] = useState(true)
    const [loadingRecords, setLoadingRecords] = useState(!window.data)
    const [saving, setSaving] = useState(false);

    const [conversationDisposition, setConversationDisposition] = useState();
    const [dispositions, setDispositions] = useState([]);
    const [dispostionAnchorEl, setDispostionAnchorEl] = useState(null);

    const [vitalSignsModified, setVitalSignsModified] = useState(false);

    const [reviewMatches, setReviewMatches] = useState(false);
    const [possibleMatches, setPossibleMatches] = useState([]);

    const [isFormLocked, setFormLocked] = useState(false);
    const [isDataCompleteChecked, setDataCompleteChecked] = useState(false);
    const [isCompleteConfirmationModalOpen, setCompleteConfirmationModalOpen] = useState(false);
    const [isCompleteButtonOn, setCompleteButtonOn] = useState(false);

    const [intakeOptions, setIntakeOptions] = useState([]);

    const [isCompleteAndCloseConfirmationModalOpen, setCompleteAndCloseConfirmationModalOpen] = useState(false);

    const [addendumsToBeSaved, setAddendumsToBeSaved] = useState([]);

    const ADDENDUM_SIGNER = `${props?.childProps?.authenticatedUser?.attributes?.given_name} ${props?.childProps?.authenticatedUser?.attributes?.family_name}`;

    let updateAddendum = (data) => {
        const SAVE_DATA = [...addendumsToBeSaved];

        if (data?.length > 0) {
            SAVE_DATA.push({
                signedAt: new Date(),
                signerFullName: ADDENDUM_SIGNER || '',
                addendumText: data || '',
            });
        }

        setAddendumsToBeSaved(SAVE_DATA);
        setCompleteConfirmationModalOpen(true);
    }

    const calculateAge = (dateOfBirth) => {
        const [year, month, day] = dateOfBirth.split("-");
        if (!year || parseInt(year) < 1000) {
            return;
        }
        const dob = new Date(year, month, day);
        const diff = Date.now() - dob.getTime();
        const age = Math.abs(new Date(diff).getUTCFullYear() - 1970);
        return age;
    }

    const calculatedValues = {
        "dateOfBirth": {
            depndendants: ["age"],
            calculator: calculateAge
        }
    }

    const runCalculatedValuesIfNeeded = (fieldName, newValue) => {
        const existingCalculation = calculatedValues[fieldName];
        if (!existingCalculation) {
            return;
        }

        existingCalculation.depndendants.forEach(dep => {
            const dependantField = sections.flatMap(s => s.fields).find(f => f.name === dep);
            if (dependantField && dependantField.readOnly) {
                const calculatedValue = existingCalculation.calculator(newValue);
                if (calculatedValue) {
                    handleInputTextChange(calculatedValue, dependantField);
                }
            }
        });
    }

    useEffect(() => {
        if (!window.conversation) {
            API.get('Core', `/api/v1/conversation/${conversationId}`)
                .then(response => {
                    setConversationDisposition(response.conversationDisposition)
                    setCompleteButtonOn(!!response.workflowState && !!response.workflowState.closedState)
                },
                    error => {
                        toast("Could not load conversation disposition " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                    });
        } else {
            setConversationDisposition(window.conversation.conversationDisposition);
            setCompleteButtonOn(!!window.conversation.workflowState && !!window.conversation.workflowState.closedState);
        }

        if (!window.dispositions) {
            API.get('Core', '/api/v1/conversation/dispositions')
                .then(response => {
                    setDispositions(response)
                },
                    error => {
                        toast("Could not load dispositions " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                    });
        } else {
            setDispositions(window.dispositions);
        }

        API.get('Core', '/api/v1/ehr/fields/options')
            .then(options => {
                setDynamicOptions(options.reduce(dynamicOptionsReducer, {}));
            });

        API.get('Core', `/api/v1/fieldoption/${ehrHeaderFields.INTAKE}`)
            .then(options => {
                setIntakeOptions(options.map(option => ({
                    value: option.optionCode || option.optionName,
                    label: option.optionName
                })))
            });

        API.get('Core', '/api/v1/ehr/fields').then(fields => {
            const sections = [...fields.reduce(fieldsBySectionReducer, []), { displayName: 'Addendums', order: -1, name: 'addendum', fields: [] }];
            setSections(sections);
            if (activeTab == null) {
                setActiveTab(sections[0].name);
            }
            setLoadingFields(false);
        })

        if (!window.data) {
            API.get('Core', `/api/v1/conversation-records/${conversationId}/all`)
                .then(response => {
                    setRecordsDataFromResponse(response)
                    setLoadingRecords(false)
                },
                    error => {
                        toast("Could not load patient information: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                    });
        } else {
            setRecordsDataFromResponse(window.data);
        }
    }, []);

    const setRecordsDataFromResponse = (response) => {
        if (response.interaction && response.interaction.vitalsObservations) {
            response.interaction.currentVitalsObservations =
                response.interaction.vitalsObservations.length > 0 ? { ...response.interaction.vitalsObservations[0] } : {}
            response.interaction.currentVitalsObservations.lastReadAt = response.interaction.currentVitalsObservations.readAt;
            response.interaction.currentVitalsObservations.readAt = null;
            response.interaction.currentVitalsObservations.id = null;
        }

        setRecordsData({
            epcrRecordNumber: response.epcrRecordNumber,
            epcrFetchedAt: response.epcrFetchedAt,
            finalPdfUrl: response.finalPdfUrl,
            demographics: response.demographics ? response.demographics : {},
            interaction: response.interaction ? response.interaction : {},
            eSignedAt: response.eSignedAt,
            eSignedBy: response.eSignedBy,
            signatoryName: response.signatoryName,
            incidentNumber: response.incidentNumber,
            intake: response.intake,
            hasLinkedEncounter: response.hasLinkedEncounter
        })

        if (response.eSignedAt) setFormLocked(true)
    }

    const onChangeIncidentNumber = (value) => {
        setRecordsData({
            ...recordsData,
            incidentNumber: value
        })
    }

    const onChangeIntake = (selectedOption) => {
        setRecordsData({
            ...recordsData,
            intake: selectedOption?.value
        })
    }

    const dynamicOptionsReducer = (dynamicOptions, option) => {
        dynamicOptions[option.fieldName] = dynamicOptions[option.fieldName] || [];
        dynamicOptions[option.fieldName].push({
            value: option.optionCode || option.optionName,
            label: option.optionName
        });
        return dynamicOptions;
    }

    const fieldsBySectionReducer = (sections, field) => {
        const existingSection = sections.find(s => s.name === field.section.name);
        if (existingSection) {
            existingSection.fields.push(field);
        } else {
            sections.push({ ...field.section, fields: [field] })
        }
        sections = _.sortBy(sections, [s => s.order])
        return sections;
    }


    const changeTab = (tab) => {
        console.log('Tab ', tab);
        setActiveTab(tab);
    }

    const onBlurHandler = (field) => {
        switch (field.name) {
            case "lastName":
            case "dateOfBirth":
            case "ssn":
                return () => searchPossibleMatches(recordsData);
            default:
                return () => { };
        }
    }

    const getFieldComponent = (field) => {
        let options;
        if (field.dynamicOptions) {
            options = dynamicOptions[field.name];
        } else {
            options = fixedOptions[field.name];
        }

        if (!options) {
            options = [];
        }

        switch (field.type) {
            case ehrFieldType.TEXT_INPUT:
            case ehrFieldType.TEXTAREA_INPUT:
            case ehrFieldType.NUMBER_INPUT:
            case ehrFieldType.DATE_INPUT:
                var type;
                switch (field.type) {
                    case ehrFieldType.TEXTAREA_INPUT:
                        type = "textarea";
                        break;
                    case ehrFieldType.NUMBER_INPUT:
                        type = "number";
                        break;
                    case ehrFieldType.DATE_INPUT:
                        type = "date";
                        break;
                    default:
                        type = "text";
                }
                return (
                    <div className={`tableCell titledSelectCell ${type === "textarea" && "textarea"}`}>
                        <div className="title">{field.displayName}</div>
                        <InputCell
                            placeholder={field.placeholder || field.displayName}
                            defaultValue={getFieldValue(field)}
                            disabled={field.readOnly || (isFormLocked && field.lockable)}
                            onChange={(e) => handleInputTextChange(e.target.value, field)}
                            onBlur={onBlurHandler(field)}
                            pattern={field.pattern}
                            required
                            type={type}
                        />
                        <div className="valueDescription">{field.description}</div>
                    </div>
                );
            case ehrFieldType.PHONE_INPUT:
                return (
                    <div className="tableCell inputCell phoneInput">
                        <div className="title">{field.displayName}</div>
                        <PhoneInput
                            inputClass={isFormLocked && field.lockable ? `tel-input disabled` : `tel-input`}
                            country={'us'}
                            onlyCountries={['us']}
                            value={getFieldValue(field) || ""}
                            onChange={(value) => handleInputTextChange(value, field)}
                            disabled={isFormLocked && field.lockable}
                        />
                    </div>
                );
            case ehrFieldType.SINGLE_SELECT:
                const singleSelectValue = parseValueToSingleSelectValue(getFieldValue(field), options);

                return (
                    <div className="tableCell titledSelectCell">
                        <div className="title">{field.displayName}</div>
                        <CreatableSelect
                            isDisabled={isFormLocked && field.lockable}
                            styles={{ width: "100px" }}
                            className="selectCell workflowTextBackSetting"
                            isClearable
                            value={singleSelectValue}
                            onChange={selectedOption => handleSingleSelectChange(selectedOption, field)}
                            classNamePrefix="selectCell"
                            options={options}
                        />
                    </div>
                );
            case ehrFieldType.MULTI_SELECT:
                const multiSelectValue = parseValueToMultiselectValue(getFieldValue(field), options);

                return (
                    <div className="tableCell titledSelectCell">
                        <div className="title" style={{ marginRight: "10px" }}>{field.displayName}</div>
                        <CreatableSelect
                            isDisabled={isFormLocked && field.lockable}
                            options={options}
                            classNamePrefix="selectCell"
                            className="selectCell"
                            isClearable
                            isMulti
                            onChange={(value) => handleMultiSelectChange(value, field)}
                            placeholder=""
                            value={multiSelectValue}
                        />
                    </div>
                );
            default:
                return null;
        }
    }

    const joinMultiSelectValue = (multiSelectValue) => {
        if (!multiSelectValue) {
            return "";
        }
        return multiSelectValue.map(option => option.value).join(",");
    }

    const parseValueToSingleSelectValue = (value, options) => {
        if (!value) {
            return "";
        }

        const optionFound = options.find(option => option.value === value);
        const label = optionFound ? optionFound.label : value

        return { value: value, label: label };
    }

    const parseValueToMultiselectValue = (multiSelectValue, options) => {
        if (!multiSelectValue) {
            return [];
        }
        const splittedValue = multiSelectValue.split(',');
        return splittedValue.map(value => {
            const optionFound = options.find(option => option.value === value);
            const label = optionFound ? optionFound.label : value
            return { value: value, label: label };
        })
    }

    const getRootField = (data, sectionName) => {
        const fieldName = ehrSectionsToFieldsMap[sectionName];
        const fieldPath = fieldName.split(".");
        let field = data[fieldPath[0]];
        for (let i = 1; i < fieldPath.length; i++) {
            if (!field.hasOwnProperty(fieldPath[i]) || field[fieldPath[i]] == null) {
                field[fieldPath[i]] = {}
            }
            field = field[fieldPath[i]];

        }
        return field;
    }

    const getFieldValue = (field) => {
        if (!recordsData) {
            return null;
        }

        const fieldPath = field.name.split(".");

        const root = getRootField(recordsData, field.section.name);

        const deepParent = getDeepParent(root, fieldPath);

        if (!deepParent) {
            return null;
        }

        const fieldValue = deepParent[fieldPath[fieldPath.length - 1]];

        return fieldValue;
    }

    const updateRecordDataValue = async (value, field) => {
        const fieldPath = field.name.split(".");

        const updatedRecordsData = {
            ...recordsData
        };

        if (field.section.name === ehrSections.VITAL_SIGNS) {
            setVitalSignsModified(true);
        }

        const root = getRootField(updatedRecordsData, field.section.name);

        if (!root) {
            return;
        }

        const deepParent = getDeepParent(root, fieldPath);
        if (!deepParent) {
            console.error(`Path '${fieldPath}' doesn't exists in ${ehrSectionsToFieldsMap[field.section.name]}`)
            return;
        }
        deepParent[fieldPath[fieldPath.length - 1]] = value;

        setRecordsData(updatedRecordsData);

        runCalculatedValuesIfNeeded(field.name, value);
    }

    const getDeepParent = (root, fieldPath) => {
        if (!root) {
            return null;
        }
        let currentField = root;
        let i = 0;
        while (i < fieldPath.length - 1) {
            const exists = !!currentField[fieldPath[i]]
            if (!exists) {
                currentField[fieldPath[i]] = {}
            }
            currentField = currentField[fieldPath[i]];
            i++;
        }

        return currentField;
    }


    const handleInputTextChange = (value, field) => {
        updateRecordDataValue(value, field);
    }

    const handleSingleSelectChange = (selectedOption, field) => {
        updateRecordDataValue(selectedOption ? selectedOption.value : "", field);
    }

    const handleMultiSelectChange = (multiSelectValue, field) => {
        const value = joinMultiSelectValue(multiSelectValue);
        updateRecordDataValue(value, field);
    }

    const searchPossibleMatches = (recordsData) => {
        if (!recordsData.demographics || !recordsData.demographics.dateOfBirth || !recordsData.demographics.lastName) {
            return;
        }

        API.get('Core', '/api/v1/records/search', {
            queryStringParameters: {
                ssn: recordsData.demographics.ssn,
                dob: recordsData.demographics.dateOfBirth,
                lastName: recordsData.demographics.lastName
            }
        })
            .then(response => {
                const filteredPossibleMatches = response;
                if (recordsData.demographics && recordsData.demographics.id) {
                    _.remove(filteredPossibleMatches, person => person.id == recordsData.demographics.id);
                }
                setPossibleMatches(filteredPossibleMatches);
            }, error => {
                toast("Error searching for possible matches: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            });
    }

    const associateExistingPatient = (connectorResult) => {
        API.put('Core', '/api/v1/conversation-records/' + conversationId + '/associate-person-demographics', { body: connectorResult })
            .then(response => {
                setRecordsDataFromResponse(response);
                setReviewMatches(false);
                searchPossibleMatches(response)
            }, error => {
                toast("Could not associate patient record: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            });
    }

    const getTabContent = (activeTab) => {
        const currentSection = sections.find(s => s.name === activeTab);
        if (!currentSection) {
            return null
        }

        const fields = currentSection?.fields?.map(field => {
            return (
                <div key={field.name} className="tableSection sectionName">
                    {getFieldComponent(field)}
                </div>
            )
        });

        let content = null;

        switch (currentSection.name) {
            case ehrSections.VITAL_SIGNS:
                content = (
                    <>
                        {recordsData.interaction.currentVitalsObservations && recordsData.interaction.currentVitalsObservations.lastReadAt ?
                            <div key={"label"} className="tableSection sectionName label">
                                Last Reading: {moment.utc(recordsData.interaction.currentVitalsObservations.lastReadAt).fromNow()}
                            </div> : null}
                        {fields}
                    </>
                );
                break;
            case ehrSections.PATIENT_INFO:
                if (reviewMatches) {
                    content = (
                        <div className="tableSection sectionMatches">
                            <div onClick={() => setReviewMatches(false)} className={`button medium action backButton`}>
                                <div className="buttonTitle">Back to Patient Info</div>
                            </div>
                            {
                                _.map(possibleMatches, (person) => (
                                    <div key={person.id} className="tableCell possibleMatchCell">
                                        <div className="patientName">{person.firstName} {person.middleName} {person.lastName}</div>

                                        <div className="patientAttributesList">
                                            <div className="patientAttribute">
                                                <div className="attributeTitle">Date of Birth</div>
                                                <div className="attributeValue">{person.dateOfBirth}</div>
                                            </div>

                                            <div className="patientAttribute">
                                                <div className="attributeTitle">Gender</div>
                                                <div className="attributeValue">{person.gender}</div>
                                            </div>
                                        </div>

                                        <div onClick={() => associateExistingPatient(person)} className={`button medium tint`}>
                                            <div className="buttonTitle">Select Patient</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    );
                } else {
                    content = (
                        <>
                            {possibleMatches && possibleMatches.length > 0 ? (
                                <div className="tableSection sectionDetailsMatch">
                                    <div onClick={() => setReviewMatches(true)} className={`bannerView`}>
                                        <p className="bannerTitle">These details match {possibleMatches.length} patients.</p>
                                        <div className="button small">
                                            <div className="buttonTitle">Review Matches</div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {fields}
                        </>
                    );
                }
                break;
            case 'addendum':
                content = (<Addendums
                    conversationId={conversationId}
                    addendumSigner={ADDENDUM_SIGNER}
                    addendumsToBeSaved={addendumsToBeSaved}
                    displaySignButton={() => setCompleteButtonOn(true)}
                    displaySignModal={() => setCompleteAndCloseConfirmationModalOpen(true)}
                    updateAddendum={updateAddendum} />);
                break;
            default:
                content = fields;
                break;
        }
        return (
            <div className="tableView">
                {content}
            </div>
        )
    }

    const saveAddendums = () => {
        return Promise.all(addendumsToBeSaved
            .map(a => ({
                conversationId: conversationId,
                addendumText: a.addendumText,
                useridpId: '',
                signerFullName: ''
            }))
            .map(b => API.post('Core', `/api/v1/conversation/${conversationId}/addendum`, { body: b })))
            .then(response => {

                toast("Successfully Saved Addendum Information!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                if (response?.length) {
                    setAddendumsToBeSaved([]);
                }
            });
    }

    const saveEHR = async (closeModal) => {
        if (!recordsData.interaction.type) {
            recordsData.interaction.type = "EMS";
        }

        if (recordsData.interaction.currentVitalsObservations) {
            const isVitalsFilled = Object.values(recordsData.interaction.currentVitalsObservations).some(v => !!v)

            if (isVitalsFilled && vitalSignsModified) {
                if (!recordsData.interaction.vitalsObservations) {
                    recordsData.interaction.vitalsObservations = []
                }
                recordsData.interaction.vitalsObservations.push(recordsData.interaction.currentVitalsObservations);
            }
        }

        recordsData.eSignedAt = null

        if (isDataCompleteChecked) {
            recordsData.eSignedAt = new Date()
            setDataCompleteChecked(false)
        }

        setVitalSignsModified(false);
        setSaving(true);

        return API.put('Core', `/api/v1/conversation-records/${conversationId}/all`, { body: recordsData })
            .then((response) => {
                setRecordsDataFromResponse(response);
                toast("Successfully Saved Patient Information!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });

                if (closeModal) {
                    console.log('Close modal');
                    window.close()
                }
            },
                error => {
                    const errorMessage = error?.response?.data?.message ? `: ${error.response.data.message}` : "";
                    toast("Could not save patient information" + errorMessage, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            )
            .finally(() => setSaving(false));
    }

    const refreshEPCR = () => {
        API.put('Core', `/api/v1/conversation-records/${conversationId}/refresh-epcr`, { body: {} })
            .then(response => {
                setRecordsDataFromResponse(response);
                toast("Successfully refresh data from ePCR!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
            },
                error => {
                    toast("Could not refresh data from ePCR: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );

    }

    const handleDispositionMenuClick = event => {
        setDispostionAnchorEl(event.currentTarget);
    };

    const onConversationUpdate = async (conversationId, updateConversation) => {
        await saveEHR(false);
        API.put('Core', '/api/v1/conversation/' + conversationId, { body: updateConversation })
            .then(response => {
                setConversationDisposition(response.conversationDisposition)
                setCompleteButtonOn(!!response.workflowState && !!response.workflowState.closedState)
            });
    }

    const handleClearDisposition = () => {
        setCompleteButtonOn(false)
        onConversationUpdate(conversationId, { conversationDisposition: { name: "NONE" } });
        setDispostionAnchorEl(null);
    };

    const handleDispositonMenuSelect = (event, disposition) => {
        setCompleteButtonOn(false)
        onConversationUpdate(conversationId, { conversationDisposition: disposition });
        setDispostionAnchorEl(null);
    };

    const getCompleteConfirmationModalContent = (closeAfterSaving) => {

        let cancelCallback = () => {
            closeAfterSaving ? setCompleteAndCloseConfirmationModalOpen(false) : setCompleteConfirmationModalOpen(false);
            setAddendumsToBeSaved([]);
        }

        const ADDENDUM_TAB = activeTab === 'addendum';

        return (
            <div>
                <div className={"alertBody"}>
                    <div className={"alertTitle"}>Complete {ADDENDUM_TAB ? 'Addendum' : 'Record'}</div>
                    <div className={"alertMessage"}>
                        <div>By checking this box, I am attesting to the data in this {ADDENDUM_TAB ? 'addendum' : 'record'} is true and accurate.</div>
                        <input
                            onChange={() => setDataCompleteChecked(!isDataCompleteChecked)}
                            type="checkbox"
                            checked={isDataCompleteChecked}
                        /> Confirm
                    </div>
                </div>

                <div className={"alertActions dualActions"}>
                    <div onClick={cancelCallback} className={"alertAction button action medium"}>
                        <div className={"buttonTitle"}>Cancel</div>
                    </div>

                    <div onClick={() => {
                        if (isDataCompleteChecked) {
                            if (ADDENDUM_TAB) {
                                saveAddendums();
                                setCompleteConfirmationModalOpen(false);
                            }
                            else if (closeAfterSaving) {
                                saveEHR(true);
                                setCompleteAndCloseConfirmationModalOpen(false);
                            }
                            else {
                                saveEHR(false)
                                setCompleteConfirmationModalOpen(false)
                            }
                        }
                    }}
                        className={`alertAction button medium ${isDataCompleteChecked ? "confirm" : "disabled"}`}>
                        <div className={"buttonTitle"}>Sign {closeAfterSaving ? ' and close' : ''}</div>
                    </div>
                </div>
            </div>
        )
    }

    const closeSignModal = () => {
        setCompleteConfirmationModalOpen(false);
        setAddendumsToBeSaved([]);
    }

    return (
        <div className="ehrModal">
            {(!loadingFields && loadingRecords) ?
                <div className="loadingRecords">
                    <div className="message">
                        Loading records...
                    </div>
                </div> : null
            }

            <div className="headerInfo">
                <div className="row">
                    <div className="field">
                        <div className="name">
                            Name:
                        </div>
                        <div className="value">
                            {recordsData.demographics && (recordsData.demographics.firstName || recordsData.demographics.lastName)
                                ? `${recordsData.demographics.firstName ? recordsData.demographics.firstName : ''} ${recordsData.demographics.lastName ? recordsData.demographics.lastName : ''}`
                                : "-"}
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">
                            D.O.B.:
                        </div>
                        <div className="value">
                            {recordsData.demographics && recordsData.demographics.dateOfBirth
                                ? moment(recordsData.demographics.dateOfBirth).format("MM/DD/YYYY")
                                : "-"}
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">
                            Age:
                        </div>
                        <div className="value">
                            {recordsData.demographics && recordsData.demographics.age
                                ? recordsData.demographics.age
                                : "-"}
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">
                            Gender:
                        </div>
                        <div className="value">
                            {recordsData.demographics && recordsData.demographics.gender
                                ? recordsData.demographics.gender
                                : "-"}
                        </div>
                    </div>


                </div>

                <div className="row">
                    <div className="field">
                        <div className="name">
                            MRN:
                        </div>
                        <div className="value">
                            {recordsData.demographics && recordsData.demographics.id
                                ? recordsData.demographics.id
                                : "-"}
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">
                            FIN:
                        </div>
                        <div className="value">
                            {recordsData.interaction && recordsData.interaction.id
                                ? recordsData.interaction.id
                                : "-"}
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">
                            Created At:
                        </div>
                        <div className="value">
                            {recordsData.interaction && recordsData.interaction.createdAt
                                ? moment.utc(recordsData.interaction.createdAt).local().format("MM/DD/YYYY hh:mm:ss a")
                                : "-"}
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">
                            Updated At:
                        </div>
                        <div className="value">
                            {recordsData.interaction && recordsData.interaction.updatedAt
                                ? moment.utc(recordsData.interaction.updatedAt).local().format("MM/DD/YYYY hh:mm:ss a")
                                : "-"}
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">
                            Signed At:
                        </div>
                        <div className="value">
                            {recordsData.eSignedAt
                                ? moment.utc(recordsData.eSignedAt).local().format("MM/DD/YYYY hh:mm:ss a")
                                : "-"}
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">
                            Signed By:
                        </div>
                        <div className="value">
                            {recordsData.signatoryName ? recordsData.signatoryName : "-"}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="field">
                        <div className="name">
                            Chief Compliant:
                        </div>
                        <div className="value">
                            {recordsData.interaction && recordsData.interaction.chiefComplaint
                                ? recordsData.interaction.chiefComplaint
                                : "-"}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="field">
                        <div className="name">
                            Incident Number:
                        </div>
                        <div className="value">
                            {recordsData.hasLinkedEncounter
                                ? (recordsData.incidentNumber ? recordsData.incidentNumber : "-")
                                :
                                <div className={`tableCell titledSelectCell incidentNumber`}>
                                    <InputCell defaultValue={recordsData.incidentNumber}
                                        type="text" onChange={(e) => onChangeIncidentNumber(e.target.value)}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="field">
                        <div className="name">
                            Intake:
                        </div>
                        <div className="value">
                            <div className="tableCell titledSelectCell intake">
                                <CreatableSelect
                                    styles={{ width: "100px" }}
                                    className="selectCell workflowTextBackSetting"
                                    isClearable
                                    value={intakeOptions.find(option => option.value === recordsData?.intake)}
                                    onChange={onChangeIntake}
                                    classNamePrefix="selectCell"
                                    options={intakeOptions}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="field">
                        <div className="name">
                            Disposition:
                        </div>
                        <div className="value menuButton">
                            <div className="icon"></div>
                            <div className="cellBody" onClick={handleDispositionMenuClick}>
                                <div className="detail">{conversationDisposition ? conversationDisposition.name : "None"}</div>
                            </div>
                            <div className="accessory popUpIndicator"></div>
                            <Menu
                                id="simple-menu"
                                anchorEl={dispostionAnchorEl}
                                keepMounted
                                open={!isFormLocked && Boolean(dispostionAnchorEl)}
                                onClose={(event, reason) => reason === 'backdropClick' ? setDispostionAnchorEl(null) : null}
                            >
                                <MenuItem onClick={handleClearDisposition}>None</MenuItem>
                                {

                                    dispositions ? (
                                        _.map(_.sortBy(dispositions, function (d) { return d.name; }), function (d, i) {
                                            let selected = conversationDisposition && conversationDisposition.id === d.id;
                                            return (
                                                <MenuItem
                                                    key={d.id}
                                                    selected={selected}
                                                    onClick={event => handleDispositonMenuSelect(event, d)}
                                                >
                                                    {d.name}
                                                </MenuItem>
                                            )
                                        })
                                    ) : null
                                }
                            </Menu>
                        </div>
                    </div>

                    {recordsData.epcrRecordNumber ?
                        <div className="field">
                            <div className="name">
                                Last Data from ePCR:
                            </div>
                            <div className="value">
                                {recordsData.epcrFetchedAt
                                    ? moment.utc(recordsData.epcrFetchedAt).fromNow()
                                    : "-"}
                                {!isFormLocked &&
                                    <Tooltip title={"Only fields that are empty will be written on a refresh"}>
                                        <div onClick={() => refreshEPCR()} className={`button tint medium`} style={{ marginLeft: "20px" }}>
                                            <div className="title">Refresh</div>
                                        </div>
                                    </Tooltip>
                                }

                                {recordsData.finalPdfUrl ?
                                    <div className={`button tint medium`} style={{ marginLeft: "20px" }}>
                                        <a href={recordsData.finalPdfUrl}><div className="title">Download ePCR PDF</div></a>
                                    </div> : null}
                            </div>
                        </div> : null}
                </div>
            </div>
            <div className="content">
                <Modal
                    content={getCompleteConfirmationModalContent(isCompleteAndCloseConfirmationModalOpen)}
                    dialogClassName={"alertModal"}
                    size="xs"
                    handleClose={closeSignModal}
                    handleOpen={() => setCompleteConfirmationModalOpen(true)}
                    open={isCompleteConfirmationModalOpen || isCompleteAndCloseConfirmationModalOpen}
                />
                <div className="secondaryNavBar">
                    <div className={`segmentedVerticalBar`} >
                        {!loadingFields ?
                            <div className="selection" style={{ top: (sections.map(s => s.name).indexOf(activeTab) * (100 / sections.length)) + '%', height: (100 / sections.length) + '%' }}>
                            </div> : null}

                        {
                            sections.map(section => (
                                <div key={section.name} onClick={() => changeTab(section.name)} className={`segment ${section.name === activeTab ? 'selected' : ''}`}>
                                    <div className="title">{section.displayName}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                {getTabContent(activeTab)}
            </div>

            <div className="actionBar">
                {!saving ? <><div className="actions left">
                    <div onClick={() => window.close()} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>
                </div>

                    {activeTab != 'addendum' && <div className="actions right">
                        {isCompleteButtonOn && !isFormLocked &&
                            <div onClick={() => setCompleteConfirmationModalOpen(true)} className={`button tint medium`} style={{ backgroundColor: "red" }}>
                                <div className="title" style={{ color: "white" }}>Complete</div>
                            </div>
                        }
                        <div onClick={() => saveEHR(true)} className={`button tint medium`}>
                            <div className="title">Save and Close</div>
                        </div>
                        <div onClick={() => saveEHR(false)} className={`button confirm medium`}>
                            <div className="title">Save</div>
                        </div>
                    </div>}
                    <div className="separator"></div></> : <CircularProgress />}
            </div>
        </div>

    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EHRModal));