import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";

import { fetchAllEncounters } from "@/shared/encounter/actions";
import { fetchQueuedTriggers, fetchTodayRuleTriggerCounts } from "@/shared/rules/actions";
import ActivityHeader from "./ActivityHeader";
import ActivityEncounterList from "./ActivityEncounterList";
import ActivityMatchedRules from "./ActivityMatchedRules";
import ActivityQueuedRules from "./ActivityQueuedRules";
import ActivitySurvey from "./ActivitySurvey";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getEncounterPage: () => dispatch(fetchAllEncounters()),
    getRuleTriggerCounts: () => dispatch(fetchTodayRuleTriggerCounts()),
    getQueuedTriggers: (page) => dispatch(fetchQueuedTriggers(page))
})

function ActivityIndex(props) {
    const ALL_TAB = "ALL";
    const REVIEW_TAB = "REVIEW";
    const MATCHED_TAB = "MATCHED";
    const SURVEY = "SURVEY";

    const [activeTab, setActiveTab] = React.useState(MATCHED_TAB);

    const tabs = [
        MATCHED_TAB,
        REVIEW_TAB,
        SURVEY,
        ALL_TAB
    ]

    React.useEffect(() => {
        props.getRuleTriggerCounts();
        props.getQueuedTriggers(0);
        if (props.match.params && props.match.params.tab && _.indexOf(tabs, props.match.params.tab) >= 0) {
            setActiveTab(props.match.params.tab);
        }
        else {
            setActiveTab(MATCHED_TAB);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTabTitle = (tabKey) => {
        // eslint-disable-next-line default-case
        switch (tabKey) {
            case ALL_TAB:
                return "All";
            case REVIEW_TAB:
                let reviewCount = "";
                if (props.rules.queuedTriggersPageInfo.totalElements > 0) {
                    reviewCount = " (" + props.rules.queuedTriggersPageInfo.totalElements + ")";
                }
                return "Review" + reviewCount;
            case SURVEY:
                return "Surveys";
            case MATCHED_TAB:
                return "Matched";
        }
    }

    const getTabContents = (tabKey) => {
        // eslint-disable-next-line default-case
        switch (tabKey) {
            case ALL_TAB:
                return (
                    <div>
                        <div className="activitySection sectionInsights">
                            <ActivityHeader ruleTriggerCounts={props.rules.ruleTriggerCounts} />
                        </div>
                        <ActivityEncounterList />
                    </div>
                );
            case REVIEW_TAB:
                return (
                    <ActivityQueuedRules />
                );
            case MATCHED_TAB:
                return (
                    <ActivityMatchedRules />
                );
            case SURVEY:
                return (
                    <ActivitySurvey />
                )
        }
    }

    const setTab = (tabKey) => {
        setActiveTab(tabKey);
        props.history.push("/activity/" + tabKey);
    }

    return (
        <div className="contentView activityView fsMask">
            <div className="navBar">
                <div className="navTitle">
                    <div className="title">Activity</div>
                </div>

                <div className="actions">
                    <div className={`segmentedBar index${_.indexOf(tabs, activeTab)}`} style={{ width: "320px" }}>
                        {
                            _.map(tabs, function (t, tk) {
                                return (
                                    <div onClick={() => setTab(t)} className={`segment ${t === activeTab ? 'selected' : ''}`} key={tk}>
                                        <div className="title">{getTabTitle(t)}</div>
                                    </div>
                                );
                            })
                        }
                        <div className="selection" style={{ width: (100 / tabs.length) + '%' }} />
                    </div>
                </div>

                <div className="separator"></div>
            </div>

            <div className="scrollView">
                {getTabContents(activeTab)}
            </div>

        </div>
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityIndex));