import React from "react";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import LocalTimeStamp from "../../common/LocalTimeStamp";
import SurveyResults from "../survey/SurveyResults";
import { getSurveyOriginationMethodValue } from "../../common/SurveyUtils";
import { Button, FilterButton } from "../../components/Buttons";
import Popup from "reactjs-popup";
import { AssetActivityConsortiumIndicator } from "../../icons/Assets";
import _ from "lodash";
import { useQuery } from "../../utils/utils";
import ConfirmModal from "../../common/ConfirmModal";
import { CircularProgress } from "@material-ui/core";

const mapStateToProps = state => ({
    surveyStatus: state.surveyStatus,
    user: state.user,
})

function SurveyStatusDetail(props) {
    const { surveyStatus } = props
    switch (surveyStatus) {
        case 'COMPLETE':
            return (
                <div>Answered</div>
            )
        case 'SENT':
            return (
                <div>Sent</div>
            )
        case 'CONTACT_DUPLICATE':
            return (
                <div>Contact Duplicate</div>
            )
        case 'CREATED':
            return (
                <div>Created</div>
            )
        case 'MISMATCH_INBOUND_MESSAGE':
            return (
                <div>Mismatched Message</div>
            )
        default:
            return (
                <div>{props.surveyStatus}</div>
            )
    }
}

function SurveyStatusButton(props) {
    const { surveyStatus } = props
    switch (surveyStatus) {
        case 'COMPLETE':
            return (
                <Button
                    style="primaryProminent"
                    size="small"
                    title="View Answers"
                />
            )
        case 'SENT':
            return (
                <Button
                    style="secondary"
                    size="small"
                    title="Sent"
                />
            )
        case 'CONTACT_DUPLICATE':
            return (
                <Button
                    style="disabled"
                    size="small"
                    title="Contact Duplicate"
                />
            )
        case 'CREATED':
            return (
                <Button
                    style="secondary"
                    size="small"
                    title="Created"
                />
            )
        case 'MISMATCH_INBOUND_MESSAGE':
            return (
                <Button
                    style="secondary"
                    size="small"
                    title="Mismatch Inbound Message"
                />
            )
        default:
            return (
                <Button
                    style="disabled"
                    size="small"
                    title={props.surveyStatus}
                />
            )
    }
}

function ActivitySurvey(props) {
    const [surveyInstances, setSurveyInstances] = React.useState([]);
    const [activePage, setActivePage] = React.useState({});
    const [page, setPage] = React.useState(0);
    const [searchFocused, setSearchFocused] = React.useState(false);
    const [searchVal, setSearchVal] = React.useState("");
    const [searchInput, setSearchInput] = React.useState("");
    const [filters, setFilters] = React.useState([]);
    const searchRef = React.createRef();
    const filterRef = React.useRef();
    const [autoOpenModal, setAutoOpenModal] = React.useState(useQuery().get("openSurveyResults"));
    const [loading, setLoading] = React.useState(false);

    const surveyInstanceIdParam = useQuery().get("surveyInstanceId");

    const FILTERS = [
        {
            key: "RESPONDED",
            title: "Responded"
        },
        {
            key: "HAS_TASK",
            title: "Has Task"
        }
    ];

    React.useEffect(() => {
        loadSurveys()
    }, []);

    const loadFirstPage = (searchParam) => {
        setPage(0);
        loadSurveys(true, 0, searchParam);
    }

    const toggleFilter = (filter) => {
        if (_.includes(filters, filter)) {
            _.remove(filters, (remFilter) => remFilter === filter);
            setFilters(Object.assign([], filters));
        } else {
            filters.push(filter);
            setFilters(Object.assign([], filters));
        }
        filterRef.current.close();
        loadFirstPage(searchRef.current.value);
    }

    const loadSurveys = (isSearch = false, pageNum = 0, searchParam = "", filtersParam = filters) => {
        let init = {
            queryStringParameters: {
                page: pageNum,
                search: searchParam,
                size: 30
            }
        }

        if (surveyInstanceIdParam)
            init.queryStringParameters.surveyInstanceId = surveyInstanceIdParam;

        if (filtersParam) {
            _.forEach(filtersParam, (f) => {
                // eslint-disable-next-line default-case
                switch (f) {
                    case "RESPONDED":
                        init.queryStringParameters.statuses = ["STARTED", "COMPLETE"];
                        break;
                    case "HAS_TASK":
                        init.queryStringParameters.hasTask = true;
                }
            })
        }

        return API.get('Core', '/api/v1/survey/instance', init)
            .then(response => {
                // if Searching then just set the content
                if (isSearch) setSurveyInstances(response.content);
                else setSurveyInstances(surveyInstances.concat(response.content));

                setActivePage(response);
            },
                error => {
                    toast("Could not load survey results: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const onSurveyInstanceChange = (s) => {
        let instance = _.findIndex(surveyInstances, function (si) {
            return si?.surveyInstance?.id === s?.surveyInstance?.id;
        })
        surveyInstances[instance] = s;
        setSurveyInstances([].concat(surveyInstances));
    }

    const loadMore = () => {
        let nextPage = page + 1;
        setPage(nextPage);
        loadSurveys(false, nextPage, searchRef.current.value);
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            setSearchVal(searchRef.current.value);
            loadFirstPage(searchRef.current.value);
        }
    }

    const clearSearch = () => {
        setSearchInput("");
        setSearchVal("");
        loadFirstPage("");
    }

    const onSearchFocused = () => {
        setSearchFocused(true);
    }

    const onSearchBlurred = () => {
        setSearchFocused(false);
    }

    const onSearchChange = (e) => {
        setSearchInput(searchRef.current.value);
    }

    const canAutoOpenModal = () => {
        return surveyInstances && surveyInstances.length === 1 && autoOpenModal === '1'
    }

    const showDeleteButton = () => props?.user?.aboutMe?.user?.userType === "INTERNAL_SUPPORT";

    const deleteSurveyInstance = survey => {
        const INSTANCE_ID = survey?.surveyInstance?.id;

        if(INSTANCE_ID != null) {
            API.del('Core', `/api/v1/survey/instance/${INSTANCE_ID}`)
            .then(() => {
                setLoading(true);
                return loadSurveys(true);
            })
            .then(() => setLoading(false));   
        }
    }

    return (
        <div>
            <div className="secondaryNavBar">
                <div className="navTitle">
                    <div className="viewName">Surveys</div>
                    <div className="counterBadge">
                        <div className="counterValue">{activePage.totalElements}</div>
                    </div>
                </div>

                <div className="navActions">
                    <Popup closeOnDocumentClick ref={filterRef} position={"bottom left"} trigger={(<div>
                        <FilterButton
                            buttonState={filters && filters.length ? "isFiltered" : ""}
                            onFilterClear={toggleFilter}
                            enabledFilters={filters && filters.length > 0 ? _.map(filters, function (f) {
                                return _.find(FILTERS, function (filt) { return filt.key == f });
                            }) : ""}
                            addClass="navAction"
                        /></div>
                    )}>
                        <div className={"pullDownMenu"}>
                            {
                                _.map(FILTERS, function (f) {
                                    return (
                                        <div key={f.key} className={`menuCell ${_.includes(filters, f.key) ? `selected` : ''}`} onClick={toggleFilter.bind(this, f.key)}>
                                            <div className="menuItemTitle">{f.title}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Popup>

                    <div className={`searchInput ${searchFocused ? "isFocused" : ""} ${(searchInput.length > 0) ? "hasInput" : ""}`}>
                        <div className="icon" />
                        <input value={searchInput} onChange={onSearchChange} placeholder={"Search Surveys"} onFocus={onSearchFocused} onBlur={onSearchBlurred} className="input" onKeyDown={keyPress} type="text" ref={searchRef} />
                        <div className="clear" onClick={clearSearch}></div>
                    </div>
                </div>
            </div>

            {!loading ? <div className="activitySection sectionSurveys">
                {
                    _.map(surveyInstances, (s) => {
                        return (
                            <SurveyResults key={s.surveyInstance.id} surveyInstanceId={s.surveyInstance.id} onChange={onSurveyInstanceChange.bind(this)} opened={canAutoOpenModal()} setAutoOpenModal={setAutoOpenModal}>
                                <div className="tableCell activitySurveyCell">

                                    <div className="cellDetails">
                                        {
                                            s.surveyInstance.review ? (
                                                <div className="surveyCellDetail">
                                                    <div className="reviewIndicator">
                                                        <div className="indicatorTitle">REVIEW</div>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                        {s.surveyInstance.tenantId !== props.user.aboutMe.currentTenant.id ? (
                                            <div className="bodyColumn columnConsortium">
                                                <div className="consortiumIndicator">
                                                    <AssetActivityConsortiumIndicator className="indicatorIcon" />
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="surveyCellDetail detailPrimary">
                                            <div className="detailTitle">{s.surveyInstance.surveyName}</div>
                                            <div className="detailValue"><LocalTimeStamp utcTimestamp={s.surveyInstance.createdAt} format={"MM/DD/YYYY, h:mm:ss a"} /></div>
                                        </div>

                                        <div className="surveyCellDetail detailSecondary">
                                            <div className="detailTitle">{s.surveyInstance.contact.readableName}</div>
                                            <div className="detailValue"><SurveyStatusDetail surveyStatus={s.surveyInstance.status} /></div>
                                        </div>

                                        <div className="surveyCellDetail detailTertiary">
                                            <div className="detailTitle">{getSurveyOriginationMethodValue(s)}</div>
                                            <div className="detailValue">Origin</div>
                                        </div>
                                        {
                                            s.tasks && s.tasks[0] ? (
                                                <div className="surveyCellDetail">
                                                    <div className="detailTitle">{s.tasks[0].taskTypeName} ({s.tasks[0]['status']})</div>
                                                    <div className="detailValue">Task</div>
                                                </div>
                                            ) : null
                                        }
                                    </div>


                                    <div className="cellActions" onClick={e => e.stopPropagation()}>
                                        {
                                            showDeleteButton() &&
                                            <ConfirmModal
                                            title="Delete"
                                            text={"Are you sure you want to delete this survey?"}
                                            onConfirm={() => deleteSurveyInstance(s)}
                                            onCancel={() => {}}
                                            confirmTitle={"Delete Survey"}>      
                                                <div className="button destructive small">
                                                            <div className="title">Delete</div>
                                                        </div>
                                            </ConfirmModal>
                                        }
                                        <SurveyStatusButton surveyStatus={s.surveyInstance.status} />
                                    </div>
                                </div>
                            </SurveyResults>
                        )
                    })
                }
                {
                    (activePage && !activePage.last) ?
                        (
                            <div onClick={loadMore} className="encountersExpander">
                                <div className="expanderButton">
                                    <div className="title">Show More</div>
                                    <div className="icon" />
                                </div>
                            </div>
                        ) : null
                }
            </div> : <CircularProgress />}
        </div>
    );
}

export default connect(mapStateToProps)(ActivitySurvey);
