import React, { useEffect } from 'react';
import getOidcServiceInstance from "./EIDOidcSingletonService";
import { CircularProgress } from "@material-ui/core";

const SSORedirection = () => {
    useEffect(() => {
        const handleSSORedirection = async () => {
            const oidcInstance = await getOidcServiceInstance();
            oidcInstance?.signIn();
        }

        handleSSORedirection();
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress size="5rem" />
        </div>
    );
};

export default SSORedirection;