import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from '../common/Copyright';
import { Auth, Hub } from 'aws-amplify';
import { toast } from 'react-toastify';


const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '400px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    '#form-container': {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function VerifyContact(props) {
    const classes = useStyles();

    const codeRef = React.createRef();
    const [attr, setAttr] = React.useState("email");
    const [codeSent, setCodeSent] = React.useState(false);

    if (props.authState !== 'verifyContact') {
        return null;
    }

    console.log(props.authData);

    async function submitConfirm(e) {
        e.preventDefault();
        Auth.verifyCurrentUserAttributeSubmit(attr, codeRef.current.value)
            .then(() => {
                Hub.dispatch('verify', {
                    event: 'verified', data: props.authData
                })
            }).catch(e => {
                toast("Could not confirm code", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            });

    }

    async function sendCode(e) {
        e.preventDefault();
        Auth.verifyCurrentUserAttribute(attr)
            .then(() => {
                toast("Code Sent! Check your email.", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
            }).catch((e) => {
                toast("Could not send code", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            });
        setCodeSent(true);
    }

    return (
        <div className="verifyContact">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>

                <div className="gatekeeperTitle">Verify Contact</div>

                <div className={classes['#form-container']} noValidate>
                    <TextField
                        autoComplete="email"
                        name="email"
                        variant="outlined"
                        disabled
                        fullWidth
                        id="email"
                        label="Email"
                        value={props.authData.username}
                    />
                    {
                        codeSent ? (
                            <div>
                                <TextField
                                    autoComplete="code"
                                    name="code"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="code"
                                    label="Verification Code"
                                    inputRef={codeRef}
                                    autoFocus
                                />
                                <Button
                                    type="submit"
                                    color="secondary"
                                    className={classes.submit}
                                    onClick={sendCode}
                                >
                                    Send another
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={submitConfirm}
                                >
                                    Confirm
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={sendCode}
                                >
                                    <div className="title">Send Code</div>
                                </Button>
                            </div>
                        )
                    }
                </div>
            </div>
            <Copyright className="copyright" />
        </div>
    );
}