import React from 'react';
import ReactDOM from "react-dom/client";
import {Provider} from 'react-redux'

import store from '@/shared/store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {setupAmplify} from './setupAmplify';
import { EidAuthProvider } from './eid/EIDAuthContext';
import './i18n';

setupAmplify();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <EidAuthProvider>
            <App/>
        </EidAuthProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
