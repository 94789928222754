import React from 'react';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import InputCell from "@/common/form/InputCell";

export default function CreateConsortium({ onSuccess }) {

    const nameRef = React.createRef()

    async function createConsortium(e) {
        e.preventDefault();
        let consortiumInfo = {
            name: nameRef.current.value
        };

        await API.post('Core', '/api/v1/consortium', { body: consortiumInfo })
            .then(() => {
                toast("Successfully created a new consortium!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                onSuccess();
            })
            .catch((err) => {
                toast(err.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            });
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection">
                        <InputCell
                            placeholder={"Super Consortium"}
                            required
                            id="consortiumName"
                            label="Consortium Name"
                            inputRef={nameRef}
                        />
                    </div>

                    <div className="tableSection">
                        <div className={`button large tintProminent`} onClick={createConsortium}>
                            <div className="title">Create Consortium</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}