import React from 'react';

function SurveyResultsDetailsAttribute(props) {
    return (
        <div className="attributeCell">
            <div className="attributeTitle">{props.attributeTitle}</div>
            <div className="attributeDetail">{props.attributeDetail}</div>
            <div className="separator" />
        </div>
    );
}

export default (SurveyResultsDetailsAttribute);