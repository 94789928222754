import React from "react"

function SatisfactionPersonnelFeedbackStatCell(props) {
    return (
        <div className="satisfactionPersonnelFeedbackStatCell">
            <div className="statValue">{props.value}</div>
            <div className="statTitle">{props.title}</div>
        </div>
    )
}

export default SatisfactionPersonnelFeedbackStatCell
