import React from "react";
import _ from "lodash";

import Modal from "@/common/Modal";
import moment from '@/common/momentConfig';

import ProviderRotationCell from "./ProviderRotationCell";

export default function ProviderRotation(props) {
  var {rotation} = props;

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getRotationBody = () => {
    return (
          <div className="content">
            <div className="tableView testedRules">
              <div className="tableSection">
                <div className="sectionTitle">Working Now</div>
                  {_.map(_.sortBy(_.filter(rotation.providerRotationMembers, function (p) {
                      return (p.working && p.recentConversation == null)
                  }), function(pr){
                      return pr.startedWorkingAt
                  }), function(prm){
                      return (
                          <ProviderRotationCell
                              providerStatus="clockedIn"
                              providerName={prm.providerUser.firstName + " " + prm.providerUser.lastName}
                              providerType="doctor"
                              providerStarted={moment.utc(prm.startedWorkingAt).fromNow()}
                              providerActivity="Last Shift"
                          />
                      )
                  })}
                  {_.map(_.sortBy(_.filter(rotation.providerRotationMembers, function (p) {
                      return (p.working && p.recentConversation != null && p.recentConversation.workflowState.closedState)
                  }), function(pr){
                      return pr.recentConversation.createdAt
                  }), function(prm){
                      return (
                          <ProviderRotationCell
                              providerStatus="clockedIn"
                              providerName={prm.providerUser.firstName + " " + prm.providerUser.lastName}
                              providerType="doctor"
                              providerStarted={moment.utc(prm.startedWorkingAt).fromNow()}
                              providerActivity={moment.utc(prm.recentConversation.createdAt).fromNow()}
                          />
                      )
                  })}
                  {_.map(_.sortBy(_.filter(rotation.providerRotationMembers, function (p) {
                      return (p.working && p.recentConversation != null && !p.recentConversation.workflowState.closedState)
                  }), function(pr){
                      return pr.recentConversation.createdAt
                  }), function(prm){
                      return (
                          <ProviderRotationCell
                              providerStatus="active"
                              providerName={prm.providerUser.firstName + " " + prm.providerUser.lastName}
                              providerType="doctor"
                              providerStarted={moment.utc(prm.startedWorkingAt).fromNow()}
                              providerActivity="Active Now"
                          />
                      )
                  })}
              </div>

              <div className="tableSection sectionNotWorking">
                <div className="sectionTitle">Not Working</div>
                {_.map(rotation.providerRotationMembers, function (rm) {
                      if (!rm.working) {
                        return (
                            <ProviderRotationCell
                                providerStatus="clockedOut"
                                providerName={rm.providerUser.firstName + " " + rm.providerUser.lastName}
                                providerType="doctor"
                                providerFinished={rm.stoppedWorkingAt != null ? moment.utc(rm.stoppedWorkingAt).fromNow() : null}
                                providerActivity=""
                            />
                        )
                      }
                    }
                )}
              </div>
            </div>
            <div className={"actionBar"}>
              <div onClick={handleClose} className={"button action medium"}>
                <div className={"buttonTitle"}>Done</div>
              </div>
            </div>
          </div>
    )
  }

  return (
      <Modal
          button={(<div onClick={handleOpen}>{props.children}</div>)}
          content={getRotationBody()}
          dialogClassName={"modalDialog providerRotationView"}
          title={"Provider Rotation"}
          size="xl"
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
      />
  )
}
