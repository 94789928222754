import {
  GET_USER_ME,
  GET_USER_ME_SUCCESS,
  GET_USER_ME_FAILURE,
  GET_USER_SYSTEM_PRIVILEGES,
  GET_USER_SYSTEM_PRIVILEGES_SUCCESS,
  GET_USER_SYSTEM_PRIVILEGES_FAILURE,
  INVITE_USER,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  INVITE_USER_CLEAR,
  LIST_PROVIDERS,
  LIST_PROVIDERS_FAILURE,
  LIST_PROVIDERS_SUCCESS, LIST_ROLES_SUCCESS,
  SEARCH_PROVIDERS_SUCCESS, SEARCH_PROVIDERS_GROUPS_SUCCESS,
  PRESENCE_MEMBER_ADDED_EVENT,
  PRESENCE_MEMBER_REMOVED_EVENT,
  PRESENCE_SUBSCRIPTION_SUCCEEDED_EVENT,
  TOGGLE_THEME_SUCCESS, LIST_FEATURES_SUCCESS
} from './actions'

import {
  START,
  SUCCESS,
  ERROR
} from '../lifecycleStates';
import {SEARCH_CLIENTS_SUCCESS} from "../client/actions";
import {getChannel} from 'pusher-redux';

const initialState = {
    aboutMe: {},
    aboutMeError: false,
    systemPrivileges: [],
    inviteUserLifecycleState: null,
    inviteUserError: "",
    providers: [],
    providersFiltered: [],
    providerFilteredGroups: [],
    features: [],
    roles: [],
    onlinePresenceMembers: []
}

const refreshPresenceMembers = (channelName) => {
    let channel = getChannel(channelName);
    return channel.members;
}

export default (state = initialState, action) => {
 switch (action.type) {
  case GET_USER_ME:
  case GET_USER_SYSTEM_PRIVILEGES:
  case GET_USER_SYSTEM_PRIVILEGES_FAILURE:
  	return state;
  	break;
  case GET_USER_ME_FAILURE:
    return Object.assign({}, state, {
        aboutMe: {},
        aboutMeError: true
    });
  case LIST_ROLES_SUCCESS:
      return {
          ...state,
          roles: action.payload
      };
  case GET_USER_ME_SUCCESS:
    return Object.assign({}, state, {
        aboutMe: action.payload,
        features: action.payload.currentTenant.features,
        aboutMeError: false
      });
  case GET_USER_SYSTEM_PRIVILEGES_SUCCESS:
    return Object.assign({}, state, {
      systemPrivileges: action.payload
    });
  case INVITE_USER:
    return Object.assign({}, state, {
      inviteUserLifecycleState: START
    });
  case INVITE_USER_FAILURE:
    return Object.assign({}, state, {
      inviteUserLifecycleState: ERROR,
      inviteUserError: action.error
    });
  case INVITE_USER_SUCCESS:
    return Object.assign({}, state, {
      inviteUserLifecycleState: SUCCESS
    });
  case INVITE_USER_CLEAR:
    return Object.assign({}, state, {
      inviteUserLifecycleState: null,
      inviteUserError: ""
    });
  case TOGGLE_THEME_SUCCESS:
      return Object.assign({}, state, {
          aboutMe: action.payload
      });
  case LIST_PROVIDERS_SUCCESS:
    return Object.assign({}, state, {
      providers: action.payload
    });
   case LIST_FEATURES_SUCCESS:
     return {
       ...state,
       features: action.payload
     };
   case SEARCH_PROVIDERS_SUCCESS:
       return {
           ...state,
           providersFiltered: action.payload
       };
   case SEARCH_PROVIDERS_GROUPS_SUCCESS:
     return {
         ...state,
         providerFilteredGroups: action.payload
     };
   case PRESENCE_SUBSCRIPTION_SUCCEEDED_EVENT:
   case PRESENCE_MEMBER_ADDED_EVENT:
   case PRESENCE_MEMBER_REMOVED_EVENT:
     let members = refreshPresenceMembers(action.channel);
     return Object.assign({}, state, {
        onlinePresenceMembers: members.members
     });
  default:
   return state
 }
}