import { Button, Checkbox, CircularProgress, IconButton, MenuItem, Select, TextField } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../common/ConfirmModal';
import EmailChips from '../../../common/EmailChips';
import TableDisplay from '../../../common/TableDisplay';
import { ACTIVITY_EMAIL_DIGEST, convertToTimeZone, TIMEZONES } from '../../../utils/utils';

const mapStateToProps = state => ({
    ...state
})

function EmailDigest(props) {
    const [digestsList, setDigestsList] = useState([]);
    const [deleting, setDeleting] = useState([]);
    const TIMEZONE = props?.user?.aboutMe?.currentTenant?.timezoneName;

    useEffect(() => {
        API.get('Core', `/api/v1/digest/settings?type=${props.type}`)
            .then(r => {
                setDigestsList(r);
            });
    }, []);

    let deleteDigest = (id) => {
        API.del('Core', `/api/v1/digest/settings/${id}`)
            .then(r => {
                var DIGEST_LIST = digestsList;
                DIGEST_LIST.splice(DIGEST_LIST.findIndex(dl => dl.id === id), 1);
                setDigestsList([...DIGEST_LIST]);
                toast('Digest deleted', { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
            })
            .catch(e => {
                console.error('Error digest delete ', e);
                toast(e.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR })
            }).finally(() => {
                let _deleting = deleting
                _deleting = _deleting.splice(_deleting.findIndex(d => d.id === id), 1)
                setDeleting(_deleting)
            });
    }

    let goToCreateDigest = () => {
        props.history.push(`/settings/create-${props.type.toLowerCase() || 'cpss'}-digest-email`,);
    }

    let goToEditDigest = (id) => {
        props.history.push(`/settings/edit-digest-email/${id}`);
    }

    let toggleDigest = (id, index) => {
        const DIGEST = digestsList[index];
        DIGEST.active = !DIGEST.active;

        API.put('Core', `/api/v1/digest/settings/${id}`, { body: DIGEST })
            .then(r => {
                const SETTING = r;

                if (SETTING) {
                    digestsList[index] = SETTING;
                    setDigestsList([...digestsList]);
                    toast.success(`Digest Setting ${r.active ? 'enabled' : 'disabled'}!`, { position: toast.POSITION.TOP_CENTER });
                }
            })
    }

    const COLUMNS = [
        { field: 'name', title: 'Name' },
        { field: 'frequency', title: 'Frequency' },
        {
            field: 'lastFireTime', title: 'Last Send', render: d => {
                return (<div>
                    {d.lastFireTime}
                </div>)
            }
        },
        {
            field: 'actions', title: 'Actions', render: d => {
                return (<div style={{ display: 'flex', alignItems: "center" }}>
                    <IconButton color="primary" onClick={() => goToEditDigest(d.id)}>
                        <Edit />
                    </IconButton>
                    {!deleting.includes(d.id) ?
                        <ConfirmModal
                            title={"Delete Digest"}
                            text={(<span>Are you sure you want to delete this email digest?</span>)}
                            onConfirm={() => {
                                setDeleting([...deleting, ...[d.id]])
                                deleteDigest(d.id)
                            }}
                            confirmTitle={"Delete"}
                        >
                            <IconButton color="primary">
                                <Delete />
                            </IconButton>
                        </ConfirmModal>
                        :
                        <CircularProgress size={18} />
                    }
                    <ConfirmModal
                        title={"Toggle Digest"}
                        text={(<span>Are you sure you want to {d.active ? 'disable' : 'enable'} this email digest?</span>)}
                        onConfirm={() => { toggleDigest(d.id, d.tableData.index) }}
                        confirmTitle={"Toggle"}
                    >
                        <Checkbox value={d.active} checked={d.active} />
                    </ConfirmModal>

                </div>);
            }
        },
    ];

    return (
        <div>
            <div className='addDigestButton' onClick={goToCreateDigest}>
                <Add />
                Add digest
            </div>
            <TableDisplay
                data={digestsList}
                title={props.title || 'Email digests'}
                columns={COLUMNS}
            />
        </div>
    );
}

export default withRouter(connect(mapStateToProps)(EmailDigest));