import React from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core";
import {API} from "aws-amplify";

import ConfirmModal from "@/common/ConfirmModal";
import Modal from "@/common/Modal";
import {fetchAllInterpreters} from "@/shared/conversation/actions";

import AddEditInterpreter from "./AddEditInterpreter";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getInterpreters : (fieldName) => dispatch(fetchAllInterpreters())
})

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const columns = [
    { id: 'language', label: 'Language', minWidth: 170 },
    { id: 'phoneNumber', label: 'Phone Number', minWidth: 170 },
    { id: 'extension', label: 'Extension', minWidth: 170 },
];

function InterpreterSettingsIndex( props ){

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState({});

    React.useEffect(() => {
        props.getInterpreters();
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.getInterpreters();
    };

    const handleSuccess = () => {
        setOpen(false);
        props.getInterpreters();
    };

    const deleteInterpreter = (interpreterId) => {
        API.del('Core', '/api/v1/interpreter/' + interpreterId)
            .then(response => {
                    props.getInterpreters();
                    toast("Deleted Interpreter!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                },
                error => {
                    toast("Could not delete interpreter." + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    const handleEditClose = (interpreter) => {
        editOpen[interpreter.id] = false;
        setEditOpen(Object.assign({}, editOpen));
    };
    
    const handleEditOpen = (interpreter) => {
        editOpen[interpreter.id] = true;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditSuccess = (interpreter) => {
        editOpen[interpreter.id] = false;
        setEditOpen(Object.assign({}, editOpen));
        props.getInterpreters();
    };

    let user = props.user;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Modal
                    button={(<Button variant="contained" color="primary" className={classes.button}>
                        Add Interpreter
                    </Button>)}
                    content={(
                        <AddEditInterpreter onCancel={handleClose} onSuccess={handleSuccess}/>
                    )}
                    title="Add Interpreter"
                    size="small"
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    open={open}
                    dialogClassName={"modalDialog editFollowUpQuestion"}
                />
            </Grid>
            <Grid xs={12}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.conversation.interpreters ? props.conversation.interpreters.map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns.map(column => {
                                        let value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key={'edit'}>
                                        <Modal
                                            button={
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                >Edit</Button>
                                            }
                                            content={
                                                <AddEditInterpreter
                                                    existingInterpreter={row}
                                                    onCancel={handleEditClose.bind(this, row)}
                                                    onSuccess={handleEditSuccess.bind(this, row)}
                                                />
                                            }
                                            title="Edit Interpreter"
                                            size="small"
                                            handleClose={handleEditClose.bind(this, row)}
                                            handleOpen={handleEditOpen.bind(this, row)}
                                            open={editOpen[row.id]}
                                        />
                                        <ConfirmModal
                                            title={"Delete Interpreter"}
                                            text={(<span>Are you sure you want to delete <b>{row.language}</b>?</span>)}
                                            onConfirm={deleteInterpreter.bind(this, row.id)}
                                            confirmTitle={"Delete Interpreter"}
                                        >
                                            <Button variant="contained" color="primary" className={classes.button}>
                                                Delete
                                            </Button>
                                        </ConfirmModal>
                                    </TableCell>
                                </TableRow>
                            );
                        }) : null}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(InterpreterSettingsIndex);