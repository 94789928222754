import React from 'react';

export default function PresenceIndicator({color}) {
    if (!color) {
        return null;
    }
    let clzName = "led-green";

    switch (color) {
        case "YELLOW":
            clzName = "led-yellow";
            break;
        case "RED":
            clzName = "led-red";
            break;
        case "GREEN":
            clzName = "led-green";
            break;
        default:
            return null;
    }

    return (
        <div className="led-box">
            <div className={clzName}></div>
        </div>
    )
}