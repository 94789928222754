import React from 'react'
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import Select from "react-select";

import InputCell from "@/common/form/InputCell";

function ManageInboundConfig({surveyDefinitions, onSuccess, onCancel}) {
    const [inboundConfig, setInboundConfig] = React.useState({});
    const [selectedSurvey, setSelectedSurvey] = React.useState({});
    const [selectedTaskType, setSelectedTaskType] = React.useState({});
    const [taskTypes, setTaskTypes] = React.useState([]);

    const startWordRef = React.createRef();

    React.useEffect(() => {
        loadConfig();
        loadTaskTypes();
    }, [])

    const loadConfig = () =>{
        API.get("Core", "/api/v1/survey/inbound-config")
            .then(res => {
                setInboundConfig(res);
                setSelectedSurvey(res.surveyDefinition);
                setSelectedTaskType(res.taskType);
            }, err => {
                toast("Could not load config: " + err.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            })
    }

    const loadTaskTypes = () =>{
        API.get("Core", "/api/v1/task/types")
            .then(res => {
                setTaskTypes(res);
            }, err => {
                toast("Could not load task types: " + err.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            })
    }

    const saveConfig = () =>{
        API.put("Core", "/api/v1/survey/inbound-config", {body: {
                taskType: selectedTaskType,
                startWord: startWordRef.current.value,
                surveyDefinition: selectedSurvey,
                enablePersonnelSurveys: inboundConfig.enablePersonnelSurveys
            }})
            .then(res => {
                setInboundConfig(res);
                onSuccess(res);
            }, err => {
                toast("Could not save config: " + err.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            })
    }

    const onSelectChange = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setSelectedSurvey(obj);
                break;
        }
    }

    const onTaskTypeChange = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setSelectedTaskType(obj);
                break;
            case "clear":
                setSelectedTaskType(null);
                break;
        }
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Overview</div>
                            <p className="cellFootnote">Configure the survey that should be sent if somebody texts your agencies survey number: <b>{inboundConfig.phoneNumber}</b></p>
                        </div>
                    </div>
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Select Survey</div>
                        </div>
                        <div className="tableCell titledSelectCell">
                            <div className="title">Inbound Survey</div>
                            <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                    onChange={onSelectChange}
                                    value={selectedSurvey ? selectedSurvey : "--"}
                                    options={surveyDefinitions}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                            />
                        </div>
                    </div>
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">What word starts the survey?</div>
                            <p className="cellFootnote">Start word should be one word and is not case sensitive</p>
                        </div>
                        <div className="tableCell titledSelectCell">
                            <InputCell
                                placeholder={""}
                                defaultValue={inboundConfig.startWord ? inboundConfig.startWord : "survey"}
                                required
                                id="start-word"
                                label="Start Word"
                                inputRef={startWordRef}
                            />
                        </div>
                        <div className="sectionTitle">
                            <p className="cellFootnote">Inbound messages not matching the start word will create a task for manual review.</p>
                        </div>
                        <div className="tableCell titledSelectCell">
                            <div className="title">Task Type</div>
                            <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                    onChange={onTaskTypeChange}
                                    value={selectedTaskType ? selectedTaskType : "--"}
                                    options={taskTypes}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    isClearable={true}
                            />
                        </div>
                    </div>
                    <div className="tableSection sectionName">
                        <div className={"sectionTitle"}>
                            <div className={"title"}>Personnel Surveys</div>
                        </div>
                        <div onClick={() =>
                        {
                            inboundConfig.enablePersonnelSurveys = !inboundConfig.enablePersonnelSurveys;
                            setInboundConfig(Object.assign({}, inboundConfig));
                        }}
                             className={`tableCell switchCell ${inboundConfig.enablePersonnelSurveys ? 'switchOn' : 'switchOff'}`}>
                            <div className="title">Enable</div>
                            <div className={`accessory accessorySwitch ${inboundConfig.enablePersonnelSurveys ? 'on' : 'off'}`}>
                                <div className="switchThumb"/>
                            </div>
                        </div>
                        {
                            inboundConfig.enablePersonnelSurveys ? (
                                <div className="sectionTitle">
                                    <p className="cellFootnote">A QR code will be generated for each Personnel that can be printed on business cards. Citizens will be prompted with a pre-filled text message that says the following to initiate a survey: <b>Please hit send to start your survey @[personnelId]</b></p>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveConfig} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>
    )
}

export default ManageInboundConfig;