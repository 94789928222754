import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ActivityDigestEmail from "../activity-digest-email/ActivityDigestEmail";

const mapStateToProps = state => ({
    ...state
});

function CreateDigest(props)
{
    let goBack = () => {
        props.history.goBack();
    }

    return (<div>
        <div className="addDigestButton" onClick={goBack}>
            <ArrowBackIos />
            Back
        </div>

        <ActivityDigestEmail frequency='DAILY' {...props}/>
    </div>);
}

export default withRouter(connect(mapStateToProps)(CreateDigest));