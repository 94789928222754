import React from 'react';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import Select from "react-select";

import InputCell from "@/common/form/InputCell";
import TenantSelect from "@/components/TenantSelect";

export default function CreateAgent({ onSuccess }) {

    const nameRef = React.createRef();
    const [tenant, setTenant] = React.useState(null);
    const [dataSources, setDataSources] = React.useState();
    const [existingDataSource, setExistingDataSource] = React.useState();

    React.useEffect(() => {
        setDataSources([]);
        if (tenant) {
            API.get('Core', '/api/v1/datasource/tenant/' + tenant.id)
                .then(response => {
                    setDataSources(response);
                },
                    error => {
                        toast("Could not load tenant data sources: " + error.response.data.message, {
                            position: toast.POSITION.TOP_CENTER,
                            type: toast.TYPE.ERROR
                        });
                    })
        }

    }, [tenant])

    async function createAgent(e) {
        e.preventDefault();

        if (!tenant || !nameRef.current.value) {
            toast("Must select tenant and enter name.", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }

        let agentInfo = {
            name: nameRef.current.value,
            tenant: tenant,
            dataSource: existingDataSource
        };

        await API.post('Core', '/api/v1/agents', { body: agentInfo })
            .then(() => {
                toast("Successfully created a new agent!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                onSuccess();
            })
            .catch((err) => {
                toast(err.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            });
    }

    const onDataSourceSelect = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setExistingDataSource(obj);
                break;
            case "clear":
                setExistingDataSource(null);
                break;
        }
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection">
                        <InputCell
                            placeholder={"Amazing Agent"}
                            required
                            id="agentName"
                            label="Agent Name"
                            inputRef={nameRef}
                        />
                    </div>
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Tenant</div>
                            <div className="subtitle">One Connector will be automatically created for this agent in the selected tenant.</div>
                        </div>
                        <TenantSelect onSelect={(ten) => setTenant(ten)} value={tenant} />
                    </div>
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">(OPTIONAL) Existing Tenant Data Source</div>
                            <div className="subtitle">If you select a data source, a new connector will not be created and instead the selected data source will be integrated as the connector.</div>
                        </div>
                        <Select className="selectCell" classNamePrefix="selectCell"
                            isDisabled={!tenant}
                            onChange={onDataSourceSelect}
                            value={existingDataSource ? existingDataSource : "--"}
                            options={dataSources}
                            isClearable={true}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id} />
                    </div>

                    <div className="tableSection">
                        <div className={`button large tintProminent`} onClick={createAgent}>
                            <div className="title">Create Agent</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}