import eidConfig from './EIDConfig';

const setUpEidSdkOidc = async () => {
    const eidSdk = window.eid;
    if (!eidSdk) {
        return;
    }
    await eidSdk.configure((configuration) => {
        configuration.openIdConnectConfiguration = {
            enabled: true,
            authority: eidConfig.oidc.authority,
            clientId: eidConfig.oidc.clientId,
            redirectUri: eidConfig.oidc.redirectUri,
            postLogoutRedirectUri: eidConfig.oidc.postLogoutRedirectUri,
            responseType: eidConfig.oidc.responseType,
            scope: eidConfig.oidc.scope,
            automaticSilentRenew: eidConfig.oidc.automaticSilentRenew
        };
    });

    return eidSdk?.oidc ?? null;
};

export default setUpEidSdkOidc;