import React from "react";
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";
import _ from "lodash";
import {connect} from "react-redux";

import {
    fetchEmailSetting,
    setEmailSetting,
    TRANSACTION_KEY
} from "@/shared/connect_config/actions";
import {ERROR, SUCCESS} from "@/shared/lifecycleStates";
import {clearTransaction} from "@/shared/transaction_state/actions";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getEmailSettings: () => dispatch(fetchEmailSetting()),
    saveEmailConfig: (body) => dispatch(setEmailSetting(body)),
    clearTransaction: (key) => dispatch(clearTransaction(key))
})

const useStyles = makeStyles(theme => ({
    panel: {
        marginTop: theme.spacing(1),
    },
    panelHeader: {
        marginLeft: theme.spacing(1)
    },
    domain: {
        whiteSpace: "nowrap",
        color: theme.palette.text.secondary
    },
    inboxNameInput: {
        margin: theme.spacing(1),

    },
    button: {
        margin: theme.spacing(3),
    },
    instructions: {
        margin: theme.spacing(1),
    }
}));

function ManageEmailSettings(props) {
    var {} = props;
    const classes = useStyles();
    const [loadActionsCalled, setLoadActionsCalled] = React.useState(false);
    const inboxNameRef = React.createRef()

    if(!loadActionsCalled){
        props.clearTransaction(TRANSACTION_KEY);
        props.getEmailSettings();
        setLoadActionsCalled(true);
    }

    const saveInbox = () => {
        let configObj = {
            inboxName: inboxNameRef.current.value
        }
        props.saveEmailConfig(configObj);
        props.clearTransaction(TRANSACTION_KEY);
    }

    let error = false;
    if(props.transactions[TRANSACTION_KEY]){
        if(props.transactions[TRANSACTION_KEY].state == ERROR){
            toast(props.transactions[TRANSACTION_KEY].errorMsg, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            error = true;
        }
        else if(props.transactions[TRANSACTION_KEY].state == SUCCESS){
            toast.success("Inbox Domain Successfully Claimed!", {position: toast.POSITION.TOP_CENTER});
            props.clearTransaction(TRANSACTION_KEY);
            props.getEmailSettings();
        }
    }

    return (
        <Grid className={classes.panel} item>
            <Paper elevation={2}>
                <Typography className={classes.panelHeader} variant={"h6"}>Email Settings</Typography>
                <Divider></Divider>
                <Typography className={classes.instructions} color={"textSecondary"}>Below is the email you should give clients if they want to email in with requests. Choose something like your clinic's name. Once it is received you will have an opportunity to route it to the correct person</Typography>
                <FormControl className={classes.inboxNameInput}>
                    <InputLabel>Inbox Name</InputLabel>
                    <FilledInput
                        error={error}
                        inputRef={inboxNameRef}
                        defaultValue={_.get(props, "connectConfig.email.inboxName")}
                        endAdornment={<InputAdornment className={classes.domain} position="end">@{_.get(props, "connectConfig.email.domain")}</InputAdornment>}
                    />
                </FormControl>
                <Button variant="contained" color="primary" className={classes.button} onClick={saveInbox}>
                    Save
                </Button>
            </Paper>
        </Grid>
    )

}

export default connect(mapStateToProps, mapDispatchToProps)(ManageEmailSettings);