import {API} from "aws-amplify";
import {toast} from "react-toastify";
import React from "react";
import Select from "react-select";


export default function TenantSelect({onSelect, value, isDisabled = false}){
    const [tenants, setTenants] = React.useState([]);

    const loadTenants = () => {
        API.get('Core', '/api/v1/support-tools/tenants')
            .then(response => {
                    setTenants(response);
                },
                error => {
                    toast("Could not get tenants: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
    }

    React.useEffect(() => {
        loadTenants();
    }, []);

    const onTenantSelect = (obj, action) => {
        switch (action.action) {
            case "select-option":
                onSelect(obj);
                break;
            case "clear":
                onSelect(null);
                break;
        }
    }

    if(!tenants || tenants.length == 0){
        return (<span>No Tenants</span>);
    }

    return (
        <Select className="selectCell" classNamePrefix="selectCell"
                isDisabled={isDisabled}
                onChange={onTenantSelect}
                value={value ? value : "--"}
                options={tenants}
                isClearable={true}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}/>
    );
}