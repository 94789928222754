import React from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core";
import {API} from "aws-amplify";

import ConfirmModal from "@/common/ConfirmModal";
import Modal from "@/common/Modal";
import {fetchAllTaskTypes, postTaskType, putTaskType} from "@/shared/tasks/actions";
import {fetchAllFollowUpQuestions} from "@/shared/survey/actions";
import AddEditQuestion from "@/routes/survey/AddEditQuestion";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getTaskTypes : () => dispatch(fetchAllTaskTypes()),
    getFollowUps : () => dispatch(fetchAllFollowUpQuestions())
})

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const columns = [
    { id: 'name', label: 'Follow Up Question Name', minWidth: 170 },
    { id: 'prompt', label: 'Follow Up Question Prompt', minWidth: 170 }
];

function FollowUpsIndex( props ){
    const EMPTY_QUESTION = {
        prompt: "",
        type: "COMMENT",
        questionOrder: 0
    };
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState({});
    const [question, setQuestion] = React.useState(EMPTY_QUESTION);

    React.useEffect(() => {
        props.getFollowUps();
        props.getTaskTypes();
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setQuestion(EMPTY_QUESTION);
        props.getFollowUps();
    };

    const handleEditClose = (followUpId) => {
        editOpen[followUpId] = false;
        setEditOpen(Object.assign({}, editOpen));
        props.getFollowUps();
    };

    const handleEditOpen = (followUpId) => {
        editOpen[followUpId] = true;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditSuccess = (followUpId) => {
        editOpen[followUpId] = false;
        setEditOpen(Object.assign({}, editOpen));
        props.getFollowUps();
    }

    const onAddFollowUpSubmit = (newFollowUp) => {
        setOpen(false);
        props.getFollowUps();
        setQuestion(EMPTY_QUESTION);
    }

    const onQuestionUpdate = (ques) => {
        //props.getFollowUps();
    }

    const deleteFollowUp = (followUpId) => {
        API.del('Core', '/api/v1/survey/followups/' + followUpId)
            .then(response => {
                    props.getFollowUps();
                    toast("Deleted Follow Up Question!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                },
                error => {
                    toast("Could not delete follow up question" + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }
    let user = props.user;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Modal
                    button={(<Button variant="contained" color="primary" className={classes.button}>
                        Add Follow Up
                    </Button>)}
                    content={(
                        <AddEditQuestion disabled={false} question={question} onUpdate={onQuestionUpdate} user={user} taskTypes={props.tasks.taskTypes} onCancel={handleClose} onSuccess={onAddFollowUpSubmit}/>
                        )}
                    title="Add Follow Up"
                    size="xxl"
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    open={open}
                    dialogClassName={"modalDialog editFollowUpQuestion"}
                />
            </Grid>
            <Grid xs={12}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.survey.followUps ? props.survey.followUps.map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns.map(column => {
                                        let value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key={'edit'}>
                                        <Modal
                                            button={(<Button variant="contained" color="primary" className={classes.button}>
                                                Edit
                                            </Button>)}
                                            content={(
                                                <AddEditQuestion disabled={false} question={row} existingQuestion={row} onUpdate={onQuestionUpdate} user={user} taskTypes={props.tasks.taskTypes} onCancel={handleEditClose.bind(this,row.id)} onSuccess={handleEditSuccess.bind(this,row.id)}/>
                                            )}
                                            title="Edit Follow Up Question"
                                            size="xl"
                                            handleClose={handleEditClose.bind(this,row.id)}
                                            handleOpen={handleEditOpen.bind(this,row.id)}
                                            open={editOpen[row.id]}
                                            dialogClassName={"modalDialog editFollowUpQuestion"}
                                        />
                                        <ConfirmModal
                                            title={"Delete Follow Up"}
                                            text={(<span>Are you sure you want to delete <b>{row.name}</b>?</span>)}
                                            onConfirm={deleteFollowUp.bind(this, row.id)}
                                            confirmTitle={"Delete Follow Up"}
                                        >
                                            <Button variant="contained" color="primary" className={classes.button}>
                                                Delete
                                            </Button>
                                        </ConfirmModal>
                                    </TableCell>
                                </TableRow>
                            );
                        }) : null}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpsIndex);