import React, {useState} from 'react';
import {connect} from "react-redux";
import CSSTransition from "react-transition-group/CSSTransition";
import TransitionGroup from "react-transition-group/TransitionGroup";
import MapGL, {Marker, NavigationControl, FullscreenControl, WebMercatorViewport, FlyToInterpolator} from "react-map-gl";
import _ from "lodash";

import Pin from "@/routes/location/Pin";
import {fetchAllConversations} from "@/shared/conversation/actions";
import {fetchAllWorkflows} from "@/shared/workflow/actions";

const mapStateToProps = state => ({
    allOpenConversations: state.conversation.allOpenConversations,
    allWorkflows: state.workflow.allWorkflows,
    openEncounters: state.encounter.recentOpenEncounters,
    lastNewEncounterAt: state.encounter.lastNewEncounterAt,
    ...state
})

const mapDispatchToProps = dispatch => ({
    getAllOpenConversations : () => dispatch(fetchAllConversations()),
    getWorkflows: () => dispatch(fetchAllWorkflows())
})

/**
 * Given an array of convos, return the bounding box. An array with SW tuple and NE tuple.
 * @param latLongConvos
 * @returns {*[][]}
 */
function getBoundingBox(coords){
    let maxLongConvo = _.maxBy(coords, function(c){return c[0];});
    let maxLong = maxLongConvo[0];
    let maxLatConvo = _.maxBy(coords, function(c){return c[1];});
    let maxLat = maxLatConvo[1];
    let minLongConvo = _.minBy(coords, function(c){return c[0];})
    let minLong = minLongConvo[0];
    let minLatConvo = _.minBy(coords, function(c){return c[1];});
    let minLat = minLatConvo[1];

    var sw = [minLong, minLat];
    var ne = [maxLong, maxLat];
    return [sw, ne];
}

function MapIndex({allOpenConversations, allWorkflows, openEncounters, lastNewEncounterAt, ...props}) {
    const [viewport, setViewport] = useState({
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 14
    });

    const [mapLoaded, setMapLoaded] = useState(false);
    const [viewportUpdater, setViewportUpdater] = useState(0);

    const fullscreenControlStyle = {
        position: 'absolute',
        top: 36,
        left: 0,
        padding: '10px'
    };

    const navStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: '10px'
    };

    React.useEffect(() => {
        props.getAllOpenConversations();
        props.getWorkflows();
        //props.getOpenEncounters();
    }, []);

    React.useEffect(() => {
        //props.getOpenEncounters();
        calculateViewport();
    }, [allOpenConversations, lastNewEncounterAt])

    React.useEffect(() => {
        calculateViewport();
    }, [mapLoaded, openEncounters])

    const getLatLongConvos = () => {
        if(!allOpenConversations){
            return [];
        }
        return _.filter(allOpenConversations, function(conv){
            return conv.recentLatitude && conv.recentLongitude && conv.latitude >= -90 && conv.latitude <= 90 && conv.longitude >= -180 && conv.longitude <= 180;
        });
    }

    const getConvoCoords = () => {
        return _.map(getLatLongConvos(), function(convo){
            return [convo.recentLongitude, convo.recentLatitude];
        })
    }

    const getEncounterCoords = () => {
        return _.map(getLatLongEncounters(), function(e){
            return [e.encounterLocation.longitude, e.encounterLocation.latitude];
        })
    }

    const getLatLongEncounters = () => {
        if(!openEncounters){
            return [];
        }
        let latLongEncounters = _.filter(openEncounters, function(e){
            return e.encounterLocation.latitude && e.encounterLocation.longitude && e.encounterLocation.latitude >= -90 && e.encounterLocation.latitude <= 90 && e.encounterLocation.longitude >= -180 && e.encounterLocation.longitude <= 180;
        });
        return latLongEncounters;
    }

    const calculateViewport = () => {
        let convoCoords = getConvoCoords();
        let encounterCoords = getEncounterCoords();
        let allCoords = [].concat(convoCoords).concat(encounterCoords);
        if(!allCoords || allCoords.length == 0 || !mapLoaded){
            return;
        }

        if(allCoords.length == 1){
            viewport.latitude = allCoords[0][1];
            viewport.longitude = allCoords[0][0];
            setViewport(viewport);
            setViewportUpdater(viewportUpdater+1);
        }
        else{
            let boundingBox = getBoundingBox(allCoords);
            /*
            const {longitude, latitude, zoom} = new WebMercatorViewport(viewport)
                .fitBounds(boundingBox, {
                    padding: 20,
                    offset: [0, -100]
                });


            viewport.latitude = latitude;
            viewport.longitude = longitude;
            viewport.zoom = parseFloat(Math.min(14, zoom).toFixed(2));
            */
            setViewport(viewport);
            setViewportUpdater(viewportUpdater+1);
        }

    }

    let workflowGroups = null;
    if(allOpenConversations){
        workflowGroups = _.groupBy(allOpenConversations, function(obj){return obj.workflowName;});
    }

    if(!allWorkflows || allWorkflows.length == 0){
        return (<span>No Workflows</span>);
    }

    return (
        <div className="mapView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="title">Map</div>
                </div>

                <div className="separator"></div>
            </div>

            <div className="scrollView">
                <div className="conversationsMapStats">
                    {
                        allWorkflows ? _.map(allWorkflows, function(wf, k){
                            let count = workflowGroups[wf.name] ? workflowGroups[wf.name].length : 0;
                            return (
                                <div className="statsCell">
                                    <div className="title">{wf.name}</div>
                                    <div className="value">
                                        <TransitionGroup exit={false}>
                                            <CSSTransition
                                                key={"convo-" + count}
                                                timeout={1500}
                                                classNames={"conversation-count"}
                                                >
                                                <span>{count}</span>
                                            </CSSTransition>
                                        </TransitionGroup>
                                    </div>
                                </div>
                            )
                        })
                         : null
                    }
                    {
                        openEncounters ? (
                            <div className="statsCell">
                                <div className="title">Other Encounters</div>
                                <div className="value">
                                    <TransitionGroup exit={false}>
                                        <CSSTransition
                                            key={"encounters-" + openEncounters.length}
                                            timeout={1500}
                                            classNames={"conversation-count"}
                                        >
                                            <span>{openEncounters.length}</span>
                                        </CSSTransition>
                                    </TransitionGroup>
                                </div>
                            </div>
                        ) : null
                    }
                </div> {/* MARK: Response Groups */}

                <div className="mapContainer">
                    {/* // TODO Pablo
                    <MapGL
                        {...viewport}
                        mapStyle="mapbox://styles/mapbox/outdoors-v11"
                        width="100%"
                        height="100%"
                        mapboxApiAccessToken={TOKEN}
                        transitionInterpolator={new FlyToInterpolator()}
                        onLoad={() => {
                            setMapLoaded(true)
                        }}
                        onViewportChange={nextViewport => setViewport(nextViewport)}>
                        {
                            _.map(getLatLongConvos(), function(c){
                                return (
                                    <Marker
                                        longitude={c.recentLongitude}
                                        latitude={c.recentLatitude}
                                        offsetTop={-20}
                                        offsetLeft={-10}
                                        key={"convo-" + c.id}
                                    >
                                        <Pin size={20} />
                                    </Marker>
                                )
                            })
                        }
                        {
                            _.map(getLatLongEncounters(), function(e){
                                return (
                                    <Marker
                                        longitude={e.encounterLocation.longitude}
                                        latitude={e.encounterLocation.latitude}
                                        offsetTop={-20}
                                        offsetLeft={-10}
                                        key={"encounter-" + e.id}
                                    >
                                        <Pin size={20} />
                                    </Marker>
                                )
                            })
                        }
                        <div style={fullscreenControlStyle}>
                            <FullscreenControl />
                        </div>
                        <div className="nav" style={navStyle}>
                            <NavigationControl/>
                        </div>
                    </MapGL>
                    */}
                </div> {/* MARK: Map Container */}

            </div> {/* MARK: Scroll View */}

        </div> // MARK: Map View
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MapIndex);