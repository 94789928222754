import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
    FormControl,
    Select,
    FormHelperText,
    InputLabel,
    makeStyles,
    MenuItem,
    TextField,
    Typography,
    Divider
} from "@material-ui/core";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import Button from "@material-ui/core/Button";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left',
        marginLeft: theme.spacing(2)
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    },
    formControl: {
        width: '50%',
        marginBottom: "15px"
    }
}));

const columns = [
    { id: 'name', label: 'Department Name', minWidth: 170 }
];

function FederationIndex( props ){
    const classes = useStyles();

    const [config, setConfig] = React.useState({});
    const [loaded, setLoaded] = React.useState(false);
    const [defaultRole, setDefaultRole] = React.useState({});
    const [allRoles, setAllRoles] = React.useState(false);
    const pathRef = React.createRef();
    const clientIdRef = React.createRef();
    const clientSecretRef = React.createRef();
    const issuerRef = React.createRef();
    const scopeRefs = React.createRef();

    const firstNameRef = React.createRef();
    const lastNameRef = React.createRef();
    const emailRef = React.createRef();
    const usernameRef = React.createRef();
    const personnelRef = React.createRef();

    const saveFederation = () => {
        config.defaultRole = defaultRole;
        config.path = pathRef.current.value;
        if(!config.identityProvider){
            config.identityProvider = {};
        }

        if(!config.identityProvider.providerDetails){
            config.identityProvider.providerDetails = {};
        }

        if(!config.identityProvider.attributeMapping){
            config.identityProvider.attributeMapping = {};
        }

        // Provider Details
        config.identityProvider.providerDetails.authorize_scopes = scopeRefs.current.value;
        config.identityProvider.providerDetails.client_secret = clientSecretRef.current.value;
        config.identityProvider.providerDetails.client_id = clientIdRef.current.value;
        config.identityProvider.providerDetails.oidc_issuer = issuerRef.current.value;

        // Attribute Mapping
        config.identityProvider.attributeMapping.given_name = firstNameRef.current.value;
        config.identityProvider.attributeMapping.family_name = lastNameRef.current.value;
        config.identityProvider.attributeMapping.email = emailRef.current.value;
        config.identityProvider.attributeMapping.username = usernameRef.current.value;
        config.identityProvider.attributeMapping['custom:personnel_id'] = personnelRef.current.value;

        let init = {body: config};
        API.put('Core', '/api/v1/federated-identity-config', init)
            .then(res => {
                loadConfig();
                toast("Saved SSO Configuration!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
            },
            err => {
                toast("Could not save configuration", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            });
    }

    const loadRoles = () => {
        API.get('Core', "/api/v1/role")
            .then(res => {
                setAllRoles(res);
            },
            err => {
                toast("Could not load roles", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            });
    }

    const loadConfig = () => {
        API.get('Core', "/api/v1/federated-identity-config")
            .then(res => {
                setConfig(res);
                setLoaded(true);
                setDefaultRole(res.defaultRole);
            },
            err => {
                toast("Could not load config", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR})
            })
    }

    React.useEffect(() => {
        loadRoles();
        loadConfig();
    }, [])

    if(!loaded){
        return (<span>Loading...</span>);
    }

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Typography variant={"h5"}>
                    Configure Open ID Connect (OIDC)
                </Typography>
                <Typography variant={"body1"}>
                    After configuring the connection with your Open ID Connect (OIDC) compliant directory, personnel in your agency will be able to login at a unique URL using the credentials they use in the configured directory.
                </Typography>
            </Grid>
            <Grid xs={12} style={{marginLeft: "15px"}}>
                <Typography variant={"overline"}><b>System Config</b></Typography>
                <Divider/>
                <FormControl className={classes.formControl} style={{marginTop: "10px"}}>
                    <TextField defaultValue={config.path} helperText={"This will be used to form a unique login page for your agency"} inputRef={pathRef} required id="standard-required" label="Path"/>
                </FormControl>
            </Grid>
            <Grid xs={12} style={{marginLeft: "15px"}}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Default User Creation Role</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        value={defaultRole ? defaultRole.id : null}
                        defaultValue={config.defaultRole ? config.defaultRole.id : null}
                        onChange={(e) => {
                            let selectedRole = _.find(allRoles, function(r){ return r.id === e.target.value});
                            setDefaultRole(selectedRole);
                        }}
                    >
                        {
                            _.map(allRoles, function(r){
                                return (
                                    <MenuItem value={r.id}>{r.name}</MenuItem>
                                )
                            })
                        }
                    </Select>
                    <FormHelperText>The role the system will assign a user the first time that user is seen.</FormHelperText>
                </FormControl>
            </Grid>
            <Grid xs={12} style={{marginTop: "20px", marginLeft: "15px"}}>
                <Typography variant={"overline"}><b>OIDC</b></Typography>
                <Divider/>
                <FormControl className={classes.formControl} style={{marginTop: "10px"}}>
                    <TextField defaultValue={(config.identityProvider && config.identityProvider.providerDetails ? config.identityProvider.providerDetails.client_id : "")}
                               helperText={"Client ID"}
                               inputRef={clientIdRef}
                               required
                               label="Client ID"/>
                </FormControl>
            </Grid>
            <Grid xs={12} style={{marginLeft: "15px"}}>
                <FormControl className={classes.formControl}>
                    <TextField defaultValue={(config.identityProvider && config.identityProvider.providerDetails ? config.identityProvider.providerDetails.client_secret : "")}
                               helperText={"Client Secret"}
                               inputRef={clientSecretRef}
                               required
                               label="Client Secret"/>
                </FormControl>
            </Grid>
            <Grid xs={12} style={{marginLeft: "15px"}}>
                <FormControl className={classes.formControl}>
                    <TextField defaultValue={(config.identityProvider && config.identityProvider.providerDetails ? config.identityProvider.providerDetails.authorize_scopes : "")}
                               helperText={"Seperate each scope with a space - e.g. profile openid email"}
                               inputRef={scopeRefs}
                               required
                               label="Authorize Scope"/>
                </FormControl>
            </Grid>
            <Grid xs={12} style={{marginLeft: "15px"}}>
                <FormControl className={classes.formControl}>
                    <TextField defaultValue={(config.identityProvider && config.identityProvider.providerDetails ? config.identityProvider.providerDetails.oidc_issuer : "")}
                               helperText={"Domain of the issuer - e.g. https://accounts.google.com"}
                               inputRef={issuerRef}
                               required
                               label="Issuer"/>
                </FormControl>
            </Grid>
            <Grid xs={12} style={{marginTop: "20px", marginLeft: "15px"}}>
                <Typography variant={"overline"}><b>Attribute Mapping</b></Typography>
                <Divider/>
                <FormControl className={classes.formControl}>
                    <TextField defaultValue={(config.identityProvider && config.identityProvider.attributeMapping ? config.identityProvider.attributeMapping.given_name : "")}
                               helperText={"Name of field in the OIDC data that contains first name"}
                               inputRef={firstNameRef}
                               required
                               label="First Name"/>
                </FormControl>
            </Grid>
            <Grid xs={12} style={{marginTop: "20px", marginLeft: "15px"}}>
                <FormControl className={classes.formControl}>
                    <TextField defaultValue={(config.identityProvider && config.identityProvider.attributeMapping ? config.identityProvider.attributeMapping.family_name : "")}
                               helperText={"Name of field in the OIDC data that contains last name"}
                               inputRef={lastNameRef}
                               required
                               label="Last Name"/>
                </FormControl>
            </Grid>
            <Grid xs={12} style={{marginTop: "20px", marginLeft: "15px"}}>
                <FormControl className={classes.formControl}>
                    <TextField defaultValue={(config.identityProvider && config.identityProvider.attributeMapping ? config.identityProvider.attributeMapping.email : "")}
                               helperText={"Name of field in the OIDC data that contains email"}
                               inputRef={emailRef}
                               required
                               label="Email"/>
                </FormControl>
            </Grid>
            <Grid xs={12} style={{marginTop: "20px", marginLeft: "15px"}}>
                <FormControl className={classes.formControl}>
                    <TextField defaultValue={(config.identityProvider && config.identityProvider.attributeMapping ? config.identityProvider.attributeMapping.username : "")}
                               helperText={"Name of field in the OIDC data that contains username"}
                               inputRef={usernameRef}
                               required
                               label="Username"/>
                </FormControl>
            </Grid>
            <Grid xs={12} style={{marginTop: "20px", marginLeft: "15px"}}>
                <FormControl className={classes.formControl}>
                    <TextField defaultValue={(config.identityProvider && config.identityProvider.attributeMapping ? config.identityProvider.attributeMapping['custom:personnel_id'] : "")}
                               helperText={"Personnel ID in the Directory"}
                               inputRef={personnelRef}
                               required
                               label="Personnel ID"/>
                </FormControl>
            </Grid>
            <Grid xs={12}>
                <Button onClick={saveFederation} variant="contained" color="primary" className={classes.button}>
                    Save
                </Button>
            </Grid>
        </Grid>
    )
}

export default FederationIndex;
