import _ from 'lodash';

import {
    GET_ALL_ENCOUNTERS_SUCCESS,
    GET_ALL_RECENT_OPEN_ENCOUNTERS_SUCCESS,
    GET_ALL_TEST_ENCOUNTERS_SUCCESS,
    NEW_ENCOUNTER_EVENT
} from "./actions";


const initialState = {
    allEncounters: [],
    allTestEncounters: [],
    allEncounterPageInfo: {},
    allTestEncounterPageInfo: {},
    lastNewEncounterAt: null,
    recentOpenEncounters: []
}

export default (state = initialState, action) => {

    function addOrUpdateEncounter(encounter){
        let encounterExists = _.findIndex(state.allEncounters, function(e){return e.id == encounter.id});
        if(encounterExists >= 0){
            state.allEncounters[encounterExists] = encounter;
        }
        else{
            state.allEncounters.push(encounter);
        }
    }

    function addOrUpdateTestEncounter(testEncounter){
        let testEncounterExists = _.findIndex(state.allTestEncounters, function(e){return e.id == testEncounter.id});
        if(testEncounterExists >= 0){
            state.allTestEncounters[testEncounterExists] = testEncounter;
        }
        else{
            state.allTestEncounters.push(testEncounter);
        }
    }

    switch (action.type) {
        case GET_ALL_ENCOUNTERS_SUCCESS:
            _.forEach(action.payload.content, function(e){
                addOrUpdateEncounter(e);
            })
            return Object.assign({}, state, {
                allEncounterPageInfo: action.payload
            });
        case GET_ALL_TEST_ENCOUNTERS_SUCCESS:
            _.forEach(action.payload.content, function(e){
                addOrUpdateTestEncounter(e);
            })
            return Object.assign({}, state, {
                allTestEncounterPageInfo: action.payload
            });
        case NEW_ENCOUNTER_EVENT:
            addOrUpdateEncounter(action.data.payload);
            return Object.assign({}, state, {
                lastNewEncounterAt: Date.now()
            });
        case GET_ALL_RECENT_OPEN_ENCOUNTERS_SUCCESS:
            return Object.assign({}, state, {
                recentOpenEncounters: action.payload
            });
        default:
            return state;
    }
}