import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { API } from 'aws-amplify';
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import _ from "lodash";
import { Menu, MenuItem } from '@material-ui/core';
import {CloudDownload, Print } from '@material-ui/icons';

import InputCell from '@/common/form/InputCell';
import { workflowTypes } from '@/utils/utils';
import moment from '@/common/momentConfig';
import Modal from "@/common/Modal";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
})

const mapFieldNameType = {
    "Phone Number": "PHONE_INPUT"
}

const ApplicantModal = (props) => {
    const { conversationId } = props.match.params;
    const [recordsData, setRecordsData] = useState({})
    const [fieldsValues, setFieldsValues] = useState({})
    const [loadingFields, setLoadingFields] = useState(true)
    const [loadingRecords, setLoadingRecords] = useState(false)

    const [conversationDisposition, setConversationDisposition] = useState();
    const [dispositions, setDispositions] = useState([]);
    const [dispostionAnchorEl, setDispostionAnchorEl] = useState(null);

    const [isFormLocked, setFormLocked] = useState(false);
    const [isDataCompleteChecked, setDataCompleteChecked] = useState(false);
    const [isCompleteConfirmationModalOpen, setCompleteConfirmationModalOpen] = useState(false);
    const [isCompleteButtonOn, setCompleteButtonOn] = useState(false);
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(null);

    const [fields, setFields] = useState([]);

    const [name, setName] = useState("");

    const CONTAINER_ID = `applicantModalInfo${conversationId}`;
    const CONTAINER_REF = useRef();
    const ACTION_BAR_REF = useRef();

    let downloadApplicantDetails = () => {
        const HEADERS = `${fields.map(f => f.fieldName).join(',')}\n`;
        
        const APPLICANT_DETAILS = `${fields.map(f => (f.fieldName === 'Phone Number' ? formattedPhoneNumber : fieldsValues[f.id]) || '').join(',')}\n`;
        
        const CSV_CONTENT = `data:text/csv;charset=utf-8,${HEADERS}${APPLICANT_DETAILS}`;

        const ENCODED_URI = encodeURI(CSV_CONTENT)
        let link = document.createElement('a');
        link.setAttribute('href',ENCODED_URI);
        link.setAttribute('download', `applicantDetails${conversationId}.csv`);
        link.click();
        link.remove();
    }

    let printApplicantDetails = () => {
        const ACTION_BAR_VISIBILITY = ACTION_BAR_REF.current.style.visibility;
        
        ACTION_BAR_REF.current.style.visibility = 'hidden';
        
        window.print();

        ACTION_BAR_REF.current.style.visibility = ACTION_BAR_VISIBILITY;
    }

    useEffect(() => {

        API.get('Core', `/api/v1/conversation-records/fields?workflowType=${workflowTypes.APPLICANT}`).then(_fields => {
            setFields(_fields)

            API.get('Core', `/api/v1/conversation-records/${conversationId}/fields/values`).then(_fieldsValues => {
                let fieldsValues_ = _fieldsValues.reduce((obj, fieldValue) => (obj[fieldValue.conversationRecordsFieldId] = fieldValue.value, obj), {})
                setFieldsValues(fieldsValues_);
                // Header Info
                setName(getValueFromFieldName("Full Name", _fields, fieldsValues_));
            })

        })

        loadRecordsData()

        API.get('Core', `/api/v1/conversation/${conversationId}`)
            .then(response => {
                setConversationDisposition(response.conversationDisposition)
                setCompleteButtonOn(!!response.workflowState && !!response.workflowState.closedState)
            },
                error => {
                    toast("Could not load conversation disposition " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                });

        API.get('Core', '/api/v1/conversation/dispositions')
            .then(response => {
                setDispositions(response)
            },
                error => {
                    toast("Could not load dispositions " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                });

    }, []);

    const loadRecordsData = () => {
        API.get('Core', `/api/v1/conversation-records/${conversationId}/all`)
            .then(response => {
                setRecordsData(response)
                setLoadingRecords(false)
            },
                error => {
                    toast("Could not load Candidate information: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                });
    }

    const getFieldComponent = (field) => {

        let fieldType = mapFieldNameType[field.fieldName];

        switch (fieldType) {
            case "PHONE_INPUT":
                return (
                    <div className="tableCell inputCell phoneInput">
                        <div className="title">{field.fieldName}</div>
                        <PhoneInput
                            inputClass={isFormLocked && field.lockable ? `tel-input disabled` : `tel-input`}
                            country={'us'}
                            onlyCountries={['us']}
                            value={getFieldValue(field) || ""}
                            onChange={(value, data, event, formattedValue) => {
                                handleInputTextChange(value, field)
                                setFormattedPhoneNumber(formattedValue);
                            }}
                            disabled={isFormLocked && field.lockable}
                        />
                    </div>
                );
            default:
                let type = "text";

                return (
                    <div className={`tableCell titledSelectCell ${type === "textarea" && "textarea"}`}>
                        <div className="title">{field.fieldName}</div>
                        <InputCell
                            placeholder={field.placeholder || field.displayName}
                            defaultValue={getFieldValue(field)}
                            disabled={field.readOnly || (isFormLocked && field.lockable)}
                            onChange={(e) => handleInputTextChange(e.target.value, field)}
                            pattern={field.pattern}
                            required
                            type={type}
                        />
                        <div className="valueDescription">{field.description}</div>
                    </div>
                );
        }
    }

    const getFieldValue = (field) => fieldsValues[field.id]

    const handleInputTextChange = (value, field) => {
        fieldsValues[field.id] = value

        if (field.fieldName === "Full Name") setName(value)
    }

    const saveCandidate = async (closeModal) => {

        let data = {
            signed: false,
            fieldsValues: fields.map(field => {
                return {
                    conversationRecordsFieldId: field.id,
                    value: fieldsValues[field.id]
                }
            })
        }

        return API.post('Core', `/api/v1/conversation-records/${conversationId}/fields/values`, { body: data })
            .then(_fieldsValues => {
                setFieldsValues(_fieldsValues.reduce((obj, fieldValue) => (obj[fieldValue.conversationRecordsFieldId] = fieldValue.value, obj), {}));
                loadRecordsData()
                toast("Successfully Saved Candidate Information!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                if (closeModal) window.close()
            },
                error => {
                    const errorMessage = error?.response?.data?.message ? `: ${error.response.data.message}` : "";
                    toast("Could not save Candidate information" + errorMessage, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );

    }

    const handleDispositionMenuClick = event => {
        setDispostionAnchorEl(event.currentTarget);
    };

    const onConversationUpdate = async (conversationId, updateConversation) => {
        await saveCandidate(false);
        API.put('Core', '/api/v1/conversation/' + conversationId, { body: updateConversation })
            .then(response => {
                setConversationDisposition(response.conversationDisposition)
                setCompleteButtonOn(!!response.workflowState && !!response.workflowState.closedState)
            });
    }

    const handleClearDisposition = () => {
        setCompleteButtonOn(false)
        onConversationUpdate(conversationId, { conversationDisposition: { name: "NONE" } });
        setDispostionAnchorEl(null);
    };

    const handleDispositonMenuSelect = (event, disposition) => {
        setCompleteButtonOn(false)
        onConversationUpdate(conversationId, { conversationDisposition: disposition });
        setDispostionAnchorEl(null);
    };

    const getCompleteConfirmationModalContent = () => {
        return (
            <div>
                <div className={"alertBody"}>
                    <div className={"alertTitle"}>Complete Record</div>
                    <div className={"alertMessage"}>
                        <div>By checking this box, I am attesting to the data in this record is true and accurate.</div>
                        <input
                            onChange={() => setDataCompleteChecked(!isDataCompleteChecked)}
                            type="checkbox"
                            checked={isDataCompleteChecked}
                        /> Confirm
                    </div>
                </div>

                <div className={"alertActions dualActions"}>
                    <div onClick={() => setCompleteConfirmationModalOpen(false)} className={"alertAction button action medium"}>
                        <div className={"buttonTitle"}>Cancel</div>
                    </div>

                    <div onClick={() => {
                        if (isDataCompleteChecked) {
                            saveCandidate(false)
                            setCompleteConfirmationModalOpen(false)
                        }
                    }}
                        className={`alertAction button medium ${isDataCompleteChecked ? "confirm" : "disabled"}`}>
                        <div className={"buttonTitle"}>Sign</div>
                    </div>
                </div>
            </div>
        )
    }

    const getValueFromFieldName = (fieldName, fields, fieldsValues) => {
        if (!fields || !fieldsValues) return "-";

        let field = fields.find(field => field.fieldName === fieldName);

        if (field && fieldsValues[field.id])
            return fieldsValues[field.id];

        return "-";
    }

    return (
        <div className="applicantModal">
            {(!loadingFields && loadingRecords) ?
                <div className="loadingRecords">
                    <div className="message">
                        Loading records...
                    </div>
                </div> : null
            }

            <div id={CONTAINER_ID} className="headerInfo" ref={CONTAINER_REF}>

                <div className="row">
                    <div className="field">
                        <div className="name">
                            Name:
                        </div>
                        <div className="value">
                            {name}
                        </div>
                    </div>

                </div>

                <div className="row">

                    <div className="field">
                        <div className="name">
                            Created At:
                        </div>
                        <div className="value">
                            {recordsData && recordsData.createdAt
                                ? moment.utc(recordsData.createdAt).local().format("MM/DD/YYYY hh:mm:ss a")
                                : "-"}
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">
                            Updated At:
                        </div>
                        <div className="value">
                            {recordsData && recordsData.updatedAt
                                ? moment.utc(recordsData.updatedAt).local().format("MM/DD/YYYY hh:mm:ss a")
                                : "-"}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="field">
                        <div className="name">
                            Disposition:
                        </div>
                        <div className="value menuButton">
                            <div className="icon"></div>
                            <div className="cellBody" onClick={handleDispositionMenuClick}>
                                <div className="detail">{conversationDisposition ? conversationDisposition.name : "None"}</div>
                            </div>
                            <div className="accessory popUpIndicator"></div>
                            <Menu
                                id="simple-menu"
                                anchorEl={dispostionAnchorEl}
                                keepMounted
                                open={!isFormLocked && Boolean(dispostionAnchorEl)}
                                onClose={(event, reason) => reason === 'backdropClick' ? setDispostionAnchorEl(null) : null}
                            >
                                <MenuItem onClick={handleClearDisposition}>None</MenuItem>
                                {

                                    dispositions ? (
                                        _.map(_.sortBy(dispositions, function (d) { return d.name; }), function (d, i) {
                                            let selected = conversationDisposition && conversationDisposition.id === d.id;
                                            return (
                                                <MenuItem
                                                    key={d.id}
                                                    selected={selected}
                                                    onClick={event => handleDispositonMenuSelect(event, d)}
                                                >
                                                    {d.name}
                                                </MenuItem>
                                            )
                                        })
                                    ) : null
                                }
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <Modal
                    content={getCompleteConfirmationModalContent()}
                    dialogClassName={"alertModal"}
                    size="xs"
                    handleClose={() => setCompleteConfirmationModalOpen(false)}
                    handleOpen={() => setCompleteConfirmationModalOpen(true)}
                    open={isCompleteConfirmationModalOpen}
                />

                <div className="tableView">
                    {fields.map((field, index) => {
                        return (
                            <div key={index} className="tableSection sectionName">
                                {getFieldComponent(field)}
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="actionBar" ref={ACTION_BAR_REF}>
                <div className="actions left">
                    <div onClick={() => window.close()} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>
                </div>

                <div className="actions right">
                    <div className='button tint' onClick={downloadApplicantDetails}><CloudDownload className='title'/></div>
                    <div className='button tint' onClick={printApplicantDetails}><Print className='title'/></div>
                    <div onClick={() => saveCandidate(true)} className={`button tint medium`}>
                        <div className="title">Save and Close</div>
                    </div>
                    <div onClick={() => saveCandidate(false)} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>
                <div className="separator"></div>
            </div>
        </div>

    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicantModal));