import React from 'react';
import { API } from "aws-amplify";
import { toast } from "react-toastify";

import InputCell from "@/common/form/InputCell";

export default function AddEditSurvey(props) {
    var { onCancel, onSuccess, existingSurvey } = props;

    const [isPublic, setIsPublic] = React.useState(existingSurvey ? existingSurvey.public : false);
    const titleRef = React.createRef();
    const MAX_SURVEY_NAME_LENGTH = 255;

    let surveyUrl = null;
    if (existingSurvey) {
        surveyUrl = window.location.protocol + "//" + window.location.hostname + "/take-survey/public/" + existingSurvey.encryptedId;
    }

    const saveSurvey = () => {

        if (titleRef.current.value.length > MAX_SURVEY_NAME_LENGTH) {
            toast(`Survey title limit is ${MAX_SURVEY_NAME_LENGTH} characters`, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }

        if (existingSurvey) {
            API.put('Core', '/api/v1/survey/' + existingSurvey.id, { body: { name: titleRef.current.value, public: isPublic } })
                .then(response => {
                    toast("Survey saved!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                    onSuccess(response);
                },
                    error => {
                        toast("Could not update survey: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                    }
                );
        }
        else {
            API.post('Core', '/api/v1/survey', { body: { name: titleRef.current.value, public: isPublic } })
                .then(response => {
                    toast("Survey saved!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                    onSuccess(response);
                },
                    error => {
                        toast("Could not create survey: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                    }
                );
        }
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"Survey Name"}
                            maxlength={MAX_SURVEY_NAME_LENGTH}
                            defaultValue={(existingSurvey ? existingSurvey.name : "")}
                            required
                            id="ruleName"
                            label="Survey Name"
                            inputRef={titleRef}
                        />
                    </div>
                    <div className="tableSection sectionName">
                        <div className={"sectionTitle"}>
                            <div className={"title"}>Enable Anonymous Web Submission</div>
                        </div>
                        <div onClick={() => {
                            setIsPublic(!isPublic);
                        }}
                            className={`tableCell switchCell ${isPublic ? 'switchOn' : 'switchOff'}`}>
                            <div className="title">Enable</div>
                            <div className={`accessory accessorySwitch ${isPublic ? 'on' : 'off'}`}>
                                <div className="switchThumb" />
                            </div>
                        </div>
                        {
                            surveyUrl && isPublic ? (
                                <div className="sectionTitle">
                                    <p className="cellFootnote">You can access the public survey at <a style={{ cursor: 'pointer' }} target={"_blank"} rel="noreferrer" href={surveyUrl}>{surveyUrl}</a></p>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveSurvey} className={`button confirm medium`}>
                        <div className="title">Done</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>

    )
}
