import React from 'react';
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import moment from '@/common/momentConfig';

const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

const MeasureDashboard = ({dashboardId, user, onLoaded}) => {
    let endDate = moment().local().endOf('day').format();
    let startDate = moment().local().add(-30, 'days').format();
    let departmentId = user && user.aboutMe && user.aboutMe.user && user.aboutMe.user.departmentId
        ? user.aboutMe.user.departmentId
        : undefined;

    React.useEffect(() => {

        let params = [
            {
                Name: 'StartDate',
                Values: [startDate]
            },
            {
                Name: 'EndDate',
                Values: [endDate]
            },
            {
                Name: 'DepartmentId',
                Values: [departmentId]
            },
            {
                Name: 'HostName',
                Values: [window.location.hostname]
            }
        ]

        API.get('Core', '/api/v1/analytics/dashboard/' + dashboardId)
            .then(async response => {
                    const containerDiv = document.getElementById("dashboard-container");
                    const options = {
                        url: response.url,
                        container: containerDiv,
                        height: '99%',
                        width: '100%'
                    };
                    const contentOptions = {
                        parameters: params,
                        locale: "en-US",
                        toolbarOptions: {
                            export: true,
                            undoRedo: true,
                            reset: true
                        },
                        onMessage: async (messageEvent, metadata) => {
                            if (messageEvent.eventName === 'CONTENT_LOADED') {
                                if (onLoaded) {
                                    onLoaded();
                                }
                            }
                        }
                    }
                    if (containerDiv) {
                        containerDiv.innerHTML = "";
                    }

                    const {createEmbeddingContext} = QuickSightEmbedding;
                    const embeddingContext = await createEmbeddingContext();
                    const embeddedDashboardExperience = await embeddingContext.embedDashboard(options, contentOptions);
                },
                error => {
                    toast("Could not load dashboard: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardId]);

    return (<div id="dashboard-container"></div>);
}

export default MeasureDashboard;