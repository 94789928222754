import React, {useState, useCallback} from 'react';
import {API, Auth} from "aws-amplify";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import MapGL, {Marker, NavigationControl} from 'react-map-gl';
import Pin from "./Pin";
import {toast} from "react-toastify";
import {decrypt} from "../../utils/utils";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({

})

const RequestLocation = (props) => {
    const TOKEN = window.__RUNTIME_CONFIG__.MAPBOX;
    const mapboxApiAccessToken = React.useMemo(() => {
        return decrypt(TOKEN);
    }, [TOKEN]);

    const navStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: '10px'
    };
    const [viewport, setViewport] = useState({
        width: '100vw',
        height: '75vh',
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 14
    });
    const [marker, setMarker] = useState( {
        latitude: 0.0,
        longitude: 0.0
    });

    const {requestLocationHash} = props.match.params;
    const [userLocation, setUserLocation] = useState( {
        latitude: 0.0,
        longitude: 0.0
    });
    const [aboutMeLoaded, setAboutMeLoaded] = React.useState(false);
    const [initialSubmit, setInitialSubmit] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);

    const [geoLocationWatching, setGeoLocationWatching] = React.useState(false);

    if (navigator.geolocation  && !geoLocationWatching) {
        console.log("in geo watch");
        navigator.geolocation.getCurrentPosition((position) => {
            setGeoLocationWatching(true);
            if (position.coords.latitude !== 0.0) {
                setViewport({...viewport, latitude: position.coords.latitude, longitude: position.coords.longitude});
                setMarker({...marker, latitude: position.coords.latitude, longitude: position.coords.longitude});
                setUserLocation({
                    ...userLocation,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
                console.log("update watch position");
            }
        });
    }

    const _onMarkerDragEnd = event => {
        setMarker({...marker, longitude: event.lngLat[0], latitude: event.lngLat[1]})
        setUserLocation({...userLocation, longitude: event.lngLat[0], latitude: event.lngLat[1]})
    };

    const _onViewportChange = viewport => {
        //console.log(map);
        setViewport(viewport);
        setMarker({...marker, longitude: viewport.longitude, latitude: viewport.latitude})
        if(initialSubmit) {
            setUserLocation({...userLocation, longitude: viewport.longitude, latitude: viewport.latitude})
        }
    };

    const handleSubmit = (userUploaded) => {
        console.log("in submit");
        console.log(userUploaded);
        if(submitting){
            return;
        }
        setSubmitting(true);
        let respondWithLocation = {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
            userUploaded: userUploaded
        }
        API.put('Unauthorized', '/u/request-location-response/' + requestLocationHash, {body:respondWithLocation})
            .then(response => {
                    setSubmitting(false);
                    if(userUploaded) {
                        alert("Location submitted.");
                    }
                },
                error => {
                    toast(error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    setSubmitting(false);
                }
            );
    }

    if(!initialSubmit) {
        console.log(initialSubmit);
        if (userLocation.latitude !== 0) {
            console.log("about to auto submit");
            console.log(userLocation.latitude)
            setInitialSubmit(true);
        }
    }


    let render = (
        <div className="locationMap">
            <MapGL
                {...viewport}
                mapStyle="mapbox://styles/mapbox/outdoors-v11"
                mapboxApiAccessToken={mapboxApiAccessToken}
                dragRotate={false}
                onViewportChange={_onViewportChange}>
                <Marker
                    longitude={marker.longitude}
                    latitude={marker.latitude}
                    offsetTop={-20}
                    offsetLeft={-10}
                    draggable
                    onDragEnd={_onMarkerDragEnd}
                >
                    <Pin size={20} />
                </Marker>
                <div className="nav" style={navStyle}>
                    <NavigationControl/>
                </div>
            </MapGL>
            <div className="submitLocation" onClick={() => handleSubmit(true)}>
                <div className="title">Submit Location</div>
            </div>
        </div>
    );
    console.log("rerender");
    return render;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestLocation));