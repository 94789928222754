import React from 'react';
import InputCell from "../../../common/form/InputCell";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import PhoneInput from "react-phone-input-2";
import _ from "lodash";

export default function PersonnelNotificationDetail(props){
    var { onCancel, notification} = props;

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Not Delivered</div>
                        </div>
                        {
                            _.map(notification.recipients, function (r, i) {
                                if(r.deliveryStatuses != null) {
                                    let res = _.filter(r.deliveryStatuses, ['status', 'queued'])
                                    let resD = _.filter(r.deliveryStatuses, ['status', 'delivered'])
                                    if(res != null && res.length > 0 && resD != null && resD.length == 0) {
                                        return (
                                            <div className={`tableCell`}>
                                                <div className="cellBody">
                                                    <div className="title">{r.encounterPersonnel.name}</div>
                                                    <div className="subtitle description">{r.encounterPersonnel.contact != null ? r.encounterPersonnel.contact.phone : ""}</div>
                                                </div>
                                            </div>

                                        )
                                    }
                                }
                            })
                        }
                        <div className="sectionTitle">
                            <div className="title">Delivered</div>
                        </div>
                        {
                            _.map(notification.recipients, function (r, i) {
                                if(r.deliveryStatuses != null) {
                                    let res = _.filter(r.deliveryStatuses, ['status', 'delivered'])
                                    if(res != null && res.length > 0) {
                                        return (
                                            <div className={`tableCell`}>
                                                <div className="title">{r.encounterPersonnel.name}</div>
                                            </div>
                                        )
                                    }
                                }
                            })
                        }
                        {/*<InputCell*/}
                        {/*    placeholder={""}*/}
                        {/*    defaultValue={existingInterpreter ? existingInterpreter.language : ""}*/}
                        {/*    required*/}
                        {/*    id="language"*/}
                        {/*    label="Language"*/}
                        {/*    inputRef={languageRef}*/}
                        {/*/>*/}
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Close</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>

    )
}