import React from "react";
import InputCell from "../../../common/form/InputCell";
import { API } from "aws-amplify";
import { toast } from "react-toastify";

export default function AddEditShift(props) {
  var { onCancel, onSuccess, existingShift } = props;

  const nameRef = React.createRef();

  const saveShift = () => {
    let init = { body: { name: nameRef.current.value } };
    if (existingShift) {
      API.put("Core", "/api/v1/shift/" + existingShift.id, init).then(
        (response) => {
          toast.success("Shift Updated!", {
            position: toast.POSITION.TOP_CENTER,
          });
          onSuccess(response);
        },
        (error) => {
          toast.error(
            "Could not update shift: " + error.response.data.message,
            { position: toast.POSITION.TOP_CENTER }
          );
        }
      );
    } else {
      API.post("Core", "/api/v1/shift", init).then(
        (response) => {
          toast.success("Shift Created!", {
            position: toast.POSITION.TOP_CENTER,
          });
          onSuccess(response);
        },
        (error) => {
          toast.error(
            "Could not create shift: " + error.response.data.message,
            { position: toast.POSITION.TOP_CENTER }
          );
        }
      );
    }
  };

  return (
    <div>
      <div className="content">
        <div className="tableView">
          <div className="tableSection sectionName">
            <InputCell
              placeholder={""}
              defaultValue={existingShift ? existingShift.name : ""}
              required
              id="name"
              label="Shift Name"
              inputRef={nameRef}
            />
          </div>
        </div>
      </div>
      <div className="actionBar">
        <div className="actions">
          <div onClick={onCancel} className="button dismiss medium">
            <div className="title">Cancel</div>
          </div>

          <div onClick={saveShift} className={`button confirm medium`}>
            <div className="title">Save</div>
          </div>
        </div>

        <div className="separator"></div>
      </div>
    </div>
  );
}
