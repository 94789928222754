import React from 'react';
import MeasureDashboard from "./MeasureDashboard";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import _ from "lodash";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import { Box, CircularProgress } from "@material-ui/core";

function mapStateToProps(state) {
    return state
}

function MeasureIndex(props) {
    const [dashboardId, setActiveDashboardId] = React.useState(props.match.params.dashboardId);
    const [dashboardsLoading, setDashboardsLoading] = React.useState(true);
    const [dashboards, setDashboards] = React.useState([]);
    const [activeDashboard, setActiveDashboard] = React.useState(null);
    const [activeDashboardLoaded, setActiveDashboardLoaded] = React.useState(false);

    const onActiveDashboardLoaded = () => {
        setActiveDashboardLoaded(true);
    }

    React.useEffect(() => {
        API.get('Core', '/api/v1/analytics/dashboard')
            .then(response => {
                    setDashboardsLoading(false);
                    setDashboards(_.sortBy(response, function(d){return d.weight;}));
                },
                error => {
                    setDashboardsLoading(false);
                    toast("Could not load dashboards: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }, []);

    React.useEffect(() => {
        if(dashboards && dashboards.length > 0){
            navigateToDashboard(dashboardId);
        }
    }, [dashboards])

    const navigateToDashboard = (dashboardId) => {
        setActiveDashboardLoaded(false);
        let activeDash = null;
        if(dashboardId){
            activeDash = _.find(dashboards, function(d){return d.dashboardId == dashboardId;})
            if(!activeDash){
                activeDash = dashboards[0];
            }
        }
        else{
            activeDash = dashboards[0];
        }
        setActiveDashboard(activeDash);
        setActiveDashboardId(activeDash.dashboardId);
        props.history.push("/measure/" + activeDash.dashboardId);
    }

    const getActiveDashIdx = () => {
        return _.findIndex(dashboards, function(d){return d.dashboardId == activeDashboard.dashboardId})
    }

    if(dashboardsLoading || !activeDashboard){
        return (<Box style={{ display:'block', height:'100vh' }}>
            <CircularProgress style={{position:'absolute',width:'40px', left:'45vw', top:'45vh'}} />
          </Box>);
    }

    if(!dashboards || (dashboards && dashboards.length < 1)){
        return (<span>No Dashboards</span>);
    }

    return (
        <div style={{width: '100%'}}>
            <div className="secondaryNavBar">
                <div className={`segmentedBar index${getActiveDashIdx()}`} style={{width: "100%"}}>
                    {
                        _.map(dashboards, function(d, dk){
                            return (
                                <div onClick={() => navigateToDashboard(d.dashboardId)} className={`segment ${d.dashboardId == activeDashboard.dashboardId ? 'selected' : ''}`} key={dk}>
                                    <div className="title">{d.name}</div>
                                </div>
                            );
                        })
                    }
                    <div className="selection" style={{left: (getActiveDashIdx() * (100 / dashboards.length)) + '%', width: (100 / dashboards.length) +'%' }}/>
                </div>
            </div>
            <div style={{width: '100%', height: `calc(100% - 56px)`}}>
                { dashboardId ?
                    <MeasureDashboard dashboardId={activeDashboard.dashboardId} user={props.user} onLoaded={onActiveDashboardLoaded} /> : ''
                }
            </div>
            {!activeDashboardLoaded && (
                <Box style={{ display:'block', height:'100vh' }}>
                    <CircularProgress style={{position:'absolute',width:'40px', left:'45vw', top:'45vh'}} />
                </Box>
            )}
        </div>

    );
}

export default withRouter(connect(mapStateToProps)(MeasureIndex));