import {
    GET_ALL_ARTICLES_SUCCESS,
    GET_ARTICLE_GROUPS_SUCCESS, GET_ARTICLES_SUCCESS, GET_SINGLE_ARTICLE_GROUP_SUCCESS, GET_SINGLE_ARTICLE_SUCCESS
} from "./actions";


const initialState = {
    articleGroups: [],
    articles: [],
    allArticles: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ARTICLE_GROUPS_SUCCESS:
            return Object.assign({}, state, {
                articleGroups: action.payload
            });
        case GET_SINGLE_ARTICLE_GROUP_SUCCESS:
            return Object.assign({}, state, {
                articleGroups: action.payload
            });
        case GET_ARTICLES_SUCCESS:
            return Object.assign({}, state, {
                articles: action.payload
            });
        case GET_SINGLE_ARTICLE_SUCCESS:
            return Object.assign({}, state, {
                articles: action.payload
            });
            return;
        case GET_ALL_ARTICLES_SUCCESS:
            return Object.assign({}, state, {
                allArticles: action.payload
            })
        default:
            return state;
    }
}