import React from "react"
import _ from "lodash";

import { getDate } from "@/common/LocalTimeStamp";

import SimpleEncounterField from "./SimpleEncounterField";

/**
 *
 * @param revision - Sample structure { commitId: "1234.0", commitDate: [date], encounterRevision: [encounterTypeDto], changes: [array of changes]}
 * @param schema
 * @param props
 * @constructor
 */
export default function CADEncounterDiff({ revision, revisions, ...props }) {

    function isUnitAdded(unit) {
        if (!revision || !revisions) {
            return null;
        }
        let unitAdded = false;

        if (revisions.length>=2){
            for( let j=0; j< revisions.length;j++) {
                let r = revisions[j];
                if (revision.commitId == r.commitId){
                    if(j>0) {
                        let previousRevision = revisions[j- 1].encounterRevision;
                        let indexFound = _.findIndex(previousRevision.units, function (unit2) {
                            return unit.unitId == unit2.unitId;
                        });
                        unitAdded = indexFound < 0;
                        if(unitAdded) {break;}
                    }
                }
            }
        }
        return  unitAdded;
    }

    function isUnitRemoved() {
        if (!revision || !revisions) {
            return null;
        }
        let unitRemoved = false;

        if (revisions.length>=2){
            for( let j=0; j< revisions.length;j++) {
                let r = revisions[j];
                if (revision.commitId == r.commitId){
                    if(j>0) {
                        let previousRevision = revisions[j- 1].encounterRevision;
                        if (previousRevision && previousRevision.units) {
                            for (let i = 0; i < previousRevision.units.length; i++) {
                                let u = previousRevision.units[i];
                                let unitExists = _.findIndex(revision.encounterRevision.units, function (ut) {
                                    return ut.unitId == u.unitId;
                                });
                                unitRemoved = unitExists < 0;
                                if (unitRemoved) {
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        return  unitRemoved;
    }

    let unitChange = _.find(revision.changes, function (ch) { return ch.propertyName === 'units'; });

    let unitRemoved = isUnitRemoved();

    return (
        <div className="contentView tableView encounterDetails">
            <div className="tableSection attributes">
                <div className={"sectionTitle"}>Call For Service Details</div>
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Caller Number"}
                    fieldPath={"contact.phone"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Status"}
                    fieldPath={"status"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Event Number"}
                    fieldPath={"eventNumber"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident Number"}
                    fieldPath={"incidentNumber"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Encounter Date"}
                    isDate={true}
                    fieldPath={"encounterDate"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Agency"}
                    fieldPath={"agency"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Agency Type"}
                    fieldPath={"agencyType"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Jurisdiction"}
                    fieldPath={"jurisdiction"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident Type"}
                    fieldPath={"incidentType"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident Type 2"}
                    fieldPath={"incidentType2"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident SubType"}
                    fieldPath={"incidentSubtype"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Priority"}
                    fieldPath={"priority"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Queue"}
                    fieldPath={"queue"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Disposition"}
                    fieldPath={"disposition"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Call Active"}
                    fieldPath={"callActive"} />
                
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Case Number"}
                    fieldPath={"caseNumbers"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Area"}
                    fieldPath={"area"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"ETA"}
                    fieldPath={"eta"} />
            </div>
            <div className="tableSection attributes">
                <div className={"sectionTitle"}>Call Details</div>
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Caller Method"}
                    fieldPath={"encounterCallDetails.callMethod"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Caller Type"}
                    fieldPath={"encounterCallDetails.callerType"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Caller Taker"}
                    fieldPath={"encounterCallDetails.callTakerName"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Caller Taker Employee ID"}
                    fieldPath={"encounterCallDetails.callTakerEmployeeId"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Call Received"}
                    isDate={true}
                    fieldPath={"encounterCallDetails.callReceived"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Call Answered"}
                    isDate={true}
                    fieldPath={"encounterCallDetails.callAnswered"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Call Dispatched"}
                    isDate={true}
                    fieldPath={"encounterCallDetails.callDispatched"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Call Closed"}
                    isDate={true}
                    fieldPath={"encounterCallDetails.callClosed"} />
            </div>
            <div className="tableSection attributes">
                <div className={"sectionTitle"}>Location Details</div>
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Address"}
                    fieldPath={"encounterLocation.summary"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Location Type"}
                    fieldPath={"encounterLocation.locationType"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"County"}
                    fieldPath={"encounterLocation.county"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Latitude"}
                    fieldPath={"encounterLocation.latitude"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Longitude"}
                    fieldPath={"encounterLocation.longitude"} />
            </div>
            <div className="tableSection attributes">
                <div className={"sectionTitle"}>Units</div>
                {
                    revision.encounterRevision.units ? (
                        _.map(_.sortBy(revision.encounterRevision.units, function (u) { return u.id; }), function (unit) {

                            let added = isUnitAdded(unit);

                            return (
                                <div className="encounterAttributeCell nested" key={unit.unitId}>
                                    <div className={`attributeCellSection ${added ? "added" : ""}`}>
                                        <div className="attributeCellSectionTitle">{unit.unitId}  {unit.primary ? '(Primary)' : ''}</div>

                                        <div className="attributeCellSectionContent">
                                            <div className="encounterNestedAttributeCell">
                                                <div className="currentValue">
                                                    <div className="attributeTitle">Time Dispatched</div>
                                                    <div className="attributeDetail">{unit.unitDispatched
                                                        ? getDate(unit.unitDispatched, "MM/DD/YYYY, h:mm:ss a", revision.encounterRevision)
                                                        : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="encounterNestedAttributeCell">
                                                <div className="currentValue">
                                                    <div className="attributeTitle">Time Enroute</div>
                                                    <div className="attributeDetail">{unit.unitEnroute
                                                        ? getDate(unit.unitEnroute, "MM/DD/YYYY, h:mm:ss a", revision.encounterRevision)
                                                        : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="encounterNestedAttributeCell">
                                                <div className="currentValue">
                                                    <div className="attributeTitle">Time Arrived</div>
                                                    <div className="attributeDetail">{unit.unitArrived
                                                        ? getDate(unit.unitArrived, "MM/DD/YYYY, h:mm:ss a", revision.encounterRevision)
                                                        : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="encounterNestedAttributeCell">
                                                <div className="currentValue">
                                                    <div className="attributeTitle">Time Cleared</div>
                                                    <div className="attributeDetail">{unit.unitCleared
                                                        ? getDate(unit.unitCleared, "MM/DD/YYYY, h:mm:ss a", revision.encounterRevision)
                                                        : ""}
                                                    </div>
                                                </div>
                                            </div>

                                            {/*<div className="encounterNestedAttributeCell changed">*/}
                                            {/*    <div className="currentValue">*/}
                                            {/*        <div className="attributeTitle">Time Arrived</div>*/}
                                            {/*        <div className="attributeDetail">12:39:34 PM</div>*/}
                                            {/*    </div>*/}

                                            {/*    <div className="previousValue">*/}
                                            {/*        <div className="attributeTitle">Previous Value</div>*/}
                                            {/*        <div className="attributeDetail">REPORTED</div>*/}
                                            {/*    </div>*/}

                                            {/*    <div className="changeIndicator">*/}
                                            {/*        <div className="indicatorTitle">Changed</div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            {/*<div className="encounterNestedAttributeCell added">*/}
                                            {/*    <div className="currentValue">*/}
                                            {/*        <div className="attributeTitle">Time Arrived</div>*/}
                                            {/*        <div className="attributeDetail">12:39:34 PM</div>*/}
                                            {/*    </div>*/}

                                            {/*    <div className="previousValue">*/}
                                            {/*        <div className="attributeTitle">Previous Value</div>*/}
                                            {/*        <div className="attributeDetail">REPORTED</div>*/}
                                            {/*    </div>*/}

                                            {/*    <div className="changeIndicator">*/}
                                            {/*        <div className="indicatorTitle">Added</div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            {/*<div className="encounterNestedAttributeCell deleted">*/}
                                            {/*    <div className="currentValue">*/}
                                            {/*        <div className="attributeTitle">Time Arrived</div>*/}
                                            {/*        <div className="attributeDetail">12:39:34 PM</div>*/}
                                            {/*    </div>*/}

                                            {/*    <div className="previousValue">*/}
                                            {/*        <div className="attributeTitle">Previous Value</div>*/}
                                            {/*        <div className="attributeDetail">REPORTED</div>*/}
                                            {/*    </div>*/}

                                            {/*    <div className="changeIndicator">*/}
                                            {/*        <div className="indicatorTitle">Deleted</div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>

                                    <div className="attributeCellSection">
                                        <div className="attributeCellSectionTitle">Personnel</div>
                                        <div className="attributeCellSectionContent">
                                            <div className="sectionContentCells">
                                                {
                                                    _.map(unit.personnel, function (p) {
                                                        let name = p.personnelId;
                                                        if (p.name) {
                                                            name = (<span>{p.name} <b>({p.personnelId})</b></span>);
                                                        }
                                                        return (
                                                            <div className="encounterNestedAttributeCell">
                                                                <div className="currentValue">
                                                                    <div className="attributeTitle"></div>
                                                                    <div className="attributeDetail">{name}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : null
                }
                {
                    unitRemoved ? (
                        <div className="encounterAttributeCell nested">
                            <div className={`attributeCellSection deleted`}>
                                <div className="attributeCellSectionTitle">UNIT REMOVED</div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}