import React from 'react';
import {API} from "aws-amplify";
import {toast} from "react-toastify";

import InputCell from "@/common/form/InputCell";

export default function AddEditRule(props){
    var { onCancel, onSuccess, existingRule} = props;

    const titleRef = React.createRef();

    const saveService = () => {
        if(existingRule){
            API.put('Core', '/api/v1/rule/' + existingRule.id, {body: {name: titleRef.current.value}})
                .then(response => {
                        toast("Rule Saved!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                        onSuccess(response);
                    },
                    error => {
                        toast("Could not update rule: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    }
                );
        }
        else{
            API.post('Core', '/api/v1/rule', {body: {name: titleRef.current.value}})
                .then(response => {
                        toast("Rule Saved!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                        onSuccess(response);
                    },
                    error => {
                        toast("Could not create rule: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    }
                );
        }
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"Rule Name"}
                            defaultValue={(existingRule ? existingRule.name : "")}
                            required
                            id="ruleName"
                            label="Rule Name"
                            inputRef={titleRef}
                        />
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveService} className={`button confirm medium`}>
                        <div className="title">Done</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>

    )
}