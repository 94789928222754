import React from 'react';
import InputCell from "../../../common/form/InputCell";
import {API} from "aws-amplify";
import {toast} from "react-toastify";

export default function AddEditDepartment(props){
    var { onCancel, onSuccess, existingDepartment} = props;

    const nameRef = React.createRef();

    const saveDepartment = () => {
        let init = {body: {name:nameRef.current.value}};
        if(existingDepartment){
            API.put('Core', '/api/v1/department/' + existingDepartment.id, init)
                .then(response => {
                    toast.success("Department Updated!", {position: toast.POSITION.TOP_CENTER});
                    onSuccess(response);
                },
                error => {
                    toast.error("Could not update department: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                })
        }
        else{
            API.post('Core', '/api/v1/department', init)
                .then(response => {
                        toast.success("Department Created!", {position: toast.POSITION.TOP_CENTER});
                        onSuccess(response);
                    },
                    error => {
                        toast.error("Could not create department: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                    }
                );
        }
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={""}
                            defaultValue={existingDepartment ? existingDepartment.name : ""}
                            required
                            id="name"
                            label="Department Name"
                            inputRef={nameRef}
                        />

                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveDepartment} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>

    )
}