import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { toast } from "react-toastify";
import { FormControlLabel, Switch, TextField, Typography } from "@material-ui/core";
import { API } from "aws-amplify";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export default function AppIntegrationsIndex() {
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);

  const loadApps = () => {
    API.get("Core", "/api/v1/app-integration").then(
      (response) => {
        setApps(response);
      },
      (error) => {
        toast("Could not get app integrations: " + error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.ERROR,
        });
      }
    );
  };

  React.useEffect(() => {
    loadApps();
  }, []);

  const AppIntegrationList = () => {
    return (
      <Table className="appIntegrationList" stickyHeader aria-label="sticky table">
        <TableBody>
          {apps.map((app) => {
            return (
              <TableRow className={`${app.enabled ? "enabled" : ""}`}  role="checkbox" tabIndex={-1} key={app.externalApp.code}>
                <TableCell >
                  {app.externalApp.appName}
                </TableCell>
                <TableCell >
                  {app.enabled ? "Enabled" : "Disabled"}
                </TableCell>
                <TableCell key={"edit"}>
                    <Button className="configureButton"
                      variant="contained"
                      color="primary"
                      onClick={() => setSelectedApp(app)}
                    >
                      Configure
                    </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    )
  }

  const SelectedApp = ({ appCode }) => {
    const [appIntegration, setAppIntegration] = useState(null);

    useEffect(() => {
      API.get("Core", `/api/v1/app-integration/${appCode}`).then(
        (response) => {
          setAppIntegration(response);
        },
        (error) => {
          toast("Could not get app integration: " + error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            type: toast.TYPE.ERROR,
          });
        }
      );
      setAppIntegration()
    }, [appCode]);

    const onChangeEnabled = () => {
      const enabled = !appIntegration.enabled;
      API.post("Core", `/api/v1/app-integration/${appCode}/enabled/${enabled}`).then(
        (response) => {
          setAppIntegration(response);
          toast.success(`App integration ${enabled ? "enabled" : "disabled"}!`, {position: toast.POSITION.TOP_CENTER});
        },
        (error) => {
          toast("Could not update app integration: " + error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            type: toast.TYPE.ERROR,
          });
        }
      );
    }

    const regenerateApiKey = () => {
      API.post("Core", `/api/v1/app-integration/${appCode}/regenerate`).then(
        (response) => {
          setAppIntegration(response);
          toast.success(`Api key regenerated!`, {position: toast.POSITION.TOP_CENTER});
        },
        (error) => {
          toast("Could not update app integration: " + error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            type: toast.TYPE.ERROR,
          });
        }
      );
    }

    return (
      <div className="selectedAppIntegration">
        {
          !appIntegration ? "Loading..." :
          <div className="formGroup">
            <div className="formField enabledField">
              <FormControlLabel
                control={
                  <Switch
                    checked={appIntegration.enabled}
                    onChange={onChangeEnabled}
                    color="primary"
                  />
                }
                label="Enabled"
              />
            </div>
            { 
              appIntegration.enabled && 
              <div className="formField">
                <TextField className="inputText" variant="outlined"
                label="API Key" value={appIntegration.idpId}
                InputProps={{
                  readOnly: true,
                  endAdornment: (<IconButton onClick={() => navigator.clipboard.writeText(appIntegration.idpId)}
                      aria-label="Copy to clipboard"
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>)
                }}
              />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={regenerateApiKey}
                >
                  Regenerate
                </Button>
              </div>
            }
              
            
          </div>
        }
        
        
      </div>
    )
  }

  const onClickBack = () => {
    setSelectedApp(null);
    loadApps();
  }

  return (
    <Grid className="AppIntegrationContainer" container spacing={3} style={{ marginTop: 10 }}>
      <Grid item xs={12}>
          {
            selectedApp ? 
            <div className="navigation">
              <IconButton aria-label="back"  size="small" disableRipple onClick={onClickBack}>
                <ArrowBackIosIcon fontSize="inherit" />
              </IconButton>
              <Typography variant="h6" style={{color:"#8a8a8a"}}>
                  {selectedApp.externalApp.appName}
              </Typography>
            </div> : 

            <Typography variant="h6" style={{color:"#8a8a8a"}}>
                App Integrations Settings
            </Typography>
          }
          
      </Grid>
      <Grid item xs={12}>
          {
            selectedApp ? <SelectedApp appCode={selectedApp.externalApp.code} /> : <AppIntegrationList />
          }
      </Grid>
    </Grid>
  );
}
