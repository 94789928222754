import React from "react"

import moment from '@/common/momentConfig'
import {
  AssetTestRuleStatusFail, AssetTestRuleStatusSuccess, AssetActivityIndicatorMedium
} from "@/icons/Assets"

function TestRuleCell(props) {
  const { testStatus, filterSchema, filterSchemaType, filterOperator, filterValue } = props;
  moment.suppressDeprecationWarnings = true;

  function getFilterActualValue() {
    if (Number(props.filterActualValue)) {
      return props.filterActualValue;
    }

    if (moment(props.filterActualValue, moment.ISO_8601, true).isValid()) {
      return moment(props.filterActualValue).format('YYYY-MM-DD');
    }

    return props.filterActualValue;
  }

  function getFilterValue() {
    if (!props.filterValue) {
      return null;
    }
    if (Number(props.filterValue) && props.filterSchema.toLowerCase().includes('date')) {
      return `${props.filterValue} sec`;
    }
    if (!Number.isNaN(Number(props.filterValue))) {
      return filterSchemaType === 'BOOLEAN' ? (props.filterValue === "0" ? "false" : "true") : props.filterValue;
    }
    if (moment(props.filterValue, moment.ISO_8601, true).isValid()) {
      return moment().format('YYYY-MM-DD');
    }
    return props.filterValue;
  }

  function getIcon() {
    switch (testStatus) {
      case 'success':
        return <AssetTestRuleStatusSuccess className="testStatusIcon" />;
      case 'fail':
        return <AssetTestRuleStatusFail className="testStatusIcon" />;
      case 'loading':
        return <AssetActivityIndicatorMedium className="testStatusIcon activityIndicator" />;
      default:
        return null;
    }
  }

  const filterActualValue = getFilterActualValue();
  const filterValueElement = getFilterValue() && (
      <div className="filterDetailItem filterValue">{getFilterValue()}</div>
  );
  const icon = getIcon();
  const testStatusTitleText = `${filterSchema} is ${filterActualValue}`;
  const testStatusTitle = (
      <div className="testStatus">
        <div className="testStatusTitle">{testStatusTitleText}</div>
      </div>
  );

  return (
      <div className={`testRuleCell ${testStatus}`}>
        <div className="testDetails">
          <div className="filterDetails">
            <div className="filterDetailItem filterSchema">{filterSchema}</div>
            <div className="filterDetailItem filterOperator">{filterOperator}</div>
            {filterValueElement}
          </div>
          {icon}
        </div>
        <div className="separator" />
        {testStatusTitle}
      </div>
  );
}

export default TestRuleCell;


