import React from 'react';
import InputCell from "../../../common/form/InputCell";
import {API} from "aws-amplify";
import {toast} from "react-toastify";

export default function AddFieldOption(props){
    var { onCancel, onSuccess, fieldName, existingFieldOption} = props;

    const optionNameRef = React.createRef();
    const optionCodeRef = React.createRef();

    const saveFieldOption = () => {
        const body = {
            fieldName: fieldName, 
            optionName: optionNameRef.current.value,
            optionCode: optionCodeRef.current.value
        };

        if (existingFieldOption) {
            API.put('Core', `/api/v1/fieldoption/${existingFieldOption.id}`, {
                body: body
            })
                .then(response => {
                        toast.success("Option Updated!", {position: toast.POSITION.TOP_CENTER});
                        onSuccess(response);
                    },
                    error => {
                        toast.error("Could not update option: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                    }
                );
        } else {
            API.post('Core', '/api/v1/fieldoption', {
                body: body
            })
                .then(response => {
                        toast.success("Option Created!", {position: toast.POSITION.TOP_CENTER});
                        onSuccess(response);
                    },
                    error => {
                        toast.error("Could not create option: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                    }
                );
        }

        
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={""}
                            defaultValue={existingFieldOption ? existingFieldOption.optionName : ""}
                            required
                            id="optionName"
                            label="Name"
                            inputRef={optionNameRef}
                        />
                    </div>
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={""}
                            defaultValue={existingFieldOption ? existingFieldOption.optionCode : ""}
                            required
                            id="optionCode"
                            label="Code"
                            inputRef={optionCodeRef}
                        />
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveFieldOption} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>

    )
}