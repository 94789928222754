import React from "react";
import _ from "lodash";
import PhoneInput from "react-phone-input-2";

import Modal from "@/common/Modal";
import EncounterList from "@/components/EncounterList";

export default function StartConversation({encounters, loadEncounters, onStart, title, submitBtnTitle, ...props}){
    const [phoneVal, setPhoneVal] = React.useState("");
    const [selectedEncounter, setSelectedEncounter] = React.useState(null);
    const [sendOpen, setSendOpen] = React.useState(false);

    const startConversation = () => {
        if(!canSubmit()){
            return;
        }
        onStart(phoneVal, selectedEncounter);
        reset();
    }

    const reset = () => {
        setSendOpen(false);
        setPhoneVal("");
        setSelectedEncounter(null);
    }

    const onPhoneChange = (phone) => {
        setPhoneVal(phone);
    }

    const isSelectedEncounter = (e) => {
        return (!_.isNil(selectedEncounter) && e.id == selectedEncounter.id);
    }

    const onEncounterSelect = (e) => {
        if(isSelectedEncounter(e)){
            setSelectedEncounter(null);
        }
        else{
            setSelectedEncounter(e);
        }

    }

    const canSubmit = () => {
        return (!_.isNil(selectedEncounter) || phoneVal);
    }

    const handleSendOpen = () => {
        loadEncounters();
        reset();
        setSendOpen(true);
    }

    const handleSendClose = () => {
        setSendOpen(false);
        reset();
    }
    return (
        <div>
            <Modal
                button={props.children}
                content={(
                    <div>
                        <div className={"content startConversationView"}>
                            <div className="tableView">
                                <div className="tableSection sectionPhone">
                                    <div className="sectionTitle">Start Manually</div>
                                    <div className="tableCell inputCell phoneInput">
                                        <div className="title">Phone Number</div>
                                        <PhoneInput
                                            inputClass="tel-input"
                                            country={'us'}
                                            onlyCountries={['us']}
                                            value={phoneVal}
                                            onChange={onPhoneChange}
                                        />
                                    </div>
                                </div>
                                <div className="tableSection sectionRecentEncounters">
                                    <div className="sectionTitle">Recent Encounters</div>
                                    <EncounterList title="" onEncounterSelect={onEncounterSelect} selectedEncounter={selectedEncounter} encounters={encounters}/>
                                </div>
                            </div>
                        </div>
                        <div className="actionBar">
                            <div className="actions">
                                <div onClick={handleSendClose} className="button dismiss medium">
                                    <div className="title">Cancel</div>
                                </div>

                                <div onClick={canSubmit() ? startConversation : null} className={`button confirm medium ${canSubmit() ? '' : 'disabled'}`}>
                                    <div className="title">{submitBtnTitle}</div>
                                </div>
                            </div>

                            <div className="separator"></div>
                        </div>
                    </div>
                )}
                title={title}
                size="xxl"
                handleClose={handleSendClose}
                handleOpen={handleSendOpen}
                open={sendOpen}
            />
        </div>
    )
}