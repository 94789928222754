import React, {useState} from 'react';
import {
    Button,
    makeStyles,
    TextField,
    IconButton,
    InputAdornment,
} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import PasswordChecklist from 'react-password-checklist';
import {toast} from 'react-toastify';
import {Auth} from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
    updatePasswordContainer: {
        width: '100%',
        padding: theme.spacing(2),
    },
    cardTitle: {
        marginBottom: theme.spacing(1),
        fontSize: '18px',
    },
    inputsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: theme.spacing(1),
        marginRight: '-15px',
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            width: '100%',
        },
        '& .MuiInputBase-input': {
            height: '3em',
            boxSizing: 'border-box',
        },
    },
    resendCodeButton: {
        color: '#047d95',
        backgroundColor: 'transparent',
        padding: theme.spacing(0.5, 1.5),
        width: '100%',
        borderRadius: '4px',
        transition: 'background-color 0.2s ease-in-out',
        border: 'none',
        textTransform: 'none',
        fontSize: '14px',
        '&:hover': {
            backgroundColor: 'rgba(4, 125, 149, 0.1)',
        },
    },
    sendChangePasswordRequest: {
        backgroundColor: '#FF9900',
        textTransform: 'uppercase',
        color: 'white',
        padding: theme.spacing(1, 0),
        minWidth: '120px',
        fontWeight: 400,
        fontFamily: 'Arial',
        letterSpacing: '1.1px',
        fontSize: '12px',
        lineHeight: '18px',
        borderRadius: 0,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#FF9900',
            color: 'white',
        },
    },
    iconButton: {
        padding: theme.spacing(0.5),
    },
    inputAdornment: {
        height: '100%',
    },
    divider: {
        height: '45px',
        borderLeft: '1px solid #ccc',
        marginRight: theme.spacing(0.5),
    },
}));

export default function CognitoVerifyChangePassword(props) {
    const CLASSES = useStyles();
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const resendCode = () => {
        Auth.forgotPassword(props.email);
    };

    const requestPasswordChange = () => {
        if (!password) {
            toast("Password cannot be empty.", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            return;
        }
        if (password !== confirmPassword) {
            toast("Passwords must match.", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            return;
        }

        Auth.forgotPasswordSubmit(props.email, code, password)
            .then(() => {
                toast("Password updated successfully", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                props.setShowResetPassword(false);
            })
            .catch((e) => {
                toast("Error updating password", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                console.error('Error:', e);
                props.setShowResetPassword(false);
            });
    };

    const shouldDisplayChecklist = password && confirmPassword && password !== confirmPassword;

    return (
        <div className={CLASSES.updatePasswordContainer}>
            <div className={`gatekeeperTitle ${CLASSES.cardTitle}`}>Reset your password</div>
            <fieldset className={CLASSES.inputsContainer}>
                <TextField
                    className={CLASSES.textField}
                    id="codeInput"
                    label="Code"
                    placeholder="Code"
                    variant="outlined"
                    onChange={(e) => setCode(e.target.value)}
                    required
                    fullWidth
                />

                <TextField
                    className={CLASSES.textField}
                    id="passwordInput"
                    label="New Password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="New Password"
                    variant="outlined"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" className={CLASSES.inputAdornment}>
                                <div className={CLASSES.divider}></div>
                                <IconButton
                                    className={CLASSES.iconButton}
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    className={CLASSES.textField}
                    id="confirmPasswordInput"
                    label="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    variant="outlined"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" className={CLASSES.inputAdornment}>
                                <div className={CLASSES.divider}></div>
                                <IconButton
                                    className={CLASSES.iconButton}
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                {shouldDisplayChecklist && (
                    <PasswordChecklist
                        rules={['match']}
                        value={password}
                        valueAgain={confirmPassword}
                        messages={{match: 'Passwords must match.'}}
                    />
                )}
            </fieldset>
            <div className={CLASSES.buttonsContainer}>
                <Button
                    onClick={requestPasswordChange}
                    className={CLASSES.sendChangePasswordRequest}
                >
                    Submit
                </Button>
                <Button
                    onClick={resendCode}
                    className={CLASSES.resendCodeButton}
                >
                    Resend Code
                </Button>
            </div>
        </div>
    );
}