import { createTheme } from '@material-ui/core/styles';
import deepOrange from '@material-ui/core/colors/deepOrange';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const theme = createTheme({
    palette: {
        primary: {
            light: '#ffffff',
            main: '#b4b4c0',
            // dark: will be calculated from palette.primary.main,
            contrastText: '#ffffff',
        },
        secondary: deepOrange,
        success: green,
        danger: red
    }
});
export default theme;