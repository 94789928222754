import React from "react";
import Select from "react-select";
import _ from "lodash";
import {ContentState, Editor, EditorState} from "draft-js";
import InputCell from "../../common/form/InputCell";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import { frequencies, dowValues, tabs } from './DigestSettingsContants';

const CALLTAKER = "CALLTAKER";
const RESPONDER = "RESPONDER";

export default function AddEditDigestSettings(props) {
    var { existingDigestSettings, onCancel, onSuccess } = props;

    const [activeTab, setActiveTab] = React.useState(CALLTAKER);
    const [currentDigestSettings, setCurrentDigestSettings] = React.useState({
        id: null,
        subjectRole: null,
        frequency: null,
        frequencyValue: null,
        minimumCPSSScore: null,
        introductionContent: null,
        lastFireTime: null,
        receivers: null,
        type: null,
        hoursValue: null,
        minutesValue: null,
        dayOfWeek: null,
        timezone: null,
        active: true,
        name: null,
        logs: null,
        feedback: null
    });
    const [introEditorState, setIntroEditorState] = React.useState(EditorState.createEmpty());
    const [doneDisabled, setDoneDisabled] =  React.useState(true)

    let introDomEditor;
    const setIntroDomEditorRef = ref => introDomEditor = ref;

    React.useEffect(() => {
        const cds = getDigestSettingBasedOnRole()
        setCurrentDigestSettings(cds !== null ? cds : {});
        const contentState = ContentState.createFromText(cds.introductionContent);
        setIntroEditorState(EditorState.createWithContent(contentState));
    }, [activeTab])

    React.useEffect(() => {
        setDoneDisabled(!currentDigestSettings.frequency || !currentDigestSettings.frequencyValue);
    }, [currentDigestSettings])

    function editorContainerClick() {
        introDomEditor.focus();
    }

    function getDigestSettingBasedOnRole() {
        let cds = _.find(existingDigestSettings, function (eds) {
            return eds.subjectRole === activeTab;
        });
        cds = cds || {};
        cds.frequencyValue = cds.frequencyValue === 0 ? 24 : cds.frequencyValue;
        cds.minimumCPSSScore = cds.minimumCPSSScore || 90;
        cds.introductionContent = cds.introductionContent || "";
        return cds;
    }

    const setProperty = (property, value) => {
        setCurrentDigestSettings(prevSettings => ({
            ...prevSettings,
            [property]: value,
        }));
    };

    const getCurrentFrequency = () => {
        if (currentDigestSettings != null && currentDigestSettings.frequency != null) {
            return _.find(frequencies, function (f) {
                return f.value === currentDigestSettings.frequency;
            });
        }
        return null;
    }

    const getCurrentFrequencyValue = () => {
        if (currentDigestSettings != null && currentDigestSettings.frequencyValue != null) {
            return _.find(dowValues, function (f) {
                return f.value === currentDigestSettings.frequencyValue;
            });
        }
        return null;
    }

    const getTabTitle = (tabKey) => {
        // eslint-disable-next-line default-case
        switch (tabKey) {
            case CALLTAKER:
                return "Call Taker";
            case RESPONDER:
                return "Responder";
        }
    }

    const setTab = (tabKey) => {
        if(tabKey !== activeTab){
            setActiveTab(tabKey);
        }
    }

    const updateIntroEditorState = (newEditorState) => {
        setIntroEditorState(newEditorState);
        setProperty('introductionContent',newEditorState.getCurrentContent().getPlainText())
    }

    const validateDigestSettings = (digestSettingDto) => {
        const frequencyValue = Number(digestSettingDto.frequencyValue)
        if (digestSettingDto.frequency === 'DAILY' && (frequencyValue < 1 || frequencyValue > 24)) {
            toast.error("Hour of the day must be between 1 and 24 values.", { position: toast.POSITION.TOP_CENTER });
            return false;
        }

        if (digestSettingDto.frequency === 'MONTHLY' && (frequencyValue < 1 || frequencyValue > 31)) {
            toast.error("Day of month must be between 1 and 31 values.", { position: toast.POSITION.TOP_CENTER });
            return false;
        }

        return true;
    }

    const saveDigestSettings = (close) => {
        let digestSettingDto = {
            subjectRole: activeTab,
            frequency: currentDigestSettings.frequency,
            frequencyValue: currentDigestSettings.frequencyValue,
            minimumCPSSScore: currentDigestSettings.minimumCPSSScore || 90,
            introductionContent: currentDigestSettings.introductionContent
        }

        if(!validateDigestSettings(digestSettingDto)) {
            return;
        }

        if (currentDigestSettings.id != null) {
            API.put('Core', '/api/v1/digest/settings/' + currentDigestSettings.id, { body: digestSettingDto })
                .then(response => {
                    toast.success("Digest Setting Updated!", { position: toast.POSITION.TOP_CENTER });
                    onSuccess(response, close);
                },
                    error => {
                        toast.error("Could not update disposition: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER });
                    }
                );
        }
        else {
            API.post('Core', '/api/v1/digest/settings', { body: digestSettingDto })
                .then(response => {
                    toast.success("Digest Setting Created!", { position: toast.POSITION.TOP_CENTER });
                    onSuccess(response, close);
                },
                    error => {
                        toast.error("Could not create disposition: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER });
                    }
                );
        }
    }

    const onChangeProperty = (property, e) => {
        setProperty(property, e.target.value);
    }
    const selectFrequency = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setProperty('frequency', obj.value)
                setProperty('frequencyValue', null)
                break;
        }
    }

    const selectFrequencyValue = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setProperty('frequencyValue', obj.value)
                break;
        }
    }

    return (
        <div>
            <div className="content">
                <div className={`segmentedBar index${_.indexOf(tabs, activeTab)}`} style={{ width: "100%" }}>
                    {
                        _.map(tabs, function (t) {
                            return (
                                <div onClick={() => setTab(t)} className={`segment ${t === activeTab ? 'selected' : ''}`}>
                                    <div className="title">{getTabTitle(t)}</div>
                                </div>
                            );
                        })
                    }
                    <div className="selection" style={{ left: (_.indexOf(tabs, activeTab) * (100 / tabs.length)) + '%', width: (100 / tabs.length) + '%' }} />
                </div>

                <div className="tableView">
                    <div className="tableSection">
                        <div className="tableCell editorCell multilineCell" onClick={editorContainerClick}>
                            <div className="title">Intro</div>
                            <Editor
                                readOnly={false}
                                style={{ height: "100%", width: "100%" }}
                                editorState={introEditorState}
                                onChange={updateIntroEditorState}
                                spellCheck={true}
                                stripPastedStyles={true}
                                ref={setIntroDomEditorRef}
                            />
                        </div>

                        <div className="tableCell titledSelectCell">
                            <div className="title">Frequency</div>
                            <Select className="tableCell selectCell"
                                    value={getCurrentFrequency()}
                                    classNamePrefix="selectCell"
                                    isDisabled={false}
                                    onChange={selectFrequency}
                                    options={frequencies} />
                        </div>

                        {
                            currentDigestSettings.frequency === 'DAILY' ? (
                                <div className="tableCell titledSelectCell">
                                    <div className="title">Hour of Day</div>
                                    <InputCell
                                        placeholder={"1"}
                                        defaultValue={currentDigestSettings.frequencyValue}
                                        required
                                        type={"number"}
                                        id="monthDow"
                                        onChange={onChangeProperty.bind(this, 'frequencyValue')}
                                        minValue="1"
                                        maxValue="24"
                                    />
                                </div>
                            ) : null
                        }

                        {
                            currentDigestSettings.frequency === 'WEEKLY' ? (
                                <div className="tableCell titledSelectCell">
                                    <div className="title">Day of Week</div>
                                    <Select className="tableCell selectCell"
                                        value={getCurrentFrequencyValue()}
                                        classNamePrefix="selectCell"
                                        isDisabled={false}
                                        onChange={selectFrequencyValue}
                                        options={dowValues}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                </div>
                            ) : null
                        }

                        {
                            currentDigestSettings.frequency === 'MONTHLY' ? (
                                <div className="tableCell titledSelectCell">
                                    <div className="title">Day of Month</div>
                                    <InputCell
                                        placeholder={"1"}
                                        defaultValue={currentDigestSettings.frequencyValue}
                                        required
                                        type={"number"}
                                        id="monthDow"
                                        onChange={onChangeProperty.bind(this, 'frequencyValue')}
                                        minValue="1"
                                        maxValue="31"
                                    />
                                </div>
                            ) : null
                        }

                        <div className="tableCell titledSelectCell">
                            <div className="title">Minimum CPSS Score</div>
                            <InputCell
                                placeholder={"90"}
                                defaultValue={currentDigestSettings.minimumCPSSScore}
                                onChange={onChangeProperty.bind(this, 'minimumCPSSScore')}
                                required
                                type={"number"}
                                id="minScore"
                            />
                            <div className="valueDescription">%</div>
                        </div>
                        <div className="cellFootnote">Digest will only be sent if subject has a CPSS score equal to or above the threshold.</div>
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions left">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>
                </div>

                <div className="actions right">
                    <div onClick={(e) => {
                        if (doneDisabled) { e.preventDefault(); return; }
                        saveDigestSettings.bind(this, true)(e);
                    }}  className={`button confirm medium ${doneDisabled ? 'disabled' : ''}`}>
                        <div className="title">Done</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>
    );
}