import React from 'react';
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import _ from "lodash";

import ConversationEncounterAttributeCell from "./ConversationEncounterAttributeCell";
import LocalTimeStamp from "@/common/LocalTimeStamp";

export default function ConversationEncounter({conversationId}){

    const [encounter, setEncounter] = React.useState(false);

    const loadEncounter = () => {
        API.get('Core', '/api/v1/conversation/' + conversationId + '/encounter')
            .then(response => {
                    setEncounter(response);
                },
                error => {
                    toast("Could not load encounter details: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    React.useEffect(() => {
        setEncounter(null);
        loadEncounter();
    }, [conversationId])

    if(!encounter){
        return null;
    }

    let unitNames = encounter.units ? _.map(encounter.units, function(u){
       return u.unitId;
    }) : [];
    return (
        <div className="sideBarSection sectionConsultation">
            <div className="sectionTitle">Encounter Details</div>

            <ConversationEncounterAttributeCell attributeTitle="Type" attributeDetail={encounter.incidentType}/>
            <ConversationEncounterAttributeCell attributeTitle="Incident #" attributeDetail={encounter.incidentNumber}/>
            {unitNames ? (
                <ConversationEncounterAttributeCell attributeTitle="Unit" attributeDetail={unitNames.join(", ")}/>
            ) : null}
            <ConversationEncounterAttributeCell attributeTitle="Call Received" attributeDetail={
                <LocalTimeStamp utcTimestamp={encounter && encounter.encounterCallDetails ? encounter.encounterCallDetails.callReceived : ""} format={"MM/DD/YYYY, h:mm:ss a"}/>
            }/>
            <ConversationEncounterAttributeCell attributeTitle="Location" attributeDetail={encounter && encounter.encounterLocation ? encounter.encounterLocation.summary : ""}/>

            <div className="sectionSeparator">
                <div className="separator"></div>
            </div>
        </div>
    )
}