import _ from 'lodash';

import store from './store';


function hasAuthority(privilege){
    let state = store.getState();
    if(!state.user || !state.user.aboutMe || !state.user.aboutMe.principal || !state.user.aboutMe.principal.authorities){
        return false;
    }
    let authorities = state.user.aboutMe.principal.authorities;
    let found = _.findIndex(authorities, function(a){return a.authority === privilege;});
    return found !== -1;
}

export function hasAuthoritiesLoaded(){
    let state = store.getState();
    if(state.user && state.user.aboutMe && state.user.aboutMe.principal && state.user.aboutMe.principal.authorities){
        return true;
    }
    return false;
}

function hasTenantFeature(tenantFeature){
    let state = store.getState();
    if(!state.user || !state.user.aboutMe || !state.user.aboutMe.currentTenant || !state.user.features){
        return false;
    }
    let found = _.findIndex(state.user.features, function(a){return a.feature === tenantFeature;});
    return found !== -1;
}

export function getCurrentUser(){
    let state = store.getState();
    if(!state.user || !state.user.aboutMe || !state.user.aboutMe.user){
        return null;
    }
    return state.user.aboutMe.user;
}

export function canAdmin(){
    return hasAuthority("SYSTEM_ADMIN");
}

export function canManagePatients(){
    return hasTenantFeature("PATIENTS") && (hasAuthority("SYSTEM_ADMIN") || hasAuthority("EDIT_PATIENT_DETAILS"));
}

export function canManageRecordConnectors(){
    return hasTenantFeature("RECORD_CONNECTORS") && hasAuthority("SYSTEM_ADMIN");
}

export function canManageProviderRotations(){
    return hasTenantFeature("PROVIDER_ROTATIONS") && (hasAuthority("MANAGE_PROVIDER_ROTATIONS") || hasAuthority("SYSTEM_ADMIN"));
}

export function canBatchFeature(){
    return hasTenantFeature("ENCOUNTER_BATCH");
}

export function canCadEncounterFeature(){
    return hasTenantFeature("CAD_ENCOUNTER");
}

export function canRMSCaseEncounterFeature(){
    return hasTenantFeature("RMS_CASE_ENCOUNTER");
}

export function canManageUsers() {
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("MANAGE_USERS");
}

export function canMessageUsers() {
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("PERSONNEL_NOTIFICATIONS");
}

export function canManageCommunicationGroups() {
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("MANAGE_COMMUNICATION_GROUPS");
}

export function canMarkAsReadConversations() {
    return hasAuthority("PARTICIPATE_CONVERSATIONS");
}

export function canViewReports(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("VIEW_REPORTS");
}

export function canManageSatisfaction(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("MANAGE_SATISFACTION");
}

export function canViewActivity(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("VIEW_ACTIVITY");
}

export function canViewFeedbackBoard(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("VIEW_FEEDBACK_BOARD");
}

export function canManageSurveys(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("MANAGE_SURVEYS");
}

export function canQASurveys(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("SURVEY_QA");
}

export function canManageTasks(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("MANAGE_TASKS");
}

export function canManageRules(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("MANAGE_RULES");
}

export function canManageArticles(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("MANAGE_ARTICLES");
}

export function canViewConversations(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("VIEW_CONVERSATIONS");
}

export function canParticipateConversations(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("PARTICIPATE_CONVERSATIONS");
}

export function convoActionVideo(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("CONVO_ACTION_VIDEO");
}

export function convoActionLibrary(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("CONVO_ACTION_LIBRARY");
}

export function convoActionRefer(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("CONVO_ACTION_REFER");
}

export function convoActionService(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("CONVO_ACTION_SERVICE");
}

export function convoActionLocation(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("CONVO_ACTION_LOCATION");
}

export function convoActionFile(){
    return hasAuthority("SYSTEM_ADMIN") || hasAuthority("CONVO_ACTION_FILE");
}

export function isLanguageSupportEnable(){
    return hasTenantFeature("SUPPORTED_LANGUAGES");
}

export function isIgnoreAnswerEnabled(){
    return hasTenantFeature("IGNORE_SURVEY_ANSWER");
}

export function isPatientsEnabled(){
    return hasTenantFeature("PATIENTS");
}

export function isCandidateCommsEnabled(){
    return hasTenantFeature("CANDIDATE_COMMS");
}

export function isPrintSurveyEnabled(){
    return hasTenantFeature("PRINT_SURVEY_RESULTS");
}

export function isActivityDigestEmailEnabled(){
    return hasTenantFeature("ACTIVITY_EMAIL_DIGEST");
}

export function isDepartmentFeatureDisabled(){
    return !hasTenantFeature("DEPARTMENT");
}

export function isPlatformUserSyncEnabled(){
    return hasTenantFeature("PLATFORM_USER_SYNCHRONIZATION");
}

export function canManageAgencyInfo(){
    return hasTenantFeature("CASE_LOOKUP_WEBSITE") && hasAuthority("SYSTEM_ADMIN");
}

export function canManageEmailDigest(){
    return isActivityDigestEmailEnabled() && (hasAuthority("SYSTEM_ADMIN") || hasAuthority("MANAGE_EMAIL_DIGEST"));
}

export function isNewVideoCallEnabled(){
    return hasTenantFeature("NEW_VIDEO_CALL");
}

export function hideAuthorsFromFocusBoard(){
    return hasTenantFeature("HIDE_AUTHORS_FROM_FOCUS_BOARD");
}