import React from "react";
import Modal from "../../common/Modal";
import {API} from "aws-amplify";
import {toast} from "react-toastify";

export default function ProviderRotationMemberUpdate(props){
    var {rotationMember, onUpdate} = props;

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleConfirm = () => {
        let newWorkingState =  !rotationMember.working;
        API.put('Core', '/api/v1/provider-rotation/member/' + rotationMember.id, {body: {working: newWorkingState}})
            .then(response => {
                    toast(newWorkingState ? "You are now working!" : "You are no longer working!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                    onUpdate();
                },
                error => {
                    toast("Could not update working state: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const getRotationMemberUpdateBody = () => {
        return (
            <div>

                <div className={"alertBody"}>
                    <div className={"alertTitle"}>{rotationMember.working ? "Stop Working?" : "Ready to Work?"}</div>
                    <div className={"alertMessage"}>
                        {
                            rotationMember.working ? (<div>You are currently working.  When you are ready to end your shift click stop working.</div>) : (<div>Do you want to mark yourself as ready to work?</div>)
                        }
                    </div>
                </div>

                <div className={"alertActions dualActions"}>
                    <div onClick={handleClose} className={"alertAction button action medium"}>
                        <div className={"buttonTitle"}>Cancel</div>
                    </div>

                    <div onClick={handleConfirm} className={"alertAction button confirm medium"}>
                        <div className={"buttonTitle"}>{rotationMember.working ? "Stop Working" : "Ready to Work"}</div>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <Modal
            button={(<div onClick={handleOpen}>{props.children}</div>)}
            content={getRotationMemberUpdateBody()}
            dialogClassName={"alertModal"}
            title={null}
            size="xs"
            handleClose={handleClose}
            handleOpen={handleOpen}
            open={open}
        />
    )
}