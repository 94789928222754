import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import SignatureCanvas from 'react-signature-canvas'
import _ from 'lodash';

import InputCell from "@/common/form/InputCell";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({

})

function ConfirmServiceOfferings(props) {
    const { acceptHash } = props.match.params;
    const [acceptInfo, setAcceptInfo] = React.useState(false);
    const [sigPad, setSigPad] = React.useState({});
    const [submitting, setSubmitting] = React.useState(false);
    const answerRef = React.createRef();

    React.useEffect(async () => {
        const data = await API.get('Unauthorized', '/u/service-offering-accept/' + acceptHash)
        setAcceptInfo(data);
    }, [])

    const onSubmit = () => {
        if (submitting) {
            return;
        }
        setSubmitting(true);
        let confirmAccept = {
            challengeQuestionAnswer: answerRef.current.value,
            signature: sigPad.getTrimmedCanvas().toDataURL('image/png')
        }
        API.post('Unauthorized', '/u/service-offering-accept/' + acceptHash, { body: confirmAccept })
            .then(response => {
                setSubmitting(false);
                setAcceptInfo(response);
            },
                error => {
                    toast(error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                    setSubmitting(false);
                }
            );
    }

    if (!acceptInfo) {
        return (<span>Loading...</span>);
    }

    if (acceptInfo.accepted) {
        return (
            <div className={"customerView customerServiceOffering confirmation"}>
                <div className="customerContentView">
                    <div className="logo" />

                    <div className="customerForm">
                        <div className="formHeader">
                            <div className="title">Review and confirm the offered services.</div>
                            <div className="separator" />
                        </div>

                        <div className="formContent tableView confirmation">
                            <div className="placeholder">
                                <h1 className="title">Service Accepted</h1>
                                <p className="body">You have successfully confirmed the offered services! Close this window and continue your conversation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={"customerView customerServiceOffering"}>
            <div className="customerContentView">
                <div className="logo" />

                <div className="customerForm">
                    <div className="formHeader">
                        <div className="title">Review and confirm the offered services.</div>
                        <div className="separator" />
                    </div>

                    <div className="formContent tableView">
                        <div className="tableSection services">
                            <div className="header">SERVICES</div>
                            {
                                _.map(acceptInfo.serviceOfferings, function (so) {
                                    return (
                                        <div className="cell service">
                                            <div className="icon">
                                                <div className="glyph" />
                                            </div>
                                            <div className="cellBody">
                                                <div className="title">{so.serviceOffering.name}</div>
                                                <div className="subtitle">{so.serviceOffering.details}</div>
                                            </div>
                                            <div className="price">
                                                <CurrencyFormat value={(so.serviceOffering.price ? so.serviceOffering.price : 0)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} prefix={'$'} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="tableSection sectionIdentity">
                            <div className="header">IDENTITY</div>
                            <InputCell
                                placeholder={"Answer"}
                                required
                                id="answer"
                                label={acceptInfo.challengeQuestion}
                                inputRef={answerRef}
                            />
                        </div>

                        <div className="tableSection sectionSignature">
                            <p className="header">YOUR SIGNATURE</p>
                            <div className="cell signature">
                                <SignatureCanvas penColor='black'
                                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} ref={(ref) => { setSigPad(ref) }} />
                            </div>
                        </div>

                        <div className={`button large confirm ${submitting ? 'disabled' : ''}`}>
                            <div className="title" onClick={onSubmit}>Accept Service</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmServiceOfferings));