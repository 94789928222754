import { API } from 'aws-amplify';
import React from 'react';
import { toast } from "react-toastify";
import Modal from "./Modal";

function CopyText({ path, queryParams, title, ...props }) {
    const [open, setOpen] = React.useState(false);
    const [transcript, setTranscript] = React.useState("");
    const transcriptRef = React.createRef();

    const copyText = () => {
        transcriptRef.current.select();
        document.execCommand('copy')
        toast("Copied to clipboard!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
    }

    const openTranscript = async () => {
        setOpen(true);
        let configs = { responseType: 'text' }
        if (queryParams) {
            configs.queryStringParameters = queryParams;
        }
        let response = await API.get('Core', path, configs);
        if (typeof response == "object") {
            response = JSON.stringify(response);
        }
        setTranscript(response);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            button={props.children}
            content={(<div>
                <div className="content">
                    <div className="tableView">
                        <div className="tableSection sectionName">
                            <div className="sectionTitle">
                                <div className="title">Content</div>
                            </div>
                        </div>
                        <textarea style={{ width: '95%', margin: 'auto', display: 'block', height: '500px' }} ref={transcriptRef} value={transcript}>
                        </textarea>
                    </div>
                </div>
                <div className="actionBar">
                    <div className="actions">
                        <div onClick={handleClose.bind(this)} className="button dismiss medium">
                            <div className="title">Close</div>
                        </div>
                        {
                            document.queryCommandSupported('copy') ? (
                                <div onClick={copyText} className={`button confirm medium`}>
                                    <div className="title">Copy to Clipboard</div>
                                </div>
                            ) : null
                        }
                    </div>

                    <div className="separator"></div>
                </div>
            </div>)}
            title={title}
            size="large"
            handleOpen={openTranscript}
            handleClose={handleClose}
            open={open}
        />
    )
}

export default CopyText;