import React, { useEffect, useState } from "react";
import "react-day-picker/dist/style.css";
import { API } from "aws-amplify";
import { CircularProgress } from "@material-ui/core";

import { workflowTypes } from "@/utils/utils";

export default function ApplicantDetails({ conversation, reloadConversation,...props }) {

    const [activeApplicantModal, setActiveApplicantModal] = useState(null)
    const [demographicsText, setDemographicsText] = useState('Click to Enter Details');
    const [loading, setLoading] = useState(false);

    const showApplicantModal = () => {
        if (activeApplicantModal && conversation && activeApplicantModal.conversation && activeApplicantModal.conversation.id !== conversation.id) {
            activeApplicantModal.close();
            openApplicantModal();
        } 
        else if (!activeApplicantModal || activeApplicantModal.closed) openApplicantModal();
        else activeApplicantModal.focus();
    }

    const closeApplicantModal = () => {
        reloadConversation();
    }

    const openApplicantModal = () => {
        const url = `/applicant/${conversation.id}`;
        const windowApplicantModal = window.open(url, url, 'width=1000,height=900');
        windowApplicantModal.conversation = conversation;
        windowApplicantModal.addEventListener("beforeunload", closeApplicantModal)
        setActiveApplicantModal(windowApplicantModal);
    }

    useEffect(() => {
        const abortController = new AbortController();
        
        if(conversation?.id)
        {
            setLoading(true);
            Promise.all([API.get('Core', `/api/v1/conversation-records/fields?workflowType=${workflowTypes.APPLICANT}`), API.get('Core', `/api/v1/conversation-records/${conversation.id}/fields/values`)])
            .then(([fields, fieldsValues]) => {
                setDemographicsText(getValueFromFieldName("Full Name", fields, fieldsValues) || 'View Applicant Details');
            })
            .finally(() => setLoading(false));
        }
        return () => {
            abortController.abort();
        }
    }, [conversation]);

    const getValueFromFieldName = (fieldName, fields, fieldsValues) => {
        if (!fields || !fieldsValues) return null;

        let field = fields?.find(field => field.fieldName === fieldName);
        let value = fieldsValues?.find(value => value.conversationRecordsFieldId === field?.id)?.value;
        
        if (value) return value;

        return null;
    }

    return (
        <div className="sideBarSection">
            <div className="sectionTitle">Candidate Details</div>

            <div className={`attributeCell demographics`}>
                <div className="icon"></div>
                <div className="cellBody" onClick={() => showApplicantModal()}>
                    <div className="title">Demographics</div>
                    {loading ? <CircularProgress size={'0.8em'}/> : <div className="detail">{demographicsText}</div>}
                </div>
            </div>

            <div className="sectionSeparator">
                <div className="separator"></div>
            </div>
        </div>
    )
}
