import React from 'react';
import {connect} from "react-redux";
import {Editor, EditorState, ContentState} from "draft-js";
import {debounce} from "lodash";

import {fetchConversationNotes, saveConversationNotes} from "@/shared/conversation/actions";

const mapStateToProps = state => ({
    notesObj: state.conversation.activeNotes,
    ...state
})

const mapDispatchToProps = dispatch => ({
    getNotes : (conversationId) => dispatch(fetchConversationNotes(conversationId)),
    updateNotes: (conversationId, notes) => dispatch(saveConversationNotes(conversationId, notes))
})

function ConversationNotes(props) {
    const {activeConversation, notesObj} = props;
    const [editorState, setEditorState] = React.useState();

    let domEditor;
    const setDomEditorRef = ref => domEditor = ref;

    function containerClick(){
        domEditor.focus();
    }


    const updateEditorState = (newEditorState) => {
        setEditorState(newEditorState);
        debounced.cancel();
        debounced(newEditorState.getCurrentContent().getPlainText());
    }

    React.useEffect(() => {
        props.getNotes(activeConversation.id);
        setEditorState(EditorState.createEmpty())
    }, [activeConversation]);

    React.useEffect(() => {

        // If we have notes and the current content is empty - the set those notes
        if(notesObj && notesObj.notes && editorState && !editorState.getCurrentContent().hasText()){
            let contentState = ContentState.createFromText(notesObj.notes);
            setEditorState(EditorState.createWithContent(contentState));
        }

    }, [notesObj]);

    const debounced = React.useCallback(debounce((content) => {
        props.updateNotes(activeConversation.id, {notes:content});
    }, 1000), []);

    if(!editorState){
        return null;
    }
    return (
        <div className={`conversationNotes ${(editorState.getCurrentContent().hasText()) ? "hasInput" : ""}`} style={{paddingTop:'8px'}} onClick={containerClick}>
            <Editor
                style={{height:"100%"}}
                editorState={editorState}
                onChange={updateEditorState}
                placeholder={"Click and type to add private notes..."}
                spellCheck={true}
                stripPastedStyles={true}
                ref={setDomEditorRef}
            />
        </div>
    )

}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationNotes);