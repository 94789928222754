import React, { useState, useEffect, useRef } from 'react';

const Participant = ({ participant }) => {
    const [videoTrack, setVideoTrack] = useState(null);
    const [audioTrack, setAudioTrack] = useState(null);

    const videoRef = useRef();
    const audioRef = useRef();

    useEffect(() => {

        const trackPublished = publication => {
            if (publication.isSubscribed) {
                addTrack(publication.track);
            }
            publication.on('subscribed', function(track) {
                console.log('Subscribed to ' + publication.kind + ' track');
                addTrack(track);
            });
            publication.on('unsubscribed', removeTrack);
        }


        const addTrack = track => {
            if (track.kind === 'video') {
                setVideoTrack(track);
            } else {
                setAudioTrack(track);
            }
        };

        const removeTrack = track => {
            if(!track){
                return;
            }
            if (track.kind === 'video') {
                if(videoTrack){
                    videoTrack.detach();
                }
                setVideoTrack(null);
            } else {
                if(audioTrack){
                    audioTrack.detach();
                }
                setAudioTrack(null);
            }
        };

        participant.tracks.forEach(function(publication){
            trackPublished(publication);
        })

        const trackUnpublished = publication => {
            removeTrack(publication);
            console.log(publication.kind + ' track was unpublished.');
        };

        participant.on('trackPublished', trackPublished);
        participant.on('trackUnpublished', trackUnpublished);

        return () => {
            setVideoTrack(null);
            setAudioTrack(null);
            participant.removeAllListeners();
        };
    }, [participant]);

    useEffect(() => {
        if (videoTrack) {
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTrack]);

    useEffect(() => {
        if (audioTrack) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTrack]);

    return (
        <div className="participant fsExclude">
            <video style={{width:"100%", height:"100%", transform: 'scaleX(-1)'}} ref={videoRef} autoPlay={true} />
            <audio ref={audioRef} autoPlay={true} muted={false} />
        </div>
    );
};

export default Participant;