import React from "react"

import {
    AssetsPositivityFeedbackPositive, AssetsPositivityFeedbackNeutral,
    AssetsPositivityFeedbackNegative
} from "@/icons/Assets"

function SatisfactionPersonnelFeedbackCell(props) {
    const { feedbackSentiment } = props
    let sentimentClass = "";
    let icon = null;
    // eslint-disable-next-line default-case
    switch (feedbackSentiment) {
        case "POSITIVE":
            sentimentClass = "positive";
            icon = (<AssetsPositivityFeedbackPositive />);
            break;
        case "NEGATIVE":
            sentimentClass = "negative";
            icon = (<AssetsPositivityFeedbackNegative />);
            break;
        case "MIXED":
        case "NEUTRAL":
            sentimentClass = "neutral";
            icon = (<AssetsPositivityFeedbackNeutral />);
            break;
    }
    return (
        <div style={{ cursor: "pointer" }} className={`satisfactionPersonnelFeedbackCell ${sentimentClass}`}>
            <div className="feedbackText">{props.feedbackText}</div>
            {icon}
        </div>
    )
}

export default SatisfactionPersonnelFeedbackCell
