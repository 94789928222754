import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";

import ClientList from "./ClientList";
import Modal from "@/common/Modal";
import AddEditClient from "@/components/AddEditClient";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
})

function ClientIndex(props) {
    const [search, setSearch] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [size, setSize] = React.useState(50);
    const searchRef = React.createRef();
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [editClient, setEditClient] = React.useState(null);
    const [searchFocused, setSearchFocused] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState("");

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            setSearch(searchRef.current.value);
        }
    }

    const clearSearch = () => {
        setSearch("");
        setSearchInput("");
    }

    const onPageChange = (event, page) => {
        setPage(page);
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSuccess = (client) => {
        let searchStr = client.email ? client.email : client.phone;
        setSearch(searchStr);
        setSearchInput(searchStr)
        setOpen(false);
        setEditClient(null);
        setEditOpen(false);
    }

    const handleEditClient = (client) => {
        setEditClient(client);
        setEditOpen(true);
    }

    const handleEditClose = () => {
        setEditClient(null);
        setEditOpen(false);
    }

    const onSearchFocused = () => {
        setSearchFocused(true);
    }

    const onSearchBlurred = () => {
        setSearchFocused(false);
    }

    const onSearchChange = (e) => {
        setSearchInput(searchRef.current.value);
    }

    return (
        <div className="rootView clientsView contactsView" data-testid="contactsContainer">
            <div className="navBar">
                <div className="navTitle">
                    <div className="title">Contacts</div>
                </div>
                <div className="actions">
                    <div className={`searchInput action ${searchFocused ? "isFocused" : ""} ${(searchInput && searchInput.length > 0) ? "hasInput" : ""}`}>
                        <div className="icon" />
                        <input value={searchInput} onChange={onSearchChange} placeholder={"Search All Contacts"} onFocus={onSearchFocused} onBlur={onSearchBlurred} className="input" onKeyDown={keyPress} type="text" ref={searchRef} />
                        <div className="clear" onClick={clearSearch}></div>
                    </div>
                    <div className="">
                        <Modal
                            button={(<div className="add action" data-testid="addContactOpenButton">
                                <div className="icon" />
                                <div className="title">Add Contact</div>
                            </div>)}
                            content={(<AddEditClient
                                onCancel={handleClose}
                                onSuccess={onSuccess}
                            />)}
                            title="Add Contact"
                            size="small"
                            handleClose={handleClose}
                            handleOpen={handleOpen}
                            open={open}
                        />

                    </div>
                </div>
                <div className="separator"></div>
            </div>
            <ClientList
                page={page}
                size={size}
                search={search}
                handlePageChange={onPageChange}
                onEditClient={handleEditClient}
            />
            <Modal
                button={null}
                content={(<AddEditClient
                    existingClient={editClient}
                    onSuccess={onSuccess}
                    onCancel={handleEditClose}
                />)}
                title="Edit Contact"
                size="small"
                handleClose={handleEditClose}
                open={editOpen}
            />
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientIndex));