import React from 'react';
import { withRouter } from "react-router";
import { API } from "aws-amplify";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import _ from 'lodash';

import { SurveyQuestionChoice, SurveyQuestionScale, SurveyQuestionComment } from "../survey/SurveyQuestionCell"

function PublicSurvey(props) {
    const { t } = useTranslation();
    const { token } = props.match.params;
    const [survey, setSurvey] = React.useState(null);
    const [instance, setInstance] = React.useState(null);
    const [surveyName, setSurveyName] = React.useState(null);
    const [tenant, setTenant] = React.useState(null);
    const [answers, setAnswers] = React.useState([]);
    const [notFound, setNotFound] = React.useState(false);
    const [languageSetup, setLanguageSetup] = React.useState(false);
    const [language] = React.useState(i18next.language ? i18next.language.substring(0, 2) : null);

    React.useEffect(() => {
        document.body.classList.remove('prefersDarkTheme');
        API.get('Unauthorized', '/u/survey/' + token).then(response => {
            setSurvey(response.survey);
            setInstance(response.instance);
            setTenant(response.tenant);
            setSurveyName(response.surveyName);
            setupTranslations(response.survey);
        },
            error => {
                setNotFound(true);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setupTranslations = (survey) => {
        let languagesTranslations = null;

        try {
            languagesTranslations = JSON.parse(survey.languagesTranslations)[language];
        } catch (e) {
            console.warn('Problem to parse languages translations');
        }

        survey.header = language && language !== 'en' && languagesTranslations && languagesTranslations.header ? languagesTranslations.header : survey.header;
        survey.footer = language && language !== 'en' && languagesTranslations && languagesTranslations.footer ? languagesTranslations.footer : survey.footer;

        setLanguageSetup(true);
    }

    const onAnswer = (questionId, answer) => {
        let idx = _.findIndex(answers, function (a) { return a.questionId === questionId; });
        answer.questionId = questionId;
        if (idx !== -1) {
            answers[idx] = answer;
        }
        else {
            answers.push(answer);
        }
        setAnswers(Object.assign([], answers));
        onUpdate();
    }

    const onUpdate = () => {
        API.post('Unauthorized', '/u/survey/' + token + '/update', { body: answers })
            .then(response => {
                setInstance(response.instance);
            },
                error => {

                })
    }

    const onComplete = () => {
        API.post('Unauthorized', '/u/survey/' + token + '/complete', { body: answers })
            .then(response => {
                setInstance(response.instance);
            },
                error => {

                })
    }

    if (notFound) {
        return (
            <div className="publicSurveyForm surveyNotFound">
                <div className="statusMessage">
                    <h1 className="messageTitle">{t('Survey Not Found')}</h1>
                    <p className="messageBody">{t('This survey is no longer available.')}</p>
                </div>
            </div>
        )
    }

    if (!survey || !tenant || !instance || !surveyName) {
        return (
            <div className="publicSurveyForm surveyNotFound">
                <div className="statusMessage">
                    <h1 className="messageTitle">{t('Loading...')}</h1>
                </div>
            </div>
        )
    }

    if (instance && instance.status === "COMPLETE") {
        return (
            <div className="publicSurveyForm surveyNotFound">
                <div className="statusMessage">
                    <h1 className="messageTitle">{t('Thank you!')}</h1>
                    <p className="messageBody">{t('Thank you for completing the survey.')}</p>
                </div>
            </div>
        )
    }

    if (languageSetup) {
        return (
            <div className="publicSurveyForm">
                <div className="surveyDetails">
                    {
                        tenant.logoFilename ? (
                            <img style={{ maxWidth: '210px', maxHeight: '100px' }} alt="logo" src={"/logos/" + tenant.logoFilename} />
                        ) : (<div className="surveyAgencyName">{tenant.name}</div>)
                    }
                    <div className="surveyTitle">{surveyName} - {survey.questions.length} {t('questions')}</div>
                </div>

                {
                    survey.header ? (
                        <div className="surveyHeader">
                            <p className="headerText">{survey.header}</p>
                        </div>
                    ) : null
                }
                {
                    _.map(_.sortBy(survey.questions, function (qu) {
                        return qu.questionOrder;
                    }), function (question) {
                        let answer = _.find(answers, function (a) {
                            return question.id === a.questionId
                        });
                        // eslint-disable-next-line default-case
                        switch (question.type) {
                            case "COMMENT":
                                return <SurveyQuestionComment question={question} answer={answer} onAnswer={onAnswer.bind(this, question.id)} language={language} />
                            case "CHOICE":
                                return <SurveyQuestionChoice question={question} answer={answer} onAnswer={onAnswer.bind(this, question.id)} language={language} />
                            case "SCALE":
                                return <SurveyQuestionScale question={question} answer={answer} onAnswer={onAnswer.bind(this, question.id)} language={language} />
                        }
                    })
                }

                <div className="surveyFooter">
                    {
                        survey.footer ? (
                            <p className="footerText">{survey.footer}</p>
                        ) : null
                    }

                    <div onClick={onComplete} className="button large confirm submitSurvey">
                        <div className="buttonTitle">{t('Submit Survey')}</div>
                    </div>
                </div>
            </div>
        );
    }
    return null;
}

export default withRouter(PublicSurvey);