import React from 'react';
import Select from "react-select";

import InputCell from "@/common/form/InputCell";
import ConfirmModal from "@/common/ConfirmModal";

export default function AddEditProvider(props){
    var { onDelete, onCancel, onSuccess, existingProvider, categoryList} = props;

    const nameRef = React.createRef();
    const descriptionRef = React.createRef();
    const handoffUrlRef = React.createRef();
    const successMessageRef = React.createRef();
    const failureMessageRef = React.createRef();
    const informationRef = React.createRef();
    const newCategoryNameRef = React.createRef();
    const newCategoryDescriptionRef = React.createRef();


    const [handoffType, setHandoffType] = React.useState("");
    const [handoffTypes, setHandoffTypes] = React.useState([]);

    const [category, setCategory] = React.useState("");
    const [categories, setCategories] = React.useState([]);

    React.useEffect(() => {
        setHandoffTypes([{id: 0, name: "API"}, {id: 1, name: "INFORMATIONAL"}]);
        if (existingProvider) {
            setHandoffType({id: -1, name: existingProvider.handoff.type});
        }
        let pcs = [];
        if (categoryList) {
            categoryList.forEach(function (pc) {
                pcs.push({id: pc.id, name: pc.name});
            });
            pcs.push({id:-1, name:"New Category"});
            console.log(pcs);
            setCategories(pcs);
        }
        if(existingProvider) {
            setCategory({id:existingProvider.category.id, name: existingProvider.category.name});
        }
    }, []);

    const deleteProvider = () => {
        onDelete(existingProvider.id);
    }

    const saveProvider = () => {

        let cat = null;
        if(category) {
            cat = categoryList.find(function (c) {
                return c.id == category.id;
            })
        }
        let catId = cat == null ? null : cat.id
        let catName = cat == null ? newCategoryNameRef.current.value : cat.name
        let catDescription = cat == null ? newCategoryDescriptionRef.current.value : cat.description
        if(existingProvider){
            onSuccess({
                id: existingProvider.id,
                name: nameRef.current.value,
                description: descriptionRef.current.value,
                handoff: {
                    id: existingProvider.handoff.id,
                    type: handoffType.name,
                    url: handoffUrlRef.current ? handoffUrlRef.current.value : null,
                    successMessage: successMessageRef.current ? successMessageRef.current.value : null,
                    failureMessage: failureMessageRef.current ? failureMessageRef.current.value : null,
                    information: informationRef.current ? informationRef.current.value : null
                },
                category: {
                    id: catId,
                    name: catName,
                    description: catDescription
                }
            })
        }
        else{
            onSuccess({
                name: nameRef.current.value,
                description: descriptionRef.current.value,
                handoff: {
                    type: handoffType.name,
                    url: handoffUrlRef.current ? handoffUrlRef.current.value : null,
                    successMessage: successMessageRef.current ? successMessageRef.current.value : null,
                    failureMessage: failureMessageRef.current ? failureMessageRef.current.value : null,
                    information: informationRef.current ? informationRef.current.value : null
                },
                category: {
                    name: catName,
                    description: catDescription
                }
            })
        }
    }

    const onHandoffTypeChange = (obj, action) => {
        setHandoffType(obj);
    }

    const onCategoryChange = (obj, action) => {
        setCategory(obj);
    }
    console.log(existingProvider);
    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"Provider Name"}
                            defaultValue={(existingProvider ? existingProvider.name : "")}
                            required
                            id="providerName"
                            label="Title Name"
                            inputRef={nameRef}
                        />
                        <InputCell
                            placeholder={"Description"}
                            required
                            defaultValue={(existingProvider ? existingProvider.description : "")}
                            id="providerDetails"
                            label="Description"
                            name="description"
                            inputRef={descriptionRef}
                        />
                    </div>
                    <div className="tableSection sectionProviderCategory">
                        <div className="sectionTitle">Provider Category</div>
                        <Select className="cell selectCell" classNamePrefix="selectCell"
                                onChange={onCategoryChange}
                                defaultValue={categories}
                                value={category}
                                options={categories}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                        />
                        { category && category.id < 0 ?
                            <div>
                                <InputCell
                                    placeholder={"Name"}
                                    required
                                    defaultValue={""}
                                    id="categoryName"
                                    label="Category Name"
                                    name="categoryName"
                                    inputRef={newCategoryNameRef}
                                />
                                <InputCell
                                    placeholder={"Description"}
                                    required
                                    defaultValue={""}
                                    id="categoryDescription"
                                    label="Category Description"
                                    name="categoryDescription"
                                    inputRef={newCategoryDescriptionRef}
                                />
                            </div>
                            : null
                        }
                    </div>
                    <div className="tableSection sectionHandoffType">
                        <div className="sectionTitle">Handoff Type</div>
                        <Select className="cell selectCell" classNamePrefix="selectCell"
                                onChange={onHandoffTypeChange}
                                defaultValue={handoffTypes}
                                value={handoffType}
                                options={handoffTypes}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                        />

                        { handoffType != null && handoffType.name == "API" ?
                            <div>
                                <InputCell
                                    placeholder={"Handoff URL"}
                                    required
                                    defaultValue={(existingProvider ? existingProvider.handoff.url : "")}
                                    id="providerHandoffUrl"
                                    label="Handoff Url"
                                    name="handoffUrl"
                                    inputRef={handoffUrlRef}
                                />
                                <InputCell
                                    placeholder={"Message"}
                                    required
                                    defaultValue={(existingProvider ? existingProvider.handoff.successMessage : "")}
                                    id="providerHandoffSuccessMessage"
                                    label="Success Message"
                                    name="successMessage"
                                    inputRef={successMessageRef}
                                />
                                <InputCell
                                placeholder={"Message"}
                                required
                                defaultValue={(existingProvider ? existingProvider.handoff.failureMessage : "")}
                                id="providerHandoffFailureMessage"
                                label="Failure Message"
                                name="failureMessage"
                                inputRef={failureMessageRef}
                                />
                            </div>
                            : null
                        }
                        { handoffType != null && handoffType.name == "INFORMATIONAL" ?
                            <div>
                                <InputCell
                                    placeholder={"message"}
                                    required
                                    defaultValue={(existingProvider ? existingProvider.handoff.information : "")}
                                    id="informationalMessage"
                                    label="Informational Message"
                                    name="informationalMessage"
                                    inputRef={informationRef}
                                />
                            </div>
                            : null
                        }

                    </div>
                </div>
            </div>
            <div className="actionBar">
                {existingProvider ?
                    <div className="actions left">
                        <ConfirmModal
                            title={"Delete"}
                            text={"Are you sure you want to delete this provider?"}
                            onConfirm={deleteProvider.bind(this)}
                            confirmTitle={"Delete Provider"}
                        >
                            <div className="button destructive medium">
                                <div className="title">Delete</div>
                            </div>
                        </ConfirmModal>
                    </div>: null
                }
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveProvider} className={`button confirm medium`}>
                        <div className="title">Done</div>
                    </div>
                 </div>
                <div className="separator"></div>
            </div>
        </div>

    )
}