import React from 'react';
import Modal from "../../common/Modal"
import { taskStatuses } from "../../utils/utils";
import Select from "react-select";
import { ContentState, Editor, EditorState } from "draft-js";
import SurveyResults from "../survey/SurveyResults";
import moment from '../../common/momentConfig';

import _ from "lodash";
import LocalTimeStamp from "../../common/LocalTimeStamp";
import { Button } from "../../components/Buttons";
import TeamList from "../settings/team/TeamList";

function TaskDetail({ task, onChange, onConversationStarted, onViewSourceConversation, viewOnly, containerDivClass, taskActionTypes, user, ...props }) {
    const [open, setOpen] = React.useState(false);
    const [currentTask, setCurrentTask] = React.useState(task);
    const [taskStatus, setTaskStatus] = React.useState(task.status);
    const [editorState, setEditorState] = React.useState();
    const [newActionEditorState, setNewActionEditorState] = React.useState();
    const [notesEditorState, setNotesEditorState] = React.useState();
    const [newAction, setNewAction] = React.useState(false);
    const [newActionType, setNewActionType] = React.useState();
    const [page, setPage] = React.useState(1);
    const [size] = React.useState(5);
    const [selectedUser, setSelectedUser] = React.useState();
    const [searchFocused, setSearchFocused] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState("");
    const searchRef = React.createRef();
    const [search, setSearch] = React.useState("");
    const [defaultWorkflow, setDefaultWorkflow] = React.useState(null);

    let domEditor;
    const setDomEditorRef = ref => domEditor = ref;
    let newActionDomEditor;
    const setNewActionDomEditorRef = ref => newActionDomEditor = ref;
    React.useEffect(() => {
        if (open) {
            let initEditorState = ContentState.createFromText(currentTask.comments ? currentTask.comments : "");
            setEditorState(EditorState.createWithContent(initEditorState));
            let initNotesEditorState = ContentState.createFromText(currentTask.notes ? currentTask.notes : "");
            setNotesEditorState(EditorState.createWithContent(initNotesEditorState));
            let initActionNotesEditorState = ContentState.createFromText("");
            setNewActionEditorState(EditorState.createWithContent(initActionNotesEditorState));
        }
        setCurrentTask(task);
        setupDefaultWorkflow(task);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const updateTask = () => {
        currentTask.status = taskStatus;
        if (selectedUser != null) {
            currentTask.assignee = selectedUser;
        }
        if (currentTask.status === "CLOSED") {
            currentTask.resolutionDate = new Date();
        } else {
            currentTask.resolutionDate = null;
        }
        currentTask.comments = editorState.getCurrentContent().getPlainText();
        onChange(currentTask);
        setOpen(false);

    }

    const startConversation = () => onConversationStarted(defaultWorkflow, currentTask.contact.phone, currentTask.sourceEncounter);

    const viewSourceConversation = () => {
        if (props.props.workflow && currentTask.sourceConversation) {
            var workflow = _.find(props.props.workflow.allWorkflows, function (w) {
                var state = _.find(w.states, function (s) {
                    return s.id === currentTask.sourceConversation.workflowState.id;
                })
                if (state) {
                    return true;
                } else {
                    return false;
                }
            })
            onViewSourceConversation(workflow, currentTask.sourceConversation);
        }
    }

    const onPageChange = (event, page) => {
        setPage(page);
    }

    const isSelectedUser = (e) => {
        return (!_.isNil(selectedUser) && e.id === selectedUser.id);
    }

    const onUserSelect = (e) => {
        if (isSelectedUser(e)) {
            setSelectedUser(null);
        }
        else {
            setSelectedUser(e);
        }
    }

    const onSearchFocused = () => {
        setSearchFocused(true);
    }

    const onSearchBlurred = () => {
        setSearchFocused(false);
    }

    const onSearchChange = (e) => {
        setSearchInput(searchRef.current.value);
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            if (searchRef.current.value !== search) {
                setPage(1);
            }
            setSearch(searchRef.current.value);
        }
    }

    const clearSearch = () => {
        setSearch("");
        setSearchInput("");
    }

    const closeModal = () => {
        setOpen(false);
    }

    const setStatus = (obj) => {
        if (obj != null) {
            setTaskStatus(obj.value);
        }
    }

    const updateEditorState = (newEditorState) => {
        setEditorState(newEditorState);
    }

    const updateNewActionEditorState = (newEditorState) => {
        setNewActionEditorState(newEditorState);
    }

    const canSubmitTaskAction = () => {
        if (newActionType) {
            return true;
        } else {
            return false;
        }
    }
    const canSubmit = () => {
        return true;
    }

    function containerClick() {
        domEditor.focus();
    }

    function newActionContainerClick() {
        newActionDomEditor.focus();
    }

    const addAction = () => {
        setNewAction(true);
    }

    const saveNewAction = () => {
        let prevActions = currentTask.actions;
        if (prevActions == null) {
            prevActions = [];
        }
        let newAction = {
            user: user.aboutMe.user,
            actionType: newActionType,
            notes: newActionEditorState.getCurrentContent().getPlainText(),
            takenAt: new Date()
        }
        prevActions.push(newAction);
        currentTask.actions = prevActions;
        onChange(currentTask);
        setNewAction(false);
        setNewAction(false);
        setNewActionType(null);
        let initActionNotesEditorState = ContentState.createFromText("");
        setNewActionEditorState(EditorState.createWithContent(initActionNotesEditorState));
    }

    const cancelNewAction = () => {
        setNewAction(false);
        setNewActionType(null);
        let initActionNotesEditorState = ContentState.createFromText("");
        setNewActionEditorState(EditorState.createWithContent(initActionNotesEditorState));
    }

    const actionTypeOptions = () => {
        let ato = _.map(taskActionTypes, function (t) {
            return { value: t, label: t.actionType };
        });
        return ato;
    }
    const changeNewActionType = (obj, action) => {
        setNewActionType(obj.value);
    }

    const setupDefaultWorkflow = (task) => {
        let workflow = null;
        if (props.props.workflow) {
            // Search for Default communication groups/workflow configured on task type
            if (task && task.type && task.type.workflow)
                workflow = _.find(props.props.workflow.allWorkflows, (w) => w.id === task.type.workflow.id)

            //Otherwise, search for the "Main"
            if (!workflow)
                workflow = _.find(props.props.workflow.allWorkflows, (w) => w.name === 'Main')
        }
        setDefaultWorkflow(workflow);
    }

    const canStartConversation = () => !!defaultWorkflow && !!currentTask && !!currentTask.contact;

    let content = open ? (
        <div className={"modalDialog taskView"}>
            <div className="mainView">
                <div className="titleBar">
                    <div className="titleBarTitle">{currentTask.type.name}</div>
                </div>
                <div className="tableView">
                    <div className="tableSection sectionAssignee">
                        <div className="tableCell taskAssigneeCell">
                            <div className="cellBody taskAssigneeDetails">
                                <div className="cellTitle taskAssigneeName">{currentTask.assignee ? currentTask.assignee.readableName : ""}</div>
                                <div className="cellSubtitle">
                                    <div className="taskAssigneeDueDate">due by <LocalTimeStamp utcTimestamp={currentTask.dueDate} format={"MM/DD/YYYY"} /></div>
                                    <div className="taskAssigneeDeadline">{moment.utc().to(currentTask.dueDate)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="tableSection sectionAssignee">
                            <div className="sectionTitle">Change Assignee</div>
                            <div className={`searchInput tableSearch ${searchFocused ? "isFocused" : ""} ${(searchInput.length > 0) ? "hasInput" : ""}`}>
                                <div className="icon" />
                                <input value={searchInput} onChange={onSearchChange} placeholder={"Search Assignees"} onFocus={onSearchFocused} onBlur={onSearchBlurred} className="input" onKeyDown={keyPress} type="text" ref={searchRef} />
                                <div className="clear" onClick={clearSearch}></div>
                            </div>

                            <TeamList
                                page={page}
                                size={size}
                                search={search}
                                handlePageChange={onPageChange}
                                onEditClient={null}
                                selectedUser={[selectedUser]}
                                onSelect={onUserSelect}
                                multipleSelect={false}
                                withoutPhone={true}
                            />
                        </div>

                        <div>
                            <div className="tableCell titledSelectCell">
                                <div className="title">Status</div>
                                <Select className="selectCell" classNamePrefix="selectCell"
                                    onChange={setStatus.bind(this)}
                                    placeholder="Status"
                                    value={taskStatus !== "" ? { label: taskStatus, value: taskStatus } : ""}
                                    options={taskStatuses}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="tableSection sectionNotes">
                        <span className="sectionTitle">Notes</span>
                        <div className="taskNotes">
                            {currentTask.notes ? currentTask.notes : ""}
                            {/*<Editor*/}
                            {/*    readOnly={true}*/}
                            {/*    style={{height:"100%"}}*/}
                            {/*    editorState={notesEditorState}*/}
                            {/*    stripPastedStyles={true}*/}
                            {/*    ref={setNotesDomEditorRef}*/}
                            {/*/>*/}
                        </div>
                    </div>

                    {((currentTask.sourceConversation && !viewOnly) || currentTask.sourceSurvey) ? (
                        <div className="tableSection sectionConversation">
                            <div className="sectionTitle">{currentTask.sourceSurvey ? "Survey" : (currentTask.sourceConversation && !viewOnly ? "Conversation" : "")}</div>

                            {currentTask.sourceSurvey ? (
                                <div className="tableCell taskConversationCell">
                                    <div className="cellBody taskConversationDetails">
                                        <div className="cellTitle taskConversationCallerName">{currentTask.sourceSurvey.surveyName}</div>
                                        <div className="cellSubtitle taskConversationEncounterName">{currentTask.sourceSurvey.contact.readableName}</div>
                                    </div>

                                    <SurveyResults surveyInstanceId={currentTask.sourceSurvey.id}>
                                        <Button
                                            title="View"
                                            size="medium"
                                            addClass="taskConversationView"
                                        />
                                    </SurveyResults>
                                </div>
                            ) : null}
                            {currentTask.sourceConversation && !viewOnly ? (
                                <div className="tableCell taskConversationCell">
                                    <div className="cellBody taskConversationDetails">
                                        <div className="cellTitle taskConversationCallerName">{currentTask.sourceConversation.initiator != null ? currentTask.sourceConversation.initiator.readableName : ""}</div>
                                        <div className="cellSubtitle taskConversationEncounterName">{currentTask.sourceConversation != null && currentTask.sourceConversation.encounter != null ? currentTask.sourceConversation.encounter.type : ""}</div>
                                    </div>
                                    <Button
                                        onClick={viewSourceConversation.bind(this)}
                                        title="View"
                                        size="medium"
                                        addClass="taskConversationView"
                                    />
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="detailView" style={{ minHeight: '500px' }}>
                <div className="tableView">
                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Task Actions</div>
                        </div>
                        {
                            _.map(currentTask.actions, function (action, i) {
                                return (
                                    <div key={action.id} className="taskActionCell" style={{ height: 'auto' }}>
                                        <div className="actionType">{action.actionType.actionType}</div>
                                        <div className="actionDetails">
                                            <div className="actionCopy copyEmphasized">
                                                <LocalTimeStamp utcTimestamp={action.takenAt} format={"MM/DD/YYYY"} utc={false} />
                                            </div>
                                            <div className="actionCopy">at</div>
                                            <div className="actionCopy copyEmphasized">
                                                <LocalTimeStamp utcTimestamp={action.takenAt} format={"h:mm a"} utc={false} />
                                            </div>
                                            <div className="actionCopy">by</div>
                                            <div className="actionCopy copyEmphasized">{action.user.email}</div>
                                        </div>
                                        <div className="actionNotes">{action.notes}</div>
                                    </div>
                                )
                            })
                        }
                        {newAction ?
                            <div className="addTaskAction" style={{ width: '100%' }}>
                                <div className="taskActionTitleBar">
                                    <div className="titleBarTitle">Add Action</div>
                                </div>

                                <div key="tempAction" className="tableCell titledSelectCell" style={{ overflow: 'visible' }}>
                                    <div className="title">Action</div>
                                    <Select className="selectCell" classNamePrefix="selectCell"
                                        onChange={changeNewActionType.bind(this)}
                                        value={newActionType ? { label: newActionType.actionType, value: newActionType } : ''}
                                        options={actionTypeOptions()}
                                    />
                                </div>

                                <div className="tableCell editorCell multilineCell" onClick={newActionContainerClick}>
                                    <div className="title">Notes</div>
                                    <Editor
                                        readOnly={false}
                                        style={{ height: "100%" }}
                                        editorState={newActionEditorState}
                                        onChange={updateNewActionEditorState}
                                        spellCheck={true}
                                        stripPastedStyles={true}
                                        ref={setNewActionDomEditorRef}
                                        placeholder={""}
                                    />
                                </div>

                                <div className="taskActionActionsBar">
                                    <div onClick={cancelNewAction} className="button action medium">
                                        <div className="title">Cancel</div>
                                    </div>

                                    <div onClick={canSubmitTaskAction() ? saveNewAction : null} className="button tint medium">
                                        <div className="title">Done</div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        {!newAction ?
                            <div onClick={addAction} className="button tint large addTaskActionButton">
                                <div className="title">Add Action</div>
                            </div> : null
                        }
                    </div>

                    <div className="tableSection sectionName" onClick={containerClick}>
                        <span className="sectionTitle">Comments</span>

                        <div className="tableCell editorCell">
                            <Editor
                                readOnly={false}
                                style={{ height: "100%" }}
                                editorState={editorState}
                                onChange={updateEditorState}
                                spellCheck={true}
                                stripPastedStyles={true}
                                ref={setDomEditorRef}
                                placeholder={"Add comments here..."}
                            />
                        </div>
                    </div>
                </div>

                <div className="actionBar">
                    {/*{currentTask.sourceConversation ?*/}
                    {/*<div onClick={viewSourceConversation} className="button confirm medium">*/}
                    {/*    <div className="title">View Source Conversation</div>*/}
                    {/*</div> : null}*/}
                    {!viewOnly ?
                        <div className="actions left">
                            <div onClick={canStartConversation() ? startConversation : null}
                                className={`button confirm medium ${canStartConversation() ? '' : 'disabled'}`}
                                title={canStartConversation() ? null : `You must configure a workflow on this task type to start conversations`}
                            >
                                <div className="title">Start Conversation</div>
                            </div>
                        </div> : null}


                    <div className="actions right">
                        <div onClick={closeModal} className="button dismiss medium">
                            <div className="title">Cancel</div>
                        </div>

                        <div onClick={canSubmit() ? updateTask : null} className={`button confirm medium ${canSubmit() ? '' : 'disabled'}`}>
                            <div className="title">Submit</div>
                        </div>
                    </div>

                    <div className="separator"></div>
                </div>
            </div>
        </div>
    ) : null;

    return (<Modal
        button={props.children}
        containerDivClass={containerDivClass ? containerDivClass : "taskCellContainer"}
        containerDivButtonClass="taskCellContent"
        content={content}
        title={"Manage Task"}
        titleBar="hidden"
        size="auto"
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        open={open}
        dialogClassName="modalDialog"
    />)
}

export default (TaskDetail);