import { API } from 'aws-amplify';

export const GET_SYSTEM_SCHEMA = 'GET_SYSTEM_SCHEMA';
export const requestSystemSchema = () => dispatch => {
    dispatch({
        type : GET_SYSTEM_SCHEMA
    });
}

export const GET_SYSTEM_SCHEMA_SUCCESS = 'GET_SYSTEM_SCHEMA_SUCCESS';
export const requestSystemSchemaSuccess = (json, entity) => dispatch => {
    dispatch({
        type : GET_SYSTEM_SCHEMA_SUCCESS,
        payload: json,
        entity: entity,
        receivedAt: Date.now()
    });
}

export const GET_SYSTEM_SCHEMA_FAILURE = 'GET_SYSTEM_SCHEMA_FAILURE';
export const requestSystemSchemaFailure = () => dispatch => {
    dispatch({
        type : GET_SYSTEM_SCHEMA_FAILURE
    });
}

export const GET_CUSTOM_SCHEMA = 'GET_CUSTOM_SCHEMA';
export const requestCustomSchema = () => dispatch => {
    dispatch({
        type : GET_CUSTOM_SCHEMA
    });
}

export const GET_CUSTOM_SCHEMA_SUCCESS = 'GET_CUSTOM_SCHEMA_SUCCESS';
export const requestCustomSchemaSuccess = (json, entity) => dispatch => {
    dispatch({
        type : GET_CUSTOM_SCHEMA_SUCCESS,
        payload: json,
        entity: entity,
        receivedAt: Date.now()
    });
}

export const GET_CUSTOM_SCHEMA_FAILURE = 'GET_CUSTOM_SCHEMA_FAILURE';
export const requestCustomSchemaFailure = () => dispatch => {
    dispatch({
        type : GET_CUSTOM_SCHEMA_FAILURE
    });
}

export const CREATE_CUSTOM_FIELD = 'CREATE_CUSTOM_FIELD';
export const createCustomField = () => dispatch => {
    dispatch({
        type : CREATE_CUSTOM_FIELD
    });
}

export const CREATE_CUSTOM_FIELD_SUCCESS = 'CREATE_CUSTOM_FIELD_SUCCESS';
export const createCustomFieldSuccess = (json, entity) => dispatch => {
    dispatch({
        type : CREATE_CUSTOM_FIELD_SUCCESS,
        payload: json,
        entity: entity
    });
}

export const DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD';
export const deleteCustomField = () => dispatch => {
    dispatch({
        type : DELETE_CUSTOM_FIELD
    });
}

export const DELETE_CUSTOM_FIELD_SUCCESS = 'DELETE_CUSTOM_FIELD_SUCCESS';
export const deleteCustomFieldSuccess = (json, entity) => dispatch => {
    dispatch({
        type : DELETE_CUSTOM_FIELD_SUCCESS,
        payload: json,
        entity: entity
    });
}

export function fetchSystemSchema(entity) {

    return function(dispatch) {

        dispatch(requestSystemSchema())

        return API.get('Core', '/api/v1/schema/' + entity + '/system')
            .then(response => {
                    dispatch(requestSystemSchemaSuccess(response, entity))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchCustomSchema(entity) {

    return function(dispatch) {

        dispatch(requestCustomSchema())

        return API.get('Core', '/api/v1/schema/' + entity + '/custom' )
            .then(response => {
                    dispatch(requestCustomSchemaSuccess(response, entity))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function createCustomFieldAndRefresh(entity, field) {

    return function(dispatch) {

        dispatch(createCustomField())

        return API.post('Core', '/api/v1/schema/' + entity + '/custom', {body:field} )
            .then(response => {
                    dispatch(fetchCustomSchema(entity));
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function deleteCustomFieldAndRefresh(entity, fieldId) {

    return function(dispatch) {

        dispatch(deleteCustomField())

        return API.del('Core', '/api/v1/schema/' + entity + '/custom/' + fieldId)
            .then(response => {
                    dispatch(fetchCustomSchema(entity));
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}