import React from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import _ from "lodash";

import InputCell from "@/common/form/InputCell";
import Modal from "@/common/Modal";
import moment from '@/common/momentConfig';
import { SortButton, FilterButton, Button, PaginationExpanderButton } from "@/components/Buttons"
import {
    AssetGeneralDisclosureIndicatorSmall,
    AssetButtonSettings, AssetPullDownMenuSortDesc, AssetPullDownMenuSortAsc
} from "@/icons/Assets"
import { fetchDigestSettings } from "@/shared/satisfaction/actions";

import SatisfactionPersonnelCell from "./SatisfactionPersonnelCell"
import AddEditDigestSettings from "./AddEditDigestSettings";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getDigestSettings: () => dispatch(fetchDigestSettings())
})

function SatisfactionIndex(props) {

    const DATE_FORMAT = "YYYY-MM-DD";
    let startDateMoment = moment().utc().subtract(30, 'day');
    let endDateMoment = moment().utc();
    let defaultStartDateFormatted = startDateMoment.format(DATE_FORMAT);
    let defaultEndDateFormatted = endDateMoment.format(DATE_FORMAT);

    const SORT_ASC = "ASC";
    const SORT_DESC = "DESC";
    const DEFAULT_SORT = "NAME";
    const [personnel, setPersonnel] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [hasMore, setHasMore] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [sort, setSort] = React.useState(DEFAULT_SORT);
    const [filters, setFilters] = React.useState([]);
    const [sortDirection, setSortDirection] = React.useState(SORT_ASC);
    const [startDate, setStartDate] = React.useState(startDateMoment);
    const [endDate, setEndDate] = React.useState(endDateMoment);
    const [startDateInput, setStartDateInput] = React.useState(defaultStartDateFormatted);
    const [endDateInput, setEndDateInput] = React.useState(defaultEndDateFormatted);
    const [search, setSearch] = React.useState("");
    const [searchFocused, setSearchFocused] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState("");
    const [shiftNameFilter, setShiftNameFilter] = React.useState("");
    const [shiftFilterFocused, setShiftFilterFocused] = React.useState(false);
    const [shiftNameInput, setShiftNameInput] = React.useState("");

    const sortRef = React.useRef();
    const filterRef = React.useRef();
    const calendarRef = React.useRef();
    const searchRef = React.createRef();
    const shifNameFilterRef = React.createRef();

    const SORTS = [
        {
            key: "NAME",
            title: "Name"
        },
        {
            key: "CPSS",
            title: "CPSS"
        }
    ];

    const FILTERS = [
        {
            key: "CALLTAKER",
            title: "Call Takers"
        },
        {
            key: "INVESTIGATOR",
            title: "Investigators"
        },
        {
            key: "RESPONDER",
            title: "Responders"
        },
        {
            key: "HAS_EMAIL",
            title: "Has Email"
        }
    ];

    React.useEffect(() => {
        props.getDigestSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        loadPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    // If sort changes, set page to 0
    React.useEffect(() => {
        loadPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, sortDirection, startDate, endDate, search, filters, shiftNameFilter]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const toggleFilter = (filter) => {
        if (_.includes(filters, filter)) {
            _.remove(filters, function (remFilter) { return remFilter === filter; });
            setFilters(Object.assign([], filters));
        }
        else {
            filters.push(filter);
            setFilters(Object.assign([], filters));
        }
        filterRef.current.close();
    }

    const onSortClick = (chosenSort) => {
        // Selecting a sort - default to desc
        if (sort == null || sort !== chosenSort) {
            setSort(chosenSort);
            setSortDirection(SORT_DESC);
        }
        // Clicked same sort again
        else if (sort === chosenSort && sortDirection === SORT_DESC) {
            setSortDirection(SORT_ASC);
        } else if (sort === chosenSort && sortDirection === SORT_ASC) {
            setSort(DEFAULT_SORT);
            setSortDirection(SORT_DESC);
        }
        sortRef.current.close();
    }
    const handleAddSuccess = (response, close) => {
        props.getDigestSettings();
        if (close) {
            handleClose();
        }
    }

    const loadMore = () => {
        setPage(page + 1);
    }

    const updateDates = () => {
        setStartDate(moment(startDateInput, DATE_FORMAT));
        setEndDate(moment(endDateInput, DATE_FORMAT));
        calendarRef.current.close();
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            setSearch(searchRef.current.value);
        }
    }

    const keyPressShiftName = (e) => {
        if (e.keyCode === 13) {
            setShiftNameFilter(shifNameFilterRef.current.value);
        }
    }

    const loadPage = (pageNum) => {
        API.get("Core", "/api/v1/survey-reports/cpss", {
            queryStringParameters: {
                page: pageNum,
                sort: sort,
                sortDirection: sortDirection,
                startDate: startDate.format(DATE_FORMAT),
                endDate: endDate.format(DATE_FORMAT),
                search: search,
                shiftName: shiftNameFilter,
                filters: filters
            }
        }).then(response => {
            if (pageNum === 0) {
                setPersonnel(response.content);
            }
            else {
                setPersonnel(personnel.concat(response.content));
            }
            setPage(response.number);
            setHasMore(!response.last);
        }, err => {
            toast("Could not load satisfaction details: " + err.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
        })
    }

    let selectedSort = _.find(SORTS, function (s) { return s.key === sort; });
    return (
        <div className="rootView satisfactionView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="viewName">Personnel Satisfaction</div>
                    <Popup closeOnDocumentClick ref={calendarRef} position={"bottom right"} trigger={(
                        <div className="viewSubtitle satisfactionPeriod">
                            <div className="dateValue">{startDate.format("MMMM Do YYYY")} - {endDate.format("MMMM Do YYYY")}</div>
                            <div className="disclosureIndicator"><AssetGeneralDisclosureIndicatorSmall /></div>
                        </div>
                    )}>
                        <div className="pullDownMenu dateRange">
                            <InputCell
                                label="Start Date"
                                type={"date"}
                                wrapperClasses={"dateRangeInput"}
                                onChange={(e) => setStartDateInput(e.target.value)}
                                defaultValue={startDateInput}
                                value={startDateInput}
                            />
                            <InputCell
                                label="End Date"
                                wrapperClasses={"dateRangeInput"}
                                type={"date"}
                                onChange={(e) => setEndDateInput(e.target.value)}
                                defaultValue={endDateInput}
                                value={endDateInput}
                            />
                            <div onClick={updateDates} className={`button confirm small`}>
                                <div className="title">Done</div>
                            </div>
                        </div>
                    </Popup>

                </div>

                <div className="navActions">
                    <div className={`searchInput action ${searchFocused ? "isFocused" : ""} ${(searchInput.length > 0) ? "hasInput" : ""}`} style={{ marginRight: 10 }}>
                        <div className="icon" />
                        <input value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder={"Search Personnel"} onFocus={() => setSearchFocused(true)} onBlur={() => setSearchFocused(false)} className="input" onKeyDown={keyPress} type="text" ref={searchRef} />
                        <div className="clear" onClick={() => {
                            setSearch("");
                            setSearchInput("");
                        }}></div>
                    </div>
                    <div className={`searchInput action ${shiftFilterFocused ? "isFocused" : ""} ${(shiftNameInput.length > 0) ? "hasInput" : ""}`}>
                        <div className="icon" />
                        <input value={shiftNameInput} onChange={(e) => setShiftNameInput(e.target.value)} placeholder={"Search Shift"} onFocus={() => setShiftFilterFocused(true)} onBlur={() => setShiftFilterFocused(false)} className="input" onKeyDown={keyPressShiftName} type="text" ref={shifNameFilterRef} />
                        <div className="clear" onClick={() => {
                            setShiftNameFilter("");
                            setShiftNameInput("");
                        }}></div>
                    </div>
                    <Popup closeOnDocumentClick ref={sortRef} position={"bottom left"} trigger={(<div><SortButton
                        buttonState=""
                        sortValue={selectedSort ? selectedSort.title : ""}
                        addClass="navAction"
                    /></div>)}>
                        <div className={"pullDownMenu"}>
                            {
                                _.map(SORTS, function (s, sIndex) {
                                    return (
                                        <div key={sIndex} className={`menuCell ${s.key === sort ? `selected sortDirection-${sortDirection}` : ''}`} onClick={onSortClick.bind(this, s.key)}>
                                            <div className="menuItemTitle">{s.title}</div>
                                            {
                                                s.key === sort ? (
                                                    sortDirection === "ASC" ? <AssetPullDownMenuSortAsc /> : <AssetPullDownMenuSortDesc />
                                                ) : null
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Popup>

                    <Popup closeOnDocumentClick ref={filterRef} position={"bottom left"} trigger={(<div>
                        <FilterButton
                            buttonState={filters && filters.length ? "isFiltered" : ""}
                            onFilterClear={toggleFilter}
                            enabledFilters={filters && filters.length > 0 ? _.map(filters, function (f) {
                                return _.find(FILTERS, function (filt) { return filt.key === f });
                            }) : ""}
                            addClass="navAction"
                        /></div>
                    )}>
                        <div className={"pullDownMenu"}>
                            {
                                _.map(FILTERS, function (f, fIndex) {
                                    return (
                                        <div key={fIndex} className={`menuCell ${_.includes(filters, f.key) ? `selected` : ''}`} onClick={toggleFilter.bind(this, f.key)}>
                                            <div className="menuItemTitle">{f.title}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Popup>

                    <Modal
                        button={(
                            <Button
                                title="none"
                                icon={<AssetButtonSettings />}
                                size="medium"
                                addClass="settingsButton navAction"
                            />
                        )}
                        content={(<AddEditDigestSettings
                            existingDigestSettings={props.satisfaction.digestSettings}
                            onCancel={handleClose}
                            onSuccess={handleAddSuccess}
                        />)}
                        dialogClassName="modalDialog satisfactionDigestPreferences"
                        title="Personnel Email Digest"
                        size="medium"
                        handleClose={handleClose}
                        handleOpen={handleOpen}
                        open={open}
                    />

                </div>

                <div className="separator"></div>
            </div>

            <div className="scrollView">
                <div className="tableView">
                    <div className="tableSection">
                        {
                            personnel.map(function (p) {
                                return (
                                    <SatisfactionPersonnelCell
                                        personnelName={p.name}
                                        key={p.internalPersonnelId}
                                        onCellClick={(e) => {
                                            // If they click anything but a clickable attribute on the row, then ignore it
                                            if (!e.target.classList.contains("personnelCellClickable")) {
                                                return;
                                            }

                                            props.history.push({
                                                pathname: "/satisfaction-personnel/" + p.internalPersonnelId,
                                                search: "?" + new URLSearchParams({ startDate: startDate.format(DATE_FORMAT), endDate: endDate.format(DATE_FORMAT) })
                                            })
                                        }}
                                        personnelRole={p.personnelType}
                                        internalPersonnelId={p.internalPersonnelId}
                                        personnelID={p.personnelId}
                                        onEmailUpdate={() => { loadPage(page); }}
                                        personnelEmail={p.email}
                                        surveysCount={p.numberResponses}
                                        positiveFeedbackCount={p.numberPositiveResponses}
                                        satisfactionScore={_.round((p.cpss * 100), 1) + '%'}
                                    />
                                )
                            })
                        }
                        {
                            hasMore ? (
                                <PaginationExpanderButton onClick={loadMore} />
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SatisfactionIndex));
