import React from 'react';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';

import InputCell from "@/common/form/InputCell";

export default function EditConsortium({ consortium, onSuccess, onCancel }) {
    const consortiumNameRef = React.createRef();

    const submitEdit = () => {
        API.put('Core', '/api/v1/consortium/' + consortium.id, {
            body: {
                name: consortiumNameRef.current.value
            }
        })
            .then(response => {
                toast("Successfully saved consortium!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                onSuccess();
            },
                error => {
                    toast("Could not save consortium: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"Consortium Name"}
                            defaultValue={consortium.name}
                            required
                            id="tenantName"
                            label="Consortium Name"
                            inputRef={consortiumNameRef}
                        />
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={submitEdit} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>
                <div className="separator"></div>
            </div>
        </div>
    );
}