import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { IconButton, makeStyles } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import ConfirmModal from "../../../common/ConfirmModal";
import { API } from "aws-amplify";
import AddEditPersonnel from "../../../components/AddEditPersonnel";
import FileDownload from "../../../common/FileDownload";
import Importer from "../../../common/Importer";
import TableDisplay from '../../../common/TableDisplay';

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const renderQrCode = rowData => {
    return (
        <FileDownload path={"/api/v1/personnel/" + rowData.id + "/qrcode"}>
            <IconButton color="primary" aria-label="Download QR Code" component="span">
                <GetAppIcon />
            </IconButton>
        </FileDownload>
    );
}

const columns = [
    { id: 'name', label: 'Personnel Name', minWidth: 170, title: 'Personnel Name', field: 'name', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 270}, 
      customSort: (a,b) => a?.name?.localeCompare(b?.name), },
    { id: 'email', label: 'Email', minWidth: 170, title: 'Email', field: 'email', customSort: (a,b) => a?.email?.localeCompare(b?.email), },
    { id: 'personnelId', label: 'Connector ID', minWidth: 170, title: 'Connector ID', field: 'personnelId', sorting: false, headerStyle: {cursor:'default'} },
    { id: 'type', label: 'Type', minWidth: 170, title: 'Type', field: 'type', },
    { id: 'qrcode', label: 'QR Code', minWidth: 50, title: 'QR Code', field: 'qrcode', render: renderQrCode, sorting: false, headerStyle: {cursor:'default'} },
];

function PersonnelIndex(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [importSchema, setImportSchema] = React.useState([]);
    const [personnel, setPersonnel] = React.useState([]);
    const loadPersonnel = () => {
        API.get("Core", "/api/v1/personnel")
            .then(response => {
                setPersonnel(response.map(p => { return { ...p, email: p.email || '' , 'actions': true } }));
            },
                error => {
                    toast("Could not get personnel: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                })
    }

    const loadSchema = () => {
        API.get("Core", "/api/v1/personnel/csv/schema")
            .then(response => {
                setImportSchema(response);
            },
                error => {
                    toast("Could not get schema: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                })
    }

    React.useEffect(() => {
        loadPersonnel();
        loadSchema();
    }, [])

    const deletePersonnel = (personnelId) => {
        API.del('Core', '/api/v1/personnel/' + personnelId)
            .then(response => {
                toast("Deleted Personnel!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                loadPersonnel();
            },
                error => {
                    toast("Could not Delete Personel" + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const renderActions = rowData => {
        let flexContainer = {display:'flex'};
        let flexChild = {flex:1};

        return (
            <div style={flexContainer}>
                <div style={flexChild}>
                <AddEditPersonnel button={(
                    <IconButton color="primary">
                        <EditIcon />
                    </IconButton>
                )} personnelId={rowData.id} onSuccess={() => { loadPersonnel(); }} />
                </div>
                <div style={flexChild}>
                <ConfirmModal
                    title={"Delete Personnel"}
                    text={(<span>Are you sure you want to delete <b>{rowData.name}</b>?</span>)}
                    onConfirm={deletePersonnel.bind(this, rowData.id)}
                    confirmTitle={"Delete"}
                >
                    <IconButton color="primary">
                        <DeleteIcon />
                    </IconButton>
                </ConfirmModal>
                </div>
            </div>);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <AddEditPersonnel
                    button={(<Button variant="contained" color="primary" className={classes.button}>
                        Add Personnel
                    </Button>)}
                    onSuccess={() => { loadPersonnel(); }}
                />
                <FileDownload path={"/api/v1/personnel/csv"}>
                    <Button variant="contained" color="primary" className={classes.button}>
                        Export Personnel
                    </Button>
                </FileDownload>
                <Importer
                    title={"Personnel"}
                    fields={importSchema}
                    importPath={"/api/v1/personnel/csv"}
                    onSuccess={() => {
                        loadPersonnel()
                    }}
                >
                    <Button variant="contained" color="primary" className={classes.button}>
                        Import Personnel
                    </Button>
                </Importer>
            </Grid>
            <Grid xs={12}>
                <TableDisplay columns={[...columns, { title: 'Actions', field: 'actions', render: renderActions, sorting: false, headerStyle: {cursor:'default'} }]} 
                    title='Personnel'
                    options={{thirdSortClick: false}}
                    data={personnel} pageSizeOptions={[10, 25, 50]} />
            </Grid>
        </Grid>
    )
}

export default PersonnelIndex;