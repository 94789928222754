import React from "react";
import _ from "lodash";
import Select from "react-select";

export default function EncounterTypePicker({ value, onChange, disabled }) {
    let encounterTypeOptions = [
        { key: "CAD", value: "CAD" },
        { key: "CONVERSATION", value: "Conversation" },
        { key: "RMS_CASE", value: "Records - Case" },
        { key: "RMS_INCIDENT", value: "Records - Incident" },
        { key: "RMS_FIELD_INTERVIEW", value: "Records - Field Interview" },
        { key: "RMS_ARREST", value: "Records - Arrest" },
        { key: "RMS_CITATION", value: "Records - Citation" },
        { key: "RMS_WARRANT", value: "Records - Warrant" },
        { key: "RMS_CRASH", value: "Records - Crash" },
        { key: "RMS_BOOKING", value: "Records - Booking" },
        { key: "RMS_PROP_EVIDENCE", value: "Records - Property & Evidence" },
        { key: "THREE_ONE_ONE", value: "311" }
    ];

    let selectedEncounterType = _.find(encounterTypeOptions, (o) => o.key === value);

    return (
        <Select className="tableCell selectCell" classNamePrefix="selectCell"
            isDisabled={disabled}
            onChange={onChange}
            value={selectedEncounterType}
            options={encounterTypeOptions}
            getOptionLabel={option => option.value}
            getOptionValue={option => option.key}
        />
    )

}