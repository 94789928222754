import React from "react"
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Select from "react-select";
import _ from "lodash";

import { Button } from "@/components/Buttons"
import Modal from "@/common/Modal";
import LocalTimeStamp from "@/common/LocalTimeStamp";
import TestRule from "@/routes/rules/TestRule";
import { fetchAllRules, fetchRuleVersions } from "@/shared/rules/actions";

import CADEncounterDiff from "./types/CADEncounterDiff";
import RMSCaseEncounterDiff from "./types/RMSCaseEncounterDiff";
import ThreeOneOneEncounterDiff from "./types/ThreeOneOneEncounterDiff";
import GenericEncounterDiff from "./types/GenericEncounterDiff";

const mapStateToProps = state => ({
    rules: state.rules.rules,
    ruleVersions: state.rules.activeRuleVersions
})

const mapDispatchToProps = dispatch => ({
    getRules: () => dispatch(fetchAllRules()),
    getRuleVersions: (ruleId) => dispatch(fetchRuleVersions(ruleId))
})

function EncounterDetails({ encounterId, rules, ruleVersions, ...props }) {
    const [open, setOpen] = React.useState(false);
    const [revisions, setRevisions] = React.useState([]);
    const [activeRevision, setActiveRevision] = React.useState(null);
    const [selectedRule, setSelectedRule] = React.useState();
    const [selectedRuleVersion, setSelectedRuleVersion] = React.useState();
    const [schema, setSchema] = React.useState([]);

    React.useEffect(() => {
        if (selectedRule && open) {
            setSelectedRuleVersion(null);
            props.getRuleVersions(selectedRule.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRule]);

    React.useEffect(() => {
        if (selectedRule && open) {
            let activeRv = _.find(ruleVersions, function (rv) { return selectedRule.activeRuleVersion && selectedRule.activeRuleVersion.id === rv.id });
            setSelectedRuleVersion(activeRv);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ruleVersions]);

    React.useEffect(() => {
        if (open) {
            loadData();
            props.getRules();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const loadData = () => {
        API.get('Core', '/api/v1/encounter/revisions/' + encounterId)
            .then(response => {
                let revisionsOrdered = _.sortBy(response.revisions, function (r) { return r.commitId; });
                setActiveRevision(revisionsOrdered[revisionsOrdered.length-1]);
                setRevisions(Object.assign([], revisionsOrdered));
                setSchema(response.schema);
            },
                error => {
                    toast("Could not load encounter revisions: " + error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.ERROR
                    });
                }
            );
    }

    const closeModal = () => {
        setOpen(false);
    }


    const getEncounterDiff = () => {
        if (!activeRevision) {
            return null;
        }

        switch (activeRevision.encounterRevision.type) {
            case "CAD":
                return <CADEncounterDiff revision={activeRevision} revisions={revisions} />
            case "RMS_CASE":
                return <RMSCaseEncounterDiff revision={activeRevision} revisions={revisions} />
            case "THREE_ONE_ONE":
                return <ThreeOneOneEncounterDiff revision={activeRevision} revisions={revisions} />
            default:
                return <GenericEncounterDiff revision={activeRevision} />
        }
    }

    const onSelectedRuleChange = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setSelectedRule(obj);
                break;
        }
    }

    const onSelectedRuleVersionChange = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setSelectedRuleVersion(obj);
                break;
        }
    }

    let content = null;

    if (open) {
        content = (
            <div className="modalDialog splitView encounterDetailsView">
                <div className="mainView">
                    <div className="navBar">
                        <div className="title">Service Request Details</div>
                        <div className="separator" />
                    </div>

                    <div className="scrollView">
                        <div className="contentView encounterVersionsList">
                            {
                                _.map(revisions, function (revision, k) {
                                    return (
                                        <div onClick={() => setActiveRevision(revision)} className={`tableCell encounterVersionCell ${revision.commitId === activeRevision.commitId ? 'selected' : ''}`} key={k}>
                                            <div className="cellTitle encounterVersionTimestamp"><LocalTimeStamp utcTimestamp={revision.commitDate} format={"MM/DD/YYYY, h:mm:ss a"} /></div>
                                            {
                                                k === 0 ? (
                                                    <div className="cellSubtitle encounterVersionDetail">Initial</div>
                                                ) : (
                                                    <div className="cellSubtitle encounterVersionDetail">{revision.changes.length} changes</div>
                                                )
                                            }
                                        </div>
                                    )
                                }).reverse()
                            }
                        </div>
                    </div>
                </div>

                <div className="detailView">
                    <div className="navBar">
                        <div className="title">{activeRevision ? (
                            <LocalTimeStamp utcTimestamp={activeRevision.commitDate} format={"MM/DD/YYYY, h:mm:ss a"} />
                        ) : ""}
                        </div>
                        <div className="separator" />
                    </div>

                    <div className="scrollView">

                        {
                            activeRevision ? (
                                <div className="contentView tableView encounterDetails" style={{ minHeight: 0, paddingBottom: '12px' }}>
                                    <div className="tableSection ruleTesting">
                                        <div className={"sectionTitle"}>Test a Rule</div>
                                        <Select className="testRuleSelectCell selectCell" classNamePrefix="selectCell"
                                            onChange={onSelectedRuleChange}
                                            value={selectedRule ? selectedRule : null}
                                            options={_.sortBy(rules, function (r) { return r.status; })}
                                            label={"Rule"}
                                            placeholder={"Select Rule..."}
                                            getOptionLabel={option => option.name + " (" + option.status + ")"}
                                            getOptionValue={option => option.id}
                                        />
                                        {
                                            selectedRule ? (
                                                <Select className="testRuleSelectCell selectCell" classNamePrefix="selectCell"
                                                    onChange={onSelectedRuleVersionChange}
                                                    value={selectedRuleVersion ? selectedRuleVersion : null}
                                                    options={_.sortBy(ruleVersions, function (r) { return r.version; }).reverse()}
                                                    placeholder={"Select Version..."}
                                                    getOptionLabel={option => {
                                                        if (selectedRule.activeRuleVersion && selectedRule.activeRuleVersion.id === option.id) {
                                                            return option.version + " (CURRENT)"
                                                        }
                                                        return option.version;
                                                    }}
                                                    getOptionValue={option => option.id}
                                                />
                                            ) : null
                                        }
                                    </div>
                                    <div className="tableSection">
                                        <div className="actions left">
                                            {
                                                selectedRule && selectedRuleVersion ? (
                                                    <TestRule
                                                        encounterId={encounterId}
                                                        encounterRevisionId={activeRevision.commitId}
                                                        ruleId={selectedRule.id}
                                                        ruleVersion={selectedRuleVersion}
                                                        schema={schema}
                                                    >
                                                        <Button
                                                            title="Test"
                                                            icon="none"
                                                            size="medium"
                                                            style="secondary"
                                                        />
                                                    </TestRule>
                                                ) : (
                                                    <Button
                                                        title="Test"
                                                        icon="none"
                                                        size="medium"
                                                        style="disabled"
                                                    />
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }
                        {getEncounterDiff()}
                    </div>

                    <div className="actionBar">

                        <div className="actions right">
                            <Button
                                onClick={closeModal}
                                title="Done"
                                icon="none"
                                size="medium"
                                style="primary"
                            />
                        </div>

                        <div className="separator" />
                    </div>
                </div>
            </div>
        )
    }

    return (<Modal
        button={props.children}
        content={content}
        title={"Encounter Details"}
        size="xl"
        titleBar="hidden"
        handleClose={() => closeModal()}
        handleOpen={() => setOpen(true)}
        open={open}
    />)
}

export default connect(mapStateToProps, mapDispatchToProps)(EncounterDetails);
