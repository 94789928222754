import { connect } from "react-redux";
import React from "react";
import {API} from "aws-amplify";
import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import _ from "lodash";

import {
    requestNewSMSNumber,
    fetchSMSSetting,
    updateSMSConfig,
    TRANSACTION_KEY
} from "@/shared/connect_config/actions";
import { SUCCESS } from "@/shared/lifecycleStates";
import { clearTransaction } from "@/shared/transaction_state/actions";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    updateSMSConfig: (body) => dispatch(updateSMSConfig(body)),
    requestSMSSetting: () => dispatch(fetchSMSSetting()),
    clearTransaction: (key) => dispatch(clearTransaction(key))
})

const useStyles = makeStyles(theme => ({
    panel: {
        marginTop: theme.spacing(1),
    },
    panelHeader: {
        marginLeft: theme.spacing(1)
    },
    inboxNameInput: {
        margin: theme.spacing(1),

    },
    button: {
        margin: theme.spacing(3),
    },
    instructions: {
        margin: theme.spacing(1),
    },
    phoneNumber: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        backgroundColor: "lightgray",
        borderRadius: "5px",
        width: "150px"
    },
    smsConfigGroup: {
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    }
}));

function ManageSMSSettings(props) {
    var { } = props;
    const classes = useStyles();
    const [loadActionsCalled, setLoadActionsCalled] = React.useState(false);
    const areaCodeRef = React.createRef();
    const defaultGreetingRef = React.createRef();

    if (!loadActionsCalled) {
        props.clearTransaction(TRANSACTION_KEY);
        props.requestSMSSetting();
        setLoadActionsCalled(true);
    }

    const requestNewNumber = () => {
        let configObj = {
            areaCode: areaCodeRef.current.value
        }

        API.post('Core', '/api/v1/sms-processing/request-number', {body: configObj})
            .then(response => {
                    toast.success("Successfully claimed the phone numbers", {position: toast.POSITION.TOP_CENTER});
                    props.clearTransaction(TRANSACTION_KEY);
                },
                error => {
                    toast.error(error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                    props.clearTransaction(TRANSACTION_KEY);
                }
            );
    }

    const updateConfig = () => {
        let body = Object.assign({}, props.connectConfig.sms);
        body.defaultGreeting = defaultGreetingRef.current.value;
        props.updateSMSConfig(body);
        props.clearTransaction(TRANSACTION_KEY);

    }

    const limitLength = (e) => {
        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
    }

    let error = false;
    if (props.transactions[TRANSACTION_KEY]) {
        if (props.transactions[TRANSACTION_KEY].state === SUCCESS) {
            toast.success("Successfully Issued a new number!", { position: toast.POSITION.TOP_CENTER });
            props.clearTransaction(TRANSACTION_KEY);
            props.requestSMSSetting();
        }
    }

    return (
        <Grid className={classes.panel} item>
            <Paper elevation={2}>
                <Typography className={classes.panelHeader} variant={"h6"}>Text Message Settings</Typography>
                <Divider></Divider>
                {
                    (_.get(props, "connectConfig.sms.phoneNumber")) ?
                        (<div>
                            <Typography className={classes.instructions} color={"textSecondary"}>Below is the phone number you should give clients if they want to text you with requests. Once it is received you will have an opportunity to route it to the correct person.</Typography>
                            <Typography className={classes.phoneNumber}>{_.get(props, "connectConfig.sms.phoneNumber")}</Typography>
                        </div>) :
                        (
                            <div>
                                <Typography className={classes.instructions} color={"textSecondary"}>Before clients start texting you, you need to claim a phone number. Enter your area code and we will issue a number in that area code.</Typography>
                                <FormControl className={classes.inboxNameInput}>
                                    <InputLabel>Area Code</InputLabel>
                                    <Input
                                        error={error}
                                        inputRef={areaCodeRef}
                                        size="small"
                                        maxLength={3}
                                        onChange={limitLength}
                                        endAdornment={<InputAdornment position="end">)</InputAdornment>}
                                        startAdornment={<InputAdornment position="start">(</InputAdornment>}
                                    />
                                </FormControl>
                                <Button variant="contained" color="primary" className={classes.button} onClick={requestNewNumber}>
                                    Claim New Phone Number
                                </Button>
                            </div>
                        )
                }
            </Paper>
        </Grid>
    )

}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSMSSettings);