import React from "react";
import { withRouter } from "react-router";
import { API, Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import Select from "react-select";
import AddAgentDataSource from "./AddAgentDataSource";
import AddEditConnector from "./AddEditConnector";
import _ from "lodash";

import Modal from "@/common/Modal";
import ConfirmModal from "@/common/ConfirmModal";
import { Button } from "@/components/Buttons";
import InputCell from "@/common/form/InputCell";

const SupportSingleAgent = (props) => {
    const LOG_EVENT_LEVELS = [
        { value: "Information", label: "Information" },
        { value: "Debug", label: "Debug" },
        { value: "Verbose", label: "Verbose" }
    ];

    const MINUTE_MULTIPLIER = 1;
    const HOUR_MINUTES = 60;
    const intervals = [
        { value: 'MINUTE', label: 'Minutes', multiplier: MINUTE_MULTIPLIER },
        { value: 'HOUR', label: 'Hours', multiplier: HOUR_MINUTES }
    ]

    const DEFAULT_LOG_LEVEL_INTERVAL = 20;

    const [agentId, setAgentId] = React.useState(props.match.params.agentId);
    const [agent, setAgent] = React.useState(null);
    const [logEventLevel, setLogEventLevel] = React.useState(LOG_EVENT_LEVELS[0]);
    const [connectors, setConnectors] = React.useState([]);
    const [dataSources, setDataSources] = React.useState([]);
    const [addDataSourceOpen, setAddDataSourceOpen] = React.useState(false);
    const [addConnectorOpen, setAddConnectorOpen] = React.useState(false);
    const [editConnector, setEditConnector] = React.useState(null);
    const tokenRef = React.createRef();
    const [logLevelInterval, setlogLevelInterval] = React.useState();
    const [selectedLogLevelIntervalOption, setSelectedLogLevelIntervalOption] = React.useState(intervals[0]);

    const loadAgent = () => {
        API.get('Core', '/api/v1/agents/' + agentId)
            .then(_agent => {
                setAgent(Object.assign({}, _agent));
                // Data sources are stored as JSON
                setDataSources(Object.assign([], JSON.parse(_agent.agentDataSources)));

                let logLevel = _agent ? _.find(LOG_EVENT_LEVELS, (lel) => lel.value === _agent.logEventLevel) : null
                setLogEventLevel(logLevel ? logLevel : LOG_EVENT_LEVELS[0]);

                if(_agent && _agent.logLevelExpiration && logLevel && logLevel.value !== LOG_EVENT_LEVELS[0].value){
                    let now = new Date();
                    let utcNow = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes());

                    let logLevelIntervalInMinutes = (new Date(_agent.logLevelExpiration) - utcNow) / (1000 * 60);

                    if(logLevelIntervalInMinutes < 0){
                        setlogLevelInterval(DEFAULT_LOG_LEVEL_INTERVAL);
                        setSelectedLogLevelIntervalOption(intervals[0]);
                    }
                    else{
                        let interval = intervals.find(i => logLevelIntervalInMinutes % i.multiplier === 0);
                        let intervalValue = logLevelIntervalInMinutes / interval.multiplier;
                        setlogLevelInterval(intervalValue);
                        setSelectedLogLevelIntervalOption(interval);
                    } 
                }
                else{
                    setlogLevelInterval(DEFAULT_LOG_LEVEL_INTERVAL);
                    setSelectedLogLevelIntervalOption(intervals[0]);
                }
            },
                error => {
                    toast("Could not load agent: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const updateAgent = () => {
        let agentUpdate = agent;
        agentUpdate.agentDataSources = JSON.stringify(dataSources);
        agentUpdate.logEventLevel = logEventLevel && logEventLevel.value ? logEventLevel.value : LOG_EVENT_LEVELS[0].value;
        agent.logLevelExpiration = (logLevelInterval ? logLevelInterval : DEFAULT_LOG_LEVEL_INTERVAL) * selectedLogLevelIntervalOption.multiplier;
        API.put('Core', '/api/v1/agents/' + agentId, { body: agentUpdate})
            .then(response => {
                setAgent(Object.assign({}, response));
                // Data sources are stored as JSON
                setDataSources(Object.assign([], JSON.parse(response.agentDataSources)));
            },
                error => {
                    toast("Could not update agent: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const loadConnectors = () => {
        API.get('Core', '/api/v1/agents/' + agentId + '/connectors')
            .then(response => {
                setConnectors(Object.assign([], response));
            },
                error => {
                    toast("Could not load agent connectors: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const copyToken = (datasource) => {
        var text = tokenRef.current.innerText;
        var elem = document.createElement("textarea");
        document.body.appendChild(elem);
        elem.value = text;
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);

        toast("Copied to clipboard!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
    }

    const removeConnector = (connector) => {
        API.del('Core', '/api/v1/agents/' + agent.id + '/connectors/' + connector.dataSourceId)
            .then(() => {
                toast("Successfully deleted connector!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                loadConnectors();
            })
            .catch((err) => {
                toast(err.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            });
    }

    const onLogEventLevelSelect = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setLogEventLevel(obj);
                break;
            default:
        }
    }

    const onDataSourceAdd = (datasource) => {
        dataSources.push(datasource);
        setDataSources(Object.assign([], dataSources));
        setAddDataSourceOpen(false);
        updateAgent();
    }

    const removeDataSource = (datasource) => {
        _.remove(dataSources, function (ds) { return ds.Id === datasource.Id; });
        setDataSources(Object.assign([], dataSources));
        updateAgent();
    }

    const onLogLevelIntervalOptionChange = (option) => {
        setSelectedLogLevelIntervalOption(option);
    }

    const onLogLevelIntervalChange = (option) => {
        setlogLevelInterval(option); 
    }

    React.useEffect(() => {
        if (agent && logEventLevel && (agent.logEventLevel !== logEventLevel.value)){
            updateAgent();
            if(logEventLevel.value === LOG_EVENT_LEVELS[0].value){
                setlogLevelInterval(DEFAULT_LOG_LEVEL_INTERVAL);
                setSelectedLogLevelIntervalOption(intervals[0]);
            }
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logEventLevel]);

    React.useEffect(() => {
        loadAgent();
        loadConnectors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agentId]);

    React.useEffect(() => {
        if (agent && logLevelInterval >= DEFAULT_LOG_LEVEL_INTERVAL && logEventLevel.value !== LOG_EVENT_LEVELS[0].value){
            updateAgent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logLevelInterval, selectedLogLevelIntervalOption]);

    if (!agent) {
        return (<span>Loading...</span>);
    }

    return (
        <div className="rootView surveysView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="title">
                        AGENT: {agent.name}
                    </div>
                </div>
                <div className="actions">
                    <span style={{ display: "none" }} ref={tokenRef}>{agent.token}</span>
                    {
                        document.queryCommandSupported('copy') ? (
                            <div onClick={copyToken}
                                className="cellAction actionEdit button small action">
                                <div className="title">Copy Token</div>
                            </div>
                        ) : null
                    }
                    <div className="">
                        {/*<Modal*/}
                        {/*    button={(<div className="button medium action">*/}
                        {/*        <div className="buttonTitle">Edit Consortium</div>*/}
                        {/*    </div>)}*/}
                        {/*    content={(<EditConsortium consortium={consortium} onSuccess={onEditSuccess} onCancel={() => setEditOpen(false)}/>)}*/}
                        {/*    title="Edit Consortium"*/}
                        {/*    size="large"*/}
                        {/*    handleClose={() => setEditOpen(false)}*/}
                        {/*    handleOpen={() => setEditOpen(true)}*/}
                        {/*    open={editOpen}*/}
                        {/*/>*/}
                    </div>

                </div>
                <div className="separator"></div>
            </div>
            <div className="scrollView">
                <div className="tableView">
                    <div className="tableSection">
                        <div className="sectionTitle">Agent Information</div>
                        <div className="tableCell" style={{ height: '100px' }}>
                            <div className="cellBody">
                                <div className="title groupName"><b>{agent.name.length > 30 ? agent.name.substring(0, 30) + '...' : agent.name}</b></div>
                                <div className="subtitle">Token: <b>{agent.token}</b></div>
                                <div className="subtitle">Version: <b>{agent.version}</b></div>
                                <div className="subtitle">Support Contact: <b>{agent.supportInformation}</b></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tableView">
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Log Event Level</div>
                        </div>

                        <Grid container>
                            <Grid item xs={5}>
                                <Select className="selectCell" classNamePrefix="selectCell"
                                    onChange={onLogEventLevelSelect}
                                    value={logEventLevel}
                                    options={LOG_EVENT_LEVELS}
                                    isClearable={false}
                                    getOptionLabel={option => option.label}
                                    getOptionValue={option => option.value} />
                                    {
                                        logEventLevel && logEventLevel.value === LOG_EVENT_LEVELS[0].value ? null :
                                        (
                                            <div className="logEventLevelInterval">
                                                <div className="sectionTitle">
                                                    <div className="title">Log Event Level Interval</div>
                                                </div>
                                                <div className="logEventLevelIntervalInput">
                                                    <InputCell
                                                    required
                                                    type={"number"}
                                                    defaultValue= {logLevelInterval}
                                                    minValue = {DEFAULT_LOG_LEVEL_INTERVAL}
                                                    onChange={(e) => onLogLevelIntervalChange(e.target.value)}
                                                    value={logLevelInterval} />
                                                    <Select className="selectCell" classNamePrefix="selectCell" value={selectedLogLevelIntervalOption} 
                                                        onChange={onLogLevelIntervalOptionChange} options={intervals} />
                                                </div>
                                           
                                            </div>
                                        )
                                    }
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="tableView">
                    <div className="tableSection">
                        <div className="sectionTitle" style={{width: '50%', flexBasis: '50%'}}>Manage Agent Data Sources</div>
                        <div style={{width: 150, paddingRight: 6, display: 'flex', flexBasis: '50%',justifyContent: 'flex-end'}}>
                            <Button
                                title="Add Data Source"
                                icon="none"
                                size="medium"
                                onClick={() => setAddDataSourceOpen(true)}
                            />
                        </div>
                        <Modal
                            content={(<AddAgentDataSource onAdd={onDataSourceAdd} onCancel={() => setAddDataSourceOpen(false)} />)}
                            title="Add Data Source"
                            size="large"
                            handleClose={() => setAddDataSourceOpen(false)}
                            handleOpen={() => setAddDataSourceOpen(true)}
                            open={addDataSourceOpen}
                        />
                        {
                            _.map(_.sortBy(dataSources, function (ds) {
                                return ds.Name;
                            }), function (ds) {
                                return (
                                    <div key={ds.Id} className="tableCell">
                                        <div className="cellBody">
                                            <div className="title groupName">{ds.Name}</div>
                                            <div className="subtitle">ID: <b>{ds.Id}</b></div>
                                            <div className="subtitle">Type: <b>{ds.Type}</b></div>
                                        </div>
                                        <div className={"cellActions"}>
                                            <ConfirmModal
                                                title={"Remove"}
                                                text={(<span>Are you sure you want to remove <b>{ds.Name}</b> Data Source?</span>)}
                                                onConfirm={removeDataSource.bind(this, ds)}
                                                confirmTitle={"Remove"}
                                            >
                                                <div className="cellAction button small destructiveProminent">
                                                    <div className="title">Remove</div>
                                                </div>
                                            </ConfirmModal>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="tableView">
                    <div className="tableSection">
                        <div className="sectionTitle" style={{width: '50%', flexBasis: '50%'}}>Manage Connectors</div>
                        <div style={{width: 150, paddingRight: 6, display: 'flex', flexBasis: '50%',justifyContent: 'flex-end'}}>
                            <Button title="Add Connector" icon="none" size="medium" onClick={() => setAddConnectorOpen(true)}/>
                        </div>
                        <Modal
                            content={(<AddEditConnector agent={agent} onSuccess={() => {
                                loadConnectors();
                                setAddConnectorOpen(false);
                            }} dataSources={dataSources} />)}
                            title="Add Connector"
                            size="large"
                            handleClose={() => setAddConnectorOpen(false)}
                            handleOpen={() => setAddConnectorOpen(true)}
                            open={addConnectorOpen}
                        />
                        {
                            _.map(_.sortBy(connectors, function (c) {
                                return c.datasourceName;
                            }), function (connector) {
                                return (
                                    <div key={connector.token} className="tableCell" style={{ height: 'auto' }}>
                                        <div className="cellBody">
                                            <div className="title groupName">{connector.datasourceName.length > 22 ? connector.datasourceName.substring(0, 22) + '...' : connector.datasourceName.name}</div>
                                            <div className="subtitle">Tenant: <b>{connector.tenantName}</b></div>
                                            <div className="subtitle">Data Source: <b>{connector.agentDataSourceId}</b></div>
                                            <div className="subtitle">Connector Interface: <b>{connector.connectorInterface}</b></div>
                                            <div className="subtitle">Vendor: <b>{connector.vendor}</b></div>
                                        </div>
                                        <div className={"cellActions"}>
                                            <Modal
                                                button={
                                                    <Button title="Edit" icon="none" size="medium" onClick={() => {setEditConnector(connector);}}
                                                    />
                                                }
                                                content={(<AddEditConnector agent={agent} onSuccess={() => {
                                                    loadConnectors();
                                                    setEditConnector(null);
                                                }} dataSources={dataSources} existingConnector={editConnector} />)}
                                                title="Edit Connector"
                                                size="large"
                                                handleClose={() => setEditConnector(null)}
                                                handleOpen={null}
                                                open={editConnector != null && editConnector.id === connector.id}
                                            />
                                            <ConfirmModal
                                                title={"Remove"}
                                                text={(<span>Are you sure you want to remove <b>{connector.datasourceName}</b>?</span>)}
                                                onConfirm={removeConnector.bind(this, connector)}
                                                confirmTitle={"Remove"}
                                            >
                                                <div className="cellAction button small destructiveProminent">
                                                    <div className="title">Remove</div>
                                                </div>
                                            </ConfirmModal>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SupportSingleAgent);