import {GET_DIGEST_SETTINGS_SUCCESS} from "./actions";

const initialState = {
    digestSettings:[]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DIGEST_SETTINGS_SUCCESS:
            return {
                ...state,
                digestSettings: action.payload
            };
        default:
            return state
    }
}