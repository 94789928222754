import { connect } from "react-redux";
import React from "react";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { fetchAllEncounters, fetchAllTestEncounters, postTestEncounter } from "@/shared/encounter/actions";
import EncounterList from "@/components/EncounterList";

const mapStateToProps = state => ({
    lastNewEncounterAt: state.encounter.lastNewEncounterAt,
    ...state
})

const mapDispatchToProps = dispatch => ({
    getEncounterPage: (page) => dispatch(fetchAllEncounters(page)),
    getTestEncounters: (page) => dispatch(fetchAllTestEncounters(page)),
    createTestEncounter: (testEncounter) => dispatch(postTestEncounter(testEncounter))
})

const RELOAD_RESET_TIME = 15000;

function ActivityEncounterList({ lastNewEncounterAt, ...props }) {
    const [encounters, setEncounters] = React.useState([]);
    const [activePage, setActivePage] = React.useState({});
    const [page, setPage] = React.useState(0);
    const [searchFocused, setSearchFocused] = React.useState(false);
    const [searchVal, setSearchVal] = React.useState("");
    const [searchInput, setSearchInput] = React.useState("");
    const [isReloadEnabled, setReloadEnabled] = React.useState(true);
    const searchRef = React.createRef();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => loadEncounters(), []);

    React.useEffect(() => {
        // Reload when there is a new encounter event
        if (!searchVal && isReloadEnabled && lastNewEncounterAt)
            loadEncounters(false, page, searchRef.current.value, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastNewEncounterAt])

    const loadFirstPage = (searchParam) => {
        setPage(0);
        loadEncounters(true, 0, searchParam);
    }

    const loadEncounters = (isSearch = false, pageNum = 0, searchParam = "", resetReload = false) => {

        if (resetReload) setReloadEnabled(false)

        API.get('Core', '/api/v1/encounter/page', {
            queryStringParameters: {
                page: pageNum,
                search: searchParam
            }
        })
            .then(response => {
                // if Searching then just set the content
                if (isSearch) setEncounters(response.content);
                else setEncounters(encounters.concat(response.content));

                setActivePage(response);
            },
                error => {
                    toast("Could not load survey results: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            ).finally(() => {
                if (resetReload) setTimeout(() => setReloadEnabled(true), RELOAD_RESET_TIME);
            });
    }

    const loadMore = () => {
        let nextPage = page + 1;
        setPage(nextPage);
        loadEncounters(false, nextPage, searchRef.current.value);
    }

    const onTestEncounterSubmit = async (testEncounter) => {
        await props.createTestEncounter(testEncounter);
        loadEncounters(false, page, searchRef.current?.value ? searchRef.current?.value : "");
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            setSearchVal(searchRef.current.value);
            loadFirstPage(searchRef.current.value);
        }
    }

    const clearSearch = () => {
        setSearchInput("");
        setSearchVal("");
        loadFirstPage("");
    }

    const onSearchFocused = () => {
        setSearchFocused(true);
    }

    const onSearchBlurred = () => {
        setSearchFocused(false);
    }

    const onSearchChange = (e) => {
        setSearchInput(searchRef.current.value);
    }

    return (
        <div>
            <div className="secondaryNavBar">
                <div className="navTitle">
                    <div className="viewName">All Encounters</div>
                    <div className="counterBadge">
                        <div className="counterValue">{activePage ? activePage.totalElements : ''}</div>
                    </div>
                </div>

                <div className="navActions">
                    {/*<TestEncounter encounters={props.encounter.allTestEncounters} loadEncounters={props.getTestEncounters} onSend={onTestEncounterSubmit} />*/}

                    <div className={`searchInput ${searchFocused ? "isFocused" : ""} ${(searchInput.length > 0) ? "hasInput" : ""}`}>
                        <div className="icon" />
                        <input value={searchInput} onChange={onSearchChange} placeholder={"Search Incident Number"} onFocus={onSearchFocused} onBlur={onSearchBlurred} className="input" onKeyDown={keyPress} type="text" ref={searchRef} />
                        <div className="clear" onClick={clearSearch}></div>
                    </div>
                </div>
            </div>
            <div className="activitySection sectionAllEncoutners">
                <EncounterList includeEncounterDetailsModal={true} title="" encounters={encounters} />
                {
                    (activePage && !activePage.last && !(activePage.numberOfElements < 20)) ? (
                        <div onClick={loadMore} className="encountersExpander">
                            <div className="expanderButton">
                                <div className="title">Show More</div>
                                <div className="icon" />
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityEncounterList);
