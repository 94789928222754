import {DEAUTH_PAYMENT_SETTINGS_SUCCESS, GET_PAYMENT_SETTINGS_SUCCESS} from "./actions";

const initialState = {
    settings : {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case DEAUTH_PAYMENT_SETTINGS_SUCCESS:
        case GET_PAYMENT_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                settings: action.payload
            });
        default:
            return state
    }
}