import React from "react"

import { AssetGeneralDisclosureIndicatorMedium } from "@/icons/Assets"
import SurveyResults from "@/routes/survey/SurveyResults";

function SatisfactionPersonnelSurveyCell(props) {
    const { satisfaction,caller,timestamp,surveyId,onModalClose } = props;
    const renderSatisfactionDetails = () => {
        if (satisfaction && satisfaction.type === 'same') {
            return (
                <div className="detailCell additionalBottomPadding">
                    <div className="detailValue">{satisfaction.value}</div>
                    <div className="detailTitle">Satisfaction</div>
                </div>
            );
        }

        if (satisfaction && satisfaction.type === 'range') {
            return (
                <>
                    <div className="detailCell">
                        <div className="detailValue">{satisfaction.lowest}</div>
                        <div className="detailTitle">Lowest Score</div>
                    </div>
                    <div className="detailCell">
                        <div className="detailValue">{satisfaction.highest}</div>
                        <div className="detailTitle">Highest Score</div>
                    </div>
                </>
            );
        }
        return null;
    };
    return (
        <div className="tableCell satisfactionPersonnelSurveyCell">
            <div className="cellBody">
                <div className="title callerName">{caller}</div>
                <div className="subtitle surveyTimestamp">{timestamp}</div>
            </div>

            <div className="cellDetails surveyResults">
                {renderSatisfactionDetails()}
                <SurveyResults surveyInstanceId={surveyId} onModalClose={onModalClose}>
                    <div className="button small tint viewSurvey">
                        <div className="buttonTitle">View</div>
                        <AssetGeneralDisclosureIndicatorMedium />
                    </div>
                </SurveyResults>
            </div>
        </div>
    )
}

export default SatisfactionPersonnelSurveyCell
