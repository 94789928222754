import React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import _ from "lodash";
import {Tooltip} from '@material-ui/core';

import Modal from "@/common/Modal";
import ConfirmModal from "@/common/ConfirmModal";
import Importer from "@/common/Importer";
import LocalTimeStamp from "@/common/LocalTimeStamp";
import {
    deleteSingleSurvey,
    fetchAllSurveys,
    fetchSurveyImports,
    fetchSurveySendImportSchema,
    fetchSurveyStats
} from "@/shared/survey/actions";
import {fetchAllEncounters} from "@/shared/encounter/actions";

import StartConversation from "../conversations/StartConversation";
import AddEditSurvey from "./AddEditSurvey";
import ManageInboundConfig from "./ManageInboundConfig";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getSurveys: () => dispatch(fetchAllSurveys()),
    getSurveyStats: () => dispatch(fetchSurveyStats()),
    deleteSurvey: (id) => dispatch(deleteSingleSurvey(id)),
    getSendSurveySchema: () => dispatch(fetchSurveySendImportSchema()),
    getImportsForSurvey: (surveyId) => dispatch(fetchSurveyImports(surveyId)),
    getEncounters: () => dispatch(fetchAllEncounters())
})

function SurveyIndex(props) {
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [sendOpen, setSendOpen] = React.useState(false);
    const [phoneVal, setPhoneVal] = React.useState("");
    const [importsExpanded, setImportsExpanded] = React.useState({});
    const [inboundOpen, setInboundOpen] = React.useState(false);

    const TITLE_MAX_LENGTH = 40;

    React.useEffect(() => {
        props.getSurveys();
        props.getSurveyStats();
        props.getSendSurveySchema();
    }, [])

    React.useEffect(() => {
        props.getSurveys();
    }, [props.survey.activeSurvey])

    const onSuccess = (rule) => {
        props.getSurveys();
        setOpen(false);
        setEditOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSendClose = () => {
        setSendOpen(false);
    }

    const editSurveyVersions = (survey) => {
        props.history.push("/survey/" + survey.id);
    }

    const deleteSurvey = (survey) => {
        props.deleteSurvey(survey.id);
    }

    const toggleSurveyImports = (surveyId) => {
        importsExpanded[surveyId] = !importsExpanded[surveyId];
        if (importsExpanded[surveyId]) {
            props.getImportsForSurvey(surveyId);
        }
        setImportsExpanded(Object.assign({}, importsExpanded));
    }

    const sendSurvey = (surveyId, phoneNumber, encounter) => {
        API.post('Core', '/api/v1/survey/' + surveyId + '/send', {body: {phone: phoneNumber, encounter: encounter}})
            .then(response => {
                    let number = encounter ? encounter.contact.phone : phoneNumber;
                    toast("Sent survey to " + number, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                    setPhoneVal("");
                    props.getSurveyStats();
                    handleSendClose();
                },
                error => {
                    toast("Could not send survey: " + error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.ERROR
                    });
                }
            );
    }

    const onImportSuccess = (surveyId) => {
        props.getImportsForSurvey(surveyId);
        importsExpanded[surveyId] = true;
        setImportsExpanded(Object.assign({}, importsExpanded));
    }

    return (
        <div className="rootView surveysView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="title">Survey</div>
                </div>
                <div className="actions">
                    <div className="">
                        <Modal
                            button={(<div className="button secondary medium">
                                <div className="icon"/>
                                <div className="title">Manage Inbound Initiated</div>
                            </div>)}
                            content={(<ManageInboundConfig
                                surveyDefinitions={props.survey ? props.survey.surveys : []}
                                onSuccess={() => setInboundOpen(false)}
                                onCancel={() => setInboundOpen(false)}
                            />)}
                            title="Inbound Initiated Survey Configuration"
                            size="small"
                            handleClose={() => setInboundOpen(false)}
                            handleOpen={() => setInboundOpen(true)}
                            open={inboundOpen}
                        />
                    </div>
                    <div>
                        <Modal
                            button={(<div className="add action">
                                <div className="icon"/>
                                <div className="title">Add Survey</div>
                            </div>)}
                            content={(<AddEditSurvey
                                onSuccess={onSuccess}
                                onCancel={handleClose}
                            />)}
                            title="Add Survey"
                            size="small"
                            handleClose={handleClose}
                            handleOpen={handleOpen}
                            open={open}
                        />

                    </div>
                </div>
                <div className="separator"></div>
            </div>
            <div className="scrollView">
                <div className="surveysList tableView">
                    <div className="tableSection">
                        <div className="sectionTitle">Your Surveys</div>
                        {
                            props.survey ? _.map(props.survey.surveys, function (survey, k) {
                                let stats = _.find(props.survey.surveyStats, function (ss) {
                                    return ss.id == survey.id;
                                });
                                let sentCount = 0;
                                let responseRate = '-'
                                if (stats && stats.sentCount) {
                                    sentCount = stats.sentCount;
                                    responseRate = _.round(((stats.completedCount / stats.sentCount) * 100), 1) + '%';
                                }
                                return (
                                    <div className="tableCell surveyCell expanded" key={k}>
                                        <div className="surveyBaseCell">

                                            <div className="cellBody">
                                                {survey?.name?.length >= TITLE_MAX_LENGTH ?
                                                    <Tooltip title={survey?.name} placement="top">
                                                        <div className="title serviceName">{survey?.name}</div>
                                                    </Tooltip> : <div
                                                        className="title serviceName">{survey?.name?.length > TITLE_MAX_LENGTH ? `${survey.name.substr(0, TITLE_MAX_LENGTH)}...` : survey?.name}</div>}
                                                <div
                                                    className="subtitle description">Version {survey?.activeSurveyVersion ? survey.activeSurveyVersion.version : "No Active Version"}</div>
                                            </div>

                                            <div className="surveyDetails">
                                                <div className="surveyStats">
                                                    <div className="surveyStatItem statSent">
                                                        <div className="statValue title">{sentCount}</div>
                                                        <div className="statTitle subtitle">Sent</div>
                                                    </div>

                                                    <div className="surveyStatItem statResponseRate">
                                                        <div className="statValue title">{responseRate}</div>
                                                        <div className="statTitle subtitle">Response Rate</div>
                                                    </div>
                                                </div>

                                                <div className="surveyActions">
                                                    <ConfirmModal
                                                        title={"Delete"}
                                                        text={"Are you sure you want to delete this survey?"}
                                                        onConfirm={deleteSurvey.bind(this, survey)}
                                                        confirmTitle={"Delete Survey"}
                                                    >
                                                        <div className="button destructive small">
                                                            <div className="title">Delete</div>
                                                        </div>
                                                    </ConfirmModal>
                                                    <div onClick={editSurveyVersions.bind(this, survey)}
                                                         className="surveyAction actionEdit button small action">
                                                        <div className="title">Edit</div>
                                                    </div>
                                                    {
                                                        survey.activeSurveyVersion ? (
                                                            <Importer
                                                                title={"Send Survey: " + survey.name + " - Version " + survey.activeSurveyVersion.version}
                                                                fields={props.survey.sendSurveyImportSchema}
                                                                importPath={"/api/v1/survey/" + survey.id + "/import/csv"}
                                                                onSuccess={onImportSuccess.bind(this, survey.id)}
                                                            >
                                                                <div
                                                                    className="surveyAction actionTest button small action">
                                                                    <div className="title">Import File to Send</div>
                                                                </div>
                                                            </Importer>
                                                        ) : null
                                                    }
                                                    <StartConversation title={"Send Survey"} submitBtnTitle={"Send"}
                                                                       encounters={props.encounter.allEncounters}
                                                                       loadEncounters={props.getEncounters}
                                                                       onStart={sendSurvey.bind(this, survey.id)}>
                                                        <div className="surveyAction actionTest button small action">
                                                            <div className="title">Single Send</div>
                                                        </div>
                                                    </StartConversation>
                                                    <div className="surveyAction actionStatus button small tint">
                                                        <div className="title">Active</div>
                                                    </div>

                                                    <div onClick={toggleSurveyImports.bind(this, survey.id)}
                                                         className="surveyAction actionExpand button small action">
                                                        <div className="title">View Imports</div>
                                                        <div className="icon"/>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        {
                                            importsExpanded[survey.id] ? (
                                                <div className="surveySourcesList">
                                                    {
                                                        props.survey.surveyImports[survey.id] && props.survey.surveyImports[survey.id].length > 0 ? (
                                                            <div>
                                                                {_.map(_.sortBy(props.survey.surveyImports[survey.id], function (imp) {
                                                                    return imp.createdAt;
                                                                }).reverse(), function (imp, k) {
                                                                    let importResponseRate = '-'
                                                                    if (imp.surveysSent) {
                                                                        importResponseRate = _.round(((imp.completedSurveys / imp.surveysSent) * 100), 1) + '%';
                                                                    }
                                                                    return (
                                                                        <div className="surveySourceCell" key={k}>
                                                                            <div className="cellBody">
                                                                                <div className="title">Contact Import
                                                                                </div>
                                                                                <div className="subtitle">
                                                                                    <LocalTimeStamp
                                                                                        utcTimestamp={imp.createdAt}
                                                                                        format={"MM/DD/YYYY, h:mm:ss a"}/>
                                                                                </div>
                                                                            </div>

                                                                            <div className="surveyDetails">
                                                                                <div className="surveyStats">
                                                                                    <div
                                                                                        className="surveyStatItem statSent">
                                                                                        <div
                                                                                            className="statValue title">{imp.surveysSent ? imp.surveysSent : 0}</div>
                                                                                        <div
                                                                                            className="statTitle subtitle">Sent
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="surveyStatItem statResponseRate">
                                                                                        <div
                                                                                            className="statValue title">{importResponseRate}</div>
                                                                                        <div
                                                                                            className="statTitle subtitle">Response
                                                                                            Rate
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="separator"/>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        ) : (
                                                            <div className="surveySourceCell empty">
                                                                <div className="cellBody">
                                                                    <div
                                                                        className="title">{props.survey.surveyImports[survey.id] ? "No Imports Yet" : "Loading..."}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : null
                                        }

                                    </div>
                                )
                            }) : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveyIndex));