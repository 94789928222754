import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {withRouter} from 'react-router';
import Container from "@material-ui/core/Container";
import _ from "lodash";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import {API} from "aws-amplify";
import {toast} from "react-toastify";

import {
	canBatchFeature, canManageAgencyInfo,
	canManageCommunicationGroups,
	canManagePatients,
	canManageProviderRotations,
	canManageRecordConnectors,
	canMessageUsers,
	isActivityDigestEmailEnabled,
	isDepartmentFeatureDisabled
} from "@/shared/access";
import { ehrHeaderFields } from "@/utils/utils";

import SettingsRoutes from './SettingsRoutes';
import store from "../../shared/store";

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		height:'100%'
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
}));

let validRoutes = [
	"/settings/team",
	"/settings/agency-info",
	"/settings/personnel",
	"/settings/personnelnotifications",
	"/settings/providerrotations",
	"/settings/roles",
	"/settings/dispositions",
	"/settings/tasktypes",
	"/settings/followup",
	"/settings/other",
	"/settings/conversations",
	"/settings/departments",
	"/settings/taskactiontypes",
	"/settings/interpreters",
	"/settings/federation",
	"/settings/record-connectors",
	"/settings/shifts",
	"/settings/encounter-configuration",
	"/settings/app-integrations",
	"/settings/groups",
	`/settings/field-options/${ehrHeaderFields.INTAKE}`,
	"/settings/weekly-digest",
	"/settings/activity-digest-email",
	"/settings/digest-email",
	"/settings/create-activity-digest-email",
	"/settings/create-cpss-digest-email",
	"/settings/public-feedback-board",
	"/settings/create-public-feedback-board",
];

function getValidRoutes(){
	return validRoutes;
}

function isValidRoute(test){
	return _.indexOf(getValidRoutes(), test) !== -1;
}

function Index(props) {
	const classes = useStyles();

	const [value, setValue] = React.useState('/settings/team');
	const [fields, setFields] = React.useState([]);

	React.useEffect(() => {
		API.get('Core', '/api/v1/ehr/fields?dynamicOptions=true').then(fieldsResponse => {
				setFields(fieldsResponse);

				fieldsResponse.forEach(field => {
					let fieldRoute = `/settings/field-options/${field.name}`;
					if(!validRoutes.includes(fieldRoute)) validRoutes.push(fieldRoute)
				})

				let activeRoute = props.location.pathname + props.location.search;
				if(!isValidRoute(activeRoute)){
					activeRoute = getValidRoutes()[0];
					props.history.push(activeRoute);
				}
				setValue(activeRoute);
            },
            error => {
                toast("Could not load patient fields: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.WARNING});
				setFields([]);
            }
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		props.history.push(newValue);
	};

	return (
		<div className={classes.root}>
			<Tabs
				orientation="vertical"
				value={value}
				onChange={handleChange}
				aria-label="Vertical tabs example"
				className={classes.tabs}
				variant="scrollable"
			>
				<Typography style={{paddingLeft: '5px'}} variant="overline">
					Agency Setup
				</Typography>
				<Divider light />
				<Tab label="Users" value="/settings/team"/>
				{
					canManageAgencyInfo() ?
						(<Tab label="Agency Info" value="/settings/agency-info"/>): null
				}
				{
					canManageProviderRotations() ?
						(<Tab label="Provider Rotations" value="/settings/providerrotations"/>):null
				}
				{
					isDepartmentFeatureDisabled() ?null:
						(<Tab label="Departments" value="/settings/departments"/>)
				}
				<Tab label="Shifts" value="/settings/shifts"/>
				<Tab label="Roles" value="/settings/roles"/>
				<Tab label="Federation & SSO" value="/settings/federation"/>
				{
					canManageRecordConnectors() ?
						(<Tab label="Record Connectors" value="/settings/record-connectors"/>) : null
				}
				<Tab label="Personnel" value="/settings/personnel"/>
				{
					canMessageUsers() ?
						(<Tab label="Personnel Notifications" value="/settings/personnelnotifications"/>) : null
				}
				
				{
					canBatchFeature() ?
					<Tab label="Encounter Configuration" value="/settings/encounter-configuration"/> : null
				}

				{
					canManageCommunicationGroups() ?
					<Tab label="Groups" value="/settings/groups"/> : null
				}

				{
					isActivityDigestEmailEnabled() ? 
					<Tab label="Activity Email Digest" value="/settings/activity-digest-email"/> : null
				}

				<Tab label="Public Feedback Board" value="/settings/public-feedback-board"/>

				{/* TODO: Enable when we want to support external apps to neogov */}
				{/* <Tab label="App Integrations" value="/settings/app-integrations"/> */}

				<Typography style={{paddingLeft: '5px'}} variant="overline">
					Conversations
				</Typography>

				<Divider light />

				<Tab label="Dispositions" value="/settings/dispositions"/>
				<Tab label="Interpreters" value="/settings/interpreters"/>
				<Typography style={{paddingLeft: '5px'}} variant="overline">
					Tasks
				</Typography>

				<Divider light />

				<Tab label="Task Types" value="/settings/tasktypes"/>
				<Tab label="Task Action Types" value="/settings/taskactiontypes"/>
				<Typography style={{paddingLeft: '5px'}} variant="overline">
					Surveys
				</Typography>

				<Divider light />

				<Tab label="Follow Up Questions" value="/settings/followup"/>
				
                { canManagePatients() ? 
    			<Typography style={{paddingLeft: '5px'}} variant="overline">
					Patients
				</Typography> : null }

				{ canManagePatients() ? <Divider light /> : null }

				{ canManagePatients() ?
				<Tab label={"Intake"} value={`/settings/field-options/${ehrHeaderFields.INTAKE}`} />
				: null }
				
				

				{canManagePatients() && !!fields && fields.map((field, index) => {
					return <Tab key={index} 
								label={field.displayName} 
								value={`/settings/field-options/${field.name}`}
								/>
				})}
			</Tabs>
			<Container maxWidth="xl" style={{overflow: 'scroll', overflowX: 'hidden'}} className={classes.container}>
				<SettingsRoutes/>
			</Container>
		</div>
	);
}
export default withRouter(Index);
