import React from "react"
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import {Button} from "./Buttons";
import InputCell from "../common/form/InputCell";
import Modal from "../common/Modal";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import {ContentState, Editor, EditorState} from "draft-js";

export default function AddEditPersonnelNotification({button, onSuccess, personnelId}) {
    const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState({});

    const [editorState, setEditorState] = React.useState();
    let domEditor;
    const setDomEditorRef = ref => domEditor = ref;

    let types = [
        {
            name: "Default",
            id: "DEFAULT"
        },
        {
            name: "Do Not Reply",
            id: "DONOTREPLY"
        }
    ]

    React.useEffect(() => {
        let initEditorState = ContentState.createFromText("");
        setEditorState(EditorState.createWithContent(initEditorState));
    }, [open])

    const notifyPersonnel = () => {
        console.log(editorState.getCurrentContent().getPlainText() == "");
        console.log(type.id == null);
        if(type.id == null || editorState.getCurrentContent().getPlainText() == ""){
            toast("Must set type and message", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            return;
        }

        let body = {
            message: editorState.getCurrentContent().getPlainText(),
            sourcePhoneNumber: type.id
        }

        API.post("Core", "/api/v1/personnel/notify", {body: body})
            .then(response => {
                onSuccess();
                setOpen(false);
            }, err => {
                toast("Could not notify personnel: " + err.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            })
    }

    const onTypeChange = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setType(obj);
                break;
        }
    }

    const updateEditorState = (newEditorState) => {
        setEditorState(newEditorState);
    }

    function containerClick(){
        domEditor.focus();
    }

    return (
        <Modal
            button={button}
            content={(
                <div>
                    <div className="content">
                        <div className="tableView">
                            <div className="tableSection sectionName" onClick={containerClick}>
                                <span className="sectionTitle">Message</span>

                                <div className="tableCell editorCell">
                                    <Editor
                                        readOnly={false}
                                        style={{height:"100%"}}
                                        editorState={editorState}
                                        onChange={updateEditorState}
                                        spellCheck={true}
                                        stripPastedStyles={true}
                                        ref={setDomEditorRef}
                                        placeholder={"Message to personnel..."}
                                    />
                                </div>
                            </div>
                            <div className="tableSection sectionName">
                                <div className="tableCell titledSelectCell">
                                    <div className="title">Source Number</div>
                                    <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                            onChange={onTypeChange}
                                            value={type}
                                            options={types}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actionBar">
                        <div className="actions">
                            <div onClick={() => setOpen(false)} className="button dismiss medium">
                                <div className="title">Cancel</div>
                            </div>

                            <div onClick={() => notifyPersonnel()} className={`button confirm medium`}>
                                <div className="title">Send</div>
                            </div>
                        </div>

                        <div className="separator"></div>
                    </div>
                </div>
            )}
            title={"Notify Personnel"}
            size="small"
            handleClose={() => setOpen(false)}
            handleOpen={() => setOpen(true)}
            open={open}
        />
    )
}