import React from 'react';
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import _ from "lodash";

import Modal from "@/common/Modal";

import CreateTenant from "./CreateTenant";

function SupportToolsTenants(props) {
    const [tenants, setTenants] = React.useState([]);
    const [createOpen, setCreateOpen] = React.useState(false);

    const loadTenants = () => {
        API.get('Core', '/api/v1/support-tools/tenants')
            .then(response => {
                setTenants(Object.assign([], response));
            },
                error => {
                    toast("Could not load tenants: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    React.useEffect(() => {
        loadTenants();
    }, []);

    const onTenantCreateSuccess = () => {
        loadTenants();
        setCreateOpen(false);
    }

    return (
        <div className="scrollView">

            <div className="">
                <Modal
                    button={(<div className="addButton">
                        <div className="buttonIcon" />
                        <div className="buttonTitle">Create Tenant</div>
                    </div>)}
                    content={(<CreateTenant onSuccess={onTenantCreateSuccess} />)}
                    title="Create Tenant"
                    size="large"
                    handleClose={() => setCreateOpen(false)}
                    handleOpen={() => setCreateOpen(true)}
                    open={createOpen}
                />

            </div>

            <div className="surveysList tableView">
                <div className="tableSection">
                    {
                        _.map(_.sortBy(tenants, function (t) {
                            return t.name;
                        }), function (tenant, k) {
                            return (
                                <div className="tableCell tenantCell" key={k}>
                                    <div className="cellBody">
                                        <div className="title">{tenant.name.length > 35 ? tenant.name.substring(0, 35) + '...' : tenant.name}</div>
                                        <div className="subtitle">Tenant {tenant.id}</div>
                                    </div>

                                    <div onClick={() => { props.history.push('/support-tools/tenant/' + tenant.id) }} className="button small action">
                                        <div className="buttonTitle">Manage</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default withRouter(SupportToolsTenants);