import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import _ from "lodash";

import {
    fetchAllArticleGroups,
    fetchAllArticles
} from "@/shared/articles/actions";

const mapStateToProps = state => ({
    articleGroups: state.articles.articleGroups,
    articles: state.articles.articles,
})

const mapDispatchToProps = dispatch => ({
    getArticleGroups : () => dispatch(fetchAllArticleGroups()),
    getArticles: (activeArticleGroupId) => dispatch(fetchAllArticles(activeArticleGroupId))
})


function LibraryIndex({defaultArticleGroupId, defaultArticleId, onSelect, onCancel, ...props}) {
    const [activeArticleGroupId, setActiveArticleGroupId] = React.useState(defaultArticleGroupId);
    const [activeArticleId, setActiveArticleId] = React.useState(defaultArticleId);

    React.useEffect(() => {
        props.getArticleGroups();
    }, [])

    React.useEffect(() => {
        props.getArticles(activeArticleGroupId);

    }, [activeArticleGroupId])

    let activeArticleGroup = _.find(props.articleGroups, function(ag){ return ag.id == activeArticleGroupId;});

    const onArticleGroupSelect = (articleGroup) => {
        setActiveArticleId(null);
        setActiveArticleGroupId(articleGroup.id);
    }

    const onArticleSelect = (article, articleGroup) => {
        setActiveArticleId(article.id);
    }

    const confirmSelect = () => {
        if(!activeArticleId){
            return;
        }
        let article = _.find(props.articles, function(art){return art.id == activeArticleId;});
        if(!article){
            return;
        }
        onSelect(article);
    }

    if(!props.articleGroups || props.articleGroups.length == 0){
        return (
            <div className={"content"}>
                There is nothing in your library. <Link to={"/library"}>Go to the Library to start building</Link>.
            </div>
        )
    }

    return (
        <div>
            <div className={"content"}>
                <div className="articlesView splitView">
                    <div className="articlesGroups mainView">
                        <div className="navBar">
                            <div className="title">Groups</div>
                            <div className="separator"/>
                        </div> {/* MARK: Nav Bar */}
                        <div className="articlesGroupsList">
                            {
                                _.map(_.sortBy(props.articleGroups, function (a) {
                                    return a.name
                                }), function (ag) {
                                    return (
                                        <div key={ag.id} onClick={onArticleGroupSelect.bind(this, ag)}
                                             className={`articlesGroupCell mainCell ${ag.id == activeArticleGroupId ? 'selected' : ''}`}>
                                            <div className="groupTitle">{ag.name}</div>
                                            <div
                                                className="articlesCount">{ag.articleCount ? (ag.articleCount == 1 ? "1 message" : ag.articleCount + " messages") : "None"}</div>
                                        </div>

                                    )
                                })
                            }
                        </div>
                    </div> {/* MARK: Articles Groups */}

                    <div className="articlesGroupDetail detailView">
                        {
                            activeArticleGroup ? (
                                <div className="navBar">
                                    <div className="title">Messages related to {activeArticleGroup.name}</div>
                                    <div className="separator"/>
                                </div>
                            ) : null
                        }
                        {
                            activeArticleGroup ? (
                                <div className="contentView articlesList">
                                    {/*<div className="searchInput">*/}
                                    {/*  <div className="icon"/>*/}
                                    {/*  <div className="input">Search All Articles</div>*/}
                                    {/*  <div className="clear"/>*/}
                                    {/*</div>*/}

                                    {
                                        _.map(_.sortBy(props.articles, function(a1){return a1.name}), function(art){
                                            if(activeArticleGroupId !== null){
                                                return (
                                                    <div key={art.id} onClick={onArticleSelect.bind(this, art)} className={`articleCell mainCell ${activeArticleId == art.id ? 'selected' : ''}`}>
                                                        <div className="articleTitle">{art.name}</div>
                                                        <div className="articleBody">{art.contents}</div>

                                                    </div>
                                                )}
                                        })
                                    }
                                </div>
                            ) : null
                        }

                    </div> {/* MARK: Articles List */}
                </div>
            </div>{/* MODAL CONTENT */}
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={confirmSelect} className={`button confirm medium ${activeArticleId ? '' : 'disabled'}`}>
                        <div className="title">Select</div>
                    </div>

                </div>

                <div className="separator"></div>
            </div> {/* ACTION BAR */}
        </div>
    );

}


export default connect(mapStateToProps, mapDispatchToProps)(LibraryIndex);