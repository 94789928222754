import React from 'react';
import _ from 'lodash';

function VersionList({ versions, selectedVersion, activeVersion, onSelect }) {
    return (
        <div>
            <div className="sectionTitle">Versions</div>
            {
                _.map(_.reverse(_.sortBy(versions, function (v) { return v.version })), function (v, k) {
                    return (
                        <div onClick={onSelect.bind(this, v)} className={`cell versionCell ${selectedVersion.id == v.id ? 'selected' : ''}`} key={k}>
                            <div className={"versionCellDetails cellBody"}>
                                <div className={"versionName title"}>
                                    Version {v.version}
                                </div>
                            </div>
                            {
                                (activeVersion && activeVersion.id == v.id) ? (
                                    <div className={"active"}>
                                        <div className="icon" />
                                    </div>
                                ) : null
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default VersionList;