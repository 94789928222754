import React from 'react';
import ResponseGroupSetup from "../conversation/ResponseGroupSetup";

function GroupsIndex(props) {

  return (
      <ResponseGroupSetup/>
  );
}

export default GroupsIndex;