import React from 'react';
import {connect} from "react-redux";
import _ from "lodash";

import Modal from "@/common/Modal";
import {
    createProvider,
    deleteProvider, fetchAllProviderCategories,
    fetchAllProviders, updateProvider,
} from "@/shared/providers/actions";

import AddEditProvider from "./AddEditProvider";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    fetchAllProviders: () => dispatch(fetchAllProviders()),
    fetchAllProviderCategories: () => dispatch(fetchAllProviderCategories()),
    createProvider: (body) => dispatch(createProvider(body)),
    deleteProvider: (id) => dispatch(deleteProvider(id)),
    updateProvider: (id, body) => dispatch(updateProvider(id,body)),
})

function Providers(props) {
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [editProvider, setEditProvider] = React.useState(null);

    React.useEffect(() => {
        props.fetchAllProviders();
        props.fetchAllProviderCategories();
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditProvider = (provider) => {
        setEditProvider(provider);
        setEditOpen(true);
    }

    const handleEditClose = () => {
        setEditProvider(null);
        setEditOpen(false);
    }

    const onSuccess = (provider) => {
        if(provider.id){
            props.updateProvider(provider.id, provider);
            handleEditClose();
        }
        else{
            props.createProvider(provider);
            handleClose();
        }
    }

    const onDelete = (providerId) => {

        if(providerId){
            props.deleteProvider(providerId);
            handleEditClose();
        }
    }

    return (
        <div className="rootView providersView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="title">Providers</div>
                </div>
                <div className="actions">
                    <div className="">
                        <Modal
                            button={(<div className="add action">
                                <div className="icon"/>
                                <div className="title">Add Provider</div>
                            </div>)}
                            content={(<AddEditProvider
                                onSuccess={onSuccess}
                                onCancel={handleClose}
                                categoryList={props.providerCategories.providerCategories}
                            />)}
                            title="Add Provider"
                            size="medium"
                            handleClose={handleClose}
                            handleOpen={handleOpen}
                            open={open}
                        />

                    </div>
                </div>
                <div className="separator"></div>
            </div>
            <div className="scrollView">
                <div className="providersList tableView">
                    <div className="tableSection">
                        <div className="sectionTitle">Providers Group Title</div>
                        {
                            _.map(props.providers.providers, function(provider, k){
                                return (
                                    <div className="tableCell provider" key={k}>
                                        <div className="icon">
                                            <div className="glyph"></div>
                                        </div>
                                        <div className="cellBody">
                                            <div className="title providerName">{provider.name}</div>
                                            <div className="subtitle description">{provider.details}</div>
                                        </div>
                                        <div className="cellActions">
                                            <div onClick={handleEditProvider.bind(this, provider)} className="cellAction actionEdit button small action">
                                                <div className="title">Edit</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Modal
                button={null}
                content={(<AddEditProvider
                    existingProvider={editProvider}
                    onDelete={onDelete}
                    onSuccess={onSuccess}
                    onCancel={handleEditClose}
                    categoryList={props.providerCategories.providerCategories}
                />)}
                title="Edit Provider"
                size="medium"
                handleClose={handleEditClose}
                open={editOpen}
            />
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Providers);