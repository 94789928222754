import React from "react";
import {ContentState, Editor, EditorState} from "draft-js";
import _ from "lodash";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import {
    comparisonOperators,
    surveyActionComparisonFields,
    surveyReviewTargetTypeOptions,
    surveyReviewTargetTypeOptionsWithoutQuestion
} from "@/utils/utils";
import InputCell from "@/common/form/InputCell";

const EMPTY_VALUE = "-";

function EditQuestion({question, onUpdate, disabled, user, taskTypes, followUpQuestions,updateSetAsActiveState}) {
    const [editorState, setEditorState] = React.useState();
    const [inputValue, setInputValue] = React.useState([]);
    const [multiSelectValue, setMultiSelectValue] = React.useState([]);
    const [compValue, setCompValue] = React.useState('');
    const [reviewCriterias, setReviewCriterias] = React.useState([]);


    let domEditor;
    const setDomEditorRef = ref => domEditor = ref;

    React.useEffect(() => {
        if (question) {
            let initEditorState = ContentState.createFromText(question.prompt ? question.prompt : "");
            setEditorState(EditorState.createWithContent(initEditorState));
            if (question.surveyQuestionReviewCriteria == null) {
                setReviewCriterias([]);
            } else {
                setReviewCriterias(question.surveyQuestionReviewCriteria);
                _.forEach(question.surveyQuestionReviewCriteria, function (criteria, j) {
                    multiSelectValue[j] = [];
                    if(criteria.comparisonValue != null && criteria.comparisonValue.length > 0) {
                        var values = criteria.comparisonValue.split(",");
                        var formatted = [];
                        for (var i = 0; i < values.length; i++) {
                            formatted.push(createOption(values[i]));
                        }
                        multiSelectValue[j] = formatted;
                    }
                });
                setMultiSelectValue(multiSelectValue);
            }
        }
    }, [question]);

    React.useEffect(() => {
        joinValues();
    }, [multiSelectValue])

    function editorContainerClick() {
        domEditor.focus();
    }

    function comparisonFieldOpts(questionType) {

        if (questionType == "SCALE" || questionType == "CHOICE") {
            return [
                {value: 'NORMALIZED_ANSWER', label: 'ANSWER'}
            ];
        } else {
            return surveyActionComparisonFields;
        }
    }

    function followUpOpts() {
        return _.map(followUpQuestions, function (fuq) {
            return followUpLabel(fuq);
        });
    }

    function comparisonOpts(questionType) {

        if (questionType == "SCALE") {
            return [
                {value: 'EQUAL', label: '='},
                {value: 'GREATER_THAN', label: '>'},
                {value: 'GREATER_THAN_EQUALS', label: '>='},
                {value: 'LESS_THAN', label: '<'},
                {value: 'LESS_THAN_EQUALS', label: '<='}
            ];
        } else if (questionType == "CHOICE") {
            return [
                {value: 'EQUAL', label: '='},
                {value: 'CONTAINS', label: 'Contains'},
                {value: 'DOES_NOT_CONTAIN', label: 'Does Not Contain'},
                {value: 'IN_LIST', label: 'In List'},
                {value: 'NOT_IN_LIST', label: 'Not In List'}
            ];
        } else {
            return [
                {value: 'EQUAL', label: '='},
                {value: 'CONTAINS', label: 'Contains'},
                {value: 'DOES_NOT_CONTAIN', label: 'Does Not Contain'},
                {value: 'IN_LIST', label: 'In List'},
                {value: 'NOT_IN_LIST', label: 'Not In List'}
            ];
        }
    }

    const updateEditorState = (newEditorState) => {
        setEditorState(newEditorState);
        if (newEditorState.getCurrentContent().getPlainText() !== editorState.getCurrentContent().getPlainText()) {
            updateSetAsActiveState(true);
        }
    }

    const blurEditor = () => {
        question.prompt = editorState.getCurrentContent().getPlainText();
        //onUpdate(question);
    }

    const setQuestionType = (obj, action) => {
        switch (action.action) {
            case "select-option":
                question.type = obj.value;
                onUpdate(question);
                break;
        }
    }

    const setQuestionSubjectRole = (obj, action) => {
        switch (action.action) {
            case "select-option":
                question.subjectRole = obj.value;
                onUpdate(question);
                break;
        }
    }

    const setTargetFollowUpQ = (idx, obj, action) => {
        const newCriterias = [...reviewCriterias];
        newCriterias[idx].followUpQuestion = obj.value;
        setReviewCriterias(newCriterias);
        question.surveyQuestionReviewCriteria = newCriterias;
        onUpdate(question);
    }

    const setTargetTaskType = (idx, obj, action) => {
        let ttIdx = _.findIndex(taskTypes, function(tt) {
            return tt.id == obj.value;
        })
        const newCriterias = [...reviewCriterias];
        newCriterias[idx].taskType = taskTypes[ttIdx];
        setReviewCriterias(newCriterias);
        question.surveyQuestionReviewCriteria = newCriterias;
        onUpdate(question);
    }

    const setQuestionReviewCriteriaTargetType = (idx, obj, action) => {
        const newCriterias = [...reviewCriterias];
        newCriterias[idx].targetType = obj.value;
        if(newCriterias[idx].targetType == "FOLLOWUP_QUESTION") {
            newCriterias[idx].followUpQuestion = {
                prompt: "",
                type: "SCALE",
                questionOrder: 0
            };
        }
        setReviewCriterias(newCriterias);
        question.surveyQuestionReviewCriteria = newCriterias;
        onUpdate(question);
    }

    const setQuestionReviewCriteriaComparisonField = (idx, obj, action) => {
        const newCriterias = [...reviewCriterias];
        newCriterias[idx].comparisonField = obj.value;
        setReviewCriterias(newCriterias);
        question.surveyQuestionReviewCriteria = newCriterias;
        onUpdate(question);
    }

    const setQuestionReviewCriteriaComparison = (idx, obj, action) => {
        const newCriterias = [...reviewCriterias];
        newCriterias[idx].comparison = obj.value;
        setReviewCriterias(newCriterias);
        question.surveyQuestionReviewCriteria = newCriterias;
        onUpdate(question);
    }

    const onReviewSelectValueChange = (idx, obj) => {
        const newCriterias = [...reviewCriterias];
        newCriterias[idx].comparisonValue = obj.value;
        setReviewCriterias(newCriterias);
        question.surveyQuestionReviewCriteria = newCriterias;
        onUpdate(question);
    }

    const onReviewValueChange = (idx, obj) => {
        const newCriterias = [...reviewCriterias];
        newCriterias[idx].comparisonValue = obj.target.value;
        setReviewCriterias(newCriterias);
        question.surveyQuestionReviewCriteria = newCriterias;
        onUpdate(question);
    }

    const components = {
        DropdownIndicator: null,
    };
    const handleChange = (idx, value, actionMeta) => {
        if (value != null) {
            let newMultiSelect = [...multiSelectValue]
            newMultiSelect[idx] = value;
            setMultiSelectValue(newMultiSelect);
        } else {
            let newMultiSelect = [...multiSelectValue]
            newMultiSelect[idx] = [];
            setMultiSelectValue(newMultiSelect);
        }
    };
    const handleInputChange = (idx, newVal) => {
        let newInValue = [...inputValue]
        newInValue[idx] = newVal;
        setInputValue(newInValue);
    };
    const handleKeyDown = (idx, event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                let newMulti = [...multiSelectValue];
                if(multiSelectValue[idx] == null) {
                    multiSelectValue[idx] = [];
                }
                newMulti[idx] = multiSelectValue[idx].concat(createOption(inputValue[idx]));
                setMultiSelectValue(newMulti)
                inputValue[idx] = '';
                setInputValue(inputValue);
                event.preventDefault();
        }
    };

    const createOption = (label) => ({
        label,
        value: label,
    });

    const joinValues = () => {
        for (var i = 0; i < multiSelectValue.length; i++) {
            if(multiSelectValue[i].length > 0) {
                var valuesOnly = [];
                for (var j = 0; j < multiSelectValue[i].length; j++) {
                    valuesOnly.push(multiSelectValue[i][j].label);
                }
                if (reviewCriterias[i] != null) {
                    reviewCriterias[i].comparisonValue = valuesOnly.join(',');
                    setReviewCriterias(reviewCriterias);
                    question.surveyQuestionReviewCriteria = reviewCriterias;
                    onUpdate(question);
                }
            }
        }

    }

    const onInputChange = (key, e) => {
        question[key] = e.target.value;
        onUpdate(question);
    }

    const onOptChange = (idx, e) => {
        question.options[idx].option = e.target.value;
        question.options[idx].optionOrder = idx;
        onUpdate(question);
    }

    const onShortOptChange = (idx, e) => {
        question.options[idx].shortOption = e.target.value;
        onUpdate(question);
    }

    const addOpt = () => {
        if (question.options && (!question.options[question.options.length-1].option || !question.options[question.options.length-1].shortOption)) {
            return;
        }

        if (!question.options) {
            question.options = [];
        }
        question.options.push({
            id: null,
            languagesTranslations: null,
            option: null,
            optionOrder: question.options.length + 1,
            shortOption:null,
        });
        onUpdate(question);
    }

    const removeOpt = (idx) => {
        question.options.sort((a, b) => a.optionOrder - b.optionOrder);
        question.options.splice(idx, 1);
        onUpdate(question);
    }

    const removeCriteria = (idx) => {
        multiSelectValue.splice(idx,1);
        setMultiSelectValue(multiSelectValue);

        inputValue.splice(idx,1);
        setInputValue(inputValue);

        const newCriterias = [...reviewCriterias];
        newCriterias.splice(idx, 1);
        setReviewCriterias([].concat(newCriterias));
        question.surveyQuestionReviewCriteria = newCriterias;
        onUpdate(question);
    }

    const addCriteria = () => {
        let criterias = reviewCriterias;
        if(!criterias){
            criterias = [];
        }
        setReviewCriterias(criterias.concat({
            comparisonValue : "" // set this property as a way of defaulting to enabled
        }));
        multiSelectValue.push([])
        setMultiSelectValue(multiSelectValue);
        inputValue.push("")
        setInputValue(inputValue);
    }

    if (!editorState) {
        return null;
    }

    const typeOptions = [
        {
            value: EMPTY_VALUE,
            label: EMPTY_VALUE},
        {
            value: "SCALE",
            label: "Scale"},
        {
            value: "CHOICE",
            label: "Choice"
        },
        {
            value: "COMMENT",
            label: "Comment"
        }
    ];
    let typeKey = question.type ? question.type : EMPTY_VALUE;
    let typeVal = _.find(typeOptions, function (to) {
        return to.value == typeKey;
    })

    const subjectRoleOptions = [{value: null, label: "None"}, {value: "CALLTAKER", label: "Call Taker"}, {value: "RESPONDER", label: "Responder"}, {value: "ALL", label: "All"}];
    let subjectRoleKey = question.subjectRole ? question.subjectRole : null;
    let subjectRoleVal = _.find(subjectRoleOptions, function (sro) {
        return sro.value == subjectRoleKey;
    })

    let body = null;
    switch (typeKey) {
        case "COMMENT":
            body = null;
            break;
        case "SCALE":
            body = (
                <div className={"scaleRange questionItem"}>
                    <div className={"numberInput"}>
                        <InputCell
                            defaultValue={(question.low ? question.low : "")}
                            required
                            disabled={disabled}
                            type={"number"}
                            onChange={onInputChange.bind(this, "low")}
                            value={question.low}
                        />
                    </div>
                    <InputCell
                        defaultValue={(question.lowDescription ? question.lowDescription : "")}
                        disabled={disabled}
                        onChange={onInputChange.bind(this, "lowDescription")}
                        value={question.lowDescription}
                        placeholder={"low value label"}
                    />
                    <span className={"scaleRangeTo"}>to</span>
                    <div className={"numberInput"}>
                        <InputCell
                            defaultValue={(question.high ? question.high : "")}
                            required
                            disabled={disabled}
                            type={"number"}
                            onChange={onInputChange.bind(this, "high")}
                            value={question.high}
                        />
                    </div>
                    <InputCell
                        defaultValue={(question.highDescription ? question.highDescription : "")}
                        disabled={disabled}
                        onChange={onInputChange.bind(this, "highDescription")}
                        value={question.highDescription}
                        placeholder={"high value label"}
                    />
                </div>
            )
            break;
        case "CHOICE":
            body = (
                <div className="multipleChoice">
                    {
                        _.map(_.sortBy(question.options, function(oo){
                            return oo.optionOrder;
                        }), function (opt, j) {
                            return (
                                <div key={'option-' + j} className={"questionItem multipleChoiceItem"}>
                                    <InputCell
                                        defaultValue={(opt.option ? opt.option : "")}
                                        wrapperClasses={"plain"}
                                        placeholder={"option text (e.g. Very Satisfied)"}
                                        required
                                        disabled={disabled}
                                        type={"text"}
                                        onChange={onOptChange.bind(this, j)}
                                    />
                                    <InputCell
                                        defaultValue={(opt.shortOption ? opt.shortOption : "")}
                                        style={{width: '80%'}}
                                        wrapperClasses={"plain multiChoiceShortcut"}
                                        placeholder={"shortcut (e.g. V)"}
                                        required
                                        disabled={disabled}
                                        type={"text"}
                                        onChange={onShortOptChange.bind(this, j)}
                                    />
                                    <div key={'option-removal-' + j} className={"remove"} onClick={removeOpt.bind(this, j)}>
                                        <div className="icon"/>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div onClick={addOpt} className={"questionItem button large action addOption"}>
                        <div className="title">Add another option...</div>
                    </div>
                </div>
            );
            break;
    }

    const followUpLabel = (fuq) => {
        if(fuq.name != null && fuq.name != "") {
            return {label: fuq.name, value: fuq};
        } else {
            return {label: fuq.prompt, value: fuq};
        }
    }

    const generateCompValue = (criteria, question, idx) => {
        let compValueIn = null;
        if (criteria != null) {
            if(question.type == "CHOICE") {
                let choiceOptions = _.map(question.options, function (opt) {
                    return {label: opt.option, value: opt.option}
                })
                if (criteria.comparison == "IN_LIST" || criteria.comparison == "NOT_IN_LIST") {
                    compValueIn =
                        <CreatableSelect
                            classNamePrefix="surveySelect"
                            className="tableCell selectCell"
                            components={components}
                            inputValue={inputValue[idx]}
                            isClearable
                            isMulti
                            onChange={handleChange.bind(this, idx)}
                            onInputChange={handleInputChange.bind(this, idx)}
                            onKeyDown={handleKeyDown.bind(this, idx)}
                            placeholder=""
                            value={multiSelectValue[idx]}
                            options={choiceOptions}
                        />
                } else {
                    compValueIn =
                        <Select className="tableCell selectCell"
                                classNamePrefix="surveySelect"
                                isDisabled={disabled}
                                value={criteria ? {
                                    label: reviewCriterias[idx].comparisonValue,
                                    value: reviewCriterias[idx].comparisonValue
                                } : ""}
                                onChange={onReviewSelectValueChange.bind(this, idx)}
                                options={choiceOptions}/>
                }
            } else {
                if (criteria.comparison == "IN_LIST" || criteria.comparison == "NOT_IN_LIST") {
                    compValueIn =
                        <CreatableSelect
                            className="tableCell selectCell"
                            components={components}
                            inputValue={inputValue[idx]}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={handleChange.bind(this, idx)}
                            onInputChange={handleInputChange.bind(this, idx)}
                            onKeyDown={handleKeyDown.bind(this, idx)}
                            placeholder=""
                            value={multiSelectValue[idx]}
                        />
                } else {
                    compValueIn =
                        <InputCell
                            wrapperClasses="tableCell valueCell"
                            disabled={disabled}
                            placeholder={"Value"}
                            type={question.type == "SCALE" ? "number" : "text"}
                            onChange={onReviewValueChange.bind(this, idx)}
                            defaultValue={criteria ? reviewCriterias[idx].comparisonValue : ""}
                        />
                }
            }
        }
        return compValueIn;
    }

    let surveyReviewSection = null;
    let taskOpts = _.concat([], _.map(taskTypes, function(tt){return {value : tt.id, label : tt.name, data: tt}}));

    //let taskOpts = _.concat([{value : EMPTY_VALUE, label:EMPTY_VALUE}], _.map(taskTypes, function(tt){return {value : tt.id, label : tt.name, data: tt}}));
    let taskKey = question.surveyQuestionReviewCriteria && question.surveyQuestionReviewCriteria.targetType == "CREATE_TASK" && question.surveyQuestionReviewCriteria.taskType ? question.surveyQuestionReviewCriteria.taskType.id : EMPTY_VALUE;
    let taskVal = _.find(taskOpts, function(t){return t.value == taskKey;})
    if(user.features != null) {
        let featureList = user.features.map(feature => feature.feature);
        let followUpQs = _.filter(reviewCriterias, function(r){
            return r.targetType == "FOLLOWUP_QUESTION"
        }).length;
        surveyReviewSection = (
            <div>
                {
                    _.map(reviewCriterias, function(c, j) {
                            return (
                                <div className="questionCriteria">
                                    <div className="criteriaContainer">
                                        <div className="criteriaRow filterRow">
                                            <div className="tableCell titledSelectCell conditionCell">
                                                <div className="title">If...</div>
                                                <Select className="selectCell" classNamePrefix="selectCell"
                                                        isDisabled={disabled}
                                                        value={c.comparisonField ? _.find(surveyActionComparisonFields, function (sacf) {
                                                            return sacf.value == c.comparisonField;
                                                        }) : ""} onChange={setQuestionReviewCriteriaComparisonField.bind(this, j)}
                                                        options={comparisonFieldOpts(question.type)}/>
                                            </div>

                                            <Select className="tableCell selectCell operatorCell" classNamePrefix="selectCell"
                                                    isDisabled={disabled}
                                                    value={c.comparison ? _.find(comparisonOperators, function (co) {
                                                        return co.value == c.comparison;
                                                    }) : ""} onChange={setQuestionReviewCriteriaComparison.bind(this, j)}
                                                    options={comparisonOpts(question.type)}/>
                                            {generateCompValue(c, question, j)}
                                        </div>

                                        <div className="criteriaRow actionRow">
                                            <div className="tableCell titledSelectCell">
                                                <div className="title">Then...</div>
                                                <Select className="selectCell" classNamePrefix="selectCell"
                                                        isDisabled={disabled}
                                                        value={c && c.targetType ? _.find(surveyReviewTargetTypeOptions, function (tt) {
                                                            return tt.value == c.targetType;
                                                        }) : ""} onChange={setQuestionReviewCriteriaTargetType.bind(this,j)}
                                                        options={followUpQs < 1 ? surveyReviewTargetTypeOptions : surveyReviewTargetTypeOptionsWithoutQuestion}/>
                                            </div>

                                            {c && c.targetType == "CREATE_TASK" ?
                                                <div className="tableCell titledSelectCell">
                                                    <div className="title">Task Type</div>
                                                    <Select className="selectCell" classNamePrefix="selectCell"
                                                            isDisabled={disabled} value={c.taskType ? {label: c.taskType.name, value: c.taskType.id} : ""} onChange={setTargetTaskType.bind(this,j)}
                                                            options={taskOpts}/>
                                                </div>
                                                : null}
                                            {c && c.targetType == "FOLLOWUP_QUESTION" ?
                                                <div className="tableCell titledSelectCell">
                                                    <div className="title">Follow Up Question</div>
                                                    <Select className="selectCell" classNamePrefix="selectCell"
                                                            isDisabled={disabled} value={c.followUpQuestion ? followUpLabel(c.followUpQuestion) : ""} onChange={setTargetFollowUpQ.bind(this,j)}
                                                            options={followUpOpts()}/>
                                                </div>
                                                : null}
                                        </div>
                                    </div>

                                    <div className={"removeCriteria"} onClick={removeCriteria.bind(this, j)}>
                                        <div className="icon">
                                            <div className="iconLine lineVertical"/>
                                            <div className="iconLine lineHorizontal"/>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                    )}
                {disabled ? null : (
                    <div onClick={addCriteria} className="button action medium addCriteria">
                        <div className="title">Add Criteria</div>
                    </div>)}
            </div>
        )

    }

    return (
        <div className={"questionContainer questionCell"}>
            <div className={"questionBody"}>
                {
                    typeKey == EMPTY_VALUE ? (<span className={"questionEmptyState"}>Select a question type to start editing</span>)
                        : (
                            <div className={"questionTitle tableCell editorCell"} onClick={editorContainerClick}>
                                <Editor
                                    readOnly={disabled}
                                    style={{height:"100%"}}
                                    editorState={editorState}
                                    onChange={updateEditorState}
                                    onBlur={blurEditor}
                                    placeholder={"Click and type to survey question prompt..."}
                                    spellCheck={true}
                                    stripPastedStyles={true}
                                    ref={setDomEditorRef}
                                />
                            </div>
                        )
                }
                <div className="tableCell titledSelectCell targetResponseGroup">
                    <div className="title">Subject Role</div>
                    <Select placeholder={"Subject Role"} className="questionType tableCell selectCell" classNamePrefix="selectCell" isDisabled={disabled} value={subjectRoleVal} onChange={setQuestionSubjectRole} options={subjectRoleOptions}/>
                </div>
                {body}
                {surveyReviewSection}


            </div>
            <Select className="questionType tableCell selectCell" classNamePrefix="selectCell" isDisabled={disabled} value={typeVal} onChange={setQuestionType} options={typeOptions}/>
        </div>
    );
}

export default EditQuestion;