

export const COMPARISON_MAP = {
    "EQUAL" : "=",
    "GREATER_THAN" : ">",
    "GREATER_THAN_EQUALS" : ">=",
    "LESS_THAN" : "<",
    "LESS_THAN_EQUALS" : "<=",
    "CONTAINS" : "Contains",
    "DOES_NOT_CONTAIN" : "Does Not Contain",
    "IS_EMPTY" : "Is Empty",
    "IS_NOT_EMPTY" : "Is Not Empty",
    "IN_LIST": "In List",
    "NOT_IN_LIST": "Not In List",
    "IN_LIST_CONTAINS": "In List Contains",
    "NOT_IN_LIST_CONTAINS": "Not In List Contains",
    "AFTER": "After"
}

export const COMPARISON_TO_TEXT_MAP = {
    "EQUAL" : "is exactly",
    "GREATER_THAN" : "is greater than",
    "GREATER_THAN_EQUALS" : "is greater than or equal to",
    "LESS_THAN" : "is less than",
    "LESS_THAN_EQUALS" : "is less than or equal to",
    "CONTAINS" : "contains",
    "DOES_NOT_CONTAIN" : "does not contain",
    "IS_EMPTY" : "is empty",
    "IS_NOT_EMPTY" : "is not empty",
    "IN_LIST": "is in list",
    "NOT_IN_LIST": "is not in list",
    "IN_LIST_CONTAINS": "is in list contains",
    "NOT_IN_LIST_CONTAINS": "is not in list contains",
    "AFTER": "is after"
}