import {API} from "aws-amplify";
import {toast} from "react-toastify";
import React, { useEffect } from "react";
import Select from "react-select";


export default function TenantDepartmentSelect({ tenantId, onSelect, value }){
    const [departments, setDepartments] = React.useState([]);

    useEffect(() => {
        if (!tenantId) {
            setDepartments([]);
            return;
        }

        API.get("Core", `/api/v1/support-tools/tenants/${tenantId}/departments`)
            .then(response => {
                    setDepartments(response);
                },
                error => {
                    toast("Could not get departments: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
    }, [tenantId]);

    const onDepartmentSelect = (obj, action) => {
        switch (action.action) {
            case "select-option":
                onSelect(obj);
                break;
            case "clear":
                onSelect(null);
                break;
        }
    }

    if(!departments || departments.length === 0){
        return (<span>No Departments</span>);
    }

    return (
        <Select className="selectCell" classNamePrefix="selectCell"
                isDisabled={false}
                onChange={onDepartmentSelect}
                value={value ? value : "--"}
                options={departments}
                isClearable={true}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}/>
    );
}