import setUpEidSdkOidc from './EIDAuthConfig';

let oidcInstance = null;

const getOidcServiceInstance = async () => {
    if (!oidcInstance) {
        oidcInstance = await setUpEidSdkOidc();
    }
    return oidcInstance;
};

export default getOidcServiceInstance;