import React from 'react';
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import PhoneInput from "react-phone-input-2";

import InputCell from "@/common/form/InputCell";

export default function AddEditInterpreter(props){
    var { onCancel, onSuccess, existingInterpreter} = props;

    const [phoneVal, setPhoneVal] = React.useState(existingInterpreter ? existingInterpreter.phoneNumber : "");

    const languageRef = React.createRef();
    const extensionRef = React.createRef();


    const addEditInterpreter = () => {

        let payload = {body: {language:languageRef.current.value, phoneNumber:phoneVal, extension: extensionRef.current.value}};

        if(existingInterpreter){
            return API.put("Core", "/api/v1/interpreter/" + existingInterpreter.id, payload).then(
                (response) => {
                  toast.success("Interpreter Updated!", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  onSuccess(response);
                },
                (error) => {
                  toast.error(
                    "Could not update interpreter: " + error.response.data.message,
                    { position: toast.POSITION.TOP_CENTER }
                  );
                }
              );
        }

        API.post('Core', '/api/v1/interpreter', payload)
            .then(response => {
                    toast.success("Interpreter Created!", {position: toast.POSITION.TOP_CENTER});
                    onSuccess(response);
                },
                error => {
                    toast.error("Could not save interpreter: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                }
            );
    }

    const onPhoneChange = (phone) => {
        setPhoneVal(phone);
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={""}
                            defaultValue={existingInterpreter ? existingInterpreter.language : ""}
                            required
                            id="language"
                            label="Language"
                            inputRef={languageRef}
                        />
                    </div>
                    <div className="tableSection sectionName">
                        <div className="tableCell inputCell phoneInput">
                            <div className="title">Phone Number</div>
                            <PhoneInput
                                inputClass="tel-input"
                                country={'us'}
                                onlyCountries={['us']}
                                value={phoneVal}
                                onChange={onPhoneChange}
                            />
                        </div>
                    </div>
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={""}
                            defaultValue={existingInterpreter ? existingInterpreter.extension : ""}
                            id="extension"
                            label="Extension"
                            inputRef={extensionRef}
                        />
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={addEditInterpreter} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>

    )
}