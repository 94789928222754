import React from 'react';
import CurrencyFormat from 'react-currency-format';

export default function InputCell(props) {
    const { label, defaultValue, inputRef, onChange, required, placeholder, type, 
        inputProps, isCurrency, disabled, wrapperClasses, maxLength, minValue, maxValue, onBlur, pattern } = props;
    const [inputFocused, setInputFocused] = React.useState(false);
    const [inputVal, setInputVal] = React.useState("");

    const onInputFocused = () => {
        setInputFocused(true);
    }

    const onInputBlurred = (e) => {
        setInputFocused(false);
        if (onBlur) {
            onBlur(e);
        }

    }

    const onInputChange = (e) => {
        if (pattern && e.target.value !== "") {
            var regexp = new RegExp(pattern);
            if (!regexp.test(e.target.value)) {
                return;
            }
        }
        
        setInputVal(e.target.value);
        if (onChange) {
            onChange(e);
        }
    }

    const onCurrencyInputChange = (values) => {
        const { formattedValue, value } = values;
        setInputVal(formattedValue);
        if (onChange) {
            onChange(value);
        }
    }

    React.useEffect(() => {
        if (defaultValue) {
            setInputVal(defaultValue);
        }
    }, [defaultValue]);
    let calculatedType = type ? type : "text";

    const field = () => {
        if (isCurrency) {
            return <CurrencyFormat value={inputVal} onValueChange={onCurrencyInputChange} decimalScale={2} className="input" fixedDecimalScale={true} prefix={'$'} />
        }

        switch (calculatedType) {
            case "textarea": return <textarea disabled={disabled}
                value={inputVal}
                onChange={onInputChange}
                onFocus={onInputFocused}
                onBlur={onInputBlurred}
                //className="input"
                ref={inputRef}
                maxLength={maxLength}
                min={minValue}
                max={maxValue}
                {...inputProps} />;

            default: return <input disabled={disabled}
                type={calculatedType}
                value={inputVal}
                onChange={onInputChange}
                onFocus={onInputFocused}
                onBlur={onInputBlurred}
                className="input"
                ref={inputRef}
                maxLength={maxLength}
                min={minValue}
                max={maxValue}
                pattern={pattern}
                placeholder={placeholder ? placeholder : ""} {...inputProps} />;
        }
    }

    return (
        <div className={`cell inputCell ${disabled ? "disabled" : ""} ${required ? "required" : ""} ${inputFocused ? "isFocused" : ""} ${(inputVal.length > 0) ? "hasInput" : ""} ${wrapperClasses ? wrapperClasses : ""}`}>
            {
                label ? (
                    <div className="title">{label}</div>
                ) : null
            }

            {field()}
        </div>
    );
}