import React, {Component, useState} from 'react';
import { Button, Link, makeStyles, TextField } from "@material-ui/core";
import { toast } from 'react-toastify';
import { Auth, } from 'aws-amplify';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    cardTitle: {
        marginBottom: '24px',
        fontSize: '18px',
    },
    requestPasswordResetCodeContainer: {
        width: '100%',
    },
    requestPasswordResetCodeInput: {
        width: '100%',
        marginBottom: '30px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    backToSignInLink: {
        color: '#FF9900',
        cursor: 'pointer',
        fontSize: '14px',
    },
    requestCodeButton: {
        backgroundColor: '#FF9900',
        textTransform: 'uppercase',
        color: 'white',
        padding: '14px 0',
        minWidth: '153px',
        touchAction: 'manipulate',
        fontWeight: 400,
        fontFamily: 'Arial',
        letterSpacing: '1.1px',
        fontSize: '12px',
        lineHeight: '18px',
        borderRadius: 0,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#FF9900',
            color: 'white',
        }
    },
}));

export default function RequestResetPasswordCode(props)
{
    const CLASSES = useStyles();

    let goBackToSignIn = () =>  props.onStateChange('signIn', {});

    let requestCode = () => {
        
        if(!!props.email)
        {
            console.log('-> ', props.email, props);
            Auth.forgotPassword(props.email)
            .then(r => {
                console.log('R -> ', r);
                props.updateCode(true);
            })
            .catch(e => {
                console.error('E -> ', e);
                toast(e.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR})
            });
        }
        else
        {
            toast("Email cannot be empty", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
        }
    }

    return (<div className={CLASSES.requestPasswordResetCodeContainer}>
        <div className={`gatekeeperTitle ${CLASSES.cardTitle}`}>Reset your password</div>
            <TextField id="requestResetPasswordCodeEmailInput"
            className={CLASSES.requestPasswordResetCodeInput}
            label="Email"
            type='email'
            placeholder='Enter your email'
            variant='outlined'
            onChange={e => props.updateEmail(e.target.value)}
            required/>

            <div className={CLASSES.buttonsContainer}>
                <Link onClick={goBackToSignIn} className={CLASSES.backToSignInLink} underline='hover'>Back to Sign In</Link>
                <Button className={CLASSES.requestCodeButton} variant='contained'
                        disableElevation
                        onClick={requestCode}>
                    Send Code
                </Button>
            </div>
    </div>);
}