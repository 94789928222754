import { API } from 'aws-amplify';

import moment from '@/common/momentConfig';

import {requestServiceOfferings, requestServiceOfferingsSuccess} from "../services/actions";


export const ADD_UPDATE_TRIGGER_EVENT = 'ADD_UPDATE_TRIGGER_EVENT';

export const GET_RULES = 'GET_RULES';
export const requestRules = () => dispatch => {
    dispatch({
        type : GET_RULES
    });
}

export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS';
export const requestRulesSuccess = (json) => dispatch => {
    dispatch({
        type : GET_RULES_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_SINGLE_RULE = 'GET_SINGLE_RULE';
export const requestSingleRule = (ruleId) => dispatch => {
    dispatch({
        type : GET_SINGLE_RULE,
        payload: ruleId
    });
}

export const GET_SINGLE_RULE_SUCCESS = 'GET_SINGLE_RULE_SUCCESS';
export const requestSingleRuleSuccess = (json) => dispatch => {
    dispatch({
        type : GET_SINGLE_RULE_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_RULE_VERSIONS = 'GET_RULE_VERSIONS';
export const requestRuleVersions = () => dispatch => {
    dispatch({
        type : GET_RULE_VERSIONS
    });
}

export const GET_RULE_VERSIONS_SUCCESS = 'GET_RULE_VERSIONS_SUCCESS';
export const requestRuleVersionsSuccess = (json) => dispatch => {
    dispatch({
        type : GET_RULE_VERSIONS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_ENCOUNTER_SCHEMA = 'GET_ENCOUNTER_SCHEMA';
export const requestEncounterSchema = () => dispatch => {
    dispatch({
        type : GET_ENCOUNTER_SCHEMA
    });
}

export const GET_ENCOUNTER_SCHEMA_SUCCESS = 'GET_ENCOUNTER_SCHEMA_SUCCESS';
export const requestEncounterSchemaSuccess = (json) => dispatch => {
    dispatch({
        type : GET_ENCOUNTER_SCHEMA_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_RULE_TRIGGER_COUNTS = 'GET_RULE_TRIGGER_COUNTS';
export const requestRuleTriggerCounts = () => dispatch => {
    dispatch({
        type : GET_RULE_TRIGGER_COUNTS
    });
}

export const GET_RULE_TRIGGER_COUNTS_SUCCESS = 'GET_RULE_TRIGGER_COUNTS_SUCCESS';
export const requestRuleTriggerCountsSuccess = (json) => dispatch => {
    dispatch({
        type : GET_RULE_TRIGGER_COUNTS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_QUEUED_RULE_TRIGGERS = 'GET_QUEUED_RULE_TRIGGERS';
export const requestQueuedRuleTriggers = () => dispatch => {
    dispatch({
        type : GET_QUEUED_RULE_TRIGGERS
    });
}

export const GET_QUEUED_RULE_TRIGGERS_SUCCESS = 'GET_QUEUED_RULE_TRIGGERS_SUCCESS';
export const requestQueuedRuleTriggersSuccess = (json) => dispatch => {
    dispatch({
        type : GET_QUEUED_RULE_TRIGGERS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_SENT_RULE_TRIGGERS = 'GET_SENT_RULE_TRIGGERS';
export const requestSentRuleTriggers = () => dispatch => {
    dispatch({
        type : GET_SENT_RULE_TRIGGERS
    });
}

export const GET_SENT_RULE_TRIGGERS_SUCCESS = 'GET_SENT_RULE_TRIGGERS_SUCCESS';
export const requestSentRuleTriggersSuccess = (json) => dispatch => {
    dispatch({
        type : GET_SENT_RULE_TRIGGERS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const DELETE_RULE = 'DELETE_RULE';
export const requestDeleteRule = () => dispatch => {
    dispatch({
        type : DELETE_RULE
    });
}

export const DELETE_RULE_SUCCESS = 'DELETE_RULE_SUCCESS';
export const requestDeleteRuleSuccess = (json) => dispatch => {
    dispatch({
        type : DELETE_RULE_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function fetchQueuedTriggers(page){
    return function(dispatch) {
        dispatch(requestQueuedRuleTriggers())

        return API.get('Core', '/api/v1/rule/trigger/queue', {
            queryStringParameters: {
                page: page
            }
        })
        .then(response => {
            dispatch(requestQueuedRuleTriggersSuccess(response));
        },
        error => {
            console.log('An error occurred.', error)
        })
    }
}

export function fetchSentTriggers(page, search){
    return function(dispatch) {
        dispatch(requestSentRuleTriggers())

        return API.get('Core', '/api/v1/rule/trigger/sent', {
            queryStringParameters: {
                page: page,
                search: search
            }
        })
        .then(response => {
            dispatch(requestSentRuleTriggersSuccess(response));
        },
        error => {
            console.log('An error occurred.', error)
        })
    }
}

export function fetchTodayRuleTriggerCounts() {
    let now = moment().utc();
    let nowEnd = moment().utc();
    let startDate = now.startOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    let endDate = nowEnd.endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    return fetchRuleTriggerCounts(startDate, endDate);
}

export function fetchRuleTriggerCounts(startDate, endDate){
    return function(dispatch) {
        dispatch(requestRuleTriggerCounts())

        return API.get('Core', '/api/v1/rule/trigger/count', {
            queryStringParameters: {
                startDate: startDate,
                endDate: endDate
            }
        })
            .then(response => {
                dispatch(requestRuleTriggerCountsSuccess(response));
            },
                error => {
                    console.log('An error occurred.', error)
                })
    }
}

export function fetchAllRules() {

    return function(dispatch) {

        dispatch(requestRules())

        return API.get('Core', '/api/v1/rule')
            .then(response => {
                    dispatch(requestRulesSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchSingleRule(ruleId) {

    return function(dispatch) {

        dispatch(requestSingleRule(ruleId))

        return API.get('Core', '/api/v1/rule/' + ruleId)
            .then(response => {
                    dispatch(requestSingleRuleSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function deleteRule(ruleId) {

    return function(dispatch) {

        dispatch(requestDeleteRule())

        return API.del('Core', '/api/v1/rule/' + ruleId)
            .then(response => {
                    dispatch(fetchAllRules())
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchRuleVersions(ruleId) {

    return function(dispatch) {

        dispatch(requestRuleVersions())

        return API.get('Core', '/api/v1/rule/' + ruleId + '/version')
            .then(response => {
                    dispatch(requestRuleVersionsSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchEncounterSchema() {

    return function(dispatch) {

        dispatch(requestEncounterSchema())

        return API.get('Core', '/api/v1/encounter/schema')
            .then(response => {
                    dispatch(requestEncounterSchemaSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}