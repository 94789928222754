import React from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core";
import {API} from "aws-amplify";

import Modal from "@/common/Modal";
import ConfirmModal from "@/common/ConfirmModal";
import {fetchConversationDispositions} from "@/shared/conversation/actions";
import AddEditDisposition from "@/components/AddEditDisposition";
import {fetchAllWorkflows} from "@/shared/workflow/actions";
import {fetchAllTaskTypes} from "@/shared/tasks/actions";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getDispositions : () => dispatch(fetchConversationDispositions()),
    getWorkflows : () => dispatch(fetchAllWorkflows()),
    getTaskTypes : () => dispatch(fetchAllTaskTypes())
})

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const columns = [
    { id: 'name', label: 'Disposition Name', minWidth: 170 }
];

function DispositionIndex( props ){
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState({});

    React.useEffect(() => {
        props.getDispositions();
        props.getWorkflows();
        props.getTaskTypes();
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDispositionAdd = (dispoId) => {
        setOpen(false);
        props.getDispositions();
    };

    const handleEditClose = (dispoId) => {
        editOpen[dispoId] = false;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditOpen = (dispoId) => {
        editOpen[dispoId] = true;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditSuccess = (dispoId) => {
        editOpen[dispoId] = false;
        setEditOpen(Object.assign({}, editOpen));
        props.getDispositions();
    }

    const deleteDispostion = (dispostionId) => {
        API.del('Core', '/api/v1/conversation/disposition/' + dispostionId)
            .then(response => {
                    props.getDispositions();
                    toast("Deleted disposition!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                },
                error => {
                    toast("Could not delete disposition" + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Modal
                    button={(<Button variant="contained" color="primary" className={classes.button}>
                        Add Disposition
                    </Button>)}
                    content={(<AddEditDisposition
                        allWorkflows={props.workflow.allWorkflows}
                        allTaskTypes={props.tasks.taskTypes}
                        onCancel={handleClose}
                        onSuccess={handleDispositionAdd}
                    />)}
                    title="Add Disposition"
                    size="large"
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    open={open}
                />
            </Grid>
            <Grid xs={12}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.conversation.conversationDispositions.map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns.map(column => {
                                        let value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key={'edit'}>
                                        <Modal
                                            button={(<Button variant="contained" color="primary" className={classes.button}>
                                                Edit
                                            </Button>)}
                                            content={(<AddEditDisposition
                                                existingDisposition={row}
                                                allWorkflows={props.workflow.allWorkflows}
                                                allTaskTypes={props.tasks.taskTypes}
                                                onCancel={handleEditClose.bind(this,row.id)}
                                                onSuccess={handleEditSuccess.bind(this, row.id)}
                                            />)}
                                            title="Edit Disposition"
                                            size="large"
                                            handleClose={handleEditClose.bind(this, row.id)}
                                            handleOpen={handleEditOpen.bind(this, row.id)}
                                            open={editOpen[row.id]}
                                        />
                                        <ConfirmModal
                                            title={"Delete Disposition"}
                                            text={(<span>Are you sure you want to delete <b>{row.name}</b>?</span>)}
                                            onConfirm={deleteDispostion.bind(this, row.id)}
                                            confirmTitle={"Delete Disposition"}
                                        >
                                            <Button variant="contained" color="primary" className={classes.button}>
                                                Delete
                                            </Button>
                                        </ConfirmModal>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DispositionIndex);
