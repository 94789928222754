import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { API } from 'aws-amplify';

import {
    fetchAllTasks,
    fetchAllTaskTypes,
    postTask,
    putTask,
    postTaskType,
    fetchAllTaskActionTypes
} from "@/shared/tasks/actions";
import { startConversation } from "@/shared/conversation/actions";
import { fetchAllWorkflows } from "@/shared/workflow/actions";

import TasksOverviewList from "./TasksOverviewList";
import AddTask from "./AddTask";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getTasks: (status, assignee, search, taskType, page) => dispatch(fetchAllTasks(status, assignee, search, taskType, page)),
    getTaskTypes: () => dispatch(fetchAllTaskTypes()),
    addNewTask: (newTask, status, assignee) => dispatch(postTask(newTask, status, assignee)),
    updateTask: (updatedTask, status, assignee) => dispatch(putTask(updatedTask, status, assignee)),
    addNewTaskType: (newTaskType) => dispatch(postTaskType(newTaskType)),
    loadWorkflows: () => dispatch(fetchAllWorkflows()),
    startConversationFromTask: (workflowId, phoneNumber, encounter) => dispatch(startConversation(workflowId, phoneNumber, encounter)),
    fetchTaskActionTypes: () => dispatch(fetchAllTaskActionTypes())
})

function TasksIndex({ ...props }) {
    const [page, setPage] = React.useState(0);
    const [taskType, setTaskType] = React.useState('');
    const [status, setStatus] = React.useState('OPEN');
    const [assignee, setAssignee] = React.useState(props.user.aboutMe.user ? props.user.aboutMe.user.id : '');

    const [customFilters, setCustomFilters] = React.useState({
        'assignee': {
            displayName: 'Assignee',
            selected: false,
            placeholder: 'Search by assignee',
            options: [],
            onChange: e => { setAssignee(e.target.value) },
            clear: () => setAssignee(null),
            value: assignee,
        },
        'status': {
            displayName: 'Status',
            selected: false,
            placeholder: 'Search by status',
            options: [
                {
                    key: "OVERDUE",
                    value: "Overdue"
                },
                {
                    key: "IN_PROGRESS",
                    value: "In Progress"
                },
                {
                    key: "OPEN",
                    value: "Upcoming"
                },
                {
                    key: "CLOSED",
                    value: "Closed"
                }
            ],
            onChange: e => setStatus(e.target.value),
            clear: () => setStatus(null),
            value: status,
        },
        'taskType': {
            displayName: 'Task type',
            selected: false,
            placeholder: 'Search by task type',
            options: props?.tasks?.taskTypes?.map(t => { return { key: t.id, value: t.name } }),
            onChange: e => setTaskType(e.target.value),
            clear: () => setTaskType(null),
            value: taskType,
        }
    });

    React.useEffect(() => {
        props.getTaskTypes();
        props.loadWorkflows();
        props.fetchTaskActionTypes();
        fetchAssignees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        const CUSTOM_FILTERS = { ...customFilters };
        CUSTOM_FILTERS['taskType'].options = props?.tasks?.taskTypes?.map(t => { return { key: t.id, value: t.name } });

        setCustomFilters(CUSTOM_FILTERS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tasks.taskTypes])

    React.useEffect(() => {
        props.getTasks(status, assignee, null, taskType, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, assignee, taskType, page])

    const handlePageChange = (event, page) => setPage(page - 1);

    const fetchAssignees = () => {
        API.get('Core', '/api/v1/users/provider')
            .then(providers => {
                const CUSTOM_FILTERS = { ...customFilters };
                CUSTOM_FILTERS['assignee'].options = providers.map(p => { return { key: p.id, value: `${p.firstName} ${p.lastName}` } })

                setCustomFilters(CUSTOM_FILTERS);
            })
    }

    const onConversationStarted = (workflow, number, encounter) => {
        props.startConversationFromTask(workflow.id, number, encounter);
        const win = window.open('/conversations/workflow/' + workflow.id + '/conversations/', "_blank");
        win.focus();
    }

    const onSourceConversationView = (workflow, conversation) => {
        const win = window.open('/conversations/workflow/' + workflow.id + '/conversations/' + conversation.id, "_blank");
        win.focus();
    }

    const onAddTaskSubmit = (newTask) => {
        props.addNewTask(newTask, status, assignee);
    }

    const onUpdateTaskSubmit = (updateTask) => {
        props.updateTask(updateTask, status, assignee);
    }

    return (
        <div className="rootView tasksView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="viewName">Tasks</div>
                    <div className="counterBadge">
                        <div className="counterValue">{props.tasks.tasks.content ? props.tasks.tasks.content.length : ""}</div>
                    </div>
                </div>

                <div className="navActions">
                    {
                        Object.keys(customFilters).filter(k => customFilters[k].options?.length).map((f, i) => {
                            return (<div key={`tasksCustomFilter${i}`} className={`searchInput action `}>

                                <select className='taskCustomFilterSelect' placeholder={customFilters[f]?.placeholder} onChange={e => customFilters[f].onChange(e)}
                                    value={f === 'assignee' ? assignee : (f === 'status' ? status : taskType)}>
                                    <option value='' label='ALL'>ALL</option>
                                    {customFilters[f]?.options?.map((o, j) => <option key={`tasksCustomFilter${i}-option${j}`} label={o.value} value={o.key}>{o.value}</option>)}
                                </select>

                            </div>);
                        })
                    }
                    <AddTask props={props} taskTypes={props.tasks.taskTypes} onAdd={onAddTaskSubmit}>
                        <div className="add action">
                            <div className="icon" />
                            <div className="title">Add Task</div>
                        </div>
                    </AddTask>
                </div>

                <div className="separator"></div>
            </div>

            <div className="scrollView">
                <TasksOverviewList
                    props={props}
                    onChange={onUpdateTaskSubmit}
                    onConversationStarted={onConversationStarted}
                    onViewSourceConversation={onSourceConversationView}
                    handlePageChange={handlePageChange}
                    currentPage={page}
                    totalPages={props?.tasks?.totalPages}
                />
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TasksIndex));