import React from 'react';
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import Select from "react-select";
import _ from "lodash";
import { withRouter } from 'react-router';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from "@material-ui/core/IconButton";

import InputCell from "@/common/form/InputCell";
import ConfirmModal from "@/common/ConfirmModal";
import FileDownload from '@/common/FileDownload';
import { filterGroupWorkflowType, listHasDuplicates } from '@/utils/utils';
import {TrashIcon} from "@/icons/Icons";
import InlineRemove from "@/common/InlineRemove";

import TeamList from "../team/TeamList";

function EditResponseGroup(props) {
    var { editWorkflow } = props;
    const [workflow, setWorkflow] = React.useState(editWorkflow);

    const [editCount, setEditCount] = React.useState(0);

    const [searchFocused, setSearchFocused] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState("");
    const searchRef = React.createRef();
    const [search, setSearch] = React.useState("");
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [size, setSize] = React.useState(50);
    const [providerRotations, setProviderRotations] = React.useState([]);
    const [selectedProviderRotation, setSelectedProviderRotation] = React.useState({});

    const [workflowTypeOptions, setWorkflowTypeOptions] = React.useState([]);
    const [conversationIdleCheck, setConversationIdleCheck] = React.useState(false);

    const locationRef = React.createRef();
    const titleRef = React.createRef();
    const recommendedLengthRef = React.createRef();
    const defaultGreetingRef = React.createRef();
    const autoReplyDelayTimeRef = React.createRef();
    const conversationIdleMinutesRef = React.createRef();

    React.useEffect(() => {
        loadProviderRotations();

        API.get('Core', '/api/v1/workflow/types')
            .then((response) => {
                setWorkflowTypeOptions(response
                    .filter(filterGroupWorkflowType)
                    .map(type => ({
                    value: type.name,
                    label: type.displayName
                })))
            })
    }, [])

    React.useEffect(() => {
        if (editWorkflow) {
            setSelectedUsers(editWorkflow.stateExpirationNotifyUsers);
            setSelectedProviderRotation(editWorkflow.providerRotation);

            setConversationIdleCheck(!!editWorkflow.conversationIdleMinutes);
        }
    }, [editWorkflow])

    const loadProviderRotations = () => {
        API.get("Core", "/api/v1/provider-rotation")
            .then(res => {
                setProviderRotations(res);
            }, err => {
                toast("Could not load provider rotations: " + err.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            })
    }

    const onCancel = () => {
        props.history.goBack();
    }

    const saveWorkflow = () => {
        workflow.name = titleRef.current.value;
        workflow.videoRecommendedLengthMinutes = recommendedLengthRef.current.value;
        workflow.stateExpirationNotifyUsers = selectedUsers;
        workflow.providerRotation = selectedProviderRotation;
        workflow.smsConfig.defaultGreeting = defaultGreetingRef.current.value;
        workflow.smsConfig.autoReplyDelayTime = autoReplyDelayTimeRef.current.value;
        workflow.conversationIdleMinutes = conversationIdleCheck ? conversationIdleMinutesRef.current.value : null;

        const SHORT_CODE_REGEX = /@[A-Z0-9]*/g;

        
        if(workflow.smsConfig.qrcodes.some(c => c.label && !c.code))
        {
            toast("Code can't be empty", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }
        
        workflow.smsConfig.qrcodes = workflow.smsConfig.qrcodes.filter(c => c.code)

        if(workflow.smsConfig.qrcodes.some(c => !c.code.match(SHORT_CODE_REGEX)))
        {
            toast("Code must start with @ and contains only letters and numbers", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }
        if (listHasDuplicates(workflow.smsConfig.qrcodes.map(c => c.code))) {
            toast("Code can't be repeated", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }
        if (conversationIdleCheck && !workflow.conversationIdleMinutes) {
            toast("Fill in the minutes for Idle Conversation", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }

        API.put('Core', '/api/v1/workflow/' + workflow.id, { body: workflow })
            .then(response => {
                if (response?.smsConfig?.qrcodes) {
                    response.smsConfig.qrcodes.forEach(qrcode => qrcode.storedCode = qrcode.code);
                }
                onSuccess();
            },
                error => {
                    toast("Could not save comm group: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const onStateNameChange = (rank, e) => {
        const updatedStates = workflow.states.map(state => {return state.rank === rank ?  {...state, name: e.target.value} : state});
        setWorkflow({...workflow, states: updatedStates});
    }

    const onStateMaxTimeChange = (rank, e) => {
        const updatedStates = workflow.states.map(state => {return state.rank === rank ?  {...state, maxSecondsElapsed: e.target.value} : state})
        setWorkflow({...workflow, states: updatedStates});
    }

    const onConvoVisibleTimeChange = (rank, e) => {
        const updatedStates = workflow.states.map(state => {return state.rank === rank ?  {...state, conversationVisibleMinutes: e.target.value * 1440} : state})
        setWorkflow({...workflow, states: updatedStates});
    }

    const onStateAutoAdvanceChange = (rank, e) => {
        const updatedStates = workflow.states.map(state => {return state.rank === rank ?  {...state, autoAdvance: !state.autoAdvance} : state})
        setWorkflow({...workflow, states: updatedStates});
    }

    const onStateClosedChange = (rank, e) => {
        const updatedStates = workflow.states.map(state =>
        {
            return state.rank === rank ?
                {
                    ...state,
                    closedState: !state.closedState,
                    conversationVisibleMinutes: (!state.closedState ? state.conversationVisibleMinutes : null)
                } : state})
        setWorkflow({...workflow, states: updatedStates});
    }

    const _getOrderedStates = () => {
        return _.sortBy(workflow.states, function (s) {
            return s.rank;
        }).filter(function(s) {
            return !s.deleted;
        })
    }

    const addState = () => {
        let allStates = _getOrderedStates();
        let state = {
            name: '',
            rank: allStates.length,
            autoAdvance: 0,
            closedState: 0
        }
        allStates.push(state);
        setWorkflow({...workflow, states: allStates});
        setEditCount(editCount + 1);
    }

    const removeState = (idx) => {
        let allStates = _getOrderedStates();
        allStates.splice(idx, 1);
        _.forEach(allStates, function (rs, i) {
            rs.rank = i;
        });
        setWorkflow({...workflow, states: allStates});
        setEditCount(editCount + 1);
    }

    const setTextBackSettingValue = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setWorkflow({...workflow, textBackSetting: obj.value});
                setEditCount(editCount + 1);
                break;
        }
    }

    const onSelectWorkflowType = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setWorkflow({...workflow, type: obj.value});
                setEditCount(editCount + 1);
                break;
        }
    }

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            if (searchRef.current.value !== search) {
                setPage(1);
            }
            setSearch(searchRef.current.value);
        }
    }

    const isSelectedUser = (e) => {
        return (!_.isEmpty(selectedUsers) && _.find(selectedUsers, function (u) {
            return u.id == e.id;
        }));
    }

    const onUserSelect = (e) => {
        let selected = selectedUsers;
        if (isSelectedUser(e)) {
            let idx = _.findIndex(selected, function (u) {
                return u.id == e.id;
            })
            if (idx > -1) {
                selected.splice(idx, 1)
            }
        }
        else {
            selected.push(e);
        }
        setSelectedUsers([...selected]);
    }

    const setBotSettingValue = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setWorkflow({
                    ...workflow,
                    botEnabled: obj.value
                });
                break;
        }
    }
    const onSuccess = () => {
        toast("Comm group saved successfully ", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
    }

    const onDelete = () => {
        toast("Comm group deleted successfully ", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
    }

    const deleteWorkflow = () => {
        API.put('Core', '/api/v1/workflow/' + workflow.id, { body: {...workflow, deleted: true} })
            .then(response => {
                    props.history.push(`/settings/groups`);
                },
                error => {
                    toast("Could not delete comm group: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
        onDelete(workflow.id);
    }

    const toggleAutoStartVideo = () => {
        workflow.autoStartVideo = !workflow.autoStartVideo;
        setWorkflow(Object.assign({}, workflow));
    }

    const onProviderRotationChange = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setSelectedProviderRotation(obj);
                break;
            case "clear":
                setSelectedProviderRotation(null);
                break;
        }
    }

    const safeGetWorkflowSmsConfigQrCodeLabel = (index) => {
        const QR_CODES = workflow?.smsConfig?.qrcodes;
        if (!QR_CODES || QR_CODES.length === 0) return null;

        return QR_CODES[index || 0].label;
    }

    const addShortCode = () => {
        let newWorkflow = { ...workflow }
        newWorkflow.smsConfig.qrcodes.push({ label: null, code: null });
        setWorkflow(newWorkflow);
    }

    const updateQrCodeShortCode = (event, index) => {
        let newWorkflow = { ...workflow }
        newWorkflow.smsConfig.qrcodes[index].code = event.target.value;

        setWorkflow(newWorkflow);
    }

    const updateQrCodeLabel = (event, index) => {
        let newWorkflow = { ...workflow }
        newWorkflow.smsConfig.qrcodes[index].label = event.target.value;

        setWorkflow(newWorkflow);
    }

    const onChangeConversationIdleCheck = () => {
        setConversationIdleCheck(!conversationIdleCheck);
    }

    const onClickCopyQRCodeURL = (qrcode) => {
        const encodedSharedNumberHandle = workflow.smsConfig.sharedNumberHandle ? `@${workflow.smsConfig.sharedNumberHandle} ` : "";
        const body = `${encodeURIComponent(encodedSharedNumberHandle)}${encodeURIComponent(qrcode.code)}`
        const url = `sms://${workflow.smsConfig.phoneNumber};?&body=${body}`;
        navigator.clipboard.writeText(url);
        toast("URL copied to clipboard ", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
    }

    let opts = [{ value: "LIMITED", label: "Limited" }, { value: "OPEN", label: "Open" }];
    let selectedVal = _.find(opts, function (o) { return o.value == workflow.textBackSetting; });
    let botOpts = [{ value: true, label: "True" }, { value: false, label: "False" }];

    let description = "";
    switch (selectedVal.value) {
        case "LIMITED":
            description = "Only incoming messages in response from recently sent rules and active conversations are allowed to respond";
            break;
        case "OPEN":
            description = "Anybody can text this number to start a conversation";
            break;

    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"Comm Group Name"}
                            defaultValue={workflow.name}
                            required
                            id="ruleName"
                            label="Comm Group Name"
                            inputRef={titleRef}
                        />
                    </div>
                    <div className="tableSectionGroups targetResponseGroup">
                        <div className="sectionTitle">
                            <div className="title">Settings</div>
                        </div>
                        <div className="tableCell titledSelectCell">
                            <div className="title">Text Back Setting</div>
                            <Select styles={{ width: "100px" }} className="selectCell workflowTextBackSetting" classNamePrefix="selectCell"
                                onChange={setTextBackSettingValue}
                                value={selectedVal}
                                options={opts}
                            />
                        </div>
                        <div className="tableCell">
                            <div className="title">Recommended Video Chat Length</div>
                            <InputCell
                                placeholder={"5"}
                                defaultValue={workflow.videoRecommendedLengthMinutes}
                                required
                                type={"number"}
                                minValue="0"
                                id="recommendedLength"
                                inputRef={recommendedLengthRef}
                            />
                            <div className="valueDescription">minutes</div>
                        </div>
                        <section className="shortCodesSection">
                            <div className="sectionTitle">QR Codes</div>
                            {
                                workflow.smsConfig.qrcodes.map((qrcode, index) => (
                                    <div key={`shortCodeInput${index}`} className="shortCodeInputs">
                                        <div className="tableCell shortCodeInput">
                                            <InputCell
                                                defaultValue={qrcode.code}
                                                required
                                                id="shortCodeHandle"
                                                label="Code"
                                                placeholder={"QR code"}
                                                onChange={e => updateQrCodeShortCode(e, index)}
                                            />
                                        </div>
                                        <div className="tableCell shortCodeInput">

                                            <InputCell
                                                placeholder={"Location label"}
                                                defaultValue={safeGetWorkflowSmsConfigQrCodeLabel(index)}
                                                value={safeGetWorkflowSmsConfigQrCodeLabel(index)}
                                                required
                                                id="ruleName"
                                                label="Label"
                                                inputRef={locationRef}
                                                onChange={e => updateQrCodeLabel(e, index)}
                                            />
                                        </div>
                                        <div className="shortCodeDownload">
                                            {(qrcode.id && qrcode.code) &&
                                            (
                                                qrcode.code === qrcode.storedCode ?
                                                <FileDownload path={`/api/v1/qrcode/${qrcode.id}/${workflow?.smsConfig?.phoneNumber}?sharedNumberHandle=${encodeURIComponent(workflow?.smsConfig?.sharedNumberHandle || "")}`}>
                                                    <CloudDownloadIcon className="downloadButton" />
                                                </FileDownload> : 
                                                <div>
                                                    <CloudDownloadIcon className="disabled" />
                                                </div>
                                            )
                                            }
                                        </div>
                                        <div className="copyQRCodeURLContainer">
                                            {(qrcode.id && qrcode.code) &&
                                            (
                                                qrcode.code === qrcode.storedCode ?
                                                <div onClick={() => onClickCopyQRCodeURL(qrcode)} className="copyQRCodeURLButton">
                                                    <FileCopyIcon />
                                                </div> : 
                                                <div>
                                                    <FileCopyIcon className="disabled" />
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>))
                            }

                            <div onClick={addShortCode} className="button tint large addShortCodeButton" onTouchEnd={() => addShortCode()}>
                                <div className="buttonTitle">Add QR Code</div>
                            </div>
                        </section>

                    </div>

                    <div className="tableSectionGroups sectionBot">
                        <div className="sectionTitle">Bot</div>
                        <div className="titledSelectCell tableCell">
                            <div className="title">Respond with Bot</div>
                            <Select styles={{ width: "100px" }} className="selectCell workflowTextBackSetting" classNamePrefix="selectCell"
                                onChange={setBotSettingValue}
                                value={botOpts.find(opt => opt.value === workflow.botEnabled)}
                                options={botOpts}
                            />
                        </div>
                        <p className="cellFootnote">{description}</p>
                    </div>

                    <div className="tableSectionGroups">
                        <div className="sectionTitle">Default Greeting</div>
                        <div className="defaultGreeting">
                            <textarea rows="8" id="textArt"
                                placeholder={"Default Greeting"}
                                defaultValue={(workflow.smsConfig && workflow.smsConfig.defaultGreeting ? workflow.smsConfig.defaultGreeting : "")}
                                ref={defaultGreetingRef}
                            />
                        </div>
                    </div>

                    <div className="tableSectionGroups">
                        <div className="tableCell">
                            <div className="title">Auto Reply Delay Time</div>
                            <InputCell
                                placeholder={""}
                                defaultValue={(workflow.smsConfig && workflow.smsConfig.autoReplyDelayTime ? workflow.smsConfig.autoReplyDelayTime : 0)}
                                type={"number"}
                                minValue="0"
                                id="autoReplyDelayTime"
                                inputRef={autoReplyDelayTimeRef}
                            />
                            <div className="valueDescription">minute(s)</div>
                        </div>
                    </div>

                    <div className="tableSectionGroups">
                        <div className="sectionTitle">Workflow</div>

                        <div className="tableCell titledSelectCell">
                            <div className="title">Type</div>
                            <Select styles={{ width: "100px" }}
                                className="selectCell" classNamePrefix="selectCell"
                                onChange={onSelectWorkflowType}
                                value={workflowTypeOptions.find(opt => opt.value === workflow.type)}
                                options={workflowTypeOptions}
                            />
                        </div>
                    </div>
                    <div className="workflowStepContainer tableSection">
                        {
                            _.map(_getOrderedStates(), function (state, i, states) {
                                return (
                                    <div key={state.rank + ":" + state.id + ":" + state.name + ":" + (state.autoAdvance ? "0" : "1")} className="workflowStep">
                                        <div className="stepContainer">
                                            <InputCell
                                                placeholder={""}
                                                defaultValue={state.name}
                                                required
                                                label={"State " + (state.rank + 1)}
                                                onBlur={onStateNameChange.bind(this,state.rank)}
                                            />

                                            <div className="tableRow">
                                                <div onClick={onStateClosedChange.bind(this, state.rank)} className={`tableCell switchCell ${state.closedState ? 'switchOn' : 'switchOff'}`}>
                                                    <div className="title">Closed State</div>
                                                    <div className={`accessory accessorySwitch ${state.closedState ? 'on' : 'off'}`}>
                                                        <div className="switchThumb" />
                                                    </div>
                                                </div>
                                                {
                                                    states.length === i+1 ? null:
                                                    <div onClick={onStateAutoAdvanceChange.bind(this, state.rank)} className={`tableCell switchCell ${state.autoAdvance ? 'switchOn' : 'switchOff'}`}>
                                                        <div className="title">Auto Advance</div>
                                                        <div className={`accessory accessorySwitch ${state.autoAdvance ? 'on' : 'off'}`}>
                                                            <div className="switchThumb" />
                                                        </div>
                                                    </div>
                                                }

                                            </div>

                                            {state.closedState ?
                                                <InputCell
                                                    placeholder={"0"}
                                                    defaultValue={state.conversationVisibleMinutes ? state.conversationVisibleMinutes / 1440 : 0}
                                                    type={"number"}
                                                    minValue="0"
                                                    maxValue="14"
                                                    id="stateDisappearTime"
                                                    label="Time to show conversation (days):"
                                                    onChange={onConvoVisibleTimeChange.bind(this, state.rank)}
                                                /> : null}

                                            <InputCell
                                                placeholder={"N/A"}
                                                defaultValue={state.maxSecondsElapsed}
                                                type={"number"}
                                                minValue="0"
                                                id="stateMaxTime"
                                                label="Max time in state (seconds):"
                                                onChange={onStateMaxTimeChange.bind(this, state.rank)}
                                            />

                                        </div>

                                        <InlineRemove title="Delete Workflow State"
                                                      text="Are you sure you would like to delete this Workflow State? Any conversations currently in this state will also be deleted."
                                                      confirmText="Delete State"
                                                      onRemoveConfirm={() => removeState(i)}>
                                            <IconButton
                                                size="small">
                                                <TrashIcon></TrashIcon>
                                            </IconButton>
                                        </InlineRemove>

                                    </div>
                                )
                            })
                        }
                        <div onClick={addState} className="button tint large addState">
                            <div className="buttonTitle">Add State</div>
                        </div>
                    </div>
                    <div className="tableSectionGroups">
                        <div className="tableCell titledSelectCell">
                            <div className="title">Rotation Group</div>
                            <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                onChange={onProviderRotationChange}
                                value={selectedProviderRotation ? selectedProviderRotation : "--"}
                                options={providerRotations}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                isClearable={true}
                            />
                        </div>
                    </div>

                    <div className="tableSectionGroups">
                        <div onClick={onChangeConversationIdleCheck} className={`tableCell switch switchCell ${conversationIdleCheck ? 'switchOn' : 'switchOff'}`}>
                            <div className="title">Check Idle Conversations</div>
                            <div className={`accessory accessorySwitch ${conversationIdleCheck ? 'on' : 'off'}`}>
                                <div className="switchThumb" />
                            </div>
                        </div>
                        {conversationIdleCheck &&
                            <div className="tableCell">
                                <div className="title">Consider Conversation as Idle After</div>
                                <InputCell
                                    placeholder={"60"}
                                    defaultValue={workflow.conversationIdleMinutes}
                                    type={"number"}
                                    minValue="1"
                                    id="conversationIdleMinutes"
                                    inputRef={conversationIdleMinutesRef}
                                />
                                <div className="valueDescription">minutes</div>
                            </div>
                        }
                    </div>

                    <div className="tableSectionGroups sectionNotifyGroup">
                        <div className="sectionTitle">Expired State Notification Settings</div>
                        <p className="cellFootnote">Selected team members can be notified if a conversation exceeds a configured max time in state.</p>
                        <div className={`searchInput tableSearch ${searchFocused ? "isFocused" : ""} ${(searchInput.length > 0) ? "hasInput" : ""}`}>
                            <div className="icon" />
                            <input value={searchInput} onChange={() => setSearchInput(searchRef.current.value)} placeholder={"Search All Personnel"} onFocus={() => setSearchFocused(true)} onBlur={() => setSearchFocused(false)} className="input" onKeyDown={keyPress} type="text" ref={searchRef} />
                            <div className="clear" onClick={() => { setSearch(""); setSearchInput(""); }}></div>
                        </div>
                        <TeamList
                            page={page}
                            size={size}
                            search={search}
                            handlePageChange={(e, page) => setPage(page)}
                            selectedUsers={selectedUsers}
                            onSelect={onUserSelect}
                            multipleSelect={true}
                        />
                        {
                            (selectedUsers && selectedUsers.length > 0) ? (
                                <div onClick={toggleAutoStartVideo} className={`autoStartVideochatSwitch tableCell switchCell ${workflow.autoStartVideo ? 'switchOn' : 'switchOff'}`}>
                                    <div className="title">Auto Start Video Chat</div>
                                    <div className={`accessory accessorySwitch ${workflow.autoStartVideo ? 'on' : 'off'}`}>
                                        <div className="switchThumb" />
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>

                </div>
            </div>
            <div className="actionBar">
                {workflow && !workflow.default ?
                    <div className="actions left">
                        <ConfirmModal
                            title={"Delete"}
                            text={"Are you sure you want to delete this workflow?"}
                            onConfirm={deleteWorkflow.bind(this)}
                            confirmTitle={"Delete Workflow"}
                        >
                            <div className="button destructive medium">
                                <div className="title">Delete</div>
                            </div>
                        </ConfirmModal>
                    </div> : null
                }
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveWorkflow} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>

    )
}

export default withRouter(EditResponseGroup)
