import React from 'react';
import Avatar from 'react-avatar';

import grey from '@material-ui/core/colors/grey';

const colors = [
    grey[400],
    grey[400]
];

export default function CustomAvatar(props) {
    var { name, size, textSizeRatio } = props;
    return (<Avatar maxInitials={2} size={size} textSizeRatio={textSizeRatio} round={true} color={Avatar.getRandomColor(name, colors)}
        name={name} />);
}