import { useDropzone } from 'react-dropzone';
import { toast } from "react-toastify";
import _ from "lodash";

export function GetDz(key, uploadFile, opts) {
    if (!opts) {
        opts = {};
    }

    let defaultOpts = {
        noClick: true,
        noKeyboard: true,
        multiple: true,
        accept: ['image/*', 'application/*', 'text/*', 'video/*'],
        onDropRejected: function (files, event) {
            toast("Could not upload file", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
        },
        onDropAccepted: function (files, event) {
            _.forEach(files, function (file, k) {
                uploadFile(key, file);
            })
        }
    };

    const finalOpts = Object.assign(defaultOpts, opts);
    return useDropzone(finalOpts)
}