import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core";
import {API} from "aws-amplify";
import AddPersonnelNotification from "../../../components/AddPersonnelNotification";
import Modal from "../../../common/Modal";
import PersonnelNotificationDetail from "./PersonnelNotificationDetail";
import FileDownload from "../../../common/FileDownload";

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const columns = [
    { id: 'message', label: 'Notification Message', minWidth: 170 },
    { id: 'numSent', label: '# Sent', minWidth: 170 },
    { id: 'numDelivered', label: '# Delivered', minWidth: 170 },
];

function PersonnelNotificationIndex( props ){
    const classes = useStyles();
    const [personnelNotifications, setPersonnelNotifications] = React.useState([]);
    const [detailsOpen, setDetailsOpen] = React.useState({});

    const handleDetailsClose = (notif) => {
        detailsOpen[notif.id] = false;
        setDetailsOpen(Object.assign({}, detailsOpen));
    };

    const handleDetailsOpen = (notif) => {
        detailsOpen[notif.id] = true;
        setDetailsOpen(Object.assign({}, detailsOpen));
    };

    const loadPersonnelNotifications = () => {
        API.get("Core", "/api/v1/personnel/notifications")
            .then(response => {
                    setPersonnelNotifications(response);
                },
                error => {
                    toast("Could not get personnel Notifications: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
    }

    React.useEffect(() => {
        loadPersonnelNotifications();
    }, [])


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <AddPersonnelNotification
                    button={(<Button variant="contained" color="primary" className={classes.button}>
                        Add Personnel Notification
                    </Button>)}
                    onSuccess={() => {loadPersonnelNotifications();}}
                />
            </Grid>
            <Grid xs={12}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {personnelNotifications.map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns.map(column => {

                                        let value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key={'edit'}>
                                        <Modal
                                            button={
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                >Details</Button>
                                            }
                                            content={
                                                <PersonnelNotificationDetail
                                                    notification={row}
                                                    onCancel={handleDetailsClose.bind(this, row)}
                                                />
                                            }
                                            title="NotificationDetail"
                                            size="small"
                                            handleClose={handleDetailsClose.bind(this, row)}
                                            handleOpen={handleDetailsOpen.bind(this, row)}
                                            open={detailsOpen[row.id] ?? false}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FileDownload path={"/api/v1/personnel/notifications/" + row.id + "/csv"}>
                                            <Button variant="contained" color="primary" className={classes.button}>
                                                Export Details
                                            </Button>
                                        </FileDownload>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default PersonnelNotificationIndex;
