import React from "react";
import { withRouter } from "react-router";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import Select from "react-select";
import _ from "lodash";

import InputCell from "@/common/form/InputCell";
import ConfirmModal from "@/common/ConfirmModal";
import Modal from "@/common/Modal";

import EditTenant from "./EditTenant";
import {useDispatch} from "react-redux";
import {refreshTenantFeatures} from '@/shared/user/actions';


function SupportSingleTenant(props) {
    const [tenantId] = React.useState(props.match.params.tenantId);
    const [tenant, setTenant] = React.useState(null);
    const [allDashboards, setAllDashboards] = React.useState([]);
    const [featureFlags, setFeatureFlags] = React.useState([]);
    const [tenantDashboards, setTenantDashboards] = React.useState([]);
    const [tenantRoles, setTenantRoles] = React.useState([]);
    const [editOpen, setEditOpen] = React.useState(false);
    const [selectedDashboard, setSelectedDashboard] = React.useState(null);
    const [dashboardDisplayName, setDashboardDisplayName] = React.useState("");
    const dispatch = useDispatch();

    const loadTenant = (tenantId) => {
        API.get('Core', '/api/v1/support-tools/tenants/' + tenantId)
            .then(response => {
                setTenant(Object.assign({}, response));
            },
                error => {
                    toast("Could not load tenants: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const loadTenantRoles = (tenantId) => {
        API.get('Core', '/api/v1/support-tools/tenants/' + tenantId + '/roles')
            .then(response => {
                setTenantRoles(Object.assign([], response));
            },
                error => {
                    toast("Could not load tenants: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const loadFeatureFlags = (tenantId) => {
        API.get('Core', '/api/v1/support-tools/feature-flags')
            .then(response => {
                setFeatureFlags(Object.assign([], response));
            },
                error => {
                    toast("Could not load tenants: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const assumeRole = (roleId) => {
        API.post('Core', '/api/v1/support-tools/tenants/' + tenantId + '/roles/' + roleId + '/assume')
            .then(response => {
                window.location = "/";
            },
                error => {
                    toast("Could not switch into role: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const loadAllDashboards = () => {
        API.get('Core', '/api/v1/analytics/dashboard/all')
            .then(response => {
                setAllDashboards(response);
            },
                error => {
                    toast("Could not load all dashboards: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                })
    }

    const loadTenantDashboards = (tenantId) => {
        API.get('Core', '/api/v1/analytics/dashboard/tenant/' + tenantId)
            .then(response => {
                setTenantDashboards(response);
            },
                error => {
                    toast("Could not load this tenants dashboards: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                })
    }

    const deleteTenantDashboard = (tenantId, dashboardId) => {
        API.del('Core', '/api/v1/analytics/dashboard/tenant/' + tenantId + '/dashboard/' + dashboardId)
            .then(response => {
                loadTenantDashboards(tenantId);
            },
                error => {
                    toast("Could not load this tenants dashboards: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                })
    }

    const addTenantDashboard = (tenantId) => {
        let dashReq = {
            dashboardId: selectedDashboard.dashboardId,
            name: dashboardDisplayName
        }
        API.post('Core', '/api/v1/analytics/dashboard/tenant/' + tenantId + '/dashboard', { body: dashReq })
            .then(response => {
                loadTenantDashboards(tenantId);
                setSelectedDashboard(null);
                setDashboardDisplayName("");
            },
                error => {
                    toast("Could not add dashboard: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                })
    }

    const onEditSuccess = () => {
        setEditOpen(false);
        loadTenant(tenantId);
    }

    const onSelectedDashChange = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setSelectedDashboard(obj);
                break;
        }
    }

    const onFeatureToggle = (feature) => {
        API.put('Core', '/api/v1/support-tools/tenants/' + tenant.id + '/toggle-feature/' + feature.name)
            .then(response => {
                toast("Successfully toggled feature: " + feature.name + "!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                loadTenant(tenantId);
                dispatch(refreshTenantFeatures())
            },
                error => {
                    toast("Could not toggle feature: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const onSysDashboardToggle = (dashboardId) => {
        API.put('Core', '/api/v1/analytics/dashboard/tenant/' + tenant.id + '/system-dashboard/' + dashboardId)
            .then(response => {
                toast("Successfully toggled dashboard!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                loadTenantDashboards(tenantId);
            },
                error => {
                    toast("Could not toggle dashboard: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    React.useEffect(() => {
        loadTenant(tenantId);
        loadTenantRoles(tenantId);
        loadAllDashboards();
        loadTenantDashboards(tenantId);
        loadFeatureFlags();
    }, [tenantId]);

    if (!tenant || !tenantRoles) {
        return (<span>Loading...</span>);
    }

    return (
        <div className="rootView surveysView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="title">TENANT: {tenant.name}</div>
                </div>
                <div className="actions">
                    <div className="">
                        <Modal
                            button={(<div className="button medium action">
                                <div className="buttonTitle">Edit Tenant</div>
                            </div>)}
                            content={(<EditTenant tenant={tenant} onSuccess={onEditSuccess} onCancel={() => setEditOpen(false)} />)}
                            title="Edit Tenant"
                            size="large"
                            handleClose={() => setEditOpen(false)}
                            handleOpen={() => setEditOpen(true)}
                            open={editOpen}
                        />
                    </div>
                </div>
                <div className="separator"></div>
            </div>
            <div className="scrollView">
                <div className="tableView">
                    <div className="tableSection">
                        <div className="sectionTitle">Tenant Roles</div>
                        {
                            _.map(_.sortBy(tenantRoles, function (r) {
                                return r.name;
                            }), function (role, k) {
                                return (
                                    <div key={role.id} className="tableCell">
                                        <div className="cellBody">
                                            <div className="title groupName">{role.name}</div>
                                            <div className={"detail"} style={{ textAlign: 'left' }}>
                                                <span>{role.privileges.length} permissions</span>
                                            </div>
                                        </div>
                                        <div className={"cellActions"}>
                                            <ConfirmModal
                                                title={"Assume Role"}
                                                text={(<span>Are you sure you want to assume <b>{role.name}</b> in <b>{tenant.name}</b>?</span>)}
                                                onConfirm={assumeRole.bind(this, role.id)}
                                                confirmTitle={"Assume Role"}
                                            >
                                                <div className="cellAction button small destructiveProminent">
                                                    <div className="title">Assume Role</div>
                                                </div>
                                            </ConfirmModal>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="tableSection">
                        <div className="sectionTitle">Feature Flags</div>
                        {
                            _.map(_.sortBy(featureFlags, function (f) {
                                return f.name;
                            }), function (feature, j) {
                                let active = _.findIndex(tenant.features, function (ft) { return ft.feature === feature.name; }) !== -1;
                                return (
                                    <div key={feature.name} className="tableCell">
                                        <div className="cellBody">
                                            <div className="title groupName">{feature.name}</div>
                                            <div className={"detail"} style={{ textAlign: 'left' }}>
                                                {feature.description}
                                            </div>
                                            <div style={{ cursor: 'pointer', display: 'block', position: 'absolute', top: '20px', right: '15px' }} onClick={onFeatureToggle.bind(this, feature)} className={`accessory accessorySwitch ${active ? 'on' : 'off'}`}>
                                                <div className="switchThumb" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="tableSection">
                        <div className="sectionTitle">Measure Dashboards</div>
                        {
                            _.map(_.sortBy(tenantDashboards, function (td) {
                                return td.systemDashboard;
                            }), function (dash, k) {
                                return (
                                    <div key={dash.dashboardId} className="tableCell">
                                        <div className="cellBody">
                                            <div className="title groupName">{dash.name} ({dash.dashboardId})</div>
                                            {
                                                dash.systemDashboard ? (
                                                    <div className={"detail"} style={{ textAlign: 'left' }}>
                                                        <span>SYSTEM DASHBOARD</span>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                dash.systemDashboard ? (
                                                    <div style={{ cursor: 'pointer', display: 'block', position: 'absolute', top: '20px', right: '15px' }} onClick={onSysDashboardToggle.bind(this, dash.dashboardId)} className={`accessory accessorySwitch ${dash.enabled ? 'on' : 'off'}`}>
                                                        <div className="switchThumb" />
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                        <div className={"cellActions"}>
                                            {
                                                !dash.systemDashboard ? (
                                                    <ConfirmModal
                                                        title={"Assume Role"}
                                                        text={(
                                                            <span>Are you sure you want to delete <b>{dash.name}</b>?</span>)}
                                                        onConfirm={() => deleteTenantDashboard(tenant.id, dash.dashboardId)}
                                                        confirmTitle={"Remove Dashboard"}
                                                    >
                                                        <div className="cellAction actionEdit button small action">
                                                            <div className="title">Remove Dashboard</div>
                                                        </div>
                                                    </ConfirmModal>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="tableCell" style={{ height: '400px' }}>
                            <div className="cellBody">
                                <InputCell
                                    placeholder={"... name of tab on Measure"}
                                    required
                                    id="displayName"
                                    label="Display Name"
                                    name="displayName"
                                    wrapperClasses={"addDashboardDisplayName"}
                                    onChange={(e) => { setDashboardDisplayName(e.target.value) }}
                                />
                                <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                    onChange={onSelectedDashChange}
                                    value={selectedDashboard ? selectedDashboard : "--"}
                                    options={_.filter(allDashboards, function (curDash) {
                                        let found = !_.find(tenantDashboards, function (td) { return td.dashboardId === curDash.dashboardId; })
                                        return found;
                                    })}
                                    getOptionLabel={option => option.name + " (" + option.dashboardId + ")"}
                                    getOptionValue={option => option.dashboardId}
                                />

                            </div>
                            <div className={"cellActions"}>
                                {
                                    <ConfirmModal
                                        title={"Add Dashboard"}
                                        text={(
                                            <span>Are you sure you want to add dashboard {selectedDashboard ? selectedDashboard.name : ""} ({selectedDashboard ? selectedDashboard.dashboardId : ""}) to measure with the tab name: <b>{dashboardDisplayName}</b>?</span>)}
                                        onConfirm={() => addTenantDashboard(tenant.id)}
                                        confirmTitle={"Add Dashboard"}
                                    >
                                        <div className="cellAction add button small action">
                                            <div className="title">Add Dashboard</div>
                                        </div>
                                    </ConfirmModal>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(SupportSingleTenant);