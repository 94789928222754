import { configurePusher } from 'pusher-redux';
import { API } from 'aws-amplify';

const options = { // options are... optional
    authorizer: function (channel, options) {
        return {
            authorize: function (socketId, callback) {
                API.get('Core', '/api/v1/realtime/auth', { queryStringParameters: { socket_id: socketId, channel_name: channel.name } })
                    .then(response => {
                        callback(false, response);
                    },
                        error => {
                            console.log("ERROR Authorizing Realtime:");
                            console.log(error);
                        })

            }
        };
    },
    forceTLS: true,
    cluster: window.__RUNTIME_CONFIG__.PUSHER_CLUSTER
}

export default function connectPusher(store) {
    configurePusher(store, window.__RUNTIME_CONFIG__.PUSHER_KEY, options);
}