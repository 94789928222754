import { API } from 'aws-amplify';
import _ from 'lodash';

import {throwGenericError} from "../transaction_state/actions";

export const NEW_MESSAGE_EVENT = 'NEW_MESSAGE_EVENT';
export const UPDATE_MESSAGE_EVENT = 'UPDATE_MESSAGE_EVENT';
export const UPDATE_CONVERSATION_EVENT = 'UPDATE_CONVERSATION_EVENT';
export const CONVERSATION_CHAT_ROOM_EVENT = 'CONVERSATION_CHAT_ROOM_EVENT';
export const TRANSACTION_KEY = "CONVERSATION";

export const GET_WORKFLOW_CONVERSATIONS = 'GET_WORKFLOW_CONVERSATIONS';
export const requestWorkflowConversations = () => dispatch => {
    dispatch({
        type : GET_WORKFLOW_CONVERSATIONS
    });
}

export const GET_WORKFLOW_CONVERSATIONS_SUCCESS = 'GET_WORKFLOW_CONVERSATIONS_SUCCESS';
export const requestWorkflowConversationsSuccess = (json) => dispatch => {
    dispatch({
        type : GET_WORKFLOW_CONVERSATIONS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_WORKFLOW_CONVERSATIONS_FAILURE = 'GET_WORKFLOW_CONVERSATIONS_FAILURE';
export const requestWorkflowConversationsFailure = () => dispatch => {
    dispatch({
        type : GET_WORKFLOW_CONVERSATIONS_FAILURE
    });
}

export const GET_CONVERSATION_MESSAGES = 'GET_CONVERSATION_MESSAGES';
export const requestConversationMessages = () => dispatch => {
    dispatch({
        type : GET_CONVERSATION_MESSAGES
    });
}

export const REFRESH_CONVERSATION_MESSAGES = 'REFRESH_CONVERSATION_MESSAGES';
export const refreshConversationMessages = () => dispatch => {
    dispatch({
        type : REFRESH_CONVERSATION_MESSAGES
    });
}

export const GET_CONVERSATION_MESSAGES_SUCCESS = 'GET_CONVERSATION_MESSAGES_SUCCESS';
export const requestConversationMessagesSuccess = (conversationId, json) => dispatch => {
    dispatch({
        type : GET_CONVERSATION_MESSAGES_SUCCESS,
        payload: json,
        conversationId: conversationId,
        receivedAt: Date.now()
    });
}

export const GET_CONVERSATION_MESSAGES_FAILURE = 'GET_CONVERSATION_MESSAGES_FAILURE';
export const requestConversationMessagesFailure = (conversationId, message) => dispatch => {
    dispatch({
        type : GET_CONVERSATION_MESSAGES_FAILURE,
        conversationId: conversationId,
        payload: message
    });
}

export const CLEAR_NEW_CONVERSATION = 'CLEAR_NEW_CONVERSATION';
export const clearNewConversation = () => dispatch => {
    dispatch({
        type : CLEAR_NEW_CONVERSATION
    });
}

export const POST_MESSAGE = 'POST_MESSAGE';
export const postMessage = () => dispatch => {
    dispatch({
        type : POST_MESSAGE
    });
}

export const POST_MESSAGE_SUCCESS = 'POST_MESSAGE_SUCCESS';
export const postMessageSuccess = (conversationId, json) => dispatch => {
    dispatch({
        type : POST_MESSAGE_SUCCESS,
        payload: json,
        conversationId: conversationId,
        receivedAt: Date.now()
    });
}

export const POST_MESSAGE_FAILURE = 'POST_MESSAGE_FAILURE';
export const postMessageFailure = (conversationId, message) => dispatch => {
    dispatch({
        type : POST_MESSAGE_FAILURE,
        conversationId: conversationId,
        payload: message
    });
}

export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const updateConversation = () => dispatch => {
    dispatch({
        type : UPDATE_CONVERSATION
    });
}

export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
export const addParticipantToConversation = () => dispatch => {
    dispatch({
        type : ADD_PARTICIPANT
    });
}

export const UPDATE_CONVERSATION_SUCCESS = 'UPDATE_CONVERSATION_SUCCESS';
export const updateConversationSuccess = (conversationId, json) => dispatch => {
    dispatch({
        type : UPDATE_CONVERSATION_SUCCESS,
        payload: json,
        conversationId: conversationId,
        receivedAt: Date.now()
    });
}

export const UPDATE_CONVERSATION_FAILURE = 'UPDATE_CONVERSATION_FAILURE';
export const updateConversationFailure = (conversationId, message) => dispatch => {
    dispatch({
        type : UPDATE_CONVERSATION_FAILURE,
        conversationId: conversationId,
        payload: message
    });
}

export const ADD_PARTICIPANT_SUCCESS = 'ADD_PARTICIPANT_SUCCESS';
export const addParticiapntSuccess = (conversationId, json) => dispatch => {
    dispatch({
        type : ADD_PARTICIPANT_SUCCESS,
        payload: json,
        conversationId: conversationId,
        receivedAt: Date.now()
    });
}

export const UPDATE_PARTICIPANT_SUCCESS = 'UPDATE_PARTICIPANT_SUCCESS';
export const updateParticipantSuccess = (conversationId, json) => dispatch => {
    dispatch({
        type : UPDATE_PARTICIPANT_SUCCESS,
        payload: json,
        conversationId: conversationId,
        receivedAt: Date.now()
    });
}

export const ADD_PARTICIPANT_FAILURE = 'ADD_PARTICIPANT_FAILURE';
export const addParticipantFailure = (conversationId, message) => dispatch => {
    dispatch({
        type : ADD_PARTICIPANT_FAILURE,
        conversationId: conversationId,
        payload: message
    });
}

export const GET_CONVERSATION = 'GET_CONVERSATION';
export const getConversation = () => dispatch => {
    dispatch({
        type : GET_CONVERSATION
    });
}

export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS';
export const getConversationSuccess = (conversationId, json) => dispatch => {
    dispatch({
        type : GET_CONVERSATION_SUCCESS,
        payload: json,
        conversationId: conversationId,
        receivedAt: Date.now()
    });
}

export const GET_CONVERSATION_FAILURE = 'GET_CONVERSATION_FAILURE';
export const getConversationFailure = (conversationId, message) => dispatch => {
    dispatch({
        type : GET_CONVERSATION_FAILURE,
        conversationId: conversationId,
        payload: message
    });
}

export const UPDATE_EDITOR_STATE = 'UPDATE_EDITOR_STATE';
export const updateEditorState = (newEditorState) => dispatch => {
    dispatch({
        type : UPDATE_EDITOR_STATE,
        payload: newEditorState
    });
}

export const GET_CONVERSATION_NOTES = 'GET_CONVERSATION_NOTES';
export const getConversationNotes = () => dispatch => {
    dispatch({
        type : GET_CONVERSATION_NOTES
    });
}

export const GET_CONVERSATION_NOTES_SUCCESS = 'GET_CONVERSATION_NOTES_SUCCESS';
export const getConversationNotesSuccess = (conversationId, json) => dispatch => {
    dispatch({
        type : GET_CONVERSATION_NOTES_SUCCESS,
        payload: json,
        conversationId: conversationId,
        receivedAt: Date.now()
    });
}

export const UPDATE_CONVERSATION_NOTES = 'UPDATE_CONVERSATION_NOTES';
export const updateConversationNotes = () => dispatch => {
    dispatch({
        type : UPDATE_CONVERSATION_NOTES
    });
}

export const UPDATE_CONVERSATION_NOTES_SUCCESS = 'UPDATE_CONVERSATION_NOTES_SUCCESS';
export const updateConversationNotesSuccess = (conversationId, json) => dispatch => {
    dispatch({
        type : UPDATE_CONVERSATION_NOTES_SUCCESS,
        payload: json,
        conversationId: conversationId,
        receivedAt: Date.now()
    });
}

export const GET_CONVERSATION_DISPOSITIONS = 'GET_CONVERSATION_DISPOSITIONS';
export const getConversationDispositions = () => dispatch => {
    dispatch({
        type : GET_CONVERSATION_DISPOSITIONS
    });
}

export const GET_CONVERSATION_DISPOSITIONS_SUCCESS = 'GET_CONVERSATION_DISPOSITIONS_SUCCESS';
export const getConversationDispositionsSuccess = (json) => dispatch => {
    dispatch({
        type : GET_CONVERSATION_DISPOSITIONS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_CONVERSATION_SERVICE_OFFERINGS = 'GET_CONVERSATION_SERVICE_OFFERINGS';
export const getConversationServiceOfferings = () => dispatch => {
    dispatch({
        type : GET_CONVERSATION_NOTES
    });
}

export const GET_CONVERSATION_SERVICE_OFFERINGS_SUCCESS = 'GET_CONVERSATION_SERVICE_OFFERINGS_SUCCESS';
export const getConversationServiceOfferingsSuccess = (conversationId, json) => dispatch => {
    dispatch({
        type : GET_CONVERSATION_SERVICE_OFFERINGS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const START_CONVERSATION = 'START_CONVERSATION';
export const startConversationBegin = () => dispatch => {
    dispatch({
        type : START_CONVERSATION
    });
}

export const START_CONVERSATION_SUCCESS = 'START_CONVERSATION_SUCCESS';
export const startConversationSuccess = (json) => dispatch => {
    dispatch({
        type : START_CONVERSATION_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const START_CONVERSATION_FAILURE = 'START_CONVERSATION_FAILURE';
export const startConversationFailure = (message) => dispatch => {
    dispatch({
        type : START_CONVERSATION_FAILURE,
        payload: message
    });
}

export const UPDATE_ACTIVE_WORKFLOW = 'UPDATE_ACTIVE_WORKFLOW';
export const updateActiveWorkflow = (activeWorkflow) => dispatch => {
    dispatch({
        type : UPDATE_ACTIVE_WORKFLOW,
        payload: activeWorkflow
    });
}

export const GET_ALL_CONVERSATIONS = 'GET_ALL_CONVERSATIONS';
export const getAllConversations = () => dispatch => {
    dispatch({
        type : GET_ALL_CONVERSATIONS
    });
}

export const GET_ALL_CONVERSATIONS_SUCCESS = 'GET_ALL_CONVERSATIONS_SUCCESS';
export const getAllConversationsSuccess = (json) => dispatch => {
    dispatch({
        type : GET_ALL_CONVERSATIONS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_CONVERSATION_VIDEO_CHATS = 'GET_CONVERSATION_VIDEO_CHATS';
export const getConversationVideoChats = () => dispatch => {
    dispatch({
        type : GET_CONVERSATION_VIDEO_CHATS
    });
}

export const GET_CONVERSATION_VIDEO_CHATS_SUCCESS = 'GET_CONVERSATION_VIDEO_CHATS_SUCCESS';
export const getConversationVideoChatsSuccess = (json) => dispatch => {
    dispatch({
        type : GET_CONVERSATION_VIDEO_CHATS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_ALL_CONVERSATIONS_FAILURE = 'GET_ALL_CONVERSATIONS_FAILURE';
export const getAllConversationsFailures = (message) => dispatch => {
    dispatch({
        type : GET_ALL_CONVERSATIONS_FAILURE,
        payload: message,
        receivedAt: Date.now()
    });
}


export const GET_ALL_INTERPRETERS = 'GET_ALL_INTERPRETERS';
export const getAllInterpreters = () => dispatch => {
    dispatch({
        type : GET_ALL_INTERPRETERS
    });
}

export const GET_ALL_INTERPRETERS_SUCCESS = 'GET_ALL_INTERPRETERS_SUCCESS';
export const getAllInterpretersSuccess = (json) => dispatch => {
    dispatch({
        type : GET_ALL_INTERPRETERS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_ALL_INTERPRETERS_FAILURE = 'GET_ALL_INTERPRETERS_FAILURE';
export const getAllInterpretersFailure = (message) => dispatch => {
    dispatch({
        type : GET_ALL_INTERPRETERS_FAILURE,
        payload: message,
        receivedAt: Date.now()
    });
}

export function fetchWorkflowConversations(id) {

    return function(dispatch) {

        dispatch(requestWorkflowConversations())

        return API.get('Core', '/api/v1/conversation/workflow/' + id)
            .then(response => {
                    dispatch(requestWorkflowConversationsSuccess(response))
                },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}

export function fetchConversationMessages(conversationId, refresh) {

    return function(dispatch) {

        if(refresh){
            dispatch(refreshConversationMessages());
        }
        else {
            dispatch(requestConversationMessages());
        }


        return API.get('Core', '/api/v1/conversation/' + conversationId + '/messages')
            .then(response => {
                    dispatch(requestConversationMessagesSuccess(conversationId, response))
                },
                error => {
                    dispatch(requestConversationMessagesFailure(conversationId, error.response.data.message));
                }
            );
    }
}

export function postConversationMessages(conversationId, message) {

    return function(dispatch) {
        dispatch(postMessage());

        return API.post('Core', '/api/v1/conversation/' + conversationId + '/messages', {body: message})
            .then(response => {
                    dispatch(postMessageSuccess(conversationId, response))
                },
                error => {
                    dispatch(postMessageFailure(conversationId, error.response.data.message));
                }
            );
    }
}

export function markConversationRead(conversationId){
    return function(dispatch) {
        return API.post('Core', '/api/v1/conversation/' + conversationId + '/mark-read');
    }
}

export function putConversation(conversationId, updateObj) {

    return function(dispatch) {
        dispatch(updateConversation());


        return API.put('Core', '/api/v1/conversation/' + conversationId, {body: updateObj})
            .then(response => {
                    dispatch(updateConversationSuccess(conversationId, response))
                },
                error => {
                    dispatch(updateConversationFailure(conversationId, error.response.data.message));
                }
            );
    }
}

export function addParticipant(conversationId, updateObj) {

    return function(dispatch) {
        dispatch(addParticipantToConversation());

        return API.post('Core', '/api/v1/conversation/' + conversationId + '/participants', {body: updateObj})
            .then(response => {
                    dispatch(addParticiapntSuccess(conversationId, response))
                },
                error => {
                    dispatch(addParticipantFailure(conversationId, error.response.data.message));
                }
            );
    }
}

export function startConversation(workflowId, phoneNumber, encounter) {

    return function(dispatch) {
        dispatch(startConversationBegin());


        return API.post('Core', '/api/v1/conversation/workflow/' + workflowId,
            {body:
                    {
                        phone: phoneNumber,
                        encounter: encounter
                    }
            })
            .then(response => {
                    dispatch(startConversationSuccess(response))
                },
                error => {
                    dispatch(startConversationFailure(error.response.data.message));
                }
            );
    }
}

export function fetchConversation(conversationId) {

    return function(dispatch) {
        dispatch(getConversation());


        return API.get('Core', '/api/v1/conversation/' + conversationId)
            .then(response => {
                    dispatch(getConversationSuccess(conversationId, response))
                },
                error => {
                    dispatch(getConversationFailure(conversationId, error.response.data.message));
                }
            );
    }
}

export function fetchConversationVideoChats(conversationId) {

    return function(dispatch) {
        dispatch(getConversationVideoChats());


        return API.get('Core', '/api/v1/conversation/' + conversationId + '/video-rooms')
            .then(response => {
                    dispatch(getConversationVideoChatsSuccess(response))
                },
                error => {

                }
            );
    }
}

export function fetchAllConversations() {

    return function(dispatch) {
        dispatch(getAllConversations());


        return API.get('Core', '/api/v1/conversation/all')
            .then(response => {
                    dispatch(getAllConversationsSuccess(response))
                },
                error => {
                    dispatch(getAllConversationsFailures(error.response.data.message));
                }
            );
    }
}

export function fetchConversationNotes(conversationId) {

    return function(dispatch) {
        dispatch(getConversationNotes());


        return API.get('Core', '/api/v1/conversation/' + conversationId + "/notes")
            .then(response => {
                    dispatch(getConversationNotesSuccess(conversationId, response))
                },
                error => {

                }
            );
    }
}

export function saveConversationNotes(conversationId, notes) {

    return function(dispatch) {
        dispatch(updateConversationNotes());


        return API.put('Core', '/api/v1/conversation/' + conversationId + "/notes", {body:notes})
            .then(response => {
                    dispatch(updateConversationNotesSuccess(conversationId, response))
                },
                error => {

                }
            );
    }
}

export function fetchConversationServiceOfferings(conversationId) {

    return function(dispatch) {
        dispatch(getConversationServiceOfferings());


        return API.get('Core', '/api/v1/conversation/' + conversationId + "/services")
            .then(response => {
                    dispatch(getConversationServiceOfferingsSuccess(conversationId, response))
                },
                error => {

                }
            );
    }
}

export function fetchConversationDispositions() {

    return function(dispatch) {
        dispatch(getConversationDispositions());


        return API.get('Core', '/api/v1/conversation/dispositions')
            .then(response => {
                    dispatch(getConversationDispositionsSuccess(response))
                },
                error => {

                }
            );
    }
}

export function fetchAllInterpreters() {

    return function(dispatch) {
        dispatch(getAllInterpreters());

        return API.get('Core', '/api/v1/interpreter')
            .then(response => {
                    dispatch(getAllInterpretersSuccess(response))
                },
                error => {

                }
            );
    }
}