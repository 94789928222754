import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { Button, makeStyles } from '@material-ui/core';
import { toast } from "react-toastify";

import TableDisplay from '@/common/TableDisplay';
import {
    deleteSingleSurvey,
    fetchAllSurveys, fetchSurveyImports,
    fetchSurveySendImportSchema,
    fetchSurveyStats
} from "@/shared/survey/actions";
import { fetchAllEncounters } from "@/shared/encounter/actions";
import { workflowTypes } from '@/utils/utils';
import exportFromJSON from "export-from-json";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getSurveys: () => dispatch(fetchAllSurveys()),
    getSurveyStats: () => dispatch(fetchSurveyStats()),
    deleteSurvey: (id) => dispatch(deleteSingleSurvey(id)),
    getSendSurveySchema: () => dispatch(fetchSurveySendImportSchema()),
    getImportsForSurvey: (surveyId) => dispatch(fetchSurveyImports(surveyId)),
    getEncounters: () => dispatch(fetchAllEncounters())
})

const useStyles = makeStyles(
    theme => ({
        exportCandidatesButton: {
            backgroundColor: '#4a77f3',
            textTransform: 'uppercase',
            color: 'white',
            minWidth: '153px',
            touchAction: 'manipulate',
            fontWeight: 400,
            fontFamily: 'Arial',
            letterSpacing: '1.1px',
            fontSize: '12px',
        }
    })
);

function CandidateIndex(props) {
    const [fields, setFields] = React.useState([]);
    const [candidates, setCandidates] = React.useState([]);

    const [filter, setFilter] = React.useState({});

    const CLASSES = useStyles();

    React.useEffect(() => {
        props.getSurveys();
        props.getSurveyStats();
        props.getSendSurveySchema();
        getCandidatesData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getConversationRecordsFields = fields => {

        let fieldsData = fields.reduce((acc, d) => {
            acc[d.id] = d;
            return acc;
        }, {});

        setFields(fields);

        let getConversationValues = fieldsValues => {
            let data = [];
            let conversationsPromises = [];

            for (let id in fieldsValues) {
                conversationsPromises.push(API.get('Core', `/api/v1/conversation-records/${id}/all`))
            }

            let processConversationData = conversationData => {
                data.push(fieldsValues[conversationData.conversationId].reduce((acc, d) => {
                    const FIELD = fieldsData[d.conversationRecordsFieldId];
                    acc[FIELD.fieldName] = d.value || '-';
                    return acc;
                }, { 'creationDate': new Date(Date.parse(conversationData.createdAt)) }));
            }

            Promise.all(conversationsPromises)
                .then(conversations => {
                    conversations.forEach(processConversationData);

                    // Function to add '+' to phone number if it's not present
                    const addPlusToPhoneNumber = (phoneNumber) => {
                        if (phoneNumber && phoneNumber.charAt(0) !== '+' && phoneNumber.charAt(0) !== '-') {
                            return `+${phoneNumber}`;
                        }
                        return phoneNumber;
                    };

                    const updatedData = data.map((item) => {
                        if (item["Phone Number"]) {
                            item["Phone Number"] = addPlusToPhoneNumber(item["Phone Number"]);
                        }
                        return item;
                    });

                    setCandidates(updatedData);
                });
        }

        API.get('Core', `/api/v1/conversation-records/fields/values`).then(getConversationValues);
    }

    const getCandidatesData = () => {
        API.get('Core', `/api/v1/conversation-records/fields?workflowType=${workflowTypes.APPLICANT}`).then(getConversationRecordsFields);
    }
    const downloadRecords = () => {
        const csvFileName="candidatesRecords";
        const exportType=exportFromJSON.types.csv;
        const cand=candidates.filter(filterCandidate);
        const formattedData = cand.map(item => {
            const creationDate = new Date(item.creationDate);
            const formattedCreationDate = creationDate.toLocaleString('en-US', {
                month: 'numeric', // mm
                day: 'numeric', // dd
                year: 'numeric', // yyyy
                hour: '2-digit', // hh
                minute: '2-digit', // mm
                second: '2-digit', // ss
                hour12: true, // AM/PM
            });
            delete item.creationDate;
            item.creationDate = formattedCreationDate;

            const fieldNamesArray = fields.map(item => item.fieldName);
            fieldNamesArray.push("creationDate");
            const reorderedItem = {};
            for (const key of fieldNamesArray) {
                reorderedItem[key] = item[key];
            }
            const modifiedObject = {};
            for (const key in reorderedItem) {
                if (key === "Bachelor’s Degree") {
                    modifiedObject["Bachelor\'s Degree"] = reorderedItem[key];
                } else if (key === "creationDate") {
                    modifiedObject["Creation date"] = reorderedItem[key];
                } else {
                    modifiedObject[key] = reorderedItem[key];
                }
            }

            return modifiedObject;
        });
        exportFromJSON(
            {
                data: formattedData,
                fileName: csvFileName,
                exportType
            }
        );
    }

    const updateFilter = (event, filterName) => {

        if (filterName === 'From date' || filterName === 'To date') {
            const TODAY = new Date();
            const AFTER_TOMORROW = new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() + 2);

            if (Date.parse(event.target.value) > AFTER_TOMORROW) {
                toast(`Invalid input for '${filterName}' filter (future date)`, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                return;
            }
        }

        const FILTER = { ...filter };

        FILTER[filterName] = event.target.value;

        setFilter(FILTER)
    }

    const filterCandidate = (c, i) => {
        let result = true;

        const FILTER_KEYS = ['Phone Number', 'Full Name']

        FILTER_KEYS.forEach(filterKey => {
            if (filter[filterKey]) {
                result &= c[filterKey]?.toString().toUpperCase().includes(filter[filterKey].toUpperCase());
            }
        })
        if (filter['From date']) {
            result &= (Date.parse(filter['From date']) <= c['creationDate'] || filter['From date'] <= c['creationDate']);
        }

        if (filter['To date']) {
            result &= (Date.parse(filter['To date']) >= c['creationDate'] || filter['To date'] >= c['creationDate']);
        }

        return result;
    }

    return (
        <div className="rootView surveysView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="title">Candidates</div>
                </div>
                <div className="actions">
                    <div className="">

                    </div>
                    {candidates.length > 0 && <div>
                        <Button onClick={downloadRecords} className={CLASSES.exportCandidatesButton}>
                            Export records
                        </Button>
                    </div>}
                </div>
                <div className="separator"></div>
            </div>
            <div className="scrollView">
                <div className="surveysList tableView">
                    <div className="tableSection">
                        <div className="candidatesHeader">
                            <div className="candidatesTitle">Your Candidates</div>
                            <div className='candidatesFilter'>

                                <input type={'text'} onFocus={e => e.target.type = 'date'} onChange={event => updateFilter(event, 'From date')} className='searchInput candidatesSearchInput ' placeholder='From date' />
                                <input type={'text'} onFocus={e => e.target.type = 'date'} onChange={event => updateFilter(event, 'To date')} className='searchInput candidatesSearchInput' placeholder='To date' />
                                <input onChange={event => updateFilter(event, 'Phone Number')} className='searchInput candidatesSearchInput' type={'tel'} placeholder={'Candidate phone'} />
                                <input onChange={event => updateFilter(event, 'Full Name')} className='searchInput candidatesSearchInput' type={'text'} placeholder={'Candidate Full Name'} />
                            </div>
                        </div>
                        <TableDisplay title={''} data={candidates.filter(filterCandidate)} columns={[...fields.map(f => {
                            return { field: f.fieldName, title: f.fieldName }
                        }), { field: 'creationDate', title: 'Creation date', render: e => (<span>{e.creationDate.toLocaleString()}</span>) }]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CandidateIndex));