import React from 'react';
import {API} from "aws-amplify";
import {toast} from "react-toastify";

import InputCell from "@/common/form/InputCell";

export default function AddEditArticleGroup(props){
    var { onCancel, onSuccess, onDelete, existingArticleGroup} = props;

    const titleRef = React.createRef();

    const saveArticleGroup = () => {
        if(existingArticleGroup){
            API.put('Core', '/api/v1/articles/' + existingArticleGroup.id, {body: {name: titleRef.current.value}})
                .then(response => {
                        onSuccess(response);
                    },
                    error => {
                        toast("Could not update ArticleGroup: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    }
                );
        }
        else{
            API.post('Core', '/api/v1/articles', {body: {name: titleRef.current.value}})
                .then(response => {
                        onSuccess(response);
                    },
                    error => {
                        toast("Could not create ArticleGroup: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    }
                );
        }
    }

    const deleteArticleGroup = () => {
        if(!existingArticleGroup){
            return;
        }

        API.del('Core', '/api/v1/articles/' + existingArticleGroup.id)
            .then(response => {
                    onDelete();
                },
                error =>{
                    toast("Could not delete article: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                });
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"Group Name"}
                            defaultValue={(existingArticleGroup ? existingArticleGroup.name : "")}
                            required

                            label="Group "
                            inputRef={titleRef}
                        />
                    </div>
                </div>
            </div>
            <div className="actionBar">
                {
                    existingArticleGroup ? (
                        <div className="actions left">
                            <div onClick={deleteArticleGroup} className="button destructive medium">
                                <div className="title">Delete</div>
                            </div>
                        </div>
                    ) : null
                }
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveArticleGroup} className={`button confirm medium`}>
<                       div className="title">Done</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>

)
}