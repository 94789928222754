import React from 'react';
import {connect} from "react-redux";
import {toast} from "react-toastify";
import _ from "lodash";

import moment from '@/common/momentConfig';
import {
    fetchConversation,
    fetchConversationMessages, fetchConversationServiceOfferings, fetchConversationVideoChats,
    markConversationRead,
    putConversation,
    addParticipant, fetchConversationDispositions, startConversation
} from "@/shared/conversation/actions";
import {canMarkAsReadConversations, canParticipateConversations} from "@/shared/access";
import {fetchAllTaskTypes} from "@/shared/tasks/actions";
import { fetchAllWorkflows } from '@/shared/workflow/actions';

import ConversationMessages from "./ConversationMessages";
import ConversationInput from "./ConversationInput";
import ConversationSidebar from "./ConversationSidebar";
import {decrypt} from "../../utils/utils";

const mapStateToProps = state => ({
    messages: state.conversation.activeConversationMessages,
    conversations: state.conversation.conversations,
    priorities: state.workflow.priorities,
    dispositions: state.conversation.conversationDispositions,
    serviceOfferings: state.conversation.activeServiceOfferings,
    videoChats: state.conversation.videoChats,
    ...state
})

const mapDispatchToProps = dispatch => ({
    getMessages: (conversationId) => dispatch(fetchConversationMessages(conversationId)),
    markRead: (conversationId) => dispatch(markConversationRead(conversationId)),
    updateConversation: (conversationId, updateConversation) => dispatch(putConversation(conversationId, updateConversation)),
    addParticipant: (conversationId, newParticipant) => dispatch(addParticipant(conversationId, newParticipant)),
    getConversation: (conversationId) => dispatch(fetchConversation(conversationId)),
    getConversationServiceOfferings: (conversationId) => dispatch(fetchConversationServiceOfferings(conversationId)),
    getVideoChats: (conversationId) => dispatch(fetchConversationVideoChats(conversationId)),
    getDispositions: () => dispatch(fetchConversationDispositions()),
    getTaskTypes : () => dispatch(fetchAllTaskTypes()),
    loadWorkflows : () => dispatch(fetchAllWorkflows()),
    startConversationFromTask: (workflowId, phoneNumber, encounter) => dispatch(startConversation(workflowId, phoneNumber, encounter)),
})

function ConversationView(props) {
    const {activeConversationId, messages, activeWorkflow, videoChats, dispositions, conversationListRef} = props;
    const [loadActionsCalled, setLoadActionsCalled] = React.useState(false);
    const [messageHeight, setMessageHeight] = React.useState("500px");
    const [sidebarMessageHeight, setSidebarMessageHeight] = React.useState("500px");
    const navBarRef = React.createRef();
    const chatRef = React.createRef();

    if(!loadActionsCalled){
        setLoadActionsCalled(true);
    }
    const TOKEN = window.__RUNTIME_CONFIG__.MAPBOX;
    const mapboxApiAccessToken = React.useMemo(() => {
        return decrypt(TOKEN);
    }, [TOKEN]);

    React.useEffect(() => {
        props.getDispositions();
        props.getTaskTypes();
        if(activeConversationId){
            props.getMessages(activeConversationId);
            props.getVideoChats(activeConversationId);
        }
    }, [activeConversationId]);

    React.useEffect(() => {
        if(activeConversationId && canMarkAsReadConversations()){
            props.markRead(activeConversationId)
            .then(response => {},
            error => {
                if (error.response) {
                    toast.error(error.response.data.message, {position: toast.POSITION.TOP_CENTER})
                }
            });
            let lastMessage = _.last(messages);
            if(lastMessage && (lastMessage.isBot || lastMessage.extensions)){
                props.getConversationServiceOfferings(activeConversationId);
            }

        }
    }, [messages]);

    React.useEffect(() => {
        updateChatDimensions();
        window.addEventListener('resize', updateChatDimensions);
        return _ => {
            window.removeEventListener('resize', updateChatDimensions)
        }
    });

    React.useEffect(() => {
        props.loadWorkflows();
    }, []);
      

    const onConversationStarted = (workflow, number) => {
        props.startConversationFromTask(workflow.id, number, null);
        conversationListRef.current.scrollTo({top: 0, behavior: 'smooth'});
    }

    const updateChatDimensions = () => {
        if(navBarRef.current && chatRef.current){
            let chatClientRect = chatRef.current.getBoundingClientRect();
            let navBarClientRect = navBarRef.current.getBoundingClientRect();
            setMessageHeight(chatClientRect.top - navBarClientRect.bottom);
            setSidebarMessageHeight(chatClientRect.bottom - navBarClientRect.bottom - 15);
        }
    }

    const reloadActiveConversation = () => {
        props.getConversation(activeConversationId);
    }

    let conversation = _.find(props.conversation.conversations, function(c){
        return c.id == activeConversationId;
    });
    if(conversation == null){
        return null;
    }

    let time = moment.utc(conversation.createdAt).fromNow();

    return (
        <div className="chatView">
            <div className="navBar" ref={navBarRef}>
                <div className="navTitle">
                    <div className="title">
                    {conversation.initiator.firstName} {conversation.initiator.lastName} {conversation.initiator.phone}
                    </div>
                    <div className="subtitle">conversation started {time}</div>
                </div>
                <div className="separator"></div>
            </div>
            <div className="chatContent">
                <div className="chatMessages">
                    <ConversationMessages messages={messages}
                                          loading={props.conversation.messagesLoading}
                                          loadError={props.conversation.messageLoadError}
                                          height={messageHeight}
                                          mapboxApiAccessToken={mapboxApiAccessToken}
                    />
                    {
                        canParticipateConversations() ? (
                            <ConversationInput chatInputBarRef={chatRef} activeConversationId={conversation.id}/>
                        ) : (<div className="chatInputBar" ref={chatRef}></div>)
                    }

                </div>
                <div className="chatContext fsMask">
                    <ConversationSidebar
                        conversation={conversation}
                        videoChats={videoChats}
                        priorities={props.priorities}
                        dispositions={props.dispositions}
                        reloadConversation={reloadActiveConversation}
                        workflowStates={(activeWorkflow ? activeWorkflow.states : [])}
                        height={sidebarMessageHeight}
                        onConversationUpdate={props.updateConversation}
                        onParticipantUpdate={props.addParticipant}
                        serviceOfferings={props.serviceOfferings}
                        user={props.user}
                        tasks={props.tasks}
                        onConversationStarted={onConversationStarted}
                        workflow={props.workflow}
                        activeWorkflow={activeWorkflow}
                        mapboxApiAccessToken={mapboxApiAccessToken}
                    />
                </div>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ConversationView);