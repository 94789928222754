import React, { useState } from 'react';
import { Button, Link, makeStyles, TextField } from "@material-ui/core";
import PasswordChecklist from 'react-password-checklist';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    updatePasswordContainer: {
        width: '100%',
    },
    cardTitle: {
        marginBottom: '24px',
        fontSize: '18px',
    },
    inputsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        marginBottom: '30px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    resendCodeButton: {
        color: '#FF9900',
        cursor: 'pointer',
        fontSize: '14px',
    },
    passwordChangeInput: {
        width: '100%',
    },
    sendChangePasswordRequest: {
        backgroundColor: '#FF9900',
        textTransform: 'uppercase',
        color: 'white',
        padding: '14px 0',
        minWidth: '153px',
        touchAction: 'manipulate',
        fontWeight: 400,
        fontFamily: 'Arial',
        letterSpacing: '1.1px',
        fontSize: '12px',
        lineHeight: '18px',
        borderRadius: 0,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#FF9900',
            color: 'white',
        }
    },
}));

export default function CustomChangePassword(props) {
    const CLASSES = useStyles();
    const [code, setCode] = useState(null);
    const [password, setPassword] = useState(null);
    const [validPassword, setValidPassword] = useState(false);

    const PASSWORD_MIN_LENGTH = 12;

    let updateValidPassword = isValid => setValidPassword(isValid)

    let resendCode = () => {
        Auth.forgotPassword(props.email);
    }

    let requestPasswordChange = () => {
        let sendPasswordReset = () => {

            Auth.forgotPasswordSubmit(props.email, code, password, { "verificationString": password })
                .then(r => {
                    toast("Password updated successfully", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                    props.onStateChange('signIn', {});
                })
                .catch(e => {
                    toast("Error updating password", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                    console.error('E -> ', e);
                })

        }

        if (password == null || !validPassword) {
            toast("Invalid password", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }


        if (!!code) {
            axios.post(`${window.__RUNTIME_CONFIG__.API_HOST}/u/users/validateNewPassword`, {
                userEmail: props.email,
                newPassword: password,
            })
                .then(r => {
                    if (!r.data.passwordValid) {
                        toast(r.data.errorMessage || "Invalid password", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                        return;
                    }
                    sendPasswordReset();
                })
        }
        else {
            toast("Code cannot be empty", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
        }

    }

    return (<div className={CLASSES.updatePasswordContainer}>

        <div className={`gatekeeperTitle ${CLASSES.cardTitle}`}>Reset your password</div>
        <fieldset className={CLASSES.inputsContainer}>

            <TextField id="requestResetPasswordCodeEmailInput"
                className={CLASSES.requestPasswordResetCodeInput}
                label="Code"
                placeholder='Code'
                variant='outlined'
                onChange={e => setCode(e.target.value)}
                required />

            <TextField id="requestResetPasswordCodeEmailInput"
                className={CLASSES.requestPasswordResetCodeInput}
                label="New Password"
                type='password'
                placeholder='New Password'
                variant='outlined'
                onChange={e => setPassword(e.target.value)}
                required />

            {password != null && <PasswordChecklist
                rules={["minLength"]}
                value={password}
                minLength={PASSWORD_MIN_LENGTH}
                onChange={updateValidPassword} 
                messages={{
                    minLength: `Passwords must contain at least ${PASSWORD_MIN_LENGTH} characters.`
                }}/>}

        </fieldset>
        <fieldset className={CLASSES.buttonsContainer}>
            <Link onClick={resendCode} className={CLASSES.resendCodeButton} variant='text'>Resend Code</Link>
            <Button onClick={requestPasswordChange} className={CLASSES.sendChangePasswordRequest}>Submit</Button>
        </fieldset>
    </div>);
}