import React from "react"

import { Button } from "@/components/Buttons"
import AddEditPersonnel from "@/components/AddEditPersonnel";
import { AssetPersonnelRoleCallTaker, AssetPersonnelRoleResponder } from "@/icons/Assets"

function PersonnelRole(props) {
    const { personnelRole } = props
    switch (personnelRole) {
        case 'RESPONDER':
            return (
                <div className="personnelRole">
                    <div className="subtitle roleName">Responder</div>
                </div>
            )
        case 'CALLTAKER':
            return (
                <div className="personnelRole">
                    <div className="subtitle roleName">Call Taker</div>
                </div>
            )
        case 'INVESTIGATOR':
            return (
                <div className="personnelRole">
                    <div className="subtitle roleName">Investigators</div>
                </div>
            )
        default:
            return (
                <div className="personnelRole">
                    <div className="subtitle roleName">{props.personnelRole}</div>
                </div>
            )
    }
}

function SatisfactionPersonnelCell(props) {
    return (
        <div onClick={(props.onCellClick ? props.onCellClick : null)} className="personnelCellClickable satisfactionPersonnelCell tableCell">
            <div className="cellBody personnelCellClickable">
                <div className="title personnelName personnelCellClickable">{props.personnelName}</div>
                <div className="subtitle personnelDetails personnelCellClickable">
                    <PersonnelRole personnelRole={props.personnelRole} />
                    <div className="personnelID">{props.personnelID}</div>
                    <div className="personnelEmail">{props.personnelEmail ? props.personnelEmail : "No Email"}</div>
                </div>
            </div>

            <div className="cellActions">
                <AddEditPersonnel button={(<Button
                    size="small"
                    title="Edit Personnel"
                    addClass={"edit-email-button"}
                />)} personnelId={props.internalPersonnelId} onSuccess={() => { props.onEmailUpdate(); }} />
            </div>

            <div className="cellDetails personnelResults">
                <div className="detailCell">
                    <div className="detailValue">{props.surveysCount}</div>
                    <div className="detailTitle">Total</div>
                </div>

                <div className="detailCell">
                    <div className="detailValue">{props.positiveFeedbackCount}</div>
                    <div className="detailTitle">Positive</div>
                </div>

                <div className="detailCell">
                    <div className="detailValue">{props.satisfactionScore}</div>
                    <div className="detailTitle">CPSS</div>
                </div>
            </div>
        </div>
    )
}

export default SatisfactionPersonnelCell
