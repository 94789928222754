import React from "react"
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import InputCell from "../../../common/form/InputCell";
import Modal from "../../../common/Modal";
import TeamList from "../team/TeamList";
import _ from "lodash";


export default function AddEditProviderRotation({button, onSuccess, providerRotationId}) {
    const [open, setOpen] = React.useState(false);
    const [providerRotation, setProviderRotation] = React.useState({});
    const nameRef = React.createRef();

    const [size, setSize] = React.useState(50);
    const [page, setPage] = React.useState(1);
    const [selectedUsers, setSelectedUsers] = React.useState([]);

    const [search, setSearch] = React.useState("");
    const [searchFocused, setSearchFocused] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState("");
    const searchRef = React.createRef();

    const onPageChange = (event, page) => {
        setPage(page);
    }

    const onSearchFocused = () => {
        setSearchFocused(true);
    }

    const onSearchBlurred = () => {
        setSearchFocused(false);
    }

    const onSearchChange = (e) => {
        setSearchInput(searchRef.current.value);
    }

    const keyPress = (e) => {
        if(e.keyCode == 13){
            if (searchRef.current.value !== search) {
                setPage(1);
            }
            setSearch(searchRef.current.value);
        }
    }

    const clearSearch = () => {
        setSearch("");
        setSearchInput("");
    }

    const handleEditClient = (client) => {
    }

    const isSelectedUser = (e) => {
        return (!_.isEmpty(selectedUsers) && _.find(selectedUsers, function(u) {
            return u.id == e.id;
        }));
    }

    const onUserSelect = (e) => {
        let selected = selectedUsers;
        if(isSelectedUser(e)){
            let idx = _.findIndex(selected, function (u) {
                return u.id == e.id;
            })
            if(idx > -1) {
                selected.splice(idx, 1)
            }
        }
        else{
            selected.push(e);
        }
        setSelectedUsers([...selected]);
    }

    React.useEffect(() => {
        if(open && providerRotationId){
            loadProviderRotation();
        }
    }, [open])

    React.useEffect(() => {
        if(providerRotation != null){
            setSelectedUsers(_.map(providerRotation.providerRotationMembers, function(prm) {return prm.providerUser}));
        }
    }, [providerRotation])

    const loadProviderRotation = () => {
        API.get("Core", "/api/v1/provider-rotation/" + providerRotationId)
            .then(response => {
                setProviderRotation(response);
            }, err => {
                toast("Could not load provider rotation: " + err.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            })
    }

    const createOrUpdate = () => {

        if(!nameRef.current.value){
            toast("Must set name", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            return;
        }

        let body = {
            name: nameRef.current.value,
            providerRotationMembers: _.map(selectedUsers, function(su) {return {providerUser: su}})
        }
        console.log(body);
        if(providerRotationId){
            API.put("Core", "/api/v1/provider-rotation/" + providerRotationId, {body: body})
                .then(response => {
                    onSuccess();
                    setOpen(false);
                }, err => {
                    toast("Could not update provider rotation: " + err.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
        }
        else{
            API.post("Core", "/api/v1/provider-rotation", {body: body})
                .then(response => {
                    onSuccess();
                    setOpen(false);
                }, err => {
                    toast("Could not create provider rotation: " + err.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
        }
    }

    return (
        <Modal
            button={button}
            content={(
                <div className="tableView">
                    <div className="content">
                        <div className="tableView">
                            <div className="tableSection sectionName">
                                <InputCell
                                    placeholder={""}
                                    defaultValue={providerRotation ? providerRotation.name : ""}
                                    required
                                    id="name"
                                    label="Name"
                                    inputRef={nameRef}
                                />
                            </div>
                        </div>
                        <div className="tableSection sectionNotifyGroup">
                            <div className="sectionTitle">
                                <span className="title">
                                    Provider Rotation
                                </span>
                            </div>
                            <div className={`searchInput tableSearch ${searchFocused ? "isFocused" : ""} ${(searchInput.length > 0) ? "hasInput" : ""}`}>
                                <div className="icon"/>
                                <input value={searchInput} onChange={onSearchChange} placeholder={"Search All Personnel"} onFocus={onSearchFocused} onBlur={onSearchBlurred} className="input" onKeyDown={keyPress} type="text" ref={searchRef}/>
                                <div className="clear" onClick={clearSearch}></div>
                            </div>
                            <TeamList
                                page={page}
                                size={size}
                                search={search}
                                handlePageChange={onPageChange}
                                onEditClient={handleEditClient}
                                selectedUsers={selectedUsers}
                                onSelect={onUserSelect}
                                multipleSelect={true}
                            />
                        </div>
                    </div>
                    <div className="actionBar">
                        <div className="actions">
                            <div onClick={() => setOpen(false)} className="button dismiss medium">
                                <div className="title">Cancel</div>
                            </div>

                            <div onClick={() => createOrUpdate()} className={`button confirm medium`}>
                                <div className="title">Save</div>
                            </div>
                        </div>

                        <div className="separator"></div>
                    </div>
                </div>
            )}
            title={providerRotationId ? "Edit Provider Rotation" : "Create Provider Rotation"}
            size="small"
            handleClose={() => setOpen(false)}
            handleOpen={() => setOpen(true)}
            open={open}
        />
    )
}