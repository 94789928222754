import { Auth } from 'aws-amplify';
import React from 'react';

function FileDownload({ path, ...props }) {

    const host = window.__RUNTIME_CONFIG__.API_HOST;
    const downloadAsPdf = async () => {
        let token = (await Auth.currentSession()).accessToken.jwtToken;
        let uri = host + path + `${!path.includes("?") ? "?" : "&"}access_token=${token}`;
        var link = document.createElement("a");
        //link.download = name;
        link.href = uri;
        link.click();
    }

    return (
        <div onClick={downloadAsPdf}>
            {props.children}
        </div>
    )

}

export default FileDownload;