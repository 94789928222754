import {
    GET_MASTER_PHONE_NUMBER_SUCCESS,
    GET_RECEIVE_EMAIL_SETTING,
    GET_RECEIVE_EMAIL_SETTING_SUCCESS,
    GET_SMS_SETTING,
    GET_SMS_SETTING_SUCCESS,
    REQUEST_NEW_SMS_NUMBER,
    REQUEST_NEW_SMS_NUMBER_SUCCESS, UPDATE_SMS_CONFIG_SUCCESS
} from './actions'

const initialState = {
    email: {},
    sms: null,
    masterPhoneNumber: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SMS_SETTING:
        case GET_RECEIVE_EMAIL_SETTING:
            return state;
            break;
        case GET_RECEIVE_EMAIL_SETTING_SUCCESS:
            return Object.assign({}, state, {
                email: action.payload
            });
        case GET_SMS_SETTING_SUCCESS:
            return Object.assign({}, state, {
                sms: action.payload
            });
        case UPDATE_SMS_CONFIG_SUCCESS:
        case REQUEST_NEW_SMS_NUMBER_SUCCESS:
            return Object.assign({}, state, {
                sms: action.payload
            });
        case GET_MASTER_PHONE_NUMBER_SUCCESS:
            return Object.assign({}, state, {
                masterPhoneNumber: action.payload
            })
        default:
            return state
    }
}