import React from 'react';
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import _ from "lodash";

import LocalTimeStamp from "@/common/LocalTimeStamp";
import CopyText from "@/common/CopyText";

function DataSourcesIndex(props) {
    const [dataSources, setDataSources] = React.useState([]);
    const [uploads, setUploads] = React.useState({});

    let tokenRefs = {};

    const loadDataSources = () => {
        API.get('Core', '/api/v1/datasource')
            .then(response => {
                    setDataSources(Object.assign([], response));
                },
                error => {
                    toast("Could not create load data sources: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    React.useEffect(() => {
        loadDataSources();
        const interval = setInterval(() => {
            loadDataSources();
        }, 15000);
        return () => clearInterval(interval);
    }, [])

    React.useEffect(() => {
        _.forEach(dataSources, function(ds){
            API.get('Core', '/api/v1/datasource/' + ds.id)
                .then(response => {
                        uploads[ds.id] = response;
                        setUploads(Object.assign([], uploads));
                    },
                    error => {
                        toast("Could not fetch uploads for " + ds.name + ". Error: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    }
                );
        })
    }, [dataSources]);

    const copyToken = (datasource) => {
        let ref = tokenRefs[datasource.id];

        var text = ref.current.innerText;
        var elem = document.createElement("textarea");
        document.body.appendChild(elem);
        elem.value = text;
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);

        toast("Copied to clipboard!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
    }
  
    return (
        <div className="rootView sourcesView fsExclude">
            <div className="navBar">
                <div className="title">Data Sources</div>
                <div className="separator"></div>
            </div>
            <div className="scrollView">
                <div className="sourcesList tableView">
                    <div className="tableSection">
                        <div className="sectionTitle">Your Data Sources</div>
                        <div className={"cellFootnote"}>Download the PowerEngage Reporting Database Agent <a style={{color: 'white', cursor:'pointer'}} href="https://rdb-agent-versions.s3-us-gov-west-1.amazonaws.com/CueHit.RDBAgent.Setup.exe">HERE</a></div>
                        {
                            _.map(dataSources, function (dataSource, k) {
                                const tokenRef = React.createRef();
                                tokenRefs[dataSource.id] = tokenRef;
                                return (
                                    <div className="tableCell sourceCell" key={k}>
                                        <div className="sourceBaseCell">
                                            <div className="cellBody">
                                                <div className="title sourceName">{dataSource.name}{( dataSource.department ? (<div className={"attributeItem priority none"}>{dataSource.department.name}</div>) : null )}</div>
                                                <div className="cellActions">
                                                    {
                                                        document.queryCommandSupported('copy') ? (
                                                            <div onClick={copyToken.bind(this, dataSource)}
                                                                 className="cellAction actionEdit button small action">
                                                                <div className="title">Copy Token</div>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>

                                            <div className="sourceDetails">
                                                <div className="sourceAttribute">
                                                    <div className="attributeTitle">Last Message</div>
                                                    <div className="attributeValue"><LocalTimeStamp utcTimestamp={dataSource.lastMessageAt} format={"MM/DD/YYYY, h:mm:ss a"}/></div>
                                                </div>

                                                <div className="sourceAttribute">
                                                    <div className="attributeTitle">OS</div>
                                                    <div className="attributeValue">{dataSource.os}</div>
                                                </div>

                                                <div className="sourceAttribute">
                                                    <div className="attributeTitle">OS Version</div>
                                                    <div className="attributeValue">{dataSource.osVersion}</div>
                                                </div>

                                                <div className="sourceAttribute">
                                                    <div className="attributeTitle">Reporting DB Connection</div>
                                                    <div className="attributeValue">{dataSource.dbConnectionExists}</div>
                                                </div>

                                                <div className="sourceAttribute">
                                                    <div className="attributeTitle">Connector Interface</div>
                                                    <div className="attributeValue">{dataSource.connectorInterface}</div>
                                                </div>

                                                <div className="sourceAttribute">
                                                    <div className="attributeTitle">Token</div>
                                                    <div className="attributeValue"><span ref={tokenRef}>{dataSource.token}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                            {
                                                uploads && uploads[dataSource.id] ? (
                                                    <div className={"sourceActivityList"}>
                                                        {
                                                            _.map(_.sortBy(uploads[dataSource.id], function(up){return up.lastModified;}).reverse(), function(u){
                                                                return (
                                                                    <div className={"sourceActivityCell"}>
                                                                        <CopyText title="Data" path={"/api/v1/datasource/" + dataSource.id + "/upload"} queryParams={{object : u.key}}>
                                                                            <div className="title">{u.key}</div>
                                                                            <div className="subtitle">Uploaded At: <LocalTimeStamp utcTimestamp={u.lastModified} format={"MM/DD/YYYY, h:mm:ss a"}/></div>
                                                                            <div className="separator"/>
                                                                        </CopyText>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                ) : null
                                            }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataSourcesIndex;
