import React from 'react';
import Select from "react-select";
import _ from "lodash";

import TenantSelect from "@/components/TenantSelect";

export default function AddEditTenantMember({ onAdd, onCancel, taskTypes, existingMember }) {
    const [tenant, setTenant] = React.useState(existingMember ? existingMember.tenant : null);
    const [surveyRoleType, setSurveyRoleType] = React.useState(existingMember ? existingMember.dataOwnerSubjectFilter : null);
    const [taskType, setTaskType] = React.useState(existingMember ? existingMember.taskType : null);

    const surveyRoleTypes = [
        { value: null, label: "-" },
        { value: 'RESPONDER', label: 'Responder' },
        { value: 'CALLTAKER', label: 'Call Taker' }
    ]

    const onSubmit = () => {
        if (existingMember) {
            existingMember.tenant = tenant;
            existingMember.dataOwnerSubjectFilter = surveyRoleType;
            existingMember.taskType = taskType;
            onAdd();
        } else {
            onAdd({ tenant: tenant, dataOwnerSubjectFilter: surveyRoleType, taskType: taskType });
        }
    }

    const getCurrentRoleValue = () => {
        if (surveyRoleType != null) {
            var currentOpt = _.find(surveyRoleTypes, function (f) {
                return f.value === surveyRoleType;
            })
            return currentOpt;
        }
        return null;
    }

    const updateSurveyRoleType = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setSurveyRoleType(obj.value);
                break;
        }
    }

    const onTaskTypeSelect = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setTaskType(obj);
                break;
            case "clear":
                setTaskType(null);
                break;
        }
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <TenantSelect onSelect={(ten) => setTenant(ten)} value={tenant} isDisabled={!!existingMember} />
                    </div>
                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Survey Role Type</div>
                        </div>
                        <Select className="tableCell selectCell"
                            value={getCurrentRoleValue()}
                            classNamePrefix="selectCell"
                            isDisabled={false}
                            onChange={updateSurveyRoleType}
                            options={surveyRoleTypes}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>
                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Task Type Filter</div>
                        </div>
                        <Select className="selectCell" classNamePrefix="selectCell"
                            onChange={onTaskTypeSelect}
                            value={taskType ? taskType : "--"}
                            options={taskTypes}
                            isClearable={true}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                </div>

            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={onSubmit} className={`button confirm medium`}>
                        <div className="title">{!!existingMember ? 'Update' : 'Save'}</div>
                    </div>
                </div>
                <div className="separator"></div>
            </div>
        </div>
    );
}
