import LocalTimeStamp from "./LocalTimeStamp";
import React from "react";
import _ from "lodash";


export const getSurveyOriginationMethodValue = (surveyInstance) => {
    switch (surveyInstance.originationMethod) {
        case "UNKNOWN":
            return "Unknown";
            break;
        case "SINGLE_SEND":
            return "Sent by: " + (surveyInstance.singleSendUser ? surveyInstance.singleSendUser.readableName : "");
            break;
        case "IMPORT":
            return "Sent via Import";
            break;
        case "RULE_TRIGGER":
            let ruleName = "";
            if(surveyInstance.ruleTriggerInstance != null) {
                ruleName = surveyInstance.ruleTriggerInstance.matchingRule.name;
            }
            return "Triggered by " + ruleName;
            break;
        case "ANONYMOUS":
            return "Website Form Submission";
            break;
        case "INBOUND_TEXT":
            return "Citizen Initiated";
            break;
    }
}

export function SurveyTimestamps({surveyInstance, messages}){

    let createdAt = (<LocalTimeStamp utcTimestamp={surveyInstance.surveyInstance.createdAt} format={"MM/DD/YYYY, h:mm:ss a"}/>);
    let sentAt;
    let respondedAt;
    if(messages){
        let sentAtMessage = _.minBy(_.filter(messages, function(m){ return m.type == "APP";}), function(appMessages){ return appMessages.createdAt;});
        if(sentAtMessage){
            sentAt = (<LocalTimeStamp utcTimestamp={sentAtMessage.createdAt} format={"MM/DD/YYYY, h:mm:ss a"}/>);
        }

        let respondedAtMessage = _.min(_.filter(messages, function(m){ return m.type == "CONTACT"}), function(contactMessages){ return contactMessages.createdAt;});
        if(respondedAtMessage){
            respondedAt = (<LocalTimeStamp utcTimestamp={respondedAtMessage.createdAt} format={"MM/DD/YYYY, h:mm:ss a"}/>);
        }
    }

    let importedAt;
    if(surveyInstance.originationMethod == "IMPORT"){
        importedAt = (<LocalTimeStamp utcTimestamp={surveyInstance.importInstance.createdAt} format={"MM/DD/YYYY, h:mm:ss a"}/>);
    }

    return (
        <div className="attributeCell">
            <div className="attributeTitle">Created At</div>
            <div className="attributeDetail">{createdAt}</div>
            {
                sentAt ? (
                    <span>
                        <div className="attributeTitle">Sent At</div>
                        <div className="attributeDetail">{sentAt}</div>
                    </span>
                ) : null
            }
            {
                respondedAt ? (
                    <span>
                        <div className="attributeTitle">Responded At</div>
                        <div className="attributeDetail">{respondedAt}</div>
                    </span>
                ) : null
            }
            {
                importedAt ? (
                    <span>
                        <div className="attributeTitle">Imported At</div>
                        <div className="attributeDetail">{importedAt}</div>
                    </span>
                ) : null
            }
            <div className="separator"/>
        </div>
    )
}