import React, { useState } from 'react';
import { API } from "aws-amplify";
import { withRouter } from "react-router";
import { toast } from "react-toastify";

const ConversationAccept = (props) => {
    const { token } = props.match.params;
    const [acceptToken, setAcceptToken] = useState(null)

    const acceptConversation = () => {
        API.put('Unauthorized', '/u/conversation/invite/' + token + '/accept')
            .then(response => {
                setAcceptToken(response);
                if (response.success && response.autoStartVideo) {
                    props.history.push("/video-app-player/" + response.roomUniqueName + '/token/' + response.videoRoomToken);
                    //toast("You have accepted the assignment and a chat room has been shared with the requester. Please join the room.", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                }
            },
                error => {
                    toast("Could not accept invite: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    if (!token) {
        return (<span>No token to accept an invite with.</span>);
    }

    let message = "Please confirm you are prepared to assign this conversation to yourself. The system will reflect you took assignment and you should be prepared to carry the conversation to completion."
    if (acceptToken && acceptToken.success && acceptToken.autoStartVideo) {
        message = "You have taken assignment of the conversation. You will receive a link via text to join the video chat.";
    }
    else if (acceptToken && acceptToken.success) {
        message = "You have taken assignment of the conversation.";
    }
    else if (acceptToken && acceptToken.errorMsg) {
        message = acceptToken.errorMsg;
    }

    return (
        <div className="publicSurveyForm">
            <div className="surveyDetails">
                <div className="surveyTitle">
                    {message}
                </div>
            </div>
            {
                acceptToken == null ? (
                    <div className="surveyFooter">
                        <div onClick={acceptConversation} className="button large confirm submitSurvey">
                            <div className="buttonTitle">Take Assignment</div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}

export default withRouter(ConversationAccept);