import {
    GET_CUSTOM_SCHEMA,
    GET_CUSTOM_SCHEMA_FAILURE,
    GET_CUSTOM_SCHEMA_SUCCESS,
    GET_SYSTEM_SCHEMA,
    GET_SYSTEM_SCHEMA_FAILURE,
    GET_SYSTEM_SCHEMA_SUCCESS,
    CREATE_CUSTOM_FIELD,
    CREATE_CUSTOM_FIELD_SUCCESS,
    DELETE_CUSTOM_FIELD,
    DELETE_CUSTOM_FIELD_SUCCESS
} from './actions'

const initialState = {
    systemFields : {
        "ANIMAL":[],
        "CLIENT_USER" : []
    },
    customFields: {
        "ANIMAL":[],
        "CLIENT_USER" : []
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOM_SCHEMA:
        case GET_CUSTOM_SCHEMA_FAILURE:
        case GET_SYSTEM_SCHEMA:
        case CREATE_CUSTOM_FIELD:
        case GET_SYSTEM_SCHEMA_FAILURE:
            return state;
            break;
        case GET_CUSTOM_SCHEMA_SUCCESS:
            var entity = action.entity;
            state.customFields[entity] = action.payload;
            return Object.assign({}, state)
        case GET_SYSTEM_SCHEMA_SUCCESS:
            var entity = action.entity;
            state.systemFields[entity] = action.payload;
            return Object.assign({}, state)
        default:
            return state
    }
}