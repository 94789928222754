import { Button, CircularProgress, MenuItem, TextField, Select, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import EmailChips from '../../../common/EmailChips';
import { convertToTimeZone, TIMEZONES } from '../../../utils/utils';
import InputCell from "../../../common/form/InputCell";
import _ from "lodash";

function ActivityDigestEmail(props) {
    const FREQUENCIES = ['DAILY', 'WEEKLY', 'BIWEEKLY', 'MONTHLY'];
    const FEEDBACKS = ['POSITIVE', 'NEUTRAL', 'NEGATIVE', 'MIXED'];
    const TIMEZONE = props?.user?.aboutMe?.currentTenant?.timezoneName;

    const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(0);
    const [selectedDayOfMonth, setSelectedDayOfMonth] = useState(1);
    const [hour, setHour] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [subscribers, setSubscribers] = useState([]);
    const [timeZone, setTimeZone] = useState(0);
    const [currentSetting, setCurrentSetting] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [digestName, setDigestName] = useState('');
    const [selectedFeedbacks, setSelectedFeedbacks] = useState(Object.fromEntries(FEEDBACKS.map(f => [f, false])));
    const [currentType, setCurrentType] = useState(props.type);

    const [currentFrequency, setCurrentFrequency] = useState(FREQUENCIES[0]);

    const DAYS_OF_WEEK = [
        { label: 'Sun', value: 0 },
        { label: 'Mon', value: 1 },
        { label: 'Tue', value: 2 },
        { label: 'Wed', value: 3 },
        { label: 'Thu', value: 4 },
        { label: 'Fri', value: 5 },
        { label: 'Sat', value: 6 },
    ];

    const DAYS_OF_MONTH = [...Array(31).keys()].map(n => ({ label: n + 1, value: n + 1 }));

    const MINUTES_OPTIONS = ['00', '30'];

    const selectDayOfWeek = i => setSelectedDayOfWeek(i);

    const updateHour = event => {
        const VALUE = Math.floor(Number(event.target.value));
        if (VALUE >= 0 && VALUE <= 23 && Number.isInteger(VALUE)) setHour(VALUE)
    }

    const updateMinutes = event => {
        const VALUE = Math.floor(Number(event.target.value));
        if (VALUE >= 0 && VALUE <= 59 && Number.isInteger(VALUE)) setMinutes(VALUE.toString().padStart(2, '0'))
    }

    const validateInput = event => {

        const ASCIICode = (event.which) ? event.which : event.keyCode;
        const ASCII_NINE_CODE = 57, ASCII_ZERO_CODE = 48;

        if (!(ASCIICode >= ASCII_ZERO_CODE && ASCIICode <= ASCII_NINE_CODE)) {
            event.preventDefault();
        }
    }

    const addEmails = (...emails) => {
        const VALUE = [...subscribers.concat(emails)];

        setSubscribers(VALUE);
    }

    const removeEmail = index => {
        const VALUE = [...subscribers];

        VALUE.splice(index, 1);

        setSubscribers(VALUE);
    }

    const getFrequencyValue = () => {
        return {
            'DAILY': hour,
            'WEEKLY': selectedDayOfWeek,
            'BIWEEKLY': selectedDayOfWeek,
            'MONTHLY': selectedDayOfMonth
        }[currentFrequency]
    }
    const onCancel = () => {
        props.history.goBack();
    }

    const createDigestData = () => ({
        ...currentSetting,
        active: true,
        name: digestName,
        receivers: subscribers.join(';'),
        frequency: currentFrequency,
        frequencyValue: getFrequencyValue(),
        hoursValue: hour,
        minutesValue: Number(minutes),
        dayOfWeek: selectedDayOfWeek,
        type: currentType,
        feedback: Object.keys(selectedFeedbacks).filter(f => selectedFeedbacks[f]).join(','),
        timezone: props?.user?.aboutMe?.currentTenant?.timezoneName,
    })


    const isAnyFeedbackSelected = () => {
        return Object.values(selectedFeedbacks).some(feedback => feedback);
    };

    const saveSubscription = () => {
        let error = null;
        if (!isAnyFeedbackSelected()) error= "Feedback Types not selected";
        if (!subscribers || subscribers.length === 0) error = "Receivers list cannot be empty";
        if(!digestName && digestName === '') error = "Digest Name cannot be empty";
        if (error) {
            toast.error(error, { position: toast.POSITION.TOP_CENTER });
            return;
        }

        const DATA = createDigestData();
        setSaving(true);

        let savingRequest;

        if (currentSetting?.id) {
            savingRequest = API.put('Core', `/api/v1/digest/settings/${currentSetting.id}`, { body: DATA })
                .then(r => {
                    const SETTING = r;

                    if (SETTING) {
                        setCurrentSetting(SETTING);
                        toast.success("Digest Setting Updated!", { position: toast.POSITION.TOP_CENTER });
                    }
                })
        }
        else {

            savingRequest = API.post('Core', '/api/v1/digest/settings', { body: DATA })
                .then(r => {
                    const SETTING = r;

                    if (SETTING) {
                        setCurrentSetting(SETTING);
                        toast.success("Digest Setting Created!", { position: toast.POSITION.TOP_CENTER });
                    }
                })
        }

        savingRequest.finally(() => setSaving(false));
    }

    let updateFeedback = feedback => {
        const SELECTED_FEEDBACKS = selectedFeedbacks;
        SELECTED_FEEDBACKS[feedback] = !SELECTED_FEEDBACKS[feedback];
        setSelectedFeedbacks({ ...SELECTED_FEEDBACKS });
    }

    useEffect(() => {
        const ID = props?.match?.params?.id;
        if (!ID) return;

        setLoading(true);
        API.get('Core', `/api/v1/digest/settings/${ID}`)
            .then(r => {
                setCurrentSetting(r || {});
                if (r) {
                    const SELECTED_FEEDBACK = selectedFeedbacks;

                    setCurrentFrequency(r.frequency);
                    setDigestName(r.name);
                    setHour(r.hoursValue);
                    setCurrentType(r.type);
                    console.log(r.type);
                    setMinutes((MINUTES_OPTIONS.map(Number).includes(r.minutesValue) ? r.minutesValue : 0).toString().padStart(2, '0'));
                    setSelectedDayOfWeek(r.dayOfWeek);

                    if (r.frequency === 'MONTHLY') setSelectedDayOfMonth(r.frequencyValue);
                    if (r.feedback != null) r.feedback.split(',').forEach(f => SELECTED_FEEDBACK[f] = true);

                    if (r.type) setCurrentType(r.type);

                    setSelectedFeedbacks({ ...SELECTED_FEEDBACK });
                    setSubscribers(r.receivers ? r.receivers.split(';') : []);
                    setTimeZone(r.timezone ? TIMEZONES.map(t => t.value).indexOf(r.timezone) : 0);
                }
            })
            .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let getTimeValue = (value) => {
        if (value == null) return '';
        else if (value < 10) return `0${value}`;
        else return value.toString();
    }

    return (
        <div className='emailDigestSubscriptionsView'>
            {!loading ? <>
                <TextField  placeholder='Digest Name' value={digestName} onChange={event => setDigestName(event.target.value)} />

                <section>
                    <div className="addDigestFrequencySelectContainer">
                        <span>Frequency:</span>
                        {
                            FREQUENCIES.map((f, i) => (<span onClick={() => setCurrentFrequency(f)} className={`addDigestFrequencySelect ${currentFrequency === f ? 'active' : ''}`} key={`addDigestFrequency${i}`}>{f}</span>))
                        }
                    </div>
                </section>



                {
                    (currentFrequency === 'WEEKLY' || currentFrequency === 'BIWEEKLY') && <section className='emailDigestSubscriptionsDayOfWeek'>
                        <h3 className='emailDigestSubscriptionsHeader'>Day of Week</h3>
                        <div className='emailDigestSubscriptionsDayOfWeekButtons'>
                            {
                                DAYS_OF_WEEK.map((d, i) => (<button key={`activityDigestDayOfWeekButton${i}`} onClick={() => selectDayOfWeek(i)} className={`${i === selectedDayOfWeek ? 'selected' : ''}`}>{d.label}</button>))
                            }
                        </div>
                    </section>}

                {
                    currentFrequency === 'MONTHLY' && <section>
                        <h3 className='emailDigestSubscriptionsHeader'>Day of Month</h3>
                        <Select value={selectedDayOfMonth} onChange={event => setSelectedDayOfMonth(event.target.value)} name='Day of month' >
                            {DAYS_OF_MONTH.map((d, i) => (<MenuItem key={`emailDigestSubscriptionsDayOfMonthOption${i}`} value={d.value}>{d.label}</MenuItem>))}
                        </Select>
                    </section>
                }



                <section className='emailDigestSubscriptionsTime'>
                    <h3 className='emailDigestSubscriptionsHeader'>Hour of Day</h3>
                    <div className='emailDigestSubscriptionsTimeInputsContainer'>
                        <TextField placeholder='Hour' type={'number'} value={getTimeValue(hour)} onChange={updateHour} onKeyPress={validateInput} style={{ width: '40px' }} />
                    </div>
                </section>

                <section className='emailDigestSubscribers'>
                    <h3 className='emailDigestSubscriptionsHeader'>Subscribers</h3>
                    <EmailChips emailsList={subscribers} addEmails={addEmails} removeItem={removeEmail} onEmailInput={() => setDisableButton(true)} onEmailEmpty={() => setDisableButton(false)} />
                </section>
                <section className='emailDigestSubscriptionFeedback'>
                    <h3 className='emailDigestSubscriptionsHeader'>Types of Feedback</h3>
                    <FormGroup className='emailDigestSubscriptionFeedbackOptions' row>
                        {FEEDBACKS.map((f, i) => (<FormControlLabel className='emailDigestSubscriptionFeedbackOption' key={`emailDigestSubscriptionFeedbackOption${i}`} label={f} control={<Checkbox checked={selectedFeedbacks[f]} />} onClick={() => updateFeedback(f)} />))}
                    </FormGroup>
                </section>

                <div className="actionBar " style={{background:"white"}}>
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveSubscription} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>
                </div>




{/*
                {saving ? <CircularProgress /> : <Button variant="contained" color="primary" onClick={saveSubscription} disabled={disableButton}>Save</Button>}
*/}

                {currentSetting?.logs?.length > 0 ? <section className='emailDigestSubscriptionsSends'>

                    <div className='emailDigestSubscriptionsSendsHeaders'>
                        <span className='emailDigestSubscriptionsSendsHeader'>Time</span>
                        <span className='emailDigestSubscriptionsSendsHeader'>#Sent</span>
                        <span className='emailDigestSubscriptionsSendsHeader'>Errors</span>
                    </div>
                    {currentSetting.logs.map((s, i) => {
                        return (<div className="emailDigestLog" key={`emailDigestLog${i}`}>
                            <span className='emailDigestLogItem'>{convertToTimeZone(s.sentAt, TIMEZONE)}</span>
                            <span className='emailDigestLogItem'>{s.receiversCount}</span>
                            <span className='emailDigestLogItem'>{s.errors || ''}</span>
                        </div>)
                    })}
                </section> : <span>This digest has no sendings history</span>}</> : <CircularProgress />}
        </div>);
}

export default ActivityDigestEmail;