import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import queryString from 'query-string';
import {API} from "aws-amplify";
import {toast} from "react-toastify";

let codeExchanged = false;

function StripeAuthReceive({location, history}) {
    const [code, setCode] = React.useState("");
    React.useEffect(() => {
        if(!location.search || codeExchanged){
            return;
        }
        const values = queryString.parse(location.search);
        let code = values.code;
        setCode(code);
        codeExchanged = true;
        API.post('Core', '/api/v1/payment-provider/stripe/authorize', {
            queryStringParameters: {
                code: code
            }
        })
            .then(response => {
                    history.push("/services");
                },
                error => {
                    toast("Could not connect to Stripe: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    history.push("/services");
                }
            );

    }, []);

    return (<span>{code}</span>);
}

export default withRouter(StripeAuthReceive);