import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import CreateEditPublicFeedbackBoard from "./createEditPublicFeedbackBoard";

const mapStateToProps = state => ({
    ...state
});

function CreatePublicFeedbackBoard(props)
{
    let goBack = () => {
        props.history.goBack();
    }

    return (<div>
        <div className="addPublicFeedbackButton" onClick={goBack}>
            <ArrowBackIos />
            Back
        </div>

        <CreateEditPublicFeedbackBoard {...props}/>
    </div>);
}

export default withRouter(connect(mapStateToProps)(CreatePublicFeedbackBoard));