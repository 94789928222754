import React, {createContext, useEffect, useState} from 'react';
import getOidcServiceInstance from "./EIDOidcSingletonService";

const EidAuthContext = createContext();

const EidAuthProvider = ({children}) => {
    const [eidOidcService, setOidcService] = useState(null);
    const [eidUser, setEidUser] = useState(() => {
        const eidSignOut = localStorage.getItem('eidSignOut');
        if (eidSignOut) {
            localStorage.removeItem('eidUser');
            return null;
        }
        const eidUserInSession = localStorage.getItem('eidUser') ?? null;
        return eidUserInSession ? JSON.parse(eidUserInSession) : null;
    });

    useEffect(() => {
        let oidcInstance;
        let handleAccessTokenExpiring;

        const initializeEidSdk = async () => {
            try {
                oidcInstance = await getOidcServiceInstance();
                setOidcService(oidcInstance);
                handleAccessTokenExpiring = async () => {
                    try {
                        const user = await oidcInstance?.refreshTokens();
                        if (user) {
                            handleUserLoaded(user);
                        }
                    } catch (error) {
                        console.error('Silent renew failed');
                    }
                };

                oidcInstance?.events.addAccessTokenExpiring(handleAccessTokenExpiring);

                try {
                    let currentUser;
                    currentUser = await oidcInstance?.getUser();
                    if (!currentUser && eidUser) {
                        currentUser = await refreshUser(eidUser, oidcInstance);
                    }
                    console.log('eid user available in session:', currentUser);
                    if (currentUser) {
                        handleUserLoaded(currentUser);
                    }
                } catch (error) {
                    console.error('Error during user retrieval or token refresh:', error);
                    handleEidUserError();
                }
            } catch (error) {
                console.error('Error during EID authentication process. Signing out.', error);
                handleEidUserError();
            }
        };

        if ((!eidUser ||
                (eidUser?.expires_at && eidUser.expires_at <= Math.floor(Date.now() / 1000) + 5 * 60)) &&
            !localStorage.getItem('eidSignOut')) {
            initializeEidSdk();
        }

        return () => {
            if (oidcInstance?.events) {
                oidcInstance.events.removeAccessTokenExpiring(handleAccessTokenExpiring);
            }
        };
    }, []);

    const refreshUser = async (storedUser, oidcServiceInstance) => {
        try {
            const refreshToken = storedUser?.refresh_token;
            const clientId = window.__RUNTIME_CONFIG__.EID_CLIENT_ID;
            const tokenEndpoint = `${window.__RUNTIME_CONFIG__.EID_AUTHORITY_URL}connect/token`;

            const body = new URLSearchParams();
            body.append('grant_type', 'refresh_token');
            body.append('client_id', clientId);
            body.append('refresh_token', refreshToken);

            const response = await fetch(tokenEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
            });

            if (!response.ok) {
                throw new Error('Failed to refresh token');
            }
            const data = await response.json();

            let updatedStoredUser = {
                ...storedUser,
                id_token: data.id_token,
                access_token: data.access_token,
                refresh_token: data.refresh_token,
                expires_at: Math.floor(Date.now() / 1000) + data.expires_in
            };

            return await buildNewUserFromStorage(updatedStoredUser, oidcServiceInstance);
        } catch (error) {
            console.error('Error refreshing token:', error);
            throw error;
        }
    };

    const buildNewUserFromStorage = async (user, oidcService) => {
        try {
            return await oidcService._userManager._buildUser(user);
        } catch (error) {
            throw new Error(`Error creating new user from storage: ${error.message}`);
        }
    };

    const handleEidUserError = () => {
        setEidUser(null);
        localStorage.removeItem('eidUser');
    };

    const handleUserLoaded = (user) => {
        setEidUser(user);
        localStorage.setItem('eidUser', JSON.stringify(user));
    };

    const setSignInEidUser = (eidUser) => {
        setEidUser(eidUser);
        localStorage.setItem('eidUser', JSON.stringify(eidUser));
    };

    const eidUserSignOut = async () => {
        localStorage.setItem('eidSignOut', 'true');
        try {
            const oidcInstance = eidOidcService || await getOidcServiceInstance();
            await oidcInstance?.signOut();
        } catch (error) {
            console.error('Error during sign-out:', error);
        }
    };

    return (
        <EidAuthContext.Provider value={{eidUser, setSignInEidUser, eidUserSignOut}}>
            {children}
        </EidAuthContext.Provider>
    );
};

export {EidAuthContext, EidAuthProvider};