import {
    GET_SERVICE_OFFERINGS,
    GET_SERVICE_OFFERINGS_SUCCESS
} from './actions'

const initialState = {
    services: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_OFFERINGS:
            return state;
            break;
        case GET_SERVICE_OFFERINGS_SUCCESS:
            return Object.assign({}, state, {
                services: action.payload
            });
        default:
            return state
    }
}