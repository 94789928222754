import { API } from 'aws-amplify';
import {throwGenericError} from "../transaction_state/actions";

export const TRANSACTION_KEY = "SERVICE_OFFERING";

export const GET_SERVICE_OFFERINGS = 'GET_SERVICE_OFFERINGS';
export const requestServiceOfferings = () => dispatch => {
    dispatch({
        type : GET_SERVICE_OFFERINGS
    });
}

export const GET_SERVICE_OFFERINGS_SUCCESS = 'GET_SERVICE_OFFERINGS_SUCCESS';
export const requestServiceOfferingsSuccess = (json) => dispatch => {
    dispatch({
        type : GET_SERVICE_OFFERINGS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const CREATE_SERVICE_OFFERINGS = 'CREATE_SERVICE_OFFERINGS';
export const createServiceOfferingRequest = () => dispatch => {
    dispatch({
        type : CREATE_SERVICE_OFFERINGS
    });
}

export const CREATE_SERVICE_OFFERINGS_SUCCESS = 'CREATE_SERVICE_OFFERINGS_SUCCESS';
export const createServiceOfferingsSuccess = (json) => dispatch => {
    dispatch({
        type : CREATE_SERVICE_OFFERINGS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const UPDATE_SERVICE_OFFERINGS = 'UPDATE_SERVICE_OFFERINGS';
export const updateServiceOfferings = () => dispatch => {
    dispatch({
        type : UPDATE_SERVICE_OFFERINGS
    });
}

export const UPDATE_SERVICE_OFFERINGS_SUCCESS = 'UPDATE_SERVICE_OFFERINGS_SUCCESS';
export const updateServiceOfferingsSuccess = (json) => dispatch => {
    dispatch({
        type : UPDATE_SERVICE_OFFERINGS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const DELETE_SERVICE_OFFERINGS = 'DELETE_SERVICE_OFFERINGS';
export const deleteServiceOfferings = () => dispatch => {
    dispatch({
        type : DELETE_SERVICE_OFFERINGS
    });
}

export const DELETE_SERVICE_OFFERINGS_SUCCESS = 'DELETE_SERVICE_OFFERINGS_SUCCESS';
export const deleteServiceOfferingsSuccess = (json) => dispatch => {
    dispatch({
        type : DELETE_SERVICE_OFFERINGS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function fetchAllServiceOfferings() {

    return function(dispatch) {

        dispatch(requestServiceOfferings())

        return API.get('Core', '/api/v1/services')
            .then(response => {
                    dispatch(requestServiceOfferingsSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function createServiceOffering(body) {

    return function(dispatch) {

        dispatch(createServiceOfferingRequest())

        return API.post('Core', '/api/v1/services', {body: body})
            .then(response => {
                    dispatch(fetchAllServiceOfferings())
                },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}

export function deleteServiceOffering(id) {

    return function(dispatch) {

        dispatch(deleteServiceOfferings())

        return API.del('Core', '/api/v1/services/' + id)
            .then(response => {
                    dispatch(fetchAllServiceOfferings())
                },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}

export function updateServiceOffering(id, body) {

    return function(dispatch) {

        dispatch(updateServiceOfferings())

        return API.put('Core', '/api/v1/services/' + id, {body: body})
            .then(response => {
                    dispatch(fetchAllServiceOfferings())
                },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}