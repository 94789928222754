import React from 'react';
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import _ from 'lodash';

import LocalTimeStamp from "@/common/LocalTimeStamp";
import TaskDetail from "@/routes/tasks/TaskDetail";

export default function ConversationTasks({ conversationId, newTask, onChange, onConversationStarted, ...props }) {

    const [tasks, setTasks] = React.useState(false);
    const [taskActionTypes, setTaskActionTypes] = React.useState([]);
    const loadTasks = () => {
        API.get('Core', '/api/v1/conversation/' + conversationId + '/tasks')
            .then(response => {
                setTasks(response);
            },
                error => {
                    toast("Could not load tasks details: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const loadTaskActionTypes = () => {
        API.get('Core', '/api/v1/task/action/types')
        .then(setTaskActionTypes,
            error => {
                toast("Could not load tasks action types: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            }
        );
    }

    React.useEffect(() => {
        setTasks([]);
        loadTasks();
        loadTaskActionTypes();
    }, [conversationId, newTask])

    if (!tasks) {
        return null;
    }

    return (
        _.map(tasks, function (t) {
            return (
                <TaskDetail task={t} onChange={onChange.bind(this)} {...props} taskActionTypes={taskActionTypes}
                    key={t.id} containerDivClass={"conversationTaskCell"} onConversationStarted={onConversationStarted}
                    user={props.props.user}>
                    <div className="cellBody">
                        <div className="taskType">{t.type.name}</div>
                        <div className="taskDueDate">Due by <LocalTimeStamp utcTimestamp={t.dueDate} format={"MM/DD/YYYY, h:mm a"} utc={false} /></div>
                    </div>
                    <div className="cellDetail">
                        <div className="taskStatus">
                            <div className="statusTitle">{t.status}</div>
                        </div>
                    </div>
                </TaskDetail>
            )
        })
    )
}