import {
    GENERIC_ERROR,
    CLEAR_ERROR,
    CONFIRM_SUCCESS
} from "./actions";
import {ERROR, START, SUCCESS} from "../lifecycleStates";

const initialState = {
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GENERIC_ERROR:
            var key = action.key;
            state[key] = {
                errorMsg: action.msg,
                state: ERROR
            };
            return Object.assign({}, state)
            break;
        case CONFIRM_SUCCESS:
            var key = action.key;
            state[key] = {
                state: SUCCESS
            };
            return Object.assign({}, state)
            break;
        case CLEAR_ERROR:
            var key = action.key;
            state[key] = {};
            return Object.assign({}, state)
        default:
            return state
    }
}