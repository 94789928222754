import React from 'react';
import {Button, makeStyles, TextField} from "@material-ui/core";
import {toast} from 'react-toastify';
import {API, Auth} from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    cardTitle: {
        marginBottom: '24px',
        fontSize: '18px',
    },
    requestPasswordResetCodeContainer: {
        width: '100%',
    },
    requestPasswordResetCodeInput: {
        width: '100%',
        marginBottom: '30px',
    },
    sendCodeContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '16px',
    },
    backToSignInButton: {
        color: '#047d95',
        backgroundColor: 'transparent',
        padding: theme.spacing(1, 2),
        width: '100%',
        borderRadius: '4px',
        transition: 'background-color 0.2s ease-in-out',
        '&:hover': {
            backgroundColor: 'rgba(4, 125, 149, 0.1)',
        },
        textTransform: 'none',
        boxShadow: 'none',
        fontWeight: 'normal',
    },
    requestCodeButton: {
        backgroundColor: '#FF9900',
        textTransform: 'uppercase',
        color: 'white',
        padding: '14px 0',
        minWidth: '153px',
        touchAction: 'manipulate',
        fontWeight: 400,
        fontFamily: 'Arial',
        letterSpacing: '1.1px',
        fontSize: '12px',
        lineHeight: '18px',
        borderRadius: 0,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#FF9900',
            color: 'white',
        },
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
}));

export default function CognitoVerifyResetPasswordCode(props) {
    const CLASSES = useStyles();
    let goBackToSignIn = () => props.setShowResetPassword(false);

    const verifyUser = async (userName) => {
        if (!userName) {
            toast("Email cannot be empty.", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            return false;
        }
        try {
            const encodedUsername = encodeURIComponent(userName);
            const resp = await API.get("Unauthorized", `/u/${encodedUsername}/whoami`);
            if (resp?.F === "P") {
                toast("Please reset password with your PowerDMS SSO account.", {
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.ERROR
                });
                goBackToSignIn();
                return false;
            }
            return true;
        } catch (error) {
            console.error("Verification failed:", error);
            goBackToSignIn();
            return false;
        }
    };

    let requestCode = async () => {
        const isVerified = await verifyUser(props.email);

        if (isVerified && !!props.email) {
            Auth.forgotPassword(props.email)
                .then((r) => {
                    console.log('R -> ', r);
                    props.updateCode(true);
                })
                .catch((e) => {
                    console.error('E -> ', e);
                    toast(e.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR})
                });
        }
    };

    return (
        <div className={CLASSES.requestPasswordResetCodeContainer}>
            <div className={`gatekeeperTitle ${CLASSES.cardTitle}`}>Reset your password</div>
            <TextField
                id="requestResetPasswordCodeEmailInput"
                className={CLASSES.requestPasswordResetCodeInput}
                label="Email"
                type="email"
                placeholder="Enter your email"
                variant="outlined"
                onChange={(e) => props.updateEmail(e.target.value)}
                required
            />

            <div className={CLASSES.sendCodeContainer}>
                <Button
                    className={CLASSES.requestCodeButton}
                    variant="contained"
                    disableElevation
                    onClick={requestCode}
                >
                    Send Code
                </Button>
            </div>
            <div className={CLASSES.buttonsContainer}>
                <Button
                    onClick={goBackToSignIn}
                    className={CLASSES.backToSignInButton}
                    disableElevation
                >
                    Back to Sign In
                </Button>
            </div>
        </div>
    );
}