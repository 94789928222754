import React from 'react';
import {Auth, API, Cache} from "aws-amplify";
import {Redirect} from "react-router-dom";
import {withRouter} from "react-router";
import {FEDERATED_APP_CLIENT_ID, FEDERATED_OAUTH, FEDERATED_PATH} from "../common/CacheKeys";

function FederatedAuthComponent(props){
    const {path} = props.match.params;

    const [noConfig, setNoConfig] = React.useState(false);
    const [config, setConfig] = React.useState(null);

    React.useEffect( () => {
        document.body.classList.remove('prefersDarkTheme');
        API.get("Unauthorized", "/u/federated-identity-config/" + path)
            .then(resp => {
                setConfig(resp);
            },
            err => {
                setNoConfig(true);
            })
    }, [])

    React.useEffect(() => {
        if(config){
            Auth.configure({
                userPoolWebClientId: config.appClientId,
                oauth: config.oauth
            })
            Cache.setItem(FEDERATED_APP_CLIENT_ID, config.appClientId);
            Cache.setItem(FEDERATED_OAUTH, config.oauth);
            Cache.setItem(FEDERATED_PATH, path);
        }
    }, [config])

    if(!path || noConfig){
        return (
            <Redirect
                to={{
                    pathname: "/auth",
                }}
            />
        )
    }

    if(!config){
        return (<span>Loading...</span>);
    }

    return (
        <div className="publicSurveyForm">
            <div className="surveyDetails">
                {
                    config.tenant.logoFilename ? (
                        <img style={{maxWidth: '210px', maxHeight: '100px'}} src={"/logos/" + config.tenant.logoFilename}/>
                    ) : (<div className="surveyAgencyName">{config.tenant.name} Login</div>)
                }
            </div>
            <div className="surveyFooter">
                {/*<p className="footerText">{survey.footer}</p>*/}
                <div onClick={() => Auth.federatedSignIn({provider: config.providerName})} className="button large confirm submitSurvey">
                    <div className="buttonTitle">Login with Agency Directory</div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(FederatedAuthComponent);