import {
    GET_WORKFLOW_SUCCESS,
    GET_PRIORITIES_SUCCESS, GET_ALL_WORKFLOWS_SUCCESS
} from './actions'

const initialState = {
    workflow: null,
    allWorkflows:[],
    priorities: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_WORKFLOW_SUCCESS:
            return {
                ...state,
                workflow: action.payload
            };
        case GET_PRIORITIES_SUCCESS:
            return {
                ...state,
                priorities: action.payload
            };
        case GET_ALL_WORKFLOWS_SUCCESS:
            return {
                ...state,
                allWorkflows: action.payload
            };
        default:
            return state
    }
}