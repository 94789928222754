import React from 'react';
import { withRouter } from "react-router";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import _ from "lodash";

function ManageAnalytics(props) {
    const [analyticsState, setAnalyticsState] = React.useState({});

    const loadAnalytics = () => {
        API.get('Core', '/api/v1/analytics/state')
            .then(response => {
                setAnalyticsState(Object.assign({}, response));
            },
                error => {
                    toast("Could not load analytics state: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const updateDashboard = (analysisId) => {
        API.post('Core', '/api/v1/analytics/state/dashboard/' + analysisId + '/sync')
            .then(response => {
                toast("Successfully updated dashboard", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                loadAnalytics();
            },
                error => {
                    toast("Could not update dashboard: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const updateTemplate = (analysisId) => {
        API.post('Core', '/api/v1/analytics/state/template/' + analysisId + '/update')
            .then(response => {
                toast("Successfully updated template", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                loadAnalytics();
            },
                error => {
                    toast("Could not update template: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    React.useEffect(() => {
        loadAnalytics();
    }, []);

    return (
        <div className="rootView surveysView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="title">Manage Analytics</div>
                </div>
                <div className="actions">
                    {
                        analyticsState.isSourceAccount ? (
                            <div style={{
                                backgroundColor: 'red',
                                fontSize: '18px',
                                fontWeight: 'bold',
                                padding: '10px 10px',
                                borderRadius: '5px'
                            }}>
                                SOURCE ACCOUNT
                            </div>
                        ) : null
                    }

                </div>
                <div className="separator"></div>
            </div>
            <div className="scrollView">
                <div className="surveysList tableView">
                    <div className="tableSection">
                        <div className="sectionTitle">Dashboards</div>
                        {
                            _.map(_.sortBy(analyticsState.analysisList, function (a) {
                                return a.analysis.name;
                            }), function (analysisState, k) {
                                return (
                                    <div className="tableCell surveyCell expanded">
                                        <div className="surveyBaseCell" style={{ height: '450px' }}>

                                            <div className="cellBody">
                                                <div className="title serviceName">{analysisState.analysis.name}</div>
                                                <div className="subtitle description"><b>Source Id:</b> {analysisState.analysis.sourceId}</div>
                                                {
                                                    analysisState.sourceTemplate ? (
                                                        <div className="cellBody" style={{ padding: '10px 10px', border: '1px solid rgba(180, 180, 192, 1)' }}>
                                                            <div className="title serviceName">Template</div>
                                                            <div className="subtitle description"><b>ARN:</b> {analysisState.sourceTemplate.arn}</div>
                                                            <div className="subtitle description"><b>Name:</b> {analysisState.sourceTemplate.name}</div>
                                                            <div className="subtitle description"><b>Version:</b> {analysisState.sourceTemplate.version.versionNumber}</div>
                                                            <div className="subtitle description"><b>Version Status:</b> {analysisState.sourceTemplate.version.status}</div>
                                                            <div className="subtitle description"><b>Source Entity ARN:</b> {analysisState.sourceTemplate.version.sourceEntityArn}</div>
                                                            {
                                                                analyticsState.isSourceAccount ? (
                                                                    <div onClick={updateTemplate.bind(this, analysisState.analysis.sourceId)} className="surveyAction actionEdit button small action" style={{ margin: 'auto', width: '50%', textAlign: 'center' }}>
                                                                        <div style={{ margin: 'auto' }} className="title">Update Template</div>
                                                                    </div>
                                                                ) : null
                                                            }
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    analysisState.currentAccountDashboard ? (
                                                        <div className="cellBody" style={{ padding: '10px 10px', border: '1px solid rgba(180, 180, 192, 1)', marginTop: '5px' }}>
                                                            <div className="title serviceName">Current Account Dashboard</div>
                                                            <div className="subtitle description"><b>ARN:</b> {analysisState.currentAccountDashboard.arn}</div>
                                                            <div className="subtitle description"><b>Dashboard ID:</b> {analysisState.currentAccountDashboard.dashboardId}</div>
                                                            <div className="subtitle description"><b>Name:</b> {analysisState.currentAccountDashboard.name}</div>
                                                            <div className="subtitle description"><b>Version:</b> {analysisState.currentAccountDashboard.version.versionNumber}</div>
                                                            <div className="subtitle description"><b>Version Status:</b> {analysisState.currentAccountDashboard.version.status}</div>
                                                            <div className="subtitle description"><b>Source Entity ARN:</b> {analysisState.currentAccountDashboard.version.sourceEntityArn}</div>
                                                            <div className="subtitle description"><b>Created Time:</b> {analysisState.currentAccountDashboard.createdTime}</div>
                                                            <div className="subtitle description"><b>Last Published Time:</b> {analysisState.currentAccountDashboard.lastPublishedTime}</div>
                                                            <div className="subtitle description"><b>Last Updated Time:</b> {analysisState.currentAccountDashboard.lastUpdatedTime}</div>
                                                            <div onClick={updateDashboard.bind(this, analysisState.analysis.sourceId)} className="surveyAction actionEdit button small action" style={{ margin: 'auto', width: '50%', textAlign: 'center' }}>
                                                                <div style={{ margin: 'auto' }} className="title">Sync Dashboard</div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ManageAnalytics);