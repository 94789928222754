import { API } from 'aws-amplify';
import React, { Component, useEffect, useState } from 'react';
import { withRouter } from "react-router";

import { isCandidateCommsEnabled, isPatientsEnabled } from '@/shared/access';

import EditResponseGroup from './EditResponseGroup';

function EditResponseGroupPage(props) {

    const editWorkflow = { ...props.location.state.workflow }
    if (editWorkflow?.smsConfig?.qrcodes) {
        editWorkflow.smsConfig.qrcodes.forEach(qrcode => qrcode.storedCode = qrcode.code);
    }
    if((editWorkflow?.type === 'APPLICANT' && !isCandidateCommsEnabled()) || 
        (editWorkflow?.type === 'PATIENT' && !isPatientsEnabled())) {
        editWorkflow.type = 'DEFAULT';
    }

    const [workflow, setWorkflow ] =  useState(editWorkflow);

    useEffect(() => {
        if (!workflow) {
            const ID = props?.match?.params?.id;
            if (ID) {
                API.get('Core', `/api/v1/workflow/${ID}`)
                    .then((response) => {
                        setWorkflow(response)
                    });
            }
        }
    }, []);

    return (
        <div className="rootView groupsView">
            <div className="navBar">
                <div className="title">Communication Groups - {workflow?.name}</div>
            </div>
            <div className="editGroupsView">
                {workflow && <EditResponseGroup
                    editWorkflow={workflow}
                    masterNumber={props?.location?.state?.masterNumber?.number
                    }
                />}
            </div>
        </div>
    );
}

export default withRouter(EditResponseGroupPage);