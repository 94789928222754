import { API } from 'aws-amplify';
import { throwGenericError } from "../transaction_state/actions";

export const TRANSACTION_KEY = "SERVICE_OFFERING";

export const GET_PROVIDERS = 'GET_PROVIDERS';
export const requestProviders = () => dispatch => {
    dispatch({
        type: GET_PROVIDERS
    });
}

export const GET_PROVIDERS_CATEGORIES = 'GET_PROVIDERS_CATEGORIES';
export const requestProvidersCategories = () => dispatch => {
    dispatch({
        type: GET_PROVIDERS_CATEGORIES
    });
}

export const GET_PROVIDER_ROTATIONS = 'GET_PROVIDER_ROTATIONS';
export const requestProviderRotations = () => dispatch => {
    dispatch({
        type: GET_PROVIDER_ROTATIONS
    });
}


export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const requestProvidersSuccess = (json) => dispatch => {
    dispatch({
        type: GET_PROVIDERS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_PROVIDERS_CATEGORIES_SUCCESS = 'GET_PROVIDERS_CATEGORIES_SUCCESS';
export const requestProvidersCategoriesSuccess = (json) => dispatch => {
    dispatch({
        type: GET_PROVIDERS_CATEGORIES_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_PROVIDER_ROTATIONS_SUCCESS = 'GET_PROVIDER_ROTATIONS_SUCCESS';
export const requestProviderRotationsSuccess = (json) => dispatch => {
    dispatch({
        type: GET_PROVIDER_ROTATIONS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const CREATE_PROVIDERS = 'CREATE_PROVIDERS';
export const createProviderRequest = () => dispatch => {
    dispatch({
        type: CREATE_PROVIDERS
    });
}

export const CREATE_PROVIDERS_SUCCESS = 'CREATE_PROVIDERS_SUCCESS';
export const createProvidersSuccess = (json) => dispatch => {
    dispatch({
        type: CREATE_PROVIDERS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const UPDATE_PROVIDERS = 'UPDATE_PROVIDERS';
export const updateProviders = () => dispatch => {
    dispatch({
        type: UPDATE_PROVIDERS
    });
}

export const UPDATE_PROVIDERS_SUCCESS = 'UPDATE_PROVIDERS_SUCCESS';
export const updateProvidersSuccess = (json) => dispatch => {
    dispatch({
        type: UPDATE_PROVIDERS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const DELETE_PROVIDERS = 'DELETE_PROVIDERS';
export const deleteProviders = () => dispatch => {
    dispatch({
        type: DELETE_PROVIDERS
    });
}

export const DELETE_PROVIDERS_SUCCESS = 'DELETE_PROVIDERS_SUCCESS';
export const deleteProvidersSuccess = (json) => dispatch => {
    dispatch({
        type: DELETE_PROVIDERS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function fetchAllProviders() {

    return function (dispatch) {

        dispatch(requestProviders())
        return API.get('Core', '/api/v1/providers')
            .then(response => {
                dispatch(requestProvidersSuccess(response))
            },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchAllProviderRotations() {

    return function (dispatch) {

        dispatch(requestProviderRotations())
        return API.get('Core', '/api/v1/provider-rotations')
            .then(response => {
                dispatch(requestProviderRotationsSuccess(response))
            },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function fetchAllProviderCategories() {

    return function (dispatch) {

        dispatch(requestProvidersCategories())
        return API.get('Core', '/api/v1/providers/categories')
            .then(response => {
                dispatch(requestProvidersCategoriesSuccess(response))
            },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export function createProvider(body) {

    return function (dispatch) {

        dispatch(createProviderRequest())

        return API.post('Core', '/api/v1/providers', { body: body })
            .then(response => {
                dispatch(fetchAllProviders())
            },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}

export function deleteProvider(id) {

    return function (dispatch) {

        dispatch(deleteProviders())

        return API.del('Core', '/api/v1/providers/' + id)
            .then(response => {
                dispatch(fetchAllProviders())
            },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}

export function updateProvider(id, body) {

    return function (dispatch) {

        dispatch(updateProviders())

        return API.put('Core', '/api/v1/providers/' + id, { body: body })
            .then(response => {
                dispatch(fetchAllProviders())
            },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}