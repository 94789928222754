import React from "react"
import _ from "lodash";

import {getDate} from "@/common/LocalTimeStamp";

import SimpleEncounterField from "./SimpleEncounterField";

/**
 *
 * @param revision - Sample structure { commitId: "1234.0", commitDate: [date], encounterRevision: [encounterTypeDto], changes: [array of changes]}
 * @param schema
 * @param props
 * @constructor
 */
export default function ThreeOneOneEncounterDiff({ revision,revisions, ...props }) {

    function isUnitAdded(unit) {
        if (!revision || !revisions) {
            return null;
        }
        let unitAdded = false;

        if (revisions.length>=2){
            for( let j=0; j< revisions.length;j++) {
                let r = revisions[j];
                if (revision.commitId == r.commitId){
                    if(j>0) {
                        let previousRevision = revisions[j- 1].encounterRevision;
                        let indexFound = _.findIndex(previousRevision.units, function (unit2) {
                            return unit.unitId == unit2.unitId;
                        });
                        unitAdded = indexFound < 0;
                        if(unitAdded) {break;}
                    }
                }
            }
        }
        return  unitAdded;
    }

    function isUnitRemoved() {
        if (!revision || !revisions) {
            return null;
        }
        let unitRemoved = false;

        if (revisions.length>=2){
            for( let j=0; j< revisions.length;j++) {
                let r = revisions[j];
                if (revision.commitId == r.commitId){
                    if(j>0) {
                        let previousRevision = revisions[j- 1].encounterRevision;
                        if (previousRevision && previousRevision.units) {
                            for (let i = 0; i < previousRevision.units.length; i++) {
                                let u = previousRevision.units[i];
                                let unitExists = _.findIndex(revision.encounterRevision.units, function (ut) {
                                    return ut.unitId == u.unitId;
                                });
                                unitRemoved = unitExists < 0;
                                if (unitRemoved) {
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        return  unitRemoved;
    }

    let unitChange = _.find(revision.changes, function (ch) { return ch.propertyName === 'units'; });

    let unitRemoved = isUnitRemoved();

    return (
        <div className="contentView tableView encounterDetails">
            <div className="tableSection attributes">
                <div className={"sectionTitle"}>Service Request</div>
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Number"}
                    fieldPath={"incidentNumber"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Encounter Date"}
                    isDate={true}
                    fieldPath={"encounterDate"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident Type"}
                    fieldPath={"incidentType"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident Type 2"}
                    fieldPath={"incidentType2"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Priority"}
                    fieldPath={"priority"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Caller Number"}
                    fieldPath={"contact.phone"} />
                
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Caller Name"}
                    fieldPath={"contact.lastName"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Caller Email"}
                    fieldPath={"contact.email"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Status"}
                    fieldPath={"status"} />
            </div>

            <div className="tableSection attributes">
                <div className={"sectionTitle"}>Call Details</div>
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Caller Method"}
                    fieldPath={"encounterCallDetails.callMethod"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Caller Taker Employee ID"}
                    fieldPath={"encounterCallDetails.callTakerEmployeeId"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Call Received"}
                    isDate={true}
                    fieldPath={"encounterCallDetails.callReceived"} />
            </div>

            <div className="tableSection attributes">
                <div className={"sectionTitle"}>Location Details</div>
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Address"}
                    fieldPath={"encounterLocation.summary"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Latitude"}
                    fieldPath={"encounterLocation.latitude"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Longitude"}
                    fieldPath={"encounterLocation.longitude"} />
            </div>

            <div className="tableSection attributes">
                <div className={"sectionTitle"}>Units</div>
                {
                    revision.encounterRevision.units ? (
                        _.map(_.sortBy(revision.encounterRevision.units, function (u) { return u.id; }), function (unit) {

                            let added = isUnitAdded(unit);

                            return (
                                <div className="encounterAttributeCell nested" key={unit.unitId}>
                                    <div className={`attributeCellSection ${added ? "added" : ""}`}>
                                        <div className="attributeCellSectionTitle">{unit.unitId}</div>
                                    </div>

                                    <div className="attributeCellSection">
                                        <div className="attributeCellSectionTitle">Personnel</div>
                                        <div className="attributeCellSectionContent">
                                            <div className="sectionContentCells">
                                                {
                                                    _.map(unit.personnel, function (p) {
                                                        let name = p.personnelId;
                                                        if (p.name) {
                                                            name = (<span>{p.name} <b>({p.personnelId})</b></span>);
                                                        }
                                                        return (
                                                            <div className="encounterNestedAttributeCell">
                                                                <div className="currentValue">
                                                                    <div className="attributeTitle"></div>
                                                                    <div className="attributeDetail">{name}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : null
                }
                {
                    unitRemoved ? (
                        <div className="encounterAttributeCell nested">
                            <div className={`attributeCellSection deleted`}>
                                <div className="attributeCellSectionTitle">UNIT REMOVED</div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}