import { API } from 'aws-amplify';
import {throwGenericError} from "../transaction_state/actions";
import {deleteServiceOfferings, fetchAllServiceOfferings} from "../services/actions";

export const TRANSACTION_KEY = "WORKFLOW";

export const GET_WORKFLOW = 'GET_WORKFLOW';
export const requestWorkflow = () => dispatch => {
    dispatch({
        type : GET_WORKFLOW
    });
}

export const GET_WORKFLOW_SUCCESS = 'GET_WORKFLOW_SUCCESS';
export const requestWorkflowSuccess = (json) => dispatch => {
    dispatch({
        type : GET_WORKFLOW_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_WORKFLOW_FAILURE = 'GET_WORKFLOW_FAILURE';
export const requestWorkflowFailure = () => dispatch => {
    dispatch({
        GET_WORKFLOW_FAILURE
    });
}

export const GET_ALL_WORKFLOWS = 'GET_ALL_WORKFLOWS';
export const requestAllWorkflows = () => dispatch => {
    dispatch({
        type : GET_ALL_WORKFLOWS
    });
}

export const GET_ALL_WORKFLOWS_SUCCESS = 'GET_ALL_WORKFLOWS_SUCCESS';
export const requestAllWorkflowsSuccess = (json) => dispatch => {
    dispatch({
        type : GET_ALL_WORKFLOWS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_PRIORITIES = 'GET_PRIORITIES';
export const getPriorities = () => dispatch => {
    dispatch({
        type : GET_PRIORITIES
    });
}

export const GET_PRIORITIES_SUCCESS = 'GET_PRIORITIES_SUCCESS';
export const getPrioritiesSuccess = (json) => dispatch => {
    dispatch({
        type : GET_PRIORITIES_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_PRIORITIES_FAILURE = 'GET_PRIORITIES_FAILURE';
export const getPrioritiesFailure = (message) => dispatch => {
    dispatch({
        type : GET_PRIORITIES_FAILURE,
        payload: message
    });
}

export const DELETE_WORKFLOW = 'DELETE_WORKFLOW';
export const requestDeleteWorkflow = () => dispatch => {
    dispatch({
        type : DELETE_WORKFLOW
    });
}

export function fetchAllWorkflows() {

    return function(dispatch) {

        dispatch(requestAllWorkflows())

        return API.get('Core', '/api/v1/workflow')
            .then(response => {
                    dispatch(requestAllWorkflowsSuccess(response))
                },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}

export function fetchWorkflow() {

    return function(dispatch) {

        dispatch(requestWorkflow())

        return API.get('Core', '/api/v1/workflow/default')
            .then(response => {
                    dispatch(requestWorkflowSuccess(response))
                },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}

export function fetchPriorities(conversationId, updateConversation) {

    return function(dispatch) {
        dispatch(getPriorities());


        return API.get('Core', '/api/v1/conversation/workflow/priorities')
            .then(response => {
                    dispatch(getPrioritiesSuccess(response))
                },
                error => {
                    dispatch(getPrioritiesFailure(error.response.data.message));
                }
            );
    }
}

export function deleteWorkflow(id) {

    return function(dispatch) {
        dispatch(requestDeleteWorkflow())

        return API.del('Core', '/api/v1/workflow/' + id)
            .then(response => {
                    dispatch(fetchAllWorkflows())
                },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}