import {CSSTransition, TransitionGroup} from "react-transition-group";
import _ from "lodash";
import React from "react";
import {getLocalDateStringFromUTC, getDateString} from "../common/LocalTimeStamp";
import {useBlockLayout, useResizeColumns, useTable} from "react-table";
import EncounterDetails from "../routes/encounter/EncounterDetails";

export default function EncounterList({encounters, title, onEncounterSelect, selectedEncounter, includeEncounterDetailsModal}){

    const isSelectedEncounter = (e) => {
        return (!_.isNil(selectedEncounter) && e.id === selectedEncounter.id);
    }

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 400,
        }),
        []
    )
    const columns = React.useMemo(

        () => [
            {
                Header: 'Summary',
                accessor: 'summary', // accessor is the "key" in the data
            },
            {
                Header: 'Caller Number',
                accessor: 'contact.phone',
            },
            {
                Header: 'Source',
                accessor: 'source.name',
            },
            {
                Header: 'Location',
                accessor: 'encounterLocation.summary',
            },
            {
                id: 'unit',
                Header: 'Unit',
                accessor: (row, idx) => {
                    return _.join(_.map(row.units, function(u){return u.unitId;}))
                }
            },
            {
                id: 'date',
                Header: 'Date/Time',
                accessor: (row, idx) => {
                    if(row.source && row.source.utc)
                        return getLocalDateStringFromUTC(row.encounterDate, "MM/DD/YYYY, h:mm:ss a");

                    return getDateString(row.encounterDate, "MM/DD/YYYY, h:mm:ss a");
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Priority',
                accessor: 'priority',
            },
            {
                Header: 'Incident Number',
                accessor: 'incidentNumber',
            },
            {
                Header: 'ETA',
                accessor: 'eta',
            }
        ],
        []
    );
    
    const data = React.useMemo(
        () => _.reverse(_.sortBy(encounters, function(e){return e.createdAt})),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [encounters.length]
    )
    const tableInstance = useTable({ columns, data, defaultColumn }, useBlockLayout, useResizeColumns);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance
    return (
        <div className="allEncountersList">
            <div className="encountersTitle">{title}</div>

            <div className="detailedEncountersTable" {...getTableProps()}>
                {_.map(headerGroups, function(headerGroup){return (
                    <div className="encountersTableHeader" {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <div {...column.getHeaderProps()} className="encountersCell headerCell encountersColumn">
                                <div className="title">{column.render('Header')}</div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                />
                            </div>
                        ))}
                    </div>
                )})}

                <div className="encountersTableCells" {...getTableBodyProps()}>
                    <TransitionGroup className="encounter-list">
                        {
                            (encounters) ? _.map(rows, function(row){
                                prepareRow(row);
                                let encounter = row.original;
                                let rowContent = (
                                    <div {...row.getRowProps()} className={`encountersTableCell ${isSelectedEncounter(encounter) ? "selected": ""}`} onClick={onEncounterSelect ? onEncounterSelect.bind(this, encounter): null}>
                                        {
                                            _.map(row.cells, function(cell, idx){
                                                return (
                                                    <div {...cell.getCellProps()} className="encountersCell rowCell encountersColumn">
                                                        <div className="title">
                                                            {
                                                                encounter.testEncounter && idx === 0 ? (
                                                                    <div className="testIndicator">
                                                                        <div className="indicatorTitle">TEST</div>
                                                                    </div>
                                                                ): null
                                                            }
                                                            {cell.render('Cell')}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {/*<div className="encountersCell rowCell encountersColumn columnCallerNumber">*/}
                                        {/*    <div className="title"><PhoneNumberDisplay phoneNumber={e.contact != null ? e.contact.phone : ""}/></div>*/}
                                        {/*</div>*/}

                                        {/*<div className="encountersCell rowCell encountersColumn columnSource">*/}
                                        {/*    <div className="title">{e.source.sourceProvider}</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="encountersCell rowCell encountersColumn columnLocation">*/}
                                        {/*    <div className="title">{e.encounterLocation != null ? e.encounterLocation.summary : "UNKOWN"}</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="encountersCell rowCell encountersColumn columnResponseTime">*/}
                                        {/*    <div className="title">{*/}
                                        {/*        _.join(_.map(e.units, function(u){return u.unitId;}))*/}
                                        {/*    }</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="encountersCell rowCell encountersColumn columnReportedDate">*/}
                                        {/*    <div className="title">{moment.utc(e.encounterDate).format("MM/DD/YYYY, h:mm:ss a")}</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="encountersCell rowCell encountersColumn columnStatus">*/}
                                        {/*    <div className="title">{e.status}</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="encountersCell rowCell encountersColumn columnPriority">*/}
                                        {/*    <div className="title">{e.priority}</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="encountersCell rowCell encountersColumn columnIncidentNumber">*/}
                                        {/*    <div className="title">{e.sourceRefId}</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="encountersCell rowCell encountersColumn columnRuleMatches">*/}
                                        {/*    <div className="ruleItem none">*/}
                                        {/*        <div className="title">None</div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {/*<div className="encountersCell rowCell encountersColumn columnRuleTarget">*/}
                                        {/*    <div className="ruleItem none">*/}
                                        {/*        <div className="title">None</div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="separator"/>
                                    </div>
                                )
                                return (
                                    <CSSTransition
                                        key={encounter.id}
                                        timeout={5000}
                                        classNames="encounter"
                                    >
                                        {
                                            includeEncounterDetailsModal ? (
                                                <EncounterDetails encounterId={encounter.id}>
                                                    {rowContent}
                                                </EncounterDetails>
                                            ) : rowContent
                                        }
                                    </CSSTransition>
                                )
                            }) : null
                        }
                    </TransitionGroup>
                </div>
            </div>
        </div>
    )
}
