import {API} from "aws-amplify";
import {toast} from "react-toastify";
import React from "react";
import Select from "react-select";


export default function DepartmentSelect({onSelect, value}){
    const [departments, setDepartments] = React.useState([]);

    const loadDepartments = () => {
        API.get("Core", "/api/v1/department")
            .then(response => {
                    setDepartments(response);
                },
                error => {
                    toast("Could not get departments: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
    }

    React.useEffect(() => {
        loadDepartments();
    }, []);

    const onDepartmentSelect = (obj, action) => {
        switch (action.action) {
            case "select-option":
                onSelect(obj);
                break;
            case "clear":
                onSelect(null);
                break;
        }
    }

    if(!departments || departments.length == 0){
        return (<span>No Departments</span>);
    }

    return (
        <Select className="selectCell" classNamePrefix="selectCell"
                isDisabled={false}
                onChange={onDepartmentSelect}
                value={value ? value : "--"}
                options={departments}
                isClearable={true}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}/>
    );
}