import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { Editor, EditorState, getDefaultKeyBinding } from 'draft-js';
import Popup from "reactjs-popup";
import CurrencyFormat from "react-currency-format";

import { updateEditorState, postConversationMessages } from "@/shared/conversation/actions";
import { GetDz } from "@/common/Dropzone";
import { uploadFile, clearUploads, clearUpload } from "@/shared/file_uploads/actions";
import { fetchAllServiceOfferings } from "@/shared/services/actions";
import { fetchAllProviders } from "@/shared/providers/actions";
import LibraryPicker from "@/routes/library/LibraryPicker";
import Modal from "@/common/Modal";
import {
    convoActionFile,
    convoActionLibrary,
    convoActionLocation,
    convoActionRefer,
    convoActionService,
    convoActionVideo
} from "@/shared/access";

const mapStateToProps = state => ({
    editorState: state.conversation.editor.state,
    editorSaving: state.conversation.editor.saving,
    activeConversationId: state.conversation.activeConversationId,
    filesState: state.files,
    ...state
})

const mapDispatchToProps = dispatch => ({
    loadServiceOfferings: () => dispatch(fetchAllServiceOfferings()),
    loadProviders: () => dispatch(fetchAllProviders()),
    updateEditorState: (editorState) => dispatch(updateEditorState(editorState)),
    postConversationMessage: (conversationId, message) => dispatch(postConversationMessages(conversationId, message)),
    uploadFile: (key, file) => dispatch(uploadFile(key, file)),
    clearUploads: (key) => dispatch(clearUploads(key)),
    clearUpload: (key, file) => dispatch(clearUpload(key, file))
})

const DZ_KEY = "DZ_CONVERSATION_INPUT";

function customKeyBindingFn(e) {
    if (e.keyCode === 13 /* `S` key */) {
        return 'post-message';
    }
    return getDefaultKeyBinding(e);
}

function ConversationInput(props) {
    const { chatInputBarRef, activeConversationId } = props;
    const [editorFocused, setEditorFocused] = React.useState(false);
    const [videoChatAdded, setVideoChatAdded] = React.useState(false);
    const [requestLocationAdded, setRequestLocationAdded] = React.useState(false);
    const [referralsAdded, setReferralsAdded] = React.useState([]);
    const [numReferrals, setNumReferrals] = React.useState(0);
    const [serviceOfferingsAdded, setServiceOfferingsAdded] = React.useState([]);
    const [libraryItems, setLibraryItems] = React.useState([]);
    const [numServiceOfferings, setNumServiceOfferings] = React.useState(0);
    const [libraryOpen, setLibraryOpen] = React.useState(false);
    let domEditor;
    const setDomEditorRef = ref => domEditor = ref;
    function toggleFocus() {
        setEditorFocused(!editorFocused);
    }

    function containerClick() {
        domEditor.focus();
    }

    React.useEffect(() => {
        props.loadServiceOfferings();
        props.loadProviders();
    }, []);

    React.useEffect(() => {
        resetEditor();
    }, [activeConversationId])

    function postMessage() {
        if (isDisabled()) {
            return;
        }
        let messageObj = {
            message: props.editorState.getCurrentContent().getPlainText(),
            attachments: _.get(props, "filesState.uploadedFiles." + DZ_KEY),
            extensions: []
        };

        if (videoChatAdded) {
            messageObj.extensions.push({ type: "VIDEO_CALL" });
        }

        if (requestLocationAdded) {
            messageObj.extensions.push({ type: "LOCATION" });
        }

        if (numServiceOfferings) {
            _.forEach(serviceOfferingsAdded, function (so) {
                messageObj.extensions.push({ type: "SERVICE_OFFERING", serviceOffering: so })
            });
        }

        if (numReferrals) {
            _.forEach(referralsAdded, function (r) {
                messageObj.extensions.push({ type: "REFERRAL", referral: r })
            });
        }

        if (libraryItems) {
            _.forEach(libraryItems, function (li) {
                messageObj.extensions.push({ type: "LIBRARY", libraryItem: li })
            })
        }

        props.postConversationMessage(props.activeConversationId, messageObj);
        resetEditor();
    }

    const openLibrary = () => {
        setLibraryOpen(true);
    }

    const closeLibrary = () => {
        setLibraryOpen(false);
    }

    const onLibrarySelection = (libraryItem) => {
        setLibraryOpen(false);
        if (!_.find(libraryItems, function (li) { return li.id == libraryItem.id })) {
            libraryItems.push(libraryItem);
            setLibraryItems(Object.assign([], libraryItems));
        }
    }

    const removeLibraryItem = (libraryItem) => {
        _.remove(libraryItems, function (li) { return li.id == libraryItem.id });
        setLibraryItems(Object.assign([], libraryItems));
    }

    function resetEditor() {
        props.clearUploads(DZ_KEY);
        setVideoChatAdded(false);
        setRequestLocationAdded(false);
        setServiceOfferingsAdded([]);
        setNumServiceOfferings(0);
        setReferralsAdded([]);
        setNumReferrals(0);
        setLibraryItems([]);
        props.updateEditorState(EditorState.createEmpty());
    }

    function addVideoChat() {
        setVideoChatAdded(true);
    }

    function removeVideo() {
        setVideoChatAdded(false);
    }

    function addRequestLocation() {
        setRequestLocationAdded(true);
    }

    function removeRequestLocation() {
        setRequestLocationAdded(false);
    }

    function removeFile(file) {
        props.clearUpload(DZ_KEY, file);
    }

    function isDisabled() {
        let buttonDisabled = ((inProgressUploads && inProgressUploads.length > 0) || props.editorSaving || !props.editorState.getCurrentContent().hasText());

        if (buttonDisabled) {
            
            if (numServiceOfferings > 0 || numReferrals > 0 || requestLocationAdded || videoChatAdded || (libraryItems && libraryItems.length > 0) ||
                Object.values(props.filesState.uploadedFiles).some(k => k && k.length > 0)) {
                buttonDisabled = false;
            }
        }
        return buttonDisabled;
    }

    const addServiceOffering = (serviceOffering) => {
        if (!_.find(serviceOfferingsAdded, function (so) { return so.id == serviceOffering.id })) {
            serviceOfferingsAdded.push(serviceOffering);
            setServiceOfferingsAdded(serviceOfferingsAdded);
        }
        setNumServiceOfferings(serviceOfferingsAdded.length);
    }

    const addReferral = (provider) => {
        if (!_.find(referralsAdded, function (r) { return r.id == provider.id })) {
            referralsAdded.push(provider);
            setReferralsAdded(referralsAdded);
        }
        setNumReferrals(referralsAdded.length);
    }

    const removeServiceOffering = (serviceOffering) => {
        _.remove(serviceOfferingsAdded, function (so) { return so.id == serviceOffering.id });
        setServiceOfferingsAdded(serviceOfferingsAdded);
        setNumServiceOfferings(serviceOfferingsAdded.length);
    }

    const removeReferral = (provider) => {
        _.remove(referralsAdded, function (r) { return r.id == provider.id });
        setReferralsAdded(referralsAdded);
        setNumReferrals(referralsAdded.length);
    }

    function handleKeyCommand(command) {
        if (command === 'post-message') {
            postMessage();
            // Perform a request to save your contents, set
            // a new `editorState`, etc.
            return 'handled';
        }
        return 'not-handled';
    }


    let uploadedFiles = _.get(props, "filesState.uploadedFiles." + DZ_KEY);
    let inProgressUploads = _.get(props, "filesState.inProgressUploads." + DZ_KEY);

    const { getRootProps, getInputProps, open, acceptedFiles } = GetDz(DZ_KEY, props.uploadFile, {});
    return (
        <div className="chatInputBar">
            <div className="chatInputContainer" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className={`chatInputCell ${editorFocused ? "isFocused" : ""} ${(props.editorState.getCurrentContent().hasText() || videoChatAdded || numServiceOfferings) ? "hasInput" : ""}`} onClick={containerClick} ref={chatInputBarRef}>
                    <Editor editorState={props.editorState}
                        onChange={props.updateEditorState}
                        placeholder={"Write a response..."}
                        spellCheck={true}
                        stripPastedStyles={true}
                        handleKeyCommand={handleKeyCommand}
                        keyBindingFn={customKeyBindingFn}
                        onBlur={toggleFocus}
                        onFocus={toggleFocus}
                        ref={setDomEditorRef}
                        readOnly={props.editorSaving}
                    />
                    <ul>
                        {_.map(uploadedFiles, function (file, fileIdx) {
                            return (
                                <div target="_blank" className="attachment compact videoCall" key={fileIdx}>
                                    <div className="icon"></div>
                                    <div className="attachmentDetails">
                                        <div className="attachmentTitle">{file.name}</div>
                                    </div>
                                    <div className="clear" onClick={removeFile.bind(this, file)}></div>
                                </div>)
                        })}
                    </ul>
                    {
                        videoChatAdded ? (
                            <div target="_blank" className="attachment compact videoCall">
                                <div className="icon"></div>
                                <div className="attachmentDetails">
                                    <div className="attachmentTitle">Video Call</div>
                                    <div className="attachmentSubtitle">Send a link to Video Call</div>
                                </div>
                                <div className="clear" onClick={removeVideo}></div>
                            </div>
                        ) : null
                    }
                    {
                        requestLocationAdded ? (
                            <div target="_blank" className="attachment compact location">
                                <div className="icon"></div>
                                <div className="attachmentDetails">
                                    <div className="attachmentTitle">Request Location</div>
                                    <div className="attachmentSubtitle">Send a link to Request Location</div>
                                </div>
                                <div className="clear" onClick={removeRequestLocation}></div>
                            </div>
                        ) : null
                    }
                    {
                        libraryItems && libraryItems.length > 0 ? (
                            <div className="libraryItemsContainer">
                                {
                                    _.map(libraryItems, function (li, liIdx) {
                                        return (
                                            <div className="attachment compact library" key={liIdx}>
                                                <div className="icon">
                                                    <div className="glyph" />
                                                </div>
                                                <div className="attachmentDetails">
                                                    <div className="attachmentTitle">{li.name}</div>
                                                    <div className="attachmentSubtitle">{li.contents}</div>
                                                </div>
                                                <div className="clear" onClick={removeLibraryItem.bind(this, li)}></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null
                    }
                    {
                        numServiceOfferings ? (
                            <div className="serviceOfferingsContainer">
                                {
                                    _.map(serviceOfferingsAdded, function (so, soIdx) {
                                        return (
                                            <div className="attachment compact serviceOffering" key={soIdx}>
                                                <div className="icon">
                                                    <div className="glyph" />
                                                </div>
                                                <div className="attachmentDetails">
                                                    <div className="attachmentTitle">{so.name}</div>
                                                    <div className="attachmentSubtitle">{so.details}</div>
                                                </div>
                                                <div className="clear" onClick={removeServiceOffering.bind(this, so)}></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null
                    }
                    {
                        numReferrals ? (
                            <div className="referralsContainer">
                                {
                                    _.map(referralsAdded, function (r, rIdx) {
                                        return (
                                            <div className="attachment compact referral" key={rIdx}>
                                                <div className="icon">
                                                    <div className="glyph" />
                                                </div>
                                                <div className="attachmentDetails">
                                                    <div className="attachmentTitle">{r.name}</div>
                                                    <div className="attachmentSubtitle">{r.description}</div>
                                                </div>
                                                <div className="clear" onClick={removeReferral.bind(this, r)}></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null
                    }
                    <div className={`send ${isDisabled() ? "disabled" : ""}`} onClick={postMessage}>
                        <div className="icon" />
                    </div>
                </div>
            </div>
            <div className="chatInputActions">
                <div className="actionsOverlay" />
                <div className="actionsList">
                    {
                        convoActionVideo() ? (
                            <div className="inputAction videoCall" onClick={addVideoChat}>
                                <div className="icon"></div>
                                <div className="title">Video Call</div>
                            </div>
                        ) : null
                    }
                    {
                        convoActionLocation() ? (
                            <div className="inputAction requestLocation" onClick={addRequestLocation}>
                                <div className="icon"></div>
                                <div className="title">Request Location</div>
                            </div>
                        ) : null
                    }
                    {
                        convoActionRefer() ? (
                            <Popup position={"top center"} trigger={(<div className="inputAction refer">
                                <div className="icon"></div>
                                <div className="title">Refer</div>
                            </div>)}>
                                <div className={"referral pullDownMenu"}>
                                    {
                                        _.map(props.providers.providers, function (provider, providerIdx) {
                                            return (
                                                <div onClick={addReferral.bind(this, provider)} className={"cell detailed hasIcon"} key={providerIdx}>
                                                    <div className="icon">
                                                        <div className="glyph" />
                                                    </div>
                                                    <div className="cellBody">
                                                        <div className="title serviceName">{provider.name}</div>
                                                        <div className="detail description">{provider.description}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Popup>
                        ) : null
                    }
                    {
                        convoActionFile() ? (
                            <div className="inputAction addAttachment" onClick={open}>
                                <div className="icon"></div>
                                <div className="title">Share File</div>
                            </div>
                        ) : null
                    }
                    {
                        convoActionLibrary() ? (
                            <Modal
                                button={(
                                    <div className="inputAction library">
                                        <div className="icon"></div>
                                        <div className="title">Library</div>
                                    </div>)}
                                content={(
                                    <LibraryPicker
                                        onSelect={onLibrarySelection}
                                        onCancel={closeLibrary} />
                                )}
                                title="Select from Library"
                                size="xl"
                                handleClose={closeLibrary}
                                handleOpen={openLibrary}
                                open={libraryOpen}
                            />
                        ) : null
                    }
                    {
                        convoActionService() ? (
                            <Popup position={"top center"} trigger={(<div className="inputAction serviceOffering">
                                <div className="icon"></div>
                                <div className="title">Offer Service</div>
                            </div>)}>
                                <div className={"serviceOffering pullDownMenu"}>
                                    {
                                        _.map(props.services.services, function (service, serviceIdx) {
                                            return (
                                                <div onClick={addServiceOffering.bind(this, service)} className={"cell detailed hasIcon"} key={serviceIdx}>
                                                    <div className="icon">
                                                        <div className="glyph" />
                                                    </div>
                                                    <div className="cellBody">
                                                        <div className="title serviceName">{service.name}</div>
                                                        <div className="detail description">{service.details}</div>
                                                    </div>
                                                    <div className="attribute price">
                                                        <CurrencyFormat value={(service.price ? service.price : 0)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} prefix={'$'} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Popup>
                        ) : null
                    }
                    <div className="inputAction spacer" />
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationInput);
