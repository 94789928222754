import React, {forwardRef} from 'react'

import MaterialTable from '@material-table/core';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  /**
   * @param columns
   * @param data
   * @param title
   * @param pageSizeOptions [options=[5,10,20]]
   * @param components
   * @param actions
   * @param rowStyle
   * @param style
   * @param options
   */
function TableDisplay({columns, data, title, pageSizeOptions, components, actions, rowStyle, style, options})
{
    const PAGE_SIZE_OPTIONS = pageSizeOptions || [5,10,20];
    
    const OPTIONS = {
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        pageSize: PAGE_SIZE_OPTIONS[0],
        sorting: true,
        padding: 'dense',
        draggable: false,
        thirdSortClick: false,
        ...options
    }

    if(data.length == 0)
    {
        components = {
            ...components,
            Body: () => (<tbody>
                <tr style={{height: 180}}>
                    <td colSpan={columns.length} style={{textAlign: 'center', verticalAlign: 'middle'}}>No records to display</td>
                </tr>
            </tbody>)
        }
    }

    if(rowStyle) OPTIONS.rowStyle = rowStyle;


    return (<MaterialTable columns={columns} data={data}
        title={title} options={OPTIONS} components={components}
                           padding={'dense'}
        actions={actions} icons={tableIcons} style={style}/>);
}

export default TableDisplay