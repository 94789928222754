import {
    GET_PROVIDER_ROTATIONS, GET_PROVIDER_ROTATIONS_SUCCESS,
    GET_PROVIDERS, GET_PROVIDERS_CATEGORIES, GET_PROVIDERS_CATEGORIES_SUCCESS, GET_PROVIDERS_SUCCESS
} from './actions'

const initialState = {
    providers: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PROVIDERS:
            return state;
            break;
        case GET_PROVIDERS_SUCCESS:
            return Object.assign({}, state, {
                providers: action.payload
            });
            break;
        case GET_PROVIDER_ROTATIONS:
            return state;
            break;
        case GET_PROVIDER_ROTATIONS_SUCCESS:
            return Object.assign({}, state, {
                rotations: action.payload
            });
            break;
        default:
            return state
    }
}