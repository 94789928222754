import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core";
import RequestResetPasswordCode from './RequestResetPasswordCode';
import CustomChangePassword from './CustomChangePassword';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '460px',
        backgroundColor: theme.palette.common.white,
        padding: '35px 40px',
        borderRadius: '6px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    '#form-container': {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function CustomResetPassword(props) {
    const [codeRequested, setCodeRequested] = useState(false);
    const [email, setEmail] = useState(null);
    const CLASSES = useStyles();

    if(!props.authState || props.authState !== 'forgotPassword') return null;

    return (
        <div className='forgotPassword'>
            <div className={CLASSES.paper}>
                {codeRequested ? <CustomChangePassword email={email} {...props}/> : <RequestResetPasswordCode email={email} updateEmail={setEmail} {...props} updateCode={setCodeRequested}/>}
            </div>
        </div>
    );
}