import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  pt: {
    translation: {
      "Your feedback is appreciated!": "Agradecemos seus comentários!",
      "OR": "OU",
      "questions": "perguntas",
      "citizen@gmail.com": "cidadao@gmail.com",
      "Phone": "Telefone",
      "Start Survey": "Iniciar Pesquisa",
      "Characters Remaining": "Caracteres Restantes",
      "Character Limit": "Limite de Caracteres",
      "Submit Survey": "Enviar Pesquisa",
      "Thank you!": "Obrigado!",
      "Thank you for completing the survey.": "Obrigado por completar a pesquisa.",
      "Loading...": "Carregando...",
      "This survey is no longer available.": "Esta pesquisa não está mais disponível.",
      "Survey Not Found": "Pesquisa não encontrada"
    }
  },
  es: {
    translation: {
      "Your feedback is appreciated!": "¡Apreciamos tus comentarios!",
      "OR": "O",
      "questions": "preguntas",
      "citizen@gmail.com": "ciudadano@gmail.com",
      "Phone": "Teléfono",
      "Start Survey": "Empezar Encuesta",
      "Characters Remaining": "Caracteres Restantes",
      "Character Limit": "Límite de Caracteres",
      "Start Survey": "Iniciar Pesquisa",
      "Submit Survey": "Enviar Encuesta",
      "Thank you!": "¡Gracias!",
      "Thank you for completing the survey.": "Gracias por completar la encuesta.",
      "Loading...": "Cargando...",
      "This survey is no longer available.": "Esta encuesta ya no está disponible.",
      "Survey Not Found": "Encuesta no encontrada"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'navigator'],
    
      // keys or params to lookup language from
      lookupQuerystring: 'lng',
    },
    resources,
    // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;