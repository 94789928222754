import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import _ from "lodash";
import 'react-day-picker/dist/style.css';
import { ContentState, Editor, EditorState } from "draft-js";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import dateFnsFormat from 'date-fns/format';


import InputCell from "@/common/form/InputCell";
import LocalTimeStamp from "@/common/LocalTimeStamp";
import Modal from "@/common/Modal";
import ImageViewerButton from "@/components/ImageViewerButton";
import { ehrSections } from "@/utils/utils";

import VerifyInsurance from "./VerifyInsurance";

export default function PatientDetails({ conversation, dispositions, onCancel, pmhOptions, reloadConversation, ...props }) {

    const INITIAL_REPORT = "INITIAL_REPORT";
    const DEMOGRAPHICS = "DEMOGRAPHICS";
    const INTERACTION = "INTERACTION";
    const INSURANCE = "INSURANCE";

    const [patientDetailsOpen, setPatientDetailsOpen] = React.useState(false);
    const [personDemographics, setPersonDemographics] = React.useState(false);
    const [reviewMatches, setReviewMatches] = React.useState(false);
    const [interaction, setInteraction] = React.useState(false);
    const [cellPhoneVal, setCellPhoneVal] = React.useState("");
    const [possibleMatches, setPossibleMatches] = React.useState([]);
    const [pastInteractions, setPastInteractions] = React.useState([]);
    const [secondaryPhoneVal, setSecondaryPhoneVal] = React.useState("");
    const [activeTab, setActiveTab] = React.useState(INITIAL_REPORT);
    const [birthday, setBirthday] = React.useState("");
    const [editorState, setEditorState] = React.useState();
    const firstNameRef = React.createRef();
    const lastNameRef = React.createRef();
    const heightFeetRef = React.createRef();
    const heightInchesRef = React.createRef();
    const weightRef = React.createRef();
    const ageRef = React.createRef();
    const heartRateRef = React.createRef();
    const heartRhythmRef = React.createRef();
    const chiefComplaintRef = React.createRef();
    const respRateRef = React.createRef();
    const [multiSelectValue, setMultiSelectValue] = React.useState([]); //the array of options that have been created after tab or enter
    const [pmhValue, setPmhValue] = React.useState();  //the current option being typed in
    const [medsMultiSelectValue, setMedsMultiSelectValue] = React.useState([]); //the array of options that have been created after tab or enter
    const [medsValue, setMedsValue] = React.useState();  //the current option being typed in
    const [allergiesMultiSelectValue, setAllergiesMultiSelectValue] = React.useState([]); //the array of options that have been created after tab or enter
    const [allergiesValue, setAllergiesValue] = React.useState();  //the current option being typed in
    const sp02Ref = React.createRef();
    const bloodGlucoseRef = React.createRef();
    const bpSysRef = React.createRef();
    const bpDiRef = React.createRef();
    const birthdayRef = React.createRef();
    const [epcrRecordNumber, setEpcrRecordNumber] = React.useState()
    const [epcrFetchedAt, setEpcrFetchedAt] = React.useState()
    const [finalPdfUrl, setFinalPdfUrl] = React.useState();
    const [eSignedAt, setESignedAt] = React.useState(null)
    const [signatoryName, setSignatoryName] = React.useState(null)
    const [incidentNumber, setIncidentNumber] = React.useState(null)
    const [intake, setIntake] = React.useState(null)
    const [hasLinkedEncounter, setHasLinkedEncounter] = React.useState(null)

    let domEditor;
    const setDomEditorRef = ref => domEditor = ref;


    const setRecordsFromAllResponse = (response) => {
        setPersonDemographics((response.demographics ? response.demographics : {}));
        setInteraction((response.interaction ? response.interaction : {}));
        setPastInteractions((response.pastInteractions ? response.pastInteractions : []));
        setEpcrRecordNumber(response.epcrRecordNumber);
        setEpcrFetchedAt(response.epcrFetchedAt);
        setFinalPdfUrl(response.finalPdfUrl);
        setESignedAt(response.eSignedAt);
        setSignatoryName(response.signatoryName);
        setIncidentNumber(response.incidentNumber);
        setIntake(response.intake);
        setHasLinkedEncounter(response.hasLinkedEncounter);

        if(response.interaction && response.interaction.pastMedicalHistory && response.interaction.pastMedicalHistory.length >0) {
            setMultiSelectValue(existingMulti(response.interaction.pastMedicalHistory.split(',')));
        } else {
            setMultiSelectValue([]);
        }

        if (response.interaction && response.interaction.currentMedications && response.interaction.currentMedications.length > 0) {
            setMedsMultiSelectValue(existingMulti(response.interaction.currentMedications.split(',')));
        } else {
            setMedsMultiSelectValue([]);
        }

        if (response.interaction && response.interaction.allergies && response.interaction.allergies.length > 0) {
            setAllergiesMultiSelectValue(existingMulti(response.interaction.allergies.split(',')));
        } else {
            setAllergiesMultiSelectValue([]);
        }
    }

    const tabs = [
        INITIAL_REPORT,
        DEMOGRAPHICS,
        INTERACTION,
        INSURANCE
    ]

    function formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
      }

    const getTabTitle = (tabKey) => {
        // eslint-disable-next-line default-case
        switch (tabKey) {
            case INITIAL_REPORT:
                return "Initial Report";
            case DEMOGRAPHICS:
                return "Demographics";
            case INTERACTION:
                return "Clinical";
            case INSURANCE:
                return "Insurance";
        }
    }

    const pmhOptionsMulti = (options) => {
        return _.map(options, function (option) {
            return { value: option.optionName, label: option.optionName }
        })
    }

    const existingMulti = (p) => {
        return _.map(p, function (p) {
            return { value: p, label: p }
        })
    }

    const searchPossibleMatches = () => {
        // DOB is set directly on object. Use REF for lastname
        if (!(birthdayRef.current && birthdayRef.current.value) || !(lastNameRef.current && lastNameRef.current.value)) {
            return;
        }

        API.get('Core', '/api/v1/records/search', {
            queryStringParameters: {
                dob: birthdayRef.current.value,
                lastName: lastNameRef.current.value
            }
        })
            .then(response => {
                setPossibleMatches(response);
            },
                error => {
                    toast("Error searching for possible matches: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const loadDemographics = () => {
        API.get('Core', '/api/v1/conversation-records/' + conversation.id + '/all')
            .then(response => {
                setRecordsFromAllResponse(response);
            },
                error => {
                    toast("Could not load patient information: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                });
    }

    const associateExistingPatient = (connectorResult) => {
        API.put('Core', '/api/v1/conversation-records/' + conversation.id + '/associate-person-demographics', { body: connectorResult })
            .then(response => {
                setRecordsFromAllResponse(response);
                setReviewMatches(false);
            },
                error => {
                    toast("Could not associate patient record: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const onInsuranceSelect = (insurance) => {
        interaction.insurance = insurance;
        setInteraction(Object.assign({}, interaction));
        saveDemographics(false);
    }

    const saveDemographics = (closeModal) => {
        let updateBody = {
            demographics: personDemographics,
            interaction: interaction
        }

        if (activeTab == DEMOGRAPHICS) {
            //let heightInchesVal = parseInt(((heightFeetRef.current && heightFeetRef.current.value) ? (heightFeetRef.current.value * 12) : 0)) + parseFloat(((heightInchesRef.current && heightInchesRef.current.value) ? heightInchesRef.current.value : 0));
            let demographicsUpdate = {
                firstName: firstNameRef.current.value,
                lastName: lastNameRef.current.value,
                homephone: secondaryPhoneVal,
                gender: personDemographics.gender,
                dateOfBirth: birthdayRef.current.value,
            }
            if (personDemographics.primaryContact) {
                demographicsUpdate.primaryContact = personDemographics.primaryContact;
                demographicsUpdate.primaryContact.phone = cellPhoneVal;
            }
            else {
                demographicsUpdate.primaryContact = {
                    phone: cellPhoneVal
                }
            }
            // Don't send insurance except on insurance tab
            if (updateBody.interaction) {
                updateBody.interaction.insurance = null;
            }

            updateBody.demographics = demographicsUpdate;
        }
        else if (activeTab == INTERACTION) {
            let interactionUpdate = {
                heartRateBPM: heartRateRef.current.value,
                heartRhythm: heartRhythmRef.current.value,
                type: "EMS",
                notes: editorState.getCurrentContent().getPlainText(),
                respiratoryRateBPM: respRateRef.current.value,
                bpdiastolic: bpDiRef.current.value,
                bpsystolic: bpSysRef.current.value,
                spO2: sp02Ref.current.value,
                pastMedicalHistory: joinValues(multiSelectValue),
                currentMedications: joinValues(medsMultiSelectValue),
                allergies: joinValues(allergiesMultiSelectValue),
                bloodGlucose: bloodGlucoseRef.current.value
            }
            // Don't send insurance except on insurance tab
            if (updateBody.interaction) {
                updateBody.interaction.insurance = null;
            }
            updateBody.interaction = interactionUpdate;
        }
        else if (activeTab == INITIAL_REPORT) {
            let interactionUpdate = {
                chiefComplaint: chiefComplaintRef.current.value,
                heartRateBPM: heartRateRef.current.value,
                heartRhythm: heartRhythmRef.current.value,
                type: "EMS",
                notes: editorState.getCurrentContent().getPlainText(),
                respiratoryRateBPM: respRateRef.current.value,
                bpdiastolic: bpDiRef.current.value,
                bpsystolic: bpSysRef.current.value,
                spO2: sp02Ref.current.value,
                age: ageRef.current.value,
                pastMedicalHistory: joinValues(multiSelectValue),
                currentMedications: joinValues(medsMultiSelectValue),
                allergies: joinValues(allergiesMultiSelectValue),
                bloodGlucose: bloodGlucoseRef.current.value
            }
            // Don't send insurance except on insurance tab
            if (updateBody.interaction) {
                updateBody.interaction.insurance = null;
            }
            updateBody.interaction = interactionUpdate;
        }
        else if (activeTab == INSURANCE) {
            // insurance objet updated in VerifyInsurance object and callback method onInsuranceSelect
            updateBody.interaction = interaction;
        }

        API.put('Core', '/api/v1/conversation-records/' + conversation.id + '/all', { body: updateBody })
            .then(response => {
                setRecordsFromAllResponse(response);
                if (closeModal) {
                    setPatientDetailsOpen(false);
                    toast("Successfully Saved Patient Information!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                }
            },
                error => {
                    toast("Could not save patient information: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    React.useEffect(() => {
        // Call this to effectively reset
        setRecordsFromAllResponse({});
        loadDemographics();
    }, [conversation.id])

    const changeTab = (tab) => {
        saveDemographics();
        setActiveTab(tab);
    }

    React.useEffect(() => {
        if (personDemographics && personDemographics.primaryContact && personDemographics.primaryContact.phone) {
            setCellPhoneVal(personDemographics.primaryContact.phone);
        }

        if (personDemographics && personDemographics.homePhone) {
            setSecondaryPhoneVal(personDemographics.homePhone);
        }
    }, [personDemographics]);

    React.useEffect(() => {
        let initEditorState = ContentState.createFromText(interaction && interaction.notes ? interaction.notes : "");
        setEditorState(EditorState.createWithContent(initEditorState));
    }, [interaction])

    const setGender = (obj, action) => {
        switch (action.action) {
            case "select-option":
                personDemographics.gender = obj.value;
                setPersonDemographics(Object.assign({}, personDemographics));
                break;
        }
    }

    const isInteractionEmpty = () => {
        if (!interaction) {
            return true;
        }
        let res = !interaction.heartRateBPM && !interaction.bpsystolic && !interaction.bpdiastolic && !interaction.respiratoryRateBPM;
        return res;
    }

    const getInteractionSummary = () => {
        if (!interaction) {
            return "";
        }
        let parts = [];
        if (interaction.heartRateBPM) {
            parts.push(interaction.heartRateBPM + " BPM");
        }

        if (interaction.bpsystolic && interaction.bpdiastolic) {
            parts.push("BP " + interaction.bpsystolic + "/" + interaction.bpdiastolic);
        }

        return parts.join("; ");
    }

    const handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
        //setBirthday(dayPickerInput.getInput().value.trim())
        if (selectedDay) {
            personDemographics.dateOfBirth = formatDate(selectedDay);
            setPersonDemographics(Object.assign({}, personDemographics));
            searchPossibleMatches();
        }
        else if (!dayPickerInput.getInput().value) {
            personDemographics.dateOfBirth = "";
            setPersonDemographics(Object.assign({}, personDemographics));
        }
        setBirthday(selectedDay);
    }

    const updateEditorState = (newEditorState) => {
        setEditorState(newEditorState);
    }


    const handleChange = (value, actionMeta) => {
        console.group('Value Changed');
        console.log(value);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        if (value != null) {
            setMultiSelectValue(value);
        } else {
            setMultiSelectValue([]);
        }
        joinValues(multiSelectValue);
    }
    const handleInputChange = (newVal) => {
        setPmhValue(newVal);
    }
    const handleKeyDown = (event) => {
        if (!pmhValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setMultiSelectValue(multiSelectValue.concat(createOption(pmhValue)));
                console.group('Value Added');
                console.log(multiSelectValue);
                console.groupEnd();
                setPmhValue('');
                event.preventDefault();
                joinValues(multiSelectValue);
        }
    }
    const createOption = (label) => ({
        label,
        value: label
    })
    const joinValues = (arrayIn) => {
        if (arrayIn != null && arrayIn.length > 0) {
            var valuesOnly = [];
            for (var j = 0; j < arrayIn.length; j++) {
                valuesOnly.push(arrayIn[j].label);
            }
            return valuesOnly.join(',');
        } else {
            return '';
        }
    }

    const components = {
        DropdownIndicator: null
    };

    const handleMedChange = (value, actionMeta) => {
        console.group('Value Changed');
        console.log(value);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        if (value != null) {
            setMedsMultiSelectValue(value);
        } else {
            setMedsMultiSelectValue([]);
        }
        joinValues(medsMultiSelectValue);
    }
    const handleMedsInputChange = (newVal) => {
        setMedsValue(newVal);
    }
    const handleMedsKeyDown = (event) => {
        if (!medsValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setMedsMultiSelectValue(medsMultiSelectValue.concat(createOption(medsValue)));
                console.group('Value Added');
                console.log(medsMultiSelectValue);
                console.groupEnd();
                setMedsValue('');
                event.preventDefault();
                joinValues(medsMultiSelectValue);
        }
    }

    const handleAllergiesChange = (value, actionMeta) => {
        console.group('Value Changed');
        console.log(value);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        if (value != null) {
            setAllergiesMultiSelectValue(value);
        } else {
            setAllergiesMultiSelectValue([]);
        }
        joinValues(allergiesMultiSelectValue);
    }
    const handleAllergiesInputChange = (newVal) => {
        setAllergiesValue(newVal);
    }
    const handleAllergiesKeyDown = (event) => {
        if (!allergiesValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setAllergiesMultiSelectValue(allergiesMultiSelectValue.concat(createOption(allergiesValue)));
                console.group('Value Added');
                console.log(allergiesMultiSelectValue);
                console.groupEnd();
                setAllergiesValue('');
                event.preventDefault();
                joinValues(allergiesMultiSelectValue);
        }
    }

    const [activeEHRModal, setActiveEHRModal] = useState(null)

    const showEHRModal = (targetEHRTab) => {

        if(activeEHRModal && conversation && activeEHRModal.conversation && activeEHRModal.conversation.id !== conversation.id) {
            activeEHRModal.close();
            openEHRModal(targetEHRTab)
        } 
        else if (!activeEHRModal || activeEHRModal.closed) openEHRModal(targetEHRTab)
        else activeEHRModal.focus();
    }

    const closeEHRModalCallback = (e) => {
        setActiveEHRModal(null);
        loadDemographics()
        reloadConversation()
    }

    const openEHRModal = (targetEHRTab) => {
      const url = `/ehr/${conversation.id}`;
      const windowEHRModal = window.open(url, url, 'width=1150,height=900');
      windowEHRModal.data = {
        interaction: interaction,
        demographics: personDemographics,
        epcrRecordNumber: epcrRecordNumber,
        epcrFetchedAt: epcrFetchedAt,
        finalPdfUrl: finalPdfUrl,
        eSignedAt: eSignedAt,
        signatoryName: signatoryName,
        incidentNumber: incidentNumber,
        intake: intake,
        hasLinkedEncounter: hasLinkedEncounter
      }

      windowEHRModal.conversation = conversation
      windowEHRModal.dispositions = dispositions

      windowEHRModal.targetEHRTab  = targetEHRTab
      windowEHRModal.addEventListener("beforeunload", closeEHRModalCallback)
      setActiveEHRModal(windowEHRModal);
    }
    
    useEffect(() => {
        if(!activeEHRModal?.window)
        {
            closeEHRModalCallback();
        }
    }, [activeEHRModal])

    const getButtons = () => {

        let insurance = (interaction && interaction.insurance && interaction.insurance.id) ? interaction.insurance : null;

        return (
            <div className="sideBarSection sectionPatientDetails">
                <div className="sectionTitle">Patient Details</div>

                <div className={`attributeCell interaction ${isInteractionEmpty() ? "empty" : ""}`}>
                    <div className="icon"></div>
                    <div className="cellBody" onClick={() => { showEHRModal(ehrSections.PATIENT_INFO); }}>
                        <div className="title">Initial Report</div>
                        <div className="detail">{isInteractionEmpty() ? "Click to Enter Initial Report" : getInteractionSummary()}</div>
                    </div>
                </div>

                <div className={`attributeCell demographics ${personDemographics ? "" : "empty"}`}>
                    <div className="icon"></div>
                    <div className="cellBody" onClick={() => { showEHRModal(ehrSections.PATIENT_INFO); }}>
                        <div className="title">Demographics</div>
                        <div className="detail">{(personDemographics && personDemographics.firstName && personDemographics.lastName) ? (personDemographics.firstName + " " + personDemographics.lastName) : "Click to Enter Details"}</div>
                    </div>
                </div>

                <div className={`attributeCell interaction ${isInteractionEmpty() ? "empty" : ""}`}>
                    <div className="icon"></div>
                    <div className="cellBody" onClick={() => { showEHRModal(ehrSections.CLINICAL); }}>
                        <div className="title">Clinical</div>
                        <div className="detail">
                            {isInteractionEmpty() ? "Click to Enter Details" : getInteractionSummary()}
                        </div>
                    </div>
                </div>

                {finalPdfUrl && 
                <a href={finalPdfUrl}>
                    <div className={`attributeCell insurance`}>
                        <div className="icon"></div>
                        <div className="cellBody">
                            <div className="title">ePCR PDF</div>
                            <div className="detail">
                                Click to Download PDF
                            </div>
                        </div>
                    </div>
                </a>}
{/*
                <div className={`attributeCell insurance ${insurance ? "" : "empty"}`}>
                    <div className="icon"></div>
                    <div className="cellBody" onClick={() => {setActiveTab(INSURANCE); setPatientDetailsOpen(true);}}>
                        <div className="title">Insurance</div>
                        <div className="detail">
                            {insurance && insurance.insurancePackage ? insurance.insurancePackage.name : ""}
                        </div>
                    </div>
                    {
                        insurance && insurance.verified ? (
                            <div className="accessory insuranceIndicator verified"></div>
                        ) : (
                            <div className="accessory insuranceIndicator invalid"></div>
                        )
                    }
                </div> 
                \*/}

                <div className="sectionSeparator">
                    <div className="separator"></div>
                </div>
            </div>
        )
    }

    const getModalContents = () => {
        return (
            <div>
                <div className="content">
                    <div className="secondaryNavBar">
                        <div className={`segmentedBar index${_.indexOf(tabs, activeTab)}`} style={{ width: "100%" }}>
                            {
                                _.map(tabs, function (t, tIdx) {
                                    return (
                                        <div onClick={() => changeTab(t)} className={`segment ${t == activeTab ? 'selected' : ''}`} key={tIdx}>
                                            <div className="title">{getTabTitle(t)}</div>
                                        </div>
                                    );
                                })
                            }
                            <div className="selection" style={{ left: (_.indexOf(tabs, activeTab) * (100 / tabs.length)) + '%', width: (100 / tabs.length) + '%' }} />
                        </div>
                    </div>

                    <div className="tableView">
                        {getTabContents(activeTab)}
                    </div>
                </div>

                <div className="actionBar">
                    <div className="actions left">
                        <div onClick={() => setPatientDetailsOpen(false)} className="button dismiss medium">
                            <div className="title">Cancel</div>
                        </div>
                    </div>

                    {
                        activeTab == INSURANCE ? (
                            null
                        ) : (
                            <div className="actions right">
                                <div onClick={() => saveDemographics(true)} className={`button tint medium`}>
                                    <div className="title">Save and Close</div>
                                </div>
                                <div onClick={() => saveDemographics(false)} className={`button confirm medium`}>
                                    <div className="title">Save</div>
                                </div>
                            </div>
                        )
                    }
                    <div className="separator"></div>
                </div>
            </div>
        )
    }

    const getCommonInteractionTabContents = () => {
        return (
            <div className="tableSection sectionInteractionDetails">
                <div className="sectionTitle">
                    <div className="title">Interaction Details</div>
                </div>
                <div className="tableCell titledSelectCell">
                    <div className="title">Past Medical History</div>
                    <CreatableSelect
                        options={pmhOptionsMulti(pmhOptions)}
                        classNamePrefix="medicalMultiSelect"
                        className="tableCell selectCell"
                        inputValue={pmhValue}
                        isClearable
                        isMulti
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder=""
                        value={multiSelectValue}
                    />
                </div>
                <div className="tableCell titledSelectCell">
                    <div className="title">Current Medications</div>
                    <CreatableSelect
                        classNamePrefix="medicalMultiSelect"
                        className="tableCell selectCell"
                        inputValue={medsValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        components={components}
                        onChange={handleMedChange}
                        onInputChange={handleMedsInputChange}
                        onKeyDown={handleMedsKeyDown}
                        placeholder=""
                        value={medsMultiSelectValue}
                    />
                </div>
                <div className="tableCell titledSelectCell">
                    <div className="title">Allergies</div>
                    <CreatableSelect
                        classNamePrefix="medicalMultiSelect"
                        className="tableCell selectCell"
                        inputValue={allergiesValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        components={components}
                        onChange={handleAllergiesChange}
                        onInputChange={handleAllergiesInputChange}
                        onKeyDown={handleAllergiesKeyDown}
                        placeholder=""
                        value={allergiesMultiSelectValue}
                    />
                </div>

                <div className="tableCell titledSelectCell">
                    <div className="title">Blood Pressure</div>
                    <InputCell
                        placeholder={"120"}
                        defaultValue={interaction ? interaction.bpsystolic : ""}
                        required
                        type={"number"}
                        id="systolic"
                        inputRef={bpSysRef}
                    />
                    <div className="valueDescription">Systolic</div>
                    <InputCell
                        placeholder={"80"}
                        defaultValue={interaction ? interaction.bpdiastolic : ""}
                        required
                        type={"number"}
                        id="diastolic"
                        inputRef={bpDiRef}
                    />
                    <div className="valueDescription">Diastolic</div>
                </div>

                <div className="tableCell titledSelectCell">
                    <div className="title">Heart Rate</div>
                    <InputCell
                        placeholder={"80"}
                        defaultValue={interaction ? interaction.heartRateBPM : ""}
                        required
                        type={"number"}
                        id="heartRate"
                        inputRef={heartRateRef}
                    />
                    <div className="valueDescription">BPM</div>
                </div>


                <div className="tableCell titledSelectCell">
                    <div className="title">Respiratory Rate</div>
                    <InputCell
                        placeholder={"12"}
                        defaultValue={interaction ? interaction.respiratoryRateBPM : ""}
                        required
                        type={"number"}
                        id="respiratoryRate"
                        inputRef={respRateRef}
                    />
                    <div className="valueDescription">per minute</div>
                </div>

                <div className="tableCell titledSelectCell">
                    <div className="title">Oxygen Saturation</div>
                    <InputCell
                        placeholder={"100"}
                        defaultValue={interaction ? interaction.spO2 : ""}
                        required
                        type={"number"}
                        id="sp02"
                        inputRef={sp02Ref}
                    />
                    <div className="valueDescription">Sp02 (%)</div>
                </div>

                <div className="tableCell titledSelectCell">
                    <div className="title">Blood Glucose</div>
                    <InputCell
                        placeholder={"130"}
                        defaultValue={interaction ? interaction.bloodGlucose : ""}
                        required
                        type={"number"}
                        id="bloodGlucose"
                        inputRef={bloodGlucoseRef}
                    />
                    <div className="valueDescription">mg/dl</div>
                </div>

                <div className="tableCell titledSelectCell">
                    <div className="title">Heart Rhythm</div>
                    <InputCell
                        placeholder={"Sinus"}
                        defaultValue={interaction ? interaction.heartRhythm : ""}
                        type={"text"}
                        id="heartRhythm"
                        inputRef={heartRhythmRef}
                    />
                </div>




                <div className="tableCell editorCell multilineCell">
                    <div className="title">Notes</div>
                    <Editor
                        readOnly={false}
                        style={{ height: "100%", width: "100%" }}
                        editorState={editorState}
                        onChange={updateEditorState}
                        spellCheck={true}
                        stripPastedStyles={true}
                        ref={setDomEditorRef}
                    />
                </div>
            </div>
        )
    }

    const getTabContents = (tabKey) => {
        switch (tabKey) {
            case DEMOGRAPHICS:
                // Filter currently selected demographics if it exists
                let filteredPossibleMatches = possibleMatches;
                if (personDemographics && personDemographics.id) {
                    _.remove(filteredPossibleMatches, function (pd) { return pd.id == personDemographics.id });
                }
                if (reviewMatches) {
                    return (
                        <div>
                            <div className="tableSection sectionMatches">
                                <div onClick={() => setReviewMatches(false)} className={`button medium action backButton`}>
                                    <div className="buttonTitle">Back to Demographics</div>
                                </div>
                                {
                                    _.map(filteredPossibleMatches, function (possibleMatch) {
                                        return (
                                            <div key={possibleMatch.id} className="tableCell possibleMatchCell">
                                                <div className="patientName">{possibleMatch.firstName} {possibleMatch.middleName} {possibleMatch.lastName}</div>

                                                <div className="patientAttributesList">
                                                    <div className="patientAttribute">
                                                        <div className="attributeTitle">Date of Birth</div>
                                                        <div className="attributeValue">{possibleMatch.dateOfBirth}</div>
                                                    </div>

                                                    <div className="patientAttribute">
                                                        <div className="attributeTitle">Gender</div>
                                                        <div className="attributeValue">{possibleMatch.gender}</div>
                                                    </div>
                                                </div>

                                                <div onClick={() => associateExistingPatient(possibleMatch)} className={`button medium tint`}>
                                                    <div className="buttonTitle">Select Patient</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    );
                }
                return (
                    <div>
                        {
                            filteredPossibleMatches && filteredPossibleMatches.length ? (
                                <div className="tableSection sectionDetailsMatch">
                                    <div onClick={() => setReviewMatches(true)} className={`bannerView`}>
                                        <p className="bannerTitle">These details match {possibleMatches.length} patients.</p>
                                        <div className="button small">
                                            <div className="buttonTitle">Review Matches</div>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }
                        <div className="tableSection sectionName">
                            <InputCell
                                placeholder={"First Name"}
                                defaultValue={(personDemographics ? personDemographics.firstName : "")}
                                required
                                id="firstName"
                                label="First Name"
                                inputRef={firstNameRef}
                            />
                            {/*<InputCell*/}
                            {/*    placeholder={"Middle Name"}*/}
                            {/*    required*/}
                            {/*    defaultValue={(personDemographics ? personDemographics.middleName : "")}*/}
                            {/*    id="middleName"*/}
                            {/*    label="Middle Name"*/}
                            {/*    name="middleName"*/}
                            {/*    inputRef={middleNameRef}*/}
                            {/*/>*/}
                            <InputCell
                                placeholder={"Last Name"}
                                required
                                onBlur={() => searchPossibleMatches()}
                                defaultValue={(personDemographics ? personDemographics.lastName : "")}
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                inputRef={lastNameRef}
                            />
                        </div>

                        <div className="tableSection sectionName">
                            <div className="sectionTitle">
                                <div className="title">PATIENT DETAILS</div>
                            </div>

                            <div className="tableCell titledSelectCell datePickerCell">
                                <div className="title">Birthdate</div>
                                {/*<DayPickerInput*/}
                                {/*    formatDate={formatDate}*/}
                                {/*    parseDate={parseDate}*/}
                                {/*    placeholder={`${formatDate(new Date())}`}*/}
                                {/*    value={personDemographics ? personDemographics.dateOfBirth : ""}*/}
                                {/*    onDayChange={handleDayChange}*/}
                                {/*/>*/}
                                <InputCell
                                    onBlur={() => searchPossibleMatches()}
                                    type={"date"}
                                    required
                                    defaultValue={personDemographics ? personDemographics.dateOfBirth : ""}
                                    inputRef={birthdayRef}
                                    id={"dob"}
                                />
                            </div>

                            <div className="tableCell titledSelectCell">
                                <div className="title">Gender</div>
                                <Select styles={{ width: "100px" }} className="selectCell workflowTextBackSetting" classNamePrefix="selectCell"
                                    onChange={setGender}
                                    value={selectedGender}
                                    options={genderOpts}
                                />
                            </div>

                            {/*<div className="tableCell titledSelectCell">*/}
                            {/*    <div className="title">Weight</div>*/}
                            {/*    <InputCell*/}
                            {/*        placeholder={"lbs..."}*/}
                            {/*        defaultValue={personDemographics ? personDemographics.weightLbs : ""}*/}
                            {/*        required*/}
                            {/*        type={"number"}*/}
                            {/*        id="weightLbs"*/}
                            {/*        inputRef={weightRef}*/}
                            {/*    />*/}
                            {/*    <div className="valueDescription">lb</div>*/}
                            {/*</div>*/}
                            {/*<div className="tableCell titledSelectCell patientHeightInput">*/}
                            {/*    <div className="title">Height</div>*/}
                            {/*        <div className="valueInputs">*/}
                            {/*        <InputCell*/}
                            {/*            placeholder={"0"}*/}
                            {/*            defaultValue={personDemographics ? _.round(personDemographics.heightInches / 12) : ""}*/}
                            {/*            required*/}
                            {/*            type={"number"}*/}
                            {/*            id="heightFeet"*/}
                            {/*            inputRef={heightFeetRef}*/}
                            {/*        />*/}
                            {/*        <div className="valueDescription">ft</div>*/}
                            {/*        <InputCell*/}
                            {/*            placeholder={"0"}*/}
                            {/*            defaultValue={personDemographics ? personDemographics.heightInches % 12 : ""}*/}
                            {/*            required*/}
                            {/*            type={"number"}*/}
                            {/*            id="heightInches"*/}
                            {/*            inputRef={heightInchesRef}*/}
                            {/*        />*/}
                            {/*        <div className="valueDescription">in</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                        <div className="tableSection sectionContactDetails">
                            <div className="sectionTitle">
                                <div className="title">CONTACT DETAILS</div>
                            </div>

                            <div className="tableCell inputCell phoneInput">
                                <div className="title">Phone Number</div>
                                <PhoneInput
                                    inputClass="tel-input"
                                    country={'us'}
                                    onlyCountries={['us']}
                                    value={cellPhoneVal}
                                    onChange={(phone) => setCellPhoneVal(phone)}
                                />
                            </div>
                            {/*<div className="tableCell inputCell phoneInput">*/}
                            {/*    <div className="title">Phone Number #2</div>*/}
                            {/*    <PhoneInput*/}
                            {/*        inputClass="tel-input"*/}
                            {/*        country={'us'}*/}
                            {/*        onlyCountries={['us']}*/}
                            {/*        value={secondaryPhoneVal}*/}
                            {/*        onChange={(phone) => setSecondaryPhoneVal(phone)}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                    </div>
                )
                    ;
            case INSURANCE:
                let insurance = (interaction && interaction.insurance && interaction.insurance.id) ? interaction.insurance : null;
                return (
                    <div>
                        {insurance && insurance.insuranceImagePath ?
                            (
                                <div className={`attributeCell`}>
                                    <ImageViewerButton url={"/api/v1/insurance/person-insurace/" + insurance.id + "/card-image"}>
                                        <span>View Insurance Card</span>
                                    </ImageViewerButton>
                                </div>
                            ) : null
                        }
                        <VerifyInsurance
                            personDemographics={personDemographics}
                            interaction={interaction}
                            onInsuranceSelect={onInsuranceSelect} />
                    </div>
                )
                break;
            case INITIAL_REPORT:
                return (
                    <div className="tableSection sectionName">
                        <div className="tableCell titledSelectCell">
                            <div className="title">Age</div>
                            <InputCell
                                placeholder={""}
                                defaultValue={interaction ? interaction.age : ""}
                                required
                                type={"number"}
                                id="age"
                                inputRef={ageRef}
                            />
                            <div className="valueDescription">years</div>
                        </div>
                        <div className="tableCell titledSelectCell">
                            <div className="title">Gender</div>
                            <Select styles={{ width: "100px" }} className="selectCell workflowTextBackSetting" classNamePrefix="selectCell"
                                onChange={setGender}
                                value={selectedGender}
                                options={genderOpts}
                            />
                        </div>
                        <div className="tableCell titledSelectCell">
                            <div className="title">Chief Complaint</div>
                            <InputCell
                                placeholder={""}
                                defaultValue={interaction ? interaction.chiefComplaint : ""}
                                required
                                type={"text"}
                                id="chiefComplaint"
                                inputRef={chiefComplaintRef}
                            />
                        </div>
                        {getCommonInteractionTabContents()}
                    </div>
                )
                break;
            case INTERACTION:
                return (
                    <div>
                        {getCommonInteractionTabContents()}
                        {
                            pastInteractions && pastInteractions.length ? (
                                <div className="tableSection sectionPastInteractions">
                                    <div className="sectionTitle">
                                        <div className="title">Past Interactions</div>
                                    </div>
                                    {
                                        _.map(_.reverse(_.sortBy(pastInteractions, function (pi) { return pi.createdAt; })), function (pi) {
                                            return (
                                                <div key={pi.id} className="tableCell patientInteractionHistoryCell">
                                                    <div className="interactionAttribute interactionNotes">
                                                        <div className="attributeTitle">Date</div>
                                                        <div className="attributeValue"><LocalTimeStamp utcTimestamp={pi.createdAt} format={"MM/DD/YYYY, h:mm:ss a"} /></div>
                                                    </div>
                                                    <div className="interactionMetrics">
                                                        <div className="interactionAttribute">
                                                            <div className="attributeTitle">BP</div>
                                                            <div className="attributeValue">{pi.bpsystolic} / {pi.diastolic}</div>
                                                        </div>

                                                        <div className="interactionAttribute">
                                                            <div className="attributeTitle">SpO2</div>
                                                            <div className="attributeValue">{pi.sp02}</div>
                                                        </div>

                                                        <div className="interactionAttribute">
                                                            <div className="attributeTitle">Heart Rate</div>
                                                            <div className="attributeValue">{pi.heartRateBPM} BPM</div>
                                                        </div>

                                                        <div className="interactionAttribute">
                                                            <div className="attributeTitle">Respiratory Rate</div>
                                                            <div className="attributeValue">{pi.respiratoryRateBPM} per minute</div>
                                                        </div>
                                                    </div>

                                                    <div className="interactionAttribute interactionNotes">
                                                        <div className="attributeTitle">Notes</div>
                                                        <div className="attributeValue">{pi.notes}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }

                    </div>
                )
                break;
        }
    }

    if (!personDemographics) {
        return (<div className="emptyPlaceholder placeholderLoading"><span className="placeholderTitle">Loading...</span></div>);
    }

    let genderOpts = [{ value: "MALE", label: "Male" }, { value: "FEMALE", label: "Female" }, { value: "NONBINARY", label: "Non-Binary" }];
    let selectedGender = _.find(genderOpts, function (o) { return o.value == personDemographics.gender; });

    return (
        <Modal
            button={getButtons()}
            content={getModalContents()}
            title={"Patient Details"}
            size="large"
            handleClose={() => setPatientDetailsOpen(false)}
            //handleOpen={() => setPatientDetailsOpen(true)}
            open={patientDetailsOpen}
            dialogClassName="modalDialog patientDetails"
        />
    )
}
