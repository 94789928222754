import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {
    useTheme,
    Text,
    View
} from '@aws-amplify/ui-react';
import CognitoVerifyChangePassword from "./CognitoVerifyChangePassword";
import CognitoVerifyResetPasswordCode from "./CognitoVerifyResetPasswordCode";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '460px',
        backgroundColor: theme.palette.common.white,
        padding: '35px 40px',
        borderRadius: '6px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    '#form-container': {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export function Footer() {
    const {tokens} = useTheme();

    return (
        <View textAlign="center" padding={tokens.space.large}>
            <Text color={tokens.colors.neutral[80]}>
                &copy; All Rights Reserved
            </Text>
        </View>
    );
}

export default function CognitoVerifyPasswordReset(props) {
    const [codeRequested, setCodeRequested] = useState(false);
    const [email, setEmail] = useState(null);
    const CLASSES = useStyles();

    return (
        <div className='forgotPassword'>
            <div className={CLASSES.paper}>
                {codeRequested ? <CognitoVerifyChangePassword email={email} {...props}/> :
                    <CognitoVerifyResetPasswordCode email={email} updateEmail={setEmail} {...props}
                                                    updateCode={setCodeRequested}/>}
            </div>
            <Footer/>
        </div>
    );
}