import {
    GET_PROVIDERS_CATEGORIES, GET_PROVIDERS_CATEGORIES_SUCCESS
} from './actions'

const initialState = {
    providerCategories: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PROVIDERS_CATEGORIES:
            return state;
            break;
        case GET_PROVIDERS_CATEGORIES_SUCCESS:
            return Object.assign({}, state, {
                providerCategories: action.payload
            });
            break;
        default:
            return state
    }
}