import React from 'react';
import _ from "lodash";
import { withRouter } from "react-router";

import SupportToolsTenants from "./SupportToolsTenants";
import SupportToolsAgents from "./SupportToolsAgents";
import SupportToolsConsortiums from "./SupportToolsConsortiums";

function SupportTools(props) {
    const TENANTS = "TENANTS";
    const AGENTS = "AGENTS";
    const CONSORTIUMS = "CONSORTIUMS";
    const [activeTab, setActiveTab] = React.useState(TENANTS);


    const tabs = [
        TENANTS,
        AGENTS,
        CONSORTIUMS
    ];

    React.useEffect(() => {
        if (props.match.params && props.match.params.tab && _.indexOf(tabs, props.match.params.tab) >= 0) {
            setActiveTab(props.match.params.tab);
        }
        else {
            setActiveTab(TENANTS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTabContents = () => {
        // eslint-disable-next-line default-case
        switch (activeTab) {
            case TENANTS:
                return (<SupportToolsTenants />);
            case AGENTS:
                return (<SupportToolsAgents />);
            case CONSORTIUMS:
                return (<SupportToolsConsortiums />);
        }
    }

    const setTab = (tabKey) => {
        setActiveTab(tabKey);
        props.history.push("/support-tools/" + tabKey);
    }

    return (
        <div className="rootView supportToolsView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="title">Support Tools</div>
                </div>

                <div className="actions">
                    <div className={`segmentedBar index${_.indexOf(tabs, activeTab)}`} style={{ width: "240px" }}>
                        {
                            _.map(tabs, function (t, idx) {
                                return (
                                    <div key={idx} onClick={() => setTab(t)} className={`segment ${t === activeTab ? 'selected' : ''}`}>
                                        <div className="title">{_.upperFirst(_.lowerCase(t))}</div>
                                    </div>
                                );
                            })
                        }
                        <div className="selection" style={{ width: (100 / tabs.length) + '%' }} />
                    </div>
                </div>
                <div className="separator"></div>
            </div>
            {
                getTabContents()
            }
        </div>
    )
}

export default withRouter(SupportTools);