import React from "react";
import ConfirmModal from "./ConfirmModal";

export default function InlineRemove(props) {
    var { onRemoveConfirm, title, text, confirmText } = props;

    const removeConfirm = () => {
        onRemoveConfirm();
    }
    return (
        <div style={{ display: 'inline-block' }}>
            <ConfirmModal
                title={title ? title : "Are you sure you want to delete this field?"}
                text={text ? text : "All data previously entered into this field will also be deleted."}
                confirmTitle={confirmText ? confirmText : "Confirm"}
                onConfirm={removeConfirm}
            >
                {props.children}
            </ConfirmModal>
        </div>
    )
}