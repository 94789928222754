import React from 'react';
import { withRouter } from "react-router";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import _ from 'lodash';

import Modal from "@/common/Modal";
import moment from '@/common/momentConfig';

import UploadFile from './UploadFile';

const REFRESH_TIME = 30000; // 30 seconds

const UPLOAD_STATUS = {
	COMPLETE: "COMPLETE",
	PARTIAL_COMPLETE: "PARTIAL_COMPLETE",
	FAILURE: "FAILURE",
	IN_PROGRESS: "IN_PROGRESS"
}

function FileImportIndex(props) {
	const [open, setOpen] = React.useState(false);
	const [editOpen, setEditOpen] = React.useState({});
	const [uploads, setUploads] = React.useState(null);

	const loadUploadProcesses = async () => {
		API.get('Core', '/api/v1/file-import')
			.then(response => {
				setUploads(response);
			},
				error => {
					setUploads([]);
					toast("Could not create load uploads records: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
				}
			);
	}

	React.useEffect(() => {
		loadUploadProcesses();
		const interval = setInterval(() => {
            loadUploadProcesses();
        }, REFRESH_TIME);
        return () => clearInterval(interval);
	}, [])

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const openEditDataSource = (ds) => {
		props.history.push("/file-import/" + ds.processId + "/logs");
	}

	const closeEditDataSource = (ds) => {
		editOpen[ds.processId] = false;
		setEditOpen(Object.assign({}, editOpen));
	}

	const isEditDataSourceOpen = (art) => {
		return editOpen[art.processId];
	}

	const onUploadSuccess = () => {
		loadUploadProcesses();
		setOpen(false);
	}

	return (
		<div className="rootView sourcesView fsExclude fileImport">
			<div className="navBar">
				<div className="title">File Import</div>
				<div className="actions">
					<div className="">
						<Modal
							button={(<div className="add action">
								<div className="title clear">New Import</div>
							</div>)}
							content={(<UploadFile
								onCancel={handleClose}
								onSuccess={onUploadSuccess}
							/>)}
							title="Import Files"
							size="large"
							handleClose={handleClose}
							handleOpen={handleOpen}
							open={open}
						/>

					</div>
				</div>
				<div className="separator"></div>
			</div>

			{uploads?.length <= 0 &&
				<div className="emptylist">
					<div className="subtitle">No File Import History</div>
				</div>
			}

			{uploads?.length > 0 &&
				<div className="scrollView">
					<div className="sourcesList tableView">
						<div className="tableSection">
							{
								_.map(uploads, (upload, idx) => {

									let statusStyle = "";

									switch (upload.status) {
										case UPLOAD_STATUS.COMPLETE:
											statusStyle = "tint"
											break;
										case UPLOAD_STATUS.FAILURE:
											statusStyle = "destructive"
											break;
										case UPLOAD_STATUS.PARTIAL_COMPLETE:
											statusStyle = "tintPurple"
											break;
										case UPLOAD_STATUS.IN_PROGRESS:
											statusStyle = "tintYellow"
											break;
									}

									return (
										<div key={idx} className="tableCell sourceCell">
											<div className="sourceBaseCell">
												{upload.sourceType &&
													<div className="cellBody">
														<div className="title sourceName">{`${upload.sourceType} import`}</div>
													</div>
												}

												<div className="sourceDetails">
													<div className="attributeTitle gridCell">{upload.fileName}</div>
													<div className="attributeTitle gridCell">
														<div className={`button small ${statusStyle} gridButton`} style={{ pointerEvents: 'none' }}>
															<div className="title">{upload.status ? upload.status.replaceAll("_", " ") : null}</div>
														</div>
													</div>
													<div className="attributeTitle gridCell">Rows: {upload.rows ? upload.rows : '-'}</div>
													<div className="attributeTitle gridCell">{moment.utc(upload.createdAt).local().format("MM-DD-YYYY HH:mm:ss")}</div>

													<Modal
														button={
															<div onClick={openEditDataSource.bind(this, upload)}
																className="cellAction actionProminent button small action">
																<div className="title">View Logs</div>
															</div>
														}
														title={`View Logs - ${upload.status}`}
														size="large"
														handleClose={closeEditDataSource.bind(this, upload)}
														handleOpen={openEditDataSource.bind(this, upload)}
														open={isEditDataSourceOpen(upload)}
													/>
												</div>
											</div>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
			}
		</div>
	);
}

export default withRouter((FileImportIndex));