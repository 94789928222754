import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Modal from "../../../common/Modal";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core";
import ConfirmModal from "../../../common/ConfirmModal";
import { API } from "aws-amplify";
import AddEditShift from "./AddEditShift";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    float: "right",
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  completed: {
    color: theme.palette.success[600] + "!important",
  },
}));

const columns = [{ id: "name", label: "Shift Name", minWidth: 170 }];

function ShiftsIndex(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState({});
  const [shifts, setShifts] = React.useState([]);

  const loadShifts = () => {
    API.get("Core", "/api/v1/shift").then(
      (response) => {
        setShifts(response);
      },
      (error) => {
        toast("Could not get shifts: " + error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.ERROR,
        });
      }
    );
  };

  React.useEffect(() => {
    loadShifts();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShiftAdd = () => {
    setOpen(false);
    loadShifts();
  };

  const handleEditClose = (shift) => {
    editOpen[shift.id] = false;
    setEditOpen(Object.assign({}, editOpen));
  };

  const handleEditOpen = (shift) => {
    editOpen[shift.id] = true;
    setEditOpen(Object.assign({}, editOpen));
  };

  const handleEditSuccess = (shift) => {
    editOpen[shift.id] = false;
    setEditOpen(Object.assign({}, editOpen));
    loadShifts();
  };

  const deleteShift = (shiftId) => {
    API.del("Core", "/api/v1/shift/" + shiftId).then(
      (response) => {
        toast("Deleted Shift!", {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.SUCCESS,
        });
        loadShifts();
      },
      (error) => {
        toast("Could not Delete Shift" + error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.ERROR,
        });
      }
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Modal
          button={
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Add Shift
            </Button>
          }
          content={
            <AddEditShift onCancel={handleClose} onSuccess={handleShiftAdd} />
          }
          title="Add Shift"
          size="small"
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
        />
      </Grid>
      <Grid xs={12}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shifts.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell key={"edit"}>
                    <Modal
                      button={
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          Edit
                        </Button>
                      }
                      content={
                        <AddEditShift
                          existingShift={row}
                          onCancel={handleEditClose.bind(this, row)}
                          onSuccess={handleEditSuccess.bind(this, row)}
                        />
                      }
                      title="Edit Shift"
                      size="small"
                      handleClose={handleEditClose.bind(this, row)}
                      handleOpen={handleEditOpen.bind(this, row)}
                      open={editOpen[row.id]}
                    />
                    <ConfirmModal
                      title={"Delete Shift"}
                      text={
                        <span>
                          Are you sure you want to delete <b>{row.name}</b>?
                        </span>
                      }
                      onConfirm={deleteShift.bind(this, row.id)}
                      confirmTitle={"Delete"}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        Delete
                      </Button>
                    </ConfirmModal>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}

export default ShiftsIndex;
