import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import ImageViewer from 'react-simple-image-viewer';
import {toast} from "react-toastify";
import {API} from "aws-amplify";

function ImageViewerButton(props){
    const {url, children} = props;
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const [data, setData] = React.useState(false);

    const openImageViewer = () => {
        setIsViewerOpen(true);
        API.get('Core', url + "?buster23", {response: true, responseType: 'arraybuffer', headers: { accept: "image/*"}})
            .then(response => {
                if(response.data){
                    let base64data = `data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`;
                    setData(base64data);
                }
            }, error => {
                toast("Could not load image: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            })
    };

    const closeImageViewer = () => {
        setIsViewerOpen(false);
    };

    const onError = (err) => {
        console.log(err);
    }

    return (
        <VisibilitySensor>
            <div>
                <div onClick={openImageViewer} style={{cursor:'pointer'}}>
                    {children}
                </div>
                {isViewerOpen && data ? (
                    <ImageViewer
                        src={[data]}
                        onClose={closeImageViewer}
                        backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,0.9)",
                            zIndex:10
                        }}
                    />
                ): null}
            </div>
        </VisibilitySensor>
    )
}

export default ImageViewerButton;