import { API } from 'aws-amplify';

export const GET_GETSTARTED_STEPS = 'GET_GETSTARTED_STEPS';
export const requestGetStartedSteps = () => dispatch => {
	dispatch({
		type : GET_GETSTARTED_STEPS
	});
}

export const GET_GETSTARTED_STEPS_SUCCESS = 'GET_GETSTARTED_STEPS_SUCCESS';
export const requestGetStartedStepsSuccess = (json) => dispatch => {
	dispatch({
		type : GET_GETSTARTED_STEPS_SUCCESS,
		payload: json,
		receivedAt: Date.now()
	});
}

export const GET_GETSTARTED_STEPS_FAILURE = 'GET_GETSTARTED_STEPS_FAILURE';
export const requestGetStartedStepsFailure = () => dispatch => {
	dispatch({
		type : GET_GETSTARTED_STEPS_FAILURE
	});
}

export function fetchGetStartedSteps() {

  return function(dispatch) {

    dispatch(requestGetStartedSteps())

    return API.get('Core', '/api/v1/get-started')
      .then(response => {
      	dispatch(requestGetStartedStepsSuccess(response))
      },
      	error => {
      		console.log('An error occurred.', error)
      	}
      );
  }
}