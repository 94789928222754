import React from "react"
import { Button } from "../../components/Buttons"
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import RMSCaseEncounterDiff from "../encounter/types/RMSCaseEncounterDiff";
import LocalTimeStamp from "../../common/LocalTimeStamp";
import _ from "lodash";

function CaseLookupDetails({ revisions, schema, onDone, ...props }) {
    const [activeRevision, setActiveRevision] = React.useState(null);

    React.useEffect(() => {
        let revisionsSorted = _.sortBy(revisions, function (r) { return r.commitId; });
        if(activeRevision == null){
            setActiveRevision(revisionsSorted[revisionsSorted.length-1])
        }
    }, []);

    if(activeRevision == null){
        return (<span>Loading...</span>);
    }

    return (
        <div className="modalDialog splitView encounterDetailsView">
            <div className="mainView">
                <div className="navBar">
                    <div className="title">Case Details</div>
                    <div className="separator" />
                </div>

                <div className="scrollView">
                    <div className="contentView encounterVersionsList">
                        {
                            _.map(_.sortBy(revisions, function (r) { return r.commitId; }), function (revision, k) {
                                return (
                                    <div onClick={() => setActiveRevision(revision)} className={`tableCell encounterVersionCell ${revision.commitId === activeRevision.commitId ? 'selected' : ''}`} key={k}>
                                        <div className="cellTitle encounterVersionTimestamp"><LocalTimeStamp utcTimestamp={revision.commitDate} format={"MM/DD/YYYY, h:mm:ss a"} /></div>
                                        {
                                            k === 0 ? (
                                                <div className="cellSubtitle encounterVersionDetail">Initial</div>
                                            ) : (
                                                <div className="cellSubtitle encounterVersionDetail">{revision.changes.length} changes</div>
                                            )
                                        }
                                    </div>
                                )
                            }).reverse()
                        }
                    </div>
                </div>
            </div>

            <div className="detailView">
                <div className="navBar">
                    <div className="title">{activeRevision ? (
                        <LocalTimeStamp utcTimestamp={activeRevision.commitDate} format={"MM/DD/YYYY, h:mm:ss a"} />
                    ) : ""}
                    </div>
                    <div className="separator" />
                </div>

                <div className="scrollView">
                    <RMSCaseEncounterDiff revision={activeRevision} revisions = {revisions} />
                </div>

                <div className="actionBar">

                    <div className="actions right">
                        <Button
                            onClick={onDone}
                            title="Done"
                            icon="none"
                            size="medium"
                            style="primary"
                        />
                    </div>

                    <div className="separator" />
                </div>
            </div>
        </div>
    )
}

export default CaseLookupDetails;
