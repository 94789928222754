import React from "react";
import PhoneInput from "react-phone-input-2";
import { ContentState, Editor, EditorState } from "draft-js";
import _ from "lodash";

import Modal from "@/common/Modal";
import TeamList from "@/routes/settings/team/TeamList";
import InterpreterList from "@/routes/settings/interpreter/InterpreterList";

export default function AddParticipant({ title, onAdd, submitTitle, currentConversation, ...props }) {
    const PROVIDER = "PROVIDER";
    const EXTERNAL = "EXTERNAL";
    const TRANSLATOR = "TRANSLATOR";

    const [phoneVal, setPhoneVal] = React.useState("");
    const [addParticipantOpen, setAddParticipantOpen] = React.useState(false);
    const [inviteEditorState, setInviteEditorState] = React.useState(EditorState.createEmpty());
    const [activeTab, setActiveTab] = React.useState(PROVIDER);
    const searchRef = React.createRef();
    const [search, setSearch] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [size, setSize] = React.useState(50);
    const [selectedUser, setSelectedUser] = React.useState();
    const [selectedInterpreter, setSelectedInterpreter] = React.useState();
    const [searchFocused, setSearchFocused] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState("");

    const tabs = [
        PROVIDER,
        EXTERNAL,
        TRANSLATOR
    ]

    const getTabTitle = (tabKey) => {
        // eslint-disable-next-line default-case
        switch (tabKey) {
            case PROVIDER:
                return "Provider";
            case EXTERNAL:
                return "External";
            case TRANSLATOR:
                return "Interpreter";
        }
    }


    let inviteDomEditor;
    const setInviteDomEditorRef = ref => inviteDomEditor = ref;

    React.useEffect(() => {
        let contentState = ContentState.createFromText("You are being invited to join a conversation.");
        setInviteEditorState(EditorState.createWithContent(contentState));
        setSelectedInterpreter(currentConversation.interpreter);
    }, [currentConversation])

    const isSelectedUser = (e) => {
        return (!_.isNil(selectedUser) && e.id == selectedUser.id);
    }

    const isSelectedInterpreter = (i) => {
        return (!_.isNil(selectedInterpreter) && i.id == selectedInterpreter.id);
    }

    const onUserSelect = (e) => {
        if (isSelectedUser(e)) {
            setSelectedUser(null);
        }
        else {
            setSelectedUser(e);
        }
    }

    const onInterpreterSelect = (e) => {
        if (isSelectedInterpreter(e)) {
            setSelectedInterpreter(null);
        }
        else {
            setSelectedInterpreter(e);
        }
    }

    const onPageChange = (event, page) => {
        setPage(page);
    }

    const onSearchFocused = () => {
        setSearchFocused(true);
    }

    const onSearchBlurred = () => {
        setSearchFocused(false);
    }

    const onSearchChange = (e) => {
        setSearchInput(searchRef.current.value);
    }

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            if (searchRef.current.value !== search) {
                setPage(1);
            }
            setSearch(searchRef.current.value);
        }
    }

    const clearSearch = () => {
        setSearch("");
        setSearchInput("");
    }

    const getTabContents = (tabKey) => {
        // eslint-disable-next-line default-case
        switch (tabKey) {
            case PROVIDER:
                return (
                    <div className="tabSection sectionProvider">
                        <div className="tableView inviteMessage">
                            <div className="tableSection sectionInviteMessage">
                                <div className={"tableCell editorCell multilineCell"} onClick={inviteEditorContainerClick}>
                                    <div className="title">Invite Message</div>
                                    <Editor
                                        readOnly={false}
                                        style={{ height: "100%" }}
                                        editorState={inviteEditorState}
                                        onChange={updateInviteEditorState}
                                        placeholder={""}
                                        spellCheck={true}
                                        stripPastedStyles={true}
                                        ref={setInviteDomEditorRef}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="providersList">
                            <div className={`searchInput ${searchFocused ? "isFocused" : ""} ${(searchInput.length > 0) ? "hasInput" : ""}`}>
                                <div className="icon" />
                                <input value={searchInput} onChange={onSearchChange} placeholder={"Search All Providers"} onFocus={onSearchFocused} onBlur={onSearchBlurred} className="input" onKeyDown={keyPress} type="text" ref={searchRef} />
                                <div className="clear" onClick={clearSearch}></div>
                            </div>

                            <div>
                                <TeamList
                                    page={page}
                                    size={size}
                                    search={search}
                                    handlePageChange={onPageChange}
                                    selectedUser={[selectedUser]}
                                    onSelect={onUserSelect}
                                    multipleSelect={false}
                                />
                            </div>
                        </div>
                    </div>
                );
            case EXTERNAL:
                return (
                    <div className="tabSection sectionExternal">
                        <div className="tableCell inputCell phoneInput">
                            <div className="title">Phone Number</div>
                            <PhoneInput
                                inputClass="tel-input"
                                country={'us'}
                                onlyCountries={['us']}
                                value={phoneVal}
                                onChange={onPhoneChange}
                            />
                        </div>

                        <div className={"tableCell editorCell multilineCell"} onClick={inviteEditorContainerClick}>
                            <div className="title">Invite Message</div>
                            <Editor
                                readOnly={false}
                                style={{ height: "100%" }}
                                editorState={inviteEditorState}
                                onChange={updateInviteEditorState}
                                placeholder={""}
                                spellCheck={true}
                                stripPastedStyles={true}
                                ref={setInviteDomEditorRef}
                            />
                        </div>
                    </div>
                );
            case TRANSLATOR:
                return (
                    <div>
                        <InterpreterList
                            selectedInterpreters={[selectedInterpreter]}
                            onSelect={onInterpreterSelect}
                            multipleSelect={false}
                        />
                    </div>
                );
        }
    }

    const setTab = (tabKey) => {
        setActiveTab(tabKey);
    }

    const addParticipant = () => {
        if (!canSubmit()) {
            return;
        }
        var addData = "";
        if (activeTab == EXTERNAL) {
            addData = { additionalParticipant: { phone: phoneVal }, inviteMessage: inviteEditorState.getCurrentContent().getPlainText() }
        } else if (activeTab == PROVIDER) {
            addData = { additionalParticipant: selectedUser, inviteMessage: inviteEditorState.getCurrentContent().getPlainText() }
        } else if (activeTab == TRANSLATOR) {
            addData = { interpreter: selectedInterpreter, inviteMessage: null }
        }
        onAdd(addData);
        reset();
    }

    const reset = () => {
        setAddParticipantOpen(false);
        setPhoneVal("");
    }

    const onPhoneChange = (phone) => {
        setPhoneVal(phone);
    }

    const canSubmit = () => {
        var cs = false;
        if (activeTab === EXTERNAL) {
            if (phoneVal.length >= 11) {
                cs = true;
            }
        } else if (activeTab === PROVIDER) {
            if (selectedUser != null) {
                cs = true;
            }
        } else if (activeTab === TRANSLATOR) {
            if (selectedInterpreter !== currentConversation.interpreter) {
                cs = true;
            }
        }
        return cs;
    }

    const handleAddParticipantOpen = () => {
        reset();
        setAddParticipantOpen(true);
    }

    const handleAddParticipantClose = () => {
        setAddParticipantOpen(false);
        reset();
    }

    const updateInviteEditorState = (newEditorState) => {
        console.log(newEditorState.getCurrentContent().getPlainText());
        setInviteEditorState(newEditorState);
    }

    function inviteEditorContainerClick() {
        inviteDomEditor.focus();
    }


    return (
        <div className="actions">
            <Modal
                button={props.children}
                content={(
                    <div>
                        <div className={"content"}>
                            <div className={`segmentedBar index${_.indexOf(tabs, activeTab)}`} style={{ width: "100%" }}>
                                {
                                    _.map(tabs, function (t, tIdx) {
                                        return (
                                            <div onClick={() => setTab(t)} className={`segment ${t == activeTab ? 'selected' : ''}`} key={tIdx}>
                                                <div className="title">{getTabTitle(t)}</div>
                                            </div>
                                        );
                                    })
                                }
                                <div className="selection" style={{ left: (_.indexOf(tabs, activeTab) * (100 / tabs.length)) + '%', width: (100 / tabs.length) + '%' }} />
                            </div>
                            {getTabContents(activeTab)}
                        </div>
                        <div className="actionBar">
                            <div className="actions">
                                <div onClick={handleAddParticipantClose} className="button dismiss medium">
                                    <div className="title">Cancel</div>
                                </div>

                                <div onClick={canSubmit() ? addParticipant : null} className={`button confirm medium ${canSubmit() ? '' : 'disabled'}`}>
                                    <div className="title">{submitTitle}</div>
                                </div>
                            </div>
                            <div className="separator"></div>
                        </div>
                    </div>
                )}
                title={title}
                size="medium"
                handleClose={handleAddParticipantClose}
                handleOpen={handleAddParticipantOpen}
                open={addParticipantOpen}
                dialogClassName="modalDialog addParticipant"
            />
        </div>
    )
}