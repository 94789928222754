import React, { useState, useEffect, useRef } from 'react';
import Video from "twilio-video";
import {isMobile} from 'react-device-detect';

let initiatingStream = false;
let multiCameraDefault = "user";

const LocalParticipant = ({ participant, muted, videoDisabled, reconnect }) => {
    const [videoTrack, setVideoTrack] = useState(null);
    const [audioTrack, setAudioTrack] = useState(null);

    const videoRef = useRef();
    const audioRef = useRef();

    const addTrack = track => {
        if (track.kind === 'video') {
            setVideoTrack(track);
        } else {
            setAudioTrack(track);
        }
    };

    const startVideoTrack = () => {

        if(initiatingStream){
            return;
        }
        initiatingStream = true;
        let constraints = {
            video : {
                facingMode: {
                    ideal: multiCameraDefault
                }
            },
            audio : false
        }

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(stream => {
                initiatingStream = false;
                let localVideoTrack = new Video.LocalVideoTrack(stream.getTracks()[0]);
                addTrack(localVideoTrack);
                participant.publishTrack(localVideoTrack);
            })
    }

    const stopVideoTrack = () => {
        videoTrack.disable();
        videoTrack.stop();
        participant.unpublishTrack(videoTrack);
        setVideoTrack(null);
    }

    const toggleCamera = () => {
        if(!isMobile){
            return;
        }
        stopVideoTrack();
        multiCameraDefault = multiCameraDefault === "user" ? "environment" : "user";
        startVideoTrack();
    }

    useEffect(() => {

        Array.from(participant.tracks.values()).filter(function(publication) {
            return publication.track;
        }).map(function(publication) {
            addTrack(publication.track);
        });

        startVideoTrack();

        return () => {
            setVideoTrack(null);
            setAudioTrack(null);
        };
    }, [participant]);

    useEffect(() => {
        if (videoTrack) {
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTrack]);

    useEffect(() => {
        if (audioTrack) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTrack]);

    useEffect(() => {
        if (videoTrack && videoTrack.isEnabled && videoDisabled) {
            stopVideoTrack();
        }
        else if(!videoTrack){
            startVideoTrack();
        }
    }, [videoDisabled]);

    useEffect(() => {
        if (audioTrack) {
            if(audioTrack.isEnabled && muted){
                audioTrack.disable()
            }
            else{
                audioTrack.enable();
            }
        }
    }, [muted]);

    return (
        <div>
            <div onClick={toggleCamera} className={`localParticipantStream fsExclude ${videoDisabled ? "hidden" : ""}`}>
                <video style={{height:"100%", width:"100%", transform:'scaleX(-1)'}} className="local-participant-video" ref={videoRef} autoPlay={true} />
                <audio ref={audioRef} autoPlay={true} muted={false} />
            </div>
            <div className={`callStatus cameraOff ${videoDisabled ? "" : "hidden"}`}>
                <div className="statusItem camera">
                    <div className="icon"></div>
                </div>
            </div>
        </div>
    );
};

export default LocalParticipant;