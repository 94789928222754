import {GET_FIELD_OPTIONS, GET_FIELD_OPTIONS_SUCCESS} from "./actions";

const initialState = {
    fieldOptions: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_FIELD_OPTIONS:
            return state;
            break;
        case GET_FIELD_OPTIONS_SUCCESS:
            return Object.assign({}, state, {
                fieldOptions: action.payload
            });
        default:
            return state
    }
}