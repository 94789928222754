import React, {useEffect} from 'react';
import {connect} from "react-redux";
import Pagination from '@material-ui/lab/Pagination';
import _ from "lodash";

import {fetchProvidersSearch} from "@/shared/user/actions";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    loadUsers: (page, size, search, withoutPhone) => dispatch(fetchProvidersSearch(page,size,search, withoutPhone))
})

function TeamList(props) {
    const {page, size, search, handlePageChange, selectedUsers, onSelect, multipleSelect, withoutPhone} = props;
    const [selectedUserIDs, setSelectedUserIDs] = React.useState([]);


    useEffect(() => {
        props.loadUsers(page-1, size, search, withoutPhone);
    }, [])


    useEffect(() => {
        // Page on server is 0 based
        props.loadUsers(page-1, size, search, withoutPhone);

        let ids = []
        _.forEach(selectedUsers, function (u) {
            ids.push(u.id);
        })
        setSelectedUserIDs(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, size, search, selectedUsers]);

    if(!props.user || !props.user.providersFiltered){
        return null;
    }

    const isSelectedUser = (e) => {
        return (!_.isEmpty(selectedUserIDs) && _.find(selectedUserIDs, function(u) {
            return u === e.id;
        }));
    }

    const selectUser = (user) => {
        if(multipleSelect) {
            if (isSelectedUser(user)) {
                let idx = _.findIndex(selectedUserIDs, function (u) {
                    return u === user.id;
                })
                if (idx > -1) {
                    selectedUserIDs.splice(idx, 1)
                }
            } else {
                selectedUserIDs.push(user.id);
            }
            setSelectedUserIDs(selectedUserIDs);
        } else {
            var selectedIds = [];
            if (!isSelectedUser(user)) {
                selectedIds = [user.id];
            }
            setSelectedUserIDs(selectedIds);
        }
        onSelect(user);
    }

    let userPage = withoutPhone ? props.user.providerFilteredGroups : props.user.providersFiltered;
    return (
        <div className="teamMembersList">
            {
                _.map(userPage.content, function(client){
                    return (
                        <div className={`teamMemberCell ${selectedUserIDs.includes(client.id) ? " selected" : ""}`}
                                key={client.id} onClick={onSelect ? selectUser.bind(this, client) : null}>
                            <div className="profilePicture">
                                <div className="icon"/>
                            </div>

                            <div className="memberDetails">
                                <div className="memberName">{client.firstName + " " + client.lastName}</div>
                            </div>
                        </div>
                    )
                })
            }
            <div className="teamMembersListPager">
                {
                    (userPage && userPage.totalPages > 1) ? (<Pagination count={userPage.totalPages} page={page} onChange={handlePageChange} />) : null
                }
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamList);