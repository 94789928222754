import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import _ from "lodash";

import {deleteWorkflow, fetchAllWorkflows} from "@/shared/workflow/actions";
import Modal from "@/common/Modal";
import PhoneNumberDisplay from "@/common/PhoneNumberDisplay";
import {fetchMasterNumber} from "@/shared/connect_config/actions";

import AddResponseGroup from "./AddResponseGroup";
import EditResponseGroup from "./EditResponseGroup";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getWorkflows : () => dispatch(fetchAllWorkflows()),
    getMasterPhoneNumber: () => dispatch(fetchMasterNumber()),
    deleteWorkflow: (id) => dispatch(deleteWorkflow(id))
})

function ResponseGroupSetup( props ){
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [editWorkflow, setEditWorkflow] = React.useState(false);

    React.useEffect(() => {
        props.getWorkflows();
        props.getMasterPhoneNumber();
    }, []);

    const onSuccess = (rule) => {
        props.getWorkflows();
        setOpen(false);
        setEditOpen(false);
    }

    const onDelete = (workflowId) => {
        props.deleteWorkflow(workflowId);
        setOpen(false);
        setEditOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleEditOpen = (workflow) => {
        props.history.push(`/settings/groups/${workflow.id}`,
            {
                workflow: workflow,
                masterNumber: props.connectConfig.masterPhoneNumber,
            });
    }

    return (
        <div className="rootView groupsView">
            <div className="navBar">
                <div className="title">Communication Groups</div>
                <div className="actions">
                    <div className="">
                        <Modal
                            button={(<div className="add action">
                                <div className="icon"/>
                                <div className="title">Add Communication Group</div>
                            </div>)}
                            content={(<AddResponseGroup
                                onSuccess={onSuccess}
                                onCancel={handleClose}
                            />)}
                            title="Add Communication Group"
                            size="medium"
                            handleClose={handleClose}
                            handleOpen={handleOpen}
                            open={open}
                        />

                    </div>
                </div>
                <div className="separator"></div>
            </div>
            <div className="tableView groupsList">
                <div className="tableSection">
                    {
                        _.map(props.workflow.allWorkflows, (w, index) => {
                            return (
                                <div key={index} className="tableCell groupCell">

                                    <div className="cellBody">
                                        <div className="title groupName">{w.name}</div>
                                        <div className="detail">
                                            {
                                                w.smsConfig && w.smsConfig.sharedNumberHandle && props.connectConfig.masterPhoneNumber ? (
                                                    <div>
                                                        <PhoneNumberDisplay phoneNumber={props.connectConfig.masterPhoneNumber.number}/>: <span>@{w.smsConfig.sharedNumberHandle}</span>
                                                    </div>
                                                ) : (
                                                    <PhoneNumberDisplay phoneNumber={w.smsConfig.phoneNumber}/>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="cellActions">
                                        <div onClick={handleEditOpen.bind(this, w)} className="cellAction actionEdit button small action">
                                            <div className="title">Edit</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponseGroupSetup));