import React, { useState, useEffect, useContext } from 'react';
import {
    useHistory,
    Route,
    Redirect
} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { EidAuthContext } from '../eid/EIDAuthContext';

const PrivateRoute = ({ render: Component, ...rest }) => {
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { eidUser } = useContext(EidAuthContext);

    const authenticate = () => {
        if (eidUser) {
            setLoaded(true);
            setIsAuthenticated(true);
            return;
        }
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setLoaded(true);
                setIsAuthenticated(true);
            })
            .catch(() => history.push('/auth'));
    };

    useEffect(() => {
        authenticate();
        const unlisten = history.listen(async () => {
            if (!eidUser) {
                try {
                    await Auth.currentAuthenticatedUser();
                } catch {
                    if (isAuthenticated) {
                        setIsAuthenticated(false);
                    }
                }
            }
        });

        return () => unlisten();
    }, [isAuthenticated]);

    if (!loaded) return null;

    return (
        <Route
            {...rest}
            render={props => {
                return isAuthenticated ? (
                    <Component {...props} isAuthenticated />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/auth",
                        }}
                    />
                );
            }}
        />
    );
};

export default PrivateRoute;