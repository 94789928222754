import {
  GET_GETSTARTED_STEPS,
  GET_GETSTARTED_STEPS_SUCCESS,
  GET_GETSTARTED_STEPS_FAILURE
} from './actions'

const initialState = {
    steps: {}
}

export default (state = initialState, action) => {
 switch (action.type) {
  case GET_GETSTARTED_STEPS:
  case GET_GETSTARTED_STEPS_FAILURE:
  	return state;
  	break;
  case GET_GETSTARTED_STEPS_SUCCESS:
   return Object.assign({}, state, {
        steps: action.payload
      });
  default:
   return state
 }
}