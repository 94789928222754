import React from "react";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import Select from "react-select";
import _ from "lodash";

import InputCell from "@/common/form/InputCell";


export default function VerifyInsurance({personDemographics, interaction, onInsuranceSelect, ...props}){
    const [insurancePackages, setInsurancePackages] = React.useState([]);
    const [personInsurancePolicies, setPersonInsurancePolicies] = React.useState([]);
    const [personDemographicMapping, setPersonDemographicMapping] = React.useState({});
    const [selectedInsurancePackage, setSelectedInsurancePackage] = React.useState(false);
    const [sameAsPatient, setSameAsPatient] = React.useState(true);
    const [firstName, setFirstName] = React.useState(personDemographics.firstName);
    const [lastName, setLastName] = React.useState(personDemographics.lastName);
    const [policyId, setPolicyId] = React.useState("");
    const [birthday, setBirthday] = React.useState(personDemographics.dateOfBirth);
    const [gender, setGender] = React.useState(personDemographics.gender);
    const [selectedPolicy, setSelectedPolicy] = React.useState((interaction && interaction.insurance ? interaction.insurance : null));


    React.useEffect(() => {
        loadInsurancePackages();
    }, [])

    React.useEffect(() => {
        if(personDemographics && personDemographics.id) {
            loadPersonDemographicsMap(personDemographics.id);
        }
    }, [personDemographics])

    const loadPersonInsurancePolicies = () => {
        API.get('Core', '/api/v1/insurance/person/' + personDemographics.id)
            .then(response => {
                    setPersonInsurancePolicies(response);
                },
                error => {
                    toast("Could not load person insurance policies: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    const savePersonInsurancePolicy = () => {

        let insurancePolicyReqBody = {
            insurancePackage : selectedInsurancePackage,
            personDemographics : personDemographics,
            policyId: policyId,
            policyHolderFirstName: firstName,
            policyHolderLastName: lastName,
            policyHolderDob: birthday,
            policyHolderSex: gender
        }

        API.post('Core', '/api/v1/insurance/person/' + personDemographics.id, {body : insurancePolicyReqBody})
            .then(response => {
                    setSelectedPolicy(response.insurance);
                    if(response.lastErrorMsg){
                        toast("Could not save person insurance policies: " + response.lastErrorMsg, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                    }
                    onInsuranceSelect(response.insurance);
                },
                error => {
                    toast("Could not save person insurance policies: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    const loadInsurancePackages = () => {
        API.get('Core', '/api/v1/insurance/external/search')
            .then(response => {
                    setInsurancePackages(response);
                },
                error => {
                    toast("Could not load insurance packages: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    const onGenderChange = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setGender(obj.value);
                break;
        }
    }

    const loadPersonDemographicsMap = (personDemographicsId) => {
        API.get('Core', '/api/v1/records/person-demographics/' + personDemographicsId + '/sync-details')
            .then(response => {
                setPersonDemographicMapping(response);
            },
            error => {
                toast("Could not load mapping: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            })
    }

    const onInsurancePackageSelect = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setSelectedInsurancePackage(obj)
                break;
        }
    }

    if(!insurancePackages || insurancePackages.length ==0){
        return (<div className="emptyPlaceholder placeholderLoading"><span className="placeholderTitle">Loading Insurance Packages...</span></div>);
    }

    if(!personDemographicMapping || !personDemographicMapping.externalRecordId){
        return (
            <div className="emptyPlaceholder placeholderEmpty"><span className="placeholderBody">The patient is not yet created in the EHR. Please complete the patient details first.</span></div>
        )
    }

    let genderOpts = [{value: "MALE", label: "Male"}, {value: "FEMALE", label: "Female"}, {value: "NONBINARY", label: "Non-Binary"}];
    let selectedGender = _.find(genderOpts, function(o){return o.value == gender;});

    return (
        <div>
            <div className="tableSection sectionInsurancePlan">
                <div className="tableCell titledSelectCell">
                    <div className="title">Insurance Plan</div>
                    {/* We should filter to just packages that the personDemographicsMapping connector type is mapped to - but we aren't that complex yet */}
                    <Select className="selectCell" classNamePrefix="selectCell"
                            isDisabled={false}
                            onChange={onInsurancePackageSelect}
                            value={selectedInsurancePackage ? selectedInsurancePackage : "--"}
                            options={insurancePackages}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.externalId}
                    />
                </div>
            </div>
            {
                selectedInsurancePackage ? (
                    <div className="tableSection sectionInteractionDetails">
                        <div className="sectionTitle">
                            <div className="title">Policy Holder Information</div>
                        </div>
                        <div onClick={() => {setSameAsPatient(!sameAsPatient)}} className={`tableCell switchCell ${sameAsPatient ? 'switchOn' : 'switchOff'}`}>
                            <div className="title">Policy Holder Same as Patient</div>
                            <div className={`accessory accessorySwitch ${sameAsPatient ? 'on' : 'off'}`}>
                                <div className="switchThumb"/>
                            </div>
                        </div>
                        <InputCell
                            placeholder={"First Name"}
                            defaultValue={firstName}
                            required
                            disabled={sameAsPatient}
                            id="firstName"
                            label="First Name"
                            onChange={(e) => {setFirstName(e.target.value)}}
                        />
                        <InputCell
                            placeholder={"Last Name"}
                            required
                            disabled={sameAsPatient}
                            defaultValue={lastName}
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            onChange={(e) => {setLastName(e.target.value)}}
                        />
                        <div className="tableCell titledSelectCell">
                            <div className="title">Gender</div>
                            <Select styles={{width: "100px"}} className="selectCell workflowTextBackSetting" classNamePrefix="selectCell"
                                    onChange={onGenderChange}
                                    value={selectedGender}
                                    options={genderOpts}
                                    isDisabled={sameAsPatient}
                            />
                        </div>
                        <InputCell
                            type={"date"}
                            required
                            label={"Birthdate"}
                            disabled={sameAsPatient}
                            defaultValue={birthday}
                            onChange={(e) => {setBirthday(e.target.value)}}
                        />
                        <InputCell
                            placeholder={"Typically Found on Insurance ID Card"}
                            required
                            defaultValue={policyId}
                            id="lastName"
                            label="Policy ID"
                            name="policyID"
                            onChange={(e) => {setPolicyId(e.target.value)}}
                        />
                    </div>
                ) : null
            }
            {
                selectedInsurancePackage && personDemographics ? (
                    <div className={"tableSection"}>
                        <div onClick={() => savePersonInsurancePolicy()} className={`button tint large buttonSaveAndVerify`}>
                            <div className="title">Save and Verify Policy</div>
                        </div>
                    </div>
                ) : null
            }

        </div>
    )
}