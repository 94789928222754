import React from 'react';
import moment from '../common/momentConfig';


export function rawDateString(utcTimestamp, format, utc) {
    let time = moment.utc(utcTimestamp).toDate();
    if (!utc) {
        time = moment(utcTimestamp).toDate();
    }
    return moment(time).local().format(format);
}

export function getLocalDateStringFromUTC(utcTimestamp, format) {
    return moment.utc(utcTimestamp).local().format(format);
}

export function getDateString(timestamp, format) {
    return moment(timestamp).format(format);
}

export function getDate(timestamp, format, encounter) {
    if (encounter && encounter.source && encounter.source.utc)
        return getLocalDateStringFromUTC(timestamp, format)
    else
        return getDateString(timestamp, format)
}

export default function ({ utcTimestamp, format, utc }) {
    if (utc == null) {
        utc = true;
    }
    return (
        <span>{rawDateString(utcTimestamp, format, utc)}</span>
    )
}