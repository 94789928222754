import React from 'react';
import { connect } from "react-redux";
import Select from "react-select";
import _ from "lodash";

import { fetchAllWorkflows, fetchPriorities } from "@/shared/workflow/actions";
import {
    AssetConversationListActionRotation,
    AssetConversationListActionWorking,
} from "../../icons/Assets"
import {
    clearNewConversation,
    fetchWorkflowConversations,
    startConversation
} from "@/shared/conversation/actions";
import { Button } from "../../components/Buttons";
import moment from '../../common/momentConfig';

import ProfilePicture from "../../common/ProfilePicture";
import { fetchAllEncounters } from "@/shared/encounter/actions";
import StartConversation from "./StartConversation";
import ProviderRotationMemberUpdate from "../providerrotation/ProviderRotationMemberUpdate";
import { providerRotationFeature } from "../../utils/utils";
import ProviderRotation from "../providers/ProviderRotation";


const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getPriorities: () => dispatch(fetchPriorities()),
    getWorkflowConversations: (workflowId) => dispatch(fetchWorkflowConversations(workflowId)),
    startConversation: (workflowId, phoneNumber, encounter) => dispatch(startConversation(workflowId, phoneNumber, encounter)),
    clearNewConversation: () => dispatch(clearNewConversation()),
    getEncounters: () => dispatch(fetchAllEncounters()),
    getWorkflows: () => dispatch(fetchAllWorkflows())
})

function ConversationList(props) {
    const { onConversationChange, activeConversationId, activeWorkflow, onWorkflowChange, conversationListRef } = props;

    const [stateClosed, setStateClosed] = React.useState({});
    const [rotationMember, setRotationMember] = React.useState(null);
    const [currentWorkFlow, setCurrentWorkflow] = React.useState(activeWorkflow);


    React.useEffect(() => {
        props.getPriorities();
    }, [])

    React.useEffect(() => {
        let selectedWorkflow = _.find(props.workflow.allWorkflows, function (w) { return w.id == currentWorkFlow.id; })
        setCurrentWorkflow(selectedWorkflow);
    }, [props.workflow.allWorkflows])

    React.useEffect(() => {
        props.getWorkflowConversations(currentWorkFlow.id);
        if (currentWorkFlow.providerRotation != null) {
            let isInRotation = _.findIndex(currentWorkFlow.providerRotation.providerRotationMembers, function (prm) {
                return prm.providerUser.id == props.user.aboutMe.user.id
            });
            if (isInRotation >= 0) {
                setRotationMember(currentWorkFlow.providerRotation.providerRotationMembers[isInRotation]);
            } else {
                setRotationMember(null);
            }
        } else {
            setRotationMember(null);
        }
    }, [currentWorkFlow])

    React.useEffect(() => setCurrentWorkflow(activeWorkflow), [activeWorkflow]);

    if (props.conversation.conversations == null || currentWorkFlow == null) {
        return null;
    }

    if (props.conversation.newlyStartedConversation) {
        onConversationChange(props.conversation.newlyStartedConversation.id);
        props.clearNewConversation();
    }

    if (props.conversation.conversations.length > 0 && !activeConversationId) {
        let firstConv = _.head(_.sortBy(_.sortBy(props.conversation.conversations, function (c) { return c.workflowState.rank; }), function (c) { return c.createdAt }).reverse());
        onConversationChange(firstConv.id);
    }

    const onStartConversation = (phoneNumber, encounter) => {
        props.startConversation(currentWorkFlow.id, phoneNumber, encounter);
    }

    const toggleStateClosedOpen = (stateId) => {
        stateClosed[stateId] = stateClosed[stateId] ? false : true;
        setStateClosed(Object.assign({}, stateClosed));
    }

    const rotationMemberUpdate = (initialWorkState) => {
        //let rotMember = {...rotationMember};
        //rotMember.working = !initialWorkState
        //setRotationMember(rotMember);
        props.getWorkflows();
    }

    //TODO: the rotation modal doesn't upadte as conversations come in and are added.
    let providerHandoffMemberUpdateSection = null;
    let featureList = props.user.features.map(feature => feature.feature);
    if (props.user.features != null) {
        if (featureList.includes(providerRotationFeature) && currentWorkFlow.providerRotation != null && rotationMember != null) {
            providerHandoffMemberUpdateSection = (
                <div>
                    {/*<ProviderRotation*/}
                    {/*    rotation={currentWorkFlow.providerRotation}*/}
                    {/*>*/}
                    {/*    <div className="conversationListAction conversationRotationButton">*/}
                    {/*        <AssetConversationListActionRotation className="conversationListActionIcon"/>*/}
                    {/*        <div className="conversationListActionTitle">Rotation</div>*/}
                    {/*        <div className="conversationListActionDetail">{_.filter(currentWorkFlow.providerRotation.providerRotationMembers, function(m){ return m.working == true;}).length} working</div>*/}
                    {/*        <AssetGeneralDisclosureIndicatorSmall className="conversationListActionDisclosureIndicator"/>*/}
                    {/*    </div>*/}
                    {/*</ProviderRotation>*/}
                    <ProviderRotationMemberUpdate
                        onUpdate={rotationMemberUpdate.bind(this, rotationMember.working)}
                        rotationMember={rotationMember}
                    >
                        {rotationMember.working ? (
                            <div className="conversationListAction conversationWorkingButton statusWorking">
                                <AssetConversationListActionWorking className="conversationListActionIcon" />
                                <div className="conversationListActionTitle">Working</div>
                                <div className="conversationListActionSwitch">
                                    <div className="conversationListActionSwitchKnob" />
                                </div>
                            </div>
                        ) : (
                            <div className="conversationListAction conversationWorkingButton statusNotWorking">
                                <AssetConversationListActionWorking className="conversationListActionIcon" />
                                <div className="conversationListActionTitle">Not Working</div>
                                <div className="conversationListActionSwitch">
                                    <div className="conversationListActionSwitchKnob" />
                                </div>
                            </div>
                        )}
                    </ProviderRotationMemberUpdate>
                </div>
            );
        } else if (featureList.includes(providerRotationFeature) && currentWorkFlow.providerRotation != null) {
            providerHandoffMemberUpdateSection = (
                <div>
                    <ProviderRotation
                        rotation={currentWorkFlow.providerRotation}
                    >
                        <div className="button action small">
                            <div className="title">{"View Rotation"}</div>
                        </div>
                    </ProviderRotation>
                </div>
            );
        }
    }


    return (
        <div className="chatsList" ref={conversationListRef}>
            <div className="navBar">
                <div className="title">
                    Conversations
                </div>
                <div className="actions">
                    {currentWorkFlow.providerRotation != null ?
                        (
                            <ProviderRotation
                                rotation={currentWorkFlow.providerRotation}
                            >
                                <Button
                                    style="secondary"
                                    size="small"
                                    icon={<AssetConversationListActionRotation />}
                                />
                            </ProviderRotation>
                        ) : null
                    }
                    <StartConversation title={"Start Conversation"} submitBtnTitle={"Start"} encounters={props.encounter.allEncounters} loadEncounters={props.getEncounters} onStart={onStartConversation}>
                        <div className="startConversation button small tint">
                            <div className="icon" />
                        </div>
                    </StartConversation>
                </div>
                <div className="separator"></div>

            </div>
            <div className="listActions">
                {
                    (props.workflow.allWorkflows && props.workflow.allWorkflows.length > 1) ? (
                        <div className="conversationListAction responseGroupPicker">
                            <div className="conversationListActionIcon" />
                            <div className="conversationListActionTitle">Group</div>
                            <Select className="groupPickerValue" classNamePrefix="groupPickerValue"
                                onChange={onWorkflowChange}
                                value={currentWorkFlow}
                                options={props.workflow.allWorkflows}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                            />
                        </div>
                    ) : null
                }

                {providerHandoffMemberUpdateSection}

                <div className="separator"></div>
            </div>
            {
                _.map(currentWorkFlow.states, function (state) {
                    let closed = stateClosed[state.id];
                    let conversations = _.filter(props.conversation.conversations, function (o) {
                        return o.workflowState.id == state.id;
                    })
                    return (
                        <div className={`chatsSection ${closed ? 'closed' : 'expanded'}`} key={state.id}>
                            <div onClick={toggleStateClosedOpen.bind(this, state.id)} className="sectionHeading">
                                <div className="headingContainer">
                                    <div className="title">{state.name}{closed ? ' (' + conversations.length + ')' : ''}</div>
                                    <div className="icon" />
                                </div>
                            </div>
                            {
                                closed ? null :
                                    _.map(_.sortBy(conversations, function (c) { return c.createdAt }).reverse(), function (conv, j) {
                                        let time = moment.utc(conv.createdAt).fromNow();
                                        let currentTransitionIdx = _.findIndex(conv.workflowStateTransitions, function (s) {
                                            return s.workflowState.id == conv.workflowState.id && s.exitedStateTime == null;
                                        });
                                        let currentTransition = conv.workflowStateTransitions[currentTransitionIdx];
                                        let overdue = false
                                        if (currentTransition != null && currentTransition.enteredStateTime != null) {
                                            let secondsInState = moment().unix() - moment.utc(currentTransition.enteredStateTime).unix();
                                            if (secondsInState > 0 && currentTransition != null && currentTransition.workflowState.maxSecondsElapsed > 0) {
                                                overdue = secondsInState > currentTransition.workflowState.maxSecondsElapsed;
                                            }
                                        }
                                        
                                        return (
                                            <div className={`chatCell ${conv.id == activeConversationId ? " selected" : ""}`} key={conv.id} onClick={onConversationChange.bind(this, conv.id)}>
                                                <ProfilePicture user={conv.initiator} size={36} />
                                                <div className="chatDetails">
                                                    <div className="clientDetails">
                                                        <div className="clientName">
                                                            <span className="name">{conv.initiator.firstName} {conv.initiator.lastName}</span>
                                                            <span className="phone">{conv.initiator.phone}</span>
                                                        </div>
                                                        <div className="timestamp">{time}</div>
                                                    </div>
                                                    <div className="attributes">
                                                        {/*{*/}
                                                        {/*    conv.priority == null ? (*/}
                                                        {/*        <div className="attributeItem priority none">no priority</div>*/}
                                                        {/*    ) : (*/}
                                                        {/*        <div className={`attributeItem priority ${priorityClassMap[conv.priority.rank]}`}>{_.lowerCase(conv.priority.name)} priority</div>*/}
                                                        {/*    )*/}
                                                        {/*}*/}
                                                        {
                                                            conv.department ? (
                                                                <div className="attributeItem priority none">{conv.department.name}</div>
                                                            ) : null
                                                        }
                                                        {
                                                            conv.unreadCount && conv.id != activeConversationId ? (
                                                                <div className="attributeItem unreadBadge">
                                                                    <div className="icon"></div>
                                                                    <div className="count">{conv.unreadCount}</div>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            overdue ? (
                                                                <div className="attributeItem overdueIndicator">
                                                                    <div className="icon"></div>
                                                                    <div className="title">overdue</div>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                                <div className="background"></div>
                                            </div>
                                        )
                                    })
                            }
                            <div className="sectionSeparator">
                                <div className="separator"></div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ConversationList);