import React from 'react';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import Select from "react-select";
import _ from "lodash";

import InputCell from "@/common/form/InputCell";
import { TIMEZONES } from "@/utils/utils";

export default function EditTenant({ tenant, onSuccess, onCancel }) {
    const companyNameRef = React.createRef();
    const testEmailRef = React.createRef();
    const salesforceIdRef = React.createRef();
    const siteKeyInternalRef = React.createRef()
    const globalEmployerIdRef = React.createRef()
    const [timezoneObj, setTimezoneObj] = React.useState(tenant.timezoneName ? _.find(TIMEZONES, function (tz) { return tz.value === tenant.timezoneName }) : TIMEZONES[0]);

    const submitEdit = () => {
        API.put('Core', '/api/v1/support-tools/tenants/' + tenant.id, {
            body: {
                name: companyNameRef.current.value,
                timezoneName: timezoneObj.value,
                testEmailInboxAddress: testEmailRef.current.value,
                salesforceId: salesforceIdRef.current.value,
                siteKeyInternal: siteKeyInternalRef.current.value === '' ? null: siteKeyInternalRef.current.value,
                globalEmployerId: globalEmployerIdRef.current.value === '' ? null: globalEmployerIdRef.current.value,
            }
        })
            .then(response => {
                toast("Successfully saved tenant!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                onSuccess();
            },
                error => {
                    toast("Could not save tenant: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const onTimezoneChange = (obj, action) => {
        setTimezoneObj(obj);
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"Tenant Name"}
                            defaultValue={tenant.name}
                            required
                            id="tenantName"
                            label="Tenant Name"
                            inputRef={companyNameRef}
                        />
                    </div>
                    <div className="tableSection sectionHandoffType">
                        <div className="sectionTitle">Timezone</div>
                        <Select className="cell selectCell" classNamePrefix="selectCell"
                                onChange={onTimezoneChange}
                                defaultValue={timezoneObj}
                                value={timezoneObj}
                                options={TIMEZONES}
                        />

                    </div>

                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"Test Email Inbox"}
                            defaultValue={tenant.testEmailInboxAddress}
                            required
                            label="Test Email Inbox"
                            inputRef={testEmailRef}
                        />
                    </div>

                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={"Salesforce Id"}
                            defaultValue={tenant.salesforceId}
                            label="Salesforce Id"
                            inputRef={salesforceIdRef}
                        />
                    </div>
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">Foundation/PowerSSO</div>
                        <InputCell
                            defaultValue={tenant.siteKeyInternal}
                            id="siteKeyInternal"
                            label="Site Key Internal"
                            inputRef={siteKeyInternalRef}
                        />
                        <InputCell
                            defaultValue={tenant.globalEmployerId}
                            id="globalEmployerId"
                            label="Global Employer ID"
                            inputRef={globalEmployerIdRef}
                        />
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={submitEdit} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>
                <div className="separator"></div>
            </div>
        </div>
    );
}