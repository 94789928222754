import {AssetTaskStatusClosed, AssetTaskStatusInProgress, AssetTaskStatusOverdue} from "../icons/Assets";
import React from "react";

export function getTaskStatusIcon(taskStatus){
    let icon = null;

    switch (taskStatus){
        case "OPEN":
            break;
        case "IN_PROGRESS":
            icon = (<AssetTaskStatusInProgress />);
            break;
        case "OVERDUE":
            icon = (<AssetTaskStatusOverdue />);
            break;
        case "CLOSED":
            icon = (<AssetTaskStatusClosed />);
            break;
    }
    return icon;
}