import {Editor, EditorState} from 'draft-js';
import _ from 'lodash';
import {toast} from "react-toastify";

import {
    NEW_MESSAGE_EVENT,
    UPDATE_MESSAGE_EVENT,
    GET_WORKFLOW_CONVERSATIONS_SUCCESS,
    GET_CONVERSATION_MESSAGES_FAILURE,
    GET_CONVERSATION_MESSAGES,
    GET_CONVERSATION_MESSAGES_SUCCESS,
    GET_WORKFLOW_CONVERSATIONS_FAILURE,
    UPDATE_EDITOR_STATE,
    NEW_CONVERSATION_EVENT,
    UPDATE_CONVERSATION_SUCCESS,
    POST_MESSAGE,
    POST_MESSAGE_SUCCESS,
    POST_MESSAGE_FAILURE,
    UPDATE_CONVERSATION_EVENT,
    GET_CONVERSATION_SUCCESS,
    UPDATE_CONVERSATION_NOTES_SUCCESS,
    GET_CONVERSATION_NOTES_SUCCESS,
    GET_CONVERSATION_SERVICE_OFFERINGS_SUCCESS,
    START_CONVERSATION_SUCCESS,
    START_CONVERSATION_FAILURE,
    CLEAR_NEW_CONVERSATION,
    UPDATE_ACTIVE_WORKFLOW,
    GET_ALL_CONVERSATIONS_SUCCESS,
    GET_ALL_CONVERSATIONS_FAILURE,
    GET_CONVERSATION_VIDEO_CHATS_SUCCESS,
    CONVERSATION_CHAT_ROOM_EVENT, GET_CONVERSATION_DISPOSITIONS_SUCCESS, GET_ALL_INTERPRETERS_SUCCESS
} from './actions'

const initialState = {
    allOpenConversations:[],
    activeWorkflow:null,
    conversations: [],
    activeConversationId:null,
    activeConversationMessages:null,
    newlyStartedConversation: null,
    messagesLoading:true,
    messageLoadError: null,
    activeNotes: null,
    lastNotificationMessage: null,
    activeServiceOfferings: [],
    conversationDispositions: [],
    videoChats: {},
    interpreters: [],
    editor : {
        saving: false,
        state: EditorState.createEmpty(),
        error: ""
    }
}

export default (state = initialState, action) => {

    function addMessageIfNotDuplicate(message){
        if(message.conversationId != state.activeConversationId){
            return;
        }
        let messageExists = _.find(state.activeConversationMessages, function(m){return m.id == message.id});
        if(!messageExists){
            let newMessageArr = _.cloneDeep(state.activeConversationMessages);
            newMessageArr.push(message);
            state.activeConversationMessages = newMessageArr;
        }
    }

    function updateMessageInConversation(message){
        if(message.conversationId != state.activeConversationId){
            return;
        }
        let messageExistsIndex = _.findIndex(state.activeConversationMessages, function(m){return m.id == message.id});
        state.activeConversationMessages[messageExistsIndex] = message;
        let newMessageArr = _.cloneDeep(state.activeConversationMessages);
        state.activeConversationMessages = newMessageArr;
    }

    function addOrUpdateConversations(conversation){
        if(!conversation){
            return;
        }
        let conversationExists = _.findIndex(state.conversations, function(c){
            return c.id == conversation.id
        });
        if(conversationExists >= 0){
            state.conversations[conversationExists] = conversation;
        }
        else{
            state.conversations.push(conversation);
        }
    }

    function addOrUpdateAllConversations(conversation){
        let existsAllConvos = _.findIndex(state.allOpenConversations, function(c){
            return c.id == conversation.id
        });
        // It is open and exists
        if(existsAllConvos >= 0 && conversation.status != "CLOSED"){
            state.allOpenConversations[existsAllConvos] = conversation;
        }
        // It is open but hasn't been added
        else if(conversation.status != "CLOSED"){
            state.allOpenConversations.push(conversation);
        }
        // It was closed - removed
        else if(existsAllConvos >= 0 && conversation.status == "CLOSED"){
            state.allOpenConversations.splice(existsAllConvos, 1);
        }
    }

    switch (action.type) {
        case POST_MESSAGE:
            state.editor.saving = true;
            return Object.assign({}, state);
        case GET_CONVERSATION_SUCCESS:
            addOrUpdateConversations(action.payload);
            return Object.assign({}, state);
        case UPDATE_CONVERSATION_EVENT:
            if(state.activeWorkflow && action.data.workflowId == state.activeWorkflow.id){
                addOrUpdateConversations(action.data.payload);
            }
            addOrUpdateAllConversations(action.data.payload);
            return Object.assign({}, state);
        case NEW_MESSAGE_EVENT:
            let newMessage = action.data.payload;
            addMessageIfNotDuplicate(newMessage);
            state.lastNotificationMessage = newMessage;
            return Object.assign({}, state);
        case UPDATE_MESSAGE_EVENT:
            let updateMessage = action.data.payload;
            updateMessageInConversation(updateMessage);
            return Object.assign({}, state);
        case POST_MESSAGE_SUCCESS:
            state.editor.saving = false;
            state.editor.state = EditorState.createEmpty();
            addMessageIfNotDuplicate(action.payload);
            return Object.assign({}, state);
        case POST_MESSAGE_FAILURE:
            state.editor.saving = false;
            state.editor.error = action.payload;
            toast(action.payload, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            return Object.assign({}, state);
        case CLEAR_NEW_CONVERSATION:
            return Object.assign({}, state, {
                newlyStartedConversation: null
            })
        case UPDATE_EDITOR_STATE:
            state.editor.state = action.payload;
            return Object.assign({}, state)
        case GET_WORKFLOW_CONVERSATIONS_SUCCESS:
            return Object.assign({}, state, {
                conversations: action.payload
            });
        case GET_CONVERSATION_MESSAGES:
            return Object.assign({}, state, {
                messagesLoading: true,
                activeConversationMessages: [],
                activeConversationId:null,
                messageLoadError: null
            });
        case GET_CONVERSATION_MESSAGES_SUCCESS:
            return Object.assign({}, state, {
                activeConversationMessages: action.payload,
                activeConversationId: action.conversationId,
                messageLoadError: null,
                messagesLoading: false
            });
        case UPDATE_ACTIVE_WORKFLOW:
            return Object.assign({}, state, {
                activeWorkflow: action.payload,
                activeConversationId: null,
                messageLoadError: null,
                messagesLoading: false,
                activeConversationMessages: null,
                conversations: []
            })
        case GET_CONVERSATION_MESSAGES_FAILURE:
            return Object.assign({}, state, {
                activeConversationMessages: [],
                activeConversationId: action.conversationId,
                messageLoadError: action.payload,
                messagesLoading: false
            });
        case UPDATE_CONVERSATION_SUCCESS:
            addOrUpdateConversations(action.payload);
            return Object.assign({}, state);
        case START_CONVERSATION_SUCCESS:
            addOrUpdateConversations(action.payload);
            return Object.assign({}, state, {
                newlyStartedConversation: action.payload
            });
        case START_CONVERSATION_FAILURE:
            toast(action.payload, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            return state;
        case GET_CONVERSATION_SERVICE_OFFERINGS_SUCCESS:
            state.activeServiceOfferings = action.payload;
            return Object.assign({}, state);
        case GET_CONVERSATION_DISPOSITIONS_SUCCESS:
            state.conversationDispositions = action.payload;
            return Object.assign({}, state);
        case GET_ALL_INTERPRETERS_SUCCESS:
            state.interpreters = action.payload;
            return Object.assign({}, state);
        case UPDATE_CONVERSATION_NOTES_SUCCESS:
            state.activeNotes = action.payload;
            return Object.assign({}, state);
        case GET_CONVERSATION_NOTES_SUCCESS:
            state.activeNotes = action.payload;
            return Object.assign({}, state);
        case GET_ALL_CONVERSATIONS_SUCCESS:
            return Object.assign({}, state, {
                allOpenConversations: action.payload
            });
        case GET_CONVERSATION_VIDEO_CHATS_SUCCESS:
            if(action.payload){
                let convoId = action.payload.conversationId;
                let chats = action.payload.videoChats;
                state.videoChats[convoId] = chats;
                return Object.assign({}, state);
            }
            return state;
        case CONVERSATION_CHAT_ROOM_EVENT:
            let chatRooms = action.data.payload;
            state.videoChats[chatRooms.conversationId] = chatRooms.videoChats;
            return Object.assign({}, state);
        case GET_ALL_CONVERSATIONS_FAILURE:
            toast(action.payload, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            return state;
        default:
            return state
    }
}