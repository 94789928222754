import React from "react"

export default function PositivityFeedbackCell(props) {

  return (
    <div className={`feedbackCell ${props.extraClasses}`} style={props.feedbackStyle}>
      <h1 className="feedbackText">{props.feedbackText}</h1>
        {
            props.callTaker ? (
                <p className="feedbackAuthor">{props.callTaker}</p>
            ) : null
        }
    </div>
  )
}
