import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Pagination from '@material-ui/lab/Pagination';
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import _ from "lodash";

import { fetchClientSearch } from "@/shared/client/actions";
import ProfilePicture from "@/common/ProfilePicture";
import ConfirmModal from "@/common/ConfirmModal";
const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    loadClients: (page, size, search) => dispatch(fetchClientSearch(page, size, search))
})

function ClientList(props) {
    const { page, size, search, handlePageChange, onEditClient } = props;

    useEffect(() => {
        // Page on server is 0 based
        props.loadClients(page - 1, size, search);
    }, [page, size, search]);

    if (!props.client || !props.client.clientListPage) {
        return null;
    }

    const confirmDelete = (client) => {
        API.del('Core', '/api/v1/client/' + client.id)
            .then(response => {
                props.loadClients(page - 1, size, search);
            },
                error => {
                    toast("Could not delete user: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    let clientPage = props.client.clientListPage;
    return (
        <div className="clientList tableView">
            <div className="clientListContents tableSection" data-testid="contactList">
                {
                    _.map(clientPage.content, function (client, k) {
                        let animalInfo = null;
                        if (client.animals && client.animals.length == 1) {
                            animalInfo = (
                                <div className="clientAnimalInfo single">
                                    <span className="clientAnimalInfoName">{client.animals[0].name}</span>
                                    <span className="clientAnimalInfoBreedSpecials">{_.capitalize(client.animals[0].species)} - {_.capitalize(client.animals[0].breed)}</span>
                                </div>);
                        } else if (client.animals && client.animals.length > 1) {
                            animalInfo = (<div className="clientAnimalInfo multiple">{client.animals.length} pets</div>)
                        }

                        return (
                            <div className="clientCell cell" key={client.id}>
                                <div className="clientInfo">
                                    <div className="separator" />
                                    <ProfilePicture user={client} />
                                    <div className="clientDetails">
                                        <div className="clientName">{client.firstName} {client.lastName}</div>
                                        {animalInfo}
                                    </div>
                                </div>
                                <div className="clientEmail clientContact">
                                    <div className="separator" />
                                    <div className="title">{client.email}</div>
                                </div>
                                <div className="clientPhone clientContact">
                                    <div className="separator" />
                                    <div className="title">{client.phone}</div>
                                </div>
                                <div className="clientActions">
                                    <div className="action editClient" onClick={onEditClient.bind(this, client)}>
                                        <div className="actionTitle">Edit Contact</div>
                                    </div>
                                    <ConfirmModal
                                        title={"Are you sure you want to delete this contact?"}
                                        text={"All previous conversations with this contact will be removed."}
                                        confirmTitle={"Confirm"}
                                        onConfirm={confirmDelete.bind(this, client)}
                                    >
                                        <div className="action destructive deleteClient">
                                            <div className="actionTitle">Delete</div>
                                        </div>
                                    </ConfirmModal>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="clientListPager">
                {
                    (clientPage && clientPage.totalPages > 1) ? (<Pagination count={clientPage.totalPages} page={page} onChange={handlePageChange} />) : null
                }
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientList);