import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
import rootReducer from './rootReducers';
import connectPusher from './realtime/PusherSetup';
import _ from 'lodash';

const loggerMiddleware = createLogger();

/**
 * Add actors if needed http://jamesknelson.com/join-the-dark-side-of-the-flux-responding-to-actions-with-actors/
 */
var actors = [
]

function configureStore(initialState={}) {
    const store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(
            thunk,
            // loggerMiddleware
        )
    );
    connectPusher(store);

    var acting = false
    store.subscribe(function() {
        // Ensure that any action dispatched by actors do not result in a new
        // actor run, allowing actors to dispatch with impunity.
        if (!acting) {
            acting = true
            _.forEach(actors, function(actor) {
                actor(store.getState(), store.dispatch)
            })
            acting = false
        }
    })

    return store;
}

const store = configureStore();

export default store;