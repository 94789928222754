import _ from 'lodash';

import {
    ADD_TASK_SUCCESS, ADD_TASK_TYPE_SUCCESS, CLEAR_FEEDBACK_MESSAGES,
    GET_TASK_ACTION_TYPES_SUCCESS,
    GET_TASK_TYPES_SUCCESS,
    GET_TASKS_SUCCESS,
    UPDATE_TASK_SUCCESS, UPDATE_TASK_TYPE, UPDATE_TASK_TYPE_SUCCESS
} from "./actions";

const initialState = {
    tasks: [],
    currentTask: null,
    pageable: null,
    successTaskTypeActionMessage: null
}

export default (state = initialState, action) => {
    function addOrUpdateTask(task){
        if(state.tasks && state.tasks.content) {
            let taskExists = _.findIndex(state.tasks.content, function (t) {
                return t.id == task.id
            });
            if (taskExists >= 0) {
                state.tasks.content[taskExists] = task;
            } else {
                state.tasks.content.push(task);
            }
        }
    }
    
    switch (action.type) {
        case GET_TASKS_SUCCESS:
            return Object.assign({}, state, {
                tasks: action.payload.response,
                pageable: action?.payload?.response?.pageable,
                totalElements: action?.payload?.response?.totalElements || 0,
                totalPages: action?.payload?.response?.totalPages || 0,
            });
        case GET_TASK_TYPES_SUCCESS:
            return Object.assign({}, state, {
                taskTypes: action.payload
            });
        case GET_TASK_ACTION_TYPES_SUCCESS:
            return Object.assign({}, state, {
                taskActionTypes: action.payload
            });
        case ADD_TASK_SUCCESS:
            addOrUpdateTask(action.payload);
            return Object.assign({}, state, {
                tasks: state.tasks
            });
        case UPDATE_TASK_SUCCESS:
            addOrUpdateTask(action.payload);
            return Object.assign({}, state, {
                tasks: state.tasks
            });
        case ADD_TASK_TYPE_SUCCESS:
        case UPDATE_TASK_TYPE_SUCCESS:
            return {
                ...state,
                successTaskTypeActionMessage: action.payload
            }
        case CLEAR_FEEDBACK_MESSAGES:
            return {
                ...state,
                successTaskTypeActionMessage: null
            }
        default:
            return state;
    }
}