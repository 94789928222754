import { API } from 'aws-amplify';

export const NEW_ENCOUNTER_EVENT = 'NEW_ENCOUNTER_EVENT';
export const UPDATE_ENCOUNTER_EVENT = 'UPDATE_ENCOUNTER_EVENT';

export const GET_ALL_ENCOUNTERS = 'GET_ALL_ENCOUNTERS';
export const requestAllEncounters = () => dispatch => {
    dispatch({
        type : GET_ALL_ENCOUNTERS
    });
}

export const GET_ALL_TEST_ENCOUNTERS = 'GET_ALL_TEST_ENCOUNTERS';
export const requestAllTestEncounters = () => dispatch => {
    dispatch({
        type : GET_ALL_TEST_ENCOUNTERS
    });
}

export const POST_TEST_ENCOUNTER = 'POST_TEST_ENCOUNTER';
export const requestPostTestEncounter = () => dispatch => {
    dispatch({
        type : POST_TEST_ENCOUNTER
    });
}

export const POST_TEST_ENCOUNTER_SUCCESS = 'POST_TEST_ENCOUNTER_SUCCESS';
export const postTestEncounterSuccess = (json) => dispatch => {
    dispatch({
        type : POST_TEST_ENCOUNTER_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const POST_TEST_ENCOUNTER_FAILURE = 'POST_TEST_ENCOUNTER_FAILURE';
export const postTestEncounterFailure = (json) => dispatch => {
    dispatch({
        type : POST_TEST_ENCOUNTER_FAILURE,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_ALL_ENCOUNTERS_SUCCESS = 'GET_ALL_ENCOUNTERS_SUCCESS';
export const requestAllEncountersSuccess = (json) => dispatch => {
    dispatch({
        type : GET_ALL_ENCOUNTERS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_ALL_TEST_ENCOUNTERS_SUCCESS = 'GET_ALL_TEST_ENCOUNTERS_SUCCESS';
export const requestAllTestEncountersSuccess = (json) => dispatch => {
    dispatch({
        type : GET_ALL_TEST_ENCOUNTERS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_ALL_RECENT_OPEN_ENCOUNTERS = 'GET_ALL_RECENT_OPEN_ENCOUNTERS';
export const requestRecentOpenEncounters = () => dispatch => {
    dispatch({
        type : GET_ALL_RECENT_OPEN_ENCOUNTERS
    });
}

export const GET_ALL_RECENT_OPEN_ENCOUNTERS_SUCCESS = 'GET_ALL_RECENT_OPEN_ENCOUNTERS_SUCCESS';
export const requestRecentOpenEncountersSuccess = (json) => dispatch => {
    dispatch({
        type : GET_ALL_RECENT_OPEN_ENCOUNTERS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function fetchAllEncounters(page) {

    return function(dispatch) {
        dispatch(requestAllEncounters());


        return API.get('Core', '/api/v1/encounter/page', {
            queryStringParameters: {
                page: page,
                testEncountersOnly: false
            }
        })
            .then(response => {
                    dispatch(requestAllEncountersSuccess(response))
                },
                error => {

                }
            );
    }
}

export function fetchAllTestEncounters(page) {

    return function(dispatch) {
        dispatch(requestAllTestEncounters());


        return API.get('Core', '/api/v1/encounter/page', {
            queryStringParameters: {
                page: page,
                testEncountersOnly: true
            }
        })
            .then(response => {
                    dispatch(requestAllTestEncountersSuccess(response))
                },
                error => {

                }
            );
    }
}

export function fetchRecentOpenEncounters() {

    return function(dispatch) {
        dispatch(requestRecentOpenEncounters());


        return API.get('Core', '/api/v1/encounter/recent-open')
            .then(response => {
                    dispatch(requestRecentOpenEncountersSuccess(response))
                },
                error => {

                }
            );
    }
}

export function postTestEncounter(testEncounter) {

    return function(dispatch) {
        dispatch(requestPostTestEncounter());

        return API.post('Core', '/api/v1/encounter',
            {
                headers: {
                    "source-provider": "CUEHIT",
                    "source-type": "JSON",
                    "test-data": "true",
                    "connector-interface": "CUEHIT"
                },
                body:
                    {"encounter":testEncounter}
            })
            .then(response => {
                    dispatch(postTestEncounterSuccess(response))
                },
                error => {
                    console.log(error);
                    dispatch(postTestEncounterFailure(error.response.data.message));
                }
            );
    }
}