import React from 'react';
import _ from "lodash";
import Select from "react-select";
import { SingleDatePicker } from 'react-dates';
import "react-dates/initialize";
import 'react-dates/lib/css/_datepicker.css';
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { ContentState, Editor, EditorState, CompositeDecorator, Modifier } from "draft-js";
import Popup from "reactjs-popup";
import CreatableSelect from 'react-select/creatable';

import InputCell from "@/common/form/InputCell";
import moment from '@/common/momentConfig';
import Modal from "@/common/Modal";
import LibraryPicker from "@/routes/library/LibraryPicker";
import { ENCOUNTER_TYPES, roles } from "@/utils/utils";
import EncounterTypePicker from "@/common/EncounterTypePicker";
import { COMPARISON_MAP } from "@/common/RuleComparisonConstants";

const MIN_DELAY_MINUTES = 5;

function ComparisonFieldValue({ onChange, type, qualifier, disabled, fieldName }) {
    const [focused, setFocused] = React.useState(false);
    const [setLoading, setSetLoading] = React.useState(false);
    const [fieldValues, setFieldValues] = React.useState(null);
    const [forceTextForSet, setForceTextForSet] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [timeUnit, setTimeUnit] = React.useState({ value: 'MINUTE', label: 'Minutes', multiplier: 60 });
    const [timeValue, setTimeValue] = React.useState(0);
    const [multiSelectValue, setMultiSelectValue] = React.useState([]);

    const MINUTE_MULTIPLIER = 60;
    const HOUR_MINUTES = 3600;
    const WEEK_MINUTES = 604800;
    const DAY_MINUTES = 86400;

    const timeWindows = [
        { value: 'MINUTE', label: 'Minutes', multiplier: MINUTE_MULTIPLIER },
        { value: 'HOUR', label: 'Hours', multiplier: HOUR_MINUTES },
        { value: 'DAY', label: 'Days', multiplier: DAY_MINUTES },
        { value: 'WEEK', label: 'Weeks', multiplier: WEEK_MINUTES }
    ]

    const onInputChange = (e) => {
        onChange(e.target.value);
    }

    const onTimeValueChange = (e) => {
        setTimeValue(e.target.value);
        onChange(e.target.value * timeUnit.multiplier);
    }

    const onTimeUnitChange = (e) => {
        setTimeUnit(e);
        onChange(timeValue * e.multiplier);
    }

    const onDateChange = (newDate) => {
        onChange(newDate.format());
    }

    const handleChange = (value, actionMeta) => {
        if (value != null) {
            setMultiSelectValue(value);
        } else {
            setMultiSelectValue([]);
        }
    };
    const handleInputChange = (inputValue) => {
        setInputValue(inputValue);
    };
    const handleKeyDown = (event) => {
        if (!inputValue) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                let splitStr = inputValue.split(',');
                let newOpts = []
                splitStr.forEach((str) => newOpts.push(createOption(str.trim())));
                setInputValue('');
                setMultiSelectValue([...multiSelectValue, ...newOpts])
                event.preventDefault();
        }
    };
    const components = {
        DropdownIndicator: null,
    };
    const createOption = (label) => ({
        label,
        value: label,
    });

    const joinValues = () => {
        let valuesOnly = [];
        for (let i = 0; i < multiSelectValue.length; i++) {
            valuesOnly.push(multiSelectValue[i].label);
        }
        qualifier.value = valuesOnly.join(',');
    }

    React.useEffect(() => {
        if (type == "SET" && !setLoading) {
            loadSchemaValues(fieldName);
        } else if (type == "AFTER") {
            setTimeValAndUnit();
        }
    }, [type])

    React.useEffect(() => {
        setTimeValAndUnit();
        if (qualifier.value != null && qualifier.value.length > 0) {
            let str = qualifier.value;
            let res = str.split(",");
            let formatted = [];
            for (let i = 0; i < res.length; i++) {
                formatted.push(createOption(res[i]));
            }
            setMultiSelectValue(formatted);
        }
    }, [qualifier])

    React.useEffect(() => {
        joinValues();
    }, [multiSelectValue])

    const setTimeValAndUnit = () => {
        if (qualifier.value) {
            if (qualifier.value % WEEK_MINUTES == 0) {
                setTimeUnit(_.find(timeWindows, function (tw) { return tw.value == "WEEK"; }));
                setTimeValue(qualifier.value / WEEK_MINUTES)
            }
            else if (qualifier.value % DAY_MINUTES == 0) {
                setTimeUnit(_.find(timeWindows, function (tw) { return tw.value == "DAY"; }));
                setTimeValue(qualifier.value / DAY_MINUTES)
            }
            else if (qualifier.value % HOUR_MINUTES == 0) {
                setTimeUnit(_.find(timeWindows, function (tw) { return tw.value == "HOUR"; }));
                setTimeValue(qualifier.value / HOUR_MINUTES)
            }
            else if (qualifier.value % MINUTE_MULTIPLIER == 0) {
                setTimeUnit(_.find(timeWindows, function (tw) { return tw.value == "MINUTE"; }));
                setTimeValue(qualifier.value / MINUTE_MULTIPLIER)
            }
        }
    }

    const loadSchemaValues = (fieldName) => {
        setFieldValues(null);
        setSetLoading(true);
        API.get('Core', '/api/v1/encounter/valuesSet', {
            queryStringParameters: {
                field: fieldName
            }
        })
            .then(response => {
                setFieldValues(response.uniqueValues);
                setSetLoading(false);
            },
                error => {
                    setSetLoading(false);
                }
            );
    }

    const setSetFieldValue = (obj, action) => {
        switch (action.action) {
            case "select-option":
                onChange(obj.value);
                break;
        }
    }

    let genericInput = (
        <InputCell
            disabled={disabled}
            defaultValue={(qualifier.value ? qualifier.value : "")}
            required
            type={type == "NUMERIC" ? "number" : "text"}
            onChange={onInputChange}
            value={qualifier.value}
        />
    );

    if (qualifier.comparison !== "IN_LIST" && qualifier.comparison !== "NOT_IN_LIST" && qualifier.comparison !== "NOT_IN_LIST_CONTAINS" && qualifier.comparison !== "IN_LIST_CONTAINS") {
        switch (type) {
            case "DATE":
                if (qualifier.comparison === "IS_EMPTY" || qualifier.comparison === "IS_NOT_EMPTY") {
                    return '';
                } else if (qualifier.comparison === "AFTER") {
                    return (
                        <div>
                            <InputCell
                                disabled={disabled}
                                required
                                type={"number"}
                                defaultValue={(timeValue ? timeValue : "0")}
                                onChange={onTimeValueChange}
                                value={timeValue}
                            />
                            <Select className="tableCell selectCell" value={timeUnit} classNamePrefix="selectCell" isDisabled={disabled} onChange={onTimeUnitChange} options={timeWindows} />
                        </div>
                    );
                } else {
                    let exDate = moment()
                    if (qualifier.value && moment(qualifier.value).isValid())
                        exDate = moment(qualifier.value)

                    return (
                        <SingleDatePicker
                            date={exDate}
                            onDateChange={onDateChange}
                            focused={focused}
                            onFocusChange={({ focused }) => setFocused(focused)}
                            id="single_date_picker_rule"
                            isOutsideRange={() => false}
                            disabled={disabled}
                        />
                    );
                }

            case "SET":
                let toggle = (<a className="typeaheadSwitch"
                    onClick={() => setForceTextForSet(!forceTextForSet)}>{forceTextForSet ?
                        <div className="icon suggested" /> : <div className="icon custom" />}</a>);
                if (forceTextForSet) {
                    return (
                        <div className="fieldValueSelect">
                            {genericInput}
                            {toggle}
                        </div>
                    )
                }

                let optStrings = fieldValues ? fieldValues.concat("-") : ["-"];
                let opts = _.map(optStrings, function (o) {
                    return { label: o, value: o }
                });
                let val = qualifier.value ? qualifier.value : "-";
                let selectedVal = { label: val, value: val };
                return (
                    <div className="fieldValueSelect">
                        <Select className="selectCell" classNamePrefix="selectCell"
                            isDisabled={disabled}
                            isLoading={setLoading}
                            onChange={setSetFieldValue.bind(this)}
                            value={selectedVal}
                            options={opts}
                        />
                        {toggle}
                    </div>
                )

            case "NUMERIC":
            case "TEXT":
            default:
                return genericInput;
        }
    } else {
        return (
            <div className="fieldValueSelect expandableField">
                <CreatableSelect
                    className="tableCell selectCell"
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    isDisabled={disabled}
                    menuIsOpen={false}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder=""
                    value={multiSelectValue}
                />
            </div>
        )
    }
}

const EMPTY_VALUE = "-";
const MINUTE_MULTIPLIER = 60;
const HOUR_MINUTES = 3600;
const WEEK_MINUTES = 604800;
const DAY_MINUTES = 86400
const timeWindows = [
    { value: EMPTY_VALUE, label: "Immediate" },
    { value: 'MINUTE', label: 'Minutes', multiplier: MINUTE_MULTIPLIER },
    { value: 'HOUR', label: 'Hours', multiplier: HOUR_MINUTES },
    { value: 'DAY', label: 'Days', multiplier: DAY_MINUTES },
    { value: 'WEEK', label: 'Weeks', multiplier: WEEK_MINUTES }
]

const TYPE_OPTIONS = {
    EMPTY: {
        value: EMPTY_VALUE,
        label: EMPTY_VALUE,
        encounterTypes: ["ALL"]
    },
    SEND_SURVEY: {
        value: "SEND_SURVEY",
        label: "Send Survey",
        encounterTypes: ["ALL"]
    },
    SEND_TEXT: {
        value: "SEND_TEXT",
        label: "Send Text",
        encounterTypes: ["ALL"]
    },
    CREATE_TASK: {
        value: "CREATE_TASK",
        label: "Create Task",
        encounterTypes: ["ALL"]
    },
    UPDATE_DISPOSITION: {
        value: "UPDATE_DISPOSITION",
        label: "Update Disposition",
        encounterTypes: [ENCOUNTER_TYPES.CONVERSATION.label]
    },
    UPDATE_WORKFLOW_STATE: {
        value: "UPDATE_WORKFLOW_STATE",
        label: "Update Workflow State",
        encounterTypes: [ENCOUNTER_TYPES.CONVERSATION.label]
    }
}

function AddEditRuleVersion({ rule, ruleVersion, onUpdate, surveys, taskTypes, numbers, dispositions, workflows }) {
    const [qualifiers, setQualifiers] = React.useState();
    const [targets, setTargets] = React.useState();
    const [multiTrigger, setMultiTrigger] = React.useState(ruleVersion ? ruleVersion.multipleTrigger : false);
    const [matchWindowAmount, setMatchWindowAmount] = React.useState(0);
    const [selectedMatchWindowUnitOption, setSelectedMatchWindowUnitOption] = React.useState(null);
    const [editorState, setEditorState] = React.useState();
    const [libraryOpen, setLibraryOpen] = React.useState();
    const [schema, setSchema] = React.useState([]);
    const [encounterType, setEncounterType] = React.useState(ruleVersion ? ruleVersion.encounterType : "CAD");
    const [typeOptions, setTypeOptions] = React.useState([]);
    const [workflow, setWorkflow] = React.useState(null);

    let domEditor;
    const setDomEditorRef = ref => domEditor = ref;

    function editorContainerClick() {
        domEditor.focus();
    }

    const updateEditorState = (newEditorState) => {
        setEditorState(newEditorState);
        let idx = _.findIndex(targets, (t) => t.type === TYPE_OPTIONS.SEND_TEXT.value)
        if (idx !== -1) {
            targets[idx].message = newEditorState.getCurrentContent().getPlainText();
        }
    }

    const openLibrary = () => {
        setLibraryOpen(true);
    }

    const closeLibrary = () => {
        setLibraryOpen(false);
    }

    const onLibrarySelection = (targetIdx, article) => {
        setLibraryOpen(false);
        targets[targetIdx].libraryItem = article;
        setTargets([].concat(targets));
    }

    const clearArticle = (targetIdx) => {
        targets[targetIdx].libraryItem = null;
        setTargets([].concat(targets));
    }

    const addFilter = () => {
        let editQuals = qualifiers;
        if (!editQuals) {
            editQuals = [];
        }
        setQualifiers(editQuals.concat({
            standardFieldName: null,
            encounterField: null,
            comparison: null,
            value: null
        }));
    }

    const addTarget = () => {
        let editTargets = targets;
        if (!editTargets) {
            editTargets = [];
        }
        setTargets(editTargets.concat({
            queueSend: true // set this property as a way of defaulting to enabled
        }));
    }

    const loadEncounterSchema = (type) => {
        return API.get('Core', '/api/v1/encounter/schema/' + type)
            .then(response => {
                setSchema(Object.assign([], response));
            },
                error => {
                    toast("Could not load schema: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const initiateTarget = (target) => {
        target.delayUnit = _.find(timeWindows, function (tw) { return tw.value === EMPTY_VALUE; })
        if (target.sendDelay) {
            if (target.sendDelay % WEEK_MINUTES === 0) {
                target.delayUnit = _.find(timeWindows, function (tw) { return tw.value === "WEEK"; })
                target.delayValue = target.sendDelay / WEEK_MINUTES;
            }
            else if (target.sendDelay % DAY_MINUTES === 0) {
                target.delayUnit = _.find(timeWindows, function (tw) { return tw.value === "DAY"; })
                target.delayValue = target.sendDelay / DAY_MINUTES;
            }
            else if (target.sendDelay % HOUR_MINUTES === 0) {
                target.delayUnit = _.find(timeWindows, function (tw) { return tw.value === "HOUR"; })
                target.delayValue = target.sendDelay / HOUR_MINUTES;
            }
            else if (target.sendDelay % MINUTE_MULTIPLIER === 0) {
                target.delayUnit = _.find(timeWindows, function (tw) { return tw.value === "MINUTE"; })
                target.delayValue = target.sendDelay / MINUTE_MULTIPLIER;
            }
        }
    }

    React.useEffect(() => {
        if (ruleVersion) {
            setEncounterType(ruleVersion.encounterType);
            setMultiTrigger(ruleVersion.multipleTrigger);
            setMatchWindowFromValueInSeconds(ruleVersion.matchWindowInSeconds);
            if (ruleVersion.targets) {
                _.forEach(ruleVersion.targets, (t) => initiateTarget(t))
                let textTarget = _.find(ruleVersion.targets, (t) => t.type == TYPE_OPTIONS.SEND_TEXT.value);
                let initEditorState = ContentState.createFromText(textTarget && textTarget.message ? textTarget.message : "");
                setEditorState(EditorState.createWithContent(initEditorState, compositeDecorator));
            } else {
                let initEditorState = ContentState.createFromText("");
                setEditorState(EditorState.createWithContent(initEditorState, compositeDecorator));
            }
            setQualifiers(ruleVersion.qualifiers ? ruleVersion.qualifiers : []);
            setTargets(ruleVersion.targets ? ruleVersion.targets : []);
        }

    }, [ruleVersion])

    const handleTypeOptions = (_targets) => {
        let _typeOptions = Object.values(TYPE_OPTIONS).map(typeOption => {
            typeOption.disabled = typeOption.value === TYPE_OPTIONS.EMPTY.value ? false : (_.findIndex(_targets, (t) => t.type == typeOption.value) != -1)
            typeOption.visible = typeOption.encounterTypes && typeOption.encounterTypes.length && (typeOption.encounterTypes.includes("ALL") || typeOption.encounterTypes.includes(encounterType))
            return typeOption
        })

        _typeOptions = _typeOptions.filter(typeOption => typeOption.visible)

        setTypeOptions(_typeOptions)
    }

    React.useEffect(() => {
        loadEncounterSchema(encounterType);
        handleTypeOptions(targets)
    }, [encounterType]);

    React.useEffect(() => {
        handleTypeOptions(targets)
    }, [targets]);

    const setMatchWindowFromValueInSeconds = (matchWindowInSeconds) => {
        let unit = timeWindows.find((option) => option.value == EMPTY_VALUE);
        let amount = 0;
        if (matchWindowInSeconds) {
            if (matchWindowInSeconds % WEEK_MINUTES === 0) {
                unit = timeWindows.find((option) => option.value === "WEEK");
                amount = matchWindowInSeconds / WEEK_MINUTES;
            } else if (matchWindowInSeconds % DAY_MINUTES === 0) {
                unit = timeWindows.find((option) => option.value === "DAY");
                amount = matchWindowInSeconds / DAY_MINUTES;
            } else if (matchWindowInSeconds % HOUR_MINUTES === 0) {
                unit = timeWindows.find((option) => option.value === "HOUR");
                amount = matchWindowInSeconds / HOUR_MINUTES;
            } else if (matchWindowInSeconds % MINUTE_MULTIPLIER === 0) {
                unit = timeWindows.find((option) => option.value === "MINUTE");
                amount = matchWindowInSeconds / MINUTE_MULTIPLIER;
            }
        }

        setMatchWindowAmount(amount);
        setSelectedMatchWindowUnitOption(unit);
    }

    const setQualifierField = (qualifierIdx, obj, action) => {
        switch (action.action) {
            case "select-option":
                let field = obj.data;

                if (field.baseField)
                    qualifiers[qualifierIdx].standardFieldName = field.key;
                else
                    qualifiers[qualifierIdx].encounterField = field;

                qualifiers[qualifierIdx].value = null;
                setQualifiers([].concat(qualifiers));
                break;
        }
    }

    const setQualifierComparator = (qualifierIdx, obj, action) => {        
        switch (action.action) {
            case "select-option":
                qualifiers[qualifierIdx].comparison = obj.value;

                if (["IS_EMPTY", "IS_NOT_EMPTY"].includes(obj.value))
                    qualifiers[qualifierIdx].value = "";

                setQualifiers([].concat(qualifiers));
                break;
        }
    }

    const onValueChange = (qualifierIdx, value) => {        
        qualifiers[qualifierIdx].value = value;
        setQualifiers([].concat(qualifiers));
    }

    const cancelEdit = () => {
        onUpdate("version-edit");
    }

    const deleteRuleVersion = () => {
        API.del('Core', '/api/v1/rule/' + rule.id + '/version/' + ruleVersion.id)
            .then(response => {
                onUpdate("version-delete", response);
            },
                error => {
                    toast("Could not delete version: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const setSendDelay = (target) => {
        if (target.delayUnit && target.delayUnit.value == EMPTY_VALUE) {
            target.sendDelay = null;
        }
        if (target.delayValue && target.delayUnit && target.delayUnit.multiplier) {
            target.sendDelay = target.delayValue * target.delayUnit.multiplier;
        }
    }

    const saveRuleVersion = () => {
        var valid = true
        for (const target of targets) {
            if (target.delayUnit?.value === 'MINUTE' && (target.delayValue || 0) < MIN_DELAY_MINUTES) {
                valid = false
                break
            }
        }

        if (!valid) {
            toast("Delay from Trigger cannot be less than 5 Minutes ", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }

        ruleVersion.qualifiers = qualifiers;
        ruleVersion.targets = targets;
        ruleVersion.encounterType = encounterType;
        ruleVersion.multipleTrigger = multiTrigger;
        ruleVersion.matchWindowInSeconds = null
        if (selectedMatchWindowUnitOption && selectedMatchWindowUnitOption.value !== EMPTY_VALUE) {
            ruleVersion.matchWindowInSeconds = selectedMatchWindowUnitOption ? matchWindowAmount * selectedMatchWindowUnitOption.multiplier : null
        }
        if (ruleVersion.targets) {
            _.forEach(ruleVersion.targets, function (t) { setSendDelay(t); })
        }
        if (ruleVersion.qualifiers) {
            _.forEach(ruleVersion.qualifiers, function (q) {
                if(q.value == null || q.value == ''){
                    const fields = ['encounterDate','callAnswered','callClosed','callDispatched','callReceived']
                    if (fields.includes(q.standardFieldName)){
                        const event = new Date();
                        q.value= event.toISOString();
                    }
                }
            });
        }
        return API.put('Core', '/api/v1/rule/' + rule.id + '/version/' + ruleVersion.id, { body: ruleVersion })
            .then(response => {
                toast("Rule Version Saved!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                onUpdate("version-edit", response);
            },
                error => {
                    toast("Could not update version: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const setActive = async () => {
        await saveRuleVersion();
        rule.activeRuleVersion = ruleVersion;
        API.put('Core', '/api/v1/rule/' + rule.id, { body: rule })
            .then(response => {
                onUpdate("set-active", response);
                toast("Successfully Set Rule Version To Active!", {
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.SUCCESS
                });
            },
                error => {
                    toast("Could not set rule active: " + error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.ERROR
                    });
                }
            );
    }

    const editNewVersion = (ruleVersion) => {
        API.post('Core', '/api/v1/rule/' + rule.id + "/version/" + ruleVersion.id, { body: rule })
            .then(response => {
                onUpdate("new-version", response);
            },
                error => {
                    toast("Could not set rule active: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const removeQualifier = (qualifierIdx) => {
        qualifiers.splice(qualifierIdx, 1);
        setQualifiers([].concat(qualifiers));
    }

    const removeTarget = (targetIdx) => {
        targets.splice(targetIdx, 1);
        setTargets([].concat(targets));
    }

    const setTargetType = (targetIdx, obj, action) => {
        switch (action.action) {
            case "select-option":
                targets[targetIdx].type = obj.value;

                if (obj.value == TYPE_OPTIONS.SEND_TEXT.value)
                    targets[targetIdx].smsConfig = getDoNotReplyNumber();

                setTargets([].concat(targets));
                break;
        }
    }

    const setTargetSurvey = (targetIdx, obj, action) => {
        switch (action.action) {
            case "select-option":
                targets[targetIdx].surveyDefinition = obj.data;
                setTargets([].concat(targets));
                break;
        }
    }

    const setTargetTaskType = (targetIdx, obj, action) => {
        switch (action.action) {
            case "select-option":
                targets[targetIdx].taskType = obj.data;
                setTargets([].concat(targets));
                break;
        }
    }

    const setTargetDisposition = (targetIdx, obj, action) => {
        switch (action.action) {
            case "select-option":
                targets[targetIdx].conversationDisposition = obj.data;
                setTargets([].concat(targets));
                break;
        }
    }

    const setTargetWorkflow = (targetIdx, obj, action) => {
        switch (action.action) {
            case "select-option":
                setWorkflow(obj.data);
                targets[targetIdx].workflowState = null;
                setTargets([].concat(targets));
                break;
        }
    }

    const setTargetWorkflowState = (targetIdx, obj, action) => {
        switch (action.action) {
            case "select-option":
                targets[targetIdx].workflowState = obj.data;
                setTargets([].concat(targets));
                break;
        }
    }

    const toggleTargetReview = (targetIdx) => {
        targets[targetIdx].queueSend = !targets[targetIdx].queueSend;
        setTargets([].concat(targets));
    }

    const setTimeUnit = (targetIdx, obj, action) => {
        switch (action.action) {
            case "select-option":
                targets[targetIdx].delayUnit = obj;
                setTargets([].concat(targets));
                break;
        }
    }

    const setTimeVal = (targetIdx, e) => {
        targets[targetIdx].delayValue = e.target.value;
    }

    const setQuietTimeStartVal = (targetIdx, e) => {
        targets[targetIdx].quietTimeStart = e.target.value;
    }

    const setQuietTimeEndVal = (targetIdx, e) => {
        targets[targetIdx].quietTimeEnd = e.target.value;
    }

    const findPlaceholder = (contentBlock, callback) => {
        const PLACEHOLDER_REGEX = /{{[a-zA-Z]*}}/g;
        const text = contentBlock.getText();
        let matchArr, start;
        while ((matchArr = PLACEHOLDER_REGEX.exec(text)) !== null) {
            start = matchArr.index;
            callback(start, start + matchArr[0].length);
        }
    }

    const VariablePlaceholder = (props) => {
        let varKey = props.decoratedText.replace("{{", "");
        varKey = varKey.replace("}}", "");
        return (
            <span className="variable-placeholder inputVariableTag" {...props}>{props.children}</span>
        )
    }

    const compositeDecorator = new CompositeDecorator([
        {
            strategy: findPlaceholder,
            component: VariablePlaceholder,
        }
    ]);

    const setTargetNumber = (targetIdx, obj, action) => {
        switch (action.action) {
            case "select-option":
                targets[targetIdx].smsConfig = obj;
                setTargets([].concat(targets));
                break;
        }
    }

    const onEncounterTypeChange = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setEncounterType(obj.key);
                break;
        }
    }

    const setTargetRole = (targetIdx, obj, action) => {
        let tr = [];
        if (obj != null) {
            obj.forEach(function (r) {
                tr.push(r.value);
            })
        }
        targets[targetIdx].targetRoles = tr;
        setTargets([].concat(targets));
    }

    const getDoNotReplyNumber = () => {
        return _.find(numbers, function (n) { return n.smsConfigType == "DONOTREPLY"; });
    }

    const addVariableToText = (schema) => {
        const selectionState = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("TOKEN", "IMMUTABLE", { time: new Date().getTime() });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newContentState = Modifier.insertText(
            contentState, selectionState, `{{${schema.key}}}`, null, entityKey
        );
        setEditorState(EditorState.createWithContent(newContentState, compositeDecorator));  //for style: createWithContent(newContentState, compositeDecorator)
    }

    const toggleMultipleTrigger = () => {
        if (!disabled) {
            if (multiTrigger) {
                setMultiTrigger(false);
            } else {
                setMultiTrigger(true);
            }
        }
    }

    const getSchemaOpts = () => {
        return [
            {
                label: "Base Encounter Fields",
                options: _.concat([{ value: EMPTY_VALUE, label: EMPTY_VALUE }], _.sortBy(_.map(_.filter(schema, (sc) => !!sc.base), (sc) => { return { value: sc.key, label: sc.name, data: sc } }), (schemaOpt) => schemaOpt.label))
            },
            {
                label: "Detailed Fields",
                options: _.sortBy(_.map(_.filter(schema, (sc) => !sc.base), (sc) => { return { value: sc.key, label: sc.name, data: sc } }), (schemaOpt) => schemaOpt.label)
            }
        ];
    }

    const onMatchWindowUnitChange = (option) => {
        setSelectedMatchWindowUnitOption(option);
    }

    let disabled = ruleVersion.activated;

    return (
        <div className="scrollView">
            <div className="navBar">
                <div className="title">Versions</div>
                <div className="separator" />
            </div>
            <div className={"editContent tableView"}>
                <div className={"tableSection filters"}>
                    <div className={"sectionTitle"}>Encounter Type</div>
                    <div className={"sectionRow"}>
                        <EncounterTypePicker value={encounterType} onChange={onEncounterTypeChange} disabled={disabled} />
                    </div>
                </div>

                <div className="tableSection filters">
                    <div className="sectionTitle">Filters</div>
                    {
                        _.map(qualifiers, function (q, i) {
                            let selectedSchema = _.find(schema, (sche) => {
                                let found = false;

                                if (sche.baseField) {
                                    found = sche.key == q.standardFieldName
                                } else if (q.encounterField != null) {
                                    found = sche.id == q.encounterField.id;
                                }

                                return found;
                            });
                            let schemaOpts = getSchemaOpts();
                            let schemaKey = selectedSchema ? selectedSchema.key : EMPTY_VALUE;
                            let flatSchemaOpts = _.flatten(_.map(schemaOpts, function (so) { return so.options; }));
                            let schemaVal = _.find(flatSchemaOpts, function (so) { return so.value == schemaKey; })
                            let compOpts = selectedSchema ? _.map(selectedSchema.validOperators, function (vo) { return { value: vo, label: COMPARISON_MAP[vo] } }) : [];
                            let compVal = _.find(compOpts, function (c) { return c.value == q.comparison; });
                            return (
                                <div className="sectionRow" key={i}>
                                    <Select className="tableCell selectCell schemaSelect" classNamePrefix="selectCell" isDisabled={disabled} value={schemaVal} onChange={setQualifierField.bind(this, i)} options={schemaOpts} />
                                    <Select className="tableCell selectCell comparisonSelect" classNamePrefix="selectCell" isDisabled={disabled || !selectedSchema} value={compVal} onChange={setQualifierComparator.bind(this, i)} options={compOpts} />
                                    {
                                        selectedSchema ? (
                                            <ComparisonFieldValue disabled={disabled} value={q.value} onChange={onValueChange.bind(this, i)} qualifier={q} type={selectedSchema.type} fieldName={selectedSchema.key} />
                                        ) : null
                                    }
                                    <div xs={1}>
                                        <div className={"remove"} onClick={removeQualifier.bind(this, i)}>
                                            <div className="icon" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        disabled ? null : (
                            <div onClick={addFilter} className="button tint medium">
                                <div className="title">Add Filter</div>
                            </div>
                        )
                    }
                </div>

                <div className="tableSection target">
                    <div className="sectionTitle">Target</div>
                    {
                        _.map(targets, (t, j) => {
                            let typeKey = t ? t.type : EMPTY_VALUE;
                            let typeVal = _.find(typeOptions, (to) => to.value == typeKey);
                            let body = (
                                <div className="tableCell disabled placeholder">
                                    <div className="title">Select a Target Type</div>
                                </div>
                            );

                            switch (typeKey) {
                                case TYPE_OPTIONS.SEND_SURVEY.value:
                                    let surveyOpts = _.concat([{ value: EMPTY_VALUE, label: EMPTY_VALUE }], _.map(surveys, (sc) => { return { value: sc.id, label: sc.name, data: sc } }));
                                    let surveyKey = t && t.surveyDefinition ? t.surveyDefinition.id : EMPTY_VALUE;
                                    let surveyVal = _.find(surveyOpts, (sv) => sv.value == surveyKey)
                                    body = (
                                        <Select className="selectCell tableCell surveySelect" classNamePrefix="selectCell" isDisabled={disabled}
                                            value={surveyVal} onChange={setTargetSurvey.bind(this, j)} options={surveyOpts}
                                        />
                                    );
                                    break;
                                case TYPE_OPTIONS.CREATE_TASK.value:
                                    let taskOpts = _.concat([{ value: EMPTY_VALUE, label: EMPTY_VALUE }], _.map(taskTypes, (tt) => { return { value: tt.id, label: tt.name, data: tt } }));
                                    let taskKey = t && t.taskType ? t.taskType.id : EMPTY_VALUE;
                                    let taskVal = _.find(taskOpts, (t) => t.value == taskKey)
                                    body = (
                                        <Select className="selectCell tableCell surveySelect" classNamePrefix="selectCell" isDisabled={disabled}
                                            value={taskVal} onChange={setTargetTaskType.bind(this, j)} options={taskOpts}
                                        />
                                    );
                                    break;
                                case TYPE_OPTIONS.SEND_TEXT.value:
                                    const styleMap = {
                                        'STRIKETHROUGH': {
                                            textDecoration: 'line-through',
                                        },
                                    };
                                    let libraryPicker = (
                                        <Modal
                                            button={(
                                                <div style={{ position: 'absolute', right: '15px', bottom: '12px' }} className="selectFromLibrary button small">
                                                    <div className="buttonTitle">Or Select from Library</div>
                                                </div>)}
                                            content={(
                                                <LibraryPicker
                                                    defaultArticleGroupId={t.libraryItem ? t.libraryItem.libraryGroup.id : null}
                                                    defaultArticleId={t.libraryItem ? t.libraryItem.id : null}
                                                    onSelect={onLibrarySelection.bind(this, j)}
                                                    onCancel={closeLibrary} />
                                            )}
                                            title="Select Article from Library"
                                            size="xl"
                                            handleClose={closeLibrary}
                                            handleOpen={openLibrary}
                                            open={!!libraryOpen}
                                        />
                                    )

                                    body = (
                                        <div>
                                            {
                                                // If an article is selected then show contents of article, otherwise show editor
                                                t.libraryItem ? (
                                                    <div className="editorCell tableCell targetTextMessage">
                                                        <Editor
                                                            readOnly={true}
                                                            editorState={EditorState.createWithContent(ContentState.createFromText(t.libraryItem.contents))}
                                                            onChange={() => { }}
                                                            customStyleMap={styleMap}
                                                        />
                                                        <div>
                                                            <div style={{ position: 'absolute', left: '15px', bottom: '12px' }} onClick={clearArticle.bind(this, j)} className="selectFromLibrary button small">
                                                                <div className="buttonTitle">Switch to Ad-Hoc Message</div>
                                                            </div>
                                                            {libraryPicker}
                                                        </div>
                                                    </div>

                                                ) : (
                                                    <div className="editorCell tableCell targetTextMessage" onClick={editorContainerClick}>
                                                        <Editor
                                                            readOnly={disabled}
                                                            editorState={editorState}
                                                            customStyleMap={styleMap}
                                                            onChange={updateEditorState}
                                                            placeholder={"Click and type to build template for text message..."}
                                                            spellCheck={true}
                                                            stripPastedStyles={true}
                                                            ref={setDomEditorRef}
                                                        />
                                                        {
                                                            disabled ? null : (
                                                                <div>
                                                                    <Popup position={"bottom center"} trigger={(
                                                                        <div className="inputAction serviceOffering">
                                                                            <div className="addVariable button small tint">
                                                                                <div className="buttonTitle">Add Variable</div>
                                                                            </div>
                                                                        </div>
                                                                    )}>
                                                                        <div style={{ height: '250px', overflow: 'scroll' }} className={"serviceOffering pullDownMenu"}>
                                                                            {
                                                                                _.map(_.sortBy(schema, (sche) => !!sche.name), (s, k) => {
                                                                                    return (
                                                                                        <div key={k} onClick={addVariableToText.bind(this, s)} className={"cell detailed hasIcon"}>
                                                                                            <div className="cellBody" style={{ paddingLeft: "10px" }}>
                                                                                                <div className="title serviceName">{s.name}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </Popup>
                                                                    <div>
                                                                        {libraryPicker}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                            <div className="tableCell titledSelectCell targetResponseGroup">
                                                <div className="title">Communication Group</div>
                                                <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                                    isDisabled={disabled}
                                                    onChange={setTargetNumber.bind(this, j)}
                                                    value={t.smsConfig ? t.smsConfig : getDoNotReplyNumber()}
                                                    options={numbers}
                                                    getOptionLabel={option => option.name}
                                                    getOptionValue={option => option.id}
                                                />
                                            </div>
                                        </div>
                                    )
                                    break;
                                case TYPE_OPTIONS.UPDATE_DISPOSITION.value:
                                    let dispositionsOpts = _.concat([{ value: EMPTY_VALUE, label: EMPTY_VALUE }], _.map(dispositions, (d) => { return { value: d.id, label: d.name, data: d } }));
                                    let dispositionKey = t && t.conversationDisposition ? t.conversationDisposition.id : EMPTY_VALUE;
                                    let dipositionVal = _.find(dispositionsOpts, (d) => d.value == dispositionKey)
                                    body = (
                                        <Select className="selectCell tableCell surveySelect" classNamePrefix="selectCell" isDisabled={disabled}
                                            value={dipositionVal} onChange={setTargetDisposition.bind(this, j)} options={dispositionsOpts}
                                        />
                                    );
                                    break;
                                case TYPE_OPTIONS.UPDATE_WORKFLOW_STATE.value:

                                    let workflowsOpts = _.concat([{ value: EMPTY_VALUE, label: EMPTY_VALUE }], _.map(workflows, (w) => { return { value: w.id, label: w.name, data: w } }));

                                    let workflowStates = workflow && workflows.find(w => w.id === workflow.id) ? workflows.find(w => w.id === workflow.id).states : []
                                    let workflowsStatesOpts = _.concat([{ value: EMPTY_VALUE, label: EMPTY_VALUE }], _.map(workflowStates, (ws) => { return { value: ws.id, label: ws.name, data: ws } }));
                                    let workflowStateKey = EMPTY_VALUE;

                                    if (t && t.workflowState && t.workflowState.id) {
                                        workflowStateKey = t.workflowState.id;
                                        if (!workflow && workflows && workflows.length) {
                                            let selectedWorkflow = workflows.filter(w => w.states.find(ws => ws.id === t.workflowState.id))
                                            if (selectedWorkflow && selectedWorkflow.length)
                                                setWorkflow(selectedWorkflow[0])
                                        }
                                    }
                                    let workflowStateVal = _.find(workflowsStatesOpts, (ws) => ws.value == workflowStateKey)

                                    body = (
                                        <div>
                                            <div className="tableCell titledSelectCell targetResponseGroup">
                                                <div className="title">Workflow</div>
                                                <Select className="selectCell tableCell surveySelect" classNamePrefix="selectCell" isDisabled={disabled}
                                                    value={workflow && workflow !== EMPTY_VALUE ? { value: workflow.id, label: workflow.name, data: workflow } : null} onChange={setTargetWorkflow.bind(this, j)} options={workflowsOpts}
                                                />
                                            </div>
                                            {workflow &&
                                                <div className="tableCell titledSelectCell targetResponseGroup">
                                                    <div className="title">State</div>
                                                    <Select className="selectCell tableCell surveySelect" classNamePrefix="selectCell" isDisabled={disabled}
                                                        value={workflowStateVal} onChange={setTargetWorkflowState.bind(this, j)} options={workflowsStatesOpts}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    );
                                    break;
                            }

                            return (
                                <div className={"targetListItem"} key={j}>
                                    <div className={"targetContainer targetCell"}>
                                        <div className="targetBody">
                                            {body}
                                            {
                                                typeVal ? (
                                                    <div>
                                                        <div className={`tableCell titledSelectCell delayFromTrigger`}>
                                                            <div className="title">Delay from Trigger</div>
                                                            {
                                                                t.delayUnit && t.delayUnit.value == EMPTY_VALUE ? null :
                                                                    <InputCell
                                                                        disabled={disabled}
                                                                        required
                                                                        type={"number"}
                                                                        defaultValue={t.delayValue}
                                                                        onChange={setTimeVal.bind(this, j)}
                                                                        value={t.delayValue}
                                                                    />
                                                            }
                                                            <Select className="tableCell selectCell" value={t.delayUnit} classNamePrefix="selectCell" isDisabled={disabled} onChange={setTimeUnit.bind(this, j)} options={timeWindows} />
                                                        </div>
                                                        <div onClick={toggleTargetReview.bind(this, j)} className={`tableCell switchCell ${t.queueSend ? 'switchOn' : 'switchOff'}`}>
                                                            <div className="title">Require Review</div>
                                                            <div className={`accessory accessorySwitch ${t.queueSend ? 'on' : 'off'}`}>
                                                                <div className="switchThumb" />
                                                            </div>
                                                        </div>
                                                        <div className="tableCell titledSelectCell targetResponseGroup">
                                                            <div className="title">Target Role</div>
                                                            <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                                                isDisabled={disabled}
                                                                isMulti
                                                                onChange={setTargetRole.bind(this, j)}
                                                                value={t.targetRoles != null && t.targetRoles.length > 0 ? t.targetRoles.map(function (o) { return { label: o, value: o } }) : ''}
                                                                options={roles}
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            />
                                                        </div>
                                                        <div className={`tableCell titledSelectCell delayFromTrigger`}>
                                                            <div className="title">Quiet Hours: </div>
                                                            <InputCell
                                                                disabled={disabled}
                                                                type={"time"}
                                                                defaultValue={t.quietTimeStart != null ? t.quietTimeStart : "--:--"}
                                                                onChange={setQuietTimeStartVal.bind(this, j)}
                                                                value={t.quietTimeStart}
                                                                placeholder={"--:--"}
                                                            />
                                                            <div className="title"> to: </div>
                                                            <InputCell
                                                                disabled={disabled}
                                                                type={"time"}
                                                                defaultValue={t.quietTimeEnd != null ? t.quietTimeEnd : "--:--"}
                                                                onChange={setQuietTimeEndVal.bind(this, j)}
                                                                value={t.quietTimeEnd}
                                                                placeholder={"--:--"}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>

                                        <Select isOptionDisabled={(option) => option.disabled} className="targetType tableCell selectCell targetSelect" classNamePrefix="selectCell" isDisabled={disabled} value={typeVal} onChange={setTargetType.bind(this, j)} options={typeOptions}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                    </div>
                                    <div className={"removeTarget"} onClick={removeTarget.bind(this, j)}>
                                        <div className="icon" />
                                    </div>
                                </div>

                            )
                        })
                    }
                    {
                        disabled ? null : (
                            <div onClick={addTarget} className="button tint medium">
                                <div className="title">Add Target</div>
                            </div>
                        )
                    }
                </div>

                <div onClick={toggleMultipleTrigger} className={`tableCell switchCell ${multiTrigger ? 'switchOn' : 'switchOff'}`}>
                    <div className="title">Allow Multiple Triggers on Encounter Update</div>
                    <div className={`accessory accessorySwitch ${multiTrigger ? 'on' : 'off'}`}>
                        <div className="switchThumb" />
                    </div>
                </div>

                <div className={`tableCell titledSelectCell matchwindowRule`}>
                    <div className="title">Match Window </div>

                    {
                        selectedMatchWindowUnitOption && selectedMatchWindowUnitOption.value === EMPTY_VALUE ? null :
                            <InputCell
                                disabled={disabled}
                                required
                                type={"number"}
                                defaultValue={(matchWindowAmount ? matchWindowAmount : "0")}
                                onChange={(e) => setMatchWindowAmount(e.target.value)}
                                value={matchWindowAmount} />
                    }

                    <Select className="tableCell selectCell" value={selectedMatchWindowUnitOption} classNamePrefix="selectCell"
                        isDisabled={disabled}
                        onChange={onMatchWindowUnitChange} options={timeWindows} />
                </div>


            </div>
            <div className="actionBar">
                <div className="actions versioning left">
                    {
                        (rule.activeRuleVersion && rule.activeRuleVersion.id == ruleVersion.id) ?
                            (
                                <div className={`button tint medium`}>
                                    <div className="title">Active</div>
                                </div>
                            ) :
                            (
                                <div onClick={setActive} className={`button tint medium`}>
                                    <div className="title">Set as Active</div>
                                </div>
                            )
                    }
                    {
                        (!ruleVersion.activated) ?
                            (
                                <div onClick={deleteRuleVersion} className="button destructive medium">
                                    <div className="title">Delete</div>
                                </div>
                            ) : null
                    }
                </div>


                {
                    !ruleVersion.activated ? (
                        <div className="actions edit right">
                            <div onClick={cancelEdit} className="button dismiss medium">
                                <div className="title">Cancel</div>
                            </div>
                            <div onClick={saveRuleVersion} className={`button confirm medium`}>
                                <div className="title">Save</div>
                            </div>
                        </div>
                    ) : (
                        <div className="actions edit right">
                            <div onClick={editNewVersion.bind(this, ruleVersion)} className={`button plain medium`}>
                                <div className="title">Edit as a New Version</div>
                            </div>
                        </div>
                    )
                }
                <div className="separator"></div>
            </div>
        </div>
    )
}

export default AddEditRuleVersion;