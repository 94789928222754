import {useEffect} from "react";
import {API, Auth, Cache} from "aws-amplify";
import {FEDERATED_PATH} from "../common/CacheKeys";

export default function VerifyCognitoSignIn({userName}) {
    const handleLogout = () => {
        let path = Cache.getItem(FEDERATED_PATH);
        Cache.clear();
        localStorage.setItem("logout_message", "Please login with your PowerDMS SSO account.");
        Auth.signOut()
            .then(data => {
                if (path) {
                    window.location = "/" + path + "/auth"
                } else {
                    window.location = "/auth";
                }
            })
            .catch(err => console.error("Logout failed:", err));
    }

    useEffect(() => {
        const verifyUser = async () => {
            if (!userName) {
                return;
            }
            try {
                const encodedUsername = encodeURIComponent(userName);
                const resp = await API.get("Unauthorized", `/u/${encodedUsername}/whoami`);
                if (resp?.F === "P") {
                    handleLogout();
                }
            } catch (error) {
                console.error("Verification failed:", error);
            }
        };
        verifyUser();
    }, []);

    return null;
}