import React, { useCallback } from 'react';
import { API, Auth } from "aws-amplify";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Video from 'twilio-video';

import { fetchUserMe } from "@/shared/user/actions";
import {isNewVideoCallEnabled} from "@/shared/access";

import Room from './Room';

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getUserMe: () => dispatch(fetchUserMe())
})

const VideoChat = (props) => {
    const { roomId, tokenId } = props.match.params;
    const [videoAuthToken, setVideoAuthToken] = React.useState(null);
    const [me, setMe] = React.useState(null);
    const [participantInfo, setParticipantInfo] = React.useState(null);
    const [aboutMeLoaded, setAboutMeLoaded] = React.useState(false);
    const [videoRecommendedLengthMinutes, setVideoRecommendedLengthMinutes] = React.useState(0);
    const [roomInfo, setRoomInfo] = React.useState({});
    const [conversation, setConversation] = React.useState(false);
    const [mediaRequested, setMediaRequested] = React.useState(false);
    const [audioInputFound, setAudioInputFound] = React.useState(false);
    const [audioOutputFound, setAudioOutputFound] = React.useState(false);
    const [videoFound, setVideoFound] = React.useState(false);
    const [lastError, setLastError] = React.useState("");

    if (!aboutMeLoaded && !tokenId) {
        props.getUserMe();
        setAboutMeLoaded(true);
    }

    if (!mediaRequested) {
        setMediaRequested(true);
        navigator.mediaDevices.enumerateDevices().then(function (mediaDevices) {
            mediaDevices.forEach(mediaDevice => {
                if (mediaDevice.kind === "videoinput") {
                    setVideoFound(true);
                }
                else if (mediaDevice.kind === "audioinput") {
                    setAudioInputFound(true);
                }
                else if (mediaDevice.kind === "audiooutput") {
                    setAudioOutputFound(true);
                }
            })
        })
    }

    const handleSubmit = useCallback(async event => {
        event.preventDefault();
        let headers = {};
        let queryStringParameters = {};

        if (!tokenId) {
            let params = new URLSearchParams(document.location.search.substring(1));
            let access_token = params.get('access_token');
            let token = access_token ? access_token : (await Auth.currentSession()).accessToken.jwtToken;
            headers = {
                Authorization: `Bearer ${token}`
            };
        } else {
            queryStringParameters = {
                token: tokenId
            };
        }

        const data = await API.get('Unauthorized', '/u/video/room/' + roomId, {
            queryStringParameters: queryStringParameters,
            headers: headers
        }).catch((error) => {
            if (error && error.response && error.response.data && error.response.data.error) {
                setLastError(error.response.data.error);
            }
            else {
                setLastError(JSON.stringify(error));
            }
            return;
        })

        if (!data) {
            return;
        }

        setMe(data.me);
        setParticipantInfo(data.participants);
        setConversation(data.conversation);
        setVideoRecommendedLengthMinutes(data.videoRecommendedLengthMinutes);
        setRoomInfo(data.room);
        setVideoAuthToken(data.token);
    }, [roomId, tokenId]);

    const handleLogout = event => {
        setVideoAuthToken(null);
    };

    let render;
    if (videoAuthToken) {
        if(isNewVideoCallEnabled()) {
            Auth.currentSession()
                .then(s => {
                    const URL = `${window.__RUNTIME_CONFIG__.VIDEO_APP_HOST}/room-id/${roomId}/token/${videoAuthToken}?access_token=${s.accessToken.jwtToken}`
                    window.location.href = URL;
                })
        }else  {
            render = (
                <Room roomName={roomId}
                      token={videoAuthToken}
                      handleLogout={handleLogout}
                      me={me}
                      roomInfo={roomInfo}
                      videoRecommendedLengthMinutes={videoRecommendedLengthMinutes}
                      participantInfo={participantInfo}
                      conversation={conversation}
                />
            );
        }
    }
    else if (!Video.isSupported) {
        render = (
            <div className="videoJoinContainer">
                <div className="videoError" >
                    Your browser is not supported
                </div>
            </div>
        );
    }
    else {
        render = (
            <div className="videoJoinContainer">
                {
                    (videoFound && audioInputFound) ?
                        (
                            <div className="videoJoin" onClick={handleSubmit}>
                                <div className="title">Join</div>
                            </div>
                        ) :
                        (<div>Awaiting Audio and Video Inputs</div>)
                }

                <div style={{ flexBasis: '100%', height: '0px' }}></div>
                <div className="videoJoinDevices">
                    Devices:
                    <ul>
                        <li>Video: {(videoFound ? "Supported" : "Not Found")}</li>
                        <li>Audio Input: {(audioInputFound ? "Supported" : "Not Found")}</li>
                        <li>Audio Output: {(audioOutputFound ? "Supported" : "Not Found")}</li>
                        {
                            lastError ? (
                                <li>Last Error: {lastError}</li>
                            ) : null
                        }
                    </ul>
                </div>
            </div>
        );
    }
    return render;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoChat));
