import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';

import { fetchEmailSetting, fetchSMSSetting } from "@/shared/connect_config/actions";
import { canAdmin } from "@/shared/access";
import { fetchGetStartedSteps } from '@/shared/getstarted/actions';
import { useQuery } from '@/utils/utils';

import SetupUsers from './SetupUsers';
import SetupConnect from './SetupConnect';

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getSteps: () => dispatch(fetchGetStartedSteps()),
    requestSMSSetting: () => dispatch(fetchSMSSetting()),
    getEmailSettings: () => dispatch(fetchEmailSetting())
})

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    panelHeading: {
        fontSize: theme.typography.pxToRem(20),
        marginLeft: '20px'
    },
    panelIcon: {
        fontSize: '2em'
    },
    container: {
        maxWidth: '1500px',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    progressContainer: {
        width: '100%',
        position: 'absolute',
        bottom: '-17px',
        left: '0px'
    },
    panel: {
        border: theme.palette.primary[50] + ' solid 1px',
        '&:hover': {
            border: theme.palette.secondary.main + ' solid 1px'
        },
        margin: theme.spacing(2, 0, 2, 0),
        padding: theme.spacing(2, 0, 2, 0),
        position: 'relative'
    },
    divider: {
        width: '100%'
    }
}));

const StepGroups = {
    "USERS": [
        "INVITE_USER",
        //"CUSTOM_FIELDS",
        // "IMPORT_CLIENTS",
        // "IMPORT_PATIENTS"
    ],
    "TREAT": [
        "TREAT_TEXT",
        "TREAT_EMAIL",
        // "SERVICE_OFFERINGS"
    ]
};

function SetupPanel(props) {
    const { icon, title, progress, content, active, progressString } = props;
    const [open, setOpen] = React.useState(false);


    useEffect(() => {
        setOpen(active);
    }, [active]);

    const setPanelOpen = (e) => {
        setOpen(!open);
    }

    const classes = useStyles();
    return (
        <Accordion className={classes.panel + " cell"} expanded={open} onChange={setPanelOpen}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {icon}<Typography className={classes.panelHeading}>{title}</Typography><Typography style={{ position: "absolute", right: "60px" }} color={"textSecondary"}>{progressString}</Typography>
                <div className={classes.progressContainer}>
                    <LinearProgress variant="determinate" value={progress} color="secondary" />
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {content}
            </AccordionDetails>
        </Accordion>
    )
}

function panelContainsStep(panel, step) {
    let idx = _.findIndex(StepGroups[panel], function (o) {
        return o === step;
    });
    return idx >= 0;
}

function GettingStarted(props) {
    let query = useQuery();
    const [loadActionsCalled, setLoadActionsCalled] = React.useState(false);
    const [currentStep, setCurrentStep] = React.useState(query.get("step"))
    if (!loadActionsCalled) {
        props.getSteps();
        props.getEmailSettings();
        props.requestSMSSetting();
        setLoadActionsCalled(true);
    }

    const calculateComplete = (group) => {
        if (!StepGroups[group]) {
            return 0;
        }
        const total = StepGroups[group].length;
        let complete = 0;
        _.forEach(StepGroups[group], function (k) {
            if (props.getstarted.steps[k] && props.getstarted.steps[k].completed) {
                complete += 1;
            }
        });
        return (complete / total) * 100;
    }

    const calculateCompleteString = (group) => {
        if (!StepGroups[group]) {
            return "Not Started";
        }
        const total = StepGroups[group].length;
        let complete = 0;
        _.forEach(StepGroups[group], function (k) {
            if (props.getstarted.steps[k] && props.getstarted.steps[k].completed) {
                complete += 1;
            }
        });
        if (complete === total) {
            return "Complete";
        }
        return complete + " out of " + total + " steps";
    }


    const onStepChange = (step) => {
        props.history.push(props.location.pathname + "?step=" + step);
    }

    const setStep = (step) => {
        props.history.push(props.location.pathname + "?step=" + step);
        setCurrentStep(step);
    }

    let inboxName = _.get(props, "connectConfig.email.inboxName");
    let inboxDomain = _.get(props, "connectConfig.email.domain");
    let numbers = _.get(props, "connectConfig.sms");

    let usersActive = panelContainsStep("USERS", currentStep);
    let treatActive = panelContainsStep("TREAT", currentStep);

    return (
        <div className="onboardingView">
            <div className="navBar">
                <div className="title">Get Started</div>
                <div className="separator" />
            </div>

            <div className="contentOptions">
                <div className="contactDetails">
                    <div className="header">
                        <div className="title">Your Organization Contact Details</div>
                        <div className="subtitle">Share your Organization contact details with your community / field personnel. When they reach out, all the text messages and emails will be organized under Conversations.</div>
                    </div>

                    <div className="tableView">
                        <div className="tableSection">
                            {
                                (numbers) ?
                                    (
                                        _.map(numbers, function (number, numerIdx) {
                                            return (
                                                <div className="cell" key={numerIdx}>
                                                    <div className="title">{number.name} Number</div>
                                                    <div className="detail">{(number ? (<CurrencyFormat displayType={"text"} value={number.phoneNumber} format="+# (###) ###-####" mask="_" />) : "")}</div>
                                                </div>
                                            )
                                        })
                                    )
                                    : (
                                        <div className={"cell"}>
                                            <div className="title">Number</div>
                                            <div onClick={setStep.bind(this, "TREAT_TEXT")} className={"button medium tint"}>
                                                <div className={"title"}>Claim Number</div>
                                            </div>
                                        </div>
                                    )
                            }
                            {
                                (inboxDomain && inboxName) ? (
                                    <div className="cell">
                                        <div className="title">Email</div>
                                        <div className="detail">{inboxName}@{inboxDomain}</div>
                                    </div>
                                ) : (canAdmin() ? (
                                    <div className={"cell"}>
                                        <div className="title">Email</div>
                                        <div onClick={setStep.bind(this, "TREAT_EMAIL")} className={"button medium tint"}>
                                            <div className={"title"}>Claim Inbox</div>
                                        </div>
                                    </div>
                                ) : null
                                )
                            }

                        </div>
                    </div>
                </div>

                <div className="tableView wizardPanels">
                    <div className="tableSection">
                        {
                            canAdmin() ? (
                                <SetupPanel
                                    title={"Add Teammates"}
                                    active={usersActive}
                                    progress={calculateComplete("USERS")}
                                    progressString={calculateCompleteString("USERS")}
                                    content={(<SetupUsers currentStep={currentStep} stepStates={props.getstarted.steps} onStepChange={onStepChange} />)}
                                />
                            ) : null
                        }
                        {
                            canAdmin() ? (
                                <SetupPanel
                                    title={"Contact Details"}
                                    active={treatActive}
                                    progress={calculateComplete("TREAT")}
                                    progressString={calculateCompleteString("TREAT")}
                                    content={(<SetupConnect currentStep={currentStep} stepStates={props.getstarted.steps} onStepChange={onStepChange} />)}
                                />
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GettingStarted));
