import React from 'react';
import InputCell from "../../../common/form/InputCell";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import Select from "react-select";
import DepartmentSelect from "../../../components/DepartmentSelect";
import _ from "lodash";

export default function AddEditRecordConnector({onCancel, onSuccess, existingConnector, departments}){

    const nameRef = React.createRef();
    const hostRef = React.createRef();
    const marketIdRef = React.createRef();
    const subdomainRef = React.createRef();
    const apiKeyRef = React.createRef();
    const usernameRef = React.createRef();
    const portRef = React.createRef();
    const ppkRef = React.createRef();
    const directoryRef = React.createRef();
    const [connectorType, setConnectorType] = React.useState(existingConnector ? existingConnector.connector : "ATHENA_HEALTH");
    const [departmentId, setDepartmentId] = React.useState(existingConnector ? existingConnector.departmentId : null);

    const connectorTypesOptions = [
        {value: "ATHENA_HEALTH", label: "Athena Health"},
        {value: "TELE911_PAYER_PORTAL", label: "Tele911 Payer Portal"},
        {value: "SAFETY_PAD", label: "ESO - SafetyPad"},
        {value: "IMAGE_TREND", label: "ImageTrend"},
        {value: "ESO_NEMSIS", label: "ESO - Nemsis"},
        {value: "ECLINICAL_WORKS", label: "EClinical Works"}
    ];

    const onConnectorTypeChange = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setConnectorType(obj.value);
                break;
        }
    }

    const saveDepartment = () => {
        let init = {
            body: {
                name: nameRef.current.value,
                connector: (existingConnector ? existingConnector.connector : connectorType),
                departmentId: departmentId,
                host: hostRef.current ? hostRef.current.value : null,
                marketId: marketIdRef.current ? marketIdRef.current.value : null,
                subdomain: subdomainRef.current ? subdomainRef.current.value : null,
                apiKey: apiKeyRef.current ? apiKeyRef.current.value : null,
                username: usernameRef.current ? usernameRef.current.value : null,
                port: portRef.current ? portRef.current.value: null,
                ppk: ppkRef.current ? ppkRef.current.value : null,
                directory: directoryRef.current ? directoryRef.current.value : null
            }
        };
        if(existingConnector){
            API.put('Core', '/api/v1/records/connectors/configured/' + existingConnector.id, init)
                .then(response => {
                        toast.success("Connector Updated!", {position: toast.POSITION.TOP_CENTER});
                        onSuccess(response);
                    },
                    error => {
                        toast.error("Could not update connector: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                    })
        }
        else{
            API.post('Core', '/api/v1/records/connectors/configured', init)
                .then(response => {
                        toast.success("Conector Created!", {position: toast.POSITION.TOP_CENTER});
                        onSuccess(response);
                    },
                    error => {
                        toast.error("Could not create connector: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                    }
                );
        }
    }

    const getConnectorFields = () => {
        switch (connectorType){
            case "ATHENA_HEALTH":
                break;
            case "ECLINICAL_WORKS":
                return (
                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">ECW Connection</div>
                        </div>
                        <InputCell
                            placeholder={""}
                            defaultValue={existingConnector ? existingConnector.host : ""}
                            required
                            id="host"
                            label="Host"
                            inputRef={hostRef}
                        />
                        <InputCell
                            placeholder={""}
                            defaultValue={existingConnector ? existingConnector.username : ""}
                            required
                            id="username"
                            label="Username"
                            inputRef={usernameRef}
                        />
                        <InputCell
                            placeholder={""}
                            defaultValue={existingConnector ? existingConnector.port : ""}
                            type={"number"}
                            required
                            id="port"
                            label="Port"
                            inputRef={portRef}
                        />
                        <InputCell
                            placeholder={""}
                            defaultValue={existingConnector ? existingConnector.directory : ""}
                            required
                            id="directory"
                            label="Directory"
                            inputRef={directoryRef}
                        />
                        <div className={`cell inputCell`}>
                            <div className="title">PPK</div>
                            <textarea ref={ppkRef}>{existingConnector ? existingConnector.ppk : ""}</textarea>
                        </div>
                    </div>
                )
                break;
            case "SAFETY_PAD":
                return (
                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">ESO - SafetyPad Connection</div>
                        </div>
                        <InputCell
                            placeholder={""}
                            defaultValue={existingConnector ? existingConnector.subdomain : ""}
                            required
                            id="subdomain"
                            label="Subdomain"
                            inputRef={subdomainRef}
                        />
                        <InputCell
                            placeholder={""}
                            defaultValue={existingConnector ? existingConnector.apiKey : ""}
                            required
                            id="apiKey"
                            label="API Key"
                            inputRef={apiKeyRef}
                        />
                    </div>
                )
                break;
            case "TELE911_PAYER_PORTAL":
                return (
                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Tele911 Payer Portal Connection</div>
                        </div>
                        <InputCell
                            placeholder={""}
                            defaultValue={existingConnector ? existingConnector.host : ""}
                            required
                            id="host"
                            type="url"
                            label="Host"
                            inputRef={hostRef}
                        />
                        <InputCell
                            placeholder={""}
                            defaultValue={existingConnector ? existingConnector.marketId : ""}
                            required
                            id="market-id"
                            type="number"
                            label="Market ID"
                            inputRef={marketIdRef}
                        />
                    </div>
                )
                break;
        }
        return null;
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Connector Type</div>
                        </div>
                        <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                onChange={onConnectorTypeChange}
                                value={connectorType ? _.find(connectorTypesOptions, function(cto){ return cto.value == connectorType;}) : "--"}
                                options={connectorTypesOptions}
                                isDisabled={existingConnector}
                        />
                    </div>
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={""}
                            defaultValue={existingConnector ? existingConnector.name : ""}
                            required
                            id="name"
                            label="Connector Name"
                            inputRef={nameRef}
                        />
                    </div>
                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Department</div>
                        </div>
                        <DepartmentSelect onSelect={(dep) => {
                            if(!dep){
                                setDepartmentId(null);
                                return;
                            }
                            setDepartmentId(dep.id)
                        }} value={_.find(departments, function(d){ return d.id == departmentId;})}/>
                    </div>
                    {getConnectorFields()}
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveDepartment} className={`button confirm medium`}>
                        <div className="title">Save</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>

    )
}