import React from "react";
import { withRouter, useParams } from "react-router";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import _ from "lodash";

import { Button } from "@/components/Buttons";
import { fetchAllTaskTypes } from "@/shared/tasks/actions";
import Modal from "@/common/Modal";
import ConfirmModal from "../../common/ConfirmModal";

import EditConsortium from "./EditConsortium";
import EditDataAccessRule from "./EditDataAccessRule";
import AddEditTenantMember from "./AddEditTenantMember";
import AddShareRule from "./AddShareRule";

const mapStateToProps = state => ({
    tasks: state.tasks,
})

const mapDispatchToProps = dispatch => ({
    getTaskTypes: (tenantsIds) => dispatch(fetchAllTaskTypes(tenantsIds)),
})

function SupportSingleConsortium(props) {
    const { consortiumId } = useParams();
    const [consortium, setConsortium] = React.useState(null);
    const [editOpen, setEditOpen] = React.useState(false);
    const [addTenantOpen, setAddTenantOpen] = React.useState(false);
    const [addRuleOpen, setAddRuleOpen] = React.useState(false);
    const [editDataAccessRuleOpen, setEditDataAccessRuleOpen] = React.useState(false);
    const [editConsortiumMemberOpen, setEditConsortiumMemberOpen] = React.useState(false);
    const [editMember, setEditMember] = React.useState(null);
    const [ruleToEdit, setRuleToEdit] = React.useState(null);

    const loadConsortium = () => {
        API.get('Core', '/api/v1/consortium/' + consortiumId)
            .then(response => {
                setConsortium(Object.assign({}, response));
                console.log(response)
                loadTaskTypes(response);
            },
                error => {
                    toast("Could not load consortium: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const updateConsortium = (updateConsortium) => {
        API.put('Core', '/api/v1/consortium/' + consortium.id, { body: updateConsortium })
            .then(response => {
                toast("Successfully updated consortium!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                loadConsortium();
            },
                error => {
                    toast("Could not save consortium: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const loadTaskTypes = (consortiumObj) => {
        let tenantsIds = consortiumObj && consortiumObj.members ? consortiumObj.members.map(m => m.tenant.id) : null;
        props.getTaskTypes(tenantsIds);
    }

    React.useEffect(() => {
        loadConsortium(consortiumId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onEditSuccess = () => {
        setEditOpen(false);
        loadConsortium(consortiumId);
    }
    const addTenant = (member) => {
        let existingMember = _.find(consortium.members, function (m) { return m.tenant.id === member.tenant.id });
        if (existingMember) {
            toast("Member already exists in Consortium!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }
        consortium.members.push(member);
        updateConsortium(consortium);
        setAddTenantOpen(false);
    }

    const updateTenant = () => {
        updateConsortium(consortium);
        setEditMember(null);
    }


    const addShareRule = (rule) => {
        let existingRule = _.find(consortium.dataAccessRules, function (r) {
            return r.sourceTenant.id === rule.sourceTenant.id
                && r.dataSource.id === rule.dataSource.id
                && r.grantedTenant.id === rule.grantedTenant.id
                && r.agencyFilter === rule.agencyFilter
                && r.jurisdictionFilter === rule.jurisdictionFilter
        });
        if (existingRule) {
            toast("Rule already exists in Consortium!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }
        consortium.dataAccessRules.push(rule);
        updateConsortium(consortium);
        setAddRuleOpen(false);
    }

    const editShareRule = (rule) => {
        let existingRuleIndex = _.findIndex(consortium.dataAccessRules, (r) => r.id === rule.id);
        if(existingRuleIndex !== -1){
            consortium.dataAccessRules[existingRuleIndex] = rule;
        }

        updateConsortium(consortium);
        setEditDataAccessRuleOpen(false);
    }

    const removeMember = (member) => {
        _.remove(consortium.members, function (m) { return m.id === member.id })
        updateConsortium(consortium);
    }

    const removeRule = (rule) => {
        _.remove(consortium.dataAccessRules, function (r) { return r.id === rule.id })
        updateConsortium(consortium);
    }

    console.log(consortium);
    return (
        consortium === null ? <span>Loading...</span> :
        <div className="rootView surveysView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="title">CONSORTIUM: {consortium.name}</div>
                </div>
                <div className="actions">
                    <div className="">
                        <Modal
                            button={(<div className="button medium action">
                                <div className="buttonTitle">Edit Consortium</div>
                            </div>)}
                            content={(<EditConsortium consortium={consortium} onSuccess={onEditSuccess} onCancel={() => setEditOpen(false)} />)}
                            title="Edit Consortium"
                            size="large"
                            handleClose={() => setEditOpen(false)}
                            handleOpen={() => setEditOpen(true)}
                            open={editOpen}
                        />
                    </div>
                </div>
                <div className="separator"></div>
            </div>
            <div className="scrollView">
                <div className="tableView">
                    <div className="tableSection">
                        <div className="sectionTitle">Member Tenants</div>
                        <Modal
                            button={(
                                <div style={{ position: 'absolute', right: '20px' }}>
                                    <Button
                                        title="Add Member"
                                        icon="none"
                                        size="medium"
                                    />
                                </div>)}
                            content={(<AddEditTenantMember
                                onAdd={addTenant}
                                onCancel={() => setAddTenantOpen(false)}
                                taskTypes={props.tasks.taskTypes}
                            />)}
                            title="Add Member"
                            size="large"
                            handleClose={() => setAddTenantOpen(false)}
                            handleOpen={() => setAddTenantOpen(true)}
                            open={addTenantOpen}
                        />
                        {
                            _.map(_.sortBy(consortium.members, function (m) {
                                return m.tenant.name;
                            }), function (member) {
                                return (
                                    <div key={member.tenant.id} className="tableCell" style={{ height: 'auto' }}>
                                        <div className="cellBody">
                                            <div className="title groupName">{member.tenant.name.length > 28 ? member.tenant.name.substring(0, 28) + '...' : member.tenant.name}</div>
                                            <div className="subtitle">Data Owner Subject Filter: <b>{member.dataOwnerSubjectFilter ? member.dataOwnerSubjectFilter : '-'}</b></div>
                                            <div className="subtitle">Task Type Filter: <b>{member.taskType ? member.taskType.name : '-'}</b></div>
                                        </div>
                                        <div className={"cellActions"}>
                                            <Button title="Edit" icon="none" size="small"
                                                onClick={() => {
                                                    setEditMember(member);
                                                    setEditConsortiumMemberOpen(true);
                                                }}
                                            />
                                            <ConfirmModal
                                                title={"Remove Member"}
                                                text={(<span>Are you sure you want to remove <b>{member.tenant.name}</b> in the consortium <b>{consortium.name}</b>?</span>)}
                                                onConfirm={removeMember.bind(this, member)}
                                                confirmTitle={"Remove Member"}
                                            >
                                                <div className="cellAction button small destructiveProminent">
                                                    <div className="title">Remove</div>
                                                </div>
                                            </ConfirmModal>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <Modal
                            content={(<AddEditTenantMember
                                onAdd={updateTenant}
                                onCancel={() => setEditConsortiumMemberOpen(false)}
                                taskTypes={props.tasks.taskTypes}
                                existingMember={editMember}
                            />)}
                            title="Edit Member"
                            size="large"
                            handleClose={() => () => setEditConsortiumMemberOpen(false)}
                            handleOpen={null}
                            open={editConsortiumMemberOpen}
                        />
                    </div>
                    <div className="tableSection">
                        <div className="sectionTitle">Data Sharing Rules</div>
                        <Modal
                            button={(
                                <div style={{ position: 'absolute', right: '20px' }}>
                                    <Button
                                        title="Add Rule"
                                        icon="none"
                                        size="small"
                                    />
                                </div>)}
                            content={(<AddShareRule consortiumTenants={consortium.members.map(function (m) { return m.tenant })} onAdd={addShareRule} onCancel={() => setAddRuleOpen(false)} />)}
                            title="Add Rule"
                            size="large"
                            handleClose={() => setAddRuleOpen(false)}
                            handleOpen={() => setAddRuleOpen(true)}
                            open={addRuleOpen}
                        />
                        {
                            _.map(_.sortBy(consortium.dataAccessRules, function (r) {
                                return r.id;
                            }), function (rule) {
                                return (
                                    <div key={rule.id} className="tableCell" style={{ height: 'auto' }}>
                                        <div className="cellBody">
                                            <div className="title groupName"><b>{rule.sourceTenant ? (rule.sourceTenant.name.length > 28 ? rule.sourceTenant.name.substring(0, 28) + '...' : rule.sourceTenant.name) : '-'}: {rule.dataSource ? (rule.dataSource.name.length > 28 ? rule.dataSource.name.substring(0, 28) + '...' : rule.dataSource.name) : '-'}</b> granted to <b>{rule.grantedTenant ? (rule.grantedTenant.name.length > 20 ? rule.grantedTenant.name.substring(0, 20) + '...' : rule.grantedTenant.name) : '-'}</b></div>
                                            <div className="subtitle">Agency Filter: <b>{rule.agencyFilter ? rule.agencyFilter : '-'}</b></div>
                                            <div className="subtitle">Jurisdiction Filter: <b>{rule.jurisdictionFilter ? rule.jurisdictionFilter : '-'}</b></div>
                                            <div className="subtitle">Survey Role Type: <b>{rule.surveyTargetRole ? rule.surveyTargetRole : '-'}</b></div>
                                        </div>
                                        <div className={"cellActions"}>
                                            <Button title="Edit" icon="none" size="small" onClick={() => {setRuleToEdit(rule); setEditDataAccessRuleOpen(true)}}/>
                                            <ConfirmModal
                                                title={"Remove Rule"}
                                                text={(<span>Are you sure you want to remove <b>{rule.sourceTenant ? rule.sourceTenant.name: '-'} : {rule.dataSource ? rule.dataSource.name : '-'}</b> granted to <b> {rule.grantedTenant ? rule.grantedTenant.name : '-'}</b>?</span>)}
                                                onConfirm={removeRule.bind(this, rule)}
                                                confirmTitle={"Remove Rule"}
                                            >
                                                <div className="cellAction button small destructiveProminent">
                                                    <div className="title">Remove Rule</div>
                                                </div>
                                            </ConfirmModal>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <Modal
                            content={(<EditDataAccessRule
                            dataAccessRule={ruleToEdit}
                            consortiumTenants={consortium.members.map(function (m) { return m.tenant })}
                            onEdit={editShareRule}
                            onCancel={() => setEditDataAccessRuleOpen(false)} />)}
                            title="Edit"
                            size="large"
                            handleClose={() => setEditDataAccessRuleOpen(false)}
                            handleOpen={() => setEditDataAccessRuleOpen(true)}
                            open={editDataAccessRuleOpen}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportSingleConsortium));
