import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import Pagination from '@material-ui/lab/Pagination';
import _ from "lodash";

import {fetchAllInterpreters} from "@/shared/conversation/actions";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getInterpreters : (fieldName) => dispatch(fetchAllInterpreters())
})

function InterpreterList(props) {
    const {selectedInterpreters, onSelect, multipleSelect} = props;
    const [selectedInterpreterIDs, setSelectedInterpreterIDs] = React.useState([]);


    useEffect(() => {
        // Page on server is 0 based
        props.getInterpreters();
        let ids = []
        console.log(selectedInterpreters);
        _.forEach(selectedInterpreters, function (u) {
            if(u != null) {
                ids.push(u.id);
            }
        })
        setSelectedInterpreterIDs(ids);
    }, []);

    const isSelectedInterpreter = (e) => {
        return (!_.isEmpty(selectedInterpreterIDs) && _.find(selectedInterpreterIDs, function(u) {
            return u == e.id;
        }));
    }

    const selectInterpreter = (int) => {
        if(multipleSelect) {
            if (isSelectedInterpreter(int)) {
                let idx = _.findIndex(selectedInterpreterIDs, function (u) {
                    return u == int.id;
                })
                if (idx > -1) {
                    selectedInterpreterIDs.splice(idx, 1)
                }
            } else {
                selectedInterpreterIDs.push(int.id);
            }
            setSelectedInterpreterIDs(selectedInterpreterIDs);
        } else {
            var selectedIds = [];
            if (!isSelectedInterpreter(int)) {
                selectedIds = [int.id];
            }
            setSelectedInterpreterIDs(selectedIds);
        }
        onSelect(int);
    }

    return (
        <div className="teamMembersList">
            {
                _.map(props.conversation.interpreters, function(int, k){
                    return (
                        <div className={`teamMemberCell ${selectedInterpreterIDs.includes(int.id) ? " selected" : ""}`} key={int.id} onClick={onSelect ? selectInterpreter.bind(this, int): null}>
                            <div className="memberDetails">
                                <div className="memberName">{int.language}</div>
                            </div>
                            {/*<div className="clientEmail clientContact">*/}
                            {/*    <div className="separator"/>*/}
                            {/*    <div className="title">{client.email}</div>*/}
                            {/*</div>*/}
                            {/*<div className="clientPhone clientContact">*/}
                            {/*    <div className="separator"/>*/}
                            {/*    <div className="title">{client.phone}</div>*/}
                            {/*</div>*/}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(InterpreterList);