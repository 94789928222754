import {
    refreshConversationMessages,
    requestConversationMessages, requestConversationMessagesFailure,
    requestConversationMessagesSuccess
} from "../conversation/actions";

import { Auth } from 'aws-amplify';

export const START_FILE_UPLOAD = 'START_FILE_UPLOAD';
export const startFileUpload = (key, file) => dispatch => {
    dispatch({
        type : START_FILE_UPLOAD,
        key: key,
        file: file
    });
}

export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const updateUploadProgress = (key, file) => dispatch => {
    dispatch({
        type : UPLOAD_PROGRESS,
        key: key,
        file: file
    });
}

export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const uploadSuccess = (key, file, storedFile) => dispatch => {
    dispatch({
        type : UPLOAD_SUCCESS,
        key: key,
        file: file,
        storedFile: storedFile
    });
}

export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';
export const uploadFailure = (key, file, errorMsg) => dispatch => {
    dispatch({
        type : UPLOAD_FAILURE,
        key: key,
        file: file,
        errorMsg: errorMsg
    });
}

export const CLEAR_UPLOADS = 'CLEAR_UPLOADS';
export const clearUploads = (key) => dispatch => {
    dispatch({
        type : CLEAR_UPLOADS,
        key: key
    });
}

export const CLEAR_UPLOAD = 'CLEAR_UPLOAD';
export const clearUpload = (key, file) => dispatch => {
    dispatch({
        type : CLEAR_UPLOAD,
        key: key,
        file: file
    });
}

export function uploadFile(key, file) {

    return function(dispatch) {
        dispatch(startFileUpload(key, file));

        let upload =  new Promise(async (resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open("POST", window.__RUNTIME_CONFIG__.API_HOST + "/api/v1/files/upload");
            req.setRequestHeader("Authorization", `Bearer ${(await Auth.currentSession()).accessToken.jwtToken}`)

            req.upload.addEventListener("progress", event => {
                if (event.lengthComputable) {
                    let copy = {};
                    copy[file.name] = {
                        state: "pending",
                        percentage: (event.loaded / event.total) * 100
                    };
                }
            });

            req.onreadystatechange = function(){
                if(req.readyState === 4){
                    resolve(req.response);
                }
            }

            req.upload.addEventListener("error", event => {
                reject(req.response);
            });


            const formData = new FormData();
            formData.append("file", file, file.name);
            req.send(formData);
        });

        upload.then(function(resp){
            let res = JSON.parse(resp);
            if(resp.error){
                dispatch(uploadFailure(key, file, res.error));
            }
            else {
                dispatch(uploadSuccess(key, file, res.file));
            }

        }).catch(function(error){
            console.log("ERROR");
            console.log(error);
        })
    }
}