import Add from '@material-ui/icons/Add';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TableDisplay from '../../../common/TableDisplay';
import ConfirmModal from "../../../common/ConfirmModal";
import {CircularProgress} from "@material-ui/core";
import {toast} from "react-toastify";

const mapStateToProps = state => ({
    ...state
})

function PublicFeedbackBoard(props) {
    const [publicFeedbackList, setPublicFeedbackList] = useState([]);
    const [linkGenerating, setLinkGenerating] = useState([]);
    const [linkDeleting, setLinkDeleting] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        API.get('Core', `/api/v1/public-feedback-board`)
            .then(r => {
                const sortedDataByCreatedAt = r.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setPublicFeedbackList(sortedDataByCreatedAt);
            });
    };

    let goToCreatePublicFeedback = () => {
        props.history.push(`/settings/create-public-feedback-board`,);
    }

    let goToEditPublicFeedback = (id) => {
        props.history.push(`/settings/edit-public-feedback-board/${id}`);
    }

    let generateNewLink = (id) => {
        API.post('Core', `/api/v1/public-feedback-board/${id}/refresh`)
            .then(() => {
                fetchData();
                toast('New link generated', { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
            })
            .catch(e => {
                console.error('Error generating new link ', e);
                toast(e.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR })
            }).finally(() => {
            setLinkGenerating(linkGenerating.filter(linkID => linkID !== id));
        });
    }

    let deleteLink = (id) => {
        API.del('Core', `/api/v1/public-feedback-board/${id}`)
            .then(() => {
                fetchData();
                toast('Setting Deleted', { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
            })
            .catch(e => {
                console.error('Error deleting the setting ', e);
                toast(e.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR })
            }).finally(() => {
            setLinkDeleting(linkDeleting.filter(linkID => linkID !== id));
        });
    }

    const copyLink = (datasource) => {
        var text = datasource;
        var elem = document.createElement("textarea");
        document.body.appendChild(elem);
        elem.value = text;
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);

        toast("Copied to clipboard!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
    }

    const COLUMNS = [
        { field: 'name',
          title: 'Name',
          customSort: (a, b) => (b.name.toLowerCase() < a.name.toLowerCase() ? -1 : 1)
        },
        {
            field: 'token', title: 'Link', sorting: false, render: d => {
                const feedbackUrl = `${window.location.protocol}//${window.location.hostname}/feedback-public/${d.token}`;
                return (
                    <div>
                        <a style={{ cursor: 'pointer', color:"inherit"}}
                           target="_blank"
                           rel="noopener noreferrer"
                           href={feedbackUrl}>
                            {feedbackUrl}
                        </a>
                    </div>
                );
            }
        },
        {
            field: 'createdAt', title: 'Created', render: d => {
                return (<div>
                    {d.createdAt}
                </div>)
            }
        },
        {
            field: 'actions', title: 'Actions', sorting: false, render: d => {
                const feedbackLink = `${window.location.protocol}//${window.location.hostname}/feedback-public/${d.token}`;
                return (<div style={{ display: 'flex', alignItems: "center" }}>
                    <div className='addPublicFeedbackButton editMarginAdjustment' onClick={() =>goToEditPublicFeedback(d.id)}>
                        Edit
                    </div>
                    {!linkGenerating.includes(d.id) ?
                        <ConfirmModal
                            title={"This will deactivate the old link"}
                            text={(<span>Are you sure you want to generate a new link? The previous link will no longer be accessible.</span>)}
                            onConfirm={() => {
                                setLinkGenerating([...linkGenerating, ...[d.id]])
                                generateNewLink(d.id)
                            }}
                            confirmTitle={"Generate Link"}
                        >
                            <div className='addPublicFeedbackButton editMarginAdjustment'>
                                New Link
                            </div>
                        </ConfirmModal>
                        :
                        <CircularProgress size={18} />
                    }
                    {!linkDeleting.includes(d.id) ?
                        <ConfirmModal
                            title={"Delete Feedback Board"}
                            text={(<span>Are you sure you want to delete this Feedback Board? The URL associated with the setting will no longer be accessible.</span>)}
                            onConfirm={() => {
                                setLinkGenerating([...linkGenerating, ...[d.id]])
                                deleteLink(d.id)
                            }}
                            confirmTitle={"Delete"}
                        >
                            <div className='addPublicFeedbackButton editMarginAdjustment'>
                                Delete
                            </div>
                        </ConfirmModal>
                        :
                        <CircularProgress size={18} />
                    }
                    <div className='addPublicFeedbackButton editMarginAdjustment' onClick={()=>copyLink(feedbackLink)}>
                        Copy Link
                    </div>
                </div>);
            }
        },
    ];

    return (
        <div>
            <div className='addPublicFeedbackButton' onClick={goToCreatePublicFeedback}>
                <Add />
                Add Board
            </div>
            <TableDisplay
                data={publicFeedbackList}
                title={props.title || 'Public Feedback Board'}
                columns={COLUMNS}
            />
        </div>
    );
}

export default withRouter(connect(mapStateToProps)(PublicFeedbackBoard));