import React from 'react';
import InputCell from "../common/form/InputCell";
import Select from "react-select";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import PhoneInput from "react-phone-input-2";
import DepartmentSelect from "./DepartmentSelect";
import {isDepartmentFeatureDisabled, isDepartmentFeatureEnabled} from "../shared/access";

export default function AddEditUser(props){
  var { roles, existingUser, onCancel, onSuccess } = props;
  var departmentFeatureDisabled = isDepartmentFeatureDisabled();
  const [userRoles, setUserRoles] = React.useState((existingUser && existingUser.role) ? existingUser.role : []);
  const [department, setDepartment] = React.useState((existingUser && existingUser.department) ? existingUser.department : null);
  const [notifyByEmail, setNotifyByEmail] = React.useState(existingUser ? existingUser.notifyByEmail : false);
  const [notifyBySMS, setNotifyBySMS] = React.useState(existingUser ? existingUser.notifyBySMS : false);
  const [phoneVal, setPhoneVal] = React.useState((existingUser && existingUser.phone) ? existingUser.phone : "");
  const [userStatus, setUserStatus] = React.useState(existingUser ? existingUser.userStatus : true);

  const firstNameRef = React.createRef();
  const lastNameRef = React.createRef();
  const emailRef = React.createRef();

  const saveUser = () => {
  	let userObj = {
  		firstName: firstNameRef.current.value,
  		lastName: lastNameRef.current.value,
  		email: existingUser ? existingUser.email : emailRef.current.value,
		role: userRoles,
		notifyByEmail: notifyByEmail,
		notifyBySMS: notifyBySMS,
		userStatus: userStatus,
		phone: phoneVal,
		department: department
  	}

  	if(existingUser){
  		userObj.email = existingUser.email;
		API.put('Core', '/api/v1/users/provider/' + existingUser.id, {body:userObj})
			.then(response => {
					toast.success("User updated!", {position: toast.POSITION.TOP_CENTER});
					onSuccess(response);
				},
				error => {
					toast.error("Could not update user: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
				}
			);
	}
  	else {
		API.post('Core', '/api/v1/users/provider', {body:userObj})
			.then(response => {
					toast.success("User Added!", {position: toast.POSITION.TOP_CENTER});
					onSuccess(response);
				},
				error => {
					toast.error("Could not create user: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
				}
			);
	}
  }

	const updateRole = (obj, action) => {
		// eslint-disable-next-line default-case
		switch (action.action) {
			case "remove-value":
			case "select-option":
				setUserRoles(Object.assign([], obj));
				break;
		}
	}

	const toggleNotifyByEmailEnabled = () => {
		setNotifyByEmail(!notifyByEmail);
	}

	const toggleNotifyBySMSEnabled = () => {
		setNotifyBySMS(!notifyBySMS);
	}
	const toggleUserStatusEnabled = () => {
		setUserStatus(!userStatus);
	}

	const onPhoneChange = (phone) => {
		setPhoneVal(phone);
	}

  return (
	  <div className="addEditClientForm">
		  <div className="content">
			  <div className="tableView">
				  <div className="tableSection sectionName">
					  <InputCell
						  placeholder={"first name"}
						  defaultValue={(existingUser ? existingUser.firstName : "")}
						  required
						  id="firstName"
						  label="First Name"
						  inputRef={firstNameRef}
					  />
					  <InputCell
						  placeholder={"last name"}
						  defaultValue={(existingUser ? existingUser.lastName : "")}
						  required
						  id="lastName"
						  label="Last Name"
						  inputRef={lastNameRef}
					  />
					  {
						  existingUser ? (
						  	<div className="cell inputCell phone">
							  <div className="title">Phone Number</div>
							  <PhoneInput
								  inputClass="tel-input"
								  country={'us'}
								  onlyCountries={['us']}
								  value={phoneVal}
								  onChange={onPhoneChange}
							  />
						  </div>
						  ) : null
					  }

					  {
					  	existingUser ? null : (
							<InputCell
								placeholder={"email"}
								defaultValue={("")}
								required
								id="email"
								label="Email"
								inputRef={emailRef}
							/>
						)
					  }
				  </div>
				  <div className="tableSection">
					  <div className="sectionTitle">
						  <div className="title">Role</div>
					  </div>
					  <Select className="selectCell" classNamePrefix="selectCell"
							  isDisabled={false}
							  isMulti
							  onChange={updateRole}
							  value={userRoles}
							  options={roles}
							  getOptionLabel={option => option.name}
							  getOptionValue={option => option.id}
					  />
				  </div>
				  { departmentFeatureDisabled?null:
				  <div className="tableSection">
					  <div className="sectionTitle">
						  <div className="title">Department</div>
					  </div>
					  <DepartmentSelect onSelect={(dep) => setDepartment(dep)} value={department}/>
				  </div>
				  }
				  <div className="tableSection">
					  <div onClick={toggleNotifyByEmailEnabled}
						   className={`tableCell switchCell ${notifyByEmail ? 'switchOn' : 'switchOff'}`}>
						  <div className="title">Notify By Email</div>
						  <div className={`accessory accessorySwitch ${notifyByEmail ? 'on' : 'off'}`}>
							  <div className="switchThumb"/>
						  </div>
					  </div>
					  <div onClick={toggleNotifyBySMSEnabled}
						   className={`tableCell switchCell ${notifyBySMS ? 'switchOn' : 'switchOff'}`}>
						  <div className="title">Notify By SMS</div>
						  <div className={`accessory accessorySwitch ${notifyBySMS ? 'on' : 'off'}`}>
							  <div className="switchThumb"/>
						  </div>
					  </div>
					  <div onClick={toggleUserStatusEnabled}
						   className={`tableCell switchCell ${userStatus ? 'switchOn' : 'switchOff'}`}>
						  <div className="title">Active</div>
						  <div className={`accessory accessorySwitch ${userStatus ? 'on' : 'off'}`}>
							  <div className="switchThumb"/>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
		  <div className="actionBar">
			  <div className="actions">
				  <div onClick={onCancel} className="button dismiss medium">
					  <div className="title">Cancel</div>
				  </div>

				  <div onClick={saveUser} className={`button confirm medium`}>
					  <div className="title">Save</div>
				  </div>
			  </div>

			  <div className="separator"></div>
		  </div>
	</div>
  );
}