import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";

const mapStateToProps = state => ({
    lastNotificationMessage: state.conversation.lastNotificationMessage,
    activeConversationId: state.conversation.activeConversationId,
    //...state
})

const mapDispatchToProps = dispatch => ({

})

function NotificationEl({ lastNotificationMessage, activeConversationId, location }) {
    const [supported, setSupported] = React.useState(false);
    const [windowIsActive, setWindowIsActive] = React.useState(true);

    const onFocus = () => {
        setWindowIsActive(true);
    }

    const onBlur = () => {
        setWindowIsActive(false);
    }

    React.useEffect(() => {
        if (!('Notification' in window)) {
            setSupported(false);
            console.log('This browser does not support system notifications');
            return;
        }

        if (Notification.permission === 'granted') {
            setSupported(true);
            console.log('You are already subscribed to web notifications');
            return;
        }

        if (
            Notification.permission !== 'denied' ||
            Notification.permission === 'default'
        ) {
            Notification.requestPermission().then(result => {
                if (result === 'granted') {
                    setSupported(true);
                    const notification = new Notification(
                        'Awesome! You will start receiving notifications shortly'
                    );
                }
            });
        }

        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, [])

    React.useEffect(() => {
        if (!lastNotificationMessage) {
            return;
        }

        let message = lastNotificationMessage;
        if (!message) {
            return;
        }

        // Only show messages from Client
        if (message.user && message.user.userType != 'CLIENT') {
            return;
        }

        let currentPath = _.trimStart(location.pathname, "/");
        // Use case where somebody has left conversation view
        let conversationFocus = _.startsWith(currentPath, 'conversation');

        /*
         * Don't send if they are currently viewing the conversation
         * ** They are in conversation area
         * ** AND active conversation is set
         * ** AND the message is from active conversation
         * ** AND browser window is in focus
         */

        if (activeConversationId && message.conversationId == activeConversationId
            && conversationFocus
            && windowIsActive
        ) {
            return;
        }

        let title = `New Message: ${lastNotificationMessage.message}`;
        if (lastNotificationMessage.user != null) {
            title = `New Message from ${lastNotificationMessage.user.readableName}: ${lastNotificationMessage.message}`;
        }

        new Notification(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastNotificationMessage])

    return (
        <span className={"global-notification"}></span>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationEl));