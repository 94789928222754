import { useLocation } from 'react-router';

import { isCandidateCommsEnabled, isPatientsEnabled } from '@/shared/access';
import CryptoJS from 'crypto-js';

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const deliveryStatusSortOrder = ['failed', 'undelivered', 'delivered', 'sent', 'queued', 'unknown'];

export const surveyCriteriaReviewFeature = 'SURVEY_REVIEW_CRITERIA';
export const multipleParticipantsFeature = 'MULTIPLE_PARTICIPANTS';
export const tasksFeature = 'TASKS_FEATURE';
export const providerRotationFeature = 'PROVIDER_ROTATIONS';
export const encounterBatchFeature = 'ENCOUNTER_BATCH';


export const taskStatuses = [
    { value: 'OPEN', label: 'OPEN' },
    { value: 'IN_PROGRESS', label: 'IN_PROGRESS' },
    { value: 'CLOSED', label: 'CLOSED' }
]

export const roles = [
    // CAD
    { value: 'CALLER', label: 'CALLER' },
    { value: 'CALLTAKER', label: 'CALL TAKER' },
    { value: 'RESPONDER', label: 'RESPONDER' },

    // Medic
    { value: 'DOCTOR', label: 'DOCTOR' },
    { value: 'MEDIC', label: 'MEDIC' },
    { value: 'PATIENT', label: 'PATIENT' },

    // RMS
    { value: 'DRIVER', label: 'DRIVER' },
    { value: 'EMPLOYEE', label: 'EMPLOYEE' },
    { value: 'PARENT', label: 'PARENT' },
    { value: 'PASSENGER', label: 'PASSENGER' },
    { value: 'PROPERTY_OWNER', label: 'PROPERTY OWNER' },
    { value: 'REPORTING_PARTY', label: 'REPORTING PARTY' },
    { value: 'SUBJECT', label: 'SUBJECT' },
    { value: 'SUSPECT', label: 'SUSPECT' },
    { value: 'VEHICLE_OWNER', label: 'VEHICLE OWNER' },
    { value: 'VICTIM', label: 'VICTIM' },
    { value: 'WITNESS', label: 'WITNESS' },

    // 311
    { value: 'CUSTOMER', label: 'CUSTOMER' },

    { value: 'OTHER', label: 'OTHER' }
]

export const sentiments = [
    { value: 'POSITIVE', label: 'POSITIVE' },
    { value: 'NEUTRAL', label: 'NEUTRAL' },
    { value: 'NEGATIVE', label: 'NEGATIVE' },
    { value: 'MIXED', label: 'MIXED' }
]

export const surveyActionComparisonFields = [
    { value: 'NORMALIZED_ANSWER', label: 'ANSWER' },
    { value: 'SENTIMENT', label: 'SENTIMENT' }
]

export const surveyReviewTargetTypeOptions = [
    {
        value: "MARK_REVIEW",
        label: "Mark For Review",
    },
    {
        value: "CREATE_TASK",
        label: "Create Task",
    },
    {
        value: "FOLLOWUP_QUESTION",
        label: "Follow Up Question",
    }];

export const surveyReviewTargetTypeOptionsWithoutQuestion = [
    {
        value: "MARK_REVIEW",
        label: "Mark For Review",
    },
    {
        value: "CREATE_TASK",
        label: "Create Task",
    }];

export const comparisonOperators = [
    { value: 'EQUAL', label: '=' },
    { value: 'GREATER_THAN', label: '>' },
    { value: 'GREATER_THAN_EQUALS', label: '>=' },
    { value: 'LESS_THAN', label: '<' },
    { value: 'LESS_THAN_EQUALS', label: '<=' },
    { value: 'CONTAINS', label: 'Contains' },
    { value: 'DOES_NOT_CONTAIN', label: 'Does Not Contain' },
    { value: 'IS_EMPTY', label: 'Is Empty' },
    { value: 'IS_NOT_EMPTY', label: 'Is Not Empty' },
    { value: 'IN_LIST', label: 'In List' },
    { value: 'NOT_IN_LIST', label: 'Not In List' },
    { value: 'IN_LIST_CONTAINS', label: 'In List Contains' },
    { value: 'NOT_IN_LIST_CONTAINS', label: 'Not In List Contains' },
]

export const TIMEZONES = [
    {
        label: "Eastern",
        value: "America/New_York"
    },
    {
        label: "Central",
        value: "America/Chicago"
    },
    {
        label: "Mountain",
        value: "America/Denver"
    },
    {
        label: "Pacific",
        value: "America/Los_Angeles"
    },
    {
        label: "Hawaii",
        value: "America/Honolulu"
    },
    {
        label: "Alaska",
        value: "America/Anchorage"
    }
];

export const ACTIVITY_EMAIL_DIGEST = 'ACTIVITY';

export const stateOptions = [
    {
        label: "Alabama",
        value: "AL"
    },
    {
        label: "Alaska",
        value: "AK"
    },
    {
        label: "American Samoa",
        value: "AS"
    },
    {
        label: "Arizona",
        value: "AZ"
    },
    {
        label: "Arkansas",
        value: "AR"
    },
    {
        label: "California",
        value: "CA"
    },
    {
        label: "Colorado",
        value: "CO"
    },
    {
        label: "Connecticut",
        value: "CT"
    },
    {
        label: "Delaware",
        value: "DE"
    },
    {
        label: "District Of Columbia",
        value: "DC"
    },
    {
        label: "Federated States Of Micronesia",
        value: "FM"
    },
    {
        label: "Florida",
        value: "FL"
    },
    {
        label: "Georgia",
        value: "GA"
    },
    {
        label: "Guam",
        value: "GU"
    },
    {
        label: "Hawaii",
        value: "HI"
    },
    {
        label: "Idaho",
        value: "ID"
    },
    {
        label: "Illinois",
        value: "IL"
    },
    {
        label: "Indiana",
        value: "IN"
    },
    {
        label: "Iowa",
        value: "IA"
    },
    {
        label: "Kansas",
        value: "KS"
    },
    {
        label: "Kentucky",
        value: "KY"
    },
    {
        label: "Louisiana",
        value: "LA"
    },
    {
        label: "Maine",
        value: "ME"
    },
    {
        label: "Marshall Islands",
        value: "MH"
    },
    {
        label: "Maryland",
        value: "MD"
    },
    {
        label: "Massachusetts",
        value: "MA"
    },
    {
        label: "Michigan",
        value: "MI"
    },
    {
        label: "Minnesota",
        value: "MN"
    },
    {
        label: "Mississippi",
        value: "MS"
    },
    {
        label: "Missouri",
        value: "MO"
    },
    {
        label: "Montana",
        value: "MT"
    },
    {
        label: "Nebraska",
        value: "NE"
    },
    {
        label: "Nevada",
        value: "NV"
    },
    {
        label: "New Hampshire",
        value: "NH"
    },
    {
        label: "New Jersey",
        value: "NJ"
    },
    {
        label: "New Mexico",
        value: "NM"
    },
    {
        label: "New York",
        value: "NY"
    },
    {
        label: "North Carolina",
        value: "NC"
    },
    {
        label: "North Dakota",
        value: "ND"
    },
    {
        label: "Northern Mariana Islands",
        value: "MP"
    },
    {
        label: "Ohio",
        value: "OH"
    },
    {
        label: "Oklahoma",
        value: "OK"
    },
    {
        label: "Oregon",
        value: "OR"
    },
    {
        label: "Palau",
        value: "PW"
    },
    {
        label: "Pennsylvania",
        value: "PA"
    },
    {
        label: "Puerto Rico",
        value: "PR"
    },
    {
        label: "Rhode Island",
        value: "RI"
    },
    {
        label: "South Carolina",
        value: "SC"
    },
    {
        label: "South Dakota",
        value: "SD"
    },
    {
        label: "Tennessee",
        value: "TN"
    },
    {
        label: "Texas",
        value: "TX"
    },
    {
        label: "Utah",
        value: "UT"
    },
    {
        label: "Vermont",
        value: "VT"
    },
    {
        label: "Virgin Islands",
        value: "VI"
    },
    {
        label: "Virginia",
        value: "VA"
    },
    {
        label: "Washington",
        value: "WA"
    },
    {
        label: "West Virginia",
        value: "WV"
    },
    {
        label: "Wisconsin",
        value: "WI"
    },
    {
        label: "Wyoming",
        value: "WY"
    }
];

export const genderOptions = [{ value: "MALE", label: "Male" }, { value: "FEMALE", label: "Female" }, { value: "NONBINARY", label: "Non-Binary" }];
export const yesNoOptions = [{ value: "YES", label: "Yes" }, { value: "NO", label: "No" }];

export const fixedOptions = {
    "gender": genderOptions,
    "state": stateOptions,
    "afterCareInstructionsSent": yesNoOptions
};

export const ehrSections = {
    "PATIENT_INFO": "PATIENT_INFO",
    "VITAL_SIGNS": "VITAL_SIGNS",
    "HPI": "HPI",
    "CLINICAL": "CLINICAL",
    "REVIEW_OF_SYSTEMS": "REVIEW_OF_SYSTEMS",
    "PHYSICAL_EXAM": "PHYSICAL_EXAM",
    "DIAGNOSTICS": "DIAGNOSTICS",
    "TREATMENT": "TREATMENT",
    "MDM_PLAN": "MDM_PLAN",
    "DX_DISPOSITION": "DX_DISPOSITION",
    "INSURANCE": "INSURANCE",
    "SOCIAL_WORKER": "SOCIAL_WORKER",
}

export const ehrSectionsToFieldsMap = {
    [ehrSections.PATIENT_INFO]: "demographics",
    [ehrSections.VITAL_SIGNS]: "interaction.currentVitalsObservations",
    [ehrSections.HPI]: "interaction",
    [ehrSections.CLINICAL]: "interaction",
    [ehrSections.REVIEW_OF_SYSTEMS]: "interaction",
    [ehrSections.PHYSICAL_EXAM]: "interaction",
    [ehrSections.DIAGNOSTICS]: "interaction",
    [ehrSections.TREATMENT]: "interaction",
    [ehrSections.MDM_PLAN]: "interaction",
    [ehrSections.DX_DISPOSITION]: "interaction",
    [ehrSections.SOCIAL_WORKER]: "interaction",
    [ehrSections.INSURANCE]: "interaction.insurance",
};

export const ehrFieldType = {
    TEXT_INPUT: "TEXT_INPUT",
    TEXTAREA_INPUT: "TEXTAREA_INPUT",
    NUMBER_INPUT: "NUMBER_INPUT",
    DATE_INPUT: "DATE_INPUT",
    PHONE_INPUT: "PHONE_INPUT",
    SINGLE_SELECT: "SINGLE_SELECT",
    MULTI_SELECT: "MULTI_SELECT"
};

export const deliveryStatusSort = ({ data, sortField }) => {
    const sortByObject = deliveryStatusSortOrder.reduce(
        (obj, item, index) => ({
            ...obj,
            [item]: index
        }), {})
    return data.sort((a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]])
}

export const listHasDuplicates = list => {
    return Object.values(list.reduce((acc, e) => {
        if(!acc[e]) acc[e] = 1;
        else acc[e]++;
        
        return acc;
    }, {})).filter(c => c > 1).length > 0;    
}

export const workflowTypes = {
    PATIENT: "PATIENT",
    APPLICANT: "APPLICANT",
    DEFAULT: "DEFAULT",
}

export const userTypes = {
    CLIENT: "CLIENT",
    PROVIDER: "PROVIDER",
    INTERPRETER: "INTERPRETER"
}

export const ehrHeaderFields = {
    INTAKE: "intake"
}

export const ENCOUNTER_TYPES = {
    CAD: { label: "CAD"},
    RMS_CASE: { label: "RMS CASE"},
    CONVERSATION: { label: "CONVERSATION"}
}

export const convertToTimeZone = (date, timeZoneString) => {
    if(date == null) return '-';

    const DATE = new Date(typeof date === "string" ? new Date(date) : date);
    const UTC_DATE = new Date(Date.UTC(DATE.getFullYear(), DATE.getMonth(), DATE.getDate(), DATE.getHours(), DATE.getMinutes(), DATE.getSeconds())); 

    return UTC_DATE.toLocaleString('en-US', {timeZone: timeZoneString, hour12: false});
}

export const filterGroupWorkflowType = type => {
    return (type.name === 'PATIENT' && isPatientsEnabled()) ||
           (type.name === 'APPLICANT' && isCandidateCommsEnabled()) ||
           (type.name === 'DEFAULT')
}

/**
 * Decrypts a given cipherText using AES encryption and PKCS#7 padding,
 * mirroring the decryption logic found in the `StringHelpers` class from Backend.
 *
 * This method is specifically tailored to decrypt texts encrypted with the StringHelpers.encrypt method,
 * utilizing a derived key from a passphrase and salt via PBKDF2.
*/
export const decrypt = (cipherText) => {
    const keySize = 256 / 32;
    const iterations = 65536;
    const hasher = CryptoJS.algo.SHA1;
    try {
        const key = CryptoJS.PBKDF2(atob(process.env.REACT_APP_REFERENCE_KEY), CryptoJS.enc.Utf8.parse(atob(process.env.REACT_APP_ANCHOR_POINT)), {
            keySize: keySize,
            iterations: iterations,
            hasher: hasher,
        });

        const hexEncodedCipher = CryptoJS.enc.Hex.parse(cipherText);
        const base64Cipher = CryptoJS.enc.Base64.stringify(hexEncodedCipher);

        const decryptedData = CryptoJS.AES.decrypt(base64Cipher, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });

        let decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);

        // eslint-disable-next-line no-control-regex
        decryptedText = decryptedText.replace(/[\x00-\x1F\x7F-\x9F]/g, ""); // Regular expression to remove control characters

        return decryptedText;
    } catch (error) {
        console.error('Decryption failed: ', error);
    }
};
export const enabledFeatureRoutes = /settings\/.*/;