import React from 'react';
import {withRouter} from 'react-router';
import _ from "lodash";
import {connect} from "react-redux";

import {fetchAllWorkflows} from "@/shared/workflow/actions";
import {updateActiveWorkflow} from "@/shared/conversation/actions";

import ConversationList from "./ConversationList";
import ConversationView from "./ConversationView";

const mapStateToProps = state => ({
    workflow: state.workflow,
    user: state.user,
    //...state
})

const mapDispatchToProps = dispatch => ({
    getWorkflows: () => dispatch(fetchAllWorkflows()),
    updateActiveWorkflow: (wf) => dispatch(updateActiveWorkflow(wf))
})

function Index(props) {
    const [activeConversationId, setActiveConversationId] = React.useState(props.match.params.conversationId);
    const [activeWorkflow, setActiveWorkflow] = React.useState(null);

    const conversationListRef = React.createRef();

    React.useEffect(() => {
        if(!_.has(props, 'user.aboutMe.currentTenant')){
            return;
        }
    });

    React.useEffect(() => {
        props.getWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    let loading = (<span>Loading...</span>);
    if(props.workflow.allWorkflows == null || props.workflow.allWorkflows.length === 0){
        return loading;
    }

    if(!activeWorkflow){
        let defaultWorkflow = null;
        if(props.match.params.workflowId){
            defaultWorkflow = _.find(props.workflow.allWorkflows, function(w){return w.id == props.match.params.workflowId;});
        } else {
            defaultWorkflow = _.find(props.workflow.allWorkflows, function(w){return w.default;});
        }

        if( !defaultWorkflow && props.workflow.allWorkflows.length > 0 ){
            defaultWorkflow = props.workflow.allWorkflows[0];
        }

        setActiveWorkflow(defaultWorkflow);
        props.updateActiveWorkflow(defaultWorkflow);
        return loading;
    }

    const updateWorkflow = (workflowId) => {
        let selectedWorkflow = _.find(props.workflow.allWorkflows, function(w){return w.id === workflowId;})
        setActiveWorkflow(selectedWorkflow);
        setActiveConversationId(null);
        props.updateActiveWorkflow(selectedWorkflow);
        props.history.push("/conversations/workflow/" + selectedWorkflow.id);
    }

    const onWorkflowChange = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                updateWorkflow(obj.id);
                break;
        }

    }

    const onConversationChange = (newConversationId, e) => {
        setActiveConversationId(newConversationId);
        props.history.push("/conversations/workflow/" + activeWorkflow.id + "/conversations/" + newConversationId);
    };

    return (
        <div className="conversationsView">
            <ConversationList conversationListRef={conversationListRef} onWorkflowChange={onWorkflowChange} onConversationChange={onConversationChange} activeConversationId={activeConversationId} activeWorkflow={activeWorkflow}/>
            <ConversationView conversationListRef={conversationListRef} activeConversationId={activeConversationId} activeWorkflow={activeWorkflow}/>
        </div>
    );
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));