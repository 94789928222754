import React from "react";
import { connect, useSelector } from "react-redux";
import Modal from "../../common/Modal";
import InputCell from "../../common/form/InputCell";
import _ from "lodash";
import TeamList from "../settings/team/TeamList";
import Select from "react-select";
import { roles } from "../../utils/utils";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
})

function AddEditTaskType({ props, open, close, existingTaskType, onSubmit, buttonOverride }) {
    const [addTaskTypeOpen, setAddTaskTypeOpen] = React.useState(false);
    const [taskTypeName, setTaskTypeName] =  React.useState(null);
    const dueDateDelayVal = React.createRef();
    const notesRef = React.createRef();

    const [searchFocused, setSearchFocused] = React.useState(false);
    const [searchAssigneeFocused, setSearchAssigneeFocused] = React.useState(false);

    const [searchInput, setSearchInput] = React.useState("");
    const [searchAssigneeInput, setSearchAssigneeInput] = React.useState("");
    const searchRef = React.createRef();
    const searchAssigneeRef = React.createRef();
    const [search, setSearch] = React.useState("");
    const [searchAssignee, setSearchAssignee] = React.useState("");
    const [pageNotifyGroupPersonnel, setPageNotifyGroupPersonnel] = React.useState(1);
    const [sizeNotifyGroupPersonnel] = React.useState(50);

    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [contactRoles, setContactRoles] = React.useState([])
    const [notifyRoles, setNotifyRoles] = React.useState([])
    const [assigneeRoles, setAssigneeRoles] = React.useState([])

    const [pageAssigneePersonnel, setPageAssigneePersonnel] = React.useState(1);
    const [sizeAssigneePersonnel] = React.useState(50);
    const [selectedUsersAssignee, setSelectedUsersAssignee] = React.useState([]);
    const [selectedWorkflow, setSelectedWorkflow] = React.useState(null);
    const [notes, setNotes] = React.useState(null);

    const defaultAssigneesSelector = useSelector(state => state.user.providerFilteredGroups);
    const notifyGroupsSelector = useSelector(state => state.user.providersFiltered);

    React.useEffect(() => {
        if (existingTaskType) {
            setSelectedUsersAssignee(existingTaskType.defaultAssignees);
            setSelectedUsers(existingTaskType.notifyGroup)
        }
    }, [defaultAssigneesSelector,notifyGroupsSelector]);

    React.useEffect(() => {
        setAddTaskTypeOpen(open);
    }, [open])

    React.useEffect(() => {
        props.getRoles();
        props.getWorkflows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (existingTaskType) {
            setTaskTypeName(existingTaskType.name)
            setNotes(existingTaskType.defaultTaskNotes ? existingTaskType.defaultTaskNotes : "")
            setContactRoles(existingTaskType.defaultContactRoles);
            setAssigneeRoles(_.map(existingTaskType.defaultAssigneeRoles, function (ar) { return { label: ar.name, value: ar } }))
            setNotifyRoles(_.map(existingTaskType.notifyRoles, function (nr) { return { label: nr.name, value: nr } }))
            setSelectedWorkflow(existingTaskType.workflow ? { label: existingTaskType.workflow.name, value: existingTaskType.workflow } : null)
        }
    }, [existingTaskType])

    const onPageChange = (event, page) => {
        setPageNotifyGroupPersonnel(page);
    }

    const onPageChangeAssignee = (event, page) => {
        setPageAssigneePersonnel(page);
    }

    const onSearchFocused = () => {
        setSearchFocused(true);
    }

    const onSearchAssigneeFocused = () => {
        setSearchAssigneeFocused(true);
    }

    const onSearchBlurred = () => {
        setSearchFocused(false);
    }

    const onSearchAssigneeBlurred = () => {
        setSearchAssigneeFocused(false);
    }

    const onSearchChange = (e) => {
        setSearchInput(searchRef.current.value);
    }

    const onSearchAssigneeChange = (e) => {
        setSearchAssigneeInput(searchAssigneeRef.current.value);
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            if (searchRef.current.value !== search) {
                setPageNotifyGroupPersonnel(1);
            }
            setSearch(searchRef.current.value);
        }
    }

    const keyPressAssignee = (e) => {
        if (e.keyCode === 13) {
            if (searchAssigneeRef.current.value !== searchAssignee) {
                setPageAssigneePersonnel(1);
            }
            setSearchAssignee(searchAssigneeRef.current.value);
        }
    }

    const clearSearch = () => {
        setSearch("");
        setSearchInput("");
    }

    const clearAssigneeSearch = () => {
        setSearchAssignee("");
        setSearchAssigneeInput("");
    }

    const isSelectedUser = (e) => {
        return (!_.isEmpty(selectedUsers) && _.find(selectedUsers, function (u) {
            return u.id === e.id;
        }));
    }

    const isSelectedUserAssignee = (e) => {
        return (!_.isEmpty(selectedUsersAssignee) && _.find(selectedUsersAssignee, function (u) {
            return u.id === e.id;
        }));
    }

    const onUserSelect = (e) => {
        let selected = selectedUsers;
        if (isSelectedUser(e)) {
            let idx = _.findIndex(selected, function (u) {
                return u.id === e.id;
            })
            if (idx > -1) {
                selected.splice(idx, 1)
            }
        }
        else {
            selected.push(e);
        }
        setSelectedUsers([...selected]);
    }

    const onUserSelectAssignee = (e) => {
        let selected = selectedUsersAssignee;
        if (isSelectedUserAssignee(e)) {
            let idx = _.findIndex(selected, function (u) {
                return u.id === e.id;
            })
            if (idx > -1) {
                selected.splice(idx, 1)
            }
        }
        else {
            selected.push(e);
        }
        setSelectedUsersAssignee([...selected]);
    }

    const handleAddTaskTypeOpen = () => {
        reset();
        setAddTaskTypeOpen(true);
    }

    const handleAddTaskTypeClose = () => {
        close();
        setSelectedUsers(existingTaskType && existingTaskType.notifyGroup ?  existingTaskType.notifyGroup : [])
        setSelectedUsersAssignee(existingTaskType && existingTaskType.defaultAssignees ? existingTaskType.defaultAssignees : [])
        setAddTaskTypeOpen(false);
        reset();
    }

    const reset = () => {
        setAddTaskTypeOpen(false);
    }

    const handleSubmit = () => {
        if (!taskTypeName) {
            return;
        }
        var taskType = {};
        taskType.notifyGroup = selectedUsers;
        taskType.notifyRoles = _.map(notifyRoles, function (nr) {
            return nr.value;
        })
        taskType.defaultAssignees = selectedUsersAssignee;
        taskType.defaultAssigneeRoles = _.map(assigneeRoles, function (nr) {
            return nr.value;
        })
        taskType.name = taskTypeName;
        taskType.defaultDueDateDelayHours = dueDateDelayVal.current.value;
        taskType.defaultTaskNotes = notes;
        taskType.defaultContactRoles = contactRoles;
        taskType.workflow = selectedWorkflow ? selectedWorkflow.value : null;

        setAddTaskTypeOpen(false);
        if (existingTaskType) {
            taskType.id = existingTaskType.id;
            onSubmit(taskType, 'update')
        } else {
            onSubmit(taskType, 'create')
        }
    }

    const setTargetRole = (obj, action) => {
        let tr = [];
        tr.push(obj.value);
        setContactRoles(tr);
    }

    const onChangeNotifyRoles = selectedOptions => {
        setNotifyRoles(selectedOptions);
    }

    const onChangeAssigneeRoles = selectedOptions => {
        setAssigneeRoles(selectedOptions);
    }

    const onChangeWorkflow = workflow => {
        setSelectedWorkflow(workflow);
    }

    const updateNotes = () => {
        setNotes(notesRef.current.value);
    }

    const changeTaskTypeVal = (e) => {
        setTaskTypeName(e.target.value)
    }

    return (
        <div className="">
            <Modal
                containerDivButtonClass={"containerDivButton"}
                content={(
                    <div>
                        <div className={"content"}>
                            <div className="tableView">
                                <div className="tableSection sectionType">
                                    <InputCell
                                        placeholder={existingTaskType ? existingTaskType.name : ""}
                                        defaultValue={existingTaskType ? existingTaskType.name : ""}
                                        id="taskType"
                                        label="Task Type"
                                        onChange={changeTaskTypeVal.bind(this)}
                                        value={taskTypeName}
                                    />
                                </div>
                                <div className="tableSection sectionType">
                                    <InputCell
                                        placeholder={existingTaskType ? existingTaskType.defaultDueDateDelayHours : ""}
                                        defaultValue={existingTaskType ? existingTaskType.defaultDueDateDelayHours : ""}
                                        required
                                        type={"number"}
                                        minValue="0"
                                        id="dueDateDelay"
                                        label="Due Date Delay Hours: "
                                        inputRef={dueDateDelayVal}
                                    />
                                </div>
                                <div className="tableSection sectionType">
                                    <div className="sectionTitle">Notes:</div>
                                    <textarea value={notes} ref={notesRef} placeholder={'Notes...'} className="sectionTextArea" onChange={updateNotes}/>
                                </div>

                                <div className="tableSection titledSelectCell targetResponseGroup">
                                    <div className="sectionTitle">Target Role</div>
                                    <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                        onChange={setTargetRole.bind(this)}
                                        value={contactRoles ? contactRoles.map(function (o) { return { label: o, value: o } }) : ''}
                                        options={roles}
                                    />
                                </div>

                                <div className="tableSection">
                                    <div className="sectionTitle">Default Communication Group</div>
                                    <div className="sectionTitle">
                                        <div className="subtitle">
                                            Select the communication group that conversations started from this task type will be created in.
                                        </div>
                                    </div>
                                    <div className="tableCell titledSelectCell targetResponseGroup">
                                        <div className="title">Communication Group</div>
                                        <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                            onChange={onChangeWorkflow.bind(this)}
                                            value={selectedWorkflow}
                                            options={props.workflow.allWorkflows.map((o) => { return { label: o.name, value: o } })}
                                        />
                                    </div>
                                </div>

                                <div className="tableSection sectionNotifyGroup">
                                    <div className="sectionTitle">Notify Group</div>
                                    <div className="tableCell titledSelectCell targetResponseGroup">
                                        <div className="title">Notify Roles</div>
                                        <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                            onChange={onChangeNotifyRoles}
                                            value={notifyRoles}
                                            options={props.user.roles.map(function (o) { return { label: o.name, value: o } })}
                                            isMulti
                                            isClearable
                                        />
                                    </div>
                                    <div className={`searchInput tableSearch ${searchFocused ? "isFocused" : ""} ${(searchInput.length > 0) ? "hasInput" : ""}`}>
                                        <div className="icon" />
                                        <input value={searchInput} onChange={onSearchChange} placeholder={"Search All Personnel"} onFocus={onSearchFocused} onBlur={onSearchBlurred} className="input" onKeyDown={keyPress} type="text" ref={searchRef} />
                                        <div className="clear" onClick={clearSearch}></div>
                                    </div>
                                    <TeamList
                                        page={pageNotifyGroupPersonnel}
                                        size={sizeNotifyGroupPersonnel}
                                        search={search}
                                        handlePageChange={onPageChange}
                                        selectedUsers={selectedUsers}
                                        onSelect={onUserSelect}
                                        multipleSelect={true}
                                        withoutPhone={false}
                                    />
                                </div>
                                <div className="tableSection sectionNotifyGroup">
                                    <div className="sectionTitle">Default Assignees</div>
                                    <div className="tableCell titledSelectCell targetResponseGroup">
                                        <div className="title">Assignee Roles</div>
                                        <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                            onChange={onChangeAssigneeRoles}
                                            value={assigneeRoles}
                                            options={props.user.roles.map(function (o) { return { label: o.name, value: o } })}
                                            isMulti
                                            isClearable
                                        />
                                    </div>
                                    <div className={`searchInput tableSearch ${searchAssigneeFocused ? "isFocused" : ""} ${(searchAssigneeInput.length > 0) ? "hasInput" : ""}`}>
                                        <div className="icon" />
                                        <input value={searchAssigneeInput} onChange={onSearchAssigneeChange} placeholder={"Search All Personnel"} onFocus={onSearchAssigneeFocused} onBlur={onSearchAssigneeBlurred} className="input" onKeyDown={keyPressAssignee} type="text" ref={searchAssigneeRef} />
                                        <div className="clear" onClick={clearAssigneeSearch}></div>
                                    </div>
                                    <TeamList
                                        page={pageAssigneePersonnel}
                                        size={sizeAssigneePersonnel}
                                        search={searchAssignee}
                                        handlePageChange={onPageChangeAssignee}
                                        selectedUsers={selectedUsersAssignee}
                                        onSelect={onUserSelectAssignee}
                                        multipleSelect={true}
                                        withoutPhone={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="actionBar">
                            <div className="actions">
                                <div onClick={handleAddTaskTypeClose} className="button dismiss medium">
                                    <div className="title">Cancel</div>
                                </div>

                                <div onClick={taskTypeName ? handleSubmit : null} className={`button confirm medium ${taskTypeName ? '' : 'disabled'}`}>
                                    <div className="title">Submit</div>
                                </div>
                            </div>

                            <div className="separator"></div>
                        </div>
                    </div>
                )}
                title={"Add Task Type"}
                size="medium"
                handleClose={handleAddTaskTypeClose}
                handleOpen={handleAddTaskTypeOpen}
                open={addTaskTypeOpen}
                dialogClassName="modalDialog addTaskType"
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditTaskType);