import React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import _ from 'lodash';

import {
    fetchAllArticleGroups,
    fetchAllArticles,
} from "@/shared/articles/actions";
import Modal from "@/common/Modal";

import AddEditArticleGroup from "./AddEditArticleGroup";
import AddEditArticles from "./AddEditArticles";

const mapStateToProps = state => ({
    articleGroups: state.articles.articleGroups,
    articles: state.articles.articles,
})

const mapDispatchToProps = dispatch => ({
    getArticleGroups : () => dispatch(fetchAllArticleGroups()),
    getArticles: (activeArticleGroupId) => dispatch(fetchAllArticles(activeArticleGroupId))
})


function LibraryIndex(props) {
    const [activeArticleGroupId, setActiveArticleGroupId] = React.useState(props.match.params.articleGroupId);
    const [activeArticleId, setActiveArticleId] = React.useState(props.match.params.articleGroupId);
    const [open, setOpen] = React.useState(false);
    const [articleOpen, setArticleOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState({});
    const [editGroupOpen, setEditGroupOpen] = React.useState({});

    React.useEffect(() => {
         props.getArticleGroups();
         props.getArticles(activeArticleGroupId);

    }, [activeArticleGroupId])

    let activeArticleGroup = _.find(props.articleGroups, function(ag){ return ag.id == activeArticleGroupId;});

    const onSuccess = (article) => {
        props.getArticleGroups();
        setOpen(false);
    }

    const onGroupEditSuccess = (group) => {
        props.getArticleGroups();
        groupEditClose(group);
    }

    const groupEditClose = (group) => {
        editGroupOpen[group.id] = false;
        setEditGroupOpen(Object.assign({}, editGroupOpen));
    }

    const groupEditOpen = (group) => {
        editGroupOpen[group.id] = true;
        setEditGroupOpen(Object.assign({}, editGroupOpen));
    }

    const isGroupOpen = (group) => {
        return editGroupOpen[group.id];
    }

    const onGroupCancel = (group) => {
        groupEditClose(group);
    }

    const onDeleteArticleGroup = (group) => {
        props.getArticleGroups();
        groupEditClose(group);
    }

    const onSuccess_articles = (article) => {
        props.getArticles(activeArticleGroupId);
        setArticleOpen(false);
    }
    const onSuccess_edit_articles = (article) => {
        closeEditArticle(article);
        props.getArticles(activeArticleGroupId);
    }

    const openEditArticle = (art) => {
        editOpen[art.id] = true;
        setEditOpen(Object.assign({}, editOpen));
    }

    const closeEditArticle = (art) => {
        editOpen[art.id] = false;
        setEditOpen(Object.assign({}, editOpen));
    }

    const isEditArticleOpen = (art) => {
        return editOpen[art.id];
    }

    const handleOpen = () => {
        setOpen(true);
    }


    const handleClose = () => {
        setOpen(false);
    }

    const handleOpenArticles = () => {
        setArticleOpen(true);
    }


    const handleCloseArticles = () => {
        setArticleOpen(false);
    }

    const deleteArticle = (article) => {
        props.getArticles(activeArticleGroupId);
        handleEditClose(article);
    }


    const handleEditClose = (article) => {
        closeEditArticle(article);
    }


    const onArticleGroupSelect = (articleGroup) => {

        setActiveArticleGroupId(articleGroup.id);
        props.history.push("/library/" + articleGroup.id + "/article/");
    }

    const onArticleSelect = (article, articleGroup) => {
        setActiveArticleId(article.id);
        props.history.push("/library/" + activeArticleGroupId + "/article/" + article.id);
    }

    const onArticleEditSelect = (article) => {
        setActiveArticleId(article.id);
        props.history.push("/library/" + activeArticleGroupId + "/article/" + article.id/ + "/edit");
    }



    return (
      <div className="articlesView splitView">
        <div className="articlesGroups mainView">
              <div className="navBar">
                  <div className="title">Groups</div>
                  <div className="actions">
                      <Modal
                          button={(
                              <div className="action add">
                                  <div className="icon"/>
                                  {/*<div className="title"> </div>*/}
                              </div>)}
                          content={(<AddEditArticleGroup
                              onSuccess={onSuccess}
                              onCancel={handleClose}
                          />)}
                          title="Add Group"
                          size="md"
                          handleClose={handleClose}
                          handleOpen={handleOpen}
                          open={open}
                      />
                  </div>
                  <div className="separator"/>
              </div> {/* MARK: Nav Bar */}
              <div className="scrollView">
                  <div className="articlesGroupsList">
                        {
                            _.map(_.sortBy(props.articleGroups, function (a) {
                                return a.name
                            }), function (ag) {
                                return (
                                    <div key={ag.id} onClick={onArticleGroupSelect.bind(this, ag)}
                                         className={`articlesGroupCell mainCell ${ag.id == activeArticleGroupId ? 'selected' : ''}`}>
                                        <div className="groupTitle">{ag.name}</div>
                                        <div className="articlesCount">{ag.articleCount ? (ag.articleCount == 1 ? "1 message" : ag.articleCount + " messages") : "None"}</div>
                                        
                                        <div className="groupActions">
                                            {ag.type === "USER_CREATED" ?
                                                <Modal
                                                button={(
                                                    <div className="actionEdit button small action">
                                                        <div className="title">Edit</div>
                                                    </div>)}
                                                content={(<AddEditArticleGroup
                                                    onSuccess={onGroupEditSuccess.bind(this, ag)}
                                                    onCancel={groupEditClose.bind(this, ag)}
                                                    onDelete={onDeleteArticleGroup.bind(this, ag)}
                                                    existingArticleGroup={ag}
                                                />)}
                                                title="Edit Group"
                                                size="md"
                                                handleClose={onGroupCancel.bind(this, ag)}
                                                handleOpen={groupEditOpen.bind(this, ag)}
                                                open={isGroupOpen(ag)}
                                              /> : null }
                                            
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>

                    </div> {/* MARK}: Articles Groups List */}
                </div> {/* MARK: Scroll View */}

        <div className="articlesGroupDetail detailView">
            {
                activeArticleGroup ? (
                    <div className="navBar">
                        <div className="title">Messages related to {activeArticleGroup.name}</div>
                        <div className="actions">

                            <Modal
                                button={(
                                    <div className="action add">
                                        <div className="icon"/>
                                        <div className="title">Add Library Item</div>
                                    </div>)}
                                content={(<AddEditArticles
                                    onSuccess={onSuccess_articles}
                                    onCancel={handleCloseArticles}
                                    articleGroupId = {activeArticleGroupId}
                                    articleId = {activeArticleId}
                                />)}
                                title="Add Article"
                                size="xl"
                                handleClose={handleCloseArticles}
                                handleOpen={handleOpenArticles}
                                open={articleOpen}
                            />
                        </div>
                        <div className="separator"/>
                    </div>
                ) : null
            }
            {
                activeArticleGroup ? (
                    <div className="scrollView">
                        <div className="contentView articlesList">
                            {/*<div className="searchInput">*/}
                            {/*  <div className="icon"/>*/}
                            {/*  <div className="input">Search All Articles</div>*/}
                            {/*  <div className="clear"/>*/}
                            {/*</div>*/}

                            {
                                _.map(_.sortBy(props.articles, function(a1){return a1.name}), function(art){
                                    if(activeArticleGroupId !== null){
                                        return (
                                            <div key={art.id} onClick={onArticleSelect.bind(this, art)} className={`articleCell mainCell`}>
                                                <div className="articleTitle">{art.name}</div>
                                                <div className="articleBody">{art.contents}</div>
                                                <div className="articleActions">
                                                    <Modal
                                                        button={(
                                                            <div onClick={onArticleSelect.bind(this, art)} className="actionEdit button small action">
                                                                <div className="title">Edit</div>
                                                            </div>
                                                        )}
                                                        content={(<AddEditArticles
                                                            onSuccess={onSuccess_edit_articles}
                                                            onCancel={handleEditClose.bind(this, art)}
                                                            onDelete = {deleteArticle.bind(this,art)}
                                                            articleGroupId = {activeArticleGroupId}
                                                            existingArticle = {art}
                                                            articleId = {art.id}
                                                        />)}
                                                        title={"Edit Article: " + art.name}
                                                        size="xl"
                                                        handleClose={closeEditArticle.bind(this, art)}
                                                        handleOpen={openEditArticle.bind(this, art)}
                                                        open={isEditArticleOpen(art)}
                                                    />

                                                    {/*<a href="https://www.apple.com/"*/}
                                                    {/*   title="">the apple homepage for testing hyperlink </a>.*/}
                                                    {/*  <div className="timestamp">2020-03-31</div>*/}
                                                    {/*  <div className="viewCount">512 views</div>*/}
                                                </div>

                                            </div>
                                        )}
                                })
                            }
                        </div>
                    </div>
                ) : null
            }
        </div> {/* MARK: Articles Group Detail */}
      </div> // MARK: Articles View
    );

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LibraryIndex));
//export default ArticlesIndex;