import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom'
import PrivateRoute from './PrivateRoute';

// ***** ROUTE COMPONENTS *****
import AuthComponent from '../authcomponents/AuthComponent';
import GettingStarted from './setup/GettingStarted';
import Settings from './settings/SettingsIndex';
import VideoChat from './video/VideoChat';
import RequestLocation from "./location/RequestLocation";
import Conversations from './conversations/ConversationsIndex';
import Clients from './clients/ClientIndex';
import Services from './services/ServicesIndex';
import Providers from './providers/ProvidersIndex';
import Rules from './rules/RulesIndex';
import Tasks from './tasks/TasksIndex';
import Help from './help/Help';
import ConfirmServiceOfferings from "./services/ConfirmServiceOfferings";
import StripeAuthReceive from "./services/StripeAuthReceive";
import Measure from "./measure/MeasureIndex";
import Satisfaction from "./satisfaction/SatisfactionIndex";
import SatisfactionPersonnelView from "./satisfaction/SatisfactionPersonnelView";
import RuleVersion from "./rules/RuleVersion";
import SurveyVersion from "./survey/SurveyVersion";
import SurveyIndex from "./survey/SurveyIndex";
import ActivityIndex from "./activity/ActivityIndex";
import MapIndex from "./map/MapIndex";
import LibraryIndex from "./library/LibraryIndex";
import PositivityIndex from "./positivity/PositivityIndex";
import DataSourcesIndex from "./datasources/DataSourcesIndex";
import SupportTools from "./supporttools/SupportTools";
import SupportSingleTenant from "./supporttools/SupportSingleTenant";
import ManageAnalytics from "./supporttools/ManageAnalytics";
import PublicSurvey from "./survey/PublicSurvey";
import RequestPublicSurvey from "./survey/RequestPublicSurvey";
import ConversationAccept from "./conversations/ConversationAccept";
import FederatedAuthComponent from "../authcomponents/FederatedAuthComponent";
import EncounterDetails from "./encounter/EncounterDetails";
import ProviderRotation from "./providers/ProviderRotation";
import TestRule from "./rules/TestRule";
import SupportSingleConsortium from "./supporttools/SupportSingleConsortium";
import SupportSingleAgent from "./supporttools/SupportSingleAgent";
import EHRModal from './conversations/EHRModal';
import FileImportIndex from './file-import/FileImportIndex';
import FileImportLogsIndex from './file-import/FileImportLogsIndex';
import ApplicantModal from './conversations/ApplicantModal';
import EditResponseGroup from './settings/conversation/EditResponseGroup';
import CandidatesIndex from './survey/CandidatesIndex';
import CaseLookup from "./caselookup/CaseLookup";
import PublicFeedbackIndex from "./positivity/PublicFeedbackIndex";
import EIDPostSignInCallback from "../eid/EIDPostSignInCallback";
import SSORedirection from "../eid/SSORedirection";
import EIDPostSignOutCallback from "../eid/EIDPostSignOutCallback";

function Routes( props ){
  var { ...childProps } = props;
  return (
    <Switch>
      <Route exact path="/auth" render={() => <AuthComponent {...childProps}/>} />
      <Route exact path="/callback" render={() => <EIDPostSignInCallback {...childProps}/>} />
      <Route exact path="/signin/sso" render={() => <SSORedirection {...childProps}/>} />
      <Route exact path="/logout/callback" render={() => <EIDPostSignOutCallback {...childProps}/>} />
      <Route exact path="/:path/auth" render={() => <FederatedAuthComponent {...childProps}/>} />
      <Route exact path="/:path/case-lookup" render={() => <CaseLookup {...childProps}/>} />
      <Route exact path="/sign-up" render={() => <AuthComponent {...childProps}/>} />
      <Route exact path="/video-chat/:roomId/token/:tokenId" render={() => <VideoChat {...childProps}/>} />
      <Route exact path="/request-location/token/:requestLocationHash" render={() => <RequestLocation {...childProps}/>} />
      <Route exact path="/video-chat/:roomId" render={() => <VideoChat {...childProps}/>} />
      <Route exact path="/conversation-accept/:token" render={() => <ConversationAccept {...childProps}/>} />
      <Route exact path="/take-survey/public/:surveyId" render={() => <RequestPublicSurvey {...childProps}/>} />
      <Route exact path="/take-survey/:token" render={() => <PublicSurvey {...childProps}/>} />
      <Route exact path="/confirm-service/:acceptHash" render={() => <ConfirmServiceOfferings {...childProps}/>} />
      <Route exact path="/ehr/:conversationId" render={() => <EHRModal {...childProps}/>} />
      <Route exact path="/applicant/:conversationId" render={() => <ApplicantModal {...childProps}/>} />
      <Route exact path="/feedback-public/:token" render={() => <PublicFeedbackIndex {...childProps}/>} />
      <PrivateRoute exact path="/" render={() => <GettingStarted/>} {...childProps} />
      <PrivateRoute exact path="/home" render={() => <GettingStarted/>} {...childProps} />
      <PrivateRoute exact path="/help" render={() => <Help/>} {...childProps} />
      <PrivateRoute path="/settings" render={() => <Settings/>} {...childProps}/>
      <PrivateRoute path="/conversations/workflow/:workflowId/conversations/:conversationId" render={() => <Conversations/>} {...childProps}/>
      <PrivateRoute path="/conversations/workflow/:workflowId" render={() => <Conversations/>} {...childProps}/>
      <PrivateRoute path="/conversations" render={() => <Conversations/>} {...childProps}/>
      <PrivateRoute path="/clients" render={() => <Clients/>} {...childProps}/>
      <PrivateRoute path="/services" render={() => <Services/>} {...childProps}/>
      <PrivateRoute path="/file-import/:id/logs" render={() => <FileImportLogsIndex/>} {...childProps}/>
      <PrivateRoute path="/file-import" render={() => <FileImportIndex/>} {...childProps}/>
      <PrivateRoute path="/datasources" render={() => <DataSourcesIndex/>} {...childProps}/>
      <PrivateRoute path="/providers" render={() => <Providers/>} {...childProps}/>
      <PrivateRoute path="/rules/:ruleId/version/:versionId" render={() => <RuleVersion/>} {...childProps}/>
      <PrivateRoute path="/rules/:ruleId" render={() => <RuleVersion/>} {...childProps}/>
      <PrivateRoute path="/rules" render={() => <Rules/>} {...childProps}/>
      <PrivateRoute path="/tasks" render={() => <Tasks/>} {...childProps}/>
      <PrivateRoute path="/survey/:surveyId/version/:versionId" render={() => <SurveyVersion/>} {...childProps}/>
      <PrivateRoute path="/survey/:surveyId" render={() => <SurveyVersion/>} {...childProps}/>
      <PrivateRoute path="/survey" render={() => <SurveyIndex/>} {...childProps}/>
      <PrivateRoute path="/candidates" render={() => <CandidatesIndex/>} {...childProps}/>
      <PrivateRoute path="/activity/:tab" render={() => <ActivityIndex/>} {...childProps}/>
      <PrivateRoute path="/activity" render={() => <ActivityIndex/>} {...childProps}/>
      <PrivateRoute path="/map" render={() => <MapIndex/>} {...childProps}/>
      <PrivateRoute path="/library/:articleGroupId/article/:articleId" render={() => <LibraryIndex/>} {...childProps}/>
      <PrivateRoute path="/library/:articleGroupId" render={() => <LibraryIndex/>} {...childProps}/>
      <PrivateRoute path="/library" render={() => <LibraryIndex/>} {...childProps}/>
      <PrivateRoute path="/positivity" render={() => <PositivityIndex/>} {...childProps}/>
      
      <PrivateRoute path="/editResponseGroup" render={() => <EditResponseGroup />} {...childProps}/>

      <PrivateRoute path="/stripe-oauth-receive" render={() => <StripeAuthReceive/>} {...childProps}/>
      <PrivateRoute path="/measure/:dashboardId" render={() => <Measure/>} {...childProps}/>
      <PrivateRoute path="/measure" render={() => <Measure/>} {...childProps}/>
      <PrivateRoute path="/satisfaction" render={() => <Satisfaction/>} {...childProps}/>
      <PrivateRoute path="/satisfaction-personnel/:personnelId" render={() => <SatisfactionPersonnelView/>} {...childProps}/>
      <PrivateRoute path="/support-tools/tenant/:tenantId" render={() => <SupportSingleTenant/>} {...childProps}/>
      <PrivateRoute path="/support-tools/consortium/:consortiumId" render={() => <SupportSingleConsortium/>} {...childProps}/>
      <PrivateRoute path="/support-tools/agent/:agentId" render={() => <SupportSingleAgent/>} {...childProps}/>
      <PrivateRoute path="/support-tools/analytics" render={() => <ManageAnalytics/>} {...childProps}/>
      <PrivateRoute path="/support-tools/:tab" render={() => <SupportTools/>} {...childProps}/>
      <PrivateRoute path="/support-tools" render={() => <SupportTools/>} {...childProps}/>
      <PrivateRoute path="/encounter-details" render={() => <EncounterDetails/>} {...childProps}/>
      <PrivateRoute path="/test-rule" render={() => <TestRule/>} {...childProps}/>
      <PrivateRoute path="/provider-rotation" render={() => <ProviderRotation/>} {...childProps}/>
    </Switch>
  )
}

export default Routes;