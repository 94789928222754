import React from "react";
import { API } from "aws-amplify";
import { withRouter } from "react-router";
import InputCell from "../../common/form/InputCell";
import { Grid, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import CaseLookupDetails from "./CaseLookupDetails";
const useStyles = makeStyles(theme => ({
    textStyle: {
        color: 'white',
        fontFamily: '"Inter", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
    }
}));

function CaseLookupFooter({ settings }) {
    const classes = useStyles();
    return (
        <footer style={{
            backgroundColor: "#444444",
            position: "fixed",
            bottom: 0,
            height: '100px',
            width: '100%',
            padding: '20px 20px'
        }}>
            <Grid container>
                <Grid sm={3} xs={6} style={{ borderRight: '3px solid #939393' }}>
                    <div style={{ fontWeight: 600 }} className={classes.textStyle}>{settings.formalAgencyName}</div>
                    <div style={{ fontWeight: 500 }} className={classes.textStyle}>{settings.address1}</div>
                    <div style={{ fontWeight: 500 }} className={classes.textStyle}>{settings.city}, {settings.state} {settings.zip}</div>
                </Grid>
                <Grid style={{ marginLeft: '20px' }} sm={3} xs={6}>
                    <div style={{ fontWeight: 500 }} className={classes.textStyle}>{settings.phone}</div>
                    <div style={{ fontWeight: 500 }} className={classes.textStyle}>{settings.email}</div>
                    <div style={{ fontWeight: 500 }} className={classes.textStyle}><a style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }} href={settings.agencyWebsite}>{settings.agencyWebsite}</a></div>
                </Grid>
            </Grid>
        </footer>
    )
}

function CaseLookup(props) {
    const { path } = props.match.params;
    const [settings, setSettings] = React.useState({});
    const [caseDetails, setCaseDetails] = React.useState(null);
    const [recaptchaLoaded, setRecaptchaLoaded] = React.useState(false);
    const caseLookupRef = React.createRef();

    const onSubmit = () => {
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(window.__RUNTIME_CONFIG__.RECAPTCHA_SITE_KEY, { action: 'CASE_LOOKUP' }).then(function (token) {
                let lookupRequest = {
                    caseId: caseLookupRef.current.value,
                    recaptchaToken: token
                }
                API.post('Unauthorized', '/u/case-portal/' + path, { body: lookupRequest }).then(response => {
                    setCaseDetails(response);
                },
                    error => {
                        toast("Could not find case: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                    })
            });
        });
    }

    const loadRecaptcha = (callback) => {
        let scriptId = "grecaptcha-script";
        const existingScript = document.getElementById(scriptId);
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js?render=' + window.__RUNTIME_CONFIG__.RECAPTCHA_SITE_KEY;
            script.id = scriptId;
            document.body.appendChild(script);
            script.onload = () => {
                if (callback) {
                    callback();
                }
            };
        }
        if (existingScript && callback) {
            callback();
        }
    };

    React.useEffect(() => {
        loadRecaptcha(() => {
            setRecaptchaLoaded(true);
        });
        API.get("Unauthorized", "/u/case-portal/" + path)
            .then(resp => {
                setSettings(resp);
            },
                err => {

                })
    }, []);

    if (!settings || !recaptchaLoaded) {
        return (<span>Loading...</span>);
    }

    if (caseDetails) {
        return (
            <Grid container className="publicSurveyForm" spacing={2}>
                <Grid sm={6} xs={12} style={{ width: '100%' }}>
                    <CaseLookupDetails onDone={() => setCaseDetails(null)} revisions={caseDetails.revisions} schema={caseDetails.schema} />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container className="publicSurveyForm" spacing={2}>
            <Grid sm={6} xs={12} style={{ width: '100%' }}>
                {
                    settings && settings.storedFile && settings.storedFile.url ? (
                        <img style={{ maxWidth: '210px', maxHeight: '100px', display: 'block', margin: 'auto' }} src={settings.storedFile.url} />
                    ) : (<div className="surveyAgencyName">{settings.formalAgencyName}</div>)
                }
                <div style={{
                    color: 'white',
                    fontFamily: '"Inter", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
                    fontWeight: 700,
                    fontSize: '20px',
                    textAlign: 'center',
                    marginTop: '10px'
                }}>{settings.formalAgencyName}</div>
            </Grid>
            <Grid sm={6} xs={12} style={{ width: '100%', marginTop: '40px' }}>
                <div className={"tableView"}>
                    <div className="tableSection sectionName">
                        <div className={"sectionTitle"}>
                            <div className={"title"} style={{ color: 'white', fontSize: '16px' }}>Case Number</div>
                        </div>
                        <InputCell
                            placeholder={"Enter your Case Number"}
                            required
                            id="case-lookup"
                            inputRef={caseLookupRef}
                            inputProps={{ style: { textAlign: 'left' } }}
                        />
                    </div>
                </div>
                {/*<p className="footerText">{survey.footer}</p>*/}
            </Grid>
            <Grid sm={6} xs={12} style={{ width: '100%', marginTop: '10px' }}>
                <div style={{ maxWidth: '200px', float: 'right' }} onClick={onSubmit} className="button large confirm submitSurvey">
                    <div className="buttonTitle">Lookup Case</div>
                </div>
            </Grid>
            <CaseLookupFooter settings={settings} />
        </Grid>
    )
}

export default withRouter(CaseLookup);