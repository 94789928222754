import React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import _ from "lodash";

import Modal from "@/common/Modal";
import ConfirmModal from "@/common/ConfirmModal";
import {deleteRule, fetchAllRules} from "@/shared/rules/actions";

import AddEditRule from "./AddEditRule";

const mapStateToProps = state => ({
    rules: state.rules,
})

const mapDispatchToProps = dispatch => ({
    getRules : () => dispatch(fetchAllRules()),
    deleteRule : (id) => dispatch(deleteRule(id))
})

function RulesIndex(props) {
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [editRule, setEditRule] = React.useState(null) ;

    React.useEffect(() => {
        props.getRules();
    }, [])

    const onSuccess = (rule) => {
        props.getRules();
        setOpen(false);
        setEditOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleEditRule = (rule) => {
        setEditRule(rule);
        setEditOpen(true);
    }

    const handleEditClose = () => {
        setEditRule(null);
        setEditOpen(false);
    }

    const editRuleVersions = (rule) => {
        props.history.push("/rules/" + rule.id);
    }

    const deleteRule = (rule) => {
        props.deleteRule(rule.id);
    }

    const toggleRule = (rule) => {
        let newStatus = rule.status === "ACTIVE" ? "DISABLED" : "ACTIVE";
        API.put('Core', '/api/v1/rule/' + rule.id, {body: {status: newStatus}})
            .then(response => {
                    props.getRules();
                },
                error => {
                    toast("Could not change rule status: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    return (
        <div className="rootView rulesView">
            <div className="navBar">
                <div className="title">Rules</div>
                <div className="actions">
                    <div className="">
                        <Modal
                            button={(<div className="add action">
                                <div className="icon"/>
                                <div className="title">Add Rule</div>
                            </div>)}
                            content={(<AddEditRule
                                onSuccess={onSuccess}
                                onCancel={handleClose}
                            />)}
                            title="Add Rule"
                            size="small"
                            handleClose={handleClose}
                            handleOpen={handleOpen}
                            open={open}
                        />

                    </div>
                </div>
                <div className="separator"></div>
            </div>
            <div className="tableView rulesList">
                <div className="tableSection activeRules">
                    <div className="sectionTitle">Active Rules</div>
                    {
                        _.map(_.filter(props.rules.rules, function(r){ return r.status === "ACTIVE";}), function (rule, k) {
                            return (
                                <div className="tableCell ruleCell" key={k}>
                                    <div className="cellBody" onClick={editRuleVersions.bind(this, rule)}>
                                        <div className="title ruleName">{(rule.name.length > 28 ? rule.name.substring(0, 28) + '...' : rule.name)}</div>
                                        <div className="subtitle version">{rule.activeRuleVersion ? "Active Version: " + rule.activeRuleVersion.version : "No Active Version"}</div>
                                    </div>

                                    <div className="ruleActions">
                                        <div className="hiddenActions">
                                            <div onClick={editRuleVersions.bind(this, rule)} className="ruleAction button small action">
                                                <div className="buttonTitle">Edit</div>
                                            </div>

                                            <ConfirmModal
                                                title={"Delete"}
                                                text={"Are you sure you want to delete this rule?"}
                                                onConfirm={deleteRule.bind(this, rule)}
                                                confirmTitle={"Delete Rule"}
                                            >
                                                <div className="ruleAction button destructive small">
                                                    <div className="title">Delete</div>
                                                </div>
                                            </ConfirmModal>
                                        </div>

                                        <ConfirmModal
                                            title={"Disable"}
                                            text={"Are you sure you want to disable this rule?"}
                                            onConfirm={toggleRule.bind(this, rule)}
                                            confirmTitle={"Disable Rule"}
                                        >
                                            <div className="ruleAction button action small">
                                                <div className="buttonTitle">Disable</div>
                                            </div>
                                        </ConfirmModal>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="tableSection inactiveRules">
                    <div className="sectionTitle">Inactive Rules</div>
                    {
                        _.map(_.filter(props.rules.rules, function(r){ return r.status != "ACTIVE";}), function (rule, k) {
                            return (
                                <div className="tableCell ruleCell" key={k}>
                                    <div className="cellBody" onClick={editRuleVersions.bind(this, rule)}>
                                        <div className="title ruleName">{(rule.name.length > 28 ? rule.name.substring(0, 28) + '...' : rule.name)}</div>
                                        <div className="subtitle version">{rule.activeRuleVersion ? "Active Version: " + rule.activeRuleVersion.version : "No Active Versions"}</div>
                                    </div>

                                    <div className="ruleActions">
                                        <div className="hiddenActions">
                                            <div onClick={editRuleVersions.bind(this, rule)} className="ruleAction button small action">
                                                <div className="buttonTitle">Edit</div>
                                            </div>

                                            <ConfirmModal
                                                title={"Delete"}
                                                text={"Are you sure you want to delete this rule?"}
                                                onConfirm={deleteRule.bind(this, rule)}
                                                confirmTitle={"Delete Rule"}
                                            >
                                                <div className="ruleAction button destructive small">
                                                    <div className="title">Delete</div>
                                                </div>
                                            </ConfirmModal>
                                        </div>

                                        <ConfirmModal
                                            title={"Set Active"}
                                            text={"Are you sure you want to enable this rule to start sending?"}
                                            onConfirm={toggleRule.bind(this, rule)}
                                            confirmTitle={"Enable Rule"}
                                        >
                                            <div className="ruleAction button tint small">
                                                <div className="title">Activate</div>
                                            </div>
                                        </ConfirmModal>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Modal
                button={null}
                content={(<AddEditRule
                    existingRule={editRule}
                    onSuccess={onSuccess}
                    onCancel={handleEditClose}
                />)}
                title="Edit Service"
                size="small"
                handleClose={handleEditClose}
                open={editOpen}
            />

        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RulesIndex));