import React from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core";
import {API} from "aws-amplify";

import ConfirmModal from "@/common/ConfirmModal";
import Modal from "@/common/Modal";
import {fetchAllTaskActionTypes} from "@/shared/tasks/actions";

import AddTaskActionType from "./AddTaskActionType";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getTaskActionTypes : () => dispatch(fetchAllTaskActionTypes())
})

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const columns = [
    { id: 'actionType', label: 'Action Type', minWidth: 170 },
];

function TaskActionTypesIndex( props ){

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        props.getTaskActionTypes();
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.getTaskActionTypes();
    };

    const handleSuccess = () => {
        setOpen(false);
        props.getTaskActionTypes();
    };

    const deleteActionType = (actionId) => {
        API.del('Core', '/api/v1/task/action/types/' + actionId)
            .then(response => {
                    props.getTaskActionTypes();
                    toast("Deleted Task Action!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                },
                error => {
                    toast("Could not delete task action" + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Modal
                    button={(<Button variant="contained" color="primary" className={classes.button}>
                        Add Task Action Type
                    </Button>)}
                    content={(
                        <AddTaskActionType onCancel={handleClose} onSuccess={handleSuccess} fieldName={"pmh"}/>
                    )}
                    title="Add Task Action Type"
                    size="small"
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    open={open}
                    dialogClassName={"modalDialog editFollowUpQuestion"}
                />
            </Grid>
            <Grid xs={12}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.tasks.taskActionTypes ? props.tasks.taskActionTypes.map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns.map(column => {
                                        let value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key={'edit'}>
                                        <ConfirmModal
                                            title={"Delete Action Type"}
                                            text={(<span>Are you sure you want to delete <b>{row.actionType}</b>?</span>)}
                                            onConfirm={deleteActionType.bind(this, row.id)}
                                            confirmTitle={"Delete Type"}
                                        >
                                            <Button variant="contained" color="primary" className={classes.button}>
                                                Delete
                                            </Button>
                                        </ConfirmModal>
                                    </TableCell>
                                </TableRow>
                            );
                        }) : null}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskActionTypesIndex);