const initialState = {
    shouldLogout: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'BACK_CHANNEL_LOGOUT_EVENT':
            return { ...state, shouldLogout: true };
        default:
            return state;
    }
}