import React from 'react';
import {API} from "aws-amplify";
import {toast} from "react-toastify";

export default function MasqWarning({}){

    const switchBack = () => {
        API.post('Core', '/api/v1/support-tools/assume/switch-back')
            .then(response => {
                    toast.success("Switching Back!", {position: toast.POSITION.TOP_CENTER});
                    window.location = "/";
                },
                error => {
                    toast.error("Could not switch back: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                }
            );
    }

    return (
        <div id="masq-alert">
            <span className="alert">
                CAUTION - YOU ARE EXPLORING AN ACCOUNT
                <br/>
                <a onClick={switchBack}>Switch Back</a>
            </span>

        </div>
    )
}