import { API } from 'aws-amplify';

export const GET_FIELD_OPTIONS = 'GET_FIELD_OPTIONS';
export const requestFieldOptions = () => dispatch => {
    dispatch({
        type : GET_FIELD_OPTIONS
    });
}

export function fetchFieldOptions(fieldName) {
    return function(dispatch) {
        dispatch(requestFieldOptions())
        return API.get('Core', '/api/v1/fieldoption/' + fieldName)
            .then(response => {
                    dispatch(requestFieldOptionsSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export const GET_FIELD_OPTIONS_SUCCESS = 'GET_FIELD_OPTIONS_SUCCESS';
export const requestFieldOptionsSuccess = (json) => dispatch => {
    dispatch({
        type : GET_FIELD_OPTIONS_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}