import React from "react"
import { connect } from "react-redux"
import LocalTimeStamp from "../../common/LocalTimeStamp"
import TaskDetail from "./TaskDetail"
import TaskCell from "./TaskCell"
import _ from "lodash";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
})

function TasksOverviewList({ props, onChange, onConversationStarted, onViewSourceConversation, currentPage, totalPages, handlePageChange }) {
    const [tasks, setTasks] = React.useState(props.tasks.tasks);

    React.useEffect(() => {
        setTasks(props.tasks.tasks);
    }, [props.tasks])


    const onTaskChange = (t) => {
        onChange(t);
    }

    let statuses = [
        {
            status: "OVERDUE",
            title: "Overdue"
        },
        {
            status: "IN_PROGRESS",
            title: "In Progress"
        },
        {
            status: "OPEN",
            title: "Upcoming"
        },
        {
            status: "CLOSED",
            title: "Closed"
        }
    ]

    return (
        tasks.content ?
            <div className="tasksList tableView">
                {
                    _.map(statuses, function (s, k) {
                        return (
                            <div className="tableSection" key={k}>
                                <div className="sectionTitle">{s.title}</div>
                                {
                                    _.map(_.filter(tasks.content, function (t) { return t.status === s.status }), function (t) {
                                        return (
                                            <TaskDetail task={t} onChange={onTaskChange.bind(this)} props={props} onConversationStarted={onConversationStarted}
                                                onViewSourceConversation={onViewSourceConversation} key={t.id} taskActionTypes={props.tasks.taskActionTypes}
                                                user={props.user}>
                                                <TaskCell
                                                    taskStatus={t.status}
                                                    taskTitle={t.type.name}
                                                    taskAssignee={t.assignee ? t.assignee.readableName : ""}
                                                    contactInfo={t.contact ? t.contact.readableName : ""}
                                                    taskReportedDate={
                                                        <LocalTimeStamp utcTimestamp={t.createdAt} format={"MMMM D"} utc={false} />
                                                    }
                                                    taskDueDate={
                                                        <LocalTimeStamp utcTimestamp={t.dueDate} format={"MMMM D"} utc={false} />
                                                    }
                                                />
                                            </TaskDetail>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
                {/* <div className="taskListPager">
            {totalPages > 1 && <Pagination color="primary" page={currentPage+1} count={totalPages} onChange={handlePageChange}/>}
          </div> */}

            </div> : <div />
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksOverviewList);