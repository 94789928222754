import React from 'react';
import Avatar from "./Avatar";

export default function ProfilePicture(props) {
    const { user, size } = props;

    let finalSize = 40;
    if (size) {
        finalSize = size;
    }

    return (
        <div className="profilePicture">
            <Avatar bottom name={user.readableName} size={finalSize}
                textSizeRatio={2} />
        </div>
    )
}