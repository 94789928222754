import React from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core";
import { API } from "aws-amplify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useRouteMatch } from 'react-router';

import ConfirmModal from "@/common/ConfirmModal";
import Modal from "@/common/Modal";
import { fetchFieldOptions } from "@/shared/field_option/actions";
import { useQuery } from '@/utils/utils';
import Importer from "@/common/Importer";

import AddEditFieldOption from "./AddEditFieldOption";

const mapStateToProps = (state, props) => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getFieldOptions: (fieldName) => dispatch(fetchFieldOptions(fieldName))
})

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const columns = [
    { id: 'optionName', label: 'Option Name', minWidth: 170 },
    { id: 'optionCode', label: 'Option Code', minWidth: 170 },
];

function FieldOptionsIndex({ ...props }) {
    let match = useRouteMatch();
    const { fieldName } = match.params

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState({});
    const [importSchema, setImportSchema] = React.useState([]);
    const [fieldOptions, setFieldOptions] = React.useState([]);

    React.useEffect(() => {
        props.getFieldOptions(fieldName);
        loadSchema();
    }, [])

    React.useEffect(() => {
        setFieldOptions(props.fieldOptions.fieldOptions)
    }, [props.fieldOptions.fieldOptions])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSuccess = () => {
        setOpen(false);
        props.getFieldOptions(fieldName);
    };

    const handleEditOpen = (fieldOption) => {
        setEditOpen(value => ({
            ...value,
            [fieldOption.id]: true
        }));
    };

    const handleEditClose = (fieldOption) => {
        setEditOpen(value => ({
            ...value,
            [fieldOption.id]: false
        }));
    };

    const handleEditSuccess = (fieldOption) => {
        setEditOpen(value => ({
            ...value,
            [fieldOption.id]: false
        }));
        props.getFieldOptions(fieldName);
    };

    const deleteFieldOption = (fieldOptionId) => {
        API.del('Core', '/api/v1/fieldoption/' + fieldOptionId)
            .then(response => {
                props.getFieldOptions(fieldName);
                toast("Deleted Field Option!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
            },
                error => {
                    toast("Could not delete field option" + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const loadSchema = () => {
        API.get("Core", "/api/v1/fieldoption/csv/schema")
            .then(response => {
                    setImportSchema(response);
                },
                error => {
                    toast("Could not get schema: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
    }

    let user = props.user;

    const reorderOptions = (options, sourceIndex, destinationIndex) => {
        const reorderedOptions = Array.from(options);
        const [removed] = reorderedOptions.splice(sourceIndex, 1);
        reorderedOptions.splice(destinationIndex, 0, removed);

        return reorderedOptions;
    };

    const recalculateOrder = (options) => {
        const affectedOptions = []
        options.forEach((option, index) => {
            if (option.optionOrder !== index + 1)  {
                affectedOptions.push(option)
                option.optionOrder = index + 1;
            }
        })

        return affectedOptions;
    }

    const updateOrders = (options) => {
        API.put('Core', `/api/v1/fieldoption/${fieldName}/order`, {
            body: options
        })
            .then(() => {},
                error => {
                    toast.error("Could not update option order: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                }
            );
    }


    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        setFieldOptions(state => {
            const reorderedOptions = reorderOptions(
                state,
                result.source.index,
                result.destination.index
            );

            const affectedOptions = recalculateOrder(reorderedOptions);

            if (affectedOptions.length > 0) {
                updateOrders(affectedOptions);
            }

            return reorderedOptions;
        })
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Modal
                    button={(<Button variant="contained" color="primary" className={classes.button}>
                        Add Option
                    </Button>)}
                    content={(
                        <AddEditFieldOption onCancel={handleClose} onSuccess={handleSuccess} fieldName={fieldName} />
                    )}
                    title="Add Option"
                    size="small"
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    open={open}
                    dialogClassName={"modalDialog editFollowUpQuestion"}
                />
                <Importer
                    title={"FieldOption"}
                    fields={importSchema}
                    importPath={"/api/v1/fieldoption/csv"}
                    onSuccess={() => handleSuccess()}
                >
                    <Button variant="contained" color="primary" className={classes.button}>
                        Import Option
                    </Button>
                </Importer>
            </Grid>
            <Grid xs={12}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                        <TableBody {...provided.droppableProps}
                        ref={provided.innerRef}>
                            {fieldOptions.map((row, index) => {
                                return (
                                    <Draggable key={row.id} draggableId={row.id+""} index={index}>
                                    {(provided, snapshot) => (
                                    <TableRow hover role="checkbox" tabIndex={-1}
                                    ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps} >
                                        {columns.map(column => {
                                            let value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell key={'edit'}>
                                            <Modal
                                                button={(<Button variant="contained" color="primary" className={classes.button}>
                                                    Edit
                                                </Button>)}
                                                content={(<AddEditFieldOption
                                                    existingFieldOption={row}
                                                    onCancel={() => handleEditClose(row)}
                                                    onSuccess={() => handleEditSuccess(row)}
                                                />)}
                                                title="Edit Field Option"
                                                size="small"
                                                handleClose={() => handleEditClose(row)}
                                                handleOpen={() => handleEditOpen(row)}
                                                open={editOpen[row.id]}
                                            />
                                            <ConfirmModal
                                                title={"Delete Option"}
                                                text={(<span>Are you sure you want to delete <b>{row.optionName}</b>?</span>)}
                                                onConfirm={deleteFieldOption.bind(this, row.id)}
                                                confirmTitle={"Delete Option"}
                                            >
                                                <Button variant="contained" color="primary" className={classes.button}>
                                                    Delete
                                                </Button>
                                            </ConfirmModal>
                                        </TableCell>
                                    </TableRow>
                                    )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </TableBody>
                        )}
                        </Droppable>
                    </DragDropContext>
                </Table>
            </Grid>
        </Grid>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldOptionsIndex);