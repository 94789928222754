import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Grid from "@material-ui/core/Grid";
import _ from "lodash";

import Modal from '@/common/Modal';
import AddEditUser from '@/components/AddEditUser';
import ManageCustomFields from '@/components/ManageCustomFields';
import { fetchSystemPrivileges, createUser, inviteUserClear } from '@/shared/user/actions';
import { SUCCESS, ERROR } from '@/shared/lifecycleStates';
import Importer from "@/common/Importer";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getSystemPrivileges: () => dispatch(fetchSystemPrivileges()),
    inviteUser: (user) => dispatch(createUser(user)),
    inviteUserClear: () => dispatch(inviteUserClear()),
})

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

function getSteps() {
    return [
        { label: 'Invite Teammates', stepStateKey: 'INVITE_USER' },
    ];
}

function getActiveStep(currentStep) {
    if (!currentStep) {
        return -1;
    }

    let stepIdx = _.findIndex(getSteps(), function (o) { return o.stepStateKey === currentStep });
    if (stepIdx >= 0) {
        return stepIdx;
    }
    return -1;
}

function SetupUsers(props) {
    const { stepStates, onStepChange, currentStep } = props;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(getActiveStep(currentStep));
    const [open, setOpen] = React.useState(false);
    const [loadActionsCalled, setLoadActionsCalled] = React.useState(false);
    const steps = getSteps();

    if (!loadActionsCalled) {
        props.getSystemPrivileges();
        setLoadActionsCalled(true);
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUserInvite = (user) => {
        props.inviteUser(user);
    }

    if (open && props.user.inviteUserLifecycleState === ERROR) {
        toast(props.user.inviteUserError, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
    }
    else if (open && props.user.inviteUserLifecycleState === SUCCESS) {
        props.inviteUserClear();
        toast.success("Invitation sent!", { position: toast.POSITION.TOP_CENTER });
        setOpen(false);
    }

    const getStepContent = (step) => {
        // eslint-disable-next-line default-case
        switch (step) {
            case 0:
                return (
                    <div>
                        <Typography color={"textSecondary"}>Invite other teammates who are going to help respond to patient questions and requests</Typography>
                        <Modal
                            button={(<Button variant="contained" color="primary" className={classes.button}>
                                Invite Teammate
                            </Button>)}
                            content={(<AddEditUser
                                systemPrivileges={props.user.systemPrivileges}
                                onCancel={handleClose}
                                onSave={handleUserInvite}
                            />)}
                            title="Add User"
                            size="small"
                            handleClose={handleClose}
                            handleOpen={handleOpen}
                            open={open}
                        />
                    </div>
                );
            case 1:
                return (
                    <div>
                        <Typography color={"textSecondary"}>Any other client / animal fields relevant to your practice can be setup. These fields will be made available on the client importer.</Typography>
                        <Grid container spacing={1}>
                            <ManageCustomFields width={6} header={"Client"} entity={"CLIENT_USER"}></ManageCustomFields>
                            <ManageCustomFields width={6} header={"Animal"} entity={"ANIMAL"}></ManageCustomFields>
                        </Grid>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <Typography>Import clients into the platform so the system can identify the clients when they get in touch.</Typography>
                        <Importer
                            title={"Clients"}
                            fields={props.client.csvSchema}
                            importPath={"/api/v1/client/csv"}
                        ><Button variant="contained" color="primary" className={classes.button}>Import Clients</Button></Importer>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <Typography>Import animals. Use the client external_id field to specify relationships.</Typography>
                        <Importer
                            title={"Animals"}
                            fields={props.animal.csvSchema}
                            importPath={"/api/v1/animal/csv"}
                        ><Button variant="contained" color="primary" className={classes.button}>Import Animals</Button></Importer>
                    </div>
                );
        }
    };

    const selectStep = (stepIdx) => {
        setActiveStep(stepIdx);
        onStepChange(getSteps()[stepIdx].stepStateKey);
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} nonLinear orientation="vertical">
                {steps.map((obj, index) => {
                    const stepProps = {};
                    const stepLabelProps = {};

                    if (stepStates[obj.stepStateKey] && stepStates[obj.stepStateKey].completed) {
                        stepProps.completed = true;
                        stepLabelProps.StepIconProps = {
                            classes: { root: classes.completed }
                        };
                    }

                    return (<Step key={obj.label} {...stepProps}>
                        <StepButton onClick={selectStep.bind(this, index)}><StepLabel {...stepLabelProps}>{obj.label}</StepLabel></StepButton>
                        <StepContent>
                            <Typography>{getStepContent(index)}</Typography>
                        </StepContent>
                    </Step>);
                })}
            </Stepper>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupUsers);