import React from 'react';
import InputCell from "../../../common/form/InputCell";
import {API} from "aws-amplify";
import {toast} from "react-toastify";

export default function AddTaskActionType(props){
    var { onCancel, onSuccess} = props;

    const actionTypeRef = React.createRef();

    const saveActionType = () => {
        API.post('Core', '/api/v1/task/action/types', {body: {actionType:actionTypeRef.current.value}})
            .then(response => {
                    toast.success("Action Type Created!", {position: toast.POSITION.TOP_CENTER});
                    onSuccess(response);
                },
                error => {
                    toast.error("Could not create action type: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                }
            );
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <InputCell
                            placeholder={""}
                            defaultValue={""}
                            required
                            id="actionType"
                            label="Action Type"
                            inputRef={actionTypeRef}
                        />

                    </div>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={saveActionType} className={`button confirm medium`}>
                        <div className="title">Done</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>

    )
}