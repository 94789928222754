import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDropzone } from 'react-dropzone';
import { API } from 'aws-amplify';
import axios from 'axios';

export default function UploadFile(props) {
    let { onCancel, onSuccess } = props;

    const [isLoading, setLoading] = useState(false);

    const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
        accept: 'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const startFileImport = async () => {

        if (!acceptedFiles || acceptedFiles.length <= 0)
            return toast.warn("Choose File", { position: toast.POSITION.TOP_CENTER });

        let file = acceptedFiles[0];

        try {
            setLoading(true);

            let datasources = await API.get("Core", "/api/v1/datasource");
            let batchDatasource = datasources.find(ds => ds.connectorInterface === 'BATCH');

            if (batchDatasource) {
                let s3UploadInfo = await API.post("Core", `/rdb/batch?action=getPreSignedUrl`, {
                    body: { fileName: file.name },
                    headers: {
                        'Authorization': `Bearer ${batchDatasource.token}`
                    }
                })

                await axios.put(s3UploadInfo.presignedUrl, file, {
                    headers: {
                        'Content-Type': 'application/octet-stream',
                    }
                })

                API.post("Core", `/rdb/batch?action=parse`, {
                    body: { fileName: s3UploadInfo.presignedUrl.split("?")[0] },
                    headers: {
                        'Authorization': `Bearer ${batchDatasource.token}`
                    }
                })

                toast.success("File Uploaded!", { position: toast.POSITION.TOP_CENTER });
                onSuccess();
            } else {
                toast.warn("Could not find datasource. Try again later.", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            }
        } catch (error) {
            console.error(error)
            toast.error("Could not upload file. Try again later.", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
        }

        setLoading(false);
    };

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Please Choose a File to Import Below</div>
                        </div>
                    </div>
                    <section className="container fileImport">
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!acceptedFiles || acceptedFiles.length <= 0 &&
                                <div className={`uploadArea ${!!fileRejections && fileRejections.length > 0 ? 'error' : ''}`}>
                                    <div className="fileUploadIcon" />
                                    {!!fileRejections && fileRejections.length > 0 && <div className="errorMessage">Error Uploading File</div>}
                                    <div>
                                        {(!fileRejections || fileRejections.length <= 0) &&
                                            <div>
                                                <span className="title">Drag an Drop you file here or </span>
                                                <span className="title uploadText" htmlFor="file">Browse</span>
                                            </div>
                                        }
                                        {!!fileRejections && fileRejections.length > 0 &&
                                            <div>
                                                <div className="title">Drag an Drop or </div>
                                                <div className="title uploadText" htmlFor="file">Browse </div>
                                                <div className="title">to Replace</div>
                                            </div>
                                        }
                                    </div>
                                    <div className="title">File Type: CSV, XLS, XLST</div>
                                </div>
                            }
                            {!!acceptedFiles && acceptedFiles.length > 0 &&
                                <div className="uploadArea ok">
                                    <div className="fileIcon" />
                                    <div className="title">{acceptedFiles[0].name}</div>
                                    <div className="title">Drag an Drop or <label className="uploadText">Browse</label> to Replace
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                </div>
            </div>
            <div className="actionBar">
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={() => {
                        // if (sourceType && file && !isLoading) uploadFile()
                        if (!!acceptedFiles && acceptedFiles.length > 0 && !isLoading) startFileImport()
                    }}
                        className={`button medium ${!!acceptedFiles && acceptedFiles.length > 0 && !isLoading ? 'confirm' : 'disabled'}`}>
                        <div className="title">{isLoading ? 'Importing ...' : 'Import'}</div>
                    </div>
                </div>

                <div className="separator"></div>
            </div>
        </div>
    );
}