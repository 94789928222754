import { API } from 'aws-amplify';

export const GET_TASKS = 'GET_TASKS';
export const requestTasks = () => dispatch => {
    dispatch({
        type : GET_TASKS
    });
}

export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const requestTasksSuccess = (payload) => dispatch => {
    dispatch({
        type : GET_TASKS_SUCCESS,
        payload: payload,
        receivedAt: Date.now()
    });
}

export function fetchAllTasks(status, assignee, search, taskType, page) {
    return function(dispatch) {
        dispatch(requestTasks())
        return API.get('Core', '/api/v1/task', {
                queryStringParameters: {
                    status: status,
                    assignee: assignee,
                    search: search,
                    taskType: taskType,
                    page: page || 0
                }
            })
            .then(response => {
                    dispatch(requestTasksSuccess({response}))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export const GET_TASK_TYPES = 'GET_TASK_TYPES';
export const requestTaskTypes = () => dispatch => {
    dispatch({
        type : GET_TASK_TYPES
    });
}

export const GET_TASK_TYPES_SUCCESS = 'GET_TASK_TYPES_SUCCESS';
export const requestTaskTypesSuccess = (json) => dispatch => {
    dispatch({
        type : GET_TASK_TYPES_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function fetchAllTaskTypes(tenantsIds = null) {
    return function(dispatch) {
        dispatch(requestTaskTypes())
        return API.get('Core', '/api/v1/task/types', tenantsIds ? { queryStringParameters: { tenantsIds } } : {})
            .then(response => {
                    dispatch(requestTaskTypesSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export const GET_TASK_ACTION_TYPES = 'GET_TASK_ACTION_TYPES';
export const requestTaskActionTypes = () => dispatch => {
    dispatch({
        type : GET_TASK_ACTION_TYPES
    });
}

export const GET_TASK_ACTION_TYPES_SUCCESS = 'GET_TASK_ACTION_TYPES_SUCCESS';
export const requestTaskActionTypesSuccess = (json) => dispatch => {
    dispatch({
        type : GET_TASK_ACTION_TYPES_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function fetchAllTaskActionTypes() {
    return function(dispatch) {
        dispatch(requestTaskActionTypes())
        return API.get('Core', '/api/v1/task/action/types')
            .then(response => {
                    dispatch(requestTaskActionTypesSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export const ADD_TASK = 'ADD_TASK';
export const addTask = () => dispatch => {
    dispatch({
        type : ADD_TASK
    });
}

export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const addTaskSuccess = (json) => dispatch => {
    dispatch({
        type : ADD_TASK_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function postTask(newTask, status, assignee) {
    return function(dispatch) {
        dispatch(addTask())
        return API.post('Core', '/api/v1/task', {body: newTask})
            .then(response => {
                    dispatch(addTaskSuccess(response));
                    dispatch(fetchAllTasks(status, assignee));
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export const ADD_TASK_TYPE = 'ADD_TASK_TYPE';
export const addTaskType = () => dispatch => {
    dispatch({
        type : ADD_TASK_TYPE
    });
}

export const ADD_TASK_TYPE_SUCCESS = 'ADD_TASK_TYPE_SUCCESS';
export const addTaskTypeSuccess = (json) => dispatch => {
    dispatch({
        type : ADD_TASK_TYPE_SUCCESS,
        payload: "Task type added successfully",
        receivedAt: Date.now()
    });
}

export function postTaskType(newTaskType) {
    return function(dispatch) {
        dispatch(addTaskType())
        return API.post('Core', '/api/v1/task/types', {body: newTaskType})
            .then(response => {
                    dispatch(addTaskTypeSuccess(response));
                    dispatch(fetchAllTaskTypes());
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export const UPDATE_TASK_TYPE = 'UPDATE_TASK_TYPE';
export const updateTaskType = () => dispatch => {
    dispatch({
        type : UPDATE_TASK_TYPE
    });
}

export const UPDATE_TASK_TYPE_SUCCESS = 'UPDATE_TASK_TYPE_SUCCESS';
export const updateTaskTypeSuccess = (json) => dispatch => {
    dispatch({
        type : UPDATE_TASK_TYPE_SUCCESS,
        payload: "Task type updated successfully",
        receivedAt: Date.now()
    });
}

export function putTaskType(updatedTaskType) {
    return function(dispatch) {
        dispatch(updateTaskType())
        return API.put('Core', '/api/v1/task/types/' + updatedTaskType.id, {body: updatedTaskType})
            .then(response => {
                    dispatch(updateTaskTypeSuccess(response));
                    dispatch(fetchAllTaskTypes());
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}

export const UPDATE_TASK = 'UPDATE_TASK';
export const updateTask = () => dispatch => {
    dispatch({
        type : UPDATE_TASK
    });
}

export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const updateTaskSuccess = (json) => dispatch => {
    dispatch({
        type : UPDATE_TASK_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function putTask(updatedTask, status, assignee) {
    return function(dispatch) {
        dispatch(updateTask())
        return API.put('Core', '/api/v1/task/'+ updatedTask.id, {body: updatedTask})
            .then(response => {
                    dispatch(updateTaskSuccess(response));
                    dispatch(fetchAllTasks(status, assignee));
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}


export const CLEAR_FEEDBACK_MESSAGES = 'CLEAR_FEEDBACK_MESSAGES';
export const clearFeedbackMessages = (json) => dispatch => {
    dispatch({
        type : CLEAR_FEEDBACK_MESSAGES,
        payload: null,
    });
}