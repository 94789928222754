import React from 'react';
import {withRouter} from "react-router";
import {connect, useSelector} from "react-redux";

import Modal from "@/common/Modal";
import {Button} from "@/components/Buttons";
import VersionList from "@/components/VersionList";
import {fetchAllFollowUpQuestions, fetchAllSystemLanguages, fetchSurveyData} from "@/shared/survey/actions";
import {fetchAllTaskTypes} from "@/shared/tasks/actions";

import AddEditSurveyVersion from "./AddEditSurveyVersion";
import AddEditSurvey from "./AddEditSurvey";
import {changeSelectedSurvey} from "../../shared/survey/actions";
import LoadingOverlay from 'react-loading-overlay';

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getSurveyData: (surveyId,versionId) => dispatch(fetchSurveyData(surveyId,versionId)),
    getTaskTypes: () => dispatch(fetchAllTaskTypes()),
    getFollowUps: () => dispatch(fetchAllFollowUpQuestions()),
    getSystemLanguages: () => dispatch(fetchAllSystemLanguages()),
    changeSelectedSurvey: (surveyId) => dispatch(changeSelectedSurvey(surveyId))
})

function SurveyVersion(props) {
    let surveyId = Number(props.match.params.surveyId);
    const [editOpen, setEditOpen] = React.useState(false);
    const [currentVersion, setCurrentVersion] = React.useState(null);
    const [saving, setSaving] = React.useState(false);
    LoadingOverlay.propTypes = undefined;

    const activeSurveyInfoSelector = useSelector(state => {
        return {
            activeSurvey: state.survey.activeSurvey,
            activeSurveyVersions: state.survey.activeSurveyVersions,
            selectedVersionId: state.survey.selectedVersionId
        }
    })

    React.useEffect(() => {
        if (activeSurveyInfoSelector.activeSurveyVersions) {
            setCurrentVersion(activeSurveyInfoSelector.activeSurveyVersions.find(v => v.id === activeSurveyInfoSelector.selectedVersionId))
        }
    }, [activeSurveyInfoSelector.selectedVersionId, activeSurveyInfoSelector.activeSurvey]);

    const refreshSurvey = () => {
        props.getSurveyData(surveyId,currentVersion ? currentVersion.id : null);
        props.getTaskTypes();
        props.getFollowUps();
        props.getSystemLanguages();
    }

    React.useEffect(() => {
        refreshSurvey();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // TODO pending thing that must be done in VErsions List
    const setSelectedVersion = (version) => {
        props.changeSelectedSurvey(version.id);
    }

    if (!props.survey?.activeSurvey?.id === surveyId || !props.survey?.activeSurveyVersions) {
        return <span>Loading...</span>;
    }

    if (!currentVersion) {
        return <span>Loading...</span>;
    }

    const saveLoadingSpinner = (isActive) => {
        setSaving(isActive);
    }

    return (
        <div className="rulesView versionsView">
            <div className="versionList tableView">
                <div className="navBar">
                    <div className="title">Edit Survey</div>
                    <div className="actions right">
                        <Modal
                            button={<Button
                                size="small"
                                title="Manage"
                                onClick={() => setEditOpen(true)}
                            />}
                            content={(<AddEditSurvey
                                existingSurvey={props.survey.activeSurvey}
                                onSuccess={() => {
                                    setEditOpen(false);
                                    props.getSurveyData(surveyId,currentVersion ? currentVersion.id : null);
                                }}
                                onCancel={() => setEditOpen(false)}
                            />)}
                            title="Edit Survey Definition"
                            size="small"
                            handleClose={() => setEditOpen(false)}
                            open={editOpen}
                        />
                    </div>
                    <div className="separator"></div>
                </div>
                <div className="versionSection tableSection groupedSection">
                    <div className={"cell inputCell"}>
                        <div className={"title"}>Name</div>
                        <div className="input">{props.survey.activeSurvey.name}</div>
                    </div>
                </div>
                <div className="versionSection tableSection groupedSection">
                    <VersionList selectedVersion={currentVersion}
                                 activeVersion={props.survey.activeSurvey.activeSurveyVersion}
                                 versions={props.survey.activeSurveyVersions} onSelect={setSelectedVersion}/>
                </div>
            </div>
            <div className="versionEdit tableView">
                <LoadingOverlay
                    active={saving}
                    spinner
                    className="versionEdit tableView"
                >
                <AddEditSurveyVersion
                    activeSurvey={props.survey.activeSurvey}
                    activeSurveyVersions={props.survey.activeSurveyVersions}
                    followUpQuestions={props.survey.followUps}
                    systemLanguages={props.survey.systemLanguages}
                    currentVersion={currentVersion}
                    user={props.user}
                    taskTypes={props.tasks.taskTypes}
                    savedSurveyVersions={props.survey.savedSurveyVersions}
                    saveLoadingSpinner={saveLoadingSpinner}
                    onCancel={refreshSurvey}
                />
                </LoadingOverlay>
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveyVersion));