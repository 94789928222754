import React, { useState } from 'react';
import _ from "lodash";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import MapGL, { Marker, NavigationControl } from "react-map-gl";
import { Player } from 'video-react';
import { API } from "aws-amplify";
import { Tooltip } from "@material-ui/core";

import moment from '@/common/momentConfig';
import Modal from "@/common/Modal";
import SegmentedSelector from "@/common/SegmentedSelector";
import FileDownload from "@/common/FileDownload";
import CopyText from "@/common/CopyText";
import { postTask, putTask } from "@/shared/tasks/actions";
import { canManagePatients } from "@/shared/access";
import { fetchSingleClient } from "@/shared/client/actions";
import { multipleParticipantsFeature, workflowTypes, userTypes } from "@/utils/utils";

import ConversationNotes from "./ConversationNotes";
import Pin from "../location/Pin";
import AddParticipant from "./AddParticipant";
import AddTask from "../tasks/AddTask";
import PatientDetails from "./PatientDetails";
import ConversationEncounter from "./ConversationEncounter";
import ConversationTasks from "./ConversationTasks";
import {
    AssetConversationParticipantMuteOn, AssetConversationParticipantMuteOff,
    AssetConversationParticipantPatient, AssetConversationParticipantDoctor,
    AssetConversationParticipantMedic, AssetConversationParticipantInterpreter,
    AssetConversationParticipantOther, AssetConversationApplicant
} from "../../icons/Assets"
import ApplicantDetails from './ApplicantDetails';

const mapStateToProps = state => ({
    singleClientMap: state.client.singleClients,
    workflow: state.workflow
})

const mapDispatchToProps = dispatch => ({
    getSingleClient: (clientId) => dispatch(fetchSingleClient(clientId)),
    addNewTask: (newTask) => dispatch(postTask(newTask)),
    updateTask: (updatedTask) => dispatch(putTask(updatedTask)),
})


function ConversationSidebar(props) {
    const {
        conversation,
        priorities,
        dispositions,
        workflowStates,
        height,
        onConversationUpdate,
        onParticipantUpdate,
        reloadConversation,
        serviceOfferings,
        user,
        onConversationStarted,
        activeWorkflow,
        mapboxApiAccessToken
    } = props;
    const [priorityAnchorEl, setPriorityAnchorEl] = React.useState(null);
    const [statusAnchorEl, setStatusAnchorEl] = React.useState(null);
    const [dispostionAnchorEl, setDispostionAnchorEl] = React.useState(null);
    const [editOpen, setEditOpen] = React.useState(false);
    const [activeSegment, setActiveSegment] = React.useState("DETAILS");
    const [openVideos, setOpenVideos] = React.useState({});
    const [expandedVideos, setExpandedVideos] = React.useState({});
    const [newTask, setNewTask] = React.useState({});

    const navStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: '10px'
    };
    const [viewport, setViewport] = useState({
        width: 300,
        height: 300,
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 14
    });
    const [marker, setMarker] = useState({
        latitude: 0.0,
        longitude: 0.0
    });

    React.useEffect(() => {
        if (conversation) {
            if (conversation.initiator.userType === userTypes.CLIENT) {
                props.getSingleClient(conversation.initiator.id);
            }
            setNewTask({});
        }
    }, [conversation])

    const handlePriorityMenuClick = event => {
        setPriorityAnchorEl(event.currentTarget);
    };

    const handleDispositionMenuClick = event => {
        setDispostionAnchorEl(event.currentTarget);
    };

    const handlePriorityMenuSelect = (event, priority) => {
        onConversationUpdate(conversation.id, { priority: priority });
        setPriorityAnchorEl(null);
    };

    const handleClearPriority = () => {
        onConversationUpdate(conversation.id, { priority: { name: "NONE" } });
        setPriorityAnchorEl(null);
    };

    const handleDispositonMenuSelect = (event, disposition) => {
        onConversationUpdate(conversation.id, { conversationDisposition: disposition });
        setDispostionAnchorEl(null);
    };

    const handleClearDisposition = () => {
        onConversationUpdate(conversation.id, { conversationDisposition: { name: "NONE" } });
        setDispostionAnchorEl(null);
    };

    const handleStatusMenuClick = event => {
        setStatusAnchorEl(event.currentTarget);
    };

    const handleStatusMenuSelect = (event, state) => {
        onConversationUpdate(conversation.id, { workflowState: state });
        setStatusAnchorEl(null);
    };

    let time = moment.utc(conversation.createdAt).fromNow();

    let priorityClass = "none";
    let priorityName = "None";
    const priorityClassMap = {
        0: "high",
        1: "medium",
        2: "low"
    }
    if (conversation && conversation.priority) {
        priorityName = conversation.priority.name;
        priorityClass = priorityClassMap[conversation.priority.rank];
    }

    const handleEditOpen = () => {
        setEditOpen(true);
    }

    const handleEditClose = () => {
        setEditOpen(false);
    }

    const handleEditSuccess = () => {
        reloadConversation();
        setEditOpen(false);
    }

    const onChangeSegment = (segmentKey) => {
        setActiveSegment(segmentKey);
    }

    const openVideo = (roomSID) => {
        openVideos[roomSID] = true;
        setOpenVideos(Object.assign({}, openVideos));
    }

    const closeVideo = (roomSID) => {
        openVideos[roomSID] = false;
        setOpenVideos(Object.assign({}, openVideos));
    }

    const isVideoOpen = (roomSID) => {
        return openVideos[roomSID];
    }

    const addParticipant = (newParticipant) => {
        onParticipantUpdate(conversation.id, newParticipant);
    }

    const toggleExpandedVideo = (roomSID) => {
        expandedVideos[roomSID] = !expandedVideos[roomSID];
        setExpandedVideos(Object.assign({}, expandedVideos));
    }

    const onAddTaskSubmit = async (newTask) => {
        newTask.sourceConversation = conversation;
        await props.addNewTask(newTask);
        setNewTask({ ...newTask });
    }

    const onUpdateTaskSubmit = (updateTask) => {
        props.updateTask(updateTask);
    }

    const onHandlePriorityClickAway = () => {
        setPriorityAnchorEl(null);
    }

    const onHandleWorkflowClickAway = () => {
        setStatusAnchorEl(null);
    }

    const onHandleDispositionClickAway = () => {
        setDispostionAnchorEl(null);
    }

    const getConversionStatus = (status) => {
        switch (status) {
            case 'composition-failed':
                return "Failed to convert Media";
            case 'composition-available':
                return "Media Converted";
            case 'composition-enqueued':
                return "Waiting to convert Media";
            default:
                return "Converting Media";
        }
    }

    const getRoomStatus = (status) => {
        switch (status) {
            case 'room-ended':
                return "Complete";
            default:
                return "In Progress";
        }
    }

    const participantIcon = (participant) => {
        if (participant.userType != null && participant.userType === userTypes.INTERPRETER) {
            return (
                <div className="participantProfilePicture interpreter">
                    <AssetConversationParticipantInterpreter />
                </div>
            )
        }

        switch (participant.additionalParticipant.userType) {
            case 'CLIENT':
                if (participant.patient) {
                    return (
                        <div className="participantProfilePicture patient">
                            <AssetConversationParticipantPatient />
                        </div>
                    )
                } else {
                    if (activeWorkflow.type === workflowTypes.APPLICANT) {
                        return (
                            <div className="participantProfilePicture other">
                                <AssetConversationApplicant />
                            </div>
                        )
                    }
                    
                    return (
                        <div className="participantProfilePicture other">
                            <AssetConversationParticipantOther />
                        </div>
                    )
                }
            case 'PROVIDER':
                let doc = participant.additionalParticipant.role.find(r => r.name.toLowerCase() === 'doctor');
                let med = participant.additionalParticipant.role.find(r => r.name.toLowerCase() === 'medic');
                if (doc != null) {
                    return (
                        <div className="participantProfilePicture medic">
                            <AssetConversationParticipantDoctor />
                        </div>
                    )
                } else if (med != null) {
                    return (
                        <div className="participantProfilePicture doctor">
                            <AssetConversationParticipantMedic />
                        </div>
                    )
                } else {
                    return (
                        <div className="participantProfilePicture other">
                            <AssetConversationParticipantOther />
                        </div>
                    )
                }
            default:
                return (
                    <div className="participantProfilePicture other">
                        <AssetConversationParticipantOther />
                    </div>
                )
        }
    }

    const participantDetail = (participant) => {
        if (participant.userType != null && participant.userType === userTypes.INTERPRETER) {
            return (
                <div className="details">
                    <div className="fullName">{participant.language}</div>
                    <div className="role">Interpreter</div>
                </div>
            )
        }
        if (!participant.additionalParticipant.userType) {
            return (
                <div className="details">
                    <div className="fullName">
                        <span className="name">{participant.additionalParticipant.firstName} {participant.additionalParticipant.lastName}</span>
                        <span className="phone">{participant.additionalParticipant.phone}</span>
                    </div>
                    <div className="role">Unknown</div>
                </div>
            )
        } else if (participant.additionalParticipant.userType === userTypes.CLIENT) {
            if (participant.patient) {
                return (
                    <div className="details">
                        <div className="fullName">
                            <span className="name">{participant.additionalParticipant.firstName} {participant.additionalParticipant.lastName}</span>
                            <span className="phone">{participant.additionalParticipant.phone}</span>
                        </div>
                        <div className="role">Patient</div>
                    </div>
                )
            } else {
                if (activeWorkflow.type === workflowTypes.APPLICANT) {
                    return (
                        <div className="details">
                            <div className="fullName">
                                <span className="name">{participant.additionalParticipant.firstName} {participant.additionalParticipant.lastName}</span>
                                <span className="phone">{participant.additionalParticipant.phone}</span>
                            </div>
                            <div className="role">Candidate</div>
                        </div>
                    )
                }

                return (
                    <div className="details">
                        <div className="fullName">
                            <span className="name">{participant.additionalParticipant.firstName} {participant.additionalParticipant.lastName}</span>
                            <span className="phone">{participant.additionalParticipant.phone}</span>
                        </div>
                        <div className="role">Other</div>
                    </div>
                )
            }
        } else if (participant.additionalParticipant.userType === userTypes.PROVIDER) {
            let doc = participant.additionalParticipant.role.find(r => r.name.toLowerCase() === 'doctor');
            let med = participant.additionalParticipant.role.find(r => r.name.toLowerCase() === 'medic');
            if (doc != null) {
                return (
                    <div className="details">
                        <div className="fullName">{participant.additionalParticipant.readableName}</div>
                        <div className="role">Doctor</div>
                    </div>
                )
            } else if (med != null) {
                return (
                    <div className="details">
                        <div className="fullName">{participant.additionalParticipant.readableName}</div>
                        <div className="role">Medic</div>
                    </div>
                )
            } else {
                if (activeWorkflow.type === workflowTypes.APPLICANT) {
                    return (
                        <div className="details">
                            <div className="fullName">{participant.additionalParticipant.readableName}</div>
                            <div className="role">Recruiter</div>
                        </div>
                    )
                }

                return (
                    <div className="details">
                        <div className="fullName">{participant.additionalParticipant.readableName}</div>
                        <div className="role">Provider</div>
                    </div>
                )
            }
        } else {
            return (
                <div className="details">
                    <div className="fullName">{participant.additionalParticipant.readableName}</div>
                    <div className="role">Other</div>
                </div>
            )
        }
    }

    let client = {};
    if (props.singleClientMap) {
        client = props.singleClientMap[conversation.initiator.id];
    }

    if (conversation.recentLatitude && conversation.recentLongitude && conversation.recentLatitude != marker.latitude && conversation.recentLongitude != marker.longitude) {
        if (conversation.recentLongitude >= -180 && conversation.recentLongitude <= 180 && conversation.recentLatitude >= -90 && conversation.recentLatitude <= 90) {
            setViewport({ ...viewport, latitude: conversation.recentLatitude, longitude: conversation.recentLongitude });
            setMarker({ ...marker, latitude: conversation.recentLatitude, longitude: conversation.recentLongitude });
        }
    }

    let videoChats = conversation ? props.videoChats[conversation.id] : null;

    let addParticipantSection = null;
    let featureList = user.features.map(feature => feature.feature);
    if (user.features != null) {
        if (featureList.includes(multipleParticipantsFeature)) {
            addParticipantSection = (
                <AddParticipant title={"Add Participant"} onAdd={addParticipant} submitTitle={"Add Participant"} currentConversation={conversation}>
                    <div className="button small action addParticipant">
                        <div className="title">Add Participant</div>
                    </div>
                </AddParticipant>
            );
        }
    }

    let addTaskSection = null;
    if (user.features != null) {
        addTaskSection = (
            <div className="sideBarSection sectionTasks">
                <div className="sectionTitle">Tasks</div>

                <div className="conversationTasksList">
                    <ConversationTasks conversationId={conversation.id} onChange={onUpdateTaskSubmit} onConversationStarted={onConversationStarted} newTask={newTask} props={props} />
                </div>

                <AddTask props={props} activeWorkflow={activeWorkflow} taskTypes={props.tasks.taskTypes} onAdd={onAddTaskSubmit} sourceConversation={conversation} sourceEncounter={conversation.encounter}>

                    <div className="sectionActions">
                        <div className="button small action addTask">
                            <div className="title">Add Task</div>
                        </div>
                    </div>
                </AddTask>

                <div className="sectionSeparator">
                    <div className="separator"></div>
                </div>
            </div>
        );
    }

    let conversationDispositionSection = null;
    if (user.features != null) {
        conversationDispositionSection = (
            <div className="attributeCell disposition">
                <div className="icon"></div>
                <div className="cellBody" onClick={handleDispositionMenuClick}>
                    <div className="title">Disposition</div>
                    <div className="detail">{((conversation && conversation.conversationDisposition) ? conversation.conversationDisposition.name : "None")}</div>
                </div>
                <div className="accessory popUpIndicator"></div>
                <Menu
                    id="simple-menu"
                    anchorEl={dispostionAnchorEl}
                    keepMounted
                    open={Boolean(dispostionAnchorEl)}
                    onClose={(event, reason) => reason === 'backdropClick' ? onHandleDispositionClickAway() : null}
                >
                    <MenuItem onClick={handleClearDisposition}>None</MenuItem>
                    {

                        dispositions && conversation ? (
                            _.map(_.sortBy(dispositions, function (d) { return d.name; }), function (d) {
                                let selected = conversation.conversationDisposition && conversation.conversationDisposition.id === d.id;
                                return (
                                    <MenuItem
                                        key={d.id}
                                        selected={selected}
                                        onClick={event => handleDispositonMenuSelect(event, d)}
                                    >
                                        {d.name}
                                    </MenuItem>
                                )
                            })
                        ) : null
                    }
                </Menu>
            </div>
        );
    }


    function toggleActive(participant) {
        participant.active = !participant.active;
        API.put('Core', '/api/v1/conversation/' + conversation.id + '/participants/' + participant.additionalParticipant.id, { body: participant })
            .then(response => {
                console.log("updated participant");
            },
                error => {
                    console.log("failed to update participant");
                }
            );
    }

    return (
        <div className="chatSideBar" style={{ height: height }}>
            <SegmentedSelector
                segments={[{
                    key: "DETAILS",
                    label: "Details"
                },
                {
                    key: "NOTES",
                    label: "Notes"
                }]}
                active={activeSegment}
                onChange={onChangeSegment}

            />
            <div className="sideBarContent">
                {
                    (activeSegment === "DETAILS") ? (
                        <div>
                            <div className="sideBarSection sectionClient">
                                {_.map(_.sortBy(conversation.participants, (cp) => {

                                    if (activeWorkflow.type === workflowTypes.APPLICANT) {
                                        if(cp.additionalParticipant.userType === userTypes.CLIENT) return 1;
                                        return 0;
                                    }

                                    return cp.additionalParticipant.readableName
                                }), (p, pIdx) => {
                                    return (
                                        <div className={`clientDetails ${p.active ? "isActive" : "isInactive"}`} key={pIdx}>
                                            {participantIcon(p)}
                                            {participantDetail(p)}
                                            {p.additionalParticipant.userType === userTypes.CLIENT ?
                                                <Tooltip title={p.active ? "Mute" : "Unmute"}>
                                                    <div onClick={toggleActive.bind(this, p)} className={`muteParticipantSwitch ${p.active ? 'switchOn' : 'switchOff'}`}>
                                                        {p.active ? <AssetConversationParticipantMuteOff /> : <AssetConversationParticipantMuteOn />}
                                                    </div>
                                                </Tooltip> : null}
                                            <div className="separator" />
                                        </div>
                                    )
                                })}

                                {conversation.recentLatitude &&
                                    <div className="clientLocation">
                                        <MapGL
                                            {...viewport}
                                            mapStyle="mapbox://styles/mapbox/outdoors-v11"
                                            width="100%"
                                            height="100%"
                                            mapboxApiAccessToken={mapboxApiAccessToken}
                                            onViewportChange={nextViewport => setViewport(nextViewport)}>
                                            <Marker
                                                longitude={marker.longitude}
                                                latitude={marker.latitude}
                                                offsetTop={-20}
                                                offsetLeft={-10}
                                            >
                                                <Pin size={20} />
                                            </Marker>
                                            <div className="nav" style={navStyle}>
                                                <NavigationControl />
                                            </div>
                                        </MapGL>
                                    </div>
                                }

                                {/*<div className="servicePlan">*/}
                                {/*    <div className="icon"></div>*/}
                                {/*    <div className="serviceDetails">*/}
                                {/*        <div className="title">Wellness Plan</div>*/}
                                {/*        <div className="detail">Text &amp; Video Consultations</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {conversation.interpreter ?
                                    <div className="clientDetails isActive">
                                        {participantIcon({ userType: userTypes.INTERPRETER, language: conversation.interpreter.language })}
                                        {participantDetail({ userType: userTypes.INTERPRETER, language: conversation.interpreter.language })}
                                        <div onClick={addParticipant.bind(this, { interpreter: null, inviteMessage: null })}>
                                            <div className="button small actionTranslucent">
                                                <div className="buttonTitle">Remove</div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                                {addParticipantSection}

                                <div className="sectionSeparator">
                                    <div className="separator"></div>
                                </div>
                            </div>

                            <div className="sideBarSection sectionConsultation">
                                <div className="sectionTitle">Conversation Details</div>
                                <div className="attributeCell status">
                                    <div className="icon"></div>
                                    <div className="cellBody" onClick={handleStatusMenuClick}>
                                        <div className="title">Workflow</div>
                                        <div className="detail">{((conversation && conversation.workflowState) ? conversation.workflowState.name : "")}</div>
                                    </div>
                                    <div className="accessory popUpIndicator"></div>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={statusAnchorEl}
                                        keepMounted
                                        open={Boolean(statusAnchorEl)}
                                        onClose={(event, reason) => reason === 'backdropClick' ? onHandleWorkflowClickAway() : null}
                                    >
                                        {
                                            workflowStates && conversation ? (
                                                _.map(  _.sortBy(workflowStates, function (w) { return w.rank; }),
                                                        function (w) {
                                                    let selected = conversation.workflowState && conversation.workflowState.id === w.id;
                                                    if(selected || !w.deleted ) {
                                                        return (
                                                            <MenuItem
                                                                key={w.id}
                                                                selected={selected}
                                                                onClick={event => handleStatusMenuSelect(event, w)}
                                                                disabled={w.deleted}
                                                            >
                                                                {w.name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                })
                                            ) : null
                                        }
                                    </Menu>
                                </div>

                                <div className={`attributeCell priority ${priorityClass}`}>
                                    <div className="icon"></div>
                                    <div className="cellBody" onClick={handlePriorityMenuClick}>
                                        <div className="title">Priority</div>
                                        <div className="detail">{priorityName}</div>
                                    </div>

                                    <div className="accessory popUpIndicator"></div>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={priorityAnchorEl}
                                        keepMounted
                                        open={Boolean(priorityAnchorEl)}
                                        onClose={(event, reason) => reason === 'backdropClick' ? onHandlePriorityClickAway() : null}
                                    >
                                    <MenuItem onClick={handleClearPriority}>None</MenuItem>
                                    {
                                        priorities && conversation ? (
                                            _.map(_.sortBy(priorities, function (p) { return p.rank; }), function (p) {
                                                let selected = conversation.priority && conversation.priority.id === p.id;
                                                return (
                                                    <MenuItem
                                                        key={p.id}
                                                        selected={selected}
                                                        onClick={event => handlePriorityMenuSelect(event, p)}
                                                    >
                                                        {p.name}
                                                    </MenuItem>
                                                )
                                            })
                                        ) : null
                                    }
                                </Menu>

                            </div>

                            {conversationDispositionSection}

                            <div className="attributeCell opened">
                                <div className="icon"></div>
                                <div className="cellBody">
                                    <div className="title">Opened</div>
                                    <div className="detail">{time}</div>
                                </div>
                            </div>

                            <div className="attributeCell qrcode">
                                <div className="icon"></div>
                                <div className="cellBody">
                                    <div className="title">QR Code Label</div>
                                    <div className="detail">{conversation?.qrCode?.label}</div>
                                </div>
                            </div>
                            <div className="sectionSeparator">
                                <div className="separator"></div>
                            </div>

                            {/*<div className="attributeCell followUpReminder empty">*/}
                            {/*    <div className="icon"></div>*/}
                            {/*    <div className="cellBody">*/}
                            {/*        <div className="title">Follow-Up Reminder</div>*/}
                            {/*        <div className="detail">Click to Set</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                            {
                    canManagePatients() && activeWorkflow && activeWorkflow.type === workflowTypes.PATIENT ? (
                        <PatientDetails
                            conversation={conversation}
                            dispositions={dispositions}
                            reloadConversation={reloadConversation}
                        />
                    ) : null
                }

                {activeWorkflow && activeWorkflow.type === workflowTypes.APPLICANT &&
                    <ApplicantDetails conversation={conversation} reloadConversation={reloadConversation} />
                }

                {<ConversationEncounter conversationId={conversation.id} />}

                {
                    serviceOfferings && serviceOfferings.length > 0 ? (
                        <div className="sideBarSection sectionConsultation">
                            <div className="sectionTitle">Services</div>
                            {
                                _.map(serviceOfferings, function (so, soIdx) {
                                    return (
                                        <div className="attributeCell service" key={soIdx}>
                                            <div className="cellBody">
                                                <div className="title serviceName">{so.serviceOffering.name}</div>
                                                {
                                                    _.map(_.sortBy(so.serviceOffering.fields, function (ff) { return ff.id; }), function (f) {
                                                        let valueObj = _.find(so.details, function (d) { return d.field.id == f.id });
                                                        return (
                                                            <div key={f.id} className="detail">{f.name}: <span className="answer">{(valueObj ? valueObj.value : "")}</span></div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="sectionSeparator">
                                <div className="separator"></div>
                            </div>
                        </div>
                    ) : null
                }

                {
                    videoChats && videoChats.length > 0 ? (
                        <div className="sideBarSection sectionVideoSessions">
                            <div className="sectionTitle">Video Sessions</div>
                            {
                                _.map(_.sortBy(videoChats, function (c) { return c.createdAt; }).reverse(), function (vc, vcIdx) {
                                    let time = moment(moment.utc(vc.createdAt)).local().calendar();
                                    return (
                                        <div onClick={toggleExpandedVideo.bind(this, vc.roomSID)} className={`attributeCell expandableCell videoCall ${expandedVideos[vc.roomSID] ? 'expanded' : ''}`} key={vcIdx}>
                                            <div className="baseCell">
                                                <div className="icon" />
                                                <div className="cellBody">
                                                    <div className="title">{getRoomStatus(vc.status)}</div>
                                                    <div className="detail">{time}</div>
                                                </div>
                                                <div className="accessory disclosureIndicator" />
                                            </div>
                                            <div className="detailsList">
                                                {
                                                    vc.participants && vc.participants.length > 0 ? (
                                                        <div className="detailCell participants">
                                                            <div className="title">{vc.participants.length} participants</div>
                                                            <div className="detail">{
                                                                _.map(vc.participants, function (p, pIdx) {
                                                                    return (
                                                                        <span className={"participant-connected"} key={pIdx}>{p.user.readableName}</span>
                                                                    )
                                                                }).reduce((prev, curr) => [prev, ', ', curr])
                                                            }</div>
                                                            <div className="separator" />
                                                        </div>
                                                    ) : null
                                                }

                                                <div className="detailCell actions">
                                                    {
                                                        vc.recording ? (
                                                            <Modal
                                                                button={(
                                                                    <div className="button small tint">
                                                                        <div className="buttonTitle">View Video</div>
                                                                    </div>
                                                                )}
                                                                content={(<div>
                                                                    <Player
                                                                        playsInline
                                                                        src={vc.recording.url}
                                                                    />
                                                                </div>)}
                                                                title="View Video"
                                                                size="xl"
                                                                handleClose={closeVideo.bind(this, vc.roomSID)}
                                                                handleOpen={openVideo.bind(this, vc.roomSID)}
                                                                open={isVideoOpen(vc.roomSID)}
                                                            />
                                                        ) : (
                                                            <div>
                                                                {
                                                                    vc.recordingStatus ? (
                                                                        <div className={"title"}>{getConversionStatus(vc.recordingStatus)}</div>
                                                                    ) : null
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="sectionSeparator">
                                <div className="separator"></div>
                            </div>
                        </div>
                    ) : null
                }
                {addTaskSection}
                <div className="sideBarSection sectionExport">
                    <div className="sectionTitle">Export</div>
                    <div className="exportActions">
                        <FileDownload path={"/api/v1/conversation/" + conversation.id + "/pdf"}>
                            <div className="button action small">
                                <div className="title">Download PDF</div>
                            </div>
                        </FileDownload>
                        <CopyText title="Copy Conversation Transcript" path={"/api/v1/conversation/" + conversation.id + "/text"}>
                            <div className="button action small">
                                <div className="title">Copy as Text</div>
                            </div>
                        </CopyText>
                    </div>
                </div>
            </div>

            ) : (<ConversationNotes activeConversation={conversation} />)
                }
        </div>
        </div >
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationSidebar);