import { API } from 'aws-amplify';
import {confirmSuccess, throwGenericError} from "../transaction_state/actions";

export const TRANSACTION_KEY = "CONNECT_CONFIG";

export const GET_RECEIVE_EMAIL_SETTING = 'GET_RECEIVE_EMAIL_SETTING';
export const requestReceiveEmailSetting = () => dispatch => {
    dispatch({
        type : GET_RECEIVE_EMAIL_SETTING
    });
}

export const GET_RECEIVE_EMAIL_SETTING_SUCCESS = 'GET_RECEIVE_EMAIL_SETTING_SUCCESS';
export const requestReceiveEmailSettingSuccess = (json) => dispatch => {
    dispatch({
        type : GET_RECEIVE_EMAIL_SETTING_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const SAVE_EMAIL_CONFIG = 'SAVE_EMAIL_CONFIG';
export const saveEmailConfig = () => dispatch => {
    dispatch({
        type : SAVE_EMAIL_CONFIG
    });
}

export const SAVE_EMAIL_CONFIG_SUCCESS = 'SAVE_EMAIL_CONFIG_SUCCESS';
export const saveEmailConfigSuccess = (json) => dispatch => {
    dispatch({
        type : SAVE_EMAIL_CONFIG_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_SMS_SETTING = 'GET_SMS_SETTING';
export const requestSMSSetting = () => dispatch => {
    dispatch({
        type : GET_SMS_SETTING
    });
}

export const GET_SMS_SETTING_SUCCESS = 'GET_SMS_SETTING_SUCCESS';
export const requestSMSSettingSuccess = (json) => dispatch => {
    dispatch({
        type : GET_SMS_SETTING_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const REQUEST_NEW_SMS_NUMBER = 'REQUEST_NEW_SMS_NUMBER';
export const requestNewSMSNumberStart = () => dispatch => {
    dispatch({
        type : REQUEST_NEW_SMS_NUMBER
    });
}

export const REQUEST_NEW_SMS_NUMBER_SUCCESS = 'REQUEST_NEW_SMS_NUMBER_SUCCESS';
export const requestNewSMSNumberSuccess = (json) => dispatch => {
    dispatch({
        type : REQUEST_NEW_SMS_NUMBER_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const UPDATE_SMS_CONFIG = 'UPDATE_SMS_CONFIG';
export const updateSmsConfigStart = () => dispatch => {
    dispatch({
        type : UPDATE_SMS_CONFIG
    });
}

export const UPDATE_SMS_CONFIG_SUCCESS = 'UPDATE_SMS_CONFIG_SUCCESS';
export const updateSmsConfigSuccess = (json) => dispatch => {
    dispatch({
        type : UPDATE_SMS_CONFIG_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export const GET_MASTER_PHONE_NUMBER = 'GET_MASTER_PHONE_NUMBER';
export const getMasterPhoneNumberStart = () => dispatch => {
    dispatch({
        type : GET_MASTER_PHONE_NUMBER
    });
}

export const GET_MASTER_PHONE_NUMBER_SUCCESS = 'GET_MASTER_PHONE_NUMBER_SUCCESS';
export const getMasterPhoneNumberSuccess = (json) => dispatch => {
    dispatch({
        type : GET_MASTER_PHONE_NUMBER_SUCCESS,
        payload: json,
        receivedAt: Date.now()
    });
}

export function fetchEmailSetting() {

    return function(dispatch) {

        dispatch(requestReceiveEmailSetting())

        return API.get('Core', '/api/v1/email-processing/config')
            .then(response => {
                    dispatch(requestReceiveEmailSettingSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}
export function setEmailSetting(body) {

    return function(dispatch) {

        dispatch(fetchEmailSetting())

        return API.post('Core', '/api/v1/email-processing/config', {body: body})
            .then(response => {
                    dispatch(confirmSuccess(TRANSACTION_KEY));
                },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}

export function fetchSMSSetting() {

    return function(dispatch) {

        dispatch(requestSMSSetting())

        return API.get('Core', '/api/v1/sms-processing/config')
            .then(response => {
                    dispatch(requestSMSSettingSuccess(response))
                },
                error => {
                    console.log('An error occurred.', error)
                }
            );
    }
}
export function requestNewSMSNumber(body) {

    return function(dispatch) {

        dispatch(requestNewSMSNumberStart())

        return API.post('Core', '/api/v1/sms-processing/request-number', {body: body})
            .then(response => {
                    dispatch(requestNewSMSNumberSuccess(response));
                },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}

export function updateSMSConfig(body) {

    return function(dispatch) {

        dispatch(requestNewSMSNumberStart())

        return API.post('Core', '/api/v1/sms-processing/config', {body: body})
            .then(response => {
                    dispatch(updateSmsConfigSuccess(response));
                },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}

export function fetchMasterNumber() {

    return function(dispatch) {

        dispatch(getMasterPhoneNumberStart())

        return API.get('Core', '/api/v1/sms-processing/master-number')
            .then(response => {
                    dispatch(getMasterPhoneNumberSuccess(response));
                },
                error => {
                    dispatch(throwGenericError(TRANSACTION_KEY, error.response.data.message));
                }
            );
    }
}