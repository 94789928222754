import React from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {connect} from "react-redux";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import _ from "lodash";

import {fetchCustomSchema, fetchSystemSchema, createCustomFieldAndRefresh, deleteCustomFieldAndRefresh} from "@/shared/schema/actions";
import {LockIcon, KeyboardIcon, MinusIcon} from "@/icons/Icons";
import InlineRemove from '@/common/InlineRemove';

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getCustomSchema: (entity) => dispatch(fetchCustomSchema(entity)),
    getSystemSchema: (entity) => dispatch(fetchSystemSchema(entity)),
    createCustomField: (entity, field) => dispatch(createCustomFieldAndRefresh(entity, field)),
    deleteCustomFieldAndRefresh: (entity, fieldId) => dispatch(deleteCustomFieldAndRefresh(entity,fieldId))
})

const useStyles = makeStyles(theme => ({
    panel: {
        marginTop: theme.spacing(1),
    },
    schemaHeader: {
        marginLeft: theme.spacing(1)
    },
    schemaItem: {
        color: theme.palette.text.secondary
    },
    addButton: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.success[500],
        '&:hover': {
            backgroundColor: theme.palette.success[200]
        },
    },
    saveButton: {
        margin: theme.spacing(1),
        float: 'right'
    },
    addIcon: {
        marginRight: theme.spacing(1),
    },
    fieldInput: {
        margin: theme.spacing(1),
        width:'90%'
    },
    removeButton :{
        width:'25px',
        height:'25px',
        minHeight:'25px',
        backgroundColor: theme.palette.danger[400],
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.danger[700]
        },
    }
}));

function ManageCustomFields(props){
    var { header, entity, width} = props;
    const classes = useStyles();
    const [loadActionsCalled, setLoadActionsCalled] = React.useState(false);
    const [addingActive, setAddingActive] = React.useState(false);
    const fieldNameRef = React.createRef()
    const fieldDescriptionRef = React.createRef()

    if(!loadActionsCalled){
        props.getCustomSchema(entity);
        props.getSystemSchema(entity);
        setLoadActionsCalled(true);
    }

    const createField = () => {
        let fieldObj = {
            name: fieldNameRef.current.value,
            description: fieldDescriptionRef.current.value,
        }
        props.createCustomField(entity,fieldObj);
        fieldNameRef.current.value = "";
        fieldDescriptionRef.current.value = "";
    }

    const deleteField = (fieldId) => {
        props.deleteCustomFieldAndRefresh(entity, fieldId);
    }

    return (
        <Grid className={classes.panel} item xs={width}>
            <Paper elevation={2}>
                <Typography className={classes.schemaHeader} variant={"h6"}>{header}</Typography>
                <Divider></Divider>
                <List dense={true}>
                    {
                        _.map(props.schema.systemFields[entity], function(field, k){
                            return (
                                <ListItem>
                                    <ListItemAvatar className={classes.schemaItem}>
                                        <LockIcon />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={field.name}
                                        secondary={field.description}
                                    >

                                    </ListItemText>
                                </ListItem>

                            )
                        })
                    }
                    {
                        _.map(props.schema.customFields[entity], function(field, k){
                            return (
                                <ListItem>
                                    <ListItemAvatar className={classes.schemaItem}>
                                        <KeyboardIcon />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={field.name}
                                        secondary={field.description}
                                    />
                                    <ListItemSecondaryAction>
                                        <InlineRemove
                                        onRemoveConfirm={deleteField.bind(this, field.id)}>
                                            <Fab size="small" aria-label="add" className={classes.removeButton} {...props}>
                                                <MinusIcon />
                                            </Fab>
                                        </InlineRemove>
                                    </ListItemSecondaryAction>
                                </ListItem>

                            )
                        })
                    }

                </List>
                {
                    (addingActive) ?
                        (
                            <Grid container>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Field Name"
                                        placeholder="e.g. Gender"
                                        autoFocus={true}
                                        required={true}
                                        size="small"
                                        className={classes.fieldInput}
                                        inputRef={fieldNameRef}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label="Field Notes"
                                        placeholder="e.g. Gender of the animal"
                                        size="small"
                                        className={classes.fieldInput}
                                        inputRef={fieldDescriptionRef}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Fab
                                        variant="extended"
                                        size="small"
                                        color="primary"
                                        aria-label="add"
                                        className={classes.saveButton}
                                        onClick={createField}
                                    >
                                        <AddIcon className={classes.addIcon} />
                                        Create Field
                                    </Fab>
                                </Grid>
                            </Grid>
                        ):
                        (
                            <Fab
                                variant="extended"
                                size="small"
                                color="primary"
                                aria-label="add"
                                className={classes.addButton}
                                onClick={setAddingActive.bind(this,true)}
                            >
                                <AddIcon className={classes.addIcon} />
                                Add Field
                            </Fab>
                        )
                }
            </Paper>
        </Grid>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCustomFields);