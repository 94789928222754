import React from "react"
import _ from "lodash";

import SimpleEncounterField from "./SimpleEncounterField";

/**
 *
 * @param revision - Sample structure { commitId: "1234.0", commitDate: [date], encounterRevision: [encounterTypeDto], changes: [array of changes]}
 * @param schema
 * @param props
 * @constructor
 */
export default function RMSCaseEncounterDiff({ revision,revisions, ...props }) {
    function isOffenseAdded(offense) {
        if (!revision || !revisions) {
            return null;
        }
        let offenseAdded = false;

        if (revisions.length>=2){
            for( let j=0; j< revisions.length;j++) {
                let r = revisions[j];
                if (revision.commitId == r.commitId){
                    if(j>0) {
                        let previousRevision = revisions[j- 1].encounterRevision;
                        let indexFound = _.findIndex(previousRevision.offenses, function (offense2) {
                            return offense.offenseCode == offense2.offenseCode;
                        });
                        offenseAdded = indexFound < 0;
                        if(offenseAdded) {break;}
                    }
                }
            }
        }
        return  offenseAdded;
    }

    function isOffenseRemoved() {
        if (!revision || !revisions) {
            return null;
        }
        let offenseRemoved = false;

        if (revisions.length>=2){
            for( let j=0; j< revisions.length;j++) {
                let r = revisions[j];
                if (revision.commitId == r.commitId){
                    if(j>0) {
                        let previousRevision = revisions[j- 1].encounterRevision;
                        if (previousRevision && previousRevision.offenses) {
                            for (let i = 0; i < previousRevision.offenses.length; i++) {
                                let offense = previousRevision.offenses[i];
                                let offenseExists = _.findIndex(revision.encounterRevision.offenses, function (offense2) {
                                    return offense2.offenseCode == offense.offenseCode;
                                });
                                offenseRemoved = offenseExists < 0;
                                if (offenseRemoved) {
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        return  offenseRemoved;
    }

    let offenseChange = _.find(revision.changes, function (ch) { return ch.propertyName === 'offenses'; });
    let offenseRemoved = isOffenseRemoved();



    return (
        <div className="contentView tableView encounterDetails">
            <div className="tableSection attributes">
                <div className={"sectionTitle"}>RMS Case Details</div>
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident Number"}
                    fieldPath={"incidentNumber"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Status"}
                    fieldPath={"status"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Encounter Date"}
                    isDate={true}
                    fieldPath={"encounterDate"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Agency"}
                    fieldPath={"agency"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Agency Type"}
                    fieldPath={"agencyType"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Jurisdiction"}
                    fieldPath={"jurisdiction"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident Type"}
                    fieldPath={"incidentType"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident Type 2"}
                    fieldPath={"incidentType2"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Incident SubType"}
                    fieldPath={"incidentSubtype"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Investigator Assigned"}
                    fieldPath={"detectiveAssigned"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Investigator Assigned Date"}
                    isDate={true}
                    fieldPath={"detectiveAssignedAt"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Assigned Investigator Name"}
                    fieldPath={"assignedDetectiveName"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Disposition"}
                    fieldPath={"disposition"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Property Recovered"}
                    fieldPath={"propertyRecovered"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Property Description"}
                    fieldPath={"propertyDescription"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Case Id"}
                    fieldPath={"caseId"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Case Closed Date"}
                    isDate={true}
                    fieldPath={"caseClosedDate"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"District Attorney Sent At"}
                    isDate={true}
                    fieldPath={"districtAttorneySentDate"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"District Attorney Returned At"}
                    isDate={true}
                    fieldPath={"districtAttorneyReturnedDate"} />

                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"District Attorney Disposition"}
                    fieldPath={"districtAttorneyDisposition"} />

            </div>
            <div className="tableSection attributes">
                <div className={"sectionTitle"}>Location Details</div>
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Address"}
                    fieldPath={"encounterLocation.summary"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Latitude"}
                    fieldPath={"encounterLocation.latitude"} />
                <SimpleEncounterField
                    encounter={revision.encounterRevision}
                    changes={revision.changes}
                    fieldName={"Longitude"}
                    fieldPath={"encounterLocation.longitude"} />
            </div>

            <div className="tableSection attributes">
                <div className={"sectionTitle"}>Offenses</div>
                {
                    revision.encounterRevision.offenses ? (
                        _.map(_.sortBy(revision.encounterRevision.offenses, function (u) { return u.id; }), function (offense) {

                            let added = isOffenseAdded(offense);

                            return (
                                <div className="encounterAttributeCell nested" key={offense.id}>
                                    <div className={`attributeCellSection ${added ? "added" : ""}`}>
                                        <div className="attributeCellSectionContent">
                                            <div className="encounterNestedAttributeCell">
                                                <div className="currentValue">
                                                    <div className="attributeTitle">Code</div>
                                                    <div className="attributeDetail">{offense.offenseCode}</div>
                                                </div>
                                            </div>
                                            <div className="encounterNestedAttributeCell">
                                                <div className="currentValue">
                                                    <div className="attributeTitle">Description</div>
                                                    <div className="attributeDetail">{offense.offenseDescription}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : null
                }
                {
                    offenseRemoved ? (
                        <div className="encounterAttributeCell nested">
                            <div className={`attributeCellSection deleted`}>
                                <div className="attributeCellSectionTitle">OFFENSE REMOVED</div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}