import { TextField } from "@material-ui/core";
import React, { useState } from "react";

export default function EmailChips(props) {
    const [emailValue, setEmailValue] = useState(null);
    const [error, setError] = useState(null);

    const emailAlreadyInList = email => props.emailsList.includes(email);

    const isValid = value => {
        let e = null;

        if (emailAlreadyInList(value)) e = `${value} has already benn added`;

        const EMAIL_REGEX = /^\w+([\.-]?[\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

        if (!EMAIL_REGEX.test(value)) e = `${value} is not a valid email`;

        setError(e);

        if (e) return false;

        return true;
    }

    const handleKeyDown = event => {
        if (["Enter", "Tab", ","].includes(event.key)) {
            event.preventDefault();

            var value = emailValue.trim();

            if (value && isValid(value)) {
                if (props.onEmailEmpty) props.onEmailEmpty();
                setEmailValue(null);
                props.addEmails(value);
            }
        }
    }

    const handleChange = event => {
        if (event.target.value.length > 0 && props.onEmailInput) props.onEmailInput();
        else if (props.onEmailEmpty && event.target.value.length === 0) props.onEmailEmpty();

        setEmailValue(event.target.value)
    };

    const handlePaste = event => {
        event.preventDefault();

        var paste = event.clipboardData.getData("text");
        var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

        if (emails) {
            var toBeAdded = emails.filter(email => !emailAlreadyInList(email));

            props.addEmails(...toBeAdded)
        }
    }

    return (<div className="emailChipsContainer">
        <section className="emailChipsDisplayList">
            {props.emailsList.map((v, i) => (<div className="emailChip" key={`emailChip${i}`}>
                {v}
                <button className="emailChipRemoveButton" onClick={() => props.removeItem(i)}>
                    &times;
                </button>
            </div>))}
            <TextField className={`emailChipsInput ${error && 'hasError'}`} value={emailValue || ''}
                placeholder="Type or paste email addresses and press `Enter`..."
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                onPaste={handlePaste}
                fullWidth
            />
            {error && <p className="error">{error}</p>}
        </section>
    </div>);
}