import React from "react";
import _ from "lodash";

export default function ActivityHeader({ ruleTriggerCounts }) {

    return (
        <div className="insightsCarousel">
            {/*<div className="insightsSection sectionToday">*/}
            {/*    <div className="insightsSectionTitle">Today's Insights</div>*/}
            {/*    <div className="insightsSectionCells">*/}

            {/*        <div className="insightsCell">*/}
            {/*            <div className="insightTitle">Survey Answer Ratio</div>*/}
            {/*            <div className="insightValue">84.23%</div>*/}
            {/*        </div>*/}

            {/*        <div className="insightsCell">*/}
            {/*            <div className="insightTitle">Rules Triggered</div>*/}
            {/*            <div className="insightValue">4,743</div>*/}
            {/*        </div>*/}

            {/*        <div className="insightsCell">*/}
            {/*            <div className="insightTitle">Surveys Sent Out</div>*/}
            {/*            <div className="insightValue">3,923</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="insightsSection sectionTopRules">
                <div className="insightsSectionTitle">Top Rules</div>
                <div className="insightsSectionCells">
                    {
                        (ruleTriggerCounts) ? (
                            _.map(_.take(_.sortBy(ruleTriggerCounts, function (c) { return c.count }).reverse(), 5), function (c, ck) {
                                return (
                                    <div className="insightsCell" key={ck}>
                                        <div className="insightTitle">{c.ruleName}</div>
                                        <div className="insightValue">{(c.count ? c.count + " (" + (((c.count / c.totalCount).toFixed(1) * 100) + '%') + ")" : "-")}</div>
                                    </div>
                                )
                            })
                        ) : null
                    }
                </div>
            </div>

            {/*<div className="insightsSection sectionTopEncounters">*/}
            {/*    <div className="insightsSectionTitle">Top Encounters</div>*/}
            {/*    <div className="insightsSectionCells">*/}
            {/*        <div className="insightsCell">*/}
            {/*            <div className="insightTitle">Motor Vehicle Accident</div>*/}
            {/*            <div className="insightValue">22.4%</div>*/}
            {/*        </div>*/}

            {/*        <div className="insightsCell">*/}
            {/*            <div className="insightTitle">Domestic Disturbance</div>*/}
            {/*            <div className="insightValue">15.3%</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )

}