import React from 'react';
import Link from '@material-ui/core/Link';

export default function Copyright() {
    return (
        <div className="copyright">
            {'Copyright © '}
            <Link className="copyrightLink" href="https://www.powerdms.com/power-engage">
                PowerDMS, Inc.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </div>
    );
}