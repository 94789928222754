import React from 'react';
import _ from "lodash";
import Select from "react-select";
import { toast } from "react-toastify";

import InputCell from "@/common/form/InputCell";

export default function AddAgentDataSource({ onAdd }) {

    const [name, setName] = React.useState("");
    const [id, setId] = React.useState("");
    const [type, setType] = React.useState(null);

    const DATASOURCE_TYPES = [
        { label: "MSSQL", value: "MSSQL" },
        { label: "PostgreSQL", value: "PostgreSQL" },
        { label: "PSQL (Pervasive SQL)", value: "PSQL" },
        { label: "API - Credentials", value: "APICredentials" },
        { label: "API - Token", value: "APIToken" },
        { label: "Filesystem", value: "FileSystem" },
        { label: "Samba Share", value: "SMB" },
        { label: "Dropbox", value: "Dropbox" }
    ];

    const onNameChange = (e) => {
        setName(e.target.value);
        let replaced = _.toUpper(_.replace(e.target.value, " ", "_"));
        setId(replaced);
    }

    const onSubmit = () => {
        if (!name || !id || !type) {
            toast("Must set a name and select a type!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }
        onAdd({
            Name: name,
            Id: id,
            Type: type.value
        })
    }

    const setDataSourceType = (obj, action) => {
        // eslint-disable-next-line default-case
        switch (action.action) {
            case "select-option":
                setType(obj)
                break;
        }
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Data Source Identifier</div>
                        </div>
                        <b>{id}</b>
                    </div>
                    <div className="tableSection">
                        <InputCell
                            placeholder={"CAD Database"}
                            required
                            id="dataSourceName"
                            label="Data Source Name"
                            onChange={onNameChange}
                        />
                    </div>
                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Data Source Type</div>
                        </div>
                        <Select className="tableCell selectCell schemaSelect" classNamePrefix="selectCell" value={type} onChange={setDataSourceType} options={DATASOURCE_TYPES} />
                    </div>
                    <div className="tableSection">
                        <div className={`button large tintProminent`} onClick={onSubmit}>
                            <div className="title">Add Data Source</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}