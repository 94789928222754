import React from 'react';
import { withRouter } from 'react-router';

function Help(props) {
    return (
        <div className="onboardingView">
            <div className="navBar">
                <div className="title">Help</div>
                <div className="separator" />
            </div>

            <div className="contentView">
                <div className="contactDetails">
                    <div className="tableView">
                        <div className="tableSection">
                            <div className={"cell"}>
                                <div className="title">Phone</div>
                                <div>
                                    <div className={"title"}>800-749-5104, Opt 2</div>
                                </div>
                            </div>

                            <div className={"cell"}>
                                <div className="title">Email</div>
                                <div>
                                    <div className={"title"}><a href="mailto:Support@PowerDMS.com" rel="noopener noreferrer" target="_blank">Support@PowerDMS.com</a></div>
                                </div>
                            </div>

                            <div className={"cell"}>
                                <div className="title">Product Documentation</div>
                                <div>
                                    <div className={"title"}><a style={{ textDecoration: 'underline' }} href="https://success.neogov.com/" rel="noopener noreferrer" target="_blank">PowerEngage Help Center</a></div>
                                </div>
                            </div>

                            <div className={"cell"}>
                                <div className="title">Training</div>
                                <div>
                                    <div className={"title"}><a style={{ textDecoration: 'underline' }} href="https://www.powerdmsuniversity.com/" rel="noopener noreferrer" target="_blank">PowerDMS University</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default withRouter(Help);
