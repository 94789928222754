import React from 'react';
import { withRouter } from "react-router";
import { API } from "aws-amplify";
import { toast } from "react-toastify";

import Modal from "@/common/Modal";
import moment from '@/common/momentConfig';

const UPLOAD_STATUS = {
    COMPLETE: "COMPLETE",
    PARTIAL_COMPLETE: "PARTIAL_COMPLETE",
    FAILURE: "FAILURE",
    IN_PROGRESS: "IN_PROGRESS"
}

function FileImportLogsIndex(props) {
    const [fileImportProcess, setFileImportProcess] = React.useState(null);
    const [statusStyle, setStatusStyle] = React.useState("");
    const [errorsLogs, setErrorsLogs] = React.useState([]);

    const loadUploadProcess = async () => {
        if (props.match.params && props.match.params.id) {
            API.get('Core', `/api/v1/file-import/${props.match.params.id}`)
                .then(response => {
                    setFileImportProcess(response);

                    try {
                        if (!!response && response.errors && response.errors.length)
                            setErrorsLogs(JSON.parse(response.errors));
                    } catch (error) {
                        setErrorsLogs([]);
                        toast("Unable to load errors logs", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                    }

                    switch (response.status) {
                        case UPLOAD_STATUS.COMPLETE:
                            setStatusStyle("tint");
                            break;
                        case UPLOAD_STATUS.FAILURE:
                            setStatusStyle("destructive");
                            break;
                        case UPLOAD_STATUS.PARTIAL_COMPLETE:
                            setStatusStyle("tintPurple");
                            break;
                        case UPLOAD_STATUS.IN_PROGRESS:
                            setStatusStyle("tintYellow");
                            break;
                    }

                },
                    error => {
                        setFileImportProcess(null);
                        toast("Could not load file import process: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                    }
                );
        }

    }

    React.useEffect(() => {
        loadUploadProcess();
    }, [])

    return (
        <div className="rootView fileImport">
            <div className="navBar">
                <div className="title">File Import Logs</div>
                <div className="separator"></div>
            </div>
            {!!fileImportProcess &&
                <div className="scrollView">
                    <div className="logsTable">
                        <div>
                            <div className="logsInfo title">{`${fileImportProcess.sourceType} Import`}</div>
                            <div className="logsInfo">{fileImportProcess.fileName}</div>
                        </div>
                        <div className="logsInfo space">{fileImportProcess.rows} rows</div>
                        <div className="logsInfo space">{moment.utc(fileImportProcess.createdAt).local().format("MM/DD/YYYY HH:mm:ss")}</div>
                    </div>

                    <div className="logsRecords">
                        <div className="content">
                            <div className="tag">
                                <div className={`button small ${statusStyle}`} style={{ pointerEvents: 'none' }}>
                                    <div className="title">{fileImportProcess.status ? fileImportProcess.status.replaceAll("_", " ") : null}</div>
                                </div>
                            </div>
                            <div className="divider" />
                            {!!errorsLogs && errorsLogs.length > 0 && errorsLogs.map((error, idx) => {
                                return (
                                    <div key={idx} >
                                        <div className="title">
                                            {!!error.rowNum && <label className="main">{`Row ${error.rowNum}`}</label>} {`${error.description}`}</div>
                                        <div className="divider" />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default withRouter((FileImportLogsIndex));